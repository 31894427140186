import React from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { toast } from 'react-toastify';
import Select from 'react-select';

import { QUERY_GET_ALL_CLIENTS, EDIT_JOB_CLIENT_ID } from '../api/gql';

export const ClientSelect = ({
  job_id,
  on_select,
  selected_client_id,
  disabled=false,
  multi=false,
  noDefault=false,
  className
}) => {
  // console.log('<ClientSelect />');
  const [job_client_mutation, { job_client_mutation_data, job_client_mutation_loading, job_client_mutation_error }] = useMutation(EDIT_JOB_CLIENT_ID);
  const { loading, error, data } = useQuery(QUERY_GET_ALL_CLIENTS);
  if (loading) return 'Loading client list...';
  if (error) return `Error! ${error.message}`;

  let mapping = [{ label: 'Select client', value: null }];
  data.GetAllClients.map(client => {
    mapping.push({
      label: client.org_name,
      value: client.id
    });
  });

  let client_idx = null;
  if (selected_client_id) {
    function findClient(obj) { return obj.value === selected_client_id; }
    client_idx = mapping.findIndex(findClient);
  }

  const onChange = async (e) => { 
    if (on_select) {
      const selected = await data.GetAllClients.find((client) => client.id === e.value);
      on_select(e, selected);
    } else 
    if (job_id && job_id.length) {
      if (!e.value) e.value = '';
      job_client_mutation({
        variables: {
          job_id: job_id,
          client_id: e.value
        }
      }).then((result) => {
        toast.success(`Client edited.`);
      }).catch((error) => {
        toast.error(`Failed to update job client. Error: ${error}`);
      });
    }
  }; 

  const defaultOption = noDefault ? undefined : { defaultValue: selected_client_id ? mapping[client_idx]: mapping[0] };
  return (<Select {...({
    options: mapping,
    isMulti: multi,
    isDisabled: disabled,
    className: className,
    onChange: onChange,
    ...defaultOption,
  })} />);
};
