import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

import { Container, Form } from 'react-bootstrap';
import Divider from 'components/common/Divider';

import { TextComponent } from 'features/TextComponent';
import { QutterSummary } from 'features/QutterSummary';
import { FormConfig } from './FormConfig';
import { PreviewForms } from './PreviewForms';

import { 
  addForm,
  updateFormQutterHelpText,
  updateFormQutterSendText
} from 'features/Qutters/slices/LibrarySlice';

const button_texts = {
  'sv-se': 'Skicka',
  'en-gb': 'Send',
  'en-us': 'Send',
  'fr-fr': 'Envoyer',
  'de-de': 'Senden',
  'es-es': 'Enviar',
  'es-mx': 'Enviar',
  'da-dk': 'Sende',
  'no-no': 'Sende',
  'fi-fi': 'Lähettää',
  'it-it': 'Inviare',
};

export const CreateForm = ({
  id
}) => {
  console.log(`<CreateForm /> ${id}`);
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[id]);
  const language = qutterConfig.language.current;
  const qutter = qutterConfig[language];
  const forms = qutter.forms;

  useEffect(() => {
    let temp = Object.assign({}, forms);
    temp.button_text = button_texts[qutterConfig.language.current];
    // dispatch(updateInputQutterConfig({ id, config: temp }));
  }, [language]);

  return (
  <Container key={'create-question-config'}>

    <TextComponent id={id} field={'intro'} />
    <TextComponent id={id} field={'text'}  />
    <TextComponent id={id} field={'outro'} />

    <Divider className="mt-4">Forms</Divider>

    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Help text</span>
      </div>
      <div className='w-50 text-left'>
        <Form.Control
          // defaultValue={qutter.help_text}
          value={qutter.help_text}
          type="text" 
          placeholder="Card help text"
          onChange={(e) => {
            dispatch(updateFormQutterHelpText({ qutter_id: id, help_text: e.target.value }));
          }}
        />
      </div>
    </div>

    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Send text</span>
      </div>
      <div className='w-50 text-left'>
        <Form.Control
          // defaultValue={button_text}
          value={qutter.send_text}
          type="text"
          placeholder='Button text, e.g. "Send"'
          onChange={(e) => {
            dispatch(updateFormQutterSendText({ qutter_id: id, send_text: e.target.value }));
          }}
        />
      </div>
    </div>

    <hr/>

    {forms.map((form, form_idx) => {
      return (
        <div 
          key={`form-config-${form_idx}`}
          style={{ borderBottom: '1px solid lightgray' }} 
          className='pb-2 pt-2'
        >
          <FormConfig qutter_id={id} form_idx={form_idx} />
        </div>
      );
    })}

    <div className='text-center mt-3'>
      <span
        onClick={() => { dispatch(addForm({ qutter_id: id })) }}
        className='hover-blue font-size-10 m-1'
      >
        <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
        Add Form
      </span>
    </div>

    <Divider className="mt-4">Full Preview</Divider>
    <PreviewForms qutter_id={id} />

    <Divider className="mt-4">Summary</Divider>
    <QutterSummary id={id} />

  </Container>
  );
}
