import React from 'react';
import { Table } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import SummaryCanvasLink from '_components/applications/SummaryCanvas';
import SoftBadge from '_components/common/SoftBadge';
import Flex from 'components/common/Flex';

import { 
  ApplicationScore,
  ApplicationQualified,
  ApplicationStatus,
  ApplicationStage,
  ApplicationAccepted
} from 'features/Application';


const QUERY_GET_CANDIDATE_BY_ID = gql`
query GetCandidateById($candidate_id: String!) {
  GetCandidateById(candidate_id: $candidate_id) {
    id
    firstName
    lastName
    email
    applications {
      id
      score
      qualified
      stage
      status
      accepted
      deleted
      job {
        id
        title
      }
      interviews {
        id
        summary {
          id
        }
      }
    }
  }
}
`;

export const Candidate = () => {
  let { id } = useParams();

  const { loading, error, data } = useQuery(
    QUERY_GET_CANDIDATE_BY_ID,
    { variables: { candidate_id: id }}
  );

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  console.log('<Candidate data />', data.GetCandidateById);

  if (!data.GetCandidateById) {
    return (<span>Could not find the candidate.</span>);
  }

  const firstName = data.GetCandidateById.firstName;
  const lastName = data.GetCandidateById.lastName;
  const email = data.GetCandidateById.email;
  const applications = data.GetCandidateById.applications;

  return (
    <>
      <h1>{firstName} {lastName}</h1>
      <span>{email}</span>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Job</th>
            <th>Score</th>
            <th>Qualified</th>
            <th>Stage</th>
            <th>Status</th>
            <th>Accepted</th>
          </tr>
        </thead>

        <tbody>
        {applications.map((application, idx) => {
          return (
          <tr key={`row-${idx}`}>
            <td>
              <Link key={`job-${idx}`} target='_blank' to={`/jobs/${application.job.id}`}>
                {application.job.title}
              </Link>
            </td>
            <td>
              <ApplicationScore score={application.score} />
            </td>
            <td>
              <ApplicationQualified qualified={application.qualified} />
            </td>
            <td>
              <ApplicationStatus status={application.status} deleted={application.deleted} />
            </td>
            <td>
              <ApplicationStage stage={application.stage} />
            </td>
            <td>
              <ApplicationAccepted accepted={application.accepted} />
            </td>
            <td>
            {application.interviews.map((interview, idx2) => {
              return (
              <>
                {(() => {
                  if (interview.summary && interview.summary.id && interview.summary.id.length) {
                    return (<SummaryCanvasLink summary_id={interview.summary.id} />);
                  } else {
                    return (
                      <Link key={`interview-${idx}`} target='_blank' to={`/score/${interview.id}`}>
                        <Flex alignItems="center">
                        <SoftBadge>
                          Score
                        </SoftBadge>
                        </Flex>
                      </Link>
                    );
                  }
                })()}
              </>)
            })}
            </td>
          </tr>
          )
          })
        }
        </tbody>
      </Table>
    </>
  );
};