import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { HubertQuill } from '..';

export const TestHubertQuill = ({}) => {

  const [value, set_value] = useState('');

  useEffect(() => {

  }, []);

  return (
  <Container>
    <Row>
      <Col>
        <HubertQuill   
          on_change={set_value}
          placeholder={'Write something'}
          value={value}
        />
      </Col>
    </Row>
    <Row>
      <Col>{value}</Col>
    </Row>
  </Container>
  );
};
