import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRobot
} from '@fortawesome/free-solid-svg-icons';

import {
  Container,
  Row,
  Col,
  Offcanvas,
  Form
} from 'react-bootstrap';

import { HubiversePropertySelect } from 'features/HubertActions';
import { QutterPreActions } from 'features/QutterPreActions';
import { QutterPostActions } from 'features/QutterPostActions';

import { updateQutterCandidateProperty, updateQutterSkipIfExists } from 'features/Qutters';

export const QutterActionsOffCanvas = ({
  template_id,
  block_id,
  qutter_id,
}) => {
  // console.log('<QutterActions />', qutter_id);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const qutter = qutter_config[qutter_config.language.current];

  if (!show) {
    return (
      <span
        onClick={() => { setShow(!show) }}
        className={`hover-blue me-3 font-size-10`}>
        <FontAwesomeIcon icon={faRobot} />
      </span>
    )
  } else {
  return (
    <Offcanvas
      show={show}
      onHide={() => { setShow(!show) }}
      scroll={true}
      backdrop={false}
      placement='end'
      className='qutter-action-canvas-container'
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <span
            dangerouslySetInnerHTML={{ __html: qutter.text.join(' ') }}
            className={`d-inline float-start font-size-10 m-auto`}
          />
        </Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body 
        as={Container} 
        style={{}}
      >

        <Row className='mt-2'>
          <Col>
            <QutterPreActions qutter_id={qutter_id} />
          </Col>
        </Row>

        <Row className='mt-2'>
          <Col>
            <QutterPostActions qutter_id={qutter_id} />
          </Col>
        </Row>

        <Row className='mt-2'>
          <Col>
            <span className='h6'>Save as candidate property *</span>
            <HubiversePropertySelect
              selected={qutter_config.candidate_property}
            />

            <div className='mt-4'>
              <Form.Check 
                type={'checkbox'}
                id={`skip-property-checkbox`}
                label={`Skip this question if property already exists`}
                defaultChecked={ qutter_config ? qutter_config.skip_if_exists: false }
              />
            </div>

            <div className='mt-2'>
              <Form.Check
                type={'checkbox'}
                disabled={true}
                defaultChecked={ qutter_config ? qutter_config.is_reusable: false }
                id={`re-usable-checkbox`}
                label={`Re-usable`}
              />
            </div>
          </Col>
        </Row>

      </Offcanvas.Body>
    </Offcanvas>
  );
  }
}