import { createSlice } from '@reduxjs/toolkit';

const update_followup_scoring_category = (categories, category, qutter_id) => {
  if (categories.hasOwnProperty(category)) {
    if (categories[category].optional.indexOf(qutter_id) === -1) {
      categories[category].optional.push(qutter_id);
    }
  } else {
    categories.list.push(category);
    // categories[category] = [[qutter_id]];
    categories[category] = {
      required: [],
      optional: [[qutter_id]]
    };
  }
};

const update_non_followup_scoring_category = (categories, category, qutter_id) => {
  if (categories.hasOwnProperty(category)) {
    if (categories[category].required.indexOf(qutter_id) === -1) {
      categories[category].required.push(qutter_id);
    }
  } else {
    categories.list.push(category);
    // categories[category] = [qutter_id];
    categories[category] = {
      required: [qutter_id],
      optional: []
    };
  }
};

const initialState = {
  bonuspoints: {
    total: 0,
    ids: []
  },
  weighted: {
    initial_total_weight: 0,
    ids: []
  },
  warnings: {
    ids: []
  },
  disqualifications: {
    ids: []
  },
  informational: {
    ids: []
  },
  qutter_list: [],
  actions_list: [],
  webhooks_list: []
};

const TemplateOutlineSlice = createSlice({
  name: 'template_outline',
  initialState: {},
  reducers: {
    ResetOutline (state, action) {
      const { languages } = action.payload;
      if (languages === null) return undefined;

      let res = {};
      languages.map(language => {
        res[language] = initialState;
      });
      return {
        ...res
      };
    },

    // Add/Remove - full qutter list
    add_qutter_to_template_summary_list (state, action) {
      const { languages, id, type } = action.payload;
      languages.map(language => {
        if (!state[language]) {
          state[language] = initialState;
        }

        if (
          type === 'question' && 
          !state[language].qutter_list.includes(id)
        ) {
          // Add to list if not already there
          // state[language].qutter_list.push(id);

          const idx = state[language].qutter_list.length;
          state[language].qutter_list = [
            ...state[language].qutter_list.slice(0, idx),
            id,
            ...state[language].qutter_list.slice(idx)
          ];
        }
        else 
        if (
          type === 'action' && 
          !state[language].action_list.includes(id)
        ) {
          // Add to list if not already there
          // state[language].action_list.push(id);

          const idx = state[language].action_list.length;
          state[language].action_list = [
            ...state[language].action_list.slice(0, idx),
            id,
            ...state[language].action_list.slice(idx)
          ];
        }
      });
    },

    remove_qutter_from_template_summary_list (state, action) {
      const { languages, id } = action.payload;
      languages.map(language => {
        const outline = state[language];
        const idx = outline.qutter_list.indexOf(id);
        if (idx > -1) outline.qutter_list.splice(idx, 1);

        // TODO: Remove bonuspoints
        // TODO: Remove disqualifications
        // TODO: Remove warnings
        // TODO: Remove weights
        // TODO: Remove informationals
      });
    },

    update_bonuspoints (state, action) {
      const { language, bonuspoints } = action.payload;
      return {
        ...state,
        [language]: {
          ...state[language],
          bonuspoints: bonuspoints
        }
      }
    },

    update_warnings (state, action) {
      const { language, warnings } = action.payload;
      return {
        ...state,
        [language]: {
          ...state[language],
          warnings: warnings
        }
      };
    },

    update_disqualifications (state, action) {
      const { language, disqualifications } = action.payload;
      return {
        ...state,
        [language]: {
          ...state[language],
          disqualifications: disqualifications
        }
      };
    },
  }
});

export const {
  // Set/reset
  ResetOutline,

  // Bonuspoints
  update_bonuspoints,

  // Warnings
  update_warnings,

  // Disqualifications
  update_disqualifications,

  // Qutter list
  add_qutter_to_template_summary_list,
  remove_qutter_from_template_summary_list,

  // Scoring categories
  update_scoring_categories,
  remove_scoring_categories,

} = TemplateOutlineSlice.actions;

export default TemplateOutlineSlice.reducer;
