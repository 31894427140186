
export function UpdateQutterCandidateProperty (state, action) {
  const { id, candidate_property } = action.payload;
  const qutter = state[id];
  qutter.candidate_property = candidate_property;
};

export function UpdateQutterSkipIfExists (state, action) {
  const { id, checked } = action.payload;
  const qutter = state[id];
  qutter.skip_if_exists = checked;
};
