import React, { useState } from 'react';
import Select from 'react-select';

import { TAGS } from '../assets/constants';

const QutterTagSelectMultiple = ({
  disabled,
  on_select, 
  selected,
  placeholder,
  className
}) => {

  let tags = [];
  if (selected && selected.length > 0) {
    let idx = 0;
    selected.map((s) => {
      function find(obj) { return obj.value === s; }
      idx = TAGS.findIndex(find);
      if (idx > -1) tags.push(TAGS[idx]);
    });
  }

  return (
    <Select
      isMulti={true}
      isDisabled={disabled}
      className={className}
      defaultValue={tags && tags.length > 0 ? tags: null}
      options={TAGS}
      placeholder={placeholder ? placeholder: 'Choose tags'}
      onChange={(e)=> {
        console.log(e)
        if (on_select) on_select(e);
      }}
    />
  );
};

export default QutterTagSelectMultiple;
