import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { score_interview } from '../api/api';

import { Chat } from './Chat/Chat';
import { ScoreBoard } from './ScoreBoard/ScoreBoard';
import { JobOffCanvasTab } from 'features/Job';
import { CandidateOffCanvasButton } from 'features/Candidate';
import { ScoreSummary } from './ScoreSummary/ScoreSummary';

import { initScoring, updateTotals } from '..';
import { INTERVIEW_TAGS } from '../constants/enums';

const interviewTagsTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props.description}
  </Tooltip>
);

export const ScoreInterview = ({
  interview_id
}) => {
  const dispatch = useDispatch();

  const [data, set_data] = useState(null);
  const [interview, set_interview] = useState(null);
  const [job, set_job] = useState(null);
  const [application, set_application] = useState(null);
  const [candidate, set_candidate] = useState(null);

  const [chat, set_chat] = useState(null);
  const [template, set_template] = useState(null);
  const [summary, set_summary] = useState(null);
  const [scoring, set_scoring] = useState(null);

  useEffect(() => {
    score_interview(`${interview_id}`)
    .then(res => {
      dispatch(initScoring({
        chat: res.chat,

        list: res.scoring.list,
        details: res.scoring.details,

        bonuspoints_sum: res.scoring.bonuspoints_sum,
        bonuspoints_max: res.scoring.bonuspoints_max,

        weighted_sum:  res.scoring.weighted_sum,
        weighted_max: res.scoring.weighted_max,

        qualified: res.scoring.qualified,
        total: res.scoring.total,
      }));
      set_data(res);
      set_interview(res.interview);
      set_job(res.job);
      set_application(res.application);
      set_candidate(res.candidate);
      set_template(res.template);
      set_summary(res.summary);
      set_chat(res.chat);
      set_scoring(res.scoring);

      dispatch(updateTotals());
      console.log(res)

    }).catch(error => {
      console.log('score_interview ERROR', error);
    });
  }, []);

  useEffect(() => {
    if (interview && interview.blanks) {
      const blanks = interview.blanks;
      const fullname = `${blanks.first_name} ${blanks.last_name}`;
      document.title = `Score - ${blanks.title} - ${fullname}`;
    }
  }, [interview]);

  if (!data) return (null);

  const status = interview.status;
  const auto_updating = interview.auto_updating;
  const aiDetection = interview.ai_detection;

  return (
    <div className=''>

      <Card className='' style={{ height: '100%' }}>
        <Card.Body
          className='full-page-minus-navbar' //style={{ maxHeight: '100vh' }}
        >

        <Container>
          <Row
            style={{overflowY: 'auto', maxHeight: '15vh' }}
          >
            {status && status.updated_at ? (
              <Row className='text-center'>
                <Col>Last update at: {new Date(status.updated_at).toISOString()}</Col>
              </Row>
            ):(<></>)}

            <div className='text-center'>
              {status && status.name ? (
                <ScoringStatusHistoryPopover
                  status={status.name}
                  history={status.history}
                />
              ):(<></>)}

              {auto_updating && auto_updating.tags && auto_updating.tags.length ? (
                <>
                  {auto_updating.tags.map((tag) => {
                    return (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 100 }}
                        overlay={interviewTagsTooltip({ description: INTERVIEW_TAGS[tag].description })}
                      >
                        {tag && tag === 'PASSED' ? (
                          <Badge bg="success" className="me-1">{tag.split('_').join(' ')}</Badge>
                        ):(
                          <Badge bg="danger" className="me-1">{tag.split('_').join(' ')}</Badge>
                        )}
                      </OverlayTrigger>
                    )
                  })}
                </>
              ):(<></>)}

              {aiDetection ? (
                <OverlayTrigger
                  placement="right"
                  delay={0}
                  overlay={interviewTagsTooltip({ description: `Score: ${aiDetection.score}` })}
                >
                  {aiDetection.is_ai ? (
                    <Badge bg="warning" className="me-1">{aiDetection.tag}</Badge>
                  ):(
                    <Badge bg="success" className="me-1">{aiDetection.tag}</Badge>
                  )}
                </OverlayTrigger>
              ):(null)}
            </div>
          </Row>

          <Row style={{overflowY: 'auto', maxHeight: '85vh', padding: '20px' }}>
            <Col
              id='message-container' 
              className='pt-2 pb-2'
              style={{overflowY: 'auto', height: '100%', maxHeight: 'inherit' }}
            >
              <Chat
                chat={chat}
                language={interview.language}
              />
            </Col>

            <Col 
              className='pt-2 pb-2'
              style={{overflowY: 'auto', height: '100%', maxHeight: 'inherit' }}
            >
              <ScoreBoard scoring={scoring} />
            </Col>

          </Row>
        </Container>

        </Card.Body>
      </Card>

      <div 
        className='text-center p-2 w-100'
        style={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          backgroundColor: 'black',
          color: 'white'
        }}
      >
        <span
          className='h4 me-1'
          style={{ color: 'white' }}
        >
          {job.title}
        </span>

        {job && job.role_id && (
          <span className='h7 ms-1'>
            {`| ${job.role_id.split('_').join(' ')}`}
          </span>
        )}

        <JobOffCanvasTab
          job={job}
          template_id={interview.interview_id}
        />

        {candidate ? (
        <CandidateOffCanvasButton
          candidate_id={candidate._id}
          candidate={candidate}
        />
        ):(null)}
      </div>

      <ScoreSummary
        chat={chat}
        job={job}
        summary={summary}
        application={application}
        interview={interview}
        scoring={scoring}
        candidate={candidate}
      />

    </div>
  );
};
