import React, { useState } from 'react';
import axios from 'axios';

const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const URL = `${base}/interviews/v1`;

function updateScore (
  id,
  score,
  status
) {
  console.log('updateScore', id, score, status)
  return new Promise((resolve, reject) => {
    axios.post(`${URL}/update-interview-score-and-status/${id}`, { id, score, status })
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}

export const UpdateApplication = () => {
  const [id, setId] = useState('');
  const [score, setScore] = useState(0);
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const onSendClick = () => {
    console.log('onSendClick')
    setSuccess('');
    setError('');

    updateScore(id, score, status)
    .then((res) => { 
      console.log('res', res)
      setSuccess(res.message)
    })
    .catch((error) => {
      console.error('error', error)
      if (typeof error === 'string')
        setError(error)
      else setError(error.message)
    })
  }

  return (
  <div className='container mt-5'>

    <div className="mb-3 row">
      <label
        htmlFor="interviewId"
        className="col-2 col-form-label"
      >
        Interview ID
      </label>
      <div className="col-3">
        <input
          onChange={(e) => { setId(e.target.value); }}
          value={id}
          placeholder='abc123'
          type="text" className="form-control" id="interviewId" />
      </div>
    </div>

    <div className="mb-3 row">
      <label
        htmlFor="score"
        className="col-2 col-form-label">
          Score
      </label>
      <div className="col-3">
        <input
          onChange={(e) => { setScore(e.target.value); }}
          value={score}
          type="number"
          min={0}
          max={100}
          className="form-control" id="score" />
      </div>
    </div>

    <div className='mb-3 row'>
      <label 
        htmlFor="status"
        className="col-2 form-label"
      >
        Status
      </label>
      <div className="col-3 d-inline w-25">
        <select
          id="status"
          className='form-select'
          value={status}
          // defaultValue={status}
          onChange={(e) => { setStatus(e.target.value); }}
        >
          <option value={null}> - </option>
          <option value='not_qualified' id='not_qualified'>Not Qualified</option>
          <option value='qualified'>Qualified</option>
          <option value='high_score'>High score</option>
        </select>
      </div>

    </div>

    <div className='mb-3'>
      <button
        className='btn btn-primary'
        onClick={onSendClick}
      >
        Send
      </button>
    </div>

    <div style={{ color: 'green' }}>
      {success}
    </div>

    <div style={{ color: 'red' }}>
      {error}
    </div>

  </div>
  );
};
