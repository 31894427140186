import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { WarningIcon } from './evaluation_icons/WarningIcon';
import { DisqualifiedIcon } from './evaluation_icons/DisqualifiedIcon';
import { BonusIcon } from './evaluation_icons/BonusIcon';
import { InfoIcon } from './evaluation_icons/InfoIcon';
import { PointIcon } from './evaluation_icons/PointIcon';

import { EvaluationPopoverContainer } from 'features/QutterEvaluation';

export const EvaluationOverlay = ({
  template_id = null,
  qutter_id,
  evaluation,
  type,
  id,
  onSave
}) => {
  const templateConfig = useSelector(state => state.Template);
  const readOnly = templateConfig.readOnlyMode;
  if (!evaluation) return <span>Error: Missing evaluation.</span>;

  return (
    <>
      {readOnly ? (
        // <div>
          <span className="me-2">
            {
              {
                warning: <WarningIcon />,
                disqualified: <DisqualifiedIcon />,
                bonus: (
                  <BonusIcon
                    bonuspoints={
                      evaluation.bonus_points ? evaluation.bonus_points : 0
                    }
                  />
                ),
                info: <InfoIcon />,
                point: (
                  <PointIcon
                    points={evaluation.points ? evaluation.points : 0}
                  />
                )
              }[evaluation.name]
            }
          </span>
        // </div>
      ) : (
        <OverlayTrigger
          trigger={['click']}
          placement="left"
          rootClose
          overlay={EvaluationPopoverContainer(
            template_id,
            qutter_id,
            evaluation,
            type,
            id,
            onSave
          )}
        >
          <span className="me-2">
            {
              {
                warning: <WarningIcon />,
                disqualified: <DisqualifiedIcon />,
                bonus: (
                  <BonusIcon
                    bonuspoints={
                      evaluation.bonus_points ? evaluation.bonus_points : 0
                    }
                  />
                ),
                info: <InfoIcon />,
                point: (
                  <PointIcon
                    points={evaluation.points ? evaluation.points : 0}
                  />
                )
              }[evaluation.name]
            }
          </span>
        </OverlayTrigger>
      )}
    </>
  );
};
