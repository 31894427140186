import React, { useState, useEffect } from 'react';
import TemplateSelectSingle from './TemplateSelectSingle';

import { get_all_templates, get_client_templates } from '../api/api';

export const TemplateSelect = ({
  client_id,
  on_select=null,
  selected_template_id,
  multi=false,
  clearable=false,
  disabled=false,
  className
}) => {
  const [templates, set_templates] = useState([]);
  const [error, set_error] = useState(null);
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    if (client_id) {
      get_client_templates(client_id)
      .then((res) => {
        set_templates(res);
        set_loading(false);
      }).catch((error) => {
        set_error(error);
        set_loading(false);
      });
    } else {
      get_all_templates(true)
      .then((res) => {
        set_templates(res);
        set_loading(false);
      }).catch((error) => {
        set_error(error);
        set_loading(false);
      });
    }
  }, [client_id]);

  if (loading) { return (<span>Loading templates...</span>); }
  if (error) { return (<span>Error: {error}</span>); }

  
  if (multi) {
    return (<div>Template multiple select is not yet available.</div>);
  }

  return (
    <TemplateSelectSingle
      templates_list={templates}
      client_id={client_id}
      on_select={on_select}
      className={className}
      selected_template_id={selected_template_id}
      clearable={clearable}
      disabled={disabled}
    />
  );
}
