import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faSave, faChartColumn } from '@fortawesome/pro-solid-svg-icons';

import { UpdateTemplate } from 'features/Template/slices/thunks';
import { approve } from 'features/InterviewSummary';

export const SaveTemplateBoard = ({
  id,
  tab_key, set_tab_key
}) => {
  const dispatch = useDispatch();
  const template = useSelector(state => state.Template[id]);
  const outline = useSelector(state => state.TemplateOutline);
  const evaluation = useSelector(state => state.Evaluation);
  const interview_summary = useSelector(state => state.InterviewSummary[id]);
  const parameters = useSelector(state => state.Parameters);
  const user = useSelector(state => state.config.user);

  return (
    <div 
      className='text-center p-2 w-100'
      style={{
        position: 'fixed', 
        left: 0,
        bottom: 0,
        backgroundColor: 'black',
        color: 'white'
      }}
    >
    {
      (() => {
        if (!interview_summary.approved) {
          if (tab_key === 'interview') {
            return (
              <span
                className="font-size-10 hover-blue"
                onClick={() => {
                  set_tab_key('summary');
                }}
              >
                <FontAwesomeIcon icon={faChartColumn} className='me-1'/>
                Approve summary layout
              </span>
            );
          } else {
            return (
              <span
                className="font-size-10 hover-blue"
                onClick={() => {
                  const approved_date = new Date();
                  const ntemplate = Object.assign({}, template);
                  const ninterview_summary = Object.assign({}, interview_summary);
                  const noutline = Object.assign({}, outline);
                  const nparameters = Object.assign({}, parameters);
                  const nevaluation = Object.assign({}, evaluation);

                  dispatch(approve({
                    template_id: id,
                    approved: true,
                    approved_by: user._id,
                    approved_date: approved_date
                  }));

                  ntemplate.interview_summary = ninterview_summary;
                  ntemplate.interview_summary.approved = true;
                  ntemplate.interview_summary.approved_by = user._id;
                  ntemplate.interview_summary.approved_date = approved_date;
                  ntemplate.outline = noutline;
                  ntemplate.parameters = nparameters;
                  ntemplate.evaluation = nevaluation;

                  dispatch(UpdateTemplate(ntemplate)).then((res) => { 
                    toast.success(`Summary approved and template saved.`);
                    set_tab_key('interview');
                  })
                }}
              >
                <FontAwesomeIcon icon={faSave} className='me-1' />
                Approve summary and save template
              </span>
            );
          }
        } else {
          return (
            <span
              className="font-size-10 hover-blue"
              onClick={() => {
                const ntemplate = Object.assign({}, template);
                const ninterview_summary = Object.assign({}, interview_summary);
                ntemplate.interview_summary = ninterview_summary;
                ntemplate.outline = Object.assign({}, outline);
                ntemplate.parameters = Object.assign({}, parameters);
                ntemplate.evaluation = Object.assign({}, evaluation);
                dispatch(UpdateTemplate(ntemplate)).then((res) => { 
                  toast.success(`Template saved.`); 
                })
              }}
            >
              <FontAwesomeIcon icon={faFloppyDisk} className='me-1' />
              Update
            </span>
          );
        }
      })()
    }
    </div>
  );
};