import React from 'react';
import Select from 'react-select';

import { OPERATORS_SELECT_LIST } from 'features/QutterRules/constants/condition_types';

export const OperatorsSelect = ({
  disabled,
  placeholder,
  className,

  on_select,
  selected,
}) => {

  let idx = null;
  if (selected) {
    function find (obj) { return obj.value === selected; }
    idx = OPERATORS_SELECT_LIST.findIndex(find);
  }

  console.log('<OperatorsSelect />', idx);
  return (
  <Select
    isMulti={false}
    className={className}
    isDisabled={disabled}
    placeholder={placeholder ? placeholder: ''}

    value={(selected ? OPERATORS_SELECT_LIST[idx]: null)}
    options={OPERATORS_SELECT_LIST}
    onChange={(e)=> {
      if (on_select) on_select(e);
    }}
  />
  );
};