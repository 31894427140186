
export const form_types = [
  { label: 'Text',      value: 'text' },
  { label: 'Number',    value: 'number' },
  { label: 'Email',     value: 'email' },
  { label: 'Slider',    value: 'slider' },
  { label: 'Checkbox',  value: 'checkbox' },
  { label: 'Range',     value: 'range' },
  { label: 'Matrix',    value: 'matrix' },
  { label: 'File',      value: 'file' },
];