import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';

import { Button } from 'react-bootstrap';
import { QutterCommentItem, QutterCommentsList } from '..';
import { getCommentsByQuestion, postComment } from '../api/api';

export const QutterCommentContainer = ({
  template_id,
  qutter_id,
  comments
}) => {
  // console.log('<QutterCommentContainer /> ')
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isPosting, setIsPosting] = useState(false);
  const [content, setContent] = useState('');
  const [posts, setPosts] = useState(comments);

  const [error, setError] = useState({ isError: false, message: '' });
  const library = useSelector(state => state.Library);
  const template = useSelector(state => state.Template[template_id]);
  const config = useSelector(state => state.config);
  const user = config.user;

  useEffect(() => {
    // getCommentsByQuestion(user, {
    //   template_id,
    //   qutter_id,
    //   client_id: template.client_id
    // })
    // .then((data) => {
    //   console.log('getCommentsByQuestion data', data);
    //   setComments(data);
    //   setLoading(false);
    // })
    // .catch((error) => {
    //   console.log(error);
    //   setComments([]);
    //   setError({ isError: true, message: error });
    //   setLoading(false);
    // });
    setLoading(false);
  }, []);

  const onCommentClick = () => {
    if (!content || !content.length)
      return;

      setIsPosting(true);

    postComment(user, {
      client_id: template.client_id,
      template_id,
      qutter_id,

      comment: {
        content,
        author: {
          id: user._id,
          name: `${user.first_name} ${user.last_name}`,
          author_type: user.userType
        },
      }
    })
    .then((data) => {
      console.log('postComment result', data);
      setPosts(data);
      setContent('');
      setIsPosting(false);
    })
    .catch((error) => {
      console.log(error);
      setError({ isError: true, message: error });
      toast.error(error);
      setIsPosting(false);
    });
  };

  if (loading) return <div className='text-center'>Loading comments...</div>

  return (
  <div
    style={{ height: '100%' }}
  >
    {error.isError ? (
      <div
        className='text-center red'
      >
        Error! {error.message}
      </div>
    ):(null)}

    <div className='d-inline-block w-100'>
      <ReactQuill
        placeholder={'Write a comment'}
        defaultValue={content}
        onChange={(e) => {
          console.log(e)
          setContent(e);
        }}
      />

      <Button
        className='float-end mt-2'
        disabled={content && content.replace(/<[^>]*>?/gm, '').length ? false: true}
        onClick={onCommentClick}
      >
        {isPosting ? 'Posting...': 'Comment'}
      </Button>
    </div>

    <QutterCommentsList comments={posts} />
  </div>
  );
};
