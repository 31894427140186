import React from 'react';
import Select from 'react-select';

import { hubiverse_property_list } from '../constants/hubiverse_property_list';

export const HubiversePropertySelect = ({
  on_select,
  selected
}) => {

  let idx = null;
  if (selected) {
    function find (obj) { return obj.value === selected; }
    idx = hubiverse_property_list.findIndex(find);
  }

  return (
    <Select
      options={hubiverse_property_list}
      defaultValue={(selected ? hubiverse_property_list[idx]: hubiverse_property_list[0])}
      onChange={(e)=> { if (on_select) on_select(e); }}
    />
  );
};
