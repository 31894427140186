import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import classNames from 'classnames';

const ClientMembers = ({ client }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0">Members</h5>
      </Card.Header>
      <Card.Body className="border-top p-0">
        {/* <Collapse in={!collapsed}></Collapse> */}
        {client.members.slice(0, (showAll === false ? 5: client.members.length)).map(member => (
          <Row
            key={member.id}
            className="g-0 align-items-center border-bottom py-2 px-3"
          >
            <Col md="2" className="pe-3">
              <span
                className={classNames('badge rounded-pill', {
                  'badge-soft-success': member.flag === 'confirmed',
                  'badge-soft-danger': member.flag === 'unconfirmed'
                })}
              >
                {member.flag}
              </span>
            </Col>
            <Col md="4" className="mt-1 mt-md-0">
              <span>
                <Link to={`/users/${member.id}`}>
                  {member.firstName} {member.lastName}
                </Link>
              </span>
            </Col>
            <Col md="4">
              <p className="mb-0">
                {member.email}
              </p>
            </Col>
            <Col md="1">
              <p className="mb-0">
                {client.admins.includes(member.id) ? (
                  <span
                    className={classNames('badge rounded-pill badge-soft-info')}
                  >
                    Is admin
                  </span>
                ) : (
                  <>{''}</>
                )}
              </p>
            </Col>
          </Row>
        ))}
      </Card.Body>
      <Card.Footer className="bg-light p-0">
        <IconButton
          variant="link"
          iconClassName="fs--2 ms-1"
          icon="chevron-right"
          className="d-block w-100"
          iconAlign="right"
          onClick={() => showAll === false ? setShowAll(true): setShowAll(false)}
        >
          {showAll === false ? 'View all': 'View less'}
        </IconButton>
      </Card.Footer>
    </Card>
  );
};

export default ClientMembers;
