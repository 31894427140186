import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faComment } from '@fortawesome/pro-solid-svg-icons';

import { RemoveQutterFromTemplate } from 'features/Template';
import QutterLanguages from 'features/Qutters/components/QutterLanguages';
import { QutterActionsOffCanvas } from 'features/QutterActions';
import { MissingQutter } from 'features/Qutters';

import { EditQutterIcon } from 'features/QutterEdit';
import { InfoQutterIcon } from 'features/QutterInfo';
import { QutterRulesOverlay } from 'features/QutterRules';
import { ClickToCopy } from 'features/ClickToCopy';
import { QutterShortDescription } from 'features/QutterCreate';

import {
  QutterTypeIcon,
  ClientQutterIcon,
  GlobalQutterIcon,
  QutterPreviewIcon,
  QutterCommentIcon
} from 'features/Icons';

export const QutterHeader = ({
  template_id,
  block_id,
  qutter_id,
  order,
  children,
  previewIsOpen,
  onFullPreviewClick,
  provided,
  is_followup,
  parent_id,

  rules = []
}) => {
  // console.log('<QutterHeader />', qutter_id);
  let params = useSelector(state => state.Parameters[qutter_id]);
  const templateConfig = useSelector(state => state.Template);
  const readOnly = templateConfig.readOnlyMode;
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const interviewReceptState = useSelector(state => state.InterviewReceipt);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];

  const custom_intro_key = `intro.${language}`;
  const custom_text_key = `text.${language}`;
  const custom_outro_key = `outro.${language}`;

  const [intro, set_intro] = useState(qutter ? qutter.intro : '');
  const [text, set_text] = useState(qutter ? qutter.text : '');
  const [outro, set_outro] = useState(qutter ? qutter.outro : '');

  const [actions_modal_show, set_actions_modal_show] = useState(false);

  useEffect(() => {
    // Intro field
    if (params && params.hasOwnProperty(custom_intro_key))
      set_intro(params[custom_intro_key]);
    else if (qutter && qutter.intro) 
      set_intro(qutter.intro);

    // Text field
    if (params && params.hasOwnProperty(custom_text_key))
      set_text(params[custom_text_key]);
    else if (qutter && qutter.text) 
      set_text(qutter.text);

    // Outro field
    if (params && params.hasOwnProperty(custom_outro_key))
      set_outro(params[custom_outro_key]);
    else if (qutter && qutter.outro) 
      set_outro(qutter.outro);

  }, [qutter_config, params, language]);

  if (qutter) {
    return (
      <div className={`bubble-hubert hover-parent-visibility`}>
        <div className={`hover-parent-visibility-child`}>
          {readOnly || is_followup ? (null):(
            <span>
              {children}
              {provided && (
                <span
                  {...provided.dragHandleProps}
                  className="mr-card font-size-10"
                >
                  <FontAwesomeIcon icon={faGripVertical} />
                </span>
              )}
            </span>
          )}

          {/* Full Preview of Qutter */}
          <QutterPreviewIcon
            previewIsOpen={previewIsOpen}
            onFullPreviewClick={onFullPreviewClick}
            qutter_id={qutter_id}
          />

          {/* Qutter Actions */}
          {template_id && templateConfig.readOnlyMode === false && (
            <QutterActionsOffCanvas
              template_id={template_id}
              block_id={block_id}
              qutter_id={qutter_id}
              show={actions_modal_show}
              setShow={set_actions_modal_show}
            />
          )}
          {/* End Qutter Actions */}

          {templateConfig.readOnlyMode === false && (
            <QutterRulesOverlay
              template_id={template_id}
              block_id={block_id}
              qutter_id={qutter_id}
              rules={rules}
            />
          )}

          {!template_id &&
            !is_followup &&
            templateConfig.readOnlyMode === false && (
              <EditQutterIcon qutter_id={qutter_id} language={language} />
            )}

          {template_id && templateConfig.readOnlyMode === false && (
            <RemoveQutterFromTemplate
              template_id={template_id}
              block_id={block_id}
              qutter_id={qutter_id}
              is_followup={is_followup}
              parent_id={parent_id}
            />
          )}

          {template_id ? (
            <QutterCommentIcon
              template_id={template_id}
              block_id={block_id}
              qutter_id={qutter_id}
              is_followup={is_followup}
              parent_id={parent_id}
            />
          ):(null)}

          <div className="float-end">
            <QutterLanguages template_id={template_id} qutter_id={qutter_id} />
            <QutterTypeIcon
              qutter_id={qutter_id}
              containerClass={'me-2 font-size-10'}
            />
            <InfoQutterIcon
              qutter_id={qutter_id}
              containerClass={'me-2 font-size-10'}
            />
            <GlobalQutterIcon
              qutter_id={qutter_id}
              containerClass={'me-2 font-size-10 color-blue'}
            />
            <ClientQutterIcon
              qutter_id={qutter_id}
              containerClass={'me-2 font-size-10 color-blue'}
            />

            <ClickToCopy
              url={`${process.env.REACT_APP_LOOPBACK_ADDRESS}/questions?id=${qutter_id}`}
              text={' '}
              tooltip_text={'Share question'}
              className={'d-inline'}
            />
          </div>
        </div>

        <div className="">
          {order !== undefined && order !== null ? (
            <span>
              {`${order}. `}
            </span>
          ):(null)}

          <QutterShortDescription
            qutter_id={qutter_id}
            shortDescription={qutter_config.short_description}
          />

          <span
            dangerouslySetInnerHTML={{ __html: intro }}
            className={`font-size-10`}
          />
          <span
            dangerouslySetInnerHTML={{ __html: text }}
            className={`font-size-10`}
          />
          <span
            dangerouslySetInnerHTML={{ __html: outro }}
            className={`font-size-10`}
          />
          <div className="float-end font-size-10 d-flex">
            <span className="d-block">{`ID: ${qutter_id}`}</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        {readOnly ? (null):
        (
          <div className={`bubble-hubert red`}>
            <span>
              {children}
              {provided && (
                <span
                  {...provided.dragHandleProps}
                  className="mr-card float-start font-size-10"
                >
                  <FontAwesomeIcon icon={faGripVertical} />
                </span>
              )}
            </span>

            <MissingQutter
              template_id={template_id}
              qutter_id={qutter_id}
              missingLanguage={language}
            />
          </div>
        )}
      </div>
    );
  }
};
