import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import {
  Container,
  Popover,
  OverlayTrigger,
  FloatingLabel, 
  Form
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';

import { add_interview_summary_block } from '..';

const AddSummaryBlockPopOver = ({
  template_id,
  show,
  set_show
}) => {
  const dispatch = useDispatch();
  const [name, set_name] = useState('');
  const [error, set_error] = useState(false);

  return (
  <Popover 
    id="popover-basic"
    className={`qutter-followup-popover ${show ? '': 'd-none'}`}
  >
    <Popover.Header className=''>
      <span>Add summary block</span>
    </Popover.Header>

    <Popover.Body
      as={Container}
      className='w-100'
    >
      <div>
        <FloatingLabel
          controlId="inputInternal"
          label="Block name"
          className="mb-3"
        >
          <Form.Control
            placeholder="Screening questions"
            defaultValue={name}
            onChange={e => {
              set_name(e.target.value);
              if (e.target.value.length > 0) set_error(false);
            }}
          />
        </FloatingLabel>
      </div>
      <div className='d-flex'>
        <div className='text-start m-auto w-50'>
          <span
            className={`font-size-10 red ${error ? 'd-block': 'd-none'}`}
          >
            <FontAwesomeIcon icon={faExclamationCircle} className='me-1' />
            Block must have a name.
          </span>
        </div>
        <div className='text-end m-auto w-50'>
          <span
            className='text-end font-size-10 hover-blue'
            onClick={() => {
              if (!name || !name.length) {
                set_error(true);
                return;
              }
              const block_id = nanoid();
              const block = {
                id: block_id,
                name: name,
                custom: true,
                questions: []
              };
              dispatch(add_interview_summary_block({
                template_id, block_id, block
              }))
              set_name('');
              // set_show(false);
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} className='me-1' />
            Add summary block
          </span>
        </div>
      </div>
    </Popover.Body>
  </Popover>
  )
}

export const AddInterviewSummaryBlockOverlay = ({
  template_id,
 }) => {
  console.log('<AddSummaryBlockOverlay />', template_id);
  const templateConfig = useSelector(state => state.Template);
  const readOnly = templateConfig.readOnlyMode;
  const [show, set_show] = useState(false);

  if (readOnly) return null;

  return (
    <OverlayTrigger
      trigger={['click']}
      placement='left'
      overlay={
        AddSummaryBlockPopOver({
          template_id,
          show, 
          set_show
        })
      }
    >
      <span 
        className='font-size-10 hover-blue me-2'
        onClick={() => { set_show(!show); }}
      >
        <FontAwesomeIcon icon={faPlusCircle} className='me-1' />
        Add summary block
      </span>
    </OverlayTrigger>
  );
};
