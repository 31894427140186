import React, { useState } from 'react';
import Select from 'react-select';

const QutterAnswerSelectSingle = ({
  disabled,
  placeholder,
  className,

  answer_list,
  selected,
  on_select,
}) => {

  let idx = null;
  if (selected && !Array.isArray(selected)) {
    function findAnswer (obj) { return obj.value === selected; }
    idx = answer_list.findIndex(findAnswer);
  }

  return (
    <Select
      isMulti={false}
      className={className}
      isDisabled={disabled}

      value={(selected ? answer_list[idx]: null)}
      placeholder={placeholder ? placeholder: 'Choose Answer'}
      options={answer_list}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};

export default QutterAnswerSelectSingle;