import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import Flex from 'components/common/Flex';

import { CandidateOffCanvas } from '..';

export const CandidateOffCanvasButton = ({
  candidate_id,
  candidate,
  job_id=null,
  application_id=null,
  interview_id=null,
}) => {
  // console.log('<CandidateCanvasTab />', candidate_id);

  const [show, setShow] = useState(false);
  const onCandidateClick = () => { setShow(!show); }

  if (!candidate_id) {
    return (
      <span variant="hubert" className='btn-sm float-end red'>
        Candidate _id not provided.
      </span>
    );
  }

  return (
    <Flex alignItems="center" className='d-inline-flex mx-2'>
      <Badge
        onClick={onCandidateClick}
        className='cursor-pointer'
      >
        Candidate
        <CandidateOffCanvas
          candidate={candidate}
          job_id={job_id}
          application_id={application_id}
          interview_id={interview_id}
          candidate_id={candidate_id}
          show={show}
          setShow={setShow}
        />
      </Badge>
    </Flex>
  );
};