import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { InterviewsFilterOffCanvasButton } from '..';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

import ApplicationIdsPopover from '../../ApplicationsList/components/ApplicationIdsPopover';
import SummaryCanvasLink from '_components/applications/SummaryCanvas';

// import ToDoList from '../../../components/dashboards/crm/ToDoList';
import ToDoList from '../../../components/dashboards/project-management/ToDoList';

import { getPaginatedInterviews } from '../api/api';
const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

const InterviewsIndex = () => {
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(true);
  const [interviewState, setInterviewState] = useState('ended');
  const [paginationState, setPaginationState] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [sort, setSort] = useState(0);
  const [filter, setFilter] = useState(0);
  const [list, setList] = useState([]);

  const columns = [
    {
      accessor: 'full_name',
      Header: 'Candidate',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { candidate } = rowData.row.original;
        const fullName = `${candidate.first_name} ${candidate.last_name}`;
        return (
          <Link 
            to={`/candidates/${candidate._id}`}
          >
            <Flex alignItems="center">
              {/* <Avatar size="s" name={fullName} className="me-2" /> */}
              <div className="flex-1">
                <h5 className="mb-0 fs--1">
                {`${candidate.first_name || candidate.last_name ? fullName: 'Candidate'}`}
                </h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Email',
      Cell: rowData => {
        const { candidate } = rowData.row.original;
        const email = `${candidate.email}`;
        return <a href={`mailto:${email}`}>{email}</a>;
      }
    },
    {
      accessor: 'role_id',
      Header: 'Role',
      Cell: rowData => {
        const { role_id } = rowData.row.original;
        return <span >{role_id}</span>;
      }
    },
    {
      accessor: 'job',
      Header: 'Job',
      Cell: rowData => {
        const { blanks } = rowData.row.original;
        return <span >{blanks ? (`${blanks.company} ${blanks.subHeader}`): ''}</span>;
      }
    },
    {
      accessor: 'interview',
      Header: 'Score/Summary',
      Cell: rowData => {
        const { _id, summary, application_id } = rowData.row.original;
        if (summary.length) {
          return (
            <>
              {summary.map((s, idx) => {
                return (
                  <SummaryCanvasLink 
                    key={`interview-${idx}`}
                    application_id={application_id}
                    interview_id={_id}
                    summary_id={s._id}
                  />
                );
              })}
            </>
          );
        } else {
          return (
            <Link key={`interview-${_id}`} target='_blank' to={`/score/${_id}`}>
              <Flex alignItems="center">
              <SoftBadge>
                Score
              </SoftBadge>
              </Flex>
            </Link>
          );
        }
      }
    },
    // {
    //   accessor: 'score',
    //   Header: 'Score',
    //   Cell: rowData => {
    //     const { score } = rowData.row.original;
    //     return <ApplicationScore score={score} />
    //   }
    // },
    // {
    //   accessor: 'stage',
    //   Header: 'Stage',
    //   Cell: rowData => {
    //     const { stage } = rowData.row.original;
    //     return <ApplicationStage stage={stage} />
    //   }
    // },
    // {
    //   accessor: 'status',
    //   Header: 'Status',
    //   Cell: rowData => {
    //     const { status } = rowData.row.original;
    //     const { deleted } = rowData.row.original;
    //     return (<ApplicationStatus status={status} deleted={deleted} />);
    //   }
    // },
    // {
    //   accessor: 'qualified',
    //   Header: 'Qualified',
    //   Cell: rowData => {
    //     const { qualified } = rowData.row.original;
    //     return (<ApplicationQualified qualified={qualified} />);
    //   }
    // },
    // {
    //   accessor: 'accepted',
    //   Header: 'Accepted',
    //   Cell: rowData => {
    //     const { accepted } = rowData.row.original;
    //     return (<ApplicationAccepted accepted={accepted} />)
    //   }
    // },
    // {
    //   accessor: 'interview',
    //   Header: 'Scoring Status',
    //   Cell: rowData => {
    //     const { id, interviews } = rowData.row.original;
    //     return (
    //       <>
    //     {interviews.map((interview, idx) => {
    //       if (interview.scoring_status) {
    //         return (
    //           <ScoringStatusHistoryPopover
    //             status={interview.scoring_status.name}
    //             history={interview.scoring_status.history}
    //           />
    //         );
    //       } else 
    //       {
    //         return (
    //           <span>-</span>
    //         );
    //       }
    //     })}
    //     </>
    //     );
    //   }
    // },
    {
      accessor: 'id',
      Header: 'IDs & Links',
      Cell: rowData => {
        const { _id, candidate, candidate_id, application_id } = rowData.row.original;
        return (
          <ApplicationIdsPopover
            application_id={application_id}
            candidate_id={candidate_id}
            interview_ids={[_id]}
            previewLinks={[`${process.env.REACT_APP_INTERVIEW_BASE_URL}/preview/${_id}`]}
            interviewLinks={[`${process.env.REACT_APP_INTERVIEW_BASE_URL}/${_id}`]}
          />
        );
      }
    },
    // {
    //   accessor: 'language',
    //   Header: 'Language',
    //   Cell: rowData => {
    //     const { interviews } = rowData.row.original;
    //     return (
    //       <span>
    //         {interviews.map((interview, idx) => {
    //           const language = interview.language;
    //           return (<span key={`interview-language-${idx}`}>{languages[language]}</span>);
    //         })}
    //       </span>
    //     );
    //   }
    // },
    {
      accessor: 'created_at',
      Header: 'Created',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <span>{new Date(created_at).toLocaleDateString("en-UK", options)}</span>;
      }
    },
    {
      accessor: 'interview_started',
      Header: 'Started',
      Cell: rowData => {
        const { interview_started } = rowData.row.original;
        if (interview_started)
          return <span>{new Date(interview_started).toLocaleDateString("en-UK", options)}</span>;
        return <span>-</span>
      }
    },
    {
      accessor: 'interview_ended',
      Header: 'Ended',
      Cell: rowData => {
        const { interview_ended } = rowData.row.original;
        if (interview_ended)
          return <span>{new Date(interview_ended).toLocaleDateString("en-UK", options)}</span>;
        return <span>-</span>
      }
    },
    // {
    //   accessor: 'none',
    //   Header: 'Action',
    //   disableSortBy: true,
    //   cellProps: {
    //     className: 'text-end'
    //   },
    //   Cell: data => {
    //     const { interviews, id } = data.row.original;
    //     return (
    //       <CardDropdown 
    //         iconClassName="fs--1" 
    //         style={{ zIndex: '1000' }}
    //         btnRevealClass="btn-reveal-sm" 
    //         drop="start"
    //       >
    //         <div className="py-2">
    //           {interviews.map((interview, idx) => {
    //             return (
    //               <ResetInterviewDropdownItem 
    //                 application_id={id}
    //                 interview_id={interview.id}
    //                 idx={idx}
    //               />
    //             );
    //           })}
    //           <Dropdown.Item href="#!">TODO: Send Reinvite</Dropdown.Item>
    //           <DeleteApplicationDropdownItem 
    //             application_id={id}
    //             applications_list={applications}
    //             set_applications_list={set_applications}
    //           />
    //         </div>
    //       </CardDropdown>
    //     );
    //   }
    // }
  ];

  useEffect(() => {
    document.title = `Interviews`;
  }, []);

  useEffect(() => {
    getPaginatedInterviews(pageNumber, limit)
    .then((res) => {
      console.log('res', res)
      setList(res.data.data)
      setPaginationState(res.data)
      setLoading(false);
    })
    .catch((error) => {
      console.log('error', error)
      setLoading(false);
    });
  }, []);

  const interviewStateOnChange = (e) => {
    setInterviewState(e.target.value)
  }

  if (loading) return 'Loading...'
  if (!paginationState) return '!PaginationState...'

  return (
  <div>
    InterviewsIndex
    <FontAwesomeIcon className="me-2" icon={faMagnifyingGlass} />
    <InterviewsFilterOffCanvasButton setFilter={setFilter} />
    <ToDoList />

    <div>
      <Form.Check // prettier-ignore
        inline
        type='radio'
        label={`Any`}
        checked={interviewState === 'any'}
        name={`interview-state`}
        value={`any`}
        onChange={interviewStateOnChange}
      />

      <Form.Check
        inline
        type='radio'
        label={`Not started`}
        checked={interviewState === 'not_started'}
        name={`interview-state`}
        value={`not_started`}
        onChange={interviewStateOnChange}
      />

      <Form.Check // prettier-ignore
        inline
        type='radio'
        label={`Started`}
        checked={interviewState === 'started'}
        name={`interview-state`}
        value={`started`}
        onChange={interviewStateOnChange}
      />

      <Form.Check
        inline
        type='radio'
        label={`Ended`}
        checked={interviewState === 'ended'}
        name={`interview-state`}
        value={`ended`}
        onChange={interviewStateOnChange}
      />
    </div>

    <AdvanceTableWrapper
      columns={columns}
      selection={true}
      sortable
      pagination
      perPage={40}

      data={list}
    >
      <Card className="mb-3">
        <Card.Header>
          {/* <ApplicationsTableHeader header={header} table /> */}
          <Row className="flex-end-center mb-2 mt-2">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table/>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>

        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <AdvanceTableFooter
            table

            rowsPerPageSelection
            rowCount={list.length}
            page={paginationState.page}
            pageIndex={paginationState.page}
            setPageSize={setLimit}

            canPreviousPage={paginationState.page > 0}
            previousPage={() => { console.log('previous page') }}
            navButtons
            canNextPage={true}
            nextPage={() => { console.log('next page') }}

            viewAllBtn={false}
            rowInfo
            perPage
            rowsPerPageOptions={[10, 20, 40]}
          />

        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  </div>
  );
};

export default InterviewsIndex;
