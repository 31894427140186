import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'react-bootstrap';

import { create_job_request } from '../api/api';

export const CreateJobButton = ({
  title,
  client_id,
  template_id,
  company_name,
  role_id,
  role_name,
  threshold,
  set_error,
  set_success
}) => {
  const navigate = useNavigate();
  const [is_valid, set_is_valid] = useState(false);

  const validate = () => {
    if (!title) {
      set_error('Missing title.');
      return false; 
    }
    if (!client_id) { 
      set_error('Missing client.'); 
      return false; 
    }
    if (!template_id) { 
      set_error('Missing template.'); 
      return false; 
    }
    if (!company_name) { 
      set_error('Missing company name.'); 
      return false; 
    }
    if (!role_id) { 
      set_error('Missing role.'); 
      return false; 
    }
    if (!role_name) { 
      set_error('Missing role name.'); 
      return false; 
    }
    if (threshold === null) {
      set_error('Missing threshold. If you do not want a threshold for this job, set it to 0.'); 
      return false; 
    }
    set_error(null);
    return true;
  };
  
  useEffect(() => {
    const v = validate();
    set_is_valid(v);
  }, [title, client_id, template_id, company_name, role_id, role_name, threshold]);

  const create = () => {
    set_is_valid(validate());
    if (is_valid) {
      create_job_request({
        title,
        client_id,
        template_id,
        company_name,
        role_id,
        role_name,
        threshold,
      })
      .then((res) => {
        set_success(res);
        setTimeout(() => {
          navigate(res.redirect);
        }, 2000);
      }).catch((error) => {
        set_error(error);
      });
    }
  };

  return (
    <Button
      variant="falcon-primary"
      size="sm"
      onClick={create}
      disabled={!is_valid}
    >
      Create
    </Button>
  );
};
