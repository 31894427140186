import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';

import { TemplateBlockHeader } from '..';
import { TemplateQutterProvider } from 'features/Template';
import { PreviewNodeAction } from 'features/Nodes';
import { AddQutterToBlock } from 'features/TemplateBlock';
import { Loading } from 'features/common';

export const TemplateBlock = ({
  template_id,
  block_id,
  block_idx,
}) => {
  // console.log('<Block />', block_id);
  const block = useSelector(state => state.Template[template_id].blocks.map[block_id]);
  const allowed_languages = useSelector(state => state.Template[template_id].language_settings.allowed_languages);
  const filtered = useSelector(state => state.Template.filtered);
  if (!block) return (<Loading />);

  return (
    <Card
      key={`accordion-block-card-${block.id}`}
      className='template-block-container pb-2'
    >
      <TemplateBlockHeader
        template_id={template_id}
        block_id={block_id} 
      />

      <Card.Body className={`pt-0 pb-0 ${!block.is_open ? 'd-none': ''}`}>

          <Droppable
            key={`droppable-${block_idx}-${block_id}`}
            droppableId={`${block_id}`}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
              >
                {provided.placeholder}
                {block && block.nodes && block.nodes.length ? (
                  <>
                  {block.nodes.map((node, node_idx) => {
                    if (node.type === 'question') {
                      return (
                        <TemplateQutterProvider
                          key={`${block_id}-${block_idx}-qutter-${node.id}`}
                          allowed_languages={allowed_languages}
                          template_id={template_id}
                          block_id={block_id}
                          block_name={block.name}
                          qutter_id={node.id}
                          block_idx={block_idx}
                          qutter_idx={node_idx}
                          node={node}
                          node_idx={node_idx}
                          is_followup={false}
                          order={node_idx + 1}
                        />
                      );
                    } else if (node.type === 'action') {
                      return (
                        <PreviewNodeAction
                          key={`${block_id}-${block_idx}-qutter-${node.id}`}
                          template_id={template_id}
                          block_id={block_id}
                          block_idx={block_idx}
                          node={node}
                          node_idx={node_idx}
                          is_followup={false}
                          
                          order={node_idx + 1}
                        />
                      );
                    }
                  })}
                  </>
                ):(
                  <AddQutterToBlock
                    template_id={template_id}
                    block_id={block_id}
                    node_idx={0}
                    qutter_idx={0}
                  />
                )}
              </div>
            )}
          </Droppable>

    </Card.Body>
  </Card>
  );
};
