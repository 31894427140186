import React, { useState } from 'react';
import Select from 'react-select';

import { LANGUAGES } from '../assets/constants';

const LanguageSelectMultiple = ({
  language_list,
  disabled,
  on_select, 
  selected,
  placeholder,
  className,
  clearable,
  searchable
}) => {
  let languages = [];

  if (selected && selected.length > 0) {
    let idx = 0;
    selected.map((s) => {
      function find(obj) { return obj.value === s; }
      idx = language_list.findIndex(find);
      if (idx > -1) languages.push(language_list[idx]);
    });
  }

  return (
    <Select
      isMulti={true}
      className={className}
      isDisabled={disabled}
      value={languages && languages.length > 0 ? languages: null}
      options={language_list}
      placeholder={placeholder ? placeholder: 'Choose languages'}
      isClearable={clearable}
      isSearchable={searchable}

      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};

export default LanguageSelectMultiple;
