import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Divider from 'components/common/Divider';
import { Form, Row, Col, Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

import { ClientSelect } from 'features/ClientSelect';
import { RoleSelect } from 'features/RoleSelect';
import { QutterTagSelect } from 'features/QutterTagSelect';

import {
  updateQutterIsGlobal,
  updateQutterTags,
  updateQutterClientId,
  updateQutterRoles,
  updateQutterIsSTAR,
  updateQutterIsReusable
} from 'features/Qutters/slices/LibrarySlice';

export const QutterGeneralSettings = ({
  id
}) => {
  const dispatch = useDispatch();
  const Modals = useSelector(state => state.Modals);
  const [qutter_id, set_qutter_id] = useState(id);
  const [language, set_language] = useState(Modals.createQuestionModal.language ? Modals.createQuestionModal.language: null);
  const qutterConfig = useSelector(state => state.Library[(qutter_id)]);
  console.log('<CreateQutterGeneralSettings />', qutter_id, language);

  let qutter = null;
  if (qutterConfig && language) {
    qutter = qutterConfig[language];
  }

  return (
  <Accordion className='m-1 mt-2'>
    <Accordion.Item eventKey="0">
      <Accordion.Header>General Settings</Accordion.Header>
      <Accordion.Body as={Container}>

        <Divider className="mt-4">Tags</Divider>
        <Row>
          <Col className='m-auto text-center'>
            <span className='m-auto'>Tags</span>
            <span className='m-auto d-block'>Tag the question to make it easier to find later.</span>
          </Col>
          <Col className='m-auto'>
            <QutterTagSelect
              multi={true}
              placeholder='Tag your question'
              selected={qutterConfig.tags}
              on_select={(e)=> { 
                const tags = e.map((role) => { return role.value });
                dispatch(updateQutterTags({ 
                  id: qutter_id,
                  tags: tags
                }))
              }}
            />
          </Col>
        </Row>

        <Divider className="mt-4">Role</Divider>
        <Row>
          <Col className='m-auto text-center'>
            <span className='m-auto'>Role</span>
            <span className='m-auto d-block'>Tag the question as a question specific to a role.</span>
          </Col>
          <Col className='m-auto'>
            <RoleSelect
              selected={qutterConfig.roles}
              on_select={(e) => {
                const roles = e.map((role) => { return role.value });
                dispatch(updateQutterRoles({
                  id: (qutter_id),
                  roles: roles
                }))
              }}
              multi={true}
            />
          </Col>
        </Row>

        {qutterConfig && qutterConfig.type === 'open' && (
        <>
          <Divider className="mt-4">STAR Question</Divider>
          <Row>
            <Col className='m-auto text-center'>
              <span className='m-auto'>STAR</span>
              <span className='m-auto d-block'>Tag the question as a STAR question.</span>
            </Col>
            <Col className='m-auto'>
              <div>
                <Form.Check
                  type='switch'
                  id='starCheckedSwitch'
                  defaultChecked={ qutterConfig ? qutterConfig.is_star_question: false }
                  onChange={(e) => {
                    dispatch(updateQutterIsSTAR({
                      id: qutter_id,
                      is_star_question: e.target.checked 
                    }))
                  }}
                />
              </div>
            </Col>
          </Row>
        </>
        )}

        <Divider className="mt-4">Reusable Question</Divider>
        <Row>
          <Col className='m-auto text-center'>
            <span className='m-auto d-block'>Tag the question as reusable by default.</span>
          </Col>
          <Col className='m-auto'>
            <Form.Check
              type='switch'
              id='reusableCheckedSwitch'
              defaultChecked={ qutterConfig ? qutterConfig.is_reusable: false }
              onChange={(e) => {
                dispatch(updateQutterIsReusable({
                  id: qutter_id,
                  is_reusable: e.target.checked
                }))
              }}
            />
          </Col>
        </Row>

        <Divider className="mt-4">Client Specific Or Global</Divider>
        <Row>
          <Col className='m-auto text-center'>
            <ClientSelect
              disabled={qutterConfig.global}
              selected_client_id={qutterConfig.client_id}
              on_select={(e) => {
                dispatch(updateQutterClientId({ 
                  id: qutter_id,
                  client_id: e.value
                }))
              }}
            />
          </Col>

          <Col className='m-auto text-center'>
            <span className='m-auto d-block'>
              Tag the question as global or a client specific question.
            </span>
            <div>
              <Form.Check
                type='switch'
                id='checkedSwitch'
                defaultChecked={qutterConfig ? qutterConfig.global: false}
                onChange={(e) => { 
                  dispatch(updateQutterIsGlobal({ 
                    id: qutter_id,
                    global: e.target.checked 
                  }))
                }}
              />
            </div>
          </Col>
          <Col className='m-auto text-center'>
            {qutterConfig && qutterConfig.global ? (
              <span>This is now global.</span>
            ):(
              <span>This is now client specific.</span>
            )}
          </Col>
        </Row>

      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
  );
};
