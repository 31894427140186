import React from 'react';

export const PreviewContainer = ({
  children,
}) => {

  return (
  <div
    style={{ 
      backgroundColor: '#f2f2f7',
      padding: '20px'
    }}
  >
    <form 
      className="mt-5 mb-3 mx-3 form" 
      style={{
        backgroundColor: '#fff',
        borderRadius: '10px',
        padding: '20px',
        position: 'relative',
        marginRight: 'auto !important',
        marginLeft: 'auto !important',
        boxShadow: '0 5px 10px #d9d9d9',
      }}
    >
      {children}
    </form>
  </div>
  )
}