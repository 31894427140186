import { createSlice } from '@reduxjs/toolkit';

import {
  EditQutterOptionEvaluation,
  AddFollowup,
  RestoreDefaultOptionFollowups,
  EditDefaultOptionFollowups,
  RemoveOptionFollowup,
  RemoveFollowup,
} from '../reducers/reducers';

import { 
  TemplateTextCopy, 
  TextFieldResetToOriginal,
  TextFieldPlus,
  TextFieldMinus,
  TextFieldUpdate
} from 'features/PreviewQuestionTexts';

import {
  RulesCopy,
  AddCustomRule,
  DeleteCustomRuleByIndex,
  CustomDeleteAllRules,
  ResetToOriginalRules,
  CustomRuleUpdate,

  CustomRuleConditionPlus,
  CustomRuleConditionRemoveByIndex,
  CustomRuleConditionUpdate,

  CustomRuleActionPlus,
  CustomRuleActionRemoveByIndex,
  CustomRuleActionUpdate
} from 'features/QutterRules';

const initialState = {};
const ParameterSlice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    ResetParameters (state, action) {
      const { parameters } = action.payload;

      if (parameters === null) {
        return undefined;
      }

      return {
        ...state,
        ...parameters
      };
    },

    // Evaluations
    editQutterOptionEvaluation: EditQutterOptionEvaluation,
    // Followups
    addFollowup: AddFollowup,
    restoreDefaultOptionFollowups: RestoreDefaultOptionFollowups,
    editDefaultOptionFollowups: EditDefaultOptionFollowups,
    removeOptionFollowup: RemoveOptionFollowup,
    removeFollowup: RemoveFollowup,

    // Text fields (intro, text, outro)
    templateTextCopy: TemplateTextCopy,
    textFieldResetToOriginal: TextFieldResetToOriginal,
    textFieldPlus: TextFieldPlus,
    textFieldMinus: TextFieldMinus,
    textFieldUpdate: TextFieldUpdate,

    // NEW Rules Functionality
    rulesCopy: RulesCopy,
    addCustomRule: AddCustomRule,
    deleteCustomRuleByIndex: DeleteCustomRuleByIndex,
    customDeleteAllRules: CustomDeleteAllRules,
    resetToOriginalRules: ResetToOriginalRules,
    customRuleUpdate: CustomRuleUpdate,

    customRuleConditionPlus: CustomRuleConditionPlus,
    customRuleConditionRemoveByIndex: CustomRuleConditionRemoveByIndex,
    customRuleConditionUpdate: CustomRuleConditionUpdate,

    customRuleActionPlus: CustomRuleActionPlus,
    customRuleActionRemoveByIndex: CustomRuleActionRemoveByIndex,
    customRuleActionUpdate: CustomRuleActionUpdate,
  }
});

export const {
  ResetParameters,

  // Evaluation
  editQutterOptionEvaluation,

  // Custom followups
  addFollowup,
  restoreDefaultOptionFollowups,
  editDefaultOptionFollowups,
  removeOptionFollowup,
  removeFollowup,

  // Text fields (intro, text, outro)
  templateTextCopy,
  textFieldResetToOriginal,
  textFieldPlus,
  textFieldMinus,
  textFieldUpdate,

  // NEW Rules Functionality
  rulesCopy,
  addCustomRule,
  deleteCustomRuleByIndex,
  customDeleteAllRules,
  resetToOriginalRules,
  customRuleUpdate,

  customRuleConditionPlus,
  customRuleConditionRemoveByIndex,
  customRuleConditionUpdate,

  customRuleActionPlus,
  customRuleActionRemoveByIndex,
  customRuleActionUpdate,
} = ParameterSlice.actions;
export default ParameterSlice.reducer;
