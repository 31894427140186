import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

function isObject (item) {
  return (typeof item === "object" && !Array.isArray(item) && item !== null);
};

export const HubertMessages = ({
  language,
  message,
  question,
  all_messages
}) => {
  // console.log(`<HubertMessages />`);

  return (
  <Card className='hubert-message rounded-2'>
    <Card.Body>
    {(() => {
      if (!message) {
        return (
          <p
            className="mb-0 d-block"
            dangerouslySetInnerHTML={{
              __html: (question && question[language] ? question[language].text.join(' ') : '')
            }}
        />)
      } else 
      {
        if (typeof(message.texts) === 'string') {
          return (<div>{message.texts}</div>);
        } else 
        if (Array.isArray(message.texts)) {
          return message.texts.map((msg, msg_idx) => {
            if (isObject(msg)) {
              return (
                <p
                  key={`msg-${msg_idx}`}
                  className="mb-0 d-block"
                  dangerouslySetInnerHTML={{
                    __html: (msg.text ? msg.text : (msg.payload ? msg.payload: ''))
                  }}
              />)
            }
            else {
              return (
                <p
                  key={`msg-${msg_idx}`}
                  className="mb-0 d-block"
                  dangerouslySetInnerHTML={{
                    __html: msg
                  }}
              />)
            }
          });
        } else
        {
          return (
            <p
              key={`msg-${msg_idx}`}
              className="mb-0 d-block"
              dangerouslySetInnerHTML={{
                __html: 'Error'
              }}
          />)
        }
      }
    })()}
    </Card.Body>
  </Card>
  );
};
