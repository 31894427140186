export const ANSWER_TAGS = {
  "PASSED" : {
    "description" : "The answer passed every test!"
  },
  "MISSING_DATA_COLLECTION" : {
      "description" : "The data generation process failed or did not start."
  },
  "IRREGULAR_FEATURES" : {
      "description" : "One or more features used to predict the score was out of the ordinary."
  },
  "INVALID_LANGUAGE" : {
      "description" : "The language of the answer is not permitted."
  },
  "DISALLOWED_INTENT" : {
      "description" : "The predicted intent of the answer is unusual and needs an extra eye."
  },
  "INVALID_SCORE" : {
      "description" : "The model predicted an invalid score."
  },
  "MISSING_SCORE" : {
      "description": "There is no model to predict a score."
  }
};

export const INTERVIEW_TAGS = {
  "PASSED" : {
    "description" : "The interview is cleared for auto-updating!"
  },
  "RANDOMLY_SELECTED_SHOWN_SCORES" : {
      "description" : "The interview was selected for manual inspection where model scores are shown."
  },
  "RANDOMLY_SELECTED_HIDDEN_SCORES" : {
      "description" : "The interview was selected for manual inspection where model scores are hidden."
  },
  "PRE_VALIDATION_FAILED" : {
      "description" : "The interview failed one or more pre-validations of answers."
  },
  "SCORE_VALIDATION_FAILED" : {
      "description" : "The interview contains one or more failed model predictions."
  },
  "VALIDATION_KEYS_NOT_MATCHING" : {
      "description" : "Tell Pontus if you see this as a rater pls. Validation steps are not using the same questions."
  },
  "INVALID_TOTAL_SCORE" : {
      "description" : "Tell Pontus if you see this as a rater pls. Admin returned an invalid total score."
  },
  "INSIDE_THRESHOLD_OFFSET" : {
      "description" : "The total score of the interview is inside the prohibited threshold range."
  },
  "INACTIVE_RULE" : {
      "description" : "Tell Pontus if you see this as a rater pls. The validation rule has been inactivated."
  },
  "BONUS_SCORE_MISSING" : {
      "description" : "The interview is missing a templated bonus score."
  },
  "BONUS_SCORE_FAIL": {
      "description" : "The interview failed to score a bonus question."
  },
  "THRESHOLD_MISSING": {
    "description" : "No threshold set for job."
  },
};