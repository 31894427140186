import React from 'react';
import { useQuery, gql } from '@apollo/client';

import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from './AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

const columns = [
  {
    accessor: 'org_name',
    Header: 'Org. Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, org_name, logo } = rowData.row.original;
      return (
        <Link to={`/clients/${id}`}>
          <Flex alignItems="center">
            {logo ? (
              <Avatar src={logo} name={org_name} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={org_name} className="me-2" />
            )}
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{org_name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'org_nr',
    Header: 'Org. Nr.',
    Cell: rowData => {
      const { org_nr } = rowData.row.original;
      return <a target='_blank' href={`https://www.allabolag.se/${org_nr.replace('-', '')}`} rel="noreferrer">{org_nr}</a>;
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: rowData => {
      const { email } = rowData.row.original;
      return <a href={`mailto:${email}`}>{email}</a>;
    }
  },
  {
    accessor: 'phone',
    Header: 'Phone',
    Cell: rowData => {
      const { phone } = rowData.row.original;
      return <a href={`tel:${phone}`}>{phone}</a>;
    }
  },
  {
    accessor: 'address',
    Header: 'Billing Address',
    headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
    cellProps: { className: 'ps-5' }
  },
  {
    accessor: 'created_at',
    Header: 'Created',
    Cell: rowData => {
      const { created_at } = rowData.row.original;
      return <span>{new Date(created_at).toISOString()}</span>;
    }
  },
  // {
  //   accessor: 'none',
  //   Header: '',
  //   disableSortBy: true,
  //   cellProps: {
  //     className: 'text-end'
  //   },
  //   Cell: () => {
  //     return (
  //       <CardDropdown iconClassName="fs--1">
  //         <div className="py-2">
  //           <Dropdown.Item href="#!">Edit</Dropdown.Item>
  //           <Dropdown.Item href="#!">Delete</Dropdown.Item>
  //         </div>
  //       </CardDropdown>
  //     );
  //   }
  // }
];

const GET_ALL_CLIENTS = gql`
  query GetAllClients {
    GetAllClients {
      id
      org_name
      org_nr
      created_at
    }
  }
`;

const ClientsList = () => {
  const { loading, error, data } = useQuery(GET_ALL_CLIENTS);
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data.GetAllClients}
      selection
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          {/* <ClientsTableHeader table /> */}
          <Row className="flex-end-center mb-2 mt-2">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table/>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default ClientsList;
