import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import { HubiverseTagCreate } from './HubiverseTagCreate';
import { HubiverseTagsTree } from './HubiverseTagsTree/HubiverseTagsTree';
import { HubiverseTag } from './HubiverseTag';

import { optionHubiverseTagsMinus, optionHubiverseTagsPlus } from 'features/Qutters';
import { updateFormByIndex } from 'features/Qutters';

import { get_tags_by_request } from '../api/api';

export const HubiverseTags = ({
  hubiverse_tags=[],
  qutter_id,
  option_id,
  form_id,

  limited=false,
  set_result,
}) => {
  const dispatch = useDispatch();
  const library = useSelector(state => state.Library);

  // console.log('hubiverse_tags', hubiverse_tags);
  // console.log('HubiverseTags qutter_id', qutter_id);
  // console.log('HubiverseTags option_id', option_id);

  const [selectedItems, setSelectedItems] = useState(hubiverse_tags);
  const [tags_list, set_tags_list] = useState([]);
  const [map, set_map] = useState({});
  const [root_tags, set_root_tags] = useState([]);

  const [current, set_current] = useState(null);
  const [error, set_error] = useState(null);
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    get_tags_by_request('all')
    .then((result) => {
      console.log('get_tags_by_request', result);
      set_map(result.map);
      set_root_tags(result.root);
      set_loading(false);
    }).catch((error) => {
      set_error(error);
      set_loading(false);
    });
  }, []);

  useEffect(() => {
    let list = [];
    for (const key in map) {
      list.push({ label: map[key].name, value: key });
    }
    set_tags_list(list);
  }, [map]);

  useEffect(() => {
    if (set_result) {
      set_result(selectedItems);
    }
  }, [selectedItems]);

  if (loading) { return (<span>Loading tags...</span>); }
  if (error) { return (<span>Error: {error.toString()}</span>); }

  // console.log('tags_list', tags_list)
  // console.log('map', map)
  // console.log('root_tags', root_tags)

  if (limited) {
    return (
    <div
      style={{
        overflow: 'auto',
        maxHeight: '60%',
        minHeight: '60%',
        height: '60%'
      }}
    >
      <HubiverseTagsTree
        // data={tags_tree}
        map={map}
        root_tags={root_tags}
        selection
        // defaultSelected={["Enb42xON3LQSBTeqE2qOg"]}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        expanded={hubiverse_tags}

        on_tag_click={(clicked_item) => {
          set_current(clicked_item);
        }}

        on_tag_check={(checked_item, is_checked) => {
          console.log('checked_item', checked_item, is_checked)
          set_current(checked_item);
        }}

        qutter_id={qutter_id}
        option_id={option_id}
      />
    </div>
    )
  }

  return (
  <Container
    style={{
      minHeight: '100%',
      maxHeight: '100%'
    }}
  >
    <Row>
      <Col xs={4}
        style={{
          minHeight: '100vh',
          maxHeight: '100vh',
          height: '100vh'
        }}
      >

        <div 
          style={{ 
            overflow: 'auto', 
            maxHeight: '60%', 
            minHeight: '60%', 
            height: '60%' 
          }}
        >
          <HubiverseTagsTree
            // data={tags_tree}
            map={map}
            root_tags={root_tags}
            selection
            // defaultSelected={["Enb42xON3LQSBTeqE2qOg"]}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            expanded={hubiverse_tags}

            on_tag_click={(clicked_item) => {
              set_current(clicked_item);
            }}

            on_tag_check={(checked_item, is_checked) => {
              console.log('checked_item', checked_item, is_checked)
              set_current(checked_item);

              if (qutter_id) {
                const qutter_config = library[qutter_id];
                if (qutter_config.type === 'single-select') {
                  if (!option_id) return;
                  const qutter = qutter_config[qutter_config.language.current];
                  if (!qutter) return;
                  const option = qutter.options.find(o => o.id === option_id);
                  if (!option) return;

                  const obj = {
                    id: qutter_id,
                    option_id,
                    hubiverse_tag_id: checked_item.id
                  }

                  if (is_checked) {
                    dispatch(optionHubiverseTagsPlus(obj));
                  } else {
                    dispatch(optionHubiverseTagsMinus(obj));
                  }

                } else if (qutter_config.type === 'form') {
                  if (!form_id) return;
                  const qutter = qutter_config[qutter_config.language.current];
                  if (!qutter) return;
                  const form_idx = qutter.forms.findIndex(o => o.id === form_id);
                  if (form_idx === -1) {
                    return;
                  }
                  const form = qutter.forms[form_idx];
                  if (!form) return;

                  let temp = Object.assign({}, form);
                  if (is_checked) {
                    if (temp.hasOwnProperty('hubiverse_tags')) {
                      const idx = temp.hubiverse_tags.findIndex(t => t.id === checked_item.id);
                      if (idx === -1) {
                        let arr = Object.assign([], temp.hubiverse_tags);
                        arr.push(checked_item.id);
                        temp.hubiverse_tags = arr;
                      }
                    } else {
                      temp.hubiverse_tags = [checked_item.id];
                    }
                  } else {
                    if (temp.hasOwnProperty('hubiverse_tags') && temp.hubiverse_tags.length > 0) {
                      let arr = Object.assign([], temp.hubiverse_tags);
                      const idx = arr.findIndex(t => t === checked_item.id);
                      if (idx > -1) {
                        arr.splice(idx, 1);
                        temp.hubiverse_tags = arr;
                      }
                    }
                  }
                  dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
                }
              }
            }}

            qutter_id={qutter_id}
            option_id={option_id}
          />
        </div>

        <HubiverseTagCreate
          root_tags={root_tags}
          set_root_tags={set_root_tags}
          map={map}
          set_map={set_map}
        />

      </Col>

      <Col
        style={{
          minHeight: '100vh',
          maxHeight: '100vh',
          height: '100vh',
          // overflow: 'auto', maxHeight: '70%', minHeight: '70%', height: '70%'
        }}
      >
        <HubiverseTag
          tag={current}
          set_current={set_current}
          root_tags={root_tags}
          set_root_tags={set_root_tags}
          map={map}
          set_map={set_map}
        />
      </Col>
    </Row>
  </Container>
  )
};
