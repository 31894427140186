import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Card, Form, FloatingLabel } from 'react-bootstrap';

import {
  toggleBlock,
  removeBlock,
  editBlockName
} from 'features/Template/slices/TemplateSlice';

export const TemplateBlockHeader = ({ template_id, block_id, block_idx }) => {
  const dispatch = useDispatch();
  const block = useSelector(
    state => state.Template[template_id].blocks.map[block_id]
  );
  const templateConfig = useSelector(state => state.Template);
  const is_editable_block = !['start', 'end'].includes(block_id);
  const [name, set_name] = useState(block.name);
  const [is_edit_mode, set_is_edit_mode] = useState(false);

  const onBlockHeaderClick = () => {
    dispatch(toggleBlock({ id: template_id, block_id: block_id }));
  };

  const handleChange = async e => {
    dispatch(
      editBlockName({
        id: template_id,
        block_id,
        name: e.target.value
      })
    );
  };

  const finnish = () => {
    set_is_edit_mode(false);
    toast.success(`Block name edited to "${name}".`);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') finnish();
    else if (event.key === 'Escape') {
      set_is_edit_mode(false);
    }
  };

  return (
    <Card.Header className="template-block-header-container">
      <div className="d-flex font-size-10">
        <div className="w-75 text-start">
          {is_edit_mode ? (
            <FloatingLabel
              controlId="inputInternal"
              label="Block name - Press enter to finnish, Esc to cancel."
              className="mb-3"
            >
              <Form.Control
                placeholder="Bla bla"
                defaultValue={name}
                onChange={e => handleChange(e)}
                onKeyDown={handleKeyDown}
              />
            </FloatingLabel>
          ) : (
            <span
              onClick={onBlockHeaderClick}
              className="template-block-header"
            >
              {block.name} ({block.nodes.length})
            </span>
          )}
        </div>
        {templateConfig.readOnlyMode ? (null):
        (
        <div className="w-25 text-end">
          <span
            onClick={() => {
              set_is_edit_mode(true);
            }}
            className="hover-blue me-2"
          >
            <FontAwesomeIcon icon={faPen} />
          </span>

          {is_editable_block && (
            <span
              onClick={() => {
                dispatch(
                  removeBlock({ id: template_id, block_id: block_id })
                );
              }}
              className="hover-blue"
            >
              <FontAwesomeIcon icon={faTrash} />
            </span>
          )}
        </div>
        )}
      </div>
    </Card.Header>
  );
};
