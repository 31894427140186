import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { add_qutter_to_template_summary_list } from '..';

import { outline_counter } from '../utils/outline_counter';

export const TemplateOutlineCounter = ({
  template_id,
  qutter_id,
  node,
  node_idx,
  is_followup
}) => {
  const dispatch = useDispatch();
  const library = useSelector(state => state.Library);
  const template = useSelector(state => state.Template[template_id]);
  const parameters = useSelector(state => state.Parameters[qutter_id]);
  const evaluation_state = useSelector(state => state.Evaluation[qutter_id]);

  useEffect(() => {
    if (!library) return;
    if (!template) return;

    outline_counter(
      dispatch,
      library,
      template,
      parameters,
      node,
      is_followup
    );

  }, [template, parameters]);

  useEffect(() => {
    if (!template) return;
    const languages = template.language_settings.list;

    if (node.type === 'question') {
      dispatch(add_qutter_to_template_summary_list({ languages, id: node.id, type: 'question' }));
    } else if (node.type === 'action') {
      dispatch(add_qutter_to_template_summary_list({ languages, id: node.id, type: 'action' }));
    }

  }, [template]);

  return (<></>);
};
