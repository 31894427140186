import { Events, scroller } from "react-scroll";

import {
  Tooltip
} from 'react-bootstrap';

export const scrollToWithContainer = (id) => {
  let goToContainer = new Promise((resolve, reject) => {
    Events.scrollEvent.register("end", () => {
      resolve();
      Events.scrollEvent.remove("end");
    });

    scroller.scrollTo('message-container', {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  });

  goToContainer.then(() =>
    scroller.scrollTo(`${id}`, {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "message-container",
      offset: -250
    })
  );
};

export const buildMarks = (min, max) => {
  let arr = [];
  for (let i = parseInt(min); i <= parseInt(max); i++) arr.push(i);
  return arr.map((i, idx) => {
    if (i === parseInt(min)) {
      return {
        style: { color: 'red', fontSize: '7px' },
        label: <strong>{parseInt(i)}</strong>
      };
    }
    else
    if (i === parseInt(max)) {
      return {
        style: { color: 'green', fontSize: '7px' },
        label: <strong>{parseInt(i)}</strong>
      };
    } else {
      return {
        style: { fontSize: '7px' },
        label: <span>{parseInt(i)}</span>
      };
    }
  });
}

export const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props.description}
  </Tooltip>
);

export const compare = (a, b) => {
  if ( a.score > b.score ) return -1;
  if ( a.score < b.score ) return 1;
  return 0;
}