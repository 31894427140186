import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { ScoringCategory } from './ScoringCategory';
import { ScoringCategoriesTotalWeight } from './ScoringCategoriesTotalWeight';
import { WeightsInfoIconHigh, WeightsInfoIconLow } from './icons/icons';

export const ScoringCategoriesOutline = ({
  template_id,
  scoring_categories,
  loading
}) => {
  const [total_weight, set_total_weight] = useState(0);

  if (loading) return (<span>Counting categories...</span>);
  if (!scoring_categories) return (<span>scoring_categories error...</span>);

  if (scoring_categories.list.length === 0) {
    return (
      <span
        style={{ fontSize: '10px', fontWeight: 900 }}
      >
        No scoring categories yet.
      </span>
    );
  }

  return (
  <div>
    <span className='d-block' style={{ fontSize: '10px', fontWeight: 900 }}>
      Scoring categories:
    </span>
    {scoring_categories.list.map((category_id, idx) => {
      let category = scoring_categories[category_id];
      return (
        <ScoringCategory
          key={`scoring-category-${idx}`}
          template_id={template_id}
          category_id={category_id}
          category={category}
          category_idx={idx}
        />
      );
    })}

    <ScoringCategoriesTotalWeight
      template_id={template_id}
      scoring_categories={scoring_categories}
      loading={loading}
    />

  </div>
  );
};
