import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const TemplateLanguageInfo = ({}) => {

  return (
    <OverlayTrigger
      placement='top'
      overlay={
      <Tooltip id="button-tooltip-2">
        Available interview Languages.
      </Tooltip>
      }
    >
    {({ ref, ...triggerHandler }) => (
      <span
        ref={ref}
        {...triggerHandler}
        onClick={() => { 
          // set_rules_modal_show(!rules_modal_show) 
        }}
        className={`me-1 ms-1 font-size-10`}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
    )}
    </OverlayTrigger>
  );
};