import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { JsonPreview } from 'features/common';
import { TemplateBlock } from '..';
import { CreateTemplateBlock } from '..';

import {
  removeFromBlockAtIndex,
  addToBlockAtIndex
} from 'features/Template/slices/TemplateSlice';

export const TemplateBlocks = ({ id }) => {
  const dispatch = useDispatch();
  const templateConfig = useSelector(state => state.Template);
  const readOnly = templateConfig.readOnlyMode;
  const template = templateConfig[id];
  const filtered = useSelector(state => state.Template.filtered);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) { return; }

    const source_block = result.source.droppableId; // Block id
    const source_idx = result.source.index;

    const destination_block = result.destination.droppableId;
    const destination_idx = result.destination.index;

    const draggable_id = result.draggableId;

    dispatch(removeFromBlockAtIndex({
      template_id: id,
      block_id: source_block,
      idx: source_idx
    }));
    dispatch(addToBlockAtIndex({
      template_id: id,
      block_id: destination_block,
      idx: destination_idx,
      qutter_id: draggable_id
    }));
  };

  if (filtered.length === 0) {
    return (
      <>
        <DragDropContext
          onDragEnd={onDragEnd}
        >
        {template && template.blocks.ids.map((block_id, idx) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <span key={`template-block-container-${block_id}-${idx}`}>
              <TemplateBlock
                key={`template-block-${block_id}-${idx}`}
                template_id={id}
                block_idx={idx}
                block_id={block_id}
                is_last_block={idx === (template.blocks.ids.length - 1)}
                filtered={filtered}
              />

              {idx < (template.blocks.ids.length - 1) && (
                <CreateTemplateBlock
                  key={`create-template-block-${block_id}-${idx}`}
                  template_id={id}
                  block_id={block_id}
                  block_idx={idx}
                />
              )}
            </span>
          );
        })}
        </DragDropContext>
        {readOnly ? (null):(
          <JsonPreview data={template} />
        )}
      </>
      );
  } else {

    let block_ids = [];
    template.blocks.ids.map((block_id, idx) => {
      const block = template.blocks.map[block_id];
      block.nodes.map((id, idx) => {
        if (filtered.includes(id)) block_ids.push(block_id);
      });
    });

    return (
      <>
      <DragDropContext
        onDragEnd={onDragEnd}
      >
      {block_ids.map((block_id, idx) => {
        return (
          // eslint-disable-next-line react/jsx-key
          <span key={`template-block-container-${block_id}-${idx}`}>
            <TemplateBlock
              key={`template-block-${block_id}-${idx}`}
              template_id={id}
              block_idx={idx}
              block_id={block_id}
              is_last_block={idx === (template.blocks.ids.length - 1)}
              filtered={filtered}
            />
    
            {idx < (template.blocks.ids.length - 1) && (
            <CreateTemplateBlock
              key={`create-template-block-${block_id}-${idx}`}
              template_id={id}
              block_id={block_id}
              block_idx={idx}
            />
            )}
          </span>
        );
      })}
      </DragDropContext>
      {readOnly ? (null):(
        <JsonPreview data={template} />
      )}
      </>
    );
  }
};
