import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Select from 'react-select';

import { OPERATORS_SELECT_LIST, then_types } from '../../constants/condition_types';
import { findOptionValue } from 'features/common';

import { QutterAnswerSelect } from 'features/QutterAnswerSelect';
import { RuleConditionIfAnswerSelects } from './RuleConditionIfAnswerSelects';
import { RuleConditionIfAnswerForm } from './RuleConditionIfAnswerForm';

import { ruleConditionUpdate } from 'features/Qutters';
import { customRuleConditionUpdate } from 'features/Parameters';

export const RuleConditionIfAnswer = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  condition_idx,
  condition,

  on_select,
  selected
}) => {
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  console.log('condition', condition)
  console.log('qutter', qutter)

  /**
   * single-select, multiple-select
   * open
   * calendar
   * form
   *    text
   *    email
   *    number
   *    slider
   *    checkbox
   *    range 
   *    matrix
   */

  return (
  <div
    className='m-1 p-2 mb-2'
  >
    <div className='d-flex font-size-10 m-1'>
      <div className='w-25 text-start m-auto'>
        If answer:
      </div>
      <div className='w-50 text-start'>
        <Select
          defaultValue={condition ? findOptionValue(OPERATORS_SELECT_LIST, condition.operator): null}
          isDisabled={template_id && is_default ? true: false}
          placeholder='Choose operator'
          isMulti={false}
          options={OPERATORS_SELECT_LIST}
          onChange={(e) => {
            let cond = Object.assign({}, condition);
            cond.operator = e.value;
            if (template_id) {
              dispatch(customRuleConditionUpdate({
                template_id,
                qutter_id,
                rule_idx, 
                condition_idx,
                condition: cond
              }))
            } else {
              dispatch(ruleConditionUpdate({
                qutter_id,
                rule_idx, 
                condition_idx,
                condition: cond
              }))
            }
          }}
        />
      </div>
    </div>

    <div className='d-flex font-size-10 m-1'>
      <div className='w-25 text-start m-auto'>
        Answers:
      </div>
      <div className='w-50 text-start'>

        {(() => {
          if (['single-select', 'multiple-select'].includes(qutter_config.type)) {
            return (
              <RuleConditionIfAnswerSelects
                template_id={template_id}
                qutter_id={qutter_id}
                is_default={is_default}
                rule_idx={rule_idx}
                condition_idx={condition_idx}
                condition={condition}
              />
            );
          } else 
          if (qutter_config.type === 'form') {
            return (
              <RuleConditionIfAnswerForm 
                template_id={template_id}
                qutter_id={qutter_id}
                is_default={is_default}
                rule_idx={rule_idx}
                condition_idx={condition_idx}
                condition={condition}
              />
            );
          }
        })()}

      </div>
    </div>

  </div>
  );
};