import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPlusCircle, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

import { HubiverseTagsSelect } from './HubiverseTagsSelect';

import {
  update_tag,
  delete_tag_by_id
} from '../api/api';

export const HubiverseTag = ({
  tag,
  set_current,
  root_tags,
  set_root_tags,
  map,
  set_map
}) => {
  console.log(`<HubiverseTag />`, tag);
  // if (loading) { return (<span>Loading tag...</span>); }
  // if (error) { return (<span>Error: {error}</span>); }

  const [list, set_list] = useState([]);
  const [parents, set_parents] = useState([]);
  const [name, set_name] = useState('');
  const [description, set_description] = useState('');

  useEffect(() => {
    if (!tag || !tag.id) return;
    let _list = [];
    let _parents = [];
    for (const key in map) {
      const item = map[key];
      if (key !== tag.id) {
        _list.push({ label: item.name, value: key });

        if (item.children.includes(tag.id)) {
          _parents.push(key);
        }
      }
    }

    set_list(_list);
    set_parents(_parents);
    set_description(tag.description)
  }, [map, tag]);

  useEffect(() => {
    if (tag) {
      set_name(tag.name);
      set_description(tag.description);
    }
  }, [tag]);

  const on_delete_tag_click = (e) => {
    delete_tag_by_id(tag.id)
    .then(r => {
      let copy = Object.assign({}, map);
      delete copy[tag.id];
      for (const key in map) {
        if (key !== tag.id) {
          const item = map[key];

          if (item.children.includes(tag.id)) {
            const idx = item.children.findIndex(c => c === tag.id);
            if (idx > -1) {
              item.children.splice(idx, 1);
              update_tag(item);
            }
          }
        }
      }

      let root_copy = Object.assign([], root_tags);
      const index = root_copy.indexOf(tag.id);
      if (index > -1) {
        root_copy.splice(index, 1);
      }

      set_map(copy);
      set_root_tags(root_copy);
      set_current(null);
      toast.success(`Tag delete ok.`);
    })
    .catch(e => {
      toast.error(`Failed to delete tag.`);
      console.log(`Failed to delete tag. Error:`, e);
    });
  };

  const on_edit_name = (e) => {
    for (const key in map) {
      if (key !== tag.id) continue;
      let copy = Object.assign({}, map);
      copy[key].name = name;
      update_tag(copy[key])
      .then((res) => {
        console.log(`Updating copy:`, copy);
        set_map(copy);
        toast.success(`Tag name update ok.`);
      })
      .catch((error) => {
        toast.error(`Failed to update tag name.`, error);
      });
    }
  };

  const on_edit_description = (e) => {
    for (const key in map) {
      if (key !== tag.id) continue;
      let copy = Object.assign({}, map);
      copy[key].description = description;
      update_tag(copy[key])
      .then((res) => {
        console.log(`Updating copy:`, copy);
        set_map(copy);
        toast.success(`Tag description update ok.`);
      })
      .catch((error) => {
        toast.error(`Failed to update tag description.`, error);
      });
    }
  };

  if (!tag) { return (null); }

  console.log('parents', parents)

  return (
    <div
      style={{}}
    >

      <div className='font-size-10 float-end d-grid'>
        <div
          className="hover-blue float-end"
          onClick={on_delete_tag_click}
        >
          <FontAwesomeIcon icon={faCircleXmark} className='me-1' />
          Delete tag
        </div>
        <div className=''>{tag.id}</div>
      </div>

      <div>
        <Form.Group className="mb-3">
          <Form.Label>Name (enter to save)</Form.Label>
          <Form.Control
            type="text"
            placeholder='Enter a tag name'
            value={name}
            onChange={(e) => {
              set_name(e.target.value);
            }}
            onKeyPress={event => {
              if (event.key === "Enter") {
                on_edit_name();
              }
            }}
          />
        </Form.Group>
      </div>

      <div>
        <Form.Group className="mb-3">
          <Form.Label>Description (enter to save)</Form.Label>
          <Form.Control
            as="textarea"
            placeholder='Enter a tag description'
            value={description}
            onChange={(e) => {
              set_description(e.target.value);
            }}
            onKeyPress={event => {
              if (event.key === "Enter") {
                on_edit_description();
              }
            }}
          />
        </Form.Group>
      </div>

      <Form.Group className="mt-3 mb-3">
        <Form.Label>Parents</Form.Label>
        <HubiverseTagsSelect
          multi={true}
          list={list}
          selected={parents}
          on_select={(e) => {
            let map_copy  = Object.assign({}, map);
            let root_copy = Object.assign([], root_tags);

            if (e.length === 0) {
              for (const key in map) {
                if (key !== tag.id) {
                  const item = map[key];

                  if (item.children.includes(tag.id)) {
                    const idx = item.children.findIndex(c => c === tag.id);
                    if (idx > -1) {
                      item.children.splice(idx, 1);
                      update_tag(item)
                      .then((r) => {
                        toast.success(`Tag update ok.`);
                      })
                      .catch((e) => {
                        toast.error(`Failed to update tag.`);
                        console.log(`Failed to update tag. Error:`, e);
                      });
                    }
                  }
                }
              }

              if (!root_copy.includes(tag.id))
                root_copy.push(tag.id)

            } else {
              for (let i = 0; i < e.length; i++) {
                let selected = e[i]
                let parent = map_copy[selected.value];
                let dis = map_copy[tag.id];

                if (!parent.children.includes(tag.id)) {
                  parent.children.push(tag.id);
                  update_tag(parent)
                  .then((r) => {
                    toast.success(`Tag update ok.`);
                  })
                  .catch((e) => {
                    toast.error(`Failed to update tag.`);
                    console.log(`Failed to update tag. Error:`, e);
                  });
                }

                if (dis.is_root) {
                  dis.is_root = false;
                  const index = root_copy.indexOf(tag.id);
                  if (index > -1) {
                    root_copy.splice(index, 1);
                    update_tag(dis)
                    .then((r) => {
                      toast.success(`Tag update ok.`);
                    })
                    .catch((e) => {
                      toast.error(`Failed to update tag.`);
                      console.log(`Failed to update tag. Error:`, e);
                    });
                  }
                }
              }
            }

            set_map(map_copy);
            set_root_tags(root_copy);
          }}
        />
      </Form.Group> 

    </div>
  );
};
