import React from 'react';
import SoftBadge from '_components/common/SoftBadge';

export const ApplicationScore = ({
  application_id,
  interview_id,
  score
}) => {
  console.log('<ApplicationScore />', score);

  return (
    <>
      {(score || score === 0) ? (
        <SoftBadge>{score.toFixed(1)}</SoftBadge>
      ): (
        <span>{'No score yet.'}</span>
      )}
    </>
  );
};
