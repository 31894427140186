import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import Logo from '_components/common/Logo';
import { topNavbarBreakpoint } from '../../../config';
import { TopNavRightSideNavItem } from './TopNavRightSideNavItem';

export const NavbarTop = () => {
  const { pathname } = useLocation();

  return (
    <Navbar
      className={classNames('navbar-glass fs--1 sticky-kit', {})}
    >

      <Logo at="navbar-top" width={40} id="topLogo" />

      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav className="me-auto my-2 my-lg-0">

          <Nav.Link as={Link} to="/clients">Clients</Nav.Link>
          <Nav.Link as={Link} to="/jobs">Jobs</Nav.Link>
          <Nav.Link as={Link} to="/candidates">Candidates</Nav.Link>
          <Nav.Link as={Link} to="/templates">Templates</Nav.Link>
          <Nav.Link as={Link} to="/questions">Questions</Nav.Link>
          <Nav.Link as={Link} to="/parser">Parser</Nav.Link>

        </Nav>
      </Navbar.Collapse>
      <TopNavRightSideNavItem />

    </Navbar>
  );
};
