import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/pro-solid-svg-icons';

import { InterviewActionsSelect } from 'features/HubertActions';

export const NodeAction = ({
  node,
  action,
  action_idx,
  set_node
}) => {
  const dispatch = useDispatch();

  return (
    <tr>
      <td>
        <InterviewActionsSelect
          selected={action.value}
          on_select={(e) => {
            const copy = Object.assign({}, node);
            node.actions[action_idx].value = e.value;
            set_node(copy);
          }}
        />
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <span
          onClick={(e) => {
            const copy = Object.assign({}, node);
            node.actions.splice(action_idx, 1);
            set_node(copy);
          }}
        >
          <FontAwesomeIcon
            className='hover-blue'
            style={{ color: 'red' }} 
            icon={faSquareXmark}
          />
        </span>
      </td>
    </tr>
  );
};
