
export const prepare_condition_option_label = async (
  config, 
  value, 
  language_list
) => {
  let labels = [];
  if (!config) return;
  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      for (let j = 0; j < language_list.length; j++) {

        let language = language_list[j];
        if (!config.available_languages.includes(language)) {
          labels.push(`Missing language (${language})`);
          continue;
        }
        let qutter = config[language];
        const options = qutter.options;
        for (let k = 0; k < options.length; k++) {
          const option = options[k];
          if (option.id === value[i]) labels.push(option.label);
        }
      }
    }
    return labels;
  } else if (typeof value === 'string') {

    for (let i = 0; i < language_list.length; i++) {
      let language = language_list[i];
      if (!config.available_languages.includes(language)) {
        labels.push(`Missing language (${language})`);
        continue;
      }
      let qutter = config[language];
      const options = qutter.options;
      for (let j = 0; j < options.length; j++) {
        const option = options[j];
        if (option.id === value) labels.push(option.label);
      }
    }
    return labels;

  } else {
    return ['Error'];
  }
};


export const array_move = async (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};