import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import { Badge, Table, Offcanvas, OverlayTrigger, Popover, } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from '_components/common/SoftBadge';

import { getIcon } from './icon';
import { CandidateHeader } from 'features/Candidate';

import { 
  ApplicationQualified,
  ApplicationScore,
  ApplicationStatus,
  ApplicationStage,
  ApplicationAccepted,
  ApplicationActivityLog
} from 'features/Application';

import InterviewMetaData from '_components/interviews/InterviewMetaData';

const QUERY_GET_SUMMARY_BY_ID = gql`
query GetSummaryById($summary_id: String!) {
  GetSummaryById(summary_id: $summary_id) {
    id
    threshold
    created_at
    job_id
    application_id
    interview_id

    summary {
      details {
        label
        answer
        warning
        symbol
        icon
        question
        points
        bonuspoints
        qualified
        isCorrect
        options {
          id
          label
          evaluation {
            name
            bonus_points
          }
          points
          isCorrect
          answered
        }
      }
      header
      icon
      bonuspoints
      average
      maxPoints
      threshold
      points
    }
    candidate {
      firstName
      lastName
      email
    }
    interview {
      meta {
        device
        is_mobile
        browserName
        appName
        OS
      }
      data_privacy_agreement {
        agreed
        created_at
      }
    }
    application {
      score
      qualified
      activityLog {
        createdAt
        createdBy
        status
        actionType
        message
      }
      accepted
      stage
      status
    }
    manual_score {
      tagged_by
      score_time
      score_history {
        date
        tagged_by
        manually_scored
        auto_updated
      }
      threshold
    }
  }
}
`;

const SummaryAnswer = ({ detail, answer }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  if (detail.options && detail.options.length) {
    return (
      <td className="border ">
        {detail.options.map((option, index) => {
          console.log('option', option)
          if (option.evaluation) {
            const temp = { icon: option.evaluation.name, bonuspoints: option.evaluation.bonus_points }
            console.log('temp', temp)
            return (
              <div>
                <span
                  className={`${open ? 'answerOpen' : 'answerClose'} summaryAnswer`}
                  dangerouslySetInnerHTML={{ __html: option.label }}
                />
                {getIcon(temp, 'list')}
              </div>
            )
          } else {
            let classValue = option.answered ? '' : 'outline-';
            classValue += option.isCorrect ? 'success' : 'danger';
            return (
              <Badge
                key={`summary-test-option-${index}`}
                variant={classValue}
                className="m-2 answerButton"
              >
                {option.label}
              </Badge>
            );
          }
        })}
      </td>
    );
  }

  return (
    <td className="border" onClick={handleClick}>
      <div
        className={`${open ? 'answerOpen' : 'answerClose'} summaryAnswer`}
        dangerouslySetInnerHTML={{ __html: answer ? answer.replace(/^<br>/, '') : '' }}
      ></div>
    </td>
  );
};

const QuestionPopover = ({label, question}) => {
  // console.log('<QuestionPopover />', question);

  return (
    <Popover className={question.length > 300 ? 'popup large' : 'popup small'}>
      <Popover.Body>
        <div className="popup title">Question</div>
        <div
          dangerouslySetInnerHTML={{
            // __html: question ? question.replace(/^<br>/, '') : label.replace(/^<br>/, ''),
            __html: question,
          }}
        ></div>
      </Popover.Body>
    </Popover>
  );
};

const SummaryDetail = ({
  idx,
  detail
}) => {
  // console.log('<SummaryDetail />', detail);

  const answer = detail.answer;
  // const bonuspoints = detail.bonuspoints;
  // const isCorrect = detail.isCorrect;
  const label = detail.label;
  // const options = detail.options;
  // const points = detail.points;
  const question = detail.question;

  return (
  <tr key={idx} className="border">
    <td className="text-center summery-icon">{getIcon(detail, 'list')}</td>
    <OverlayTrigger 
      trigger={["click"]} 
      placement="top" 
      overlay={QuestionPopover({label, question})}>
      <td>{label}</td>
    </OverlayTrigger>
    <SummaryAnswer detail={detail} answer={answer} />
  </tr>
  );
};

const SummaryPart = ({
  idx,
  part
}) => {
  // console.log('<SummaryPart />', part);

  return (
    <Table borderless size="sm" hover className="">
      <thead>
        <tr>
          <th colSpan="2" className={'pl-0'}>
            <h5>
              {part.header}
              {part && part.points ? (
                <small className="float-right">
                  ({part.points.toFixed(0)}/{part.maxPoints}) Threshold: {part.threshold}
                </small>
              ) : (
                null
              )}
            </h5>
          </th>
        </tr>
      </thead>
      <tbody>
      {part && part.details && part.details.length ? (
        <>
          {part.details.map((detail, idx) => {
            return (
              <SummaryDetail
                key={`summary-detail-${idx}`}
                idx={idx}
                detail={detail}
              />
            );
          })}
        </>
      ):(
        <span>Error: Could not load the summary part.</span>
      )}
      </tbody>
    </Table>
  );
};

const Summary = ({
  job_id,
  application_id,
  interview_id,
  summary_id,
  show,
  setShow
}) => {
  // console.log('<Summary />', summary_id);
  const { loading, error, data } = useQuery(
    QUERY_GET_SUMMARY_BY_ID,
    { variables: { summary_id: summary_id }}
  );

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  // console.log('<Summary data />', data.GetSummaryById);

  if (!data.GetSummaryById || !data.GetSummaryById.summary) {
    return (<span>Could not load the summary.</span>);
  }

  const summary = data.GetSummaryById.summary;
  const candidate = data.GetSummaryById.candidate;
  const application = data.GetSummaryById.application;
  const interview = data.GetSummaryById.interview;
  const ms = data.GetSummaryById.manual_score;

  return (
    data && data.GetSummaryById && data.GetSummaryById.summary ? (
      <>
        <CandidateHeader candidate={candidate} />
        <ApplicationActivityLog activityLog={application.activityLog} />
        <InterviewMetaData meta={interview.meta} />

        <div>
          <span>Accepted Lead: </span> 
          <ApplicationAccepted accepted={application.accepted} />
        </div>

        <div>
          <span>Qualified: </span>
          <ApplicationQualified qualified={application.qualified} />
        </div>

        <div>
          <span>Score: </span>
          <ApplicationScore score={application.score} />
        </div>

        <div>
          <span>Stage: </span>
          <ApplicationStage stage={application.stage} />
        </div>

        <div>
          <span>Status: </span>
          <ApplicationStatus status={application.status} />
        </div>

        {summary && summary.length && summary.map((part, idx) => {
          return (
            <SummaryPart
              key={`summary-part-${idx}`}
              part={part}
            />
          );
        })}
      </>
    ):(
      <span>Could not load the summary.</span>
    )
  );
};

const SummaryCanvas = ({
  job_id,
  application_id,
  interview_id,
  summary_id,
  show,
  setShow
}) => {
  // console.log('<Summary />', summary_id, application_id);

  return (
    <Offcanvas
      onClick={(e) => { e.stopPropagation(); }}
      show={show} 
      scroll={true} 
      backdrop={true}
      data-bs-keyboard="false"
      className='w-75'
    >
      <Offcanvas.Header>
        <Offcanvas.Title className='w-100'>
          <span className='h-5'>Summary</span>
          <Link className='float-end' key={`interview-score-link`} target='_blank' to={`/score/${interview_id}`}>
            <Flex alignItems="center">
              <SoftBadge>
                Score
              </SoftBadge>
            </Flex>
          </Link>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>

        <Summary
          job_id={job_id}
          application_id={application_id}
          interview_id={interview_id}
          summary_id={summary_id}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const SummaryCanvasLink = ({
  job_id,
  application_id,
  interview_id,
  summary_id,
  showSummary,
  setShowSummary
}) => {
  // console.log('<SummaryCanvasLink />', application_id);
  const [show, setShow] = useState(false);

  const onSummaryClick = () => {
    if (showSummary !== undefined && setShowSummary !== undefined)
      setShowSummary(!showSummary);
    else setShow(!show)
  }

  if (!summary_id) {
    return (
      <span variant="hubert" className='btn-sm red'>
        Summary Not Available
      </span>
    );
  }

  return (
  <Badge
    onClick={onSummaryClick}
    className='cursor-pointer'
  >
    Summary
    <SummaryCanvas
      job_id={job_id}
      application_id={application_id}
      interview_id={interview_id}
      summary_id={summary_id}
      show={showSummary !== undefined ? showSummary: show}
      setShow={setShowSummary}
    />
  </Badge>
  );
};

export default SummaryCanvasLink;

