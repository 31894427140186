import React from 'react';
import { useDispatch } from 'react-redux';

import { customRuleConditionRemoveByIndex } from 'features/Parameters';
import { ruleConditionRemoveByIndex } from 'features/Qutters';

import {
  DeleteRuleConditionButton,
  RuleConditionIfAnswer,
  RuleConditionShowIf
} from '../..';

export const RuleCondition = ({
  template_id,
  qutter_id,
  rule_idx,
  condition_idx,
  condition,
  rule_type,
  is_default
}) => {
  const dispatch = useDispatch();

  return (
  <div
    className='m-1 p-2 mb-2'
    style={{ border: '1px solid lightgray', borderRadius: '3px' }}
  >

    <div className='d-flex m-2'>

      <div className='w-75 text-start m-auto'>
        {template_id ? (
          rule_type === 'SHOW_THIS_IF' ? (
            <div className=''>
              <strong>Show question</strong> only if the following <strong>conditions is met</strong>:
            </div>
          ):(
            <div className=''>
              <strong>If question answer</strong> then <strong>choose action that happens</strong>:
            </div>
          )
        ) : (
          <div className=''>
            <strong>If question answer</strong> then <strong>choose action that happens</strong>:
          </div>
        )}
      </div>

      <div className='w-25 text-end'>
        <DeleteRuleConditionButton
          template_id={template_id}
          is_default={is_default}
          on_click={() => {
            if (template_id) {
              dispatch(customRuleConditionRemoveByIndex({
                template_id,
                qutter_id,
                rule_idx,
                condition_idx
              }));
            } else {
              dispatch(ruleConditionRemoveByIndex({
                qutter_id,
                rule_idx,
                condition_idx
              }));
            }
          }}
        />
      </div>
    </div>

    {template_id ? (
      rule_type === 'SHOW_THIS_IF' ? (
        <RuleConditionShowIf
          template_id={template_id}
          qutter_id={qutter_id}
          is_default={is_default}
          rule_idx={rule_idx}
          condition_idx={condition_idx}
          condition={condition}
        />
      ) : (
        <RuleConditionIfAnswer
          template_id={template_id}
          qutter_id={qutter_id}
          is_default={is_default}
          rule_idx={rule_idx}
          condition_idx={condition_idx}
          condition={condition}
        />
      )
    ):(
      <RuleConditionIfAnswer
        template_id={template_id}
        qutter_id={qutter_id}
        is_default={is_default}
        rule_idx={rule_idx}
        condition_idx={condition_idx}
        condition={condition}
      />
    )}
  </div>
  );
};