import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateBack, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import { FormSliderEvaluation } from 'features/QutterForm';
import { FormMatrixEvaluation } from 'features/QutterForm';
import { toggleFormEvaluationModal } from 'features/Modals/slices/ModalSlice';
import { setCustomEvaluation } from 'features/QutterEvaluation';

export const FormEvaluationModal = ({
  showModal,
  data,
}) => {
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[data && data.qutter_id]);
  const language = qutterConfig.language.current;

  // const Evaluation = useSelector(state => state.Evaluation[data.qutter_id]);
  const [originalEvaluation, setOriginalEvaluation] = useState(null);
  useEffect(() => {
    if (data && data.evaluation) {
      setOriginalEvaluation(data.evaluation);
    }
  }, [data]);

  if (!data) return (<div>Error: no data.</div>);
  if (!data.qutter_id) return (<div>Error: no data.qutter_id</div>);
  if (!data.form) return (<div>Error: no data.form</div>);

  const onCloseModalClick = (e) => {
    dispatch(toggleFormEvaluationModal({
      show: false,
      data: null,
    }));
  };

  const onSetToOriginal = (e) => {
    if (data && data.form.evaluation) {
      setOriginalEvaluation(data.form.evaluation);
      dispatch(setCustomEvaluation({
        language,
        qutter_id: data.qutter_id,
        id: data.form.id,
        evaluation: data.form.evaluation
      }));
    }
  };

  console.log('<FormEvaluationModal /> data', data);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        onCloseModalClick();
      }}
      dialogClassName=""
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {'Form Evaluation Modal'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">

        <span
          className="font-size-10 hover-blue me-2"
          onClick={onSetToOriginal}
        >
          <FontAwesomeIcon icon={faRotateBack} className="me-1" />
          Reset to original
        </span>

        {(() => {
          if (data.form.type === 'slider') {
            return (
              <FormSliderEvaluation
                template_id={data.template_id}
                qutter_id={data.qutter_id}
                form={data.form}
                originalEvaluation={originalEvaluation}
              />
            );
          } else
          if (data.form.type === 'matrix') {
            return (
              <FormMatrixEvaluation
                template_id={data.template_id}
                qutter_id={data.qutter_id}
                form={data.form}
                originalEvaluation={originalEvaluation}
              />
            );
          } else {
            return (<div className="">Not supported form type.</div>);
          }
        })()}
      </Modal.Body>
    </Modal>
  );
};
