import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export const FormMatrixRowPreview = ({
  qutter_id,
  form_idx,
  row_idx
}) => {
  // console.log('<FormMatrixPreview />');
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const interviewReceptState = useSelector(state => state.InterviewReceipt);
  const [checked_id, set_checked_id] = useState(null);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  const rows = forms[form_idx].rows;
  const row = rows[row_idx];
  const columns = forms[form_idx].columns;

  const on_check = (row_id, column_id) => {
    console.log('row_id', row_id);
    console.log('column_id', column_id);
    set_checked_id(column_id);
  };

  return (
  <tr key={`${row.id}-${row_idx}`} className="border ">
    <td className="text-center summery-icon">
      {row.label}
    </td>
    {columns && columns.length ? (
      columns.map((column, column_idx) => {
        return (
          <td key={`${column.id}-${column_idx}`} className="text-center summery-icon">
            <input 
              type="radio"
              name={`${row.id}`}
              id={`${column.id}`}
              value={column.id}
              checked={column.id === checked_id} 
              onChange={(e) => { on_check(row.id, column.id); }} 
              disabled={interviewReceptState.readOnlyMode ? true : false}
            />
          </td>
        );
      })
    ):(
      <span>Add columns to preview.</span>
    )}
  </tr>
  );
}
