import React from 'react';
import { OpenInNewTab } from 'features/OpenInNewTab';

export const OpenInterviewLink = ({
  job_id,
  templates=[],
  version
}) => {
  const baseUrl = 'https://interviews.hubert.ai/job'
  const new_chat_base_url = process.env.REACT_APP_INTERVIEW_BASE_URL ?? 'http://localhost:4000';

  if (version === 2) {
    // New chat
    return (
      <div>
        <OpenInNewTab
          url={`${new_chat_base_url}/job/${job_id}`}
          name={'Open Interview Link'}
        />
        {templates.map((template, idx) => {
          return (
            <OpenInNewTab
              key={`OpenInNewTab-${idx}`}
              url={`${new_chat_base_url}/template/test/${template.id}`}
              name={'Template test Link'}
            />
          );
        })}
      </div>
    )
  } else {
    // Fallback to old chat
    return (
      <OpenInNewTab
        url={`${baseUrl}/${job_id}`}
        name={'Open Interview Link'}
      />
    );
  }
};
