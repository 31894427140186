import axios from 'axios';

const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const URL = `${base}/interviews/v1`;


export function get_interview_version (id) {
  return new Promise((resolve, reject) => {
    axios.get(`${URL}/version/${id}`)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}


export function score_interview (id) {
  return new Promise((resolve, reject) => {
    axios.get(`${URL}/score/${id}`)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}


export function post_score (
  chat,
  manual_score,
  interview_id
) {
  return new Promise((resolve, reject) => {
    axios.post(
      `${URL}/manual-postscore/${interview_id}`,
      {
        manual_score: manual_score,
        chat: chat,
      }
    ).then(res => {
      // 2XX statuses.
      resolve(res.data);
    }).catch((error) => {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx  
        reject({
          type:     'error',
          message:  `Error ${error.response.status}: ${error.response.data.message}`
        });
      } else if (error.request) {
        // The request was made but no response was received.
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        reject({
          type:     'warning',
          message:  `No response: ${error.request}`
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        reject({
          type:     'error',
          message:  `Error: ${error.message}`
        });
      }
    });
  });
}


export function update_score (interview_id, manual_score) {
  return new Promise((resolve, reject) => {
    axios.post(
      `${URL}/update-score/${interview_id}`,
      { manual_score }
    ).then(res => {
      // 2XX statuses.
      resolve(res.data);
    }).catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        reject({
          type:     'error',
          message:  `Error ${error.response.status}: ${error.response.data.message}`
        });
      } else if (error.request) {
        // The request was made but no response was received.
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        reject({
          type:     'warning',
          message:  `No response: ${error.request}`
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        reject({
          type:     'error',
          message:  `Error: ${error.message}`
        });
      }
    });
  });
}


export function update_summary (id, chat) {
  return new Promise((resolve, reject) => {
    axios.post(`${URL}/update-summary/${id}`, { chat })
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}


export function update_integration (id) {
  return new Promise((resolve, reject) => {
    axios.get(`${URL}/update-integration/${id}`)
    .then((result) => resolve(result.data), reject);
  });
}