import { gql } from '@apollo/client';

export const QUERY_FIND_CANDIDATES_BY_STRING = gql`
query SearchCandidate($searchString: String!) {
  SearchCandidate(searchString: $searchString) {
    id
    firstName
    lastName
    email
  }
}
`;
