import { createSlice } from '@reduxjs/toolkit';

import {
  AddBlock,
  ToggleBlock,
  UpdateBlockList,
  RemoveFromBlockAtIndex,
  AddToBlockAtIndex,
  EditBlockName,
  RemoveBlock,
  AddQutterToTemplateBlock,
  RemoveQutterFromBlock,
  AddNodeToTemplateBlock,
  RemoveNodeActionAtIndex,
} from 'features/TemplateBlock';

import {
  // General Functionality
  SetCurrentTemplateLanguage,
  UpdateFilteredArray,
  AddTemplate,
  ToggleStatus,
  SetReadOnlyMode,
  EditTemplateScriptFlag,

  // Template Information
  EditTemplateClient,
  EditTemplateLanguage,
  EditTemplateDefault,
  EditTemplateRoleId,
  EditTemplateRoleName,
  EditTemplateName,
  EditTemplateDescription,
  EditTemplateTitle,
  EditTemplateCompanyName,
  EditTemplateCompanyDepartment,
  EditTemplateFormOfEmployment,
  EditTemplateThreshold,
  EditTemplateEstimatedLength,
  EditTemplatePublished,
  EditTemplateImmediatelyNotifyUnscored,
  EditTemplateCandidateAppToggle,

  // Interview Summary
  EditQutterSummaryViewability
} from './templatesReducers';

import {
  SaveNewTemplate,
  UpdateTemplate,
  GetTemplateById,
  RemoveTemplateById
} from './thunks';

import { initialCreateNewTemplate } from '../constants/constants';

const initialState = {
  status: 'idle',
  current: '',
  is_edited: false,
  readOnlyMode: false,
  language: { list: [], current: '' },
  createNewTemplate: initialCreateNewTemplate,
  filtered: []
};

const TemplateSlice = createSlice({
  name: 'template',
  initialState: initialState,
  reducers: {
    // General Functionality
    setCurrentTemplateLanguage: SetCurrentTemplateLanguage,
    updateFilteredArray: UpdateFilteredArray,
    addTemplate: AddTemplate,
    toggleBlock: ToggleBlock,
    toggleStatus: ToggleStatus,
    setReadOnlyMode: SetReadOnlyMode,
    editTemplateScriptFlag: EditTemplateScriptFlag,

    // Template blocks
    removeFromBlockAtIndex: RemoveFromBlockAtIndex,
    addToBlockAtIndex: AddToBlockAtIndex,
    updateBlockList: UpdateBlockList,
    addBlock: AddBlock,
    editBlockName: EditBlockName,
    removeBlock: RemoveBlock,
    addQutterToTemplateBlock: AddQutterToTemplateBlock,
    removeQutterFromBlock: RemoveQutterFromBlock,

    // Node
    addNodeToTemplateBlock: AddNodeToTemplateBlock,
    removeNodeActionAtIndex: RemoveNodeActionAtIndex,

    // Template Information
    editTemplateClient: EditTemplateClient,
    editTemplateLanguage: EditTemplateLanguage,
    editTemplateDefault: EditTemplateDefault,
    editTemplateRoleId: EditTemplateRoleId,
    editTemplateRoleName: EditTemplateRoleName,
    editTemplateName: EditTemplateName,
    editTemplateDescription: EditTemplateDescription,
    editTemplateTitle: EditTemplateTitle,
    editTemplateCompanyName: EditTemplateCompanyName,
    editTemplateCompanyDepartment: EditTemplateCompanyDepartment,
    editTemplateFormOfEmployment: EditTemplateFormOfEmployment,
    editTemplateThreshold: EditTemplateThreshold,
    editTemplateEstimatedLength: EditTemplateEstimatedLength,
    editTemplatePublished: EditTemplatePublished,
    editTemplateImmediatelyNotifyUnscored: EditTemplateImmediatelyNotifyUnscored,
    editTemplateCandidateAppToggle: EditTemplateCandidateAppToggle,

    // Interview Summary
    editQutterSummaryViewability: EditQutterSummaryViewability
  },
  extraReducers: (builder) => {
    builder
    .addCase(SaveNewTemplate.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(SaveNewTemplate.rejected, (state) => {
      state.status = 'error';
    })
    .addCase(SaveNewTemplate.fulfilled, (state, action) => {
      state.status = 'idle';
      // const id = action.payload.id;
      // return id;
      // return action.payload
    })

    .addCase(UpdateTemplate.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(UpdateTemplate.rejected, (state) => {
      state.status = 'error';
    })
    .addCase(UpdateTemplate.fulfilled, (state, action) => {
      state.status = 'idle';
      const id = action.payload.id;
      return id;
    })

    .addCase(GetTemplateById.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(GetTemplateById.rejected, (state) => {
      state.status = 'error';
    })
    .addCase(GetTemplateById.fulfilled, (state, action) => {
      state.status = 'idle';
      const id = action.payload.id;
      return id;
    })
    
    .addCase(RemoveTemplateById.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(RemoveTemplateById.rejected, (state) => {
      state.status = 'error';
    })
    .addCase(RemoveTemplateById.fulfilled, (state, action) => {
      const id = action.payload.id;
      state.status = 'idle';
      state[id] = undefined;
      return id;
    })

  },
});

export const {
  // General Functionality
  setCurrentTemplateLanguage,
  updateFilteredArray,
  addTemplate,
  toggleBlock,
  toggleStatus,
  setReadOnlyMode,
  editTemplateScriptFlag,

  // Template blocks
  removeFromBlockAtIndex,
  addToBlockAtIndex,
  updateBlockList,
  addBlock,
  editBlockName,
  removeBlock,
  addQutterToTemplateBlock,
  removeQutterFromBlock,

  // Nodes
  addNodeToTemplateBlock,
  removeNodeActionAtIndex,

  // Template Information
  editTemplateClient,
  editTemplateLanguage,
  editTemplateDefault,
  editTemplateRoleId,
  editTemplateRoleName,
  editTemplateName,
  editTemplateDescription,
  editTemplateTitle,
  editTemplateCompanyName,
  editTemplateCompanyDepartment,
  editTemplateFormOfEmployment,
  editTemplateThreshold,
  editTemplateEstimatedLength,
  editTemplatePublished,
  editTemplateImmediatelyNotifyUnscored,
  editTemplateCandidateAppToggle,

  // Interview Summary
  editQutterSummaryViewability
} = TemplateSlice.actions;
export default TemplateSlice.reducer;
