import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import { UpdateQutter } from 'features/Qutters/slices/thunks';
import { QutterSearch } from 'features/QutterSearch';

export const DefaultFollowupModal = ({
  id,
  showModal,
  setShowModal,
  option_idx,
  language,
  shouldDisplayCreateQutterButton=true,
  onModalClose
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[language ? language: qutterConfig.language.current];

  const onSaveClick = () => {
    setLoading(true);
    // VALIDATION - Validate the saved qutter
    dispatch(UpdateQutter(qutterConfig)).then((res) => {
      console.log('UpdateQutter res', res);
      setShowModal(false);
      if (onModalClose) onModalClose();
    });
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      dialogClassName="create-question-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Default followups to - {id}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <QutterSearch
          selection={true}
          // setShowModal={setShowModal}
          add_policy='default-followup'
          qutter_id={id}
          option_idx={option_idx}
          shouldDisplayCreateQutterButton={shouldDisplayCreateQutterButton}
        />
      </Modal.Body>

      <Modal.Footer>
        <div className="float-end">
          <span
            className='hover-blue font-size-10 me-3'
            onClick={onSaveClick}
          >
            <FontAwesomeIcon className="me-1" icon={faFloppyDisk} />
            Save
          </span>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
