import React, { useState } from 'react';
import Select from 'react-select';

const LanguageSelectSingle = ({
  language_list,
  disabled,
  on_select, 
  selected_language,
  placeholder,
  className
}) => {

  let language_idx = null;
  if (selected_language) {
    function findLanguages (obj) { return obj.value === selected_language; }
    language_idx = language_list.findIndex(findLanguages);
  }

  return (
    <Select
      isMulti={false}
      className={className}
      isDisabled={disabled}
      value={(selected_language ? language_list[language_idx]: null)}
      placeholder={placeholder ? placeholder: 'Choose language'}
      options={language_list}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};

export default LanguageSelectSingle;
