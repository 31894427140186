import { useState } from 'react';
import { useNavigate, useParams, redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {formatDate} from 'features/common';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import { toggleQutterCommentModal } from 'features/Modals/slices/ModalSlice';
import { markAsread } from '../../QutterComment';

const scroll = (id) => {
  console.log('scroll to element', id)
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }
};

export const Notification = ({
  notification,
  notificationId,
  templateId,
  qutterId,
  content,
  author,
  clientId,
  createdAt,
  className,
  flush
}) => {
  // console.log('Notification', notificationId)
  const [isRead, setIsRead] = useState(false);
  const navigate = useNavigate();
  let { template_id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => state.config.user);

  const onNotificationClick = () => {
    console.log('onNotificationClick 2')
    markAsread(user, {
      templateCommentId: notificationId
    })
    .then((result) => {
      console.log('result', result)
      if (templateId === template_id) {
        console.log('result', result)
        scroll(qutterId);
        dispatch(toggleQutterCommentModal({
          show: true,
          data: {
            template_id: templateId,
            qutter_id: qutterId,
            comments: notification
          },
        }));
      } else {
        let url = user.userType === 'cs' ? `/templates/${templateId}`: `/app/script/${templateId}`;
        url += `?id=${qutterId}&comment=1`;
        // navigate(url);
        // redirect(url);
        console.log('redirect other template', url);
        window.location.href = url;
      }
    })
    .catch((error) => {
      console.log('error', error)
    });
  };

  return (
    <div
      className={classNames(
        'hover-blue notification',
        {
          'notification-unread': !isRead,
          'notification-flush': flush
        },
        className
      )}
      onClick={() => {
        console.log('onNotificationClick')
        onNotificationClick();
      }}
    >
      <div className="notification-body">
        <span>{author.name}: </span>
        <div>
          <p
            className="mb-1"
            dangerouslySetInnerHTML={createMarkup(content)}
          />
        </div>
        <div className="notification-time font-size-12">
          {formatDate(new Date(createdAt))}
        </div>
      </div>
    </div>
  );
}

// Notification.propTypes = {
//   avatar: PropTypes.shape(Avatar.propTypes),
//   time: PropTypes.string.isRequired,
//   className: PropTypes.string,
//   unread: PropTypes.bool,
//   flush: PropTypes.bool,
//   emoji: PropTypes.string,
//   children: PropTypes.node
// };

// Notification.defaultProps = { unread: false, flush: false };
