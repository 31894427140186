
import { knockoutIconWithText } from './Knockout';
import { warningIconWithText } from './Warning';
import { infoIconWithText } from './Informational';
import { disqualificationIconWithText } from './Disqualification';
import { bonusIconWithText } from './Bonus';
import { basePointIconWithText } from './BasePoint';

export const evaluation_list = [
  { 
    id: 'info', 
    label: infoIconWithText, 
    value: "info",
    color: "grey"
  },
  { 
    id: 'warning', 
    label: warningIconWithText, 
    value: "warning", 
    color: '#FFC400' 
  },
  { 
    id: 'disqualified',
    label: disqualificationIconWithText,
    value: "disqualified",
    color: '#FF5630'
  },
  // { 
  //   id: 'knockout',
  //   label: knockoutIconWithText,
  //   value: "knockout",
  //   color: '#FF5630'
  // },
  { 
    id: 'bonus',
    label: bonusIconWithText,
    value: "bonus",
    color: '#36B37E'
  },
  {
    id: 'point',
    label: basePointIconWithText,
    value: "point",
    color: '#5243AA'
  }
];