import React, { useEffect, useState }  from 'react';
import SoftBadge from 'components/common/SoftBadge';

import { PastesAndCopyOverlay } from './PastesAndCopyOverlay';

function isObject (item) {
  return (typeof item === "object" && !Array.isArray(item) && item !== null);
}

function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export const CandidateMessagesOpen = ({ message }) => {
  console.log(`<CandidateMessagesOpen />`, message);

  const intent = message.intent_name ? message.intent_name: 
    message.intent ? message.intent: null;

  return (
    <div>
      <SoftBadge bg='primary' className='me-2'>Intent: {intent}</SoftBadge>
      <PastesAndCopyOverlay message={message} />

      {message.texts.map((msg, msg_idx) => {
        if (isObject(msg)) {
          let text = (msg.text ? msg.text : (msg.payload ? msg.payload: msg))
          return (
            <p
              key={`msg-${msg_idx}`}
              className="mb-0"
              dangerouslySetInnerHTML={{
                __html: text
              }}
            />
          )
        }
        else {
          return (
            <p
              key={`msg-${msg_idx}`}
              className="mb-0"
              dangerouslySetInnerHTML={{
                __html: msg
              }}
            />
          )
        }
      })}
    </div>
  )
};
