import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faMagnifyingGlass,
  faArrowCircleLeft
} from '@fortawesome/pro-solid-svg-icons';

import { Container, Row, Col, Table, Form, Button, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from '_components/common/SoftBadge';

import { ClientSelect } from 'features/ClientSelect';
import { TemplateSelect } from 'features/TemplateSelect';
import { LanguageSelect } from 'features/LanguageSelect';

import { CandidateFilter } from '..';

// API
import { filter_candidates_by_job_and_questions } from '../api/api';

export const CandidateFilterSearch = ({
  clientid,
  job_id,
  template_id,
}) => {

  const [loading, set_loading] = useState(false);
  const [client_id, set_client_id] = useState(clientid);
  const [language, set_language] = useState(null);
  const [filters, set_filters] = useState([]);
  const [interviews, set_interviews] = useState([]);

  const on_client_select = (client_id) => {
    // set_loading(true);
    // find_questions_by_client_id(client_id).then(async (data) => {
    //   set_questions(data);
    //   const questions = await prepare_questions(data);
    //   set_template_questions(questions.local);
    //   set_global_questions(questions.global);
    // }).catch((error) => {
    //   set_loading(false);
    //   toast.error(`Search failed: ${error}`);
    // });
  };

  const on_find_click = (job_id) => {
    set_loading(true);
    //TODO: Validate filters
    let fs = Object.assign([], filters);


    filter_candidates_by_job_and_questions(job_id, fs).then(async (data) => {
      set_interviews(data.interviews);
      set_loading(false);
    }).catch((error) => {
      set_interviews([]);
      set_loading(false);
      toast.error(`Search failed: ${error}`);
    });
  };

  const reset = () => {
    set_interviews([]);
    set_filters([]);
    set_loading(false);
  };


  return (
  <Container>

    <Row>
      <Col>
        <ClientSelect
          className={'m-1'}
          // disabled={true}
          on_select={(e) => {
            set_client_id(e.value);
            on_client_select(e.value)
          }}
          selected_client_id={client_id}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <TemplateSelect
          className={'m-1'}
          // disabled={true}
          selected_template_id={template_id}
        />
      </Col>
    </Row>

    {/* <LanguageSelect /> */}

    <div className='m-4 text-center'>
      <span>Show me all candidates where...</span>
      <span
          onClick={(e) => {
            set_filters([...filters, { id: nanoid() }])
          }}
          className='hover-blue text-start'
          // style={{ float: 'left' }}
        >
          <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
          Add filter
        </span>
    </div>

    {filters.map((filter, idx) => {
      return (
        <CandidateFilter
          key={`candidate-filter-${filter.id}`}
          client_id={client_id}
          template_id={template_id}
          filters={filters}
          filter_idx={idx}
          set_filters={set_filters}
        />
      )
    })}

    <div className='text-center mt-4'>
      <div style={{ position: 'absolute' }}>
        <span
          onClick={(e) => {
            set_filters([...filters, { id: nanoid() }])
          }}
          className='hover-blue m-auto text-start'
          style={{ float: 'left' }}
        >
          <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
          Add filter
        </span>
      </div>

      <span
        className="hover-blue m-auto mx-2"
        onClick={() => {
          on_find_click(job_id);
        }}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} className="me-1"/>
        Search
      </span>

      <span
        className="hover-blue m-auto mx-2"
        onClick={() => {
          reset();
        }}
      >
        <FontAwesomeIcon icon={faArrowCircleLeft} className="me-1"/>
        Reset
      </span>
    </div>

    {loading ? (
    <div className='text-center m-4'>
      <Spinner animation="border" variant="dark" />
    </div>
    ):(null)}

    {interviews && interviews.length > 0 ? (
    <Table className="fs--1 mt-3">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Email</th>
          <th>Qualified</th>
          <th>Score</th>
          <th>Links</th>
        </tr>
      </thead>
      <tbody>
        {interviews.map((interview, idx) => {
          const candidate = interview.data.candidate;
          let name = `${candidate.first_name} ${candidate.last_name}`;
          if (!name || name.length === 0) name = '-';
          const application = interview.data.application;
          const summary = interview.data.summary;

          return (
            <tr key={interview._id}>
              <td>
                {idx + 1}
              </td>
              <td>
                {name}
              </td>
              <td>
                {`${candidate.email}`}
              </td>
              <td>
                {application.qualified ? ('Yes'):('No')}
              </td>
              <td>
                {application.score || application.score === 0 ? (
                  <span>{application.score.toFixed(0)}</span>
                ):(
                  <span>-</span>
                )}
              </td>
              <td>
                <Link target='_blank' to={`/score/${interview._id}`}>
                  <Flex alignItems="center">
                    <SoftBadge>
                      Score
                    </SoftBadge>
                  </Flex>
                </Link>
                <Link target='_blank' to={`/score/${interview._id}`}>
                  <Flex alignItems="center">
                    <SoftBadge>
                      Summary
                    </SoftBadge>
                  </Flex>
                </Link>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
    ):(null)}

  </Container>
  );
};
