import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { toast } from 'react-toastify';
import Select from 'react-select';
import JobRoleScoringTemplatePopover from '_components/uncommon/JobRoleScoringTemplatePopover';

import { EDIT_JOB_ROLE, QUERY_GET_ALL_ROLES } from '../api/gql';

function formatRoleName (role_id) {
  let label = role_id.replaceAll('_', ' ').split(' ');
  return label.map(l => { return capitalizeFirstLetter(l) }).join(' ');
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const RoleSelectMulti = ({
  role_list=[],
  job_id,
  selected=[],
  role_id,
  disabled,
  scoring_template=null,
  multi=false,
  on_select,
  placeholder,
  className
}) => {
  let roles = [];
  if (selected && selected.length > 0) {
    let idx = 0;
    selected.map((s) => {
      function find(obj) { return obj.value === s; }
      idx = role_list.findIndex(find);
      if (idx > -1) roles.push(role_list[idx]);
    });
  }

  return (
    <Select
      isMulti={true}
      options={role_list}
      isDisabled={disabled}
      className={className}
      defaultValue={roles && roles.length > 0 ? roles: null}
      placeholder={placeholder ? placeholder: 'Select role'}
      onChange={(e)=> {
        if (on_select) { on_select(e); }
      }}
    />
  );
};

export default RoleSelectMulti;
