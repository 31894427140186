import React from 'react';

import { Container } from 'react-bootstrap';
import Divider from 'components/common/Divider';

import { TextComponent } from 'features/TextComponent';
import { QutterSummary } from 'features/QutterSummary';

export const CreateInfo = ({ id }) => {
  // console.log('<CreateInfo />');
  return (
    <Container key={'create-question-info'}>
      <TextComponent id={id} field={'intro'} />
      <TextComponent id={id} field={'text'} />
      <TextComponent id={id} field={'outro'} />

      <Divider className="mt-4">Summary</Divider>
      <QutterSummary id={id} />

    </Container>
  );
}
