import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';

import {
  updateQutterShortDescription,
} from 'features/Qutters/slices/LibrarySlice';

export const EditQutterShortDescription = ({ qutter_id }) => {
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[qutter_id]);

  return (
    <Row>
      <Col className='m-auto text-center'>
        <span className='m-auto'>Alias/short description (ENGLISH ONLY)</span>
        <span className='m-auto d-block'>Give your question a short description</span>
      </Col>
      <Col className='m-auto'>
        <Form.Control
          defaultValue={qutterConfig.short_description}
          type="text"
          placeholder="Alias/description"
          onChange={(e) => {
            dispatch(updateQutterShortDescription({ id: qutter_id, short_description: e.target.value }));
          }}
        />
      </Col>
    </Row>
  )
};
