import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

export const AddActionButton = ({
  template_id,
  is_default,
  on_click
}) => {
  if (template_id) {
    if (is_default) {
      return (null)
    } else {
      return (
        <span
          className="d-block font-size-10 hover-blue"
          onClick={(e) => { on_click(e); }}
        >
          <FontAwesomeIcon icon={faPlusCircle} className="me-1"/>
          Add Action
        </span>
      );
    }
  } else {
    return (
      <span
        className="d-block font-size-10 hover-blue"
        onClick={(e) => { on_click(e); }}
      >
        <FontAwesomeIcon icon={faPlusCircle} className="me-1"/>
        Add Action
      </span>
    );
  }
};