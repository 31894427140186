const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const URL = `${base}/auth/v1`;

export function appHubertLoginRequest (
  email: string, 
  password: string
) {
  return new Promise((resolve, reject) => {
    fetch(
      `${URL}/hubert-login`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include', // Don't forget to specify this if you need cookies
        body: JSON.stringify({ email, password })
      }
    )
    .then(async (result) => {
      console.log('appHubertLoginRequest', result);
      if (!result.ok) {
        reject(result.statusText);
      }
      resolve(await result.json());
    })
    .catch(error => {
      console.log('API Auth hubert-login error', error);
      const msg = `${error.message}. `;
      if (error.response) {
        // Request made and server responded
        reject(`${msg}. ${error.response.data.message}`);
      } else if (error.request) {
        // The request was made but no response was received
        reject(`${msg}. ${error.request}`);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(`${msg}.`);
      }
    });
  });
};

export function appHubertLogoutRequest () {
  return new Promise((resolve, reject) => {
    fetch(
      `${URL}/hubert-logout`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include', // Don't forget to specify this if you need cookies
      }
    )
    .then(async (result) => {
      console.log('appHubertLogout', result);
      if (!result.ok) {
        reject(result.statusText);
      }
      resolve();
    })
    .catch(error => {
      console.log('API Auth hubert-logout error', error);
      const msg = `${error.message}. `;
      if (error.response) {
        // Request made and server responded
        reject(`${msg}. ${error.response.data.message}`);
      } else if (error.request) {
        // The request was made but no response was received
        reject(`${msg}. ${error.request}`);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(`${msg}.`);
      }
    });
  });
};

export function adminLogoutRequest () {
  return new Promise((resolve, reject) => {
    fetch(
      `${URL}/logout`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include', // Don't forget to specify this if you need cookies
      }
    )
    .then(async (result) => {
      console.log('adminLogoutRequest', result);
      if (!result.ok) {
        reject(result.statusText);
      }
      resolve();
    })
    .catch(error => {
      console.log('API Auth hubert-logout error', error);
      const msg = `${error.message}. `;
      if (error.response) {
        // Request made and server responded
        reject(`${msg}. ${error.response.data.message}`);
      } else if (error.request) {
        // The request was made but no response was received
        reject(`${msg}. ${error.request}`);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(`${msg}.`);
      }
    });
  });
};