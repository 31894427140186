import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import { Card, Table } from 'react-bootstrap';

import { LANGUAGE_FLAGS } from 'features/LanguageSelect/assets/constants';
import { GetJobTemplates } from '../api/api';

export const JobTemplates = ({
  jobId,
  linkOnly=false
}) => {
  console.log('<JobTemplates />', jobId)
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    GetJobTemplates(jobId)
    .then((result) => {
      console.log('GetJobTemplates result', result);
      setTemplates(result);
    }).catch((error) => {
      toast.error(`${JSON.stringify(error)}.`);
    })
  }, []);

  return (
  <Card className="mb-3">
    <Card.Header>
      <h5 className="mb-0">Templates</h5>
    </Card.Header>
    <Card.Body className="border-top p-0">
      <Table className='m-0'>
        <thead>
        {linkOnly ? (null):
        (
          <tr>
            <th>Name</th>
            <th>Role Name</th>
            <th>Role Id</th>
            <th>Language</th>
            <th>Published</th>
            <th>Template version</th>
          </tr>
        )}
        </thead>
        <tbody>
          {templates.map((template, idx) => {
            if (!template) return null;
            const url = `/templates/${template._id}`;

            if (linkOnly) {
              return (
                <tr
                  key={`${template.id}-${idx}`}
                  className="g-0 align-items-center border-bottom py-2 px-3"
                >
                  <td>
                    <p className="mb-0">
                      {template.template_version === 2 ? (
                        <Link to={url}>{template.name}</Link>
                      ):(
                        <span>{template.name}</span>
                      )}
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      {template.template_version === 2 ? template.role.name: template.role}
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      {template.template_version === 2 ? template.role.id: (template.job_settings ? template.job_settings.role_id: '')}
                    </p>
                  </td>
                </tr>
              )
            }

            return (
              <tr
                key={`${template.id}-${idx}`}
                className="g-0 align-items-center border-bottom py-2 px-3"
              >
                <td>
                  <p className="mb-0">
                    {template.template_version === 2 ? (
                      <Link to={url}>{template.name}</Link>
                    ):(
                      <span>{template.name}</span>
                    )}
                  </p>
                </td>
                <td>
                  <p className="mb-0">
                    {template.template_version === 2 ? template.role.name: template.role}
                  </p>
                </td>
                <td>
                  <p className="mb-0">
                    {template.template_version === 2 ? template.role.id: (template.job_settings ? template.job_settings.role_id: '')}
                  </p>
                </td>
                <td>
                  <span>
                    {template.language_settings ? (
                      template.language_settings.list.map((language, idx) => {
                        return (<span key={`${language}-${idx}`}>{LANGUAGE_FLAGS[language]}</span>)
                      })
                    ): (null)}
                  </span>
                </td>
                <td>
                  <span
                    className={classNames('badge rounded-pill', {
                      'badge-soft-success': template.published === true,
                      'badge-soft-danger': template.published === false
                    })}
                  >
                    {template.published ? 'Published': 'Not published'}
                  </span>
                </td>
                <td>
                  <p className="mb-0">
                    {template.template_version === 2 ? 2: 1}
                  </p>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Card.Body>
  </Card>
  );
};
