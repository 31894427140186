import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import { prepare_questions_list } from '../utils/utils';
import { get_template_questions } from '../api/api';

export const TemplateQuestionSelect = ({
  disabled,
  placeholder,
  className,

  template_id,
  on_select,
}) => {
  const [loading, set_loading] = useState(true);
  const [list, set_list] = useState([]);
  const [options, set_options] = useState([]);

  useEffect(() => {
    get_template_questions(template_id).then(async (questions) => {
      set_list(questions);
    }).catch((error) => {
      set_loading(false);
      toast.error(`get_template_questions failed: ${error}`);
    });
  }, []);

  useEffect(() => {
    async function load_list () {
      const l = await prepare_questions_list(list);
      set_options(l);
      set_loading(false);
    }
    load_list();
  }, [list]);

  if (loading) return (<span>Loading template questions...</span>)

  return (
    <Select
      isMulti={false}
      className={className}
      isDisabled={disabled}

      placeholder={placeholder ? placeholder: 'Select Question'}
      options={options}
      onChange={(e) => {
        let s = list.find(q => q.id === e.question_id);
        if (on_select) on_select(e, s);
      }}
    />
  );
};
