import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlusCircle,
  faMinusCircle
} from '@fortawesome/free-solid-svg-icons';

import {
  Row,
  Col,
  Button
} from 'react-bootstrap';

import {
  intentReplyPlus,
  intentReplyMinus,
  intentReplyChange
} from 'features/Qutters/slices/LibrarySlice';

export const IntentText = ({
  id,
  intent_idx,
  reply_idx
}) => {
  const dispatch = useDispatch();
  // const qutter = useSelector(state => state.Library[id]);
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[qutterConfig.language.current];

  const [text, setText] = useState(qutter.intents[intent_idx].replies[reply_idx]);
  console.log(`<IntentText />`, reply_idx);
  console.log('Text value: ', text);

  const [editMode, setEditMode] = useState(false);

  const handleDone = () => {
    editMode ? setEditMode(false): setEditMode(true);
    dispatch(intentReplyChange({ id, intent_idx, reply_idx, text }));
  };

  const onMinusClick = () => {
    dispatch(intentReplyMinus({ id, intent_idx, reply_idx }));
  };

  const onPlusClick = () => {
    dispatch(intentReplyPlus({ id, intent_idx, reply_idx }));
  };

  return (
      <Row className='m-1'>
        {editMode ? (
          <div>
            <ReactQuill
              onChange={setText}
              placeholder={'Reply Text'}
              defaultValue={qutter.intents[intent_idx].replies[reply_idx]}
            />
            <Button
              className="w-auto btn btn-sm"
              variant='primary'
              onClick={handleDone}
            >
              Done
            </Button>
          </div>
        ) : (
          (() => {
            if (qutter.intents[intent_idx].replies[reply_idx].length) {
              return (
                <>
                  <Col className='bubble cursor-pointer'>
                    <div className="">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: qutter.intents[intent_idx].replies[reply_idx]
                        }}
                        onClick={() => { editMode ? setEditMode(false): setEditMode(true); }}
                      />
                    </div>
                  </Col>
                  <Col className='text-center m-auto' xs={1} md={1} lg={1}>
                    <span 
                      className="m-1 cursor-pointer"
                      onClick={() => { onMinusClick(); }}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </span>
                    <span
                      className="m-1 cursor-pointer"
                      onClick={() => { onPlusClick(); }}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </span>
                  </Col>
                </>
              );
            } else {
              return (
                <Button 
                  className="btn btn-sm w-auto"
                  variant='hubert'
                  onClick={() => { editMode ? setEditMode(false): setEditMode(true); }}
                >
                  + Add Question Text
                </Button>
              );
            }
          })()
        )}
      </Row>
  );
};
