import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { TemplateQutterProvider } from 'features/Template';

export const PreviewRuleAction = ({
  template_id,
  qutter_id,
  parent_id,
  action,
  order
 }) => {
  // console.log('<UniqueFollowups />', qutter_id);
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  // const qutter = qutter_config[language];

  // const template = useSelector(state => state.Template[template_id]);
  // const params = useSelector(state => state.Parameters[qutter_id]);
  const name = action.name;
  const value = action.value;

  if (!value) return null;

  return (
    <span>
      {/* <span className='font-size-10'>THEN {name}</span> */}

      {(() => {
        if (name === 'JUMP_TO') {
          return (
            value.map((v, idx) => {
              if (typeof v === 'string') {
                return (
                  <div 
                    key={`jump-to-qutter-${v}`}
                    className="mb-1"
                  >
                    <TemplateQutterProvider
                      template_id={template_id}
                      language={language}
                      qutter_id={v}
                      qutter_idx={idx}
                      node={{ type: 'question', id: v }}
                      node_idx={idx}
                      order={(order !== undefined ? (order + '.' + (idx + 1)): order)}
                      is_followup={true}
                      parent_id={qutter_id}
                    />
                  </div>
                )
              } else if (typeof v === 'object') {
                return (
                  <div
                    key={`jump-to-qutter-${v.id}`}
                    className="mb-1"
                  >
                    <TemplateQutterProvider
                      template_id={template_id}
                      language={language}
                      qutter_id={v.id}
                      qutter_idx={idx}
                      node={{ type: 'question', id: v.id }}
                      node_idx={idx}
                      order={(order !== undefined ? (order + '.' + (idx + 1)): order)}
                      is_followup={true}
                      parent_id={qutter_id}
                    />
                  </div>
                )
              } else {
                return (
                  <div className='red'>
                    Erroneous action value.
                  </div>
                )
              }
            })
          );
        }
      })()}

    </span>
  );
};
