import React from 'react';

import {
  Container,
  Badge,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';

const popover = ({
  history
}) => {
  return (
  <Popover 
    style={{ 
      minWidth: '300px !important',
      width: '300px !important'
    }}
  >
    <Popover.Header as="h3">
      <strong class='h6 text-capitalize text-decoration-underline'>
        Update History
      </strong>
    </Popover.Header>
    <Popover.Body 
      as={Container} 
      className='w-100'
      style={{ 
        minWidth: '300px !important',
        width: '300px !important'
      }}
    >
      {history && history.length && history.map((h, idx) => {
        const date = new Date(h.updated_at);
        return (
          <div 
            key={`scoring-status-history-popover-${idx}`}
          >
            <span>{h.name.split('_').join(' ')} - </span>
            <span>{date.toLocaleDateString()} - {date.toLocaleTimeString()}</span>
          </div>
        )
      })}
    </Popover.Body>
  </Popover>)
}


const ScoringStatusHistoryPopover = ({
  status,
  history
}) => {

  return (
    <OverlayTrigger
      placement="left"
      delay={{ show: 100, hide: 100 }}
      overlay={popover({ history })}
    >
      {(() => {
        if (['auto_scoring_finished', 'manual_scoring_finished'].includes(status)) {
          return (<Badge bg="success" className="me-1">{status.split('_').join(' ')}</Badge>);
        } else 
        if ([ 'processor_failed', 'auto_scoring_failed' ].includes(status)) {
          return (<Badge bg="danger" className="me-1">{status.split('_').join(' ')}</Badge>);
        } else if (status) {
          return (<Badge bg="info" className="me-1">{status.split('_').join(' ')}</Badge>);
        } else {
          return <></>;
        }
      })()}
    </OverlayTrigger>
  );
};

export default ScoringStatusHistoryPopover;
