import React from 'react';
import Select from 'react-select';
import { QUTTER_TYPES_LIST } from '..';

export const QutterTypeSelectMultiple = ({
  disabled,
  on_select, 
  selected,
  placeholder,
  className
}) => {

  let types = [];
  if (selected && selected.length > 0) {
    let idx = 0;
    selected.map((s) => {
      function find(obj) { return obj.value === s; }
      idx = QUTTER_TYPES_LIST.findIndex(find);
      if (idx > -1) types.push(QUTTER_TYPES_LIST[idx]);
    });
  }

  return (
    <Select
      isMulti={true}
      isDisabled={disabled}
      className={className}
      defaultValue={types && types.length > 0 ? types: null}
      options={QUTTER_TYPES_LIST}
      placeholder={placeholder ? placeholder: 'Choose tags'}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};
