export const condition_types = [
  { label: 'Question Answer', value: 'question_answer' },
  { label: 'Interview property', value: 'interview_property' },
  { label: 'Candidate property', value: 'candidate_property' },
];

// export const OPERATORS = {
//   EQUALS:         'EQUALS',
//   IS:             'IS',
//   IS_NOT:         'IS_NOT',
//   IS_IN:          'IS_IN',
//   IS_NOT_IN:      'IS_NOT_IN',
//   INCLUDES:       'INCLUDES',
//   GREATER_THAN:   'GREATER_THAN',
//   LESS_THAN:      'LESS_THAN',
//   STRING_LENGTH:  'STRING_LENGTH',
// };

export const OPERATORS = {
	EQUALS:					'EQUALS',
	eq: 						'EQUALS',
	IS: 						'IS',
	is: 						'IS',
	IS_NOT: 				'IS_NOT',
	is_not: 				'IS_NOT',
	IS_IN: 					'IS_IN',
	is_in: 					'IS_IN',
	IS_NOT_IN: 			'IS_NOT_IN',
	is_not_in: 			'IS_NOT_IN',
	INCLUDES: 			'INCLUDES',
	includes: 			'INCLUDES',
	GREATER_THAN: 	'GREATER_THAN',
	gt: 						'GREATER_THAN',
	LESS_THAN: 			'LESS_THAN',
	lt: 						'LESS_THAN',
	STRING_LENGTH: 	'STRING_LENGTH',
};

export const OPERATORS_SELECT_LIST = [
  { label: 'Equals', value: 'EQUALS' },
  { label: 'is', value: 'IS' },
  { label: 'Is not', value: 'IS_NOT' },
  { label: 'Is in', value: 'IS_IN' },
  { label: 'Is not in', value: 'IS_NOT_IN' },
  { label: 'Greater than', value: 'GREATER_THAN' },
  { label: 'Less than', value: 'LESS_THAN' },
];

export const ABSOLUTE_RELATIVE_SELECT_LIST = [
  { label: 'Absolute', value: 'absolute' },
  { label: 'Relative', value: 'relative' },
];

export const then_types = [
  { label: 'Jump to', value: 'JUMP_TO' },
  // { label: 'Disqualify', value: 'disqualify' },
  // { label: 'Immediate Qualify', value: 'qualify' },
  // { label: 'Send email', value: 'send_email' },
];

export const AND_OR = [
  { label: 'AND', value: 'AND' },
  { label: 'OR', value: 'OR' },
];

export const interview_properties = [
  { label: 'Language', value: 'language' },
  { label: 'Qualified', value: 'qualified' },
];
