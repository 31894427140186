import React from 'react';
import { useDispatch } from 'react-redux';
import { customRuleActionPlus } from 'features/Parameters';
import { ruleActionPlus } from 'features/Qutters';

import { RuleAction, AddActionButton } from '../..';

export const RuleActions = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  rule,
}) => {
  const dispatch = useDispatch();
  const actions = rule.actions;

  if (!actions || actions.length === 0) {
    return (
      <AddActionButton
        template_id={template_id}
        is_default={is_default}
        on_click={() => {
          if (template_id) {
            dispatch(customRuleActionPlus({ template_id, qutter_id, rule_idx }));
          } else {
            dispatch(ruleActionPlus({ qutter_id, rule_idx }));
          }
        }}
      />
    );
  } 
  return (
    <div>
      {actions.map((action, action_idx) => {
        return (
          <RuleAction
            key={`${qutter_id}-rule-action-${action.id}`}
            template_id={template_id}
            qutter_id={qutter_id}
            is_default={is_default}
            rule_idx={rule_idx}
            action={action}
            action_idx={action_idx}
          />
      )})}

      <AddActionButton
        template_id={template_id}
        is_default={is_default}
        on_click={() => {
          if (template_id) {
            dispatch(customRuleActionPlus({ template_id, qutter_id, rule_idx }));
          } else {
            dispatch(ruleActionPlus({ qutter_id, rule_idx }));
          }
        }}
      />
    </div>
  );
};