import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormEvaluationElement } from './FormEvaluationElement';
import { Button } from 'react-bootstrap';
import { toggleFormEvaluationModal } from 'features/Modals/slices/ModalSlice';
import { setCustomEvaluation } from 'features/QutterEvaluation';

export const FormEvaluation = ({
  template_id,
  qutter_id,
  form,
}) => {
  const dispatch = useDispatch();
  const interviewReceptState = useSelector(state => state.InterviewReceipt);
  const Evaluation = useSelector(state => state.Evaluation[qutter_id]);
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const language = qutterConfig.language.current;

  const [evaluation, setEvaluation] = useState([]);
  const [isDefaultEvaluation, setIsDefaultEvaluation] = useState(true);
  const custom_key = `${form.id}.evaluation`;

  useEffect(() => {
    console.log(`<FormEvaluation ${qutter_id} useEffect start: `)
    if (!template_id) {
      console.log(`<FormEvaluation ${qutter_id} useEffect !template_id: `)
      setEvaluation(form.evaluation);
    } else {

      if (Evaluation && Evaluation[language] && Evaluation[language][custom_key]) {
        console.log(`<FormEvaluation ${qutter_id}: if `, Evaluation && Evaluation[language] && Evaluation[language][custom_key])
        setEvaluation(Evaluation[language][custom_key]);
      } else console.log(`<FormEvaluation ${qutter_id} useEffect else: `)
    }
  }, [Evaluation, language]);

  const onAddEvaluationClick = (e) => {
    dispatch(toggleFormEvaluationModal({
      show: true,
      data: {
        template_id,
        qutter_id,
        form,
        evaluation: []
      },
    }));
  };

  const onEditEvaluationClick = (e) => {
    if (!evaluation) return;
    dispatch(toggleFormEvaluationModal({
      show: true,
      data: {
        template_id,
        qutter_id,
        form,
        evaluation
      },
    }));
  };

  console.log(`<FormEvaluation ${qutter_id} final evaluation`, evaluation)

  if (!evaluation) return (
    <div className='text-center mb-4 mt-4'>
      <Button
        className='btn btn-sm'
        onClick={onAddEvaluationClick}
      >
        Add evaluation
      </Button>
    </div>
  );

  return (
    <div 
      className='evaluation-overlay-container mb-4 mt-4' 
    >
      <div className='evaluation-overlay-default'>
        {Array.isArray(evaluation) ? (
          <div className=''>
            {evaluation.map((detail, idx) => {
              return (
                <FormEvaluationElement
                  key={`form-evaluation-element-${template_id}-${qutter_id}-${idx}`}
                  template_id={template_id}
                  qutter_id={qutter_id}
                  formId={form.id}
                  evaluation={detail}
                />
              )
            })}
          </div>
        ):(
          <div className='text-center'>
            <span className='badge badge-soft-info'>Weighted: {evaluation.weight * 100}%</span>
          </div>
        )}
      </div>
      <div className="evaluation-overlay-hover">
        <Button 
          className="overlay-text btn btn-sm me-2"
          onClick={onEditEvaluationClick}
        >
          Edit
        </Button>
      </div>
    </div>
  )
}
