import React, { useState } from 'react';
import Select from 'react-select';

const HubiverseTagsSelectMultiple = ({
  disabled,
  on_select, 
  selected,
  placeholder,
  className,
  list
}) => {

  let tags = [];
  console.log('selected', selected)
  if (selected && selected.length > 0) {

    let idx = -1;
    selected.map((s) => {
      function find(obj) { return obj.value === s; }
      idx = list.findIndex(find);
      if (idx > -1) {
        tags.push(list[idx]);
      }
    });
  }

  console.log('HubiverseTags full list', list);
  console.log('HubiverseTags selected tags', tags);

  return (
    <Select
      isMulti={true}
      isDisabled={disabled}
      className={className}
      // defaultValue={tags && tags.length > 0 ? tags: null}
      value={tags && tags.length > 0 ? tags: null}
      options={list}
      placeholder={placeholder ? placeholder: 'Choose parent'}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};

export default HubiverseTagsSelectMultiple;
