import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { toast } from 'react-toastify';
import Select from 'react-select';
import JobRoleScoringTemplatePopover from '_components/uncommon/JobRoleScoringTemplatePopover';

import { EDIT_JOB_ROLE } from '../api/gql';

const RoleSelectSingle = ({
  role_list=[],
  job_id,
  role_id,
  disabled,
  scoring_template=null,
  multi=false,
  on_select,
  placeholder,
  className
}) => {
  //console.log('<RoleSelectSingle />', role_id)
  const [_role_id, set_role_id] = useState(null);
  const [role_idx, set_role_idx] = useState(null);

  const [job_role_mutation, { job_role_mutation_data, job_role_mutation_loading, job_role_mutation_error }] = useMutation(EDIT_JOB_ROLE);

  function findRole(obj) { return obj.value === _role_id; }
  useEffect(() => {
    if (role_id) {
      set_role_id(role_id);
      set_role_idx(role_list.findIndex(findRole));
    }
  }, [role_id, role_idx, _role_id]);

  return (
  <>
    <Select
      defaultValue={_role_id && role_idx !== null ? role_list[role_idx]: null}
      value={_role_id && role_idx !== null ? role_list[role_idx]: null}
      options={role_list}
      placeholder={placeholder ? placeholder: 'Select role'}
      className={`${className} ${scoring_template ? 'd-inline-flex': ''}`}
      isDisabled={disabled !== null ? disabled: false}
      onChange={(e)=> {
        set_role_id(e.value);
        if (on_select) {
          on_select(e);
        } else 
        if (job_id && job_id.length) {
          job_role_mutation({
            variables: {
              job_id: job_id,
              role_id: e.value
            }
          }).then((result) => {
            toast.success(`Job role edited.`);
          }).catch((error) => {
            toast.error(`Failed to update job role. Error: ${error}`);
          });
        }
      }}
    />
    {scoring_template && scoring_template === true && (
      <JobRoleScoringTemplatePopover role_id={role_id} />
    )}
  </>
  );
};

export default RoleSelectSingle;