import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col, Table, OverlayTrigger, Popover, Badge, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCertificate,
} from '@fortawesome/free-solid-svg-icons';


const Summary = ({ candidate, application, summary }) => {
  let { id } = useParams();
  console.log('<Admin Summary />', summary);

  return (
    <Container>
      <h1>Summary: {id}</h1>
      <Row>
        <Col>
        {summary.summary.map((sum, index) => {
          return (
            <Row key={index} className="">
              <PrintSummary key={index} summary={sum} />
            </Row>
          );
        })}
        </Col>
      </Row>
    </Container>
  );
};

export default Summary;

const popover = (detail) => {
  return (
  <Popover className={detail.question.length > 300 ? 'popup large' : 'popup small'}>
    <Popover.Content>
      <div className="popup title">Question</div>
      <span
        dangerouslySetInnerHTML={{ __html: detail.question ? detail.question.replace(/^<br>/, '') : detail.label.replace(/^<br>/, '') }}
      />
    </Popover.Content>
  </Popover>
  )
}


const PrintSummary = ({ summary }) => {
  return (
  <Table borderless size="sm" hover className="">
    <thead>
      <tr>
        {/*
        <th>
          <span className="ml-1 align-middle">{getIcon(summary, 'list')}</span>
        </th>
        */}
        <th colSpan="2" className="align-middle">
          <h2>
            {summary.header}
            {summary && summary.points ? (
              <small className="float-right">
                ({summary.points.toFixed(0)}/{summary.maxPoints}) Threshold: {summary.threshold}
              </small>
            ) : null}
          </h2>
        </th>
      </tr>
    </thead>
    <tbody>
      {summary.details.map((detail, index) => {
        console.log('detail', detail)
        if (detail.options && detail.options.length) {
          return (
            <tr key={index} className="border summary-row test">
              <td className="text-center summery-icon">{getIcon(detail, 'list')} </td>

              <td>{detail.question}</td>

              <td className="border ">
                {detail.options.map((option, index) => {
                  let classValue = option.answered ? '' : 'outline-';
                  classValue += option.isCorrect ? 'success' : 'danger';
                  return (
                    <Badge
                      key={`summary-test-option-${option.id}`}
                      variant={classValue}
                      className="m-2 answerButton"
                    >
                      {option.label}
                    </Badge>
                  );
                })}
              </td>
            </tr>
          );
        } else
          return (
            <tr key={index} className="border ">
              <td className="text-center summery-icon">{getIcon(detail, 'list')}</td>
              <OverlayTrigger
                rootCloseEvent="click"
                rootClose={true}
                placement="top"
                delay={{ show: 400, hide: 50 }}
                trigger="click"
                overlay={popover(detail)}
              >
                <td>{detail.label}</td>
              </OverlayTrigger>
              <SummaryAnswer detail={detail} />
            </tr>
          );
      })}
    </tbody>
  </Table>
  );
};

const SummaryAnswer = ({ detail }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <td className="border" onClick={handleClick}>
      <div
        className={`${open ? 'answerOpen' : 'answerClose'} summaryAnswer`}
        dangerouslySetInnerHTML={{ __html: (detail.answer ? detail.answer.replace(/^<br>/, '') : '') }}
      ></div>
    </td>
  );
};

const getCert = (points) => {
  return (
    <span className="fa-layers fa-fw cert">
      <FontAwesomeIcon
        fixedWidth
        className="text-success" 
        icon={faCertificate}
      />
      <span style={{ fontSize: '0.92593rem' }} className="fa-layers-text fa-inverse">
        {points}
      </span>
    </span>
  );
};
const getPointCorrect = (points) => {
  return (
    <span className="fa-layers fa-fw cert">
      <FontAwesomeIcon className="text-success" icon={['fas', 'circle']} />
      <span style={{ fontSize: '1rem' }} className="fa-layers-text fa-inverse">
        {points}
      </span>
    </span>
  );
};
const getPointIncorrect = (points) => {
  return (
    <span className="fa-layers fa-fw cert">
      <FontAwesomeIcon className="text-danger" icon={['fas', 'circle']} />
      <span style={{ fontSize: '1rem' }} className="fa-layers-text fa-inverse">
        {points}
      </span>
    </span>
  );
};

const getIcon = (detail, large) => {
  let icon;
  console.log('getIcon: (summary)', detail)
  switch (true) {
    case detail.icon === 'accepted':
      icon = <FontAwesomeIcon className="icon text-success" fixedWidth icon={['fas', 'check']} />;
      break;
    case detail.icon === 'disqualified':
      icon = <FontAwesomeIcon className="icon text-danger" fixedWidth icon={['fas', 'times-circle']} />;
      break;

    case detail.icon === 'point':
      if (typeof detail.average === 'number')
        icon = (
          <span className={'purple icon ' + (large ? `score-pill-${large}` : 'score-pill-md')}>
            {Math.round(detail.average)}
          </span>
        );
      else if (detail.points || detail.points === 0) {
        icon = (
          <span className={'purple icon ' + (large ? `score-pill-${large}` : 'score-pill-md')}>
            {Math.round(detail.points)}
          </span>
        );
      }
      break;
    case detail.icon === 'warning':
      icon = <FontAwesomeIcon className="icon text-warning" fixedWidth icon={['fas', 'exclamation-triangle']} />;
      break;
    case detail.icon === 'bonus':
      icon = getCert(detail.bonuspoints);
      break;
    case detail.points != null:
      if (detail.points !== 0) icon = getPointCorrect(detail.points.toFixed(0));
      else icon = getPointIncorrect(detail.points.toFixed(0));
      break;

    // case summary.icon === 'correct':
    //   icon = getPointIncorrect(summary.points.toFixed(0));
    //   break;
    case detail.icon === 'info':
      icon = <FontAwesomeIcon className="icon text-grey" fixedWidth icon={['fas', 'info-circle']} />;
      break;

    default:
      icon = <FontAwesomeIcon className="icon" fixedWidth icon={['fas', 'info-circle']} />;
  }
  return icon;
};
