import React, { useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';

import { RoleSelect } from 'features/RoleSelect';
import { QutterTagSelect } from 'features/QutterTagSelect';
import { LanguageSelect } from 'features/LanguageSelect';

import { CandidateSearchGlobal } from 'features/CandidateSearchGlobal';
import { CandidateSearchLocal } from 'features/CandidateSearchLocal';

import { candidate_advanced_search } from '../api/api';

export const CandidateAdvancedSearch = () => {

  const [text, set_text] = useState('');
  const [scope, set_scope] = useState('global');
  const [filters, set_filters] = useState([]);
  const [language, set_language] = useState('');
  const [role, set_role] = useState([]);
  const [tags, set_tags] = useState([]);
  const [types, set_types] = useState([]);
  const [loading, set_loading] = useState(false);

  const [qutter_list, set_qutter_list] = useState([]);

  const on_search_click = () => {
    let search_params = {};
    if (text.length) search_params.text = text;
    if (language.length) search_params.language = language;
    if (tags.length) search_params.tags = tags;
    if (role.length) search_params.role = role;
    if (types.length) search_params.types = types;
    set_loading(true);

    candidate_advanced_search(search_params).then((data) => {
      set_qutter_list(data);
      set_loading(false);
    }).catch((error) => {
      set_loading(false);
      toast.error(`Search failed: ${error}`);
    });
  };

  return (
  <div>
    <div>
      <div className='d-inline me-2'>Scope</div>
      <Form.Check
        name="scope-check"
        checked={scope === 'global'}
        value={'global'}
        onChange={(e) => {
          set_scope(e.target.checked ? 'global': 'local')
        }}
        inline
        label="Global"
        type={'radio'}
        id={`scope-check-global`}
        />

      <Form.Check
        name="scope-check"
        checked={scope === 'local'}
        value={'local'}
        onChange={(e) => {
          set_scope(e.target.checked ? 'local': 'global')
        }}
        inline
        label="Local"
        type={'radio'}
        id={`scope-check-local`}
      />
    </div>

    {scope === 'global' ? (
      <CandidateSearchGlobal />
    ) : (
      <CandidateSearchLocal />
    )}

    <span
      onClick={on_search_click}
      className='hover-blue font-size-10'
    >
      <FontAwesomeIcon className="me-1" icon={faMagnifyingGlass} />
      Search
    </span>

    {/* 
    <LanguageSelect
      on_select={(e) => { set_language(e.value) }}
      placeholder='Search by nationality'
      className={'m-1'}
    />

    <RoleSelect
      multi={true}
      className={'m-1'}
      on_select={(e) => {
        set_role(e.map((t) => {return t.value; }))
      }}
      placeholder='Search by role'
    />

    <QutterTagSelect
      multi={true}
      className={'m-1'}
      placeholder='Search by tag'
      on_select={(e) => {
        set_tags(e.map((t) => {return t.value; }))
      }}
    /> 
    */}
  </div>
  );
};
