import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import classNames from 'classnames';

import { LANGUAGE_FLAGS } from 'features/LanguageSelect/assets/constants';

const ensureArray = (x:any) => {
  if(Array.isArray(x))
    return x;
  return [x];
}

export const TemplatesList = ({
  clientName,
  templates,
  show=false
}) => {
  const [showAll, setShowAll] = useState((show !== undefined && show !== null) ? show: false);

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0">{clientName} - Templates</h5>
      </Card.Header>
      <Card.Body className="border-top p-0">
        <Table className='m-0'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role Name | Id</th>
              <th>Language</th>
              <th>Client</th>
              <th>Published</th>
            </tr>
          </thead>
          <tbody>
            {templates.slice(0, (showAll === false ? 5: templates.length)).map((template, idx) => {
              let url = `/templates/${template._id}`
              const language = (template.language_settings && template.language_settings.list) ?? ensureArray(template.language);
              const company = template.company?.name ?? template.client?.org_name;
              return (
                <tr
                  key={`${template.id}-${idx}`}
                  className="g-0 align-items-center border-bottom py-2 px-3"
                >
                  <td>
                    <p className="mb-0">
                      <Link to={url}>{template.name}</Link>
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      {template.role.name} | {template.role.id}
                    </p>
                  </td>
                  <td>
                    <span>
                      {language ? (
                        language.map((language, idx) => {
                          return (<span key={`${language}-${idx}`}>{LANGUAGE_FLAGS[language]}</span>)
                        })
                      ): (null)}
                    </span>
                  </td>
                  <td>
                    <span>
                      {company ? company : '-'}
                    </span>
                  </td>
                  <td>
                    <span
                      className={classNames('badge rounded-pill', {
                        'badge-soft-success': template.published === true,
                        'badge-soft-danger': template.published === false
                      })}
                    >
                      {template.published ? 'Published': 'Not published'}
                    </span>
                  </td>
                </tr>
              )}
            )}
          </tbody>
        </Table>
      </Card.Body>
      {templates && templates.length > 5 ? (
        <Card.Footer className="bg-light p-0">
          <IconButton
            variant="link"
            iconClassName="fs--2 ms-1"
            icon="chevron-right"
            className="d-block w-100"
            iconAlign="right"
            onClick={() => showAll === false ? setShowAll(true): setShowAll(false)}
          >
            {showAll === false ? 'View all': 'View less'}
          </IconButton>
        </Card.Footer>
      ):(
        <></>
      )}
    </Card>
  );
};