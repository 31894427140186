import React from 'react';

const IntegrationSmall = ({
  integration
}) => {

  if (integration) {
    if (typeof integration === 'string')
      integration = JSON.parse(integration);
    let name = Object.keys(integration)[0];
    if (integration.hasOwnProperty(name)) {
      name = name.toUpperCase();
    }
    else {
      return (
        <div className='my-2'>
          <span className='d-block red'>Job has integration but unknown to me:</span>
          <span className='d-block red'>{JSON.stringify(integration)}</span>
        </div>
      );
    }

    return (
      <div className='my-2'>Integration: {name}</div>
    );
  } else {
    return (
      <div className='my-2'>No integration for this job.</div>
    );
  }
};

export default IntegrationSmall;
