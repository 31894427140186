import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import { 
  Container, 
  Row, 
  Col, 
  Form, 
  Card, 
  Button, 
  Badge,
  Popover,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';

import { Events, scroller } from "react-scroll";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

import ExperiencePopover from './ExperiencePopover';
import ScoringDetailsPopover from './ScoringDetailsPopover';
import { ANSWER_TAGS, INTERVIEW_TAGS } from './TagEnums';

import {
  updateQutterScore,
  updateTotals
} from './redux/ScorerSlice';

const scrollToWithContainer = (id) => {
  let goToContainer = new Promise((resolve, reject) => {
    Events.scrollEvent.register("end", () => {
      resolve();
      Events.scrollEvent.remove("end");
    });

    scroller.scrollTo('message-container', {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  });

  goToContainer.then(() =>
    scroller.scrollTo(`${id}`, {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "message-container",
      offset: -250
    })
  );
};

const buildMarks = (min, max) => {
  let arr = [];
  for (let i = parseInt(min); i <= parseInt(max); i++) arr.push(i);
  return arr.map((i, idx) => {
    if (i === parseInt(min)) {
      return {
        style: { color: 'red', fontSize: '7px' },
        label: <strong>{parseInt(i)}</strong>
      };
    }
    else
    if (i === parseInt(max)) {
      return {
        style: { color: 'green', fontSize: '7px' },
        label: <strong>{parseInt(i)}</strong>
      };
    } else {
      return {
        style: { fontSize: '7px' },
        label: <span>{parseInt(i)}</span>
      };
    }
  });
}

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props.description}
  </Tooltip>
);

function compare (a, b) {
  if ( a.score > b.score ) return -1;
  if ( a.score < b.score ) return 1;
  return 0;
}

const ScoreTableDetail = ({ detail, idx }) => {
  const dispatch = useDispatch();
  console.log(`<ScoreTableDetail /> ${detail.qutter.id}`);
  const detail_scorer = useSelector(state => state.scorer.per_qutter[detail.qutter.id]);
  console.log('detail', detail, detail_scorer)

  if (!detail_scorer) {
    console.log(`!detail_scorer: ${detail.qutter.id}`, detail, detail_scorer);
    return <div>Loading question... {detail.qutter.id}</div>;
  }

  const onSliderChange = value => {
    console.log(value);
    dispatch(updateQutterScore({
      qutter_id: detail.qutter.id,
      scale_max: detail.qutter.manual_score.scale[1],
      new_score: value,
    }));
    dispatch(updateTotals());
  };

  const tags = (detail_scorer.tags && detail_scorer.tags.length ? detail_scorer.tags: null);
  const severity_degree = (detail_scorer.severity_degree ? detail_scorer.severity_degree: null);

  let bg = '';
  if (detail_scorer.auto_score_success === false) {
    if (severity_degree && severity_degree === 1)
      bg = 'score-warning'
    else if (severity_degree && severity_degree === 2)
      bg = 'score-fail'
    else bg = 'bg-soft-dark'
  }
  else bg = 'score-success';

  const cats = { ...detail_scorer.categories };
  let categories = [];
  if (cats) {
    categories = Object.keys(cats).map((key, idx) => {
      return {
        ...cats[key],
        label: key
      }
    });
    categories = categories.sort(compare);
  }

  const weight = detail_scorer.weight ? detail_scorer.weight: 0;
  const weighted = detail_scorer.weighted ? detail_scorer.weighted: 0;
  const score = detail_scorer.score ? detail_scorer.score: 0;
  const predicted = detail_scorer.predicted ? detail_scorer.predicted: null;
  let edited = null;
  if ('edited' in detail_scorer)
    edited = detail_scorer.edited;
  else if (predicted || predicted === 0) {
    if (predicted !== score) edited = true;
    else edited = false;
  }

  return (
    <Container 
      id={`score-table-row-${detail.qutter.id}`} 
      className={`p-1 ${bg}`} 
      style={{ border: '1px gray solid' }}
    >
      <Row key={`popover-row-${idx}`} className='mb-1'>
        <Col>
          <Form.Label>
            <span style={{ fontSize: '10px' }}>
              {detail.qutter.manual_score.name}
            </span>
            {predicted !== null && !isNaN(predicted) && (
              <span className='d-block font-size-10'>Predicted: {predicted.toFixed(1)}</span>
            )}
          </Form.Label>
          <ScoringDetailsPopover
            name={detail.qutter.manual_score.name}
            header={'Details'}
            is_weighted={detail_scorer.is_weighted}
            score={score}
            predicted={predicted}
            edited={edited}
            weight={weight}
            weighted={weighted}
          />
        </Col>
        <Col>
          <Slider
            min={detail.qutter.manual_score.scale[0]}
            max={detail.qutter.manual_score.scale[1]}
            step={detail.qutter.manual_score.step ? detail.qutter.manual_score.step: .2}
            defaultValue={score}
            dots
            marks={buildMarks(detail.qutter.manual_score.scale[0], detail.qutter.manual_score.scale[1])}
            onFocus={() => {
              scrollToWithContainer(detail.qutter.id)
              var element = document.getElementById(`${detail.qutter.id}`);
              element.classList.add('red');

              document.getElementById(`score-table-row-${detail.qutter.id}`).classList.add('highlight-score-table-row');
            }}
            onBlur={() => {
              var element = document.getElementById(`${detail.qutter.id}`);
              element.classList.remove('red');

              document.getElementById(`score-table-row-${detail.qutter.id}`).classList.remove('highlight-score-table-row');
            }}
            onChange={onSliderChange}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {tags && tags.length ? (
            tags.map((tag, idx) => {
              if (tag === 'PASSED') return (<></>);
              else {
                return (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip({ description: ANSWER_TAGS[tag].description })}
                  >
                    <Badge bg="primary" className="me-2">{tag.split('_').join(' ')}</Badge>
                  </OverlayTrigger>
                )
              }
            })
          ):(<></>)}
        </Col>

        <Col>
        {categories && categories.length ? (
          categories.map((category, idx) => {
            return (
              <ExperiencePopover key={`cat-${idx}`} header={category.label} data={category} />
            )
          })
        ):(<></>)}
        </Col>

      </Row>
    </Container>
  );
};

export default ScoreTableDetail;
