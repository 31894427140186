import React from 'react';
import { useQuery } from '@apollo/client';

import RoleSelectSingle from './RoleSelectSingle';
import RoleSelectMulti from './RoleSelectMulti';
import { QUERY_GET_ALL_ROLES } from '../api/gql';

function formatRoleName (role_id) {
  let label = role_id.replaceAll('_', ' ').split(' ');
  return label.map(l => { return capitalizeFirstLetter(l) }).join(' ');
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const RoleSelect = ({
  selected=[],
  job_id,
  role_id,
  disabled,
  scoring_template=null,
  multi=false,
  on_select,
  placeholder,
  className
}) => {
  const { loading: all_roles_loading, error: all_roles_error, data } = useQuery(QUERY_GET_ALL_ROLES);

  let mapping = [
    { label: 'General/Suitable for all', value: 'general' },
  ];

  if (all_roles_loading) return 'Loading role list...';
  if (all_roles_error) return `Error! ${all_roles_error.message}`;

  data.GetAllJobRoles.map(r => {
    mapping.push({
      value: r.role_id,
      label: formatRoleName(r.role_id),
    });
  });

  if (multi) {
    return (
      <RoleSelectMulti
        role_list={mapping}
        job_id={job_id}
        role_id={role_id}
        disabled={disabled}
        on_select={on_select}
        selected={selected}
        placeholder={placeholder}
        className={className}
      />
    );
  }

  return (
    <RoleSelectSingle
      role_list={mapping}
      job_id={job_id}
      role_id={role_id}
      disabled={disabled}
      scoring_template={scoring_template}
      on_select={on_select}
      placeholder={placeholder}
      className={className}
    />
  );
};
