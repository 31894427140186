import React from 'react';
import { useSelector } from 'react-redux';
import { Col, } from 'react-bootstrap';

import { PreviewSelectOption } from '..';

export const PreviewSelectOptions = ({
  template_id,
  qutter_id,
  order
}) => {
  // console.log('<PreviewOptionsLight />', qutter_id);
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const qutter = qutterConfig[qutterConfig.language.current];
  if (!qutter) return (<span>Options missing.</span>);

  return (
  <div className='bubble-candidate mt-3'>
    {qutter.options && qutter.options.length ? (
      <>
      {qutter.options.map((option, idx) => {
        return (
          <PreviewSelectOption
            key={`${qutter_id}-option-${option.id}-${idx}`}
            template_id={template_id}
            qutter_id={qutter_id}
            option_idx={idx}
            order={order}
          />
        )
      })}
      </>
    ):(
      <div className=''>
        <Col className=''>
          <span>No options added.</span>
        </Col>
      </div>
    )}
  </div>
  );
};

export default PreviewSelectOptions;
