import React, { useContext, useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import NavbarTop from '../_components/navbars/NavbarTop';
import { NavbarTop } from 'features/Navbar';
// import Dashboard from '../components/dashboards/default';
// import NavbarTop from '../components/navbar/top/NavbarTop';
// import NavbarVertical from '../components/navbar/vertical/NavbarVertical';
// import MainRoutes from './MainRoutes';
// import Footer from '../components/footer/Footer';

import { getAccessToken, setAccessToken, fetchAccessToken } from 'tokens/tokens';
import { setIsLoggedIn, setIsLoggedOut } from '_components/config/ConfigSlice';

const MainLayouts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const { hash, pathname } = useLocation();

  useEffect(() => {
    fetchAccessToken()
      .then(({ accessToken, user }) => {
        if (accessToken) {
          setAccessToken(accessToken);
          dispatch(setIsLoggedIn({
            user: {
              ...user,
              userType: 'cs'
            }
          }));
          setIsLoadingAuth(false);
        } else {
          setAccessToken('');
          dispatch(setIsLoggedOut());
          return navigate(`/login?redirect=${pathname}`);
        }
      })
      .catch((err) => {
        setAccessToken('');
        dispatch(setIsLoggedOut());
        console.error(err);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      {isLoadingAuth ? (
        <span>loading</span>
      ):(
        <div>
          {/* <NavbarTop /> */}
          <NavbarTop />
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default MainLayouts;
