import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

import { Card, Form } from 'react-bootstrap';
import { JsonPreview } from 'features/common';

export const PredictedLanguage = ({ language }) => {

  // useEffect(() => {
  //   if (!result) return;
  //   if (result.language) set_language(result.language);
  //   if (result.text_en) set_text_en(result.text_en);
  //   if (result.text_sv) set_text_sv(result.text_sv);
  //   if (result.intent) set_intent(result.intent);
  //   if (result.sentiment) set_sentiment(result.sentiment);
  //   if (result.entities) set_entities(result.entities);

  // }, [result]);

  return (
  <div>
    Predicted Language: {language}
  </div>
  );
};
