import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  removeQutterFromBlock
} from 'features/Template/slices/TemplateSlice';

import { removeFollowup } from 'features/Parameters';

import {
  remove_qutter_from_template_summary_list,
  remove_scoring_categories
} from 'features/TemplateOutline';

import { removeEvaluation } from 'features/QutterEvaluation';

export const RemoveQutterFromTemplate = ({
  template_id,
  block_id,
  qutter_id,
  is_followup,
  parent_id
}) => {
  const dispatch = useDispatch();
  // console.log('<RemoveQutter />', parent_id, qutter_id);
  const template = useSelector(state => state.Template[template_id]);
  const languages = template.language_settings.list;

  const parentQutterConfig = useSelector(state => state.Library[parent_id]);
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;

  const onClickRemoveQuestion = () => {
    if (is_followup) {
      dispatch(removeFollowup({
        id: template_id,
        parent_id,
        qutter_id,
        type: parentQutterConfig.type,
        options: parentQutterConfig[language].options
      }))
    } else {
      dispatch(
        removeQutterFromBlock({
          id: template_id,
          block_id,
          qutter_id
      }));
    }
    dispatch(remove_qutter_from_template_summary_list({ 
      languages, id: qutter_id
    }));

    // dispatch(remove_scoring_categories({ 
    //   template_id, 
    //   qutter_config,
    //   is_followup
    // }));

    dispatch(removeEvaluation({ qutter_id }));
  };

  return (
  <OverlayTrigger
    placement='top'
    overlay={
    <Tooltip id="button-tooltip-2">
      {is_followup ? ('Remove followup'):('Remove from block')}
    </Tooltip>
    }
  >
  {({ ref, ...triggerHandler }) => (
    <span
      className={`hover-blue font-size-10 me-3`}
      ref={ref}
      {...triggerHandler}
      onClick={onClickRemoveQuestion}
    >
      <FontAwesomeIcon icon={faTrash} />
    </span>
  )}
  </OverlayTrigger>
  );
};