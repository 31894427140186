import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';

import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { ScoreSummaryHeader } from './ScoreSummaryHeader';
import { ApplicationQualified } from 'features/Application';
import { BonuspointSummary } from './BonuspointSummary';
import { WeightedSummary } from './WeightedSummary';

import { 
  post_score,
  update_score,
  update_summary,
  update_integration
} from 'features/Scoring/api/api';

export const ScoreSummary = ({
  job,
  summary,
  application,
  candidate,
  interview
}) => {
  const scoring = useSelector(state => state.scoring);
  const chat = useSelector(state => state.scoring.chat);

  const user = useSelector(state => state.config.user);
  const [startTime, setStartTime] = [new Date()];

  // Summary
  const [summary_id, setSummaryId] = useState((summary ? summary._id: null));
  const [showSummary, setShowSummary] = useState(false);
  const [is_updating, set_is_updating] = useState(false);

  const [updateSummary, setUpdateSummary] = useState(true);
  const [updateIntegration, setUpdateIntegration] = useState(job.integration ? true: false);

  const onUpdateClick = async () => {
    set_is_updating(true);
    const fullname = user.first_name + ' ' + user.last_name;

    let manual_score = {
      job_id: job._id,
      application_id: application._id,
      candidate_id: candidate._id,
      interview_id: interview._id,
      template_id: interview.template_id,

      role_id: job.role_id,
      threshold: job.threshold,
      bonus_booster: 0,
      score_time: (new Date() - startTime)/1000,
      total_score: scoring.total,

      bonuspoints_max: scoring.bonuspoints_max,
      bonuspoints_sum: scoring.bonuspoints_sum,
      qualified:       scoring.qualified,

      weighted_total: scoring.weighted_sum,
      weighted_max: scoring.weighted_max,

      tagged_by: fullname,
      tagged_by_admin_id: user._id,
    };

    for (let i = 0; i < scoring.list.length; i++) {
      const score_id  = scoring.list[i];
      const detail    = scoring.details[score_id];

      let sc = {
        score:      detail.score,
        predicted:  detail.predicted,
        value:      detail.value,
        edited:     detail.edited,
        displayed_score: detail.displayed_score
      };
      manual_score[score_id] = sc;
      if (detail.is_weighted) {
        manual_score[score_id].is_weighted    = true;
        manual_score[score_id].weight         = detail.weight;
        manual_score[score_id].weighted_score = detail.weighted_score;
      }
      else 
        manual_score[score_id].is_weighted = false;
    }

    let conversation = JSON.parse(JSON.stringify(chat));
    for (let i = 0; i < conversation.length; i++) {
      let collection = conversation[i];
      if (!collection.hasOwnProperty('scoring_detail'))
        continue;

      let scoring_detail = collection.scoring_detail;
      if (Array.isArray(scoring_detail)) {
        let list = [];
        for (let j = 0; j < scoring_detail.length; j++) {
          const detail = scoring_detail[j];
          let score = null;
          if (detail.hasOwnProperty('category_id')) {
            const category_id = detail.category_id;
            score = manual_score[category_id];
          } else {
            const qutter_id = scoring_detail.qutter_id;
            score = manual_score[qutter_id];
          }
          list.push(score);
        }
        conversation[i].score = list;
      } else {
        if (scoring_detail.hasOwnProperty('category_id')) {
          const category_id = scoring_detail.category_id;
          conversation[i].score = manual_score[category_id];
        } else {
          const qutter_id = scoring_detail.qutter_id;
          conversation[i].score = manual_score[qutter_id];
        }
      }
    }

    // console.log('manual_score', manual_score);
    // console.log('conversation', conversation);

    let abort = false;

    await update_score(interview._id, manual_score)
    .then((result) => {
      toast.success(`Score updated.`);
    }).catch((error) => {
      abort = true;
      toast.error(error.message);
    });

    if (abort) {
      set_is_updating(false);
      return;
    }

    if (updateSummary) {
      await update_summary(interview._id, conversation)
      .then((result) => {
        toast.success(`Summary updated.`);
        setSummaryId(result.summary_id);
        setShowSummary(true);
      }).catch((error) => {
        abort = true;
        console.log('error', error)
        if (error && error.message)
          toast.error(error.message);
        else toast.error(error);
      });
    }

    if (abort) {
      set_is_updating(false);
      return;
    }

    if (updateIntegration) {
      await update_integration(interview._id)
      .then((result) => {
        console.log('result', result)
        if (result.hasOwnProperty('integration_update')) {
          const platform = result.integration_update.platform;
          const message = result.integration_update.message;
          toast.success(`${platform} card update success. ${message}`);

        } else {
          if (typeof result === 'string')
            toast.info(`Unknown return object. ${result}`);
          else {
            toast.info(`Unknown return object. ${JSON.stringify(result)}`);
          }
        }
      }).catch((error) => {
        console.info('updateIntegration error', { error });
        if(axios.isAxiosError(error) && error.response?.status === 404) {
          toast.info(`Application not found in integration, probably already rejected.\n${error.message}`);
        }
        else toast.error(error.response?.data?.message ?? 'An error occured when updating scores via integration,.');
      });
    } else toast.info('Integration chosen not to be updated.');

    set_is_updating(false);
    return;

    /*
    post_score(
      conversation,
      manual_score,
      interview._id,
    )
    .then((result) => {

      if (!result.summary_id) 
        return toast.error('Error: no summary id.');

      // update_integration
      // create_summary

      setSummaryId(result.summary_id);
      setShowSummary(true);
      toast.success(`Score and summary updated. Summary id: ${summary_id}`);

      if (result.hasOwnProperty('integration_update') && result.integration_update) {
        const platform = result.integration_update.platform;
        if (platform === 'teamtailor') {
          if (result.integration_update.data.card) {
            const card = result.integration_update.data.card;
            if (card.status === 200)
              toast.success(`${platform} card update success.`);
            else {
              let msg = card.message;
              card.error.forEach(e => {
                msg += `\n${e.title} (${e.code}): ${e.detail}`;
              })
              toast.error(msg);
            }
          }
  
          if (result.integration_update.data.move) {
            const move = result.integration_update.data.move;
            if (move.status === 200)
              toast.success(`${platform} move success.`);
            else {
              let msg = move.message;
              move.error.forEach(e => {
                msg += `\n\n${e.title} (${e.code}): ${e.detail}`;
              })
              toast.error(msg);
            }
          }
        }
      }

    }).catch((error) => {
      if (error.type === 'error')
        toast.error(error.message);
      else toast.warning(error.message);

    }).finally(() => {
      set_is_updating(false);
    });
    */
  };

  return (
  <div
    id='score-summary-container'
    className='text-center p-1 row-fluid'
    style={{}}
  >

    <ScoreSummaryHeader
      application_id={application.id}
      summary_id={summary_id}
      interview_id={interview.id}
      showSummary={showSummary}
      setShowSummary={setShowSummary}
    />

    <BonuspointSummary />
    <WeightedSummary />

    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Qualified</span>
      <span className='p-0 text-center'>
        <ApplicationQualified qualified={scoring.qualified} />
      </span>
    </div>

    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Threshold</span>
      <span className='p-0 text-center'>
        {job.threshold && job.threshold ? job.threshold: 'Threshold not set'}
      </span>
    </div>

    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block'>Total</span>
      <span
        className={`p-1 text-center ${(scoring.total > job.threshold ? 'bg-success': 'bg-warning')}`}
        style={{ borderRadius: '5px' }}
      >
        {scoring.total.toFixed(1)}
      </span>
    </div>

    {job.integration ? (
    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Update integration</span>
      <span className='p-0 text-center'>
        <Form.Check
          type="switch"
          id="update-integration-switch"
          // defaultValue={updateIntegration}
          checked={updateIntegration}
          onChange={(e) => {
            e.persist();
            setUpdateIntegration(e.target.checked);
          }}
        />
      </span>
    </div>
    ):(null)}

    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Update summary</span>
      <span className='p-0 text-center'>
        <Form.Check
          type="switch"
          id="update-summary-switch"
          // defaultValue={updateSummary}
          checked={updateSummary}
          onChange={(e) => {
            e.persist();
            setUpdateSummary(e.target.checked);
          }}
        />
      </span>
    </div>

    <div
      className='text-center App-header'
      style={{ WebkitFlex: 'none', height: '75px' }}
    >
      <Button
        className='btn-sm m-auto text-center'
        style={{ position: 'fixed', bottom: '5px', right: '2px' }}
        variant="primary"
        disabled={is_updating}
        onClick={onUpdateClick}
      >
        {is_updating ? 'Updates...' : 'Update'}
      </Button>
    </div>

  </div>
  );
};
