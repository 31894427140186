import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ScoreBoardDetail } from './ScoreBoardDetail';

export const ScoreBoard = ({}) => {
  const scoring = useSelector(state => state.scoring);

  return (
    scoring.list.map((id, idx) => {
      return (
        <ScoreBoardDetail
          key={`scoreboard-detail-${idx}`} 
          detail={scoring.details[id]}
          id={id}
        />
      );
    })
  );
};
