import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

export const DeleteRuleActionButton = ({
  template_id,
  is_default,
  on_click,
  className
}) => {

  if (template_id) {
    if (is_default) {
      return (null)
    } else {
      return (
        <span
          className={`${className} font-size-10 hover-blue`}
          onClick={(e) => { on_click(e); }}
        >
          <FontAwesomeIcon icon={faTrash} className="me-1"/>
          Delete Action
        </span>
      );
    }
  } else {
    return (
      <span
        className={`${className} font-size-10 hover-blue`}
        onClick={(e) => { on_click(e); }}
      >
        <FontAwesomeIcon icon={faTrash} className="me-1"/>
        Delete Action
      </span>
    );
  }
};