import React, { useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';

import { ClientSelect } from 'features/ClientSelect';
import { TemplateSelect } from 'features/TemplateSelect';
import { RoleSelect } from 'features/RoleSelect';
import { CreateJobButton } from './CreateJobButton';

export const CreateJobForm = ({
}) => {
  const [title, set_title] = useState(null);
  const [template_id, set_template_id] = useState(null);

  const [client_id, set_client_id] = useState(null);
  const [client_name, set_client_name] = useState(null);
  const [company_name, set_company_name] = useState(null);

  const [role_id, set_role_id] = useState(null);
  const [role_name, set_role_name] = useState(null);
  const [threshold, set_threshold] = useState(0);

  const [error, set_error] = useState('');
  const [success, set_success] = useState(null);

  return (
    <Container>

      {/* Title */}
      <Form.Group className="mb-3" controlId="form-title">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          defaultValue={title}
          placeholder="Lagerarbetare till lagerarbetareföretaget"
          onChange={(e) => { set_title(e.target.value) }}
        />
      </Form.Group>

      {/* Client */}
      <Form.Group className="mb-3" controlId="form-client">
        <Form.Label>Client</Form.Label>
        <ClientSelect
          on_select={(value, selected) => {
            set_client_name(value.label);
            set_company_name(value.label);
            set_client_id(value.value);
          }}
        />
      </Form.Group>

      {client_id && (
        <>
        {/* Company name */}
        <Form.Group className="mb-3" controlId="form-company-name">
          <Form.Label>Company name</Form.Label>
          <Form.Control 
            type="text"
            defaultValue={company_name}
            placeholder="First Claes Transport"
            onChange={(e) => { set_company_name(e.target.value) }}
          />
        </Form.Group>

        {/* Template */}
        <Form.Group className="mb-3" controlId="form-template">
          <Form.Label>Template</Form.Label>
          <TemplateSelect
            client_id={client_id}
            on_select={(selected, selected_template) => { 
              set_template_id(selected.value);
              if (selected_template && selected_template.role && selected_template.role.id) {
                setTimeout(() => {
                  set_role_id(selected_template.role.id);
                }, 500);
              }
            }}
          />
        </Form.Group>

        {template_id && (
          <div>
            {/* Role ID */}
            <Form.Group className="mb-3" controlId="form-role-id">
              <Form.Label>Role</Form.Label>
              <RoleSelect
                role_id={role_id}
                disabled={false}
                on_select={(selected) => {
                  set_role_id(selected.value);
                }}
              />
            </Form.Group>

            {/* Role name */}
            <Form.Group className="mb-3" controlId="form-role-name">
              <Form.Label>Custom Role name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={role_name}
                placeholder="Lagertrucksmaskinist med simfötter"
                onChange={(e) => {
                  console.log('Role name', e.target.value)
                  set_role_name(e.target.value) 
                }}
              />
            </Form.Group>

            {/* Threshold */}
            <Form.Group className="mb-3" controlId="form-threshold">
              <Form.Label>Threshold</Form.Label>
              <Form.Control
                type="number"
                defaultValue={threshold}
                placeholder="65"
                min={0}
                max={100}
                onChange={(e) => { 
                  console.log('theshold', e.target.value)
                  set_threshold(e.target.value) 
                }}
              />
            </Form.Group>
          </div>
        )}
        </>
      )}

      {error && (
        <div className='mb-3 text-center red'>{error}</div>
      )}

      {success && (
        <div className='mb-3 text-center' style={{ color: 'green' }}>
          {success.message}
        </div>
      )}

      <div className='float-end'>
        <CreateJobButton
          title={title}
          client_id={client_id}
          template_id={template_id}
          company_name={company_name}
          role_id={role_id}
          role_name={role_name}
          threshold={threshold}
          set_error={set_error}
          set_success={set_success}
        />
      </div>
    </Container>
  );
};
