import React from 'react';

import { Badge, Offcanvas } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from '_components/common/SoftBadge';

import { CandidateHeader } from '..';

export const CandidateOffCanvas = ({
  candidate,
  job_id,
  application_id,
  interview_id,
  candidate_id,
  show,
  setShow
}) => {
  return (
    <Offcanvas
      onClick={(e) => { e.stopPropagation(); }}
      show={show}
      scroll={true}
      backdrop={true}
      data-bs-keyboard="false"
      // className='w-75'
      style={{
        width: '50%',
        height: '100%',
      }}
    >
      <Offcanvas.Body>
        <CandidateHeader candidate={candidate} />
      </Offcanvas.Body>
    </Offcanvas>
  );
};
