import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';

import { CreateBlockModal } from '..';

export const CreateTemplateBlock = ({ template_id, block_idx, block_id }) => {
  // const dispatch = useDispatch();
  // console.log('<CreateBlock />');
  const [addBlockModalShow, setAddBlockModalShow] = useState(false);
  const [addBlockIdx, setAddBlockIdx] = useState(null);
  const templateConfig = useSelector(state => state.Template);

  const onAddBlockClick = (block_id, block_idx, event) => {
    console.log('onAddBlockClick: ', block_id, block_idx, event);
    setAddBlockIdx(block_idx);
    addBlockModalShow
      ? setAddBlockModalShow(false)
      : setAddBlockModalShow(true);
  };

  return (
    <Row className="text-center m-auto mt-2 mb-2">
      {addBlockModalShow && (
        <CreateBlockModal
          template_id={template_id}
          show={addBlockModalShow}
          setShow={setAddBlockModalShow}
          block_idx={block_idx}
        />
      )}
      {templateConfig.readOnlyMode ? (null) : 
      (
        <Col>
          <span
            onClick={e => onAddBlockClick(block_id, block_idx, e)}
            className="hover-blue"
          >
            <span className="font-size-10 me-2">
              <FontAwesomeIcon icon={faPlusCircle} />
            </span>
            <span className="font-size-10">Add Block</span>
          </span>
        </Col>
      )}
    </Row>
  );
};
