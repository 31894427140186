import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';

import {
  Row,
  Button,
  Modal,
  Container,
  Col,
  Form,
  InputGroup
} from 'react-bootstrap';
import Divider from 'components/common/Divider';
import { JsonPreview } from 'features/common';

import { ClientSelect } from 'features/ClientSelect';
import { RoleSelect } from 'features/RoleSelect';
import { LanguageSelect } from 'features/LanguageSelect';
import { TemplateSelect } from 'features/TemplateSelect';

import {
  SaveNewTemplate,
  CreateTemplateFromScratch,
  GetTemplateById
} from 'features/Template/slices/thunks';

export const CreateTemplateModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  let Navigate = useNavigate();
  const [type, setType] = useState('');
  const [template, setTemplate] = useState({});
  const [template_id, setTemplateId] = useState('createNewTemplate');
  const [company, set_company] = useState('');
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const [validated, setValidated] = useState(true);
  // const template = useSelector(state => state.Template[template_id]);
  const status = useSelector(state => state.Template.status);

  useEffect(() => {
    if (
      template_id &&
      template_id.length &&
      template_id !== 'createNewTemplate'
    ) {
      setDisableSaveButton(false);
      dispatch(GetTemplateById(template_id)).then(r => {
        delete r.payload._id;
        delete r.payload.client_id;
        delete r.payload.client_details;
        console.log('r', r)
        setTemplate(r.payload);
      });
    }
  }, [template_id]);

  useEffect(() => {
    console.log('fake reload');
  }, [template]);

  const onSaveClick = () => {
    // TODO: VALIDATION - Validate the saved template
    console.log('Save template', template)
    if (template_id !== 'createNewTemplate') {
      dispatch(SaveNewTemplate({ id: type, ...template })).then(r => {
        return Navigate(`/templates/${r.payload.id}`);
      });
    }
  };

  const onCreateFromScratchClick = () => {
    dispatch(CreateTemplateFromScratch()).then(r => {
      return Navigate(`/templates/${r.payload.id}`);
    });
  };

  console.log('template', template);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      dialogClassName="create-question-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span>Create Template</span>
        </Modal.Title>

        <div>
          <span
            onClick={() => {
              onCreateFromScratchClick();
            }}
            className="hover-blue font-size-10 ms-2"
          >
            <FontAwesomeIcon className="me-1" icon={faCirclePlus} />
            Create from scratch
          </span>
        </div>
      </Modal.Header>

      <Modal.Body className="">
        {status && status === 'loading' ? (
          <p>Loading...</p>
        ) : (
          <>
            <Divider className="mt-4">Details</Divider>

            <Row className="bg-100 m-1 p-1">
              <Col className="m-auto text-center">
                <span className="m-auto">Base new template on: </span>
              </Col>
              <Col xs={8}>
                <TemplateSelect
                  on_select={e => {
                    setTemplateId(e.value);
                  }}
                  selected_template_id={template_id}
                  clearable={true}
                />
              </Col>
            </Row>

            {template_id && template && template.role ? (
              <>
                <Row className="bg-100 m-1 p-1">
                  <Col className="m-auto text-center">
                    <span className="m-auto">Template Name</span>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      placeholder='"Template Name"'
                      defaultValue={template.name}
                      onChange={e => {
                        template.name = e.target.value;
                        setTemplate(template);
                      }}
                    />
                  </Col>
                </Row>

                <Row className="bg-100 m-1 p-1">
                  <Col className="m-auto text-center">
                    <span className="m-auto">Template Description</span>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      placeholder={`This is the interview questions for ${company}'s ${template.role.name} role`}
                      defaultValue={template.description}
                      onChange={e => {
                        template.description = e.target.value;
                        setTemplate(template);
                      }}
                    />
                  </Col>
                </Row>

                {/*
                <Row className="bg-100 m-1 p-1">
                  <Col className="m-auto text-center">
                    <span className="m-auto">Is Default Template</span>
                  </Col>
                  <Col xs={8}>
                    <InputGroup size="sm" className="m-auto">
                      <Form.Check
                        type="switch"
                        id="defaultTemplateSwitch"
                        className="m-0"
                        label={
                          template.default === true
                            ? 'Default Template'
                            : 'Default Template'
                        }
                        defaultChecked={template.default}
                        onChange={e => {
                          template.default = e.target.checked;
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row className="bg-100 m-1 p-1">
                  <Col className="m-auto text-center">
                    <span className="m-auto">Client</span>
                  </Col>
                  <Col xs={8}>
                    <ClientSelect
                      on_select={e => {
                        template.client_id = e.value;
                        set_company(e.label);
                      }}
                      selected_client_id={
                        template.client_id ? template.client_id : null
                      }
                    />
                  </Col>
                </Row>

                <Row className="bg-100 m-1 p-1">
                  <Col className="m-auto text-center">
                    <span className="m-auto">Template Language</span>
                  </Col>
                  <Col xs={8}>
                    <LanguageSelect
                      multi={true}
                      on_select={e => {
                        let languages = e.map(language => {
                          return language.value;
                        });
                        template.language_settings.list = languages;
                        setTemplate(template);
                      }}
                      selected={template.language_settings.list}
                    />
                  </Col>
                </Row>

                <Row className="bg-100 m-1 p-1">
                  <Col className="m-auto text-center">
                    <span className="m-auto">Role</span>
                  </Col>
                  <Col xs={8}>
                    <div>
                      <RoleSelect
                        on_select={e => {
                          template.role_id = e.value;
                          template.role.id = e.value;
                          setTemplate(template);
                        }}
                        role_id={template.role.id ? template.role.id : null}
                      />
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextSearch"
                    >
                      <Form.Label>Your Role name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder='"Warehouseworker with a twist"'
                        defaultValue={template.role.name}
                        onChange={e => {
                          template.role.name = e.target.value;
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                */}
              </>
            ) : (
              <></>
            )}

            <JsonPreview data={template} />
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Container>
          <Row className="float-end">
            <Col>
              <Button
                className="m-1"
                onClick={() => {
                  setTemplate({});
                  setTemplateId('createNewTemplate');
                  set_company('');
                  setDisableSaveButton(true);
                  setShowModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="m-1"
                disabled={disableSaveButton}
                onClick={() => {
                  onSaveClick();
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};
