import { isEmpty } from 'lodash';

const initialState = {};

export function ResetEvaluation (state, action) {
  const { evaluation } = action.payload;

  if (evaluation === null) {
    return undefined;
  }

  return {
    ...evaluation
  };
}

export function InitiateEvaluation (state, action) {
  const { languages, qutter_id, is_followup } = action.payload;
  let res = { 
    [qutter_id]: {
      is_followup: is_followup
    }
  };
  languages.map(language => {
    res[qutter_id][language] = {
      is_followup: is_followup
    };
  });
  return {
    ...state,
    ...res
  };
}

export function RemoveEvaluation (state, action) {
  const { qutter_id } = action.payload;
  delete state[qutter_id];
}

export function SetDefaultEvaluation (state, action) {
  const { language, qutter_id, id, evaluation } = action.payload;

  let key = '';
  if (!id) {
    key = `evaluation`;
  } else 
    key = `${id}.evaluation`;

  console.log(`SetDefaultEvaluation ${qutter_id}`, evaluation, state[qutter_id][language][key]);
  if (!state[qutter_id][language][key]) {
    return {
      ...state,
      [qutter_id]: {
        ...state[qutter_id],
        is_default: true,
        [language]: {
          is_default: true,
          ...state[qutter_id][language],
          [key]: evaluation
        }
      }
    };
  }
}

export function SetCustomEvaluation (state, action) {
  const { language, qutter_id, id, evaluation } = action.payload;
  let _key = `${id}.evaluation`;

  if (!id) {
    _key = `evaluation`;
  } else 
    _key = `${id}.evaluation`;

  if (state[qutter_id] && state[qutter_id][language]) {
    return {
      ...state,
      [qutter_id]: {
        ...state[qutter_id],
        is_default: false,
        [language]: {
          is_default: false,
          ...state[qutter_id][language],
          [_key]: evaluation
        }
      }
    };
  } else {
    if (!state[qutter_id]) 
      state[qutter_id] = { [language]: {} };
    state[qutter_id][language] = {
      ...state[qutter_id],
      is_default: false,
      [language]: {
        is_default: false,
        ...state[qutter_id][language],
        [_key]: evaluation
      }
    };
  }
}

export const update_followup_scoring_category = (
  categories,
  category,
  qutter_id,
  weight
) => {
  if (categories.hasOwnProperty(category)) {
    if (categories[category].optional.indexOf(qutter_id) === -1) {
      categories[category].optional.push(qutter_id);
      categories[category].weights.push(weight);
    }
  } else {
    categories.list.push(category);
    // categories[category] = [[qutter_id]];
    categories[category] = {
      required: [],
      weight: weight,
      weights: [weight],
      optional: [[qutter_id]]
    };
  }
};

export const update_non_followup_scoring_category = (
  categories,
  category,
  qutter_id,
  weight
) => {
  if (categories.hasOwnProperty(category)) {
    if (categories[category].required.indexOf(qutter_id) === -1) {
      categories[category].required.push(qutter_id);
      categories[category].weights.push(weight);
    }
  } else {
    categories.list.push(category);
    // categories[category] = [qutter_id];
    categories[category] = {
      required: [qutter_id],
      weight: weight,
      weights: [weight],
      optional: []
    };
  }
};

// Scoring categories
export function UpdateScoringCategories (state, action) {
  const {
    qutter_id,
    category,
    weight,
    is_followup
  } = action.payload;
  const categories = state.scoring_categories;

  if (is_followup) {
    update_followup_scoring_category(
      categories,
      category,
      qutter_id,
      weight
    );
  } else {
    update_non_followup_scoring_category(
      categories,
      category,
      qutter_id,
      weight
    );
  }
};

export function UpdateCategoryWeight (state, action) {
  const {
    category_id,
    weight,
  } = action.payload;
  const categories = state.scoring_categories;
  const category = categories[category_id]
  category.weight = weight;
};

// remove_scoring_categories (state, action) {
  // const { template_id, qutter_config, is_followup } = action.payload;
  // const categories = state[template_id].scoring_categories;
  // if (!qutter_config.hasOwnProperty('scoring_category')) return;
  // const scoring_category = qutter_config.scoring_category;

  // let required = categories[scoring_category].required;
  // let optional = categories[scoring_category].optional;

  // // Remove from category list
  // let idx = null;
  // if (is_followup) {
  //   idx = optional.indexOf(qutter_config.id);
  //   if (idx > -1) optional.splice(idx, 1);
  // } else {
  //   idx = required.indexOf(qutter_config.id);
  //   if (idx > -1) required.splice(idx, 1);
  // }

  // // Remove category from list of categories 
  // // if optional and required lists are empty
  // if (required.length === 0 && optional === 0) {
  //   idx = categories.list.indexOf(scoring_category);
  //   if (idx > -1) {
  //     categories.list.splice(idx, 1);
  //   }
  // }
// };