import React from 'react';
import { QutterEvaluationSelectSingle } from './QutterEvaluationSelectSingle';
import { QutterEvaluationSelectMultiple } from './QutterEvaluationSelectMultiple';

export const QutterEvaluationSelect = ({
  multi,
  disabled,
  on_select,
  selected,
  placeholder,
  className
}) => {

  if (multi) {
    return (
      <QutterEvaluationSelectMultiple
        className={className}
        disabled={disabled}
        on_select={on_select}
        selected={selected}
        placeholder={placeholder}
      />
    )
  }

  return (
    <QutterEvaluationSelectSingle
      className={className}
      disabled={disabled}
      on_select={on_select}
      selected={selected}
      placeholder={placeholder}
    />
  );
};
