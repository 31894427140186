import React from 'react';
import {
  Container,
  Badge,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';

const popover = ({
  name,
  header,
  is_weighted,
  score,
  predicted,
  edited,
  weight
}) => {

  return (
  <Popover id="popover-basic" style={{ minWidth: '200px !important', width: '200px !important' }}>
    <Popover.Header as="h3">
      {( is_weighted === true ) ? (
        <strong className='h6 text-capitalize text-decoration-underline'>
          Weighted question<br/>
        </strong>
      ):(
        <strong className='h6 text-capitalize text-decoration-underline'>
          Bonus question<br/>
        </strong>
      )}
    </Popover.Header>
    <Popover.Body 
      as={Container} 
      className='w-100'
      style={{ minWidth: '200px !important', width: '200px !important' }}
    >

      {is_weighted === true ? (
        <>
          {(predicted || predicted === 0) ? (
            <span>
              {(edited === true) && (
                <Badge bg="warning" className="d-table me-2">EDITED</Badge>
              )}
              {(edited === false) && (
                <Badge bg="success" className="d-table me-2">NOT EDITED</Badge>
              )}
              <span>Predicted value: {predicted.toFixed(1)}</span>
              <br/>
            </span>
          ):(<></>)}

          {(weight || weight === 0) ? (
            <span>
              Weight: {(weight * 100).toFixed(1)}%<br/>
            </span>
          ):(<></>)}
        </>
      ):(<></>)}

      {(score || score === 0) ? (
        <strong className='h5 text-capitalize text-decoration-underline'>
          Score: {score.toFixed(1)}<br/>
        </strong>
      ):(<></>)}
    </Popover.Body>
  </Popover>)
}


export const ScoringDetailsPopover = ({
  name,
  header,
  is_weighted,
  score,
  predicted,
  edited,
  weight,
}) => {

  return (
    <OverlayTrigger 
      trigger={['click']} 
      placement='left'
      overlay={
        popover({
          header, 
          is_weighted, 
          score, 
          predicted, 
          edited, 
          weight
        })
      }
      >
        <Badge
          bg="info"
          className="font-size-10 me-2 float-end cursor-pointer">{header}</Badge>
    </OverlayTrigger>
  );
};
