import { gql } from '@apollo/client';

export const GET_APPLICATIONS = gql`
query FilterApplicationsByJob($job_id: String, $filter: String) {
  FilterApplicationsByJob(job_id: $job_id, filter: $filter) {
    id
    candidate {
      id
      firstName
      lastName
      email
    }
    interviews {
      id
      language
      manual_score {
        tagged_by
        updated_at
      }
      scoring_status {
        name
        updated_at
        history {
          name
          updated_at
        }
      }
      summary {
        id
      }
      links {
        preview
        interview
      }
    }
    interview {
      id
      language
      manual_score {
        tagged_by
        updated_at
      }
      scoring_status {
        name
        updated_at
        history {
          name
          updated_at
        }
      }
      summary {
        id
      }
    }
    stage
    status
    score
    qualified
    accepted
    deleted
    created_at
  }
}
`;
