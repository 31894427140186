import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheckCircle, faListSquares, faComment, faCalendar, faSliders } from '@fortawesome/pro-solid-svg-icons';

import { Row, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';

export const QutterTypePicker = ({
  id,
  on_select
}) => {

  return (
  <>
    <Divider className="">Question Type</Divider>
    <Row id='question-type-container'>

      <Col>
        <div
          className={`${id === 'create-info' ? 'color-blue': ''} text-center cursor-pointer hover-blue`}
          onClick={(e) => { if (on_select) { on_select('create-info'); }}}
        >
          <span className="d-block">
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
          <span className="d-block">
            Info
          </span>
        </div>
      </Col>

      <Col>
        <div
          className={`${id === 'create-yesno' ? 'color-blue': ''} text-center cursor-pointer hover-blue`}
          onClick={(e) => { if (on_select) { on_select('create-yesno'); }}}
        >
          <span className="d-block">
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
          <span className="d-block">
            Yes/no
          </span>
        </div>
      </Col>

      <Col>
        <div 
          className={`${id === 'create-choice' ? 'color-blue': ''} text-center cursor-pointer hover-blue`}
          onClick={(e) => { if (on_select) { on_select('create-choice'); }}}
        >
          <span className="d-block">
            <FontAwesomeIcon icon={faListSquares} />
          </span>
          <span className="d-block">Choice</span>
        </div>
      </Col>

      <Col>
        <div 
          className={`${id === 'create-open' ? 'color-blue': ''} text-center cursor-pointer hover-blue`}
          onClick={(e) => { if (on_select) { on_select('create-open'); }}}
        >
          <span className="d-block">
            <FontAwesomeIcon icon={faComment} />
          </span>
          <span className="d-block">Open</span>
        </div>
      </Col>

      <Col>
        <div
          className={`${id === 'create-calendar' ? 'color-blue': ''} text-center cursor-pointer hover-blue`}
          onClick={(e) => { if (on_select) { on_select('create-calendar'); }}}
        >
          <span className="d-block">
            <FontAwesomeIcon icon={faCalendar} />
          </span>
          <span className="d-block">Calendar</span>
        </div>
      </Col>

      <Col>
        <div
          className={`${id === 'create-form' ? 'color-blue': ''} text-center cursor-pointer hover-blue`}
          onClick={(e) => { if (on_select) { on_select('create-form'); }}}
        >
          <span className="d-block">
            <FontAwesomeIcon icon={faSliders} />
          </span>
          <span className="d-block">Form</span>
        </div>
      </Col>

    </Row>
  </>
  );
};
