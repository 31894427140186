export const NOT_VIEWABLE_BLOCK_IN_SUMMARY = 'Lhv-4GgyCFftpX6326Hkf';

export const default_summary_blocks = [
  {
    label: 'No show',
    value: 'Lhv-4GgyCFftpX6326Hkf',
    id: 'Lhv-4GgyCFftpX6326Hkf'
  },
  {
    label: 'Interview flow',
    value: 'LVv-4GVyCFfHpX63r6Hky',
    id: 'LVv-4GVyCFfHpX63r6Hky'
  },
  {
    label: 'Introduction',
    value: 'LVv-4GVyCwhUkXUt96Hky',
    id: 'LVv-4GVyCwhUkXUt96Hky'
  },
  {
    label: 'Education',
    value: 'sNWaU4OASe6LGZ2R496Zn',
    id: 'sNWaU4OASe6LGZ2R496Zn'
  },
  {
    label: 'Occupation',
    value: 'UxS7r2TAco1Xr3kOlZV9S',
    id: 'UxS7r2TAco1Xr3kOlZV9S'
  },
  {
    label: 'Experience & Motivation',
    value: '1QnVwuHopNxSw0QZbd6iY',
    id: '1QnVwuHopNxSw0QZbd6iY'
  },
  {
    label: 'Role specific',
    value: 'LVv-4GVyCwGtYXUt96Hky',
    id: 'LVv-4GVyCwGtYXUt96Hky'
  },
  {
    label: 'Behavioral Interview Questions',
    value: 'LUwJ3jKScVVr3UZtE2I-a',
    id: 'LUwJ3jKScVVr3UZtE2I-a'
  },
  {
    label: 'Practical',
    value: 'I4MWNCFKd3ntr3AvpO3NJ',
    id: 'I4MWNCFKd3ntr3AvpO3NJ'
  },
  {
    label: 'Certificates & Licenses',
    value: 'NnwLhZamEzUaiqFF0Hzk8',
    id: 'NnwLhZamEzUaiqFF0Hzk8'
  },
  {
    label: 'Languages',
    value: 'NnwPhZamEzUaiQFF0H9k8',
    id: 'NnwPhZamEzUaiQFF0H9k8'
  },
  {
    label: 'Personal information',
    value: 'NnwLhZamEzUaiqFF0Hzp2',
    id: 'NnwLhZamEzUaiqFF0Hzp2'
  },
  {
    label: 'Legal',
    value: 'NnwTfZamEzUaiqFF0Hzk8',
    id: 'NnwTfZamEzUaiqFF0Hzk8'
  }
];
