import { library } from '@fortawesome/fontawesome-svg-core';

import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { faHatCowboy } from '@fortawesome/pro-thin-svg-icons';
import { faHatChef } from '@fortawesome/sharp-solid-svg-icons';

library.add(fas, fat, faHatCowboy, faHatChef);

/**
Setup && Examples: 
https://fontawesome.com/docs/web/use-with/react/
https://fontawesome.com/docs/web/use-with/react/add-icons

<FontAwesomeIcon icon="fa-brands fa-font-awesome" />
<FontAwesomeIcon icon="fa-solid fa-twitter" />
<FontAwesomeIcon icon="fa-solid fa-check-square" />
Your <FontAwesomeIcon icon="fa-regular fa-coffee" /> is hot!
Compliments of the <FontAwesomeIcon icon="fa-sharp fa-solid fa-hat-chef" />!
*/