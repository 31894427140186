import React from 'react';
import Select from 'react-select';

import { AND_OR } from 'features/QutterRules/constants/condition_types';

export const AndOrSelect = ({
  disabled,
  placeholder,
  className,

  on_select,
  selected,
}) => {

  let idx = null;
  if (selected) {
    function find (obj) { return obj.value === selected; }
    idx = AND_OR.findIndex(find);
  }

  console.log('<QutterConditionSelect />', idx);
  return (
  <Select
    isMulti={false}
    className={className}
    isDisabled={disabled}
    value={(selected ? AND_OR[idx]: null)}
    placeholder={placeholder ? placeholder: ''}
    options={AND_OR}
    onChange={(e)=> {
      if (on_select) on_select(e);
    }}
  />
  );
};