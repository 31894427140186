import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { PreviewRuleCondition } from './PreviewRuleCondition';
import { PreviewRuleAction } from './PreviewRuleAction';

export const PreviewRuleDoThisIf = ({
  template_id,
  qutter_id,
  parent_id,
  rule,
  rule_idx,
  order,
 }) => {
  // console.log('<UniqueFollowups />', qutter_id);
  // const qutter_config = useSelector(state => state.Library[qutter_id]);
  // const language = qutter_config.language.current;
  // const qutter = qutter_config[language];

  // const template = useSelector(state => state.Template[template_id]);
  // const params = useSelector(state => state.Parameters[qutter_id]);

  const conditions = rule.conditions;
  const actions = rule.actions;

  return (
    <div>
      {/* <div>{rule.name} | {rule.type}</div> */}

      {conditions && conditions.map((condition, condition_idx) => {
        return (
          <PreviewRuleCondition
            key={`preview-rule-condition-${condition.id}`}
            template_id={template_id}
            parent_id={qutter_id}
            qutter_id={qutter_id}
            rule={rule}
            rule_idx={rule_idx}
            order={order}
            condition={condition}
          />
        )
      })}

      {actions && actions.map((action, action_idx) => {
        return (
          <PreviewRuleAction
            key={`preview-rule-action-${action.id}`}
            template_id={template_id}
            parent_id={qutter_id}
            qutter_id={qutter_id}
            rule={rule}
            rule_idx={rule_idx}
            order={order}
            action={action}
          />
        )
      })}

    </div>
  );
};
