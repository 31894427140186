import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Button, Modal, Container, Col } from 'react-bootstrap';

import { SelectOptionReplyText } from '..';

export const SelectReplyModal = ({
  showModal,
  setShowModal,
  id,
  option_idx
}) => {
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[qutterConfig.language.current];

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop={true}
      backdropClassName={'black'}
      dialogClassName=""
      size="s"
      aria-labelledby=""
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Hubert's replies
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">

        <Row>
          <Col auto='true'>
            {qutter.options.length ? (
              qutter.options[option_idx].replies.map((text, reply_idx) => {
                return (
                  <SelectOptionReplyText
                    key={`option-reply-text-${option_idx}-${reply_idx}`}
                    id={id}
                    option_idx={option_idx}
                    reply_idx={reply_idx}
                  />
                );
              })
            ):(
              <span>No texts.</span>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <span
          className="font-size-10 hover-blue me-2"
          onClick={()=>{ setShowModal(false); }}
        >
          <FontAwesomeIcon icon={'fas fa-xmark'} className="me-1"/>
          Close
        </span>
      </Modal.Footer>
    </Modal>
  );
};
