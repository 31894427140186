import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { JsonPreview } from 'features/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

import { Modal, Container } from 'react-bootstrap';
import { AddLanguageVersionPopover } from 'features/QutterCreate';

import { QutterPreviewComponents } from 'features/QutterType';
import { PreviewSelect } from 'features/QutterSelects';

import { addToLibrary } from 'features/Qutters/slices/LibrarySlice';
import { RemoveQutter } from 'features/Qutters/slices/thunks';

import { QutterInTemplateList } from 'features/QutterInTemplateList';

export const ViewDefaultQutterModal = ({
  id,
  language,
  showModal,
  setShowModal
}) => {
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[id]);
  const [loading, setLoading] = useState(true);
  console.log('<ViewDefaultQutterModal />', id, language, qutterConfig);

  useEffect(() => {
    if (!id) return;

    axios.get(`${process.env.REACT_APP_URL}/qutters/v1/get-by-id/${id}`,
    ).then(async res => {
      console.log('res.data', res.data);

      await dispatch(addToLibrary({
        qutter: res.data,
        current_language: language
      }));

      setLoading(false);
    });
  }, [id, language]);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      dialogClassName="create-question-modal"
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Header closeButton>
        <Modal.Title 
          id="contained-modal-title-vcenter"
          className='w-100'
        >
          <div>
            <span>Preview</span>
            <span className='font-size-10 float-end'>{id}</span>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <Container>
        {qutterConfig && id /* && language && qutterConfig[id] && qutterConfig[id][language]*/ ? (
          <>
          <div>

            <AddLanguageVersionPopover
              qutter_id={id}
              copy_from_language={language}
            />

            <div className='d-inline float-end' style={{ textAlign: 'right' }}>
              <span
                className='hover-blue font-size-10'
                onClick={() => {
                  dispatch(RemoveQutter({id, language: qutterConfig.language.current }))
                  .then((res) => {
                    console.log('res', res);
                  })
                }}
              >
                <FontAwesomeIcon icon={faMinusCircle} className="font-size-10 me-2"/>
                Remove language version
              </span>
              <span
                className='hover-blue font-size-10'
                onClick={() => {
                  dispatch(RemoveQutter({id}))
                  .then((res) => {
                    console.log('res', res);
                    setShowModal(false);
                  })
                }}
              >
                <FontAwesomeIcon icon={faTrash} className="font-size-10 me-2 ms-2"/>
                Delete question
              </span>
            </div>
          </div>

          {React.createElement(
            (
              ['single-select', 'multiple-select'].includes(qutterConfig.type) ? 
              PreviewSelect : QutterPreviewComponents[qutterConfig.type]
            ),
            { qutter_id: id }
          )}

          <QutterInTemplateList id={id} />

          <JsonPreview data={qutterConfig} />
          </>
        ):(
          <p>Loading...</p>
        )}
        </Container>
      </Modal.Body>

    </Modal>
  );
};
