import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getHubertAccessToken, fetchHubertAccessToken } from 'tokens/hubertTokens';
import jwtDecode from 'jwt-decode';

import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faPen,
  faInfoCircle,
  faLink,
  faReceipt
} from '@fortawesome/pro-solid-svg-icons';

import { SearchQutterInTemplate } from 'features/Template';
import { TemplateOutline } from 'features/TemplateOutline';
import {
  TemplateSettings,
  EditTemplateSettings
} from 'features/TemplateSettings';
import { RemoveTemplateButton } from 'features/Template';

import { toggleCreateQuestionModal } from 'features/Modals/slices/ModalSlice';
import { ConfirmationModal } from 'features/ConfirmationModal';
import { ClickToCopy } from 'features/ClickToCopy';

const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const URL = `${base}/template-comments/v1`;
const httpClient = axios.create({ baseURL: base });

const isExpired = (token) => {
  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000;
};

httpClient.interceptors.request.use(async (config) => {
  let token = getHubertAccessToken();
  if(!token || isExpired(token)) {
      const { accessToken } = await fetchHubertAccessToken();
      token = accessToken;
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});


const RedirectPage = ({ url, name }) => {
  return (
    <a
      className="link-primary hover-blue"
      style={{ fontSize: '9px', fontWeight: 'bold' }}
      target="_blank"
      href={url}
      rel="noreferrer"
    >
      {name}
    </a>
  );
};

export const TemplateOverview = ({ id }) => {
  const dispatch = useDispatch();
  const templateConfig = useSelector(state => state.Template);
  const readOnly = templateConfig.readOnlyMode;
  const template = useSelector(state => state.Template[id]);
  const [edit_mode, set_edit_mode] = useState(false);
  const [testJob, setTestJob] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}/jobs/v1/get-test-job-by-template-id/${id}`
      )
      .then(async res => {
        setTestJob(res.data);
      });
  }, []);

  if (!template || template.status === 'loading') {
    return <span>Loading...</span>;
  }

  console.log('templateConfig readOnly', readOnly)

  const generateReceiptURL = () => {
    // axios
    //   .post(`${process.env.REACT_APP_URL}/interview-receipt/v1/receipt`, {
    //     template_id: id,
    //     client_id: clientId
    //   })
    //   .then(async res => {
    //     setInterviewReceiptId(res.data._id);
    //     setInterviewReceiptStatus(true);
    //     setReceiptGeneratorConfirmation(false);
    //   });
  };

  const onClickReceiptGeneratorConfirm = () => {
    generateReceiptURL();
  };

  const onClickReceiptGeneratorCancel = () => {
    setReceiptGeneratorConfirmation(false);
  };

  return (
    <>
      {/*
      <ConfirmationModal
        headerTitle={'Generate interview receipt'}
        bodyContent={'Do You Want To Generate Interview Receipt For This Template?'}
        cancelButtonFunction={onClickReceiptGeneratorCancel}
        confirmButtonFunction={onClickReceiptGeneratorConfirm}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Generate'}
        modalVisibility={receiptGeneratorConfirmation}
        setModalVisibility={setReceiptGeneratorConfirmation}
      />
      */}
      <div className="m-1 m-auto">
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
          Default Settings
        </span>
        <span className="float-end">
          {readOnly ? (null) : (
            <span
              onClick={() => {
                set_edit_mode(true);
              }}
              className="hover-blue ms-2"
            >
              <FontAwesomeIcon className="" size={'lg'} icon={faPen} />
            </span>
          )}
        </span>
      </div>

      {edit_mode ? (
        <EditTemplateSettings id={id} set_edit_mode={set_edit_mode} />
      ) : (
        <TemplateSettings id={id} />
      )}

      <span
        className="mt-3 d-block"
        style={{ fontSize: '16px', fontWeight: 'bold' }}
      >
        Outline
      </span>
      <TemplateOutline template_id={id} />

      <span
        className="mt-3 d-block"
        style={{ fontSize: '16px', fontWeight: 'bold' }}
      >
        Links
      </span>

      <div>
        <ClickToCopy
          url={`${process.env.REACT_APP_INTERVIEW_BASE_URL}/template/test/${id}`}
          text={'Test chat'}
          tooltip_text={'Click to copy'}
        />
      </div>


      {template.script ? (
      <div>
        <ClickToCopy
          url={`${'https://the.hubert.ai'}/app/script/${id}`}
          text={'Script'}
          tooltip_text={'Click to copy'}
          className='font-size-10'
        />
      </div>
      ):(null)}

      {!readOnly && testJob && testJob._id ? (
        <div>
          <RedirectPage
            url={`/jobs/${testJob._id}`}
            name={'Test job'}
          />
        </div>
      ): (null)}

      {readOnly ? (
        ''
      ) : (
        <Row>
          <Col className="float-end">
            <span
              className="mt-3 d-block"
              style={{ fontSize: '16px', fontWeight: 'bold' }}
            >
              Search & Create
            </span>

            <div>
              <RemoveTemplateButton id={id} />
            </div>

            <div>
              <span
                onClick={() => {
                  dispatch(
                    toggleCreateQuestionModal({
                      show: true,
                      qutter_id: null,
                      language: null
                    })
                  );
                }}
                className="hover-blue d-block font-size-10"
              >
                <FontAwesomeIcon
                  className="me-1"
                  size={'sm'}
                  icon={faPlusCircle}
                />
                Create Question
              </span>
            </div>

            <SearchQutterInTemplate />
          </Col>
        </Row>
      )}
    </>
  );
};
