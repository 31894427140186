import React, {useState} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import { CreateJobModal } from '..';

export const CreateJobModalShowButton = ({}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {showModal && (
      <CreateJobModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
      )}
      <div
        onClick={(e) => { setShowModal(!showModal); }}
        className='hover-blue font-size-10'
      >
        <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
        Create job
      </div>
    </>
  );
};
