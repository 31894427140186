import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';

import { Form, Offcanvas, Dropdown } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from '_components/common/SoftBadge';

const InterviewsFilterOffCanvas = ({
  show,
  setFilter
}) => {
  return (
    <Offcanvas
      onClick={(e) => { e.stopPropagation(); }}
      show={show}
      scroll={true}
      backdrop={true}
      data-bs-keyboard="false"
      // className='w-75'
      placement={'end'}
      style={{
        width: '40%',
        height: '100%',
      }}
    >
      <Offcanvas.Body>
        FILTER
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          label="Check this switch"
        />

<Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Dropdown Button
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

      </Offcanvas.Body>
    </Offcanvas>
  );
};

export const InterviewsFilterOffCanvasButton = ({
  setFilter
}) => {
  const [show, setShow] = useState(false);
  const onClick = () => { setShow(!show); }

  return (
    <Flex alignItems="center" className='d-inline-flex mx-2'>
      <span
        onClick={onClick}
        className='cursor-pointer hover-blue'
      >
        <FontAwesomeIcon className="me-2" icon={faFilter} />
        <InterviewsFilterOffCanvas
          show={show}
          setFilter={setFilter}
        />
      </span>
    </Flex>
  );
};
