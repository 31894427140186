import React from 'react';

export const BonuspointsOutline = ({
  loading,
  bonuses
}) => {
  if (loading) return (<span>Counting warnings...</span>);
  if (!bonuses) return (<span>Bonuses error...</span>);

  const ids = [...bonuses.ids]
  const length = ids.length;

  return (
  <div>
    <div className='d-flex font-size-10'>
      <div className='w-75 text-start'>
        Bonuspoints questions:
      </div>
      <div className='w-25 text-end'>
        {length}
      </div>
    </div>
    <div className='d-flex font-size-10'>
      <div className='w-75 text-start'>
        Total bonus points:
      </div>
      <div className='w-25 text-end'>
        {bonuses.total}
      </div>
    </div>
    {bonuses && bonuses.errors && bonuses.errors.length > 0 ? (
      bonuses.errors.map(error => {
        return (<div>{error}</div>)
      })
    ):(null)}
  </div>
  );
};
