import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { 
  Container,
  Popover,
  OverlayTrigger,
  Form
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import {
  toggleEditQutterModal
} from 'features/Modals/slices/ModalSlice';

import { addLanguage } from 'features/Qutters/slices/LibrarySlice';

const PopOver = ({
  qutter_id,
  copy_from_language,
  hide, setHide
}) => {
  const dispatch = useDispatch();
  const [language, set_language] = useState(null);

  const handleChange = e => {
    e.persist();
    set_language(e.target.value);
  };

  const on_create_click = () => {
    dispatch(addLanguage({
      qutter_id,
      copy_from_language,
      new_language: language
    }));
    dispatch(toggleEditQutterModal({
      show: true,
      qutter_id: qutter_id,
      language: language,
      newLanguage: false
    }));
    setHide(true);
  };

  return (
  <Popover 
    id="popover-basic"
    className={`qutter-followup-popover ${hide ? 'd-none': ''}`}
  >
    <Popover.Body 
      as={Container}
      className='w-100'
    >
      <div>Which language?</div>

      <Form.Check
        type='radio'
        onChange={handleChange}
        label={`Swedish`}
        id={`radio-sv-se`}
        value={`sv-se`}
        name='language'
      />

      <Form.Check
        type='radio'
        onChange={handleChange}
        label={`American English`}
        id={`radio-en-us`}
        value={`en-us`}
        name='language'
      />
      <Form.Check
        type='radio'
        onChange={handleChange}
        label={`British English`}
        id={`radio-en-gb`}
        value={`en-gb`}
        name='language'
      />
      <Form.Check
        type='radio'
        onChange={handleChange}
        label={`Spanish`}
        id={`radio-es`}
        value={`es-es`}
        name='language'
      />
      <Form.Check
        type='radio'
        onChange={handleChange}
        label={`Danish`}
        id={`radio-dk`}
        value={`da-dk`}
        name='language'
      />
      <Form.Check
        type='radio'
        onChange={handleChange}
        label={`Norwegian`}
        id={`radio-no`}
        value={`no-no`}
        name='language'
      />
      <Form.Check
        type='radio'
        onChange={handleChange}
        label={`Finnish`}
        id={`radio-fi`}
        value={`fi-fi`}
        name='language'
      />
      <Form.Check
        type='radio'
        onChange={handleChange}
        label={`German`}
        id={`radio-de`}
        value={`de-de`}
        name='language'
      />
      <Form.Check
        type='radio'
        onChange={handleChange}
        label={`French`}
        id={`radio-fr`}
        value={`fr-fr`}
        name='language'
      />
      <Form.Check
        type='radio'
        onChange={handleChange}
        label={`Italian`}
        id={`radio-it`}
        value={`it-it`}
        name='language'
      />

      <div
        style={{ textAlign: 'right' }}
      >
        <span
          className='hover-blue font-size-10 d-inline'
          onClick={on_create_click}
        >
          Create
        </span>
      </div>
    </Popover.Body>
  </Popover>
  )
}

export const AddLanguageVersionPopover = ({
  qutter_id,
  copy_from_language
 }) => {
  const [hide, setHide] = useState(false);

  return (
    <span
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
    <OverlayTrigger 
      trigger={['click']}
      placement='right'
      overlay={
        PopOver({
          qutter_id,
          copy_from_language,
          hide, setHide
        })
      }
    >
      <span 
        onMouseDown={e => e.stopPropagation()}
        className='hover-blue font-size-10 d-inline'
        style={{ textAlign: 'left' }}
      >
        <FontAwesomeIcon 
          style={{}} 
          size={'sm'} 
          icon={faPlusCircle}
          className="font-size-10 me-2"
        />
        Add language
      </span>
    </OverlayTrigger>
    </span>
  );
};
