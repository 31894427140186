import React from 'react';
import { Modal } from 'react-bootstrap';

import { HubiverseTags } from './HubiverseTags';

export const HubiverseTagsModal = ({
  showModal,
  setShowModal,
  qutter_id,
  option_id,
  form_id,
  hubiverse_tags
}) => {

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      dialogClassName="create-question-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className='font-size-10 d-block'>Question Tags</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <HubiverseTags
          qutter_id={qutter_id}
          option_id={option_id}
          form_id={form_id}
          hubiverse_tags={hubiverse_tags}
        />
      </Modal.Body>

    </Modal>
  );
};
