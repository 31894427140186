import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';

const EDIT_JOB_ACTIVE = gql`
mutation EditJobActive($job_id: String!, $active: Boolean!) {
  EditJobActive(job_id: $job_id, active: $active)
}
`;

const JobActive = ({ job_id, _active }) => {
  let { id } = useParams();
  if (job_id) id = job_id;
  else job_id = id;

  const [active, setActive] = useState(_active);
  const [jobActiveMutation, {data, loading, error}] = useMutation(EDIT_JOB_ACTIVE);

  return (
    <>
      {
        (() => {
          if (loading) { return ('Updating job activity...'); }
          else 
          if (error) { return (`Error: ${error}`); }
          else 
          {
            return (
            <div>
              <Form.Check 
                type='switch'
                id='jobActiveSwitch'
                defaultChecked={active}
                label='Active'
                style={{ margin: 0 }}
                onChange={
                  (e) => {
                    setActive(!active);
                    jobActiveMutation({
                      variables: {
                        job_id: job_id,
                        active: !active
                      }
                    }).then((result) => {
                      if (!active) toast.success(`Job is now active.`);
                      else toast.success(`Job is now closed.`);
                    }).catch((error) => {
                      toast.error(`Failed to edit job. Error: ${error}`);
                    });
                  }
                }
              />
            </div>
            );
          }
        })()
      }
    </>
  );
};

export default JobActive;
