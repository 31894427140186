import {
  initiateEvaluation,
  setDefaultEvaluation,
  updateScoringCategories
} from "..";

export const intent_context = async (
  dispatch,
  library,
  template,
  eval_state,
  node,
  is_followup
) => {
  const qutter_config = library[node.id];
  const languages = template.language_settings.list;

  // console.log('intent_context ', qutter_config.id)

  dispatch(initiateEvaluation({
    languages,
    qutter_id: node.id,
    is_followup: is_followup
  }));

  languages.map(async language => {
    let bonuspoints = [];
    const qutter = qutter_config[language];
    if (!qutter) return;
    const intents = qutter.intents;

    for (let i = 0; i < intents.length; i++) {
      const intent = intents[i];

      let default_eval = {
        name: intent.evaluation
      };

      if (intent.hasOwnProperty('bonus_points')) {
        default_eval.bonus_points = intent.bonus_points;
      }
      if (intent.hasOwnProperty('points')) {
        default_eval.points = intent.points;
      }
      if (intent.hasOwnProperty('weight')) {
        default_eval.weight = intent.weight;
      }

      dispatch(setDefaultEvaluation({
        language,
        qutter_id: node.id,
        id: intent.intent,
        evaluation: default_eval
      }));
    }

    const cond = 
      qutter_config.hasOwnProperty('weight') &&
      qutter_config.hasOwnProperty('scoring_category') &&
      qutter_config.scoring_category !== 'info';
    if (cond) {

      dispatch(updateScoringCategories({
        qutter_id: qutter_config.id,
        category: qutter_config.scoring_category,
        weight: qutter_config.weight,
        is_followup
      }));

      dispatch(setDefaultEvaluation({
        language,
        qutter_id: node.id,
        id: 'scoring_category',
        evaluation: {
          weight: qutter_config.weight,
          scoring_category: qutter_config.scoring_category
        }
      }));
    }
  });
};

export const open_context = async (
  dispatch,
  library,
  template,
  eval_state,
  node,
  is_followup
) => {
  // console.log('open_context', node.id)

  await intent_context(
    dispatch,
    library,
    template,
    eval_state,
    node,
    is_followup
  );

  // const qutter_config = library[node.id];
  // const cond = 
  //   qutter_config.hasOwnProperty('weight') &&
  //   qutter_config.hasOwnProperty('scoring_category') &&
  //   qutter_config.scoring_category !== 'info';
  // if (cond) {
  //   dispatch(update_scoring_categories({ qutter_config, is_followup }));
  //   // await dispatch(add_weight({ template_id, qutter_id, weight: qutter_config.weight }));
  // }
};
