import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Modal, Container, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';

import { EditQutter } from 'features/QutterEdit';

import { removeFromLibrary } from 'features/Qutters/slices/LibrarySlice';
import { UpdateQutter } from 'features/Qutters/slices/thunks';
import { toggleEditQutterModal } from 'features/Modals/slices/ModalSlice';

export const EditQutterModal = ({
  header,
  showModal,
  qutter_id,
  language,
  newLanguage
}) => {
  // console.log('<EditQutterModal />', qutter_id, language);
  const dispatch = useDispatch();
  const [id, setId] = useState('');

  const Modals = useSelector(state => state.Modals);
  const qutterConfig = useSelector(state => state.Library[Modals.editQutterModal.qutter_id]);
  if (!Modals.editQutterModal.show) return <></>;
  // const qutter = qutterConfig[Modals.editQutterModal.language];
  // console.log('editQutterModal', Modals.editQutterModal, qutterConfig);

  const onCloseModalClick = (e) => {
    if (Modals.editQutterModal.newLanguage === true) {
      dispatch(removeFromLibrary({ qutter_id, language }))
    }
    dispatch(toggleEditQutterModal({
      show: false,
      qutter_id: null,
      language: null,
      newLanguage: false
    }));
  };

  const onSaveClick = () => {
    // VALIDATION - Validate the saved qutter
    dispatch(UpdateQutter(qutterConfig)).then((res) => {
      dispatch(toggleEditQutterModal({
        show: false,
        qutter_id: null,
        language: null,
        newLanguage: false
      }));
    });
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        onCloseModalClick();
      }}
      dialogClassName="create-question-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {header ? header: `Edit Question`}
          {qutter_id && language ? (
            <span className='font-size-10'>{`${qutter_id} ${language}`}</span>
          ):(
            <span className='font-size-10'>{`Error`}</span>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        {qutter_id && language && qutterConfig ? (
          <EditQutter
            qutter_id={qutter_id}
            language={language}
          />
        ) : (
          <div
            className='m-auto text-center center'
            style={{
              margin: 0,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <span>Something went wrong.</span>
          </div>
        )}

      </Modal.Body>

      <Modal.Footer>
        <div className="float-end font-size-10">
          <span
            className="me-3 hover-blue"
            onClick={() => { onCloseModalClick(); }}
          >
            <FontAwesomeIcon className="me-1" icon={faXmark} />
            Cancel
          </span>

          <span
            className="me-1 hover-blue"
            onClick={() => { onSaveClick(); }}
          >
            <FontAwesomeIcon className="me-1" icon={faFloppyDisk} />
            Update
          </span>
        </div>
      </Modal.Footer>

    </Modal>
  );
};