import React from 'react';
import SummaryCanvasLink from '_components/applications/SummaryCanvas';

export const ScoreSummaryHeader = ({
  application_id,
  summary_id,
  interview_id,
  showSummary,
  setShowSummary
}) => {

  return (
  <div 
    className='p-0 m-auto text-center'
    id='score-summary-container-header'
    style={{ WebkitFlex: 'none', height: '75px' }}
  >

    <SummaryCanvasLink
      key={`interview-summary`}
      application_id={application_id}
      summary_id={summary_id}
      interview_id={interview_id}
      showSummary={showSummary}
      setShowSummary={setShowSummary}
    />
  </div>
  );
};
