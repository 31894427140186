import React, { useState } from 'react';
import { Card, Collapse, Form, Container, Row, Col } from 'react-bootstrap';
import { OpenInNewTab } from 'features/OpenInNewTab';

const ApplyPageNew = ({
  job_id
}) => {
  const [open, set_open] = useState(false);
  const base_url = 'https://apply.hubert.ai';
  const url = `${base_url}/${job_id}`;

  return (
    <Card
      key={`collapse-card-${'job_id'}-${'header'}`}
      className='mt-1 mb-1 w-100'
    >
      <Card.Header
        className='cursor-pointer'
        onClick={() => set_open(!open)}
      >
        New
      </Card.Header>

      <Card.Body className='pt-0 pb-0'>

        <Collapse in={open}>
          {open && open === true ? (
            <Container>
              <Row>
                <OpenInNewTab
                  url={url}
                  name={'Link'}
                />
              </Row>
              <Row style={{ minHeight: '600px', height: '600px' }}>
                <iframe src={url}></iframe>
              </Row>
            </Container>
          ) : (
            <></>
          )}
        </Collapse>
      </Card.Body>
    </Card>
  );
};

export default ApplyPageNew;
