import React, {useState} from 'react';
import { useSelector } from 'react-redux';

import Range from 'rc-slider';
import 'rc-slider/assets/index.css';

import { LabelPreview } from '../../common/LabelPreview';


export const FormRangePreview = ({
  qutter_id,
  form_idx,
  preview_container
}) => {
  console.log('<FormRangePreview />');
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const interviewReceptState = useSelector(state => state.InterviewReceipt);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  const form = forms[form_idx];

  const min = form.min ? form.min: 0;
  const max = form.max ? form.max: 100;
  const step = form.step ? form.step: 1;
  const [value, set_value] = useState([0,10]);

  const on_change = value => { set_value(value); };

  return (
  <div className="form-group">
    <LabelPreview label={form.label} is_mandatory={form.is_mandatory} />

    <div className='d-flex mt-1'>
      <div className='w-25 m-auto text-start'>
        <span>{form.label_left}</span>
      </div>
      <div className='w-50 m-auto text-center'>
        <span>{value[0]} - {value[1]} ({form.unit})</span>
      </div>
      <div className='w-25 text-end'>
        <span>{form.label_right}</span>
      </div>
    </div>

    <div 
      // className='ms-6 me-6'
    >
      <Range
        min={min}
        max={max}
        step={step}
        defaultValue={value}
        onChange={on_change}
        disabled={interviewReceptState.readOnlyMode ? true : false}
        // dots={true}
        // marks={buildMarks(min, max, step)}
      />
    </div>
  </div>
  );
}
