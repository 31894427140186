import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';

import { setIsLoggedOut } from '_components/config/ConfigSlice';
import { setHubertAccessToken } from 'tokens/hubertTokens';
import { setAccessToken } from 'tokens/tokens';
import {
  appHubertLogoutRequest,
  adminLogoutRequest
} from 'features/Login/api/appHubertApi';

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.config.user);
  const fullname = user.first_name + ' ' + user.last_name;

  const onLogoutClick = () => {
    if (user.userType === 'user') {
      appHubertLogoutRequest().then(r => {
        console.log('response: ', r);
        setHubertAccessToken('');
        dispatch(setIsLoggedOut());
        navigate('/app-login');
      })
      .catch(err => {
        console.error('response err: ', err);
        // errorSignOn(err.message);
      });
    } else {
      adminLogoutRequest().then(r => {
        console.log('response: ', r);
        setAccessToken('');
        dispatch(setIsLoggedOut());
        navigate('/login');
      })
      .catch(err => {
        console.error('response err: ', err);
        // errorSignOn(err.message);
      });
    }
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 nav-link"
      >

        <Flex alignItems="center">
          {user.logo ? (
            <Avatar src={user.logo} name={fullname} size="xl" className="me-2" />
          ) : (
            <Avatar size="xl" name={fullname} className="me-2" />
          )}
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{fullname}</h5>
          </div>
        </Flex>

        {/* <Avatar src={team3} /> */}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
            as='span'
          >
            {user.email}
          </Dropdown.Item>
          <Dropdown.Item
            as='span'
          >
            {user._id}
          </Dropdown.Item>
          <Dropdown.Item 
            as={Link} 
            to="/logged-out"
            onClick={onLogoutClick}
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
