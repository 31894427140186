import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export const disqualificationIconWithText = (
  <span className="">
    <FontAwesomeIcon style={{ color: 'indianred' }} size={'lg'} icon={faTimesCircle} />
    <span className="m-2">Not Qualified</span>
  </span>
);

export const disqualificationIcon = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'indianred' }} size={'lg'} icon={faTimesCircle} />
  </span>;
