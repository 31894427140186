import React, { useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { Card, Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import {ClientSelect} from 'features/ClientSelect';
import JobsList from '_components/jobs/JobsList';

import { CreateJobModalShowButton } from 'features/CreateJob';

const QUERY_FIND_JOBS = gql`
query FindJobsBy(
    $client_id: String,
    $title: String,
    $company: String,
    $role: String,
    $responsible_cs: String,
    $cancel: Boolean,
    $active: Boolean
  ) {
  FindJobsBy(input: {
    client_id: $client_id,
    title: $title,
    company: $company,
    role: $role,
    responsible_cs: $responsible_cs,
    cancel: $cancel
    active: $active
  }) {
    id
    title
    created_at
    responsible_cs {
      id
      first_name
      last_name
    }
    scoring_statistics {
      todos
      manually_updated
      auto_updated
      invited
    }
    version
  }
}
`;

const JobSearch = ({
  client_id
}) => {
  const [client, setClient] = useState(null);
  const [myJobs, setMyJobs] = useState(true);
  const [activeJobs, setActiveJobs] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const user = useSelector(state => state.config.user);
  const [searchJobs, { loading, data, error }] = useLazyQuery(QUERY_FIND_JOBS, { fetchPolicy: 'network-only' });

  const onSearchClick = (e) => {
    setIsLoading(true);
    searchJobs({ variables: {
      active: activeJobs,
      client_id: client,
      company: textSearch,
      title: textSearch,
      responsible_cs: (myJobs ? user._id: '')
    }})
    setIsLoading(false);
  };

  if (error) {
    toast.error(`Error: ${error.message}`);
    return `Error! ${error.message}`;
  }

  return (
    <>
    <Card className='mt-4 mb-4'>
      <Card.Header>
        Search Job
        <div className=''>
          <CreateJobModalShowButton />
        </div>
      </Card.Header>
      <Card.Body>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextSearch">
          <Form.Label>Text Search</Form.Label>
          <Form.Control 
            type="text"
            placeholder='"Bullens företag"' 
            onChange={(e) => { setTextSearch(e.target.value); }}
            onKeyPress={event => {
              if (event.key === "Enter") {
                onSearchClick();
              }
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlClientSearch">
          <Form.Label>By Client</Form.Label>
          <ClientSelect 
            on_select={(e)=> { setClient(e.value); }}
          />
        </Form.Group>

        <Form.Check 
          type='checkbox'
          id='myJobsCheckbox'
          label='My Jobs'
          defaultChecked={myJobs}
          onChange={() => { setMyJobs(!myJobs) }}
        />

        <Form.Check 
          type='switch'
          id='activeJobsCheckbox'
          label='Active Jobs'
          defaultChecked={activeJobs}
          onChange={() => { setActiveJobs(!activeJobs) }}
        />
        {(loading || isLoading) ? (
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        ): (
          <Button
            onClick={onSearchClick}
            className='m-2'
            variant="falcon-primary"
            size="sm"
          >
            Search jobs
          </Button>
        )}

      </Card.Body>
    </Card>

    {data && data.FindJobsBy && data.FindJobsBy.length ?
    (
      <JobsList jobs={data.FindJobsBy} />
    ):
    (
      <span>No Jobs...</span>
    )}
    </>
  );
};

export default JobSearch;
