import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Modal } from 'react-bootstrap';
import { CandidateFilterSearch } from '..';

import { get_job_by_id } from '../api/api';

export const CandidateFilterSearchModal = ({
  job_id,
  client_id,
  template_id,
  showModal, setShowModal,
}) => {

  if (!job_id) {
    setShowModal(false);
    return null;
  }

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      dialogClassName="create-question-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Candidate Search/Filter
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">

        {client_id && template_id ? (
          <CandidateFilterSearch
            client_id={client_id}
            job_id={job_id}
            template_id={template_id}
          />
        ):(
          <span>
            Cannot find client id and temlate id
          </span>
        )}
      </Modal.Body>

    </Modal>
  );
};
