import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';

import { PreviewIntent } from '..';

export const PreviewIntents = ({
  template_id,
  qutter_id,
  order
}) => {
  // console.log('<PreviewIntents />', qutter_id);
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const qutter = qutterConfig[qutterConfig.language.current];
  if (!qutter) return (<span>Intents missing.</span>);

  return (
  <div className='bubble-candidate mt-3'>
    {qutter.intents && qutter.intents.length ? (
      <>
      {qutter.intents.map((intent, idx) => {
        return (
          <PreviewIntent
            key={`${qutter_id}-intent-${intent.id}-${idx}`}
            template_id={template_id}
            qutter_id={qutter_id}
            intent_idx={idx}
            order={order}
          />
        )
      })}
      </>
    ):(
      <div className=''>
        <Col className=''>
          <span>No intents added.</span>
        </Col>
      </div>
    )}
  </div>
  );
};
