import React from 'react';
import { useQuery } from '@apollo/client';

import { Modal } from 'react-bootstrap';

import ApplyPageLegacy from './ApplyPageLegacy';
import ApplyPageIframe from './ApplyPageIframe';
import ApplyPageNew from './ApplyPageNew';
import ApplyPageSettings from './ApplyPageSettings';

import { QUERY_GET_JOB_APPLY_SETTINGS } from '../api/gql';

const ApplyPageModal = ({
  job_id,
  showModal,
  setShowModal
}) => {

  const { loading, error, data } = useQuery(
    QUERY_GET_JOB_APPLY_SETTINGS,
    { 
      fetchPolicy: "no-cache",
      variables: { job_id: job_id }
    }
  );

  if (loading) return null;
  if (error) return `Error! ${error.message}`;

  const job = data.GetJob;

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      dialogClassName="create-question-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className='font-size-10 d-block'>Apply pages</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <ApplyPageSettings 
          job_id={job_id}
          apply_settings={job.apply_settings}
        />
        <ApplyPageNew job_id={job_id} />
        <ApplyPageLegacy job_id={job_id} />
        <ApplyPageIframe job_id={job_id} />
      </Modal.Body>

    </Modal>
  );
};

export default ApplyPageModal;