import { 
  single_select_context,
  multiple_select_context
} from "./multiple_choice_context";

import { 
  open_context,
  intent_context
} from "./open_context";

import { date_context } from "./date_context";
import { form_context } from "./form_context";

export const evaluation_context = async (
  dispatch,
  library,
  template,
  eval_state,
  node,
  is_followup
) => {

  if (node.type === 'question') {
    const qutter_config = library[node.id];

    if (qutter_config.type === 'info') return;
    if (qutter_config.type === 'single-select') {
      await single_select_context(
        dispatch,
        library,
        template,
        eval_state,
        node,
        is_followup
      );
    }
    else
    if (qutter_config.type === 'multiple-select') {
      await multiple_select_context(
        dispatch,
        library,
        template,
        eval_state,
        node,
        is_followup
      );
    }
    else 
    if (qutter_config.type === 'open') {
      await open_context(
        dispatch,
        library,
        template,
        eval_state,
        node,
        is_followup
      );
    }
    else
    if (qutter_config.type === 'date-picker') {
      await date_context(
        dispatch,
        library,
        template,
        eval_state,
        node,
        is_followup
      );
    }
    else
    if (qutter_config.type === 'form') {
      await form_context(
        dispatch,
        library,
        template,
        eval_state,
        node,
        is_followup
      );
    }
  } else if (node.type === 'action') {
    // console.log('Outline counter: Action');
  }
};
