import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { Row, Col } from 'react-bootstrap';
import { TextType } from './TextType';
import { Text } from './Text';

import { textPlus } from 'features/Qutters/slices/LibrarySlice';

export const TextComponent = ({
  id,
  field,
}) => {
  // console.log(`<TextComponent /> ${id} ${field}`);
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[id]);
  const type = qutterConfig.type;
  const qutter = qutterConfig[qutterConfig.language.current];

  const onPlusClick = () => {
    dispatch(textPlus({ id, idx: 0, field }));
  };

  if (type === 'info' && ['intro', 'outro'].includes(field)) return <></>;

  return (
  <Row>
    <Col className='m-auto' xs={1} md={1} lg={1}>
      <TextType
        qutter_type={type}
        field={field}
      />
    </Col>
    <Col auto='true' className='m-auto'>
      {qutter && qutter[field] && qutter[field].length ? (
        <>
        {qutter[field].map((text, idx) => {
          return (
            <Text 
              key={`text-${idx}`} 
              id={id}
              idx={idx}
              field={field}
            />
          );
        })}
        </>
      ):(
        <span
          onClick={() => { onPlusClick(); }}
          className='hover-blue font-size-10 m-1 float-end'
        >
          <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
          Add text
        </span>
      )}
    </Col>
  </Row>
  );
};
