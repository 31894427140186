import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WeightsInfoIconHigh, WeightsInfoIconLow } from './icons/icons';

export const ScoringCategoriesTotalWeight = ({
  template_id,
  scoring_categories,
  loading
}) => {
  const [total_weight, set_total_weight] = useState(0);
  const library = useSelector(state => state.Library);

  useEffect(() => {
    let weight = 0;
    scoring_categories.list.map(category_id => {
      let category = scoring_categories[category_id];
      weight += category.weight;
    });

    set_total_weight(weight);
  }, [scoring_categories]);

  if (loading) return (<span>Counting weights...</span>);
  if (!scoring_categories) return (<span>scoring_categories total weights error...</span>);

  return (
  <div
    className='d-flex font-size-10' 
    style={{ fontSize: '9px', fontWeight: 900 }}
  >
    <div className='w-25 text-start'>
      <span>
        Total
      </span>
    </div>
    <div className='w-75 text-end'>
      <span className=''>
        {(() => {
          if (total_weight < 1) {
            return (
              <span className='red'>
                <WeightsInfoIconLow containerClass={'me-1 font-size-10'} />
                {Math.round((total_weight * 100) * 10) / 10}%
              </span>
            );
          } else 
          if (total_weight > 1) {
            return (
              <span className='red'>
                <WeightsInfoIconHigh containerClass={'me-1 red font-size-10'} />
                {Math.round((total_weight * 100) * 10) / 10}%
              </span>
            );
          } else 
          {
            return (
              <span>{Math.round((total_weight * 100) * 10) / 10}%</span>
            );
          }
        })()}
      </span>
    </div>
  </div>
  );
};
