import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';

import { setHubertAccessToken } from '../../../tokens/hubertTokens';
import { setIsLoggedIn } from '_components/config/ConfigSlice';

import { appHubertLogin, appHubertLoginRequest } from '../api/appHubertApi';

const AppHubertLoginForm = ({ hasLabel, layout }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // On login: Redirect to
  const { search, pathname } = useLocation();

  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  // loading
  const [loading, setLoading] = useState(false);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    appHubertLoginRequest(formData.email, formData.password)
    .then(data => {
      console.log('appHubertLogin data', data);
      if (data) {

        console.log('data', data);
        setHubertAccessToken(data.accessToken);
        dispatch(setIsLoggedIn({ 
          user: {
            ...data.user,
            userType: 'user'
          }
        }));
        console.log('pathname', pathname)
        console.log('search', search)

        if (pathname && pathname !== '/' && search) {
          const s = search.split('redirect=')[1];
          navigate(s);
        }
        else {
          navigate('/app');
        }
        toast.success(`Logged in as ${formData.email}.`);
      }
    })
    .catch(error => {
      console.log('appHubertLogin error', error);
      toast.error(`Failed to login. ${error}`);
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ToastContainer  />
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          {loading && loading === true ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="false"
              />
              Logging in
            </>
          ) : (
            'Log in'
          )}
        </Button>
      </Form.Group>

    </Form>
  );
};

AppHubertLoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

AppHubertLoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default AppHubertLoginForm;
