import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

import { updateQutterExampleAnswer } from 'features/Qutters/slices/LibrarySlice';
import { QutterSummaryLabel } from '..';
import { QutterSummaryDefaultBlocksSelect } from '..';

export const QutterSummary = ({
  id,
}) => {
  console.log('<QutterSummary />', id);
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[qutterConfig.language.current];

  return (
  <div>

    <QutterSummaryDefaultBlocksSelect 
      qutter_id={id}
    />
    <QutterSummaryLabel 
      qutter_id={id}
    />

    {['create-open'].includes(id) && (

      <div className='d-flex mt-3'>
        <div className='w-50 m-auto text-center'>
          <span>Example answer</span>
        </div>
        <div className='w-50 text-left'>
          <Form.Control 
            defaultValue={qutter.presentation.example_answer}
            type="text" 
            placeholder="An example answer"
            onChange={(e) => {
              dispatch(updateQutterExampleAnswer({ id, example_answer: e.target.value }));
            }}
          />
        </div>
      </div>
    )}
  </div>
  );
};
