
export function SetCurrentQutterLanguage (state, action) {
  const { 
    id,
    language,
    new_language
  } = action.payload;

  if (new_language === true) {
    let currentLanguage = state[id].language.current;
    const first = state[id].language.list[0]
    let new_qutter = Object.assign({}, state[id][first]);
    new_qutter.language = language;
    state[id][language] = new_qutter;
    try {
      const idx = state[id].language.list.findIndex((lang) => lang === language);
      if (idx === -1) {
        state[id].language.list.push(language);
      }
    } catch (e) {
      console.log('[AddToLibrary] language.list Exception', e);
    }
  } else {
    state[id].language.current = language;
  }
};

export function SetAllCurrentQutterLanguage (state, action) {
  const { language } = action.payload;
  let prohibited = ['status', 'create-info', 'create-yesno', 'createOpen', 'create-choice'];
  for (let key in state) {
    if (!prohibited.includes(key)) {
      state[key].language.current = language;
    }
  }
};

export function AddLanguage (state, action) {
  const { qutter_id, copy_from_language, new_language } = action.payload;
  const available_languages = [...state[qutter_id].available_languages, new_language];
  const source = state[qutter_id][copy_from_language];
  const target = Object.assign({}, source);
  state[qutter_id] = {
    ...state[qutter_id],
    available_languages: available_languages,
    [new_language]: target,
    language: {
      current: new_language,
      list: available_languages
    },
  };
}

export function AddToLibrary (state, action) {
  const { qutter, current_language, allowed_languages } = action.payload;
  const id = qutter.id;
  const q = state[id];
  let current = null;

  if (current_language) 
    current = current_language;
  else if (allowed_languages && allowed_languages.length > 0) 
    current = allowed_languages[0].code;
  else 
    current = qutter.available_languages[0];

  if (!q) {
    state[id] = {
      ...qutter,
      language: {
        current: current,
        list: qutter.available_languages
      },
    }
  }
  if (current_language) {
    state[id].language.current = current_language;
  }
};

export function RemoveFromLibrary (state, action) {
  const { qutter_id, language } = action.payload;
  try {
    const index = state[qutter_id].language.list.indexOf(language);
    if (index > -1) {
      state[qutter_id].language.list.splice(index, 1);
    }
    // state[qutter_id].language.current = state[qutter_id].language.list[0];
    state[qutter_id].language.current = language;
    delete state[qutter_id][language];
  } catch (e) {
    console.log('[AddToLibrary] language.list Exception', e);
  }
}

export function TextMinus (state, action) {
  const { id, field, idx } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current][field].splice(idx, 1);
  }
};

export function TextPlus (state, action) {
  const { id, idx, field } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current][field].splice((idx + 1), 0, 'Edit this text.');
  }
};

export function UpdateQutterTextField (state, action) {
  const { id, idx, field, text } = action.payload;
  const qutter = state[id];
  const language = qutter.language;
  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current][field][idx] = text;
  }
};

export function UpdateQutterSummaryBlock (state, action) {
  const { id, block } = action.payload;
  const config = state[id];
  const language = config.language;
  if (config && language.current && config[language.current]) {
    config[language.current].presentation.block = block;
  }
};

export function UpdateQutterSummaryLabel (state, action) {
  const { id, label } = action.payload;
  const config = state[id];
  const language = config.language;
  if (config && language.current && config[language.current]) {
    config[language.current].presentation.label = label;
  }
};

export function UpdateQutterExampleAnswer (state, action) {
  const { id, example_answer } = action.payload;
  const config = state[id];
  const language = config.language;
  if (config && language.current && config[language.current]) {
    config[language.current].presentation.example_answer = example_answer;
  }
};

export function UpdateQutterIsGlobal (state, action) {
  const { id, global } = action.payload;
  const qutterConfig = state[id];
  qutterConfig.global = global;
};

export function UpdateQutterIsSTAR (state, action) {
  const { id, is_star_question } = action.payload;
  const qutterConfig = state[id];
  qutterConfig.is_star_question = is_star_question;
};

export function UpdateQutterIsReusable (state, action) {
  const { id, is_reusable } = action.payload;
  const qutterConfig = state[id];
  qutterConfig.is_reusable = is_reusable;
};

export function UpdateQutterWeight (state, action) {
  const { id, weight } = action.payload;
  const qutterConfig = state[id];
  qutterConfig.weight = weight;
};

export function UpdateQutterScoringCategory (state, action) {
  const { id, scoring_category } = action.payload;
  const qutterConfig = state[id];
  qutterConfig.scoring_category = scoring_category;
};

export function UpdateQutterTags (state, action) {
  const { id, tags } = action.payload;
  const qutterConfig = state[id];
  qutterConfig.tags = tags;
};

export function UpdateQutterClientId (state, action) {
  const { id, client_id } = action.payload;
  const qutterConfig = state[id];
  qutterConfig.client_id = client_id;
};

export function UpdateQutterRoles (state, action) {
  const { id, roles } = action.payload;
  const qutterConfig = state[id];
  qutterConfig.roles = roles;
};

export function UpdateQutterShortDescription (state, action) {
  const { id, short_description } = action.payload;
  const config = state[id];
  config.short_description = short_description;
};

/*
// export function AddToLibrary (state, action) {
//   const { qutter, currentLanguage } = action.payload;
//   const id = qutter.id;
//   const language = qutter.language;
//   const q = state[id];
//   if (!q) {
//     state[id] = {
//       id: id,
//       [language]: qutter,
//       type: qutter.type,
//       language: {
//         current: language,
//         list: [language]
//       },
//       global: qutter.global && qutter.global === true ? true: false,
//       client_id: qutter.client_id ? qutter.client_id: false,
//       tags: qutter.tags && qutter.tags.length ? qutter.tags: [],
//       role: qutter.role && qutter.role.length ? qutter.role: []
//     };
//   } else {
//     state[id][language] = qutter;
//     try {
//       const idx = state[id].language.list.findIndex((lang) => lang === language);
//       if (idx === -1) {
//         state[id].language.list.push(language);
//       }
//     } catch (e) {
//       console.log('[AddToLibrary] language.list Exception', e);
//     }
//   }

//   if (currentLanguage) {
//     state[id].language.current = currentLanguage;
//   }
// };

*/