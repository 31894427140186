import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

import { Card, Form } from 'react-bootstrap';
import { JsonPreview } from 'features/common';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export const PredictedIntents = ({ intent }) => {
  const [intent_name, set_intent_name] = useState(null);

  useEffect(() => {
    if (!intent)
      set_intent_name(null)
    else if (typeof intent === 'string')
      set_intent_name(intent)
    else {
      set_intent_name(intent.intent_name);
    }
  }, [intent]);

  return (
  <div>
    Intent: {intent_name}
  </div>
  );
};
