import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/pro-solid-svg-icons';

import { HubertQuill } from 'features/HubertQuill';
import { Col, Button } from 'react-bootstrap';

import {
  textFieldPlus,
  textFieldMinus,
  textFieldUpdate
} from 'features/Parameters';

export const TemplateText = ({
  is_default,
  template_id,
  qutter_id,
  text,
  field,
  idx
}) => {
  // console.log('<TemplateText />')
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const interviewReceptState = useSelector(state => state.InterviewReceipt);
  const language = qutter_config.language.current;

  const [editMode, setEditMode] = useState(false);
  const [value, set_value] = useState('');

  const handleDone = () => {
    dispatch(
      textFieldUpdate({
        qutter_id,
        field,
        language,
        idx,
        value
      })
    );
    setEditMode(!editMode);
  };

  const onMinusClick = async () => {
    await dispatch(
      textFieldMinus({
        qutter_id,
        field,
        language,
        idx
      })
    );
  };

  const onPlusClick = async () => {
    await dispatch(
      textFieldPlus({
        qutter_id,
        field,
        language,
        idx
      })
    );
  };

  return (
    <div className="ms-1 mt-1 mb-1">
      {editMode && interviewReceptState.readOnlyMode === false ? (
        <div>
          <HubertQuill
            on_change={set_value}
            placeholder={'Write something...'}
            value={text}
          />
          <Button
            className="w-auto btn btn-sm"
            variant="primary"
            onClick={handleDone}
          >
            Done
          </Button>
        </div>
      ) : (
        (() => {
          if (text && text.length) {
            return (
              <Col
                className={`bubble ${is_default ? '' : 'cursor-pointer'}`}
                style={{
                  position: 'relative'
                }}
              >
                <div className="d-inline-block">
                  <div
                    dangerouslySetInnerHTML={{ __html: text }}
                    onClick={() => {
                      if (
                        !is_default &&
                        interviewReceptState.readOnlyMode === false
                      )
                        setEditMode(!editMode);
                    }}
                  />
                </div>
                {interviewReceptState.readOnlyMode ? (
                  ''
                ) : (
                  <div
                    className={`hover-parent-child ${
                      is_default ? 'd-none' : ''
                    }`}
                    style={{
                      position: 'absolute',
                      right: '8px',
                      top: '3px'
                    }}
                  >
                    <span
                      className="cursor-pointer hover-blue d-flex"
                      onClick={() => {
                        onMinusClick();
                      }}
                    >
                      <FontAwesomeIcon size="xs" icon={faMinusCircle} />
                    </span>
                    <span
                      className="cursor-pointer hover-blue"
                      onClick={() => {
                        onPlusClick();
                      }}
                    >
                      <FontAwesomeIcon size="xs" icon={faPlusCircle} />
                    </span>
                  </div>
                )}
              </Col>
            );
          } else {
            return (
              <Button
                className="btn btn-sm w-auto"
                variant="hubert"
                onClick={() => {
                  editMode ? setEditMode(false) : setEditMode(true);
                }}
              >
                + Add Question Text
              </Button>
            );
          }
        })()
      )}
    </div>
  );
};
