import chroma from 'chroma-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faThumbsUp,
  faThumbsDown,
  faDizzy,
  faHatCowboy,
  faInfinity,
  faEmptySet,
  faBattery0
} from '@fortawesome/pro-solid-svg-icons';


export const statementOptions = [
  { value: 'statement', label: 'statement', rating: 'safe' },
  { value: 'complaint', label: 'complaint', rating: 'safe' },
  { value: 'dont_know', label: 'dont_know', rating: 'safe' },
  { value: 'profanity', label: 'profanity', rating: 'safe' },
  { value: 'gibberish', label: 'gibberish', rating: 'safe' },
  { value: 'praise', label: 'praise', rating: 'safe' },
  { value: 'nothing', label: 'nothing', rating: 'safe' },
  { value: 'everything', label: 'everything', rating: 'safe' },
  { value: 'report_error', label: 'report_error', rating: 'safe' },
  { value: 'no_example', label: 'no_example', rating: 'safe' },
  { value: 'technical_issues', label: 'technical_issues', rating: 'safe' },
  { value: 'starting_date', label: 'starting_date', rating: 'safe' },
];

export const referQuestionOptions = [
  { value: 'refer_question', label: 'refer_question', rating: 'safe' },
  { value: 'continue_previous', label: 'continue_previous', rating: 'safe' },
  { value: 'already_told_you', label: 'already_told_you', rating: 'safe' },
  { value: 'misclicked', label: 'misclicked', rating: 'safe' },
  { value: 'misunderstood', label: 'misunderstood', rating: 'safe' },
];

export const jobMotivationOptions = [
  { value: 'job_motivation', label: 'job_motivation', rating: 'safe' },
  { value: 'want_job', label: 'want_job', rating: 'safe' },
  { value: 'want_money', label: 'want_money', rating: 'safe' },
];

export const experienceOptions = [
  { value: 'experience', label: 'experience', rating: 'safe' },
  { value: 'has_experience', label: 'has_experience', rating: 'safe' },
  { value: 'no_experience', label: 'no_experience', rating: 'safe' },
  { value: 'other_experience', label: 'other_experience', rating: 'safe' },
];

export const resumeOptions = [
  { value: 'resume', label: 'resume', rating: 'safe' },
  { value: 'resume_see', label: 'resume_see', rating: 'safe' },
  { value: 'resume_send', label: 'resume_send', rating: 'safe' },
  { value: 'resume_expand', label: 'resume_expand', rating: 'safe' },
  { value: '', label: '', rating: 'safe' },
];

export const queryOptions = [
  { value: 'query', label: 'query', rating: 'safe' },
  { value: 'query_clarify', label: 'query_clarify', rating: 'safe' },
  { value: 'query_done', label: 'query_done', rating: 'safe' },
  { value: 'query_help', label: 'query_help', rating: 'safe' },
  { value: 'query_who_is_hubert', label: 'query_who_is_hubert', rating: 'safe' },
  { value: 'query_num_questions', label: 'query_num_questions', rating: 'safe' },
  { value: 'query_job', label: 'query_job', rating: 'safe' },
  { value: 'query_next_steps', label: 'query_next_steps', rating: 'safe' },
  { value: 'query_continue_later', label: 'query_continue_later', rating: 'safe' },
  { value: 'query_phone', label: 'query_phone', rating: 'safe' },
  { value: 'query_change_language', label: 'query_change_language', rating: 'safe' },
  { value: 'query_restart_interview', label: 'query_restart_interview', rating: 'safe' },
  { value: 'query_end_interview', label: 'query_end_interview', rating: 'safe' },
  { value: 'query_reuse_interview', label: 'query_reuse_interview', rating: 'safe' },
  { value: 'query_go_back', label: 'query_go_back', rating: 'safe' },
];
export const formalityOptions = [
  { value: 'formality', label: 'formality', rating: 'safe' },
  { value: 'greet', label: 'greet', rating: 'safe' },
  { value: 'goodbye', label: 'goodbye', rating: 'safe' },
  { value: 'thanks', label: 'thanks', rating: 'safe' },
  { value: 'how_are_you', label: 'how_are_you', rating: 'safe' },
];


const affirm = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'green' }} size={'lg'} icon={faThumbsUp} />
    <span className="m-2">Affirmative</span>
  </span>;

const affirm_statement = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'green' }} className="mr-3" size={'lg'} icon={faThumbsUp} />
    <span className="m-2">Affirmative statement</span>
  </span>;

const yes = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'green' }} className="mr-3" size={'lg'} icon={faThumbsUp} />
    <span className="m-2">On "Yes"</span>
  </span>;

const decline = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'red' }} size={'lg'} icon={faThumbsDown} />
    <span className="m-2">Decline</span>
  </span>;

const decline_statement = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'red' }} size={'lg'} icon={faThumbsDown} />
    <span className="m-2">Decline statement</span>
  </span>;

const no = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'red' }} size={'lg'} icon={faThumbsDown} />
    <span className="m-2">On "No"</span>
  </span>;

const statement = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'blue' }} size={'lg'} icon={faComment} />
    <span className="m-2">Statement</span>
  </span>;

const dont_know = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'gray' }} size={'lg'} icon={faDizzy} />
    <span className="m-2">Clear don't know</span>
  </span>;

const dont_know_statement = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'gray' }} size={'lg'} icon={faDizzy} />
    <span className="m-2">Don't know statement</span>
  </span>;

const everything = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'gray' }} size={'lg'} icon={faInfinity} />
    <span className="m-2">Everything statement</span>
  </span>;

const nothing = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'gray' }} size={'lg'} icon={faEmptySet} />
    <span className="m-2">Nothing statement</span>
  </span>;

const fallback = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'black' }} size={'lg'} icon={faHatCowboy} />
    <span className="m-2">Fallback</span>
  </span>;

export const mostCommonIntents = [
  { 
    id: 'affirm', 
    label: affirm, 
    value: "affirm",
    color: "green"
  },
  { 
    id: 'affirm+statement', 
    label: affirm_statement, 
    value: "affirm+statement", 
    color: 'green' 
  },
  { 
    id: 'yes', 
    label: yes, 
    value: "yes", 
    color: 'green' 
  },
  { 
    id: 'decline',
    label: decline,
    value: "decline",
    color: 'red'
  },
  { 
    id: 'decline+statement',
    label: decline_statement,
    value: "decline+statement",
    color: 'red'
  },
  { 
    id: 'no',
    label: no,
    value: "no",
    color: 'red'
  },
  {
    id: 'statement',
    label: statement,
    value: "statement",
    color: 'blue'
  },
  {
    id: 'dont_know',
    label: dont_know,
    value: "dont_know",
    color: 'gray'
  },
  {
    id: 'dont_know_statement',
    label: dont_know_statement,
    value: "dont_know_statement",
    color: 'gray'
  },
  {
    id: 'everything',
    label: everything,
    value: "everything",
    color: 'black'
  },
  {
    id: 'nothing',
    label: nothing,
    value: "nothing",
    color: 'gray'
  },
  {
    id: 'fallback',
    label: fallback,
    value: 'fallback',
    color: 'gray'
  },
  {
    id: 'has_experience',
    label: 'Has experience',
    value: 'has_experience',
    color: 'gray'
  },
  {
    id: 'job_motivation',
    label: 'Job motivation',
    value: 'job_motivation',
    color: 'gray'
  },
];

export const groupedIntents = [
  {
    label: 'Most Common',
    options: mostCommonIntents,
  },
  {
    label: 'Statement related',
    options: statementOptions,
  },
  {
    label: 'Refer Question related',
    options: referQuestionOptions,
  },
  {
    label: 'Job Motivation related',
    options: jobMotivationOptions,
  },
  {
    label: 'Experience related',
    options: experienceOptions,
  },
  {
    label: 'Resume related',
    options: resumeOptions,
  },
  {
    label: 'Query related',
    options: queryOptions,
  },
  {
    label: 'Formality related',
    options: formalityOptions,
  },
];

export const allIntents = mostCommonIntents
  .concat(statementOptions)
  .concat(referQuestionOptions)
  .concat(jobMotivationOptions)
  .concat(experienceOptions)
  .concat(resumeOptions)
  .concat(queryOptions)
  .concat(formalityOptions)

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    // fontFamily: 'Font Awesome 5 Free',
    // 'font-family': 'Font Awesome 5 Regular',
    backgroundColor: color,
    // borderRadius: 10,
    // content: '" "',
    // fontWeight: 900,
    // display: 'block',
    // height: 0,
    // width: 0,
    // marginRight: 8,
  },
});

export const colourStyles = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: 'white',

    minHeight: '30px',
    height: '30px',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '30px',
    padding: '0 6px'
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '30px',
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma((data.color ? data.color: '#000'));
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  input: (styles) => ({ 
    ...styles, 
    margin: '0px',
    ...dot(),
  }),
  placeholder: (styles) => ({ 
    ...styles, 
    ...dot('#ccc') 
  }),
  singleValue: (styles, { data }) => ({ 
    ...styles, 
    ...dot(data.color) 
  }),
};