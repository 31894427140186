
import React from 'react';
import { statuses } from '../constants/statuses';

export const ApplicationStatus = ({
  application_id,
  interview_id,
  status,
  deleted
}) => {
  if (deleted) {
    return (<span>{statuses['deleted']}</span>)
  } else 
  if (status) {
    return (<span>{statuses[status]}</span>)
  } else {
    return (<span>{'-'}</span>)
  }
}