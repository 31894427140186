import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning } from '@fortawesome/pro-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';

// import { JumpsToOverlay } from '..';
// import { EvaluationOverlay } from '..';
import { SelectActionsModal } from './SelectActionsModal';

import { 
  EvaluationOverlay,
  setCustomEvaluation
} from 'features/QutterEvaluation';

export const PreviewSelectOption = ({
  template_id,
  qutter_id,
  option_idx,
  order
}) => {
  const dispatch = useDispatch();
  const [showActionsModal, setShowActionsModal] = useState(false);

  const templateConfig = useSelector(state => state.Template);
  const readOnly = templateConfig.readOnlyMode;

  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];

  if (!qutter) return <span>Option missing.</span>;
  const option = qutter.options[option_idx];

  // Option properties
  let Evaluation = useSelector(state => state.Evaluation[qutter_id]);
  const has_actions = option.actions && option.actions.length > 0;
  const custom_key = `${option.id}.evaluation`;

  const [evaluation, set_evaluation] = useState({});
  const [is_default, set_is_default] = useState(true);

  useEffect(() => {
    if (!template_id) {
      let evalu = {
        name: option.evaluation,
        bonus_points: option.bonus_points,
        points: option.points
      };
      set_evaluation(evalu);
      set_is_default(true);
    } else 

    if (Evaluation && Evaluation[language]) {
      if (Evaluation[language].hasOwnProperty(custom_key)) {
        set_evaluation(Evaluation[language][custom_key]);
        set_is_default(Evaluation[language].is_default);
      } 
      else 
      {
        let evalu = {
          name: option.evaluation,
          bonus_points: option.bonus_points,
          points: option.points
        };
        dispatch(setCustomEvaluation({
          language,
          qutter_id,
          id: option.id,
          evaluation: evalu
        }));
      }
    }
  }, [qutter_config, Evaluation, language]);

  return (
    <Row className="m-1 option-row-hover">
      <Col className="m-auto" style={{ position: 'relative' }}>
        {showActionsModal && (
          <SelectActionsModal
            id={qutter_id}
            option_idx={option_idx}
            showModal={showActionsModal}
            setShowModal={setShowActionsModal}
          />
        )}

        <div className="option text-center w-50 m-auto">
          <span className="">{option.label}</span>
          {readOnly ? (null):(
            <span className="font-size-10">{` - ${option.id}`}</span>
          )}
        </div>

        <div style={{ position: 'absolute', top: '3px', right: 0 }}>
          <div className="m-auto float-end" style={{ verticalAlign: 'middle' }}>
            {readOnly ? (null):
            (
              <span
                onClick={() => {
                  setShowActionsModal(true);
                }}
                className={`cursor-pointer hover-blue ${
                  showActionsModal || has_actions ? 'color-blue' : ''
                }`}
              >
                <FontAwesomeIcon icon={faBoltLightning} />
              </span>
            )}

            <EvaluationOverlay
              template_id={template_id}
              qutter_id={qutter_id}
              evaluation={evaluation}
              type={'option'}
              id={option.id}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};
