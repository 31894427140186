import React from 'react';
import { useSelector } from 'react-redux';

export const BonuspointSummary = ({}) => {
  const scoring = useSelector(state => state.scoring);
  // console.log('<BonuspointSummary /> scoring', scoring);

  return (
    <div className='' style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Bonus Points</span>
      <span className='p-0 text-center'>
        <span>{scoring.bonuspoints_sum}</span> / {scoring.bonuspoints_max}</span>
    </div>
  );
};
