import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faArrowRotateLeft } from '@fortawesome/pro-solid-svg-icons';

import { TemplateText } from './TemplateText';

import { templateTextCopy, textFieldResetToOriginal } from 'features/Parameters';

import { capitalizeFirstLetter } from 'features/common';

export const TemplateTexts = ({
  template_id,
  qutter_id,
  field,
}) => {
  // console.log('<TemplateTexts />', qutter_id, field);
  const dispatch = useDispatch();

  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];

  let params = useSelector(state => state.Parameters[qutter_id]);
  const interviewReceptState = useSelector(state => state.InterviewReceipt);
  const custom_key = `${field}.${language}`;

  const [text, set_text] = useState(qutter[field]);
  const [is_default, set_is_default] = useState(true);

  useEffect(() => {
    if (params && params.hasOwnProperty(custom_key)) {
      set_text(params[custom_key])
      set_is_default(false);
    } else {
      set_text(qutter[field]);
      set_is_default(true);
    }
  }, [qutter_config, params, language]);

  return (
  <div className='hover-parent'>

    <div className='font-size-10'>

      {(function() {
        if (qutter_config.type === 'info') {
          return (
            <span className='text-start text-uppercase text-900'>
              {capitalizeFirstLetter(field)}
            </span>
          );
        } else {
          return (
            <span className='text-start text-uppercase text-900'>
              {field === 'text' ? 'Question (not editable)': capitalizeFirstLetter(field) }
            </span>
          );
        }
      })()}

      <span className="float-end hover-parent-child">
        {template_id && interviewReceptState.readOnlyMode === false ? (
          <>
          {is_default ? (
            <span
              onClick={() => {
                dispatch(templateTextCopy({
                  qutter_id,
                  field,
                  language,
                  original: qutter[field] ? qutter[field]: []
                }))
              }}
              className={
                `hover-blue me-2 ${
                  (!is_default || field === 'text') && qutter_config.type !== 'info' ? 'd-none': ''
                }`
              }
            >
              <FontAwesomeIcon className='me-1' size={'lg'} icon={faPen} />
              Edit
            </span>
          ):(
            <span
              className={`hover-blue font-size-10 ms-1 ${is_default === true ? 'd-none': ''}`}
              onClick={() => {
                dispatch(textFieldResetToOriginal({
                  qutter_id,
                  field,
                  language: language
                }));
              }}
            >
              <FontAwesomeIcon className='me-1' size={'lg'} icon={faArrowRotateLeft} />
              Reset to original
            </span>
          )}
          </>
        ):(null)}
      </span>

    </div>

    {!text || !Array.isArray(text) || !text.length ? (null) : (
      text.map((text, idx) => {
        return (
          <TemplateText
            key={`template-text-${qutter_id}-${field}-${idx}`}
            is_default={is_default}
            template_id={template_id}
            qutter_id={qutter_id}
            idx={idx}
            field={field}
            text={text}
          />
        )
      })
    )}

  </div>
  );
};
