
export const municipality = [
  { label: '' , value: '' },
  { label: 'Stockholm' , value: 'stockholm' },
  { label: 'Rotebro' , value: 'rotebro' },
  { label: 'Täby' , value: 'täby' },
  { label: 'Danderyd' , value: 'danderyd' },
  { label: 'Länna' , value: 'länna' },
  { label: 'Göteborg' , value: 'göteborg' },
  { label: 'Borås' , value: 'borås' },
  { label: 'Malmö' , value: 'malmö' },
];
