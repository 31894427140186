import React  from 'react';
import { Container } from 'react-bootstrap';
import Divider from 'components/common/Divider';

import Intents from 'features/QutterOpen/components/Intents';
import { EvaluateOpen } from '..';
import { QutterSummary } from 'features/QutterSummary';
import { TextComponent } from 'features/TextComponent';

export const CreateOpen = ({
  id
}) => {
  return (
    <Container key={'create-question-open'}>

      <EvaluateOpen id={id} />

      <Divider className="mt-4">Texts</Divider>
      <TextComponent id={id} field={'intro'} />
      <TextComponent id={id} field={'text'} />
      <TextComponent id={id} field={'outro'} />
      <TextComponent id={id} field={'clarify'} />

      <Divider className="mt-4">Intents</Divider>
      <Intents id={id} />

      <Divider className="mt-4">Summary</Divider>
      <QutterSummary id={id} />

    </Container>
  );
}
