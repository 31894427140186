import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';

import { CalendarEvaluationElement } from './CalendarEvaluationElement';

export const CalendarEvaluationList = ({
  template_id,
  qutter_id,
}) => {
  // console.log('<CalendarEvaluationList />');

  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];

  let Evaluation = useSelector(state => state.Evaluation[qutter_id]);
  const custom_key = `evaluation`;

  const [list, set_list] = useState([]);
  const [is_default, set_is_default] = useState(true);

  useEffect(() => {
    if (!template_id) {
        set_list(qutter_config.evaluation); 
        set_is_default(true);
    } else {
      if (Evaluation && Evaluation[language]) {
        if (Evaluation[language].hasOwnProperty(custom_key)) {
          set_list(Evaluation[language][custom_key]);
          set_is_default(Evaluation[language].is_default);
        } else {
          set_list(qutter_config.evaluation);
          set_is_default(true);
        }
      } else {
        set_list(qutter_config.evaluation);
        set_is_default(true);
      }
    }
  }, [qutter_config, Evaluation, language]);

  if (!list) return null;

  // console.log('CalendarEvaluationList', list);
  return (
    <div className='text-center'>
      {list.map((evaluation, idx) => {
        return (
          <CalendarEvaluationElement
            key={`CalendarEvaluationElement-${idx}`}
            template_id={template_id}
            qutter_id={qutter_id}
            evaluation={evaluation} 
          />
        )
      })}
    </div>
  )
}
