const tz = { hasResult: false, timeZone: undefined };

export function getTimezone() {
    if(tz.hasResult)
        return tz.timeZone;

    try {
        tz.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch { }
    tz.hasResult = true;
    return tz.timeZone;
} 