import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPlusCircle, faTags } from '@fortawesome/pro-solid-svg-icons';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

import { Card, Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

import { RoleSelect } from 'features/RoleSelect';
import { QutterTagSelect } from 'features/QutterTagSelect';
import { LanguageSelect } from 'features/LanguageSelect';
import { QutterList } from 'features/QutterList';
import { QutterTypeSelect } from 'features/QutterType';

import { toggleCreateQuestionModal } from 'features/Modals/slices/ModalSlice';
import { CreateQutterBlockModal } from 'features/Modals/components/CreateQutterBlockModal';

import { HubiverseTagsModalShowButton } from 'features/HubiverseTags';

import { search } from '../api/api';

export const QutterSearch = ({
  template_id=null,
  block_id=null,
  block_name=null,
  qutter_idx=null,
  qutter_id=null,
  option_idx=null,
  option_id=null,
  selection=false,
  add_policy='',
  setShowModal=null,
  shouldDisplayCreateQutterButton=true,
  shouldDisplayCreateQutterBlockButton=true
}) => {
  const dispatch = useDispatch();
  const [text, set_text] = useState('');
  const [language, set_language] = useState('');
  const [role, set_role] = useState([]);
  const [tags, set_tags] = useState([]);
  const [types, set_types] = useState([]);
  const [loading, set_loading] = useState(false);
  const [show_create_qutter_block_modal, set_show_create_qutter_block_modal] = useState(false);

  const [qutter_list, set_qutter_list] = useState([]);

  const on_create_question_click = (e) => {
    dispatch(toggleCreateQuestionModal({
      show: true,
      qutter_id: null,
      language: null,
    }));
  };

  const on_create_question_block_click = (e) => {
    set_show_create_qutter_block_modal(!show_create_qutter_block_modal);
  };

  const on_search_click = () => {
    let search_params = {};
    if (text.length) search_params.text = text;
    if (language.length) search_params.language = language;
    if (tags.length) search_params.tags = tags;
    if (role.length) search_params.role = role;
    if (types.length) search_params.types = types;
    set_loading(true);

    search(search_params).then((data) => {
      set_qutter_list(data);
      set_loading(false);
    }).catch((error) => {
      set_loading(false);
      toast.error(`Search failed: ${error}`);
    });
  };

  return (
    <>
    <CreateQutterBlockModal showModal={show_create_qutter_block_modal} setShowModal={set_show_create_qutter_block_modal} />

      <Card className='mb-1'>
        <Card.Body>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextSearch">
            <Form.Label>Question Text Search</Form.Label>
            <Form.Control
              type="text"
              placeholder='"Tell me a situation..."'
              defaultValue={text}
              onChange={(e) => { set_text(e.target.value); }}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  on_search_click();
                }
              }}
            />
          </Form.Group>

          <Accordion className='m-1 mt-2 mb-2'>
            <Accordion.Item eventKey="0">
              <Accordion.Header as={'span'} style={{ padding: 0}}>
                <span className=''>Search options</span>
              </Accordion.Header>
              <Accordion.Body>

                <QutterTypeSelect
                  multi={true}
                  placeholder={'Search by question type'}
                  className='m-1'
                  on_select={(e)=> {
                    set_types(e.map((t) => {return t.value; }))
                  }}
                />

                <LanguageSelect
                  on_select={(e) => { set_language(e.value) }}
                  placeholder='Search by language'
                  className={'m-1'}
                />

                <RoleSelect
                  multi={true}
                  className={'m-1'}
                  on_select={(e) => { 
                    set_role(e.map((t) => {return t.value; }))
                  }}
                  placeholder='Search by role'
                />

                <QutterTagSelect
                  multi={true}
                  className={'m-1'}
                  placeholder='Search by tag'
                  on_select={(e) => {
                    set_tags(e.map((t) => {return t.value; }))
                  }}
                />

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className='d-flex'>
            <div className='me-2'>
              <Form.Check
                type='checkbox'
                id='qutters-checkbox'
                label='Questions'
                defaultChecked
                disabled={true}
                onChange={(e) => { console.log(e) }}
              />
            </div>
            <div className=''>
              <Form.Check
                disabled={true}
                type='checkbox'
                id='utters-checkbox'
                label='Utters'
                onChange={(e) => { console.log(e) }}
              />
            </div>
          </div>

          <span
            className="font-size-10 me-4 hover-blue"
            onClick={on_search_click}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} className='me-1' />
            Search
          </span>

          {shouldDisplayCreateQutterButton && (
          <span
            className="font-size-10 hover-blue"
            onClick={on_create_question_click}
          >
            <FontAwesomeIcon icon={faPlusCircle} className='me-1' />
            Create Question
          </span>
          )}

          {/* 
          {shouldDisplayCreateQutterBlockButton && (
          <span
            className="ms-2 font-size-10 hover-blue"
            onClick={on_create_question_block_click}
          >
            <FontAwesomeIcon icon={faPlusCircle} className='me-1' />
            Create Question Block
          </span>
          )} 
          */}

          <HubiverseTagsModalShowButton />

        </Card.Body>
      </Card>

      <QutterList
        selection={selection}
        isLoading={loading}
        list={qutter_list}
        add_policy={add_policy}
        template_id={template_id}
        block_id={block_id}
        block_name={block_name}
        qutter_id={qutter_id}
        qutter_idx={qutter_idx}
        option_idx={option_idx}
        option_id={option_id}
        setShowModal={setShowModal}
      />
    </>
  );
};
