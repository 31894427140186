import { initiateEvaluation, setDefaultEvaluation } from "..";

export const single_select_context = async (
  dispatch,
  library,
  template,
  eval_state,
  node,
  is_followup
) => {
  const qutter_config = library[node.id];
  const languages = template.language_settings.list;

  dispatch(initiateEvaluation({
    languages,
    qutter_id: node.id,
    is_followup: is_followup
  }));

  languages.map(async language => {
    let bonuspoints = [];
    const qutter = qutter_config[language];
    if (!qutter) return;
    const options = qutter.options;

    for (let i = 0; i < options.length; i++) {
      const option = options[i];

      let default_eval = {
        name: option.evaluation
      };

      if (option.hasOwnProperty('bonus_points')) {
        default_eval.bonus_points = option.bonus_points;
      }
      if (option.hasOwnProperty('points')) {
        default_eval.points = option.points;
      }
      if (option.hasOwnProperty('weight')) {
        default_eval.weight = option.weight;
      }

      dispatch(setDefaultEvaluation({
        language,
        qutter_id: node.id,
        id: option.id,
        evaluation: default_eval
      }));
    }
  });
};

export const multiple_select_context = async (
  dispatch,
  library,
  template,
  eval_state,
  node,
  is_followup
) => {
  const qutter_config = library[node.id];
  const languages = template.language_settings.list;

  dispatch(initiateEvaluation({
    languages,
    qutter_id: node.id,
    is_followup: is_followup
  }));

  languages.map(async language => {
    let bonuspoints = [];
    const qutter = qutter_config[language];
    if (!qutter) return;
    const options = qutter.options;

    for (let i = 0; i < options.length; i++) {
      const option = options[i];

      let default_eval = {
        name: option.evaluation
      };

      if (option.hasOwnProperty('bonus_points')) {
        default_eval.bonus_points = option.bonus_points;
      }
      if (option.hasOwnProperty('points')) {
        default_eval.points = option.points;
      }
      if (option.hasOwnProperty('weight')) {
        default_eval.weight = option.weight;
      }

      dispatch(setDefaultEvaluation({
        language,
        qutter_id: node.id,
        id: option.id,
        evaluation: default_eval
      }));
    }
  });
};
