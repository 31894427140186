import React, { useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row } from 'react-bootstrap';
import Divider from 'components/common/Divider';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";

import enGB from 'date-fns/locale/en-GB';     // British English
import enUS from 'date-fns/locale/en-US';     // American English (default - no setup needed)
import es from 'date-fns/locale/es';          // Spanish
import fr from 'date-fns/locale/fr';          // French
import sv from 'date-fns/locale/sv';          // Swedish
import fi from 'date-fns/locale/fi';          // Finnish
import nb from 'date-fns/locale/nb';          // Norwegian (bokmål)
import da from 'date-fns/locale/da';          // Danish

import { TextComponent } from 'features/TextComponent';
import { QutterSummary } from 'features/QutterSummary';

registerLocale('es', es);
registerLocale('fr', fr);
registerLocale('sv-se', sv);
registerLocale('fi', fi);
registerLocale('en-GB', enGB);
registerLocale('en-US', enUS);
registerLocale('nb', nb);
registerLocale('da', da);

export const CreateCalendar = ({ id }) => {
  console.log('<CreateCalendar />');
  const dispatch = useDispatch();
  const config = useSelector(state => state.config);
  const qutter_config = useSelector(state => state.Library[id]);

  const [startDate, setStartDate] = useState(new Date());
  const [type, set_type] = useState('date-picker');

  return (
    <Container key={'create-question-info'}>
      <TextComponent id={id} field={'intro'} />
      <TextComponent id={id} field={'text'} />
      <TextComponent id={id} field={'outro'} />

      <Divider className="mt-4">Calendar Type</Divider>
      <select
        onChange={(e) => {
          set_type(e.target.value);
        }}
      >
        <option value="date-picker">Date picker</option>
        <option value="month-picker">Month picker</option>
      </select>

      <Divider className="mt-4">Example</Divider>
      <Row>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          locale={`${qutter_config.language.current.toLowerCase()}`}
          placeholderText="Weeks start on Monday"
          showMonthYearPicker={type === 'date-picker' ? false: true}
        />
      </Row>

      <Divider className="mt-4">Summary</Divider>
      <QutterSummary id={id} />
    </Container>
  );
}
