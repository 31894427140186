import { lookup } from "features/common";
import { nanoid } from "@reduxjs/toolkit";

/**
 * Sets a template specific evaluation to an option 
 * (single/multiple select questions)
 */
export function EditQutterOptionEvaluation (state, action) {
  const {
    id,
    qutter_id,
    option_id,
    evaluation,
    bonus_points,
    weight
  } = action.payload;
  let params = state[id];
  let value = { id: evaluation };

  if (evaluation === 'bonus') value['bonus_points'] = bonus_points;
  else if (evaluation === 'point') value['weight'] = weight/100;

  if (!params.hasOwnProperty(qutter_id)) {
    params[qutter_id] = {
      [option_id]: {
        evaluation: value
      }
    };
  } else {

    if (!params[qutter_id].hasOwnProperty(option_id)) {

      params[qutter_id] = {
        ...params[qutter_id],
        [option_id]: { evaluation: value }
      }
    } else {
      params[qutter_id] = {
        ...params[qutter_id],
        [option_id]: {
          ...params[qutter_id][option_id],
          evaluation: value
        }
      };
    }
  }
}

/********** Template Specific Followups **********/

/* Edit default option followups
* Copies qutter.options[option_idx].jumps_to 
* to
* parameters.qutter_id.option_id.jumps_to
*/
export function EditDefaultOptionFollowups (state, action) {
  const {
    id,
    qutter_id,
    option_id,
    jumps
  } = action.payload;
  const parameters = state[id];

  if (parameters.hasOwnProperty(qutter_id)) {
    if (parameters[qutter_id].hasOwnProperty(option_id)) {
      parameters[qutter_id][option_id].jumps_to = jumps;
    } else {
      parameters[qutter_id][option_id] = { jumps_to: jumps };
    }
  } else {
    parameters[qutter_id] = {
      [option_id]: { jumps_to: jumps }
    };
  }
}

/* Reset to default followups 
* Removes parameters.qutter_id.option_id.jumps_to
*/
export function RestoreDefaultOptionFollowups (state, action) {
  const {
    id,
    qutter_id,
    option_id
  } = action.payload;
  const parameters = state[id];

  if (parameters.hasOwnProperty(qutter_id)) {
    if (parameters[qutter_id].hasOwnProperty(option_id)) {
      if (parameters[qutter_id][option_id].hasOwnProperty('jumps_to')) {
        delete parameters[qutter_id][option_id].jumps_to;
        if (Object.keys(parameters[qutter_id][option_id]).length === 0)
          delete parameters[qutter_id][option_id];
        if (Object.keys(parameters[qutter_id]).length === 0)
          delete parameters[qutter_id];
      }
    }
  }
}

export function RemoveOptionFollowup (state, action) {
  const {
    id,
    parent_id,
    qutter_id,
    option_id,
  } = action.payload;

  const parameters = state[id];
  let index = parameters[parent_id][option_id].jumps_to.indexOf(qutter_id);
  if (index > -1) {
    parameters[parent_id][option_id].jumps_to.splice(index, 1);
  }
}

export function RemoveFollowup (state, action) {
  const {
    id,
    parent_id,
    qutter_id,
    type,
    options
  } = action.payload;
  const parameters = state[id];

  if (!['single-select', 'multiple-select', 'open'].includes(type)) {
    parameters[qutter_id] = false;
    return;
  }

  options.map((option) => {
    const option_id = option.id;

    // Check if custom followup or default
    if (
      parameters.hasOwnProperty(parent_id) && 
      parameters[parent_id].hasOwnProperty(option_id) && 
      parameters[parent_id][option_id].hasOwnProperty('jumps_to')
    ) {
      // Custom followup
      let index = parameters[parent_id][option_id].jumps_to.indexOf(qutter_id);
      if (index > -1) {
        parameters[parent_id][option_id].jumps_to.splice(index, 1);
      }
    } else {
      // Default followup
      if (!parameters.hasOwnProperty(parent_id)) {
        parameters[parent_id] = {};
      }
      if (option.jumps_to.includes(qutter_id)) {
        if (parameters[parent_id].hasOwnProperty(option_id)) {
          // parameters[parent_id][option_id][qutter_id] = false;
          parameters[parent_id][option_id].jumps_to = [...option.jumps_to];
          let index = parameters[parent_id][option_id].jumps_to.indexOf(qutter_id);
          if (index > -1) {
            parameters[parent_id][option_id].jumps_to.splice(index, 1);
          }
        } else {
          parameters[parent_id][option_id] = {
            // [qutter_id]: false,
            jumps_to: [...option.jumps_to]
          };
          let index = parameters[parent_id][option_id].jumps_to.indexOf(qutter_id);
          if (index > -1) {
            parameters[parent_id][option_id].jumps_to.splice(index, 1);
          }
        }
      }
    }
  });
}

/**
 * Adds custom followup to select qutter
 */
export function AddFollowup (state, action) {
  const {
    id,
    qutter_id,
    option_id,
    followup_id,
  } = action.payload;
  let parameters = state[id];

  if (
    parameters.hasOwnProperty(qutter_id) && 
    parameters[qutter_id].hasOwnProperty(option_id) && 
    parameters[qutter_id][option_id].hasOwnProperty('jumps_to')
  ) {
    // Custom followup
    parameters[qutter_id][option_id].jumps_to.push(followup_id);
  }
}


/**
 * QUTTER LOGIC AND CONDITIONS - OLD
export function QutterConditionPlus (state, action) {
  const { 
    template_id,
    qutter_id,
    rule_idx
  } = action.payload;
  let parameters = state[template_id];

   if (parameters.hasOwnProperty(qutter_id)) {
    if (parameters[qutter_id].hasOwnProperty('conditioned_on')) {
      parameters[qutter_id].conditioned_on.push({
        type: 'qutter_answer',
        id:   null,
        value: []
      });
    } else {
      parameters[qutter_id].conditioned_on = [{
        type: 'qutter_answer',
        id:   null,
        value: []
      }];
    }
  } else {
    parameters[qutter_id] = {
      conditioned_on: [{
        type: 'qutter_answer',
        id:   null,
        value: []
      }]
    };
  }
}

export function QutterConditionUpdate (state, action) {
  const { template_id, qutter_id, conditioned_on_idx, condition } = action.payload;
  let parameters = state[template_id];
  parameters[qutter_id].conditioned_on[conditioned_on_idx] = condition;
}

export function QutterConditionRemoveByIndex (state, action) {
  const { template_id, qutter_id, conditioned_on_idx } = action.payload;
  let parameters = state[template_id];
  parameters[qutter_id].conditioned_on.splice(conditioned_on_idx, 1);
}
 */
