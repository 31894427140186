import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';

// Features
import { TemplateQutterSelect } from 'features/TemplateQutterSelect';
import { QutterAnswerSelect } from 'features/QutterAnswerSelect';

import { ruleConditionUpdate } from 'features/Qutters';
import { customRuleConditionUpdate } from 'features/Parameters';

export const RuleConditionByQutterAnswer = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  condition_idx,
  condition
}) => {
  const dispatch = useDispatch();
  console.log('<RuleConditionByQutterAnswer />');
  const [chosen_qutter_id, set_chosen_qutter_id] = useState(null);

  return (
  <div>
    <div className='d-flex font-size-10 m-1'>
      <div className='text-start m-auto'>
        Choose question from template
      </div>
      <div className='w-50 text-start'>
        <TemplateQutterSelect
          multi={false}
          disabled={template_id && is_default ? true: false}
          template_id={template_id}
          current_qutter_id={qutter_id}
          selected={condition.value}
          placeholder={'Choose questions from template'}
          on_select={(e) => {
            set_chosen_qutter_id(e.value)
            // let act = Object.assign({}, action);
            // act.value = e.map(s => s.value);
            // if (template_id) {
            //   dispatch(customRuleActionUpdate({ template_id, qutter_id, rule_idx, action_idx, updated_action: act }));
            // } else {
            //   dispatch(ruleActionUpdate({ qutter_id, rule_idx, action_idx, updated_action: act }));
            // }
          }}
        />
      </div>
    </div>

    {chosen_qutter_id ? (
    <div className='d-flex font-size-10 m-1'>
      <div className='text-start m-auto'>
        Choose Answers
      </div>
      <div className='w-50 text-start'>
        <QutterAnswerSelect
          template_id={template_id}
          qutter_id={qutter_id}
          selected={condition.value}

          multi={true}
          clearable={true}
          searchable={true}
          disabled={template_id && is_default ? true: false}

          on_select={(e) => {
            console.log('e', e)
            let cond = Object.assign({}, condition);
            cond.value = e.map(s => s.value);

            if (template_id) {
              dispatch(customRuleConditionUpdate({ template_id, qutter_id, rule_idx, condition_idx, condition: cond }))
            } else {
              dispatch(ruleConditionUpdate({ qutter_id, rule_idx, condition_idx, condition: cond }))
            }
          }}
        />
      </div>
    </div>
    ):(
      <div className='red'>Choose a question.</div>
    )}
  </div>
  );
};