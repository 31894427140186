import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateBack } from '@fortawesome/pro-solid-svg-icons';

import { InterviewSummaryBlock, AddInterviewSummaryBlockOverlay } from '..';

import {
  update_block_ids,
  update_blocks,
  remove_from_block_at_idx,
  add_to_block_at_idx
} from '../slices/InterviewSummarySlice';

import { reorder } from '../utils/utils';
import * as INTERVIEW_SUMMARY_CONSTANTS from 'features/InterviewSummary';

export const InterviewSummaryContainer = ({ template_id }) => {
  // console.log('<InterviewSummaryContainer />', template_id)
  const dispatch = useDispatch();
  const lib = useSelector(state => state.Library);

  const templateConfig = useSelector(state => state.Template);
  const readOnly = templateConfig.readOnlyMode;
  const template = templateConfig[template_id];
  const language = useSelector(state => state.Template.language.current);
  const languages = template.language_settings.list;
  const list = useSelector(
    state => state.TemplateOutline[language].qutter_list
  );
  const summary = useSelector(state => state.InterviewSummary[template_id]);

  const [is_loading, set_is_loading] = useState(true);
  const [draggable_type, set_draggable_type] = useState(null);
  const [current_draggable_id, set_current_draggable_id] = useState(null);
  const [current_draggable_idx, set_current_draggable_idx] = useState(null);

  const [current_droppable_id, set_current_droppable_id] = useState(null);
  const [current_droppable_idx, set_current_droppable_idx] = useState(null);

  const [summary_block_ids, set_summary_block_ids] = useState(
    summary.block_ids
  );
  const [summary_blocks, set_summary_blocks] = useState(summary.blocks);

  const presummary = async reset => {
    let temp_block_ids = new Set();
    let temp_blocks = {};
    if (!summary.approved || reset) {
      for (let i = 0; i < list.length; i++) {
        let qutter_id = list[i];
        if (qutter_id in lib) {
          let config = lib[qutter_id];
          let language = config.language.current;
          let qutter = config[language];

          if (!qutter) {
            toast.warning(
              `Missing current language for question ${qutter_id}.`
            );
            continue;
          }

          let presentation = qutter.presentation;
          if (!presentation || !presentation.block) {
            toast.warning(
              `Missing default block (1) name for qutter_id: ${qutter_id}`
            );
            continue;
          }
          temp_block_ids.add(presentation.block);

          if (!temp_blocks.hasOwnProperty(presentation.block)) {
            const block =
              INTERVIEW_SUMMARY_CONSTANTS.default_summary_blocks.find(
                block => block.value === presentation.block
              );
            if (!block) {
              toast.warning(
                `Missing default block name (2) for block id: ${presentation.block}`
              );
              continue;
            }
            temp_blocks[presentation.block] = {
              id: presentation.block,
              name: block.label,
              details: [{ id: qutter_id }]
            };
          } else {
            temp_blocks[presentation.block].details.push({ id: qutter_id });
          }
        }
      }

      // Put not shown last
      if (
        temp_block_ids.has(
          INTERVIEW_SUMMARY_CONSTANTS.NOT_VIEWABLE_BLOCK_IN_SUMMARY
        )
      ) {
        temp_block_ids.delete(
          INTERVIEW_SUMMARY_CONSTANTS.NOT_VIEWABLE_BLOCK_IN_SUMMARY
        );
        temp_block_ids.add(
          INTERVIEW_SUMMARY_CONSTANTS.NOT_VIEWABLE_BLOCK_IN_SUMMARY
        );
      }

      dispatch(
        update_block_ids({ template_id, block_ids: Array.from(temp_block_ids) })
      );
      dispatch(update_blocks({ template_id, blocks: temp_blocks }));
    }
  };

  useEffect(() => {
    // presummary(true);
    set_is_loading(false);
  }, []);

  const onDragEnd = result => {
    const { source, destination } = result;
    // dropped outside the list
    if (!result.destination) return;
    if (!draggable_type) return;

    if (draggable_type === 'draggable-block') {
      const items = reorder(
        [...summary.block_ids],
        result.source.index,
        result.destination.index
      );
      set_summary_block_ids(items);
    } else if (draggable_type === 'draggable-qutter') {
      /**
      Example source
      { droppableId: "droppable-block#abc123", index: 0 }
      Example destination
      { droppableId: "droppable-block#abc1234", index: 1 }
      */
      let from_block = source.droppableId.split('###');
      const from_idx = source.index;
      let to_block = destination.droppableId.split('###');
      const to_idx = destination.index;

      if (from_block.length < 2) {
        toast.warning(`from_block.length < 2`);
        return;
      }
      if (to_block.length < 2) {
        toast.warning(`to_block.length < 2`);
        return;
      }
      if (to_block === 'droppable-blocks') {
        toast.warning(`to_block === 'droppable-blocks`);
        return;
      }
      if (!current_draggable_id || current_draggable_id.length < 3) {
        toast.warning(
          `!current_draggable_id || current_draggable_id.length < 3`
        );
        return;
      }

      if (from_block[1] === to_block[1]) {
        if (from_idx === to_idx) {
          toast.warning(`Move within the same block, same index, do nothing.`);
          return;
        }
        // Move within the same block.
        // const items = reorder(
        //   summary.blocks[from_block[1]].questions,
        //   from_idx,
        //   to_idx
        // );
        // blocks[from_block[1]].questions = items;
        // set_summary_blocks(blocks);

        dispatch(
          remove_from_block_at_idx({
            template_id,
            block_id: from_block[1],
            idx: from_idx
          })
        );
        dispatch(
          add_to_block_at_idx({
            template_id,
            block_id: to_block[1],
            idx: to_idx,
            qutter_id: current_draggable_id
          })
        );
        return;
      } else {
        dispatch(
          remove_from_block_at_idx({
            template_id,
            block_id: from_block[1],
            idx: from_idx
          })
        );
        dispatch(
          add_to_block_at_idx({
            template_id,
            block_id: to_block[1],
            idx: to_idx,
            qutter_id: current_draggable_id
          })
        );
        // set_summary_blocks(blocks);
      }
    }
  };

  const onDragStart = result => {
    const { source, draggableId } = result;
    if (!source || !source.droppableId) return;
    set_current_droppable_id(source.droppableId);
    set_current_droppable_idx(source.index);

    const type_id = draggableId.split('###');
    set_draggable_type(type_id[0]);
    set_current_draggable_id(type_id[1]);
  };

  if (is_loading) return (<span>Loading...</span>);

  return (
    <div>
      {readOnly ? (null):
      (
        <div className="text-end">
          <span
            className="font-size-10 hover-blue me-2"
            onClick={() => {
              presummary(true);
            }}
          >
            <FontAwesomeIcon icon={faRotateBack} className="me-1" />
            Reset to original
          </span>
          <AddInterviewSummaryBlockOverlay template_id={template_id} />
        </div>
      )}

    <DragDropContext 
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    >
      {summary.block_ids.map((summary_block_id, idx) => (
        <InterviewSummaryBlock
          key={`summary-block-${summary_block_id}-${idx}`}
          template_id={template_id}
          summary_block_id={summary_block_id}
          summary_block_idx={idx}
        />
      ))}
    </DragDropContext>
  </div>
  );
};
