import { 
  createAsyncThunk,
  nanoid
} from '@reduxjs/toolkit';

import { saveNewJob } from './api';

export const SaveNewJob = createAsyncThunk(
  'create-job/save-new',
  async (job) => {
    // The value we return becomes the `fulfilled` action payload
    return await saveNewJob(job);
  }
);
