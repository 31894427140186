import React, { useState } from 'react';
import { Card, Collapse } from 'react-bootstrap';

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

export const ApplicationActivityLog = ({ activityLog }) => {
  const [open, setOpen] = useState(false);
  if (!activityLog || !activityLog.length) return (<span>No activity yet.</span>)

  return (
    <Card
      key={`collapse-card-${'job_id'}-${'header'}`}
      className='mt-1 mb-1'
    >
      <Card.Header onClick={() => setOpen(!open)} >
        Activity log
      </Card.Header>

      <Card.Body className='pt-0 pb-0'>

      <Collapse in={open}>
        {open && open === true ? (
          <>
          {activityLog.map((activity, idx) => {
            return (
              <div key={`summary-activity-${idx}`} >
                <span>{new Date(activity.createdAt).toLocaleDateString("en-UK", options)}: {activity.message}</span>
              </div>
            );
          })}
          </>
        ) : (<div>No activity yet.</div>)}
      </Collapse>
      </Card.Body>
    </Card>
  );
};
