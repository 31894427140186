export const interview_actions_list = [
  { 
    label: 'Interview is loaded',
    value: 'interview:set_state:interview_loaded' 
  },
  { 
    label: 'Interview is started',
    value: 'interview:set_state:interview_started'
  },
  { 
    label: 'Interview is resumable',
    value: 'interview:set_state:interview_resumable' 
  },
  { 
    label: 'Interview is ended',
    value: 'interview:set_state:interview_ended' 
  },
  { 
    label: 'Interview language British English',
    value: 'interview:set_language:en-gb' 
  },
  { 
    label: 'Interview language American English',
    value: 'interview:set_language:en-us' 
  },
  { 
    label: 'Interview language Swedish',
    value: 'interview:set_language:sv-se' 
  },
  { 
    label: 'Interview language Danish',
    value: 'interview:set_language:da-dk' 
  },
  { 
    label: 'Interview language Norwegian',
    value: 'interview:set_language:no-no' 
  },
  { 
    label: 'Interview language Finnish',
    value: 'interview:set_language:fi-fi' 
  },
  { 
    label: 'Interview language German',
    value: 'interview:set_language:de-de' 
  },
  { 
    label: 'Interview language Spanish (Spain)',
    value: 'interview:set_language:es-es' 
  },
  { 
    label: 'Interview language Italian',
    value: 'interview:set_language:it-it' 
  },
  { 
    label: 'Interview language Spanish (Mexico)',
    value: 'interview:set_language:es-mx' 
  },
  { 
    label: 'Application as \"Not qualified\"', 
    value: 'application:set_state:not_qualified' 
  },
  { 
    label: 'Save basic candidate information',
    value: 'candidate:information:basic_information' 
  },
  { 
    label: 'Send dashboard email',
    value: 'application:send_email:dashboard' 
  },
  {
    label: 'Save answer as variable',
    value: 'interview:variable:save'
  },
  {
    label: 'Reusability check',
    value: 'interview:reusability:check' 
  },
  { 
    label: 'Reusability confirmed',
    value: 'interview:reusability:confirmed' 
  },
  { 
    label: 'Reusability do',
    value: 'interview:reusability:do' 
  },
  { 
    label: 'Reusability Followups',
    value: 'interview:reusability:followups' 
  },
];
