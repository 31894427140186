export const TextType = ({
  qutter_type,
  field
}) => {

  if (field === 'intro') {
    return (<span className='m-1 font-size-10'>Intro: </span>)
  }

  if (field === 'text') {
    if (qutter_type === 'info') return (<span className='m-1 font-size-10'>Message: </span>)
    else return (<span className='m-1 font-size-10'>Question: </span>)
  }

  if (field === 'outro') {
    return (<span className='m-1 font-size-10'>Outro: </span>)
  }

  if (field === 'clarify') {
    return (<span className='m-1 font-size-10'>Clarify: </span>)
  }
};
