export const hubiverse_property_list = [
  { label: 'Do not save to property', value: '' },
  { label: 'Social security number (SSN)', value: 'ssn' },
  { label: 'Candidate first name', value: 'candidate_first_name' },
  { label: 'Candidate last name', value: 'candidate_last_name' },
  { label: 'Candidate full name', value: 'candidate_full_name', description: '' },
  { label: 'Candidate Email', value: 'candidate_email' },
  { label: 'Candidate Phone', value: 'candidate_phone' },
  { label: 'Data Privacy Policy Consent', value: 'data_privacy_policy_agreement' },
  { label: 'Postal code', value: 'postal_code' },
  { label: 'Postal code: Denmark', value: 'postal_code_dk' },
  { label: 'Postal code: Sweden', value: 'postal_code_sv' },
  { label: 'Postal code: Norway', value: 'postal_code_no' },
  { label: 'Postal code: UK', value: 'postal_code_uk' },
  { label: 'Date of birth', value: 'date_of_birth' },
  { label: 'Gender', value: 'gender' },
  { label: 'Start date', value: 'start_date' },
  { label: 'University school', value: 'university_school' },
  { label: 'University program', value: 'university_program' },
  { label: 'Main occupation', value: 'main_occupation' },
];