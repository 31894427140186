import { gql } from '@apollo/client';

export const QUERY_GET_ALL_CLIENTS = gql`
  query {
    GetAllClients {
      id
      org_name
    }
  }
`;

export const EDIT_JOB_CLIENT_ID = gql`
mutation EditJobClient($job_id: String!, $client_id: String!) {
  EditJobClient(job_id: $job_id, client_id: $client_id)
}
`;