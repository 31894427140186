import { nanoid } from '@reduxjs/toolkit';

export const initial_input = {
  id: 'create-input',
  type: 'input',
  language: {
    current: 'sv-se',
    list: [
      'sv-se', 
      'en-gb', 
      'en-us', 
      'es-es', 
      'es-mx', 
      'fr-fr', 
      'da-dk', 
      'de-de', 
      'fi-fi', 
      'no-no',
      'it-it'
    ]
  },
  global: true,
  client_id: false,
  tags:  [],
  roles: [],
  pre_actions: [],
  post_actions: [],
  is_star_question: false,
  is_reusable: false,
  'sv-se': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'sv-se',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  },
  'en-gb': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'en-gb',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  }, 
  'en-us': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'en-us',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  }, 
  'es-es': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'es-es',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  }, 
  'es-mx': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'es-mx',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  }, 
  'fr-fr': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'fr-fr',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  }, 
  'da-dk': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'da-dk',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  },
  'de-de': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'de-de',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  }, 
  'fi-fi': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'fi-fi',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  }, 
  'no-no': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'no-no',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  },
  'it-it': {
    type: 'input',
    intro: [], text: [], outro: [], clarify: [],
    language: 'it-it',
    config: {
      type: 'text',
      min: null,
      max: null,
      help_text: null,
      button_text: null,
      validator: null,
    },
    presentation: {
      block:  '',
      label:  '',
      example_answer: ''
    }
  }
};
