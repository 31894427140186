import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import AppContext from 'context/Context';

import { useMutation, gql } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';

import { setAccessToken } from '../../tokens/tokens';

import { setIsLoggedIn } from '_components/config/ConfigSlice';

const LOGIN = gql`
mutation Login($email: String!, $password: String!) {
  AdminLogin(email: $email, password: $password) {
    accessToken
    user {
      id
      first_name
      last_name
    }
  }
}
`;

const LoginForm = ({ hasLabel, layout }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // On login: Redirect to
  const { search, pathname } = useLocation();

  // Context
  const {
    config: { user, isLoggedIn },
    setConfig
  } = useContext(AppContext);

  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  var [login, { loading }] = useMutation(LOGIN, {
    onCompleted(data) {
      if (loading) console.log('Loading.....');
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    login({
      variables: {
        email: formData.email,
        password: formData.password
      }
    })
      .then(r => {
        if (r && r.data) {
          setAccessToken(r.data.AdminLogin.accessToken);
          dispatch(setIsLoggedIn({
            user: {
              ...r.data.AdminLogin.user,
              userType: 'cs'
            }
          }));
          setConfig('isLoggedIn', true);

          if (pathname && pathname !== '/' && search) 
            navigate(search.split('redirect=')[1]);
          else navigate('/');
          toast.success(`Logged in as ${formData.email}`);
        }
      })
      .catch(err => {
        if(err.networkError?.result?.errors?.length > 0) {
          toast.error(err.networkError.result.errors[0].message);
        } else {
          toast.error('Failed to login: ' + e);
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ToastContainer  />
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          {loading && loading === true ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="false"
              />
              Logging in
            </>
          ) : (
            'Log in'
          )}
        </Button>
      </Form.Group>

    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
