import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';

import CandidateMessages from './CandidateMessages';
import HubertMessages from './HubertMessages';
import ScoreTable from './ScoreTable';
import ScoreSummary from './ScoreSummary';
import ScoringStatusHistoryPopover from './ScoringStatusHistoryPopover';

import { initScorer } from './redux/ScorerSlice';
import { INTERVIEW_TAGS } from './TagEnums';

const interviewTagsTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props.description}
  </Tooltip>
);

const Score = ({ interview }) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  console.log(`<Score /> ${id}`, interview);
  const [conversation, setConversation] = useState(null);
  const [autoUpdateData, setAutoUpdateData] = useState(null);

  const job = interview && interview.application && interview.application.job ? 
    interview.application.job: {};
  const candidate = interview && interview.application && interview.application.candidate ? 
    interview.application.candidate: {};
  const application = interview && interview.application ? { ...interview.application }: {};

  useEffect(() => {
    axios.get(
      `${process.env.REACT_APP_URL}/scoring/v1/manual_pre_score/${id}`
    )
    .then(res => {
      console.log('manual_pre_score: ');
      console.log(res.data);
      setConversation(res.data.conversation);

      if (res.data.scorer.auto_updating) {
        setAutoUpdateData(res.data.scorer.auto_updating);
      }

      dispatch(initScorer({
        max_bonus_points: res.data.scorer.max_bonus_points,
        max_weighted:     res.data.scorer.max_weighted,
        initial_weight:   res.data.scorer.initial_weight,
        weighted_total:   res.data.scorer.weighted_total,
        total:            res.data.scorer.total,
        per_qutter:       res.data.scorer.per_qutter,
        qualified:       res.data.scorer.qualified
      }));
    });
  }, []);

  if (!conversation || !conversation.length) return <div>Loading...</div>;
  console.log('conversation', conversation);

  const status = interview.status;
  console.log('STATUS STATUS', status);
  const auto_updating = interview.auto_updating;
  const aiDetection = interview.ai_detection;

  return (
    <Card className='' style={{ height: '100%' }}>
      <Card.Body 
        className='full-page-minus-navbar' 
        // style={{ maxHeight: '100vh' }}
      >

      <Container>
        <Row
          style={{overflowY: 'auto', maxHeight: '15vh' }}
        >
          {status && status.updated_at ? (
            <Row className='text-center'>
              <Col>Last update at: {new Date(status.updated_at).toISOString()}</Col>
            </Row>
          ):(<></>)}

          <div className='text-center'>
            {status && status.name ? (
              <ScoringStatusHistoryPopover
                status={status.name}
                history={status.history}
              />
            ):(<></>)}

            {auto_updating && auto_updating.tags && auto_updating.tags.length ? (
              <>
                {auto_updating.tags.map((tag) => {
                  return (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 100, hide: 100 }}
                      overlay={interviewTagsTooltip({ description: INTERVIEW_TAGS[tag].description })}
                    >
                      {tag && tag === 'PASSED' ? (
                        <Badge bg="success" className="me-1">{tag.split('_').join(' ')}</Badge>
                      ):(
                        <Badge bg="danger" className="me-1">{tag.split('_').join(' ')}</Badge>
                      )}
                    </OverlayTrigger>
                  )
                })}
              </>
            ):(<></>)}

            {aiDetection ? (
              <OverlayTrigger
                placement="right"
                delay={0}
                overlay={interviewTagsTooltip({ description: `Score: ${aiDetection.score}` })}
              >
                {aiDetection.is_ai ? (
                  <Badge bg="warning" className="me-1">
                    {aiDetection.tag && aiDetection.tag.split('_').join(' ')}
                  </Badge>
                ):(
                  <Badge bg="success" className="me-1">
                    {aiDetection.tag && aiDetection.tag.split('_').join(' ')}
                  </Badge>
                )}
                {/* <Badge bg="warning" className="me-1">{aiDetection.tag}</Badge> */}
              </OverlayTrigger>
            ):(null)}
          </div>
        </Row>

        <Row
          style={{overflowY: 'auto', maxHeight: '85vh' }}
        >
          <Col
            id='message-container' className='pt-2 pb-2' 
            style={{overflowY: 'auto', height: '100%', maxHeight: 'inherit' }}
          >
          {/* Messages */}
          {conversation && conversation.length ? (
            conversation.map((detail, idx) => {
              if (detail.qutter) {
                console.log('detail.qutter.id', detail.qutter.id)
                return (
                <Row id={detail.qutter.id} key={idx}>
                  <Col>
                  {detail.conversation.map((answer, answer_idx) => {
                    return (
                      <div key={answer_idx}>
                        {
                          (() => {
                            if (answer.sender === 'candidate') {
                              return (
                                <CandidateMessages
                                  answer={answer}
                                  answer_idx={answer_idx} 
                                />
                              );
                            } else 
                            if (answer.sender === 'hubert') {
                              return (
                                <HubertMessages
                                  answer={answer}
                                  answer_idx={answer_idx}
                                />
                              );
                            } else
                            {
                              console.log('other sender: Wrong');
                            }
                          })()
                        }
                      </div>
                    )
                  })}
                  </Col>
                </Row>
                )
              }
            })
          ):(
            <div>Loading...</div>
          )}
          </Col>
          <Col className='pt-2 pb-2' style={{overflowY: 'auto', height: '100%', maxHeight: 'inherit' }}>
            <ScoreTable conversation={conversation} />
          </Col>
        </Row>
      </Container>
      </Card.Body>
    </Card>
  );
};

export default Score;
