import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarHalf } from '@fortawesome/free-solid-svg-icons';

export const basePointIconWithText = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#5243AA' }} size={'lg'} icon={faStarHalf} />
    <span className="m-2">Point</span>
  </span>;

export const basePointIcon = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#5243AA' }} size={'lg'} icon={faStarHalf} />
    <span className="m-2">Point</span>
  </span>;