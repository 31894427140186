import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

export const ScoringCategoryListItem = ({
  template_id,
  qutter_id,
  mandatory
}) => {
  const template = useSelector(state => state.Template[template_id]);
  const languages = template.language_settings.list;
  const library = useSelector(state => state.Library);

  return (
  <li>
    {languages.map((language, idx) => {
      if (library[qutter_id] && library[qutter_id][language]) {
        return (
          <div
            key={`scoring-category-listitem-${qutter_id}-${language}-${idx}`}
            className='d-flex'
          >
          <div
            className='m-1'
            dangerouslySetInnerHTML={{ __html: library[qutter_id][language].text.join(' ') }}
          />
          {mandatory ? (
            <FontAwesomeIcon
              className='red m-auto'
              size={'sm'} 
              icon={faAsterisk}
            />
          ): null}
          </div>
        );
      } else return null;
    })}
  </li>
  )
}
