import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { InviteCandidateRequest } from '../api/api';

export const Invite = ({
  job_id,
  email,
  first_name,
  last_name,
  set_error,
  set_success
}) => {

  const invite = () => {
    if (!job_id) { set_error('Missing job id.'); return; }
    if (!email) { set_error('Missing email.'); return; }
    if (!first_name) { set_error('Missing first name.'); return; }
    if (!last_name) { set_error('Missing last name.'); return; }
    InviteCandidateRequest({
      job_id,
      email,
      first_name,
      last_name,
    })
    .then((res) => {
      set_success(res);
    }).catch((error) => {
      set_error(error);
    });
  };

  return (
    <Button onClick={invite} >
      Invite
    </Button>
  );
};
