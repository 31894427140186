import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { toast } from 'react-toastify';
import { Button, Card, Collapse, Form, Container, Row, Col } from 'react-bootstrap';

import { EDIT_JOB_APPLY_SETTINGS } from '../api/gql';

const ApplyPageSettings = ({
  job_id,
  apply_settings
}) => {
  const [open, set_open] = useState(false);
  const [settings, set_apply_settings] = useState(apply_settings);

  const [job_apply_settings_mutation, {data, loading, error}] = useMutation(EDIT_JOB_APPLY_SETTINGS);

  return (
    <Card
      key={`collapse-card-${'job_id'}-${'header'}`}
      className='mt-1 mb-1'
    >
      <Card.Header onClick={() => set_open(!open)} >
        Settings
      </Card.Header>

      <Card.Body className='pt-0 pb-0'>

        <Collapse in={open}>
          {open && open === true ? (
            <Container>

              <Row>
                <Col>
                  First name
                </Col>
                <Col>
                  <Form.Check
                    defaultChecked
                    className='float-end'
                    type="switch"
                    id="first-name-switch"
                    label="Mandatory"
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  Last name
                </Col>
                <Col>
                  <Form.Check
                    defaultChecked
                    className='float-end'
                    type="switch"
                    id="last-name-switch"
                    label="Mandatory"
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  Email
                </Col>
                <Col>
                  <Form.Check
                    defaultChecked
                    className='float-end'
                    type="switch"
                    id="email-name-switch"
                    label="Mandatory"
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  Telephone
                </Col>
                <Col>
                  <Form.Check
                    defaultChecked
                    className='float-end'
                    type="switch"
                    id="telephone-name-switch"
                    label="Mandatory"
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  Logo link
                </Col>
                <Col>
                  <Form.Control 
                    type="text"
                    placeholder='"Bullens företag"'
                    defaultValue={settings.logo}
                    onChange={(e) => { 
                      set_apply_settings({ 
                        ...settings,
                        logo: e.target.value
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  CV 
                  <span className='font-size-10 d-block'>
                    NOTE: only available in the legacy version
                  </span>
                </Col>
                <Col>
                  <Form.Check
                    className='float-end'
                    type="switch"
                    id="cv-switch"
                    label="Check this switch"
                    defaultChecked={settings.cv}
                    disabled={false}
                    onChange={(e) => { 
                      set_apply_settings({ 
                        ...settings,
                        cv: e.target.checked
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  CL
                  <span className='font-size-10 d-block'>
                    NOTE: only available in the legacy version
                  </span>
                </Col>
                <Col>
                  <Form.Check
                    className='float-end'
                    type="switch"
                    id="cl-switch"
                    label="Check this switch"
                    defaultChecked={settings.cl}
                    disabled={false}
                    onChange={(e) => { 
                      set_apply_settings({ 
                        ...settings,
                        cl: e.target.checked
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  Country
                </Col>
                <Col>
                  <Form.Check
                    className='float-end'
                    type="switch"
                    id="country-switch"
                    label="Check this switch"
                    defaultChecked={settings.country}
                    disabled={false}
                    onChange={(e) => { 
                      set_apply_settings({ 
                        ...settings,
                        country: e.target.checked
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  Linkedin
                </Col>
                <Col>
                  <Form.Check
                    className='float-end'
                    type="switch"
                    id="linkedin-switch"
                    label="Check this switch"
                    defaultChecked={settings.linkedin}
                    disabled={false}
                    onChange={(e) => { 
                      set_apply_settings({ 
                        ...settings,
                        linkedin: e.target.checked
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  City
                </Col>
                <Col>
                  <Form.Check
                    className='float-end'
                    type="switch"
                    id="city-switch"
                    label="Check this switch"
                    defaultChecked={settings.city}
                    disabled={false}
                    onChange={(e) => { 
                      set_apply_settings({ 
                        ...settings,
                        city: e.target.checked
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  Postal code
                </Col>
                <Col>
                  <Form.Check
                    className='float-end'
                    type="switch"
                    id="postal-switch"
                    label="Check this switch"
                    defaultChecked={settings.postal_code}
                    disabled={false}
                    onChange={(e) => {
                      set_apply_settings({
                        ...settings,
                        postal_code: e.target.checked
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  Street address
                </Col>
                <Col>
                  <Form.Check 
                    className='float-end'
                    type="switch"
                    id="street-switch"
                    label="Check this switch"
                    defaultChecked={settings.street_address}
                    disabled={false}
                    onChange={(e) => { 
                      set_apply_settings({ 
                        ...settings,
                        street_address: e.target.checked
                      });
                    }}
                  />
                </Col>
              </Row>

              <div className='float-end'>
                  <Button
                    variant="falcon-primary"
                    size="sm"
                    onClick={() => {
                      console.log(settings)
                      job_apply_settings_mutation({
                        variables: {
                          job_id: job_id,
                          ...settings 
                        }
                      }).then((result) => {
                        toast.success(`Apply page settings updated.`);
                      }).catch((error) => {
                        toast.error(`Failed to edit job. Error: ${error}`);
                      });
                    }}
                  >Update</Button>
              </div>

            </Container>
          ) : (
            <></>
          )}
        </Collapse>
      </Card.Body>
    </Card>
  );
};

export default ApplyPageSettings;