import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { WarningsOutline } from './WarningsOutline';
import { DisqualificationsOutline } from './DisqualificationsOutline';
import { BonuspointsOutline } from './BonuspointsOutline';
import { WeightsOutline } from './WeightsOutline';
import { ScoringCategoriesOutline } from './scoring_category/ScoringCategoriesOutline';
import { MainQuestionsCount } from './MainQuestionsCount';

import {
  update_bonuspoints,
  update_warnings,
  update_disqualifications
} from '..';

export const TemplateOutline = ({
  template_id
}) => {
  const dispatch = useDispatch();
  const library = useSelector(state => state.Library);
  const Evaluation = useSelector(state => state.Evaluation);
  const template = useSelector(state => state.Template);
  const language = template.language.current;
  const outline = useSelector(state => state.TemplateOutline[language]);

  const [loading, set_loading] = useState(true);
  const [warnings, set_warnings] = useState(null);
  const [bonuses, set_bonuses] = useState(null);
  const [disqualifications, set_disqualifications] = useState(null);
  const [infos, set_infos] = useState(null);
  const [points, set_points] = useState(null);
  const [weighted, set_weighted] = useState(null);
  const [scoring_categories, set_scoring_categories] = useState(null);

  let list = [];
  if (outline) list = outline.qutter_list;

  useEffect(() => {
    set_loading(true);

    async function compute_outline () {
      set_warnings(null);
      set_bonuses(null);
      set_disqualifications(null);
      set_weighted(null);
      set_points(null);
      set_infos(null);

      let warnings = {
        ids: new Set()
      };

      let bonuses = {
        ids: new Set(),
        total: 0,
        map: {}
      };

      let disqualifications = {
        ids: new Set()
      };

      for (const qutter_id in Evaluation) {
        if (qutter_id === 'scoring_categories') continue;

        let evaluation = Evaluation[qutter_id];
        const qutter_config = library[qutter_id];

        if (!qutter_config) {
          continue;
        }

        if (isEmpty(evaluation)) {
          continue;
        }

        const qutter = qutter_config[language];
        if (!qutter) {
          continue;
        }

        const text = qutter.text.join(' ');
        evaluation = evaluation[language];
        let bonuspoints = [];

        for (const key in evaluation) {

          if (!evaluation[key]) {
            continue;
          }

          if (!key.includes('evaluation')) continue;
          if (Array.isArray(evaluation[key])) {
            const evaluation_list = evaluation[key];

            for (let i = 0; i < evaluation_list.length; i++) {
              const evaluation_element = evaluation[key][i];

              if (evaluation_element.name === 'bonus') {
                bonuspoints.push(evaluation_element.bonus_points);
                bonuses.ids.add(qutter_id);
              }
              else 
              if (evaluation_element.name === 'warning') {
                warnings.ids.add(qutter_id);
              } else 
              if (evaluation_element.name === 'disqualified') {
                disqualifications.ids.add(qutter_id);
              }
            }

          } else {

            if (evaluation[key].name === 'warning') {
              warnings.ids.add(qutter_id);
            } else 

            if (evaluation[key].name === 'bonus') {
              bonuspoints.push(evaluation[key].bonus_points);
              bonuses.ids.add(qutter_id);
            } else 

            if (evaluation[key].name === 'disqualified') {
              disqualifications.ids.add(qutter_id);
            } else 

            if (evaluation[key].name === 'info') {}
            else

            if (evaluation[key].name === 'point') {}
          }
        }

        if (bonuspoints.length > 0) {
          if (qutter_config.type === 'single-select') {
            const add = await Math.max(...bonuspoints);
            bonuses.total += add;
            bonuses.map[qutter_id] = { 
              id: qutter_id,
              type: qutter.type,
              text: text,
              bonuspoints: add
            };
          } else 

          if (qutter_config.type === 'multiple-select') {
            const add = await bonuspoints.reduce((partial, a) => partial + a, 0);
            bonuses.total += add;
            bonuses.map[qutter_id] = { 
              id: qutter_id,
              type: qutter.type,
              text: text,
              bonuspoints: add
            };
          } else 

          if (qutter_config.type === 'open') {
            const add = await bonuspoints.reduce((partial, a) => partial + a, 0);
            bonuses.total += add;
            bonuses.map[qutter_id] = { 
              id: qutter_id,
              type: qutter.type,
              text: text,
              bonuspoints: add
            };
          } else 

          if (qutter_config.type === 'date-picker') {
            const add = await Math.max(...bonuspoints);
            bonuses.total += add;
            bonuses.map[qutter_id] = { 
              id: qutter_id,
              type: qutter.type,
              text: text,
              bonuspoints: add
            };
          }

          if (qutter_config.type === 'form') {
            const add = await Math.max(...bonuspoints);
            bonuses.total += add;
            bonuses.map[qutter_id] = {
              id: qutter_id,
              type: qutter.type,
              text: text,
              bonuspoints: add
            };
          }
        }

      }

      bonuses.ids = [...bonuses.ids];
      warnings.ids = [...warnings.ids];
      disqualifications.ids = [...disqualifications.ids];
      return {
        warnings: warnings,
        bonuses: bonuses,
        disqualifications: disqualifications
      };
    }

    async function compute () {
      const res = await compute_outline();

      // Update Warnings
      set_warnings(res.warnings);
      dispatch(update_warnings({
        language,
        warnings: res.warnings
      }));

      // Update Bonuspoints
      set_bonuses(res.bonuses);
      dispatch(update_bonuspoints({
        language,
        bonuspoints: res.bonuses
      }));

      // Update Disqualifications
      set_disqualifications(res.disqualifications);
      dispatch(update_disqualifications({ 
        language,
        disqualifications: res.disqualifications
      }));

      set_weighted(res.weighted);
      set_scoring_categories(Evaluation.scoring_categories);
      set_loading(false);
    }

    if (!outline || list === null || list === undefined) return;
    compute();

  }, [Evaluation, library, language]);

  if (loading) return (<span>Computing outline...</span>);

  return (
  <div>
    <div className='d-flex font-size-10'>
      <div className='w-75 text-start'>
        Total number of questions:
      </div>
      <div className='w-25 text-end'>
        {list.length}
      </div>
    </div>

    <MainQuestionsCount />

    <WarningsOutline 
      warnings={warnings}
      loading={loading}
    />
    <DisqualificationsOutline 
      disqualifications={disqualifications}
      loading={loading}
    />
    <BonuspointsOutline
      bonuses={bonuses}
      loading={loading}
    />

    {/* 
    <WeightsOutline
      weighted={weighted}
      loading={loading}
    /> 
    */}

    <ScoringCategoriesOutline
      template_id={template_id}
      scoring_categories={scoring_categories}
      loading={loading}
    />
  </div>
  );
};
