import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';

import { QutterHeader } from 'features/Template';
// import { AddQutterToBlock } from 'features/TemplateBlock';
// import { PreviewQuestionTexts } from 'features/PreviewQuestionTexts';
import { TemplateTexts } from 'features/PreviewQuestionTexts';
import { PreviewForms } from '..';
import { PreviewRules } from 'features/QutterRules';

export const PreviewFormInTemplate = ({
  template_id,
  block_id,
  block_name,
  qutter_id,
  qutter_idx,
  order,
  provided,
  is_followup,
  parent_id,
}) => {
  // console.log('<PreviewInfo />', qutter_id);
  const [previewIsOpen, setPreviewIsOpen] = useState(false);
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const language = qutterConfig.language.current;
  const qutter = qutterConfig[qutterConfig.language.current];
  const onFullPreviewClick = e => { setPreviewIsOpen(!previewIsOpen); };

  let params = useSelector(state => state.Parameters[qutter_id]);

  // Rules
  const [rules, set_rules] = useState([]);
  const custom_rules_key = `rules`;

  useEffect(() => {
    // Rules
    if (params && params.hasOwnProperty(custom_rules_key)) {
      set_rules(params[custom_rules_key]);
    } else if (qutterConfig.rules) {
      set_rules(qutterConfig.rules);
    }
  }, [qutterConfig, params, language]);

  return (
  <div
    key={`view-info-qutter-${qutter_id}-${qutter_idx}`} 
    className={`mb-2 ${qutter && qutter.is_follow_up ? 'p-0': ''}`}
  >

    <QutterHeader
      template_id={template_id}
      block_id={block_id}
      qutter_id={qutter_id}
      order={order}
      previewIsOpen={previewIsOpen}
      onFullPreviewClick={onFullPreviewClick}
      provided={provided}
      is_followup={is_followup}
      parent_id={parent_id}

      rules={rules}
    />

    {qutterConfig && qutter && (
      <div className={`${previewIsOpen ? '': 'd-none'}`}>
        {/* <PreviewQuestionTexts
          template_id={template_id}
          qutter_id={qutter_id}
        /> */}

        {qutterConfig && qutter ? (
          <div>

            {/* Intro (field: intro) */}
            <TemplateTexts
              template_id={template_id}
              qutter_id={qutter_id}
              field={'intro'}
            />

            {/* Question (field: text) */}
            <TemplateTexts
              template_id={template_id}
              qutter_id={qutter_id}
              field={'text'}
            />

            {/* Outro (field: outro) */}
            <TemplateTexts
              template_id={template_id}
              qutter_id={qutter_id}
              field={'outro'}
            />
          </div>
        ) : null}

        <PreviewForms template_id={template_id} qutter_id={qutter_id} />
      </div>
    )}
    {/* 
    {template_id && is_followup !== true && (
      <AddQutterToBlock
        template_id={template_id}
        block_id={block_id}
        qutter_id={qutter_id}
        qutter_idx={qutter_idx}
      />
    )} */}

    <PreviewRules
      template_id={template_id}
      qutter_id={qutter_id}
      order={order}
      rules={rules}
    />

  </div>
  );
}
