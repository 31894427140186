import React from 'react';
import { useSelector } from 'react-redux';

import { ScoringCategoryListItem } from './ScoringCategoryListItem';

export const ScoringCategoryList = ({
  template_id,
  category_id,
  category
}) => {
  const required = category.required;
  const optional = category.optional;

  return (
  <ul>
    {required && required.length > 0 ? (
      required.map((elem, idx1) => {
        return (
          <ScoringCategoryListItem
            key={`scoring-cat-${elem}-${idx1}`}
            template_id={template_id}
            qutter_id={elem}
            mandatory={true}
          />
        )
      })
    ):null}

    {optional && optional.length > 0 ? (
      optional.map((elem, idx1) => {
        return (
          <ScoringCategoryListItem
            key={`scoring-cat-${elem}-${idx1}`}
            template_id={template_id}
            qutter_id={elem}
            mandatory={false}
          />
        )
      })
    ):null}
  </ul>
  )
}