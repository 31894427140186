import React from 'react';
import {
  Container,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';

function compare(a, b) {
  if ( (a && a.date) < (b && b.date) ) return -1;
  if ( (a && a.date) > (b && b.date) ) return 1;
  return 0;
}

const PastesAndCopyPopOver = (events) => {
  if (!events) return (null);

  return (
  <Popover 
    id="popover-basic"
    className={`qutter-followup-popover`}
  >
    <Popover.Header>
      <span>Pastes and copies</span>
    </Popover.Header>

    <Popover.Body
      as={Container}
      className='w-100'
      style={{ overflowY: 'auto' }}
    >
      {events.sort(compare).map((event, idx) => {
        if (event && event.date) {
          let date = new Date(event.date);
          let ds = date.toLocaleDateString()
          let ts = date.toLocaleTimeString()
          if (event.text) {
            // Paste
            return (
              <div
                key={`pastes-copies-${date.getTime()}`}
              >
  
                <div>
                {ds} - {ts}: PASTE
                </div>
                <div>
                  <i>{event.text}</i>
                </div>
              </div>
            )
          } else {
            // Window focus change
            return (
              <div
                key={`pastes-copies-${date.getTime()}`}
              >
                {ds} - {ts}: {event.left ? 'Left tab': 'Returned to tab'}
              </div>
            )
          }
        } else {
          return null
        }
      })}
    </Popover.Body>
  </Popover>
  )
}

export const PastesAndCopyOverlay = ({
  message
 }) => {
  let events = []

  for (let i = 0; i < message.units.length; i++) {
    const unit = message.units[i];
    const pastes = unit.pastes;
    const tab_switches = unit.tab_switches;
    if (pastes && pastes.n > 0) {
      let ev = pastes.texts.map(t => { return { text: t.text, date: t.timestamp }})
      events = [...events, ...ev]
    }
    if (tab_switches && tab_switches.length) {
      events = [...events, ...tab_switches]
    }
  }

  if (events.length === 0) return (null);

  return (
    <OverlayTrigger
      trigger={['click']}
      placement='right'
      overlay={
        PastesAndCopyPopOver(events)
      }
    >
      <span 
        className='font-size-10 hover-blue me-2'
      >
        <SoftBadge bg='primary' className='me-2'>Pastes and tab switch events: {events.length}</SoftBadge>
      </span>
    </OverlayTrigger>
  );
};
