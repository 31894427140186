import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Container,
  Table
} from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Background } from '../../../common/components/Background/Background';
import bgImage from 'assets/img/icons/spot-illustrations/corner-5.png';
import connectCircle from 'assets/img/icons/connect-circle.png';
import { ToastContainer, toast } from 'react-toastify';

const InterviewAnalyticsCard = () => {
  // const templateList = [];
  const [showModal, setShowModal] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState('');
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [proceedBtnDisable, setProceedBtnDisable] = useState(true);
  const [exportBtnDisable, setExportBtnDisable] = useState(true);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [jobsForAnalytics, setJobsForAnalytics] = useState([]);
  const [analyticsDataForTable, setAnalyticsDataForTable] = useState([]);
  const [analyticsData, setAnalyticsData] = useState({});
  const [analyticsSummaryData, setAnalyticsSummaryData] = useState([]);

  const getOldTemplate = () => {
    try {
      let url = `${process.env.REACT_APP_URL}/templates/v1/get-all-old-templates`;
      axios.get(url).then(async res => {
        if (res.status === 200) {
          res.data.map(item => {
            const obj = {
              label: item.name,
              value: item._id
            };
            setTemplateList(oldArray => [...oldArray, obj]);
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast.error('Failed to get data: ' + err.message);
    }
  };
  const getTemplateRelatedJobs = templateId => {
    try {
      let url = `${process.env.REACT_APP_URL}/jobs/v1/get-jobs-by-interview-template/${templateId}`;
      axios.get(url).then(async res => {
        if (res.status === 200) {
          console.log(res);
          res.data.map(item => {
            const obj = {
              label: item.title,
              value: item._id
            };
            setJobList(oldArray => [...oldArray, obj]);
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast.error('Failed to get data: ' + err.message);
    }
  };
  const getAnalyticsData = () => {
    try {
      setLoadingAnalytics(true);
      setProceedBtnDisable(true);
      let url = `${process.env.REACT_APP_URL}/dashboard-analytics/v1/non-conversion-rate/${selectedOptions}`;
      const data = { jobIds: selectedJobs };
      axios.post(url, data).then(async res => {
        if (res.status === 200) {
          const tempJobs = res.data.interviewAnalytics.map(item => {
            return { job_id: item.job_id, name: item.name };
          });
          const summaryAnalytics = res.data.analyticsSummary.map(
            ({ order, ...rest }) => ({ order: '', ...rest })
          );
          setJobsForAnalytics(tempJobs);
          setLoadingAnalytics(false);
          setProceedBtnDisable(false);
          setShowTable(true);
          setAnalyticsDataForTable(res.data.exportAnalytics);
          setAnalyticsData(res);
          setAnalyticsSummaryData(summaryAnalytics);
          setExportBtnDisable(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingAnalytics(false);
      setProceedBtnDisable(false);
      toast.error('Failed to get data: ' + err.message);
    }
  };

  const handleTemplateSelectChange = selected => {
    setJobList([]);
    setSelectedOptions(selected.value);
    getTemplateRelatedJobs(selected.value);
  };
  const handleJobSelectChange = selected => {
    if (selected.length <= 5 && selected.length >= 1) {
      const jobIds = selected.map(item => {
        return item.value;
      });
      setSelectedJobs(jobIds);
      setProceedBtnDisable(false);
    } else {
      setProceedBtnDisable(true);
      setExportBtnDisable(true);
    }
  };

  const exportToExcel = async () => {
    try {
      setExportBtnDisable(true);
      setLoadingExport(true);
      const analytics = analyticsData.data.exportAnalytics;
      const summaryAnalytics = analyticsData.data.analyticsSummary.map(
        ({ order, ...rest }) => ({ order: '', ...rest })
      );

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const fileName = `Hubert Interview Analytics`;

      const ws = XLSX.utils.json_to_sheet([...analytics, {}]);
      XLSX.utils.sheet_add_json(ws, summaryAnalytics, {
        origin: -1,
        skipHeader: true
      });

      const wscols = [{ wpx: 150 }, { wpx: 150 }, { wpx: 150 }];
      ws['!cols'] = wscols;

      const wb = {
        Sheets: {
          Analytics: ws
        },
        SheetNames: ['Analytics']
      };

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      setExportBtnDisable(false);
      setLoadingExport(false);
    } catch (error) {
      console.log(error);
      setExportBtnDisable(false);
      toast.error('Failed to export data: ' + err.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <Card>
        <Background
          image={bgImage}
          className="bg-card"
          style={{
            borderTopRightRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem'
          }}
        />
        <Card.Body className="position-relative">
          <Row className="g-2 align-items-sm-center">
            <Col xs="auto">
              <img src={connectCircle} alt="connectCircle" height={55} />
            </Col>
            <Col>
              <Row className="align-items-center">
                <Col className="pe-xl-8">
                  <h5 className="fs-0 mb-3 mb-sm-0 text-primary">
                    Click here to view analytics related to Hubert's interview.
                  </h5>
                </Col>
                <Col xs="auto" className="ms-auto">
                  <Button
                    onClick={() => {
                      setShowModal(true);
                      getOldTemplate();
                    }}
                    variant="falcon-primary"
                  >
                    View Analytics
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setTemplateList([]);
          setJobList([]);
          setSelectedOptions('');
          setSelectedJobs([]);
          setProceedBtnDisable(true);
          setShowTable(false);
          setExportBtnDisable(true);
        }}
        dialogClassName="create-question-modal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Hubert's interview analytics</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="">
          <Row className="bg-100 m-1 p-1">
            <Col className="m-auto text-center">
              <span className="m-auto">Old templates </span>
            </Col>
            <Col xs={8}>
              <Select
                options={templateList}
                isMulti={false}
                onChange={handleTemplateSelectChange}
              />
            </Col>
          </Row>
          <Row className="bg-100 m-1 p-1">
            <Col className="m-auto text-center">
              <span className="m-auto">Select jobs base on template </span>
            </Col>
            <Col xs={8}>
              <Select
                options={jobList}
                isMulti={true}
                onChange={handleJobSelectChange}
              />
            </Col>
          </Row>
          <Row className="bg-100 m-1 p-1">
            <Col className="m-auto text-center"></Col>
            <Col xs={8}>
              <Button
                disabled={proceedBtnDisable}
                onClick={() => {
                  getAnalyticsData();
                }}
                variant="falcon-primary"
              >
                {loadingAnalytics ? 'Processing' : 'Proceed'}
              </Button>
            </Col>
          </Row>
          <Row>
            {showTable && (
              <>
                <Table striped bordered hover size="sm" responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>qutter</th>
                      {jobsForAnalytics.map(item => {
                        return <th>{item.name}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {analyticsDataForTable.map((item, index) => {
                      const length = Object.keys(item).length;
                      let tableData = [];
                      for (let index = 0; index < length; index++) {
                        tableData.push(<td>{Object.values(item)[index]}</td>);
                      }
                      return <tr key={index}>{tableData}</tr>;
                    })}
                  </tbody>
                </Table>

                <Table striped bordered hover size="sm" responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th></th>
                      {jobsForAnalytics.map(item => {
                        return <th>{item.name}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {analyticsSummaryData.map((item, index) => {
                      const length = Object.keys(item).length;
                      let tableData = [];
                      for (let index = 0; index < length; index++) {
                        tableData.push(<td>{Object.values(item)[index]}</td>);
                      }
                      return <tr key={index}>{tableData}</tr>;
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Container>
            <Row>
              <Col xs={8}>
                <Button
                  disabled={exportBtnDisable}
                  onClick={() => {
                    exportToExcel();
                  }}
                  variant="falcon-primary"
                >
                  {loadingExport ? 'Processing' : 'Export'}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InterviewAnalyticsCard;
