export const lookup = async (obj, path) => {
  const args = path.split('.');
  return await args.reduce((obj, level) => obj && obj[level], obj);
};

export const findOptionValue = (labels, value) => {
  let val = labels.find(label => label.value === value);
  if (!val) return null;
  return val;
};

export const findOptionValues = (labels, values) => {
  // Return example: [values[2], values[3]]
  let ret = [];
  values.map((value, idx) => {
    labels.map((label, idx) => {
      if (label.value === value) {
        ret.push(label)
      };
    });
  });
  return ret;
};

export const PrettyPrintJson = ({data}) => {
  // (destructured) data could be a prop for example
  return (<div><pre>{JSON.stringify(data, null, 2) }</pre></div>);
}

export const formatDate = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return `${new Date(date).toLocaleDateString('en-gb', { weekday:"long", year:"numeric", month:"short", day:"numeric"})} ${strTime}`
}

export const StripHTML = (obj) => {
  if (typeof obj === 'string')
    return obj.replace(/<[^>]*>?/gm, '').replace('&nbsp;', '')
  else if (Array.isArray(obj))
    return obj.join(' ').replace(/<[^>]*>?/gm, '').replace('&nbsp;', '')
  return obj.replace(/<[^>]*>?/gm, '').replace('&nbsp;', '')
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  // console.log('[reorder] result 1: ', result);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  // console.log('[reorder] result 2: ', result);
  return result;
};

export function short_text(text) {
  return text.length < 120 ? text: text.substring(0, 120) + '...';
}

export function makeid(length) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  return result;
}

export const employmentTypes = {
  ew: 'Extra work',
  pt: 'Part time',
  ft: 'Full time'
};

export function isObject(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Object;
}

export function isEmptyObject(obj) {
  if (isObject(obj) && Object.keys(obj).length === 0) return true;
  return false;
}

export function isArray(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Array;
}

export function isBoolean(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Boolean;
}

export function isFunction(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Function;
}

export function isNumber(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Number;
}

export function isString(obj) {
    return obj !== undefined && obj !== null && obj.constructor === String;
}

export function isInstanced(obj) {
  if(obj === undefined || obj === null) { return false; }

  if(isArray(obj)) { return false; }
  if(isBoolean(obj)) { return false; }
  if(isFunction(obj)) { return false; }
  if(isNumber(obj)) { return false; }
  if(isObject(obj)) { return false; }
  if(isString(obj)) { return false; }

  return true;
}