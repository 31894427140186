import React from 'react';
import { Modal } from 'react-bootstrap';

import { InviteCandidateForm } from './InviteCandidateForm';
import InvitePeople from 'components/pages/miscellaneous/invite-people/InvitePeople';
import InviteToBoard from 'components/app/kanban/InviteToBoard';
export const InviteCandidateModal = ({
  job_id,
  job_title,
  showModal,
  setShowModal
}) => {

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="small"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className='font-size-10 d-block'>Invite Candidate to</span>
          <em className='' style={{ fontSize: 12 }}>{job_title}</em>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <InviteCandidateForm job_id={job_id} />
      </Modal.Body>

    </Modal>
  );
};
