import { nanoid } from '@reduxjs/toolkit';

export function AddForm (state, action) {
  const { qutter_id } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  const default_form = {
    id:           nanoid(),
    type:         'text',
    label:        null,
    help_text:    null,
    placeholder:  null,
    is_mandatory: false,
    min:          0,
    max:          100,
    step:         1,
    rows:     [],
    columns:  [],
    validator:    null,
    hubiverse_property: null,
    candidate_property: null,
  };
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].forms.push(default_form);
  }
};

export function InitForm (state, action) {
  const { qutter_id, form_idx, form } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].forms[form_idx] = form;
  }
};

export function RemoveFormByIndex (state, action) {
  const { qutter_id, form_idx } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].forms.splice(form_idx, 1);
  }
};

export function UpdateFormByIndex (state, action) {
  const { qutter_id, form_idx, form_config } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].forms[form_idx] = form_config;
  }
};

export function UpdateFormQutterHelpText (state, action) {
  const { qutter_id, help_text } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].help_text = help_text;
  }
};

export function UpdateFormQutterSendText (state, action) {
  const { qutter_id, send_text } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].send_text = send_text;
  }
};

///// Form Matrix Rows
export function UpdateFormMatrixRowAtIndex (state, action) {
  const { qutter_id, form_idx, row_idx, value } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].forms[form_idx].rows[row_idx] = value;
  }
};

export function AddFormMatrixRowAtIndex (state, action) {
  const { qutter_id, form_idx, row_idx } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].forms[form_idx].rows.splice(
      (row_idx + 1), 
      0,
      { label: `Row ${row_idx + 2}`, id: nanoid(), value: null }
    );
  }
};

export function RemoveFormMatrixRowAtIndex (state, action) {
  const { qutter_id, form_idx, row_idx } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].forms[form_idx].rows.splice(row_idx, 1);
  }
};

///// Form Matrix Columns
export function UpdateFormMatrixColumnAtIndex (state, action) {
  const { qutter_id, form_idx, column_idx, value } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].forms[form_idx].columns[column_idx] = value;
  }
};

export function AddFormMatrixColumnAtIndex (state, action) {
  const { qutter_id, form_idx, column_idx } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].forms[form_idx].columns.splice(
      (column_idx + 1), 
      0, 
      { label: `Column ${column_idx + 2}`, id: nanoid(), value: null }
    );
  }
};

export function RemoveFormMatrixColumnAtIndex (state, action) {
  const { qutter_id, form_idx, column_idx } = action.payload;
  const qutter_config = state[qutter_id];
  const language = qutter_config.language;
  if (qutter_config && language.current && qutter_config[language.current]) {
    qutter_config[language.current].forms[form_idx].columns.splice(column_idx, 1);
  }
};
