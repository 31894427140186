import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';

import { customRuleUpdate } from 'features/Parameters';
import { ruleUpdate } from 'features/Qutters';

export const RuleName = ({
  template_id,
  qutter_id,
  rule,
  rule_idx,
  is_default
}) => {
  const dispatch = useDispatch();
  const [rule_name, set_rule_name] = useState(rule.name);

  if (template_id) {
    if (is_default) {
      return (<div>{rule_name}</div>)
    } else {
      return (
        <Form.Group className="mb-1">
          <Form.Control
            type="text"
            placeholder={`Rule name - (${rule_idx})`}
            defaultValue={rule.name}
            onChange={(e) => {
              set_rule_name(e.target.value)
            }}
            onBlur={(e) => {
              let r = Object.assign({}, rule);
              r.name = e.target.value;
              if (template_id) {
                dispatch(customRuleUpdate({ qutter_id, rule_idx, rule: r }))
              } else {
                dispatch(ruleUpdate({ qutter_id, rule_idx, rule: r }))
              }
            }}
          />
          <Form.Text className="text-muted">
            Name your rule to more easily identify it elsewhere.
          </Form.Text>
        </Form.Group>
      );
    }
  } else {
    return (
      <Form.Group className="mb-1">
        <Form.Control
          type="text"
          placeholder={`Rule name - (${rule_idx})`}
          defaultValue={rule.name}
          onChange={(e) => {
            set_rule_name(e.target.value)
          }}
          onBlur={(e) => {
            let r = Object.assign({}, rule);
            r.name = e.target.value;
            if (template_id) {
              dispatch(customRuleUpdate({ qutter_id, rule_idx, rule: r }))
            } else {
              dispatch(ruleUpdate({ qutter_id, rule_idx, rule: r }))
            }
          }}
        />
        <Form.Text className="text-muted">
          Name your rule to more easily identify it elsewhere.
        </Form.Text>
      </Form.Group>
    );
  }
};