import React from 'react';
import { useParams } from 'react-router-dom';

import ScoreTableDetail from './ScoreTableDetail';

function is_scored (qutter) {
  if ((qutter.is_weighted || qutter.is_auto_scored) && qutter.is_manually_scored) {
    return true;
  }
  return false;
};

const ScoreTable = ({ conversation }) => {
  let { id } = useParams();
  console.log(`<ScoreTable /> ${id}`);

  if (!conversation || !conversation.length) return <div>Loading...</div>;
  console.log('conversation', conversation)
  let counted = new Set();
  return (
    <>
    {conversation.map((detail, idx) => {
      if (detail.qutter && is_scored(detail.qutter) && !counted.has(detail.qutter.id)) {
        counted.add(detail.qutter.id)
        return (
          <ScoreTableDetail key={'score-table-detail-'+idx} detail={detail} idx={idx} />
        );
      }
    })}
    </>
  );
};

export default ScoreTable;
