import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { EvaluationOverlay } from 'features/QutterEvaluation';
import { OperatorsSelect } from 'features/QutterRules';

import { setCustomEvaluation } from 'features/QutterEvaluation';

export const FormEvaluationElement = ({
  template_id,
  qutter_id,
  formId,
  evaluation,
  evaluationIdx
}) => {
  const dispatch = useDispatch();
  const Evaluation = useSelector(state => state.Evaluation[qutter_id]);
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const language = qutterConfig.language.current;
  const form = qutterConfig[language].forms.find(f => f.id === formId);
  const condition = evaluation.condition;

  return (
    <div className='d-flex'>
      <div className='w-90'>
        {condition.type === 'relative' ? (
          <div className='d-flex'>

              <span className='m-auto'>If answer </span>

              <div className='m-auto'>
                <OperatorsSelect
                  selected={condition.operator}
                  on_select={(e) => {
                    let customKey = null;
                    if (qutterConfig.type === 'form') {
                      customKey = `${form.id}.evaluation`;
                    }
                    let temp = Object.assign([], Evaluation[language][customKey]);
                    temp = JSON.parse(JSON.stringify(temp));
                    temp[evaluationIdx].condition.operator = e.value;

                    if (template_id) {
                      dispatch(setCustomEvaluation({
                        language,
                        qutter_id: qutter_id,
                        id: form.id,
                        evaluation: temp
                      }));
                    } else {
                      toast.info('Sorry: can only be edited in template.');
                    }
                  }}
                />
              </div>

              <div className='m-auto'>
                <Form.Control
                  type="number"
                  placeholder="Input a value"
                  value={condition.value}
                  className='m-auto'
                  onChange={(e) => {
                    let customKey = null;
                    if (qutterConfig.type === 'form') {
                      customKey = `${form.id}.evaluation`;
                    }
                    let temp = Object.assign([], Evaluation[language][customKey]);
                    temp = JSON.parse(JSON.stringify(temp));
                    temp[evaluationIdx].condition.value = parseInt(e.target.value);

                    if (template_id) {
                      dispatch(setCustomEvaluation({
                        language,
                        qutter_id: qutter_id,
                        id: form.id,
                        evaluation: temp
                      }));
                    } else {
                      toast.info('Sorry: can only be edited in template.');
                    }
                  }}
                />
              </div>

          </div>
        ):
        (
          <span>
            <span>
              <span>If answer is </span>
              <strong>
                <i>
                <OperatorsSelect
                  selected={condition.operator}
                />
                </i>
              </strong>
              <strong>
                <i>
                  <span>{new Date(condition.value).toDateString()}</span>
                </i>
              </strong>
            </span>
          </span>
        )}
      </div>

      <div className='w-10 text-end d-flex m-auto justify-content-end'>
        <EvaluationOverlay
          template_id={template_id}
          qutter_id={qutter_id}
          evaluation={evaluation}
          type={'calendar'}
          onSave={(e) => {
            let customKey = null;
            if (qutterConfig.type === 'form') {
              customKey = `${form.id}.evaluation`;
            }
            let temp = Object.assign([], Evaluation[language][customKey]);
            temp = JSON.parse(JSON.stringify(temp));
            temp[evaluationIdx].name = e.name;
            if (e.name === 'bonus') {
              temp[evaluationIdx].bonus_points = parseInt(e.bonus_points);
            }
            else {
              delete temp[evaluationIdx].bonus_points;
            }

            if (template_id) {
              dispatch(setCustomEvaluation({
                language,
                qutter_id,
                id: form.id,
                evaluation: temp
              }));
            } else {
              toast.info('Sorry: can only be edited in template.');
            }
          }}
        />

        <span
          onClick={(e) => {
            let customKey = null;
            if (qutterConfig.type === 'form') {
              customKey = `${form.id}.evaluation`;
            }
            let temp = Object.assign([], Evaluation[language][customKey]);
            temp.splice(evaluationIdx, 1);

            if (template_id) {
              dispatch(setCustomEvaluation({
                language,
                qutter_id: qutter_id,
                id: form.id,
                evaluation: temp
              }));
            } else {
              toast.info('Sorry: can only be edited in template.');
            }
          }}
          className=''
        >
          <FontAwesomeIcon
            className='hover-blue'
            style={{ color: 'red' }}
            icon={faCircleXmark}
          />
        </span>
      </div>
    </div>
  );
}
