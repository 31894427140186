export const stages = {
  '1': {
    stage: 1,
    name: 'New Applicants',
    color: 'warning',
    show: false,
    statuses: {
      invited: 'Invited',
      opened_invitation: 'Opened invitation',
      reminder_sent: 'Reminder sent',
      reminder_opened: 'Reminder opened',
      interview_started: 'Started Interview',
    },
    status: [
      { 'stage': 1, 'name': 'Invited', 'id': 'invited' },
      { 'stage': 1, 'name': 'Opened invitation', 'id': 'opened_invitation' },
      { 'stage': 1, 'name': 'Reminder sent', 'id': 'reminder_sent' },
      { 'stage': 1, 'name': 'Reminder opened', 'id': 'reminder_opened' },
      { 'stage': 1, 'name': 'Started Interview', 'id': 'interview_started' }
    ]
  },
  '2': {
    stage: 2,
    name: 'Hubert Leads',
    color: 'warning',
    show: false,
    statuses: {
      high_score: 'High scored',
      passed: 'Passed',
      unaccepted: 'Unaccepted',
    },
    status: [
      { 'stage': 2, 'name': 'High scored', 'id': 'high_score' },
      { 'stage': 2, 'name': 'Passed', 'id': 'passed' },
      { 'stage': 2, 'name': 'Unaccepted', 'id': 'unaccepted' },
    ],
  },
  '3': {
    stage: 3,
    name: 'Next Step',
    color: 'info',
    accepted: true,
    statuses: {
      no_next: 'No next step',
      book_phone_interview: 'Schedule phone interview',
      phone_interviewed: 'Phone screened',
      book_interview: 'Schedule interview',
      interviewed: 'Interviewed',
      test: 'Test',
      references: 'Check references',
      offer: 'Offer'
    },
    status: [
      { 'stage': 3, 'name': 'No next step', 'id': 'no_next', 'show': false },
      { 'stage': 3, 'name': 'Schedule phone interview', 'id': 'book_phone_interview' },
      { 'stage': 3, 'name': 'Phone screened', 'id': 'phone_interviewed' },
      { 'stage': 3, 'name': 'Schedule interview', 'id': 'book_interview' },
      { 'stage': 3, 'name': 'Interviewed', 'id': 'interviewed' },
      { 'stage': 3, 'name': 'Test', 'id': 'test' },
      { 'stage': 3, 'name': 'Check references', 'id': 'references' },
      { 'stage': 3, 'name': 'Offer', 'id': 'offer' }
    ],
  },
  '4': {
      stage: 4,
      name: 'Hired',
      color: 'success',
      accepted: true,
      statuses: {
        hired: 'Hired'
      },
      status: [{ 'stage': 4, 'name': 'Hired', 'id': 'hired' }],
  },
  '5': {
    stage: 5,
    name: 'Not Hired',
    color: 'danger',
    accepted: false,
    statuses: {
      not_qualified: 'Not qualified',
      manually_rejected: 'Manually rejected',
      no_answer: 'No answer',
      over_qualified: 'Over qualified',
      failed_tests: 'Failed test',
      hired_elsewhere: 'Hired elsewhere',
      offer_declined: 'Offer declined'
    },
    status: [
      //   { 'stage': 5, 'name': 'Rejected', 'id': 'rejected' },
      { 'stage': 5, 'name': 'Not qualified', 'id': 'not_qualified', 'show': false },
      { 'stage': 5, 'name': 'Manually rejected', 'id': 'manually_rejected', 'show': false },
      { 'stage': 5, 'name': 'No answer', 'id': 'no_answer' },
      { 'stage': 5, 'name': 'Over qualified', 'id': 'over_qualified' },
      { 'stage': 5, 'name': 'Failed test', 'id': 'failed_tests' },
      { 'stage': 5, 'name': 'Hired elsewhere', 'id': 'hired_elsewhere' },
      { 'stage': 5, 'name': 'Offer declined', 'id': 'offer_declined' },
    ],
  }
};

export const statuses = {
  invited: 'Invited',
  opened_invitation: 'Opened invitation',
  reminder_sent: 'Reminder sent',
  reminder_opened: 'Reminder opened',
  interview_started: 'Started Interview',
  high_score: 'High scored',
  passed: 'Passed',
  unaccepted: 'Unaccepted',
  no_next: 'No next step',
  book_phone_interview: 'Schedule phone interview',
  phone_interviewed: 'Phone screened',
  book_interview: 'Schedule interview',
  interviewed: 'Interviewed',
  test: 'Test',
  references: 'Check references',
  offer: 'Offer',
  hired: 'Hired',
  not_qualified: 'Not qualified',
  manually_rejected: 'Manually rejected',
  no_answer: 'No answer',
  over_qualified: 'Over qualified',
  failed_tests: 'Failed test',
  hired_elsewhere: 'Hired elsewhere',
  offer_declined: 'Offer declined',
  old_application: 'Old application',
  deleted: 'Deleted 🗑️',
};
