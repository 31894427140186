import { gql } from '@apollo/client';

export const QUERY_GET_JOB_APPLY_SETTINGS = gql`
query GetJob($job_id: String) {
  GetJob(job_id: $job_id) {
    id
    apply_settings {
      cv
      cl
      country
      linkedin
      city
      postal_code
      street_address
      logo
    }
  }
}
`;

export const EDIT_JOB_APPLY_SETTINGS  = gql`
mutation EditJobApplySettings(
  $job_id: String!,
  $cv:             Boolean
  $cl:             Boolean
  $country:        Boolean
  $linkedin:       Boolean
  $city:           Boolean
  $postal_code:    Boolean
  $street_address: Boolean
  $logo:           String
) {
  EditJobApplySettings(
    job_id: $job_id,
    input: {
      cv: $cv,
      cl: $cl,
      country: $country,
      linkedin: $linkedin,
      city: $city,
      postal_code: $postal_code
      street_address: $street_address
      logo: $logo
    }
  ){
    cv
    cl
    country
    linkedin
    city
    postal_code
    street_address
    logo
  }
}
`;
