import React, { useState } from 'react';
import { PreviewRuleShowThisIfCondition } from './PreviewRuleShowThisIfCondition';

export const PreviewRuleShowThisIf = ({
  template_id,
  qutter_id,
  parent_id,
  rule,
  rule_idx,
  order,
 }) => {
  const conditions = rule.conditions;

  return (
    <div>
      <div>Show this question if:</div>
      {conditions && conditions.map((condition, condition_idx) => {
        return (
          <PreviewRuleShowThisIfCondition
            key={`PreviewRuleShowThisIfCondition-${condition_idx}`}
            template_id={template_id}
            qutter_id={qutter_id}
            condition={condition}
          />
        )
      })}
    </div>
  );
};
