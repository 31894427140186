import React from 'react';
import Select from 'react-select';

import { interview_properties } from 'features/QutterRules/constants/condition_types';

export const InterviewPropertySelect = ({
  disabled,
  placeholder,
  className,

  on_select,
  selected,
}) => {

  let idx = null;
  if (selected) {
    function find (obj) { return obj.value === selected; }
    idx = interview_properties.findIndex(find);
  }

  console.log('<InterviewPropertySelect />', idx);
  return (
  <Select
    isMulti={false}
    className={className}
    isDisabled={disabled}
    value={(selected ? interview_properties[idx]: null)}
    placeholder={placeholder ? placeholder: ''}
    options={interview_properties}
    onChange={(e)=> {
      if (on_select) on_select(e);
    }}
  />
  );
};