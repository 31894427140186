import React from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

import { RuleActionJumpToListItem } from './RuleActionJumpToListItem';
import { toggleSearchQutterModal } from 'features/Modals';

export const RuleActionJumpToList = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  action_idx,
  action
}) => {
  const dispatch = useDispatch();
  const ids = action.value;

  const onAddClick = async (template_id) => {
    dispatch(toggleSearchQutterModal({
      show: true,
      header_text: 'BULLE',
      add_policy: template_id ? 'CUSTOM_RULE_ACTION_PLUS': 'DEFAULT_RULE_ACTION_PLUS',
      shouldDisplayCreateQutterButton: false,
      qutter_id: qutter_id,
      data: {
        qutter_id: qutter_id,
        rule_idx: rule_idx,
        action_idx: action_idx
      }
    }));
  };

  if (ids && ids.length > 0) {
    return (
      <>
      <ol>
        {ids.map((id, idx) => {
          return (
            <RuleActionJumpToListItem
              key={`action-id-${id}`}
              template_id={template_id}
              qutter_id={qutter_id}
              is_default={is_default}
              rule_idx={rule_idx}
              action_idx={action_idx}
              action={action}
              item_id={id}
              item_idx={idx}
              num_items={ids.length}
            />
          );
        })}
      </ol>

      <span
        onClick={() => onAddClick(template_id)}
        className={`ms-1 hover-blue me-3 font-size-10 ps-4`}
      >
        <FontAwesomeIcon className={'me-1'} icon={faPlusCircle} />
        Add to list
      </span>

      </>
    );
  }

  return (
    <div className='m-2 text-center red'>
      <span>No questions added yet.</span>
    </div>
  );
};