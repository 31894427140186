import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Container, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';

import { JsonPreview } from 'features/common';
import { LanguageSelect } from 'features/LanguageSelect';

import { QutterComponents } from 'features/QutterType';
import { CreateSelect } from 'features/QutterSelects';

import { QutterActionsAccordion } from 'features/QutterActions';
import { QutterGeneralSettings } from 'features/QutterCreate';

import { EditQutterShortDescription } from 'features/QutterCreate';

import { setCurrentQutterLanguage } from 'features/Qutters/slices/LibrarySlice';
import { toggleEditQutterModal } from 'features/Modals/slices/ModalSlice';

export const EditQutter = ({
  qutter_id,
  language,
}) => {
  // console.log('<EditQutter />', qutter_id, language);
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const qutter = qutterConfig[language];

  return (
  <>
  {qutterConfig ? (
  <>

  {/* <Divider className="">Language</Divider> */}
  <Row>
    <Col className='m-auto text-center'>
      <span className='m-auto'>Choose Language</span>
    </Col>
    <Col className='m-auto'>
      <LanguageSelect
        on_select={(e) => {
          dispatch(setCurrentQutterLanguage({
            id: qutter_id,
            language: e.value
          }));
        }}
        disabled={true}
        selected_language={(language ? language: null)}
      />
    </Col>

  </Row>

  <EditQutterShortDescription qutter_id={qutter_id} />

  <Divider className="mt-4">Details</Divider>
  <Container>
    <Row>
      {qutter_id && qutter_id.length ? (
        React.createElement(
          (['single-select', 'multiple-select'].includes(qutterConfig.type) ? CreateSelect : QutterComponents[qutterConfig.type]),
          {
            id: qutter_id,
            language: language
          }
        )
      ):(
        <span>Nothing chosen yet.</span>
      )}
    </Row>

    <QutterGeneralSettings id={qutter_id} />
    <QutterActionsAccordion qutter_id={qutter_id} />

  </Container>

  <JsonPreview data={qutter} />
  </>
  ) : (
    <div 
      className='m-auto text-center center'
      style={{
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        msTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <span>You must choose a type and language to continue.</span>
    </div>
  )}
  </>
  );
};