import { useSelector } from 'react-redux';
import { OverlayTrigger } from 'react-bootstrap';

import { ScoringCategoryPopover } from './ScoringCategoryPopover';
import { capitalizeFirstLetter } from 'features/common';

export const ScoringCategoryPopoverOverlay = ({
  template_id,
  category_id,
  category,
  weight
}) => {
  const templateConfig = useSelector(state => state.Template);
  const readOnly = templateConfig.readOnlyMode;
  let name = category_id.split('_').join(' ');
  name = capitalizeFirstLetter(name);

  if (readOnly) {
    return (
      <span className=''>{name}</span>
    )
  } else {
    return (
      <OverlayTrigger
        trigger="click"
        placement="right"
        overlay={ScoringCategoryPopover(template_id, category_id, category, weight)}
      >
        <span className='hover-blue'>{name}</span>
      </OverlayTrigger>
    )
  }
}