import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

export const warningIconWithText = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#FFC400' }} className="mr-3" size={'lg'} icon={faExclamationTriangle} />
    <span className="m-2">Warning</span>
  </span>;

export const WarningIcon = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#FFC400' }} className="mr-3" size={'lg'} icon={faExclamationTriangle} />
  </span>;
