import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

export const PointIcon = ({ points }) => {
  return (
    <span
      className="cursor-pointer fa-layers fa-fw"
    >
      <FontAwesomeIcon 
        style={{ color: '#7365f2' }}
        size={'lg'}
        icon={faCircle}
      />
      <span 
        className="fa-layers-text fa-inverse font-size-10" 
        style={{ fontWeight: '900' }}
      >
        +{points}
      </span>
    </span>
  );
};
