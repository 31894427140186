import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { evaluation_context } from '../utils/evaluation_context';

export const EvaluationContext = ({
  template_id,
  qutter_id,
  node,
  node_idx,
  is_followup
}) => {
  // console.log('<EvaluationContext /> START', node.id)
  const dispatch = useDispatch();
  const library = useSelector(state => state.Library);
  const template = useSelector(state => state.Template[template_id]);
  const evaluation_state = useSelector(state => state.Evaluation[qutter_id]);

  useEffect(() => {
    if (evaluation_state) return;
    if (!library) {
      // console.log('<EvaluationContext /> No Library', node.id);
      return;
    }

    if (!template) {
      // console.log('<EvaluationContext /> No template', node.id);
      return;
    }

    evaluation_context(
      dispatch,
      library,
      template,
      evaluation_state,
      node,
      is_followup
    );
  }, []);

  return (<></>);
};

/**
Pseudo
- If first time 
  - Update eval_state with default eval
- Thus if qutter_id not in eval_state: it's the first time
- EvaluationOverlay always gets from eval_state
- Parent to EvaluationOverlay clears out the evaluation object to EvaluationOverlay
*/