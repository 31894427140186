import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';

import ClientDetailsHeader from './detail/ClientDetailsHeader';
import ClientInfo from './detail/ClientInfo';
import ClientMembers from './detail/ClientMembers';
import { TemplatesList } from 'features/Template';
import JobsList from '../jobs/JobsList';

const QUERY_GET_CLIENT = gql`
  query GetClient($client_id: String) {
    GetClient(client_id: $client_id) {
      id  
      org_name
      org_nr
      created_at
      admins
      jobs {
        id
        title
        created_at
        responsible_cs {
          id
          first_name
          last_name
        }
        scoring_statistics {
          todos
          manually_updated
          auto_updated
          invited
        }
        version
      }
      members {
        id
        firstName
        lastName
        email
        flag
      }
      templates {
        id
        name
        role {
          id
          name
        }
        type
        language
      }
    }
  }
`;

const Client = () => {
  let { id } = useParams();

  const { loading, error, data } = useQuery(
    QUERY_GET_CLIENT,
    { variables: { client_id: id }}
  );

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <ClientDetailsHeader client={data.GetClient} />
      {/* <ClientInfo client={data.GetClient} /> */}
      <JobsList
        showSearchBar={false}
        jobs={data.GetClient.jobs}
        client_id={id}
      />
      <Row>
        <Col>
          <ClientMembers client={data.GetClient} />
        </Col>
        <Col>
          <TemplatesList templates={data.GetClient.templates} />
        </Col>
      </Row>
    </div>
  );
};

export default Client;
