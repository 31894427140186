import React, { useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { RoleSelect } from 'features/RoleSelect';
import { QutterTagSelect } from 'features/QutterTagSelect';
import { LanguageSelect } from 'features/LanguageSelect';

import { candidate_advanced_search } from '../api/api';

export const CandidateSearchGlobal = () => {

  const [text, set_text] = useState('');
  const [scope, set_scope] = useState('global');
  const [language, set_language] = useState('');
  const [role, set_role] = useState([]);
  const [tags, set_tags] = useState([]);
  const [types, set_types] = useState([]);
  const [loading, set_loading] = useState(false);

  const [qutter_list, set_qutter_list] = useState([]);

  const on_search_click = () => {
    let search_params = {};
    if (text.length) search_params.text = text;
    if (language.length) search_params.language = language;
    if (tags.length) search_params.tags = tags;
    if (role.length) search_params.role = role;
    if (types.length) search_params.types = types;
    set_loading(true);

    candidate_advanced_search(search_params).then((data) => {
      set_qutter_list(data);
      set_loading(false);
    }).catch((error) => {
      set_loading(false);
      toast.error(`Search failed: ${error}`);
    });
  };

  return (
  <div>
    <div>Global</div>
    <div>Find candidates among <strong>Hubert's database</strong> that have answered in your search criteria.</div>
  </div>
  );
};
