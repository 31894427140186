import axios from 'axios';
import { getAccessToken, fetchAccessToken } from '../../../tokens/tokens';
import jwtDecode from 'jwt-decode';

const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const job_base = `/jobs/v1`;
const match_base = `/match/v1`;

const httpClient = axios.create({ baseURL: base });

const isExpired = (token) => {
  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000;
}

httpClient.interceptors.request.use(async (config) => {
  let token = getAccessToken();
  if(!token || isExpired(token)) {
      const { accessToken } = await fetchAccessToken();
      token = accessToken;
  }

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export function candidate_advanced_search (search_params) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${URL}`, search_params)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
};


export function get_questions_by_client_id (client_id) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${FIND_QUESTIONS_BY_CLIENT_ID}/${client_id}`)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
};


export function get_global_questions () {
  return new Promise((resolve, reject) => {
    httpClient.get(`${FIND_QUESTIONS_BY_CLIENT_ID}/${client_id}`)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
};


export function get_job_by_id (job_id) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${job_base}/get-by-id/${job_id}`)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
};


export function filter_candidates_by_job_and_questions (job_id, query) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${match_base}/filter-candidates-by-job-and-questions/${job_id}`, query)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
};

export function filter_candidates (query) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${match_base}/filter-candidates`, query)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
};

export function get_candidate (candidate_id) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${match_base}/get-candidate/${candidate_id}`)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}

export async function getCandidateById(id) {
  const r = await httpClient.get(`${match_base}/candidates/${id}`);
  return r.data.data;
}
