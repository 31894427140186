import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/pro-solid-svg-icons';

export const DisqualifiedIcon = ({}) => {

  return (
    <span
      onClick={(e) => {}}
      className='cursor-pointer'
    >
      <FontAwesomeIcon
        style={{ color: 'red' }}
        className='cursor-pointer'
        size={'lg'}
        icon={faSquareXmark}
      />
    </span>
  );
};