import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/pro-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toggleQutterCommentModal } from 'features/Modals/slices/ModalSlice';

import { getCommentsByQuestion } from 'features/QutterComment/api/api';

export const QutterCommentIcon = ({
  template_id,
  qutter_id,
  containerClass='',
}) => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState(null);
  const [error, setError] = useState({ isError: false, message: '' });
  const qutter = useSelector(state => state.Library[qutter_id]);
  const template = useSelector(state => state.Template[template_id]);
  const user = useSelector(state => state.config.user);

  useEffect(() => {
    if (!user || !template_id || !template.client_id)
      return;

    getCommentsByQuestion(user, {
      template_id,
      qutter_id,
      client_id: template.client_id
    })
    .then((data) => {
      console.log('getCommentsByQuestion data', data);
      setComments(data);
    })
    .catch((error) => {
      console.log(error);
      setComments(null);
      setError({ isError: true, message: error });
    });
  }, []);

  const onCommentClick = (e) => {
    dispatch(toggleQutterCommentModal({
      show: true,
      data: {
        template_id,
        qutter_id,
        comments
      },
    }));
  };

  if (qutter) {
    return (
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id="button-tooltip-2">Comments</Tooltip>}
      >
      {({ ref, ...triggerHandler }) => (
        <span
          style={{
            position: 'relative'
          }}
          className={`hover-blue me-3 font-size-10 ${containerClass}`}
          ref={ref}
          {...triggerHandler}
          onClick={onCommentClick}
        >
          <FontAwesomeIcon icon={faComment} />
          {comments && comments.comments.length > 0 ? (
            <span
              className='comment-notification'
            >
              {comments.comments.length}
            </span>
          ):(null)}
        </span>
      )}
      </OverlayTrigger>
    );
  }
  else {
    return (<>Comment Error! Cannot find qutter.</>);
  }
};