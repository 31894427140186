import React from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

import {
  remove_interview_summary_block,
  NOT_VIEWABLE_BLOCK_IN_SUMMARY
} from '..';

export const RemoveInterviewSummaryBlock = ({
  template_id,
  summary_block_id
}) => {
  const dispatch = useDispatch();

  if (summary_block_id === NOT_VIEWABLE_BLOCK_IN_SUMMARY)
    return (null);

  return (
  <span
    className="cursor-pointer hover-blue"
    onClick={() => {
      dispatch(
        remove_interview_summary_block({
          template_id,
          block_id: summary_block_id
        })
      );
    }}
  >
    <FontAwesomeIcon icon={faTrash} />
  </span>
  );
};
