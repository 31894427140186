import React from 'react';
import HubiverseTagsSelectSingle from './HubiverseTagsSelectSingle';
import HubiverseTagsSelectMultiple from './HubiverseTagsSelectMultiple';

export const HubiverseTagsSelect = ({
  multi,
  on_select,
  selected,
  disabled,
  placeholder,
  className,
  list
}) => {

  if (multi) {
    return (
      <HubiverseTagsSelectMultiple
        on_select={on_select}
        selected={selected}
        disabled={disabled}
        placeholder={placeholder}
        className={className}
        list={list}
      />
    )
  }

  return (
    <HubiverseTagsSelectSingle
      on_select={on_select}
      selected={selected}
      disabled={disabled}
      placeholder={placeholder}
      className={className}
      list={list}
    />
  );
};
