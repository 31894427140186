import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { RemoveTemplateById } from 'features/Template/slices/thunks';
import { ConfirmationModal } from 'features/ConfirmationModal';

export const RemoveTemplateButton = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [templateDeleteConfirmation, setTemplateDeleteConfirmation] =
    useState(false);

  const on_remove_click = () => {
    dispatch(RemoveTemplateById(id))
      .then(res => {
        if (res.payload.deletedCount === 0) {
          toast.warning(`Something went wrong - No template removed.`);
        } else if (res.payload.deletedCount === 1) {
          toast.success(`Template removed.`);
          navigate('/templates');
        } else if (res.payload.deletedCount > 1) {
          toast.warning(`Removed more than 1 template.`);
          navigate('/templates');
        } else {
          toast.error(`Failed to remove template. Deleted count is wrong.`);
        }
      })
      .catch(error => {
        toast.error(`Failed to remove template. Error: ${error}`);
      });
  };

  const onClickTemplateDelete = () => {
    on_remove_click();
  };
  const onClickTemplateCancel = () => {
    setTemplateDeleteConfirmation(false);
  };

  return (
    <>
      <ConfirmationModal
        headerTitle={'Template Deletion'}
        bodyContent={'Do You Want To Remove This Template?'}
        cancelButtonFunction={onClickTemplateCancel}
        confirmButtonFunction={onClickTemplateDelete}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Remove'}
        modalVisibility={templateDeleteConfirmation}
        setModalVisibility={setTemplateDeleteConfirmation}
      />
      <span
        onClick={() => {
          setTemplateDeleteConfirmation(true);
        }}
        className="hover-blue d-block font-size-10"
      >
        <FontAwesomeIcon className="me-1" size={'sm'} icon={faTrash} />
        Delete template
      </span>
    </>
  );
};
