import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faText } from '@fortawesome/pro-solid-svg-icons';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { QUTTER_TYPES_TO_TEXT } from 'features/QutterType';

export const QutterTypeIcon = ({
  qutter_id,
  containerClass=''
}) => {
  const qutter = useSelector(state => state.Library[qutter_id]);

  if (qutter) {
    return (
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id="button-tooltip-2">{QUTTER_TYPES_TO_TEXT[qutter.type]}</Tooltip>}
      >
      {({ ref, ...triggerHandler }) => (
        <span
          className={containerClass}
          ref={ref}
          {...triggerHandler}
        >
          <FontAwesomeIcon icon={faText} />
        </span>
      )}
      </OverlayTrigger>
    );
  }
  else {
    return (<></>);
  }
};