import React from 'react';

export const LabelPreview = ({
  label,
  is_mandatory
}) => {
  // console.log('<LabelPreview />');
  return (
    <div className='d-flex'>
      <span
        dangerouslySetInnerHTML={{ __html: `${label}` }}
        style={{ maxWidth: '90%', verticalAlign: 'middle' }}
        className="m-0"
      >
      </span>
      {is_mandatory ? (<span className=''>*</span>): (<span></span>)}
    </div>
    // <label 
    //   style={{ maxWidth: '90%' }}
    // >
    //   {label}
    //   {is_mandatory ? (<span>*</span>): (<span></span>)}
    // </label>
  );
}
