import React from 'react';
import { useSelector } from 'react-redux';

import { CreateQutterModal } from 'features/Modals';
import { SearchQutterModal } from 'features/Modals';
import { EditQutterModal } from 'features/Modals';
import { NodeActionModal } from 'features/Nodes';
import { FormEvaluationModal } from 'features/Modals';
import { QutterCommentModal } from 'features/Modals';

export const ModalsContainer = ({}) => {
  // console.log('<ModalsContainer />');
  const Modals = useSelector(state => state.Modals);
  return (
    <>
      <CreateQutterModal
        showModal={Modals.createQuestionModal.show}
        qutter_id={Modals.createQuestionModal.qutter_id}
      />

      <EditQutterModal
        showModal={Modals.editQutterModal.show}
        qutter_id={Modals.editQutterModal.qutter_id}
        language={Modals.editQutterModal.language}
      />

      <SearchQutterModal
        showModal={Modals.searchQutterModal.show}
        header_text={Modals.searchQutterModal.header_text}
        add_policy={Modals.searchQutterModal.add_policy}
        template_id={Modals.searchQutterModal.template_id}
        block_id={Modals.searchQutterModal.block_id}
        block_name={Modals.searchQutterModal.block_name}
        qutter_idx={Modals.searchQutterModal.qutter_idx}
        qutter_id={Modals.searchQutterModal.qutter_id}
      />

      <NodeActionModal showModal={Modals.InterviewNodeActionsModal.show} />

      {Modals.formEvaluationModal && Modals.formEvaluationModal.data ? (
        <FormEvaluationModal
          showModal={Modals.formEvaluationModal.show}
          data={Modals.formEvaluationModal.data}
        />
      ):(null)}

      <QutterCommentModal
        showModal={Modals.qutterCommentModal.show}
        data={Modals.qutterCommentModal.data}
      />

      {/* <SearchQutterModal
        template_id={template_id}
        block_id={block_id}
        block_name={block.name}
        qutter_idx={qutter_idx}
        showModal={showSearchQutterModal}
        setShowModal={setShowSearchQutterModal}
      /> */}

    </>
  );
};
