import { lookup } from "features/QutterEvaluation";

import {
  update_scoring_categories,
  add_qutter_to_template_summary_list,
  add_weight,
  add_disqualification,
  add_warning
} from '..';

import { single_select_counter } from "./single_select_counter";

const multiple_select_counter = async (dispatch, template, parameters, qutter_config, language) => {
  const template_id = template._id;
  const qutter_id = qutter_config.id;

  // let bonuspoints = [];
  // const options = qutter_config[language].options;

  // for (let i = 0; i < options.length; i++) {
  //   const option = options[i];
  //   const evaluation = await lookup(parameters,`${qutter_id}.${option.id}.evaluation`);

  //   // Bonuspoints
  //   if (evaluation && evaluation.id === 'bonus') {
  //     bonuspoints.push(evaluation.bonus_points);
  //   } else 
  //   if (option.hasOwnProperty('bonus_points') && !['none-above', 'all-above'].includes(option.id)) {
  //     bonuspoints.push(option.bonus_points);
  //   }

  //   // Disqualification
  //   if (evaluation && evaluation.id === 'disqualified') {
  //     dispatch(add_disqualification({ template_id, qutter_id }));
  //   } else 
  //   if (option.hasOwnProperty('evaluation') && option.evaluation === 'disqualified') {
  //     dispatch(add_disqualification({ template_id, qutter_id }));
  //   }

  //   // Warning
  //   if (evaluation && evaluation.id === 'warning') {
  //     dispatch(add_warning({ template_id, qutter_id }));
  //   } else 
  //   if (option.hasOwnProperty('evaluation') && option.evaluation === 'warning') {
  //     dispatch(add_warning({ template_id, qutter_id }));
  //   }
  // }
};

const open_counter = async (dispatch, template, parameters, qutter_config, is_followup) => {
  const template_id = template._id;
  const qutter_id = qutter_config.id;
  const languages = template.language_settings.list;

  // if (
  //   qutter_config.hasOwnProperty('weight') &&
  //   qutter_config.hasOwnProperty('scoring_category') && qutter_config.scoring_category !== 'info'
  // ) {
  //   dispatch(update_scoring_categories({ template_id, qutter_config, is_followup }));
  //   await dispatch(add_weight({ template_id, qutter_id, weight: qutter_config.weight }));
  // }
};

export const outline_counter = async (
  dispatch,
  library,
  template,
  parameters,
  node,
  is_followup
) => {

  if (node.type === 'question') {
    const qutter_config = library[node.id];

    if (qutter_config.type === 'info') return;
    if (qutter_config.type === 'single-select') {
      // single_select_counter(
      //   dispatch,
      //   library,
      //   template,
      //   parameters,
      //   node,
      //   is_followup
      // );
    }
    // else return;
    // if (qutter_config.type === 'multiple-select') {
    //   multiple_select_counter(dispatch, template, parameters, qutter_config, languages);
    // } 
    // else 
    // if (qutter_config.type === 'open') {
    //   open_counter(dispatch, template, parameters, qutter_config, languages, is_followup);
    // }
  } else if (node.type === 'action') {
  }
};
