import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faBinoculars
} from '@fortawesome/free-solid-svg-icons';

import { Row, Col, Card, Form } from 'react-bootstrap';

import { TemplatesList } from 'features/Template';
import { ClientSelect } from 'features/ClientSelect';
import { CreateTemplateModal } from 'features/Template';

import { get_all_templates, get_client_templates } from 'features/TemplateSelect';

// const GET_TEMPLATES_BY_CLIENT_ID = gql`
// query GetTemplatesByClientId($client_id: String) {
//   GetTemplatesByClientId(client_id: $client_id) {
//     id
//     name
//     language
//     role {
//       id
//       name
//     }
//     role_id
//   }
// }
// `;

// const GET_ALL_TEMPLATES = gql`
// query {
//   GetAllTemplates {
//     id
//     name
//     language
//     role {
//       id
//       name
//     }
//     role_id
//     published
//   }
// }
// `;

export const TemplatesIndex = () => {
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);
  const [loading, set_loading] = useState(true);
  const [error, set_error] = useState(false);
  const [error_message, set_error_message] = useState('');
  const [client, set_client] = useState(null);
  const [templatesList, setTemplatesList] = useState([]);

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (client) {
      get_client_templates(client.value)
      .then((response) => {
        if (response && Array.isArray(response)) {
          setTemplatesList(response);
        } else {
          setTemplatesList([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }
  }, [client]);
  
  const get = () => {
    get_all_templates(true)
      .then((response) => {
        if (response.length) {
          setTemplatesList(response);
        } else {
          setTemplatesList([]);
        }
        set_loading(false);
      })
      .catch((err) => {
        console.error(err);
        set_error(true)
        set_error_message(err);
      });
  };
  const onCreateTemplateClick = (e) => { setShowCreateTemplateModal(true); };

  if (loading) return 'Loading...';
  if (error) return `Error! ${error_message}`;
  // if (clientError) return `Error! ${clientError.message}`;

  return (
    <>
    <Card className='mb-1'>
      <Card.Header>
        <h5 className="">Templates</h5>
      </Card.Header>
      <Card.Body className="">

        <CreateTemplateModal
          showModal={showCreateTemplateModal}
          setShowModal={setShowCreateTemplateModal}
        />

        <Form.Group as={Row} className="mb-3" controlId="formGroupClients">
          <Form.Label column sm="2">
            Filter by client
          </Form.Label>
          <Col sm="4">
            <ClientSelect
              on_select={(e)=> {
                set_client(e);
              }}
            />
          </Col>
        </Form.Group>

        <span
          className='hover-blue font-size-10 d-inline me-2'
          style={{ textAlign: 'left' }}
          // disabled={(client && client.value ? false: true)}
          onClick={() => {get()}}
        >
          <FontAwesomeIcon 
            style={{}} 
            size={'sm'} 
            icon={faBinoculars}
            className="font-size-10 me-2"
          />
          Search templates
        </span>

        <span
          className='hover-blue font-size-10 d-inline float-end'
          style={{ textAlign: 'left' }}
          onClick={onCreateTemplateClick}
        >
          <FontAwesomeIcon 
            style={{}} 
            size={'sm'} 
            icon={faPlusCircle}
            className="font-size-10 me-2"
          />
          Create template
        </span>

      </Card.Body>
    </Card>

    {(() => {
        if (templatesList && templatesList.length) {
          return (
            <TemplatesList
              clientName={''}
              templates={templatesList}
              show={true}
            />
          );
        } else {
          return (
            <span>No templates</span>
          );
        }
      })()}
    </>
  );
};