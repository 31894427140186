
import React from 'react';
import { stages } from '../constants/statuses';

export const ApplicationStage = ({
  application_id,
  interview_id,
  stage
}) => {
  console.log('<ApplicationStage />', stage);

  if (stage) {
    return (<span>{stages[stage.toString()].name}</span>)
  } else {
    return (<span>{'-'}</span>)
  }
};
