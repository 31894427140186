import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';

import FalconCardHeader from 'components/common/FalconCardHeader';
import { Notification } from './Notification';

export const NotificationDropdown = ({
  unread=[]
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(
    unread.length ? true: false
  );

  const handleToggle = () => { setIsOpen(!isOpen); };

  const markAsRead = e => {
    e.preventDefault();
    setIsAllRead(true);
    // TODO: Update all as read
  };

  return (
    <Dropdown navbar={true} as="li" onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        // to="#!"
        className={classNames('px-0 nav-link', 
        {
          // 'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >

        <span
          style={{
            position: 'relative'
          }}
          className={`hover-blue me-3`}
          // onClick={onCommentClick}
        >
          <FontAwesomeIcon icon={faBell} className="fs-4" />
          {unread.length > 0 ? (
            <span
              className='comment-notification'
            >
              {unread.length}
            </span>
          ):(null)}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                Mark all as read
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '25rem' }}
          >
            <div className="list-group-title">NEW</div>{' '}
            {unread.map(notification => {
              const len = notification.comments.length;
              if (!len) return null;
              const last = notification.comments[len - 1];
              return (
                <ListGroup.Item 
                  key={notification._id}
                  onClick={handleToggle}
                >
                  <Notification
                    notification={notification}
                    notificationId={notification._id}
                    templateId={notification.template_id}
                    qutterId={notification.qutter_id}
                    content={last.content}
                    author={last.author}
                    clientId={notification.client_id}
                    createdAt={notification.created_at}
                    flush
                  />
                </ListGroup.Item>
              )
            })}
            {/*
            <div className="list-group-title">EARLIER</div>
            {isIterableArray(earlierNotifications) &&
              earlierNotifications.map(notification => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))}
            */}
          </ListGroup>

        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};
