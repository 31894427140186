import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import ScoringCategorySelectSingle from './ScoringCategorySelectSingle';

import { get_scoring_categories } from '../../QutterOpen/api/api';

const categories = [
  { label: 'None - informational', value: 'info' },
  { label: 'Experience', value: 'experience' },
  { label: 'Motivation - Job Characteristics', value: 'motivation_perceived_job_characterictics' },
  { label: 'Motivation - Candidate Aptitude', value: 'motivation_justificiation_candidate_aptitude' },
  { label: 'STAR - Independent', value: 'independent' },
  { label: 'STAR - Meticulous', value: 'accurate' },
  { label: 'STAR - Goal oriented', value: 'goal_oriented' },
  { label: 'STAR - Stress', value: 'stress' },
  { label: 'STAR - Initiative', value: 'initiative' },
  { label: 'STAR - Leadership', value: 'leadership' },
  { label: 'STAR - Customer service', value: 'great_customer_support' },
  { label: 'STAR - Problem solver', value: 'problem_solving' },
  { label: 'STAR - Physical Experience Current', value: 'physical_experience_current' },
  { label: 'STAR - Physical Experience Past/history', value: 'physical_experience_past' },
  { label: 'STAR - Cooperative', value: 'cooperative' },
];

export const ScoringCategorySelect = ({
  disabled,
  on_select,
  selected,
  placeholder,
  className
}) => {
  const [loading, set_loading] = useState(true);
  const [error, set_error] = useState(false);
  const [categories, set_categories] = useState([]);
  const [description, set_description] = useState('');
  let idx = null;

  console.log('ScoringCategorySelect', selected);

  useEffect(() => {
    get_scoring_categories('select')
    .then((data) => {
      console.log('get_scoring_categories', data);
      set_categories(data);
      set_loading(false);
    })
    .catch((error) => {
      set_categories([]);
      set_error(true);
    })
  }, []);

  useEffect(() => {
    if (selected) {
      function find (obj) { return obj.value === selected; }
      idx = categories.findIndex(find);
      if (idx > -1) {
        const category = categories[idx]
        set_description(category.description);
      }
    }
  }, [categories]);

  if (loading) return (<span>Loading categories.</span>)
  if (error) return (<span>Error fetching categories.</span>)

  return (
    <div>
      <ScoringCategorySelectSingle
        list={categories}
        selected={selected}
        on_select={on_select}
      />
      {/* 
      <Select
        isMulti={false}
        className={className}
        isDisabled={disabled}
        defaultValue={(selected_category ? categories[idx]: null)}
        placeholder={placeholder ? placeholder: 'Scoring category'}
        options={categories}
        onChange={(e)=> {
          if (on_select) on_select(e);
        }}
      /> 
      */}
      <p>{description}</p>
    </div>
  );
};
