import React, { useState }  from 'react';
import { Container, Row, Col, Collapse } from 'react-bootstrap';

import Divider from 'components/common/Divider';
import { PrettyPrintJson } from 'features/common';

export const JsonPreview = ({
  data,
  show=false
}) => {
  const [collapsed, setCollapsed] = useState(show);

  return (
  <Container className='mb-2'>
    {show === false ? (
      <span
        onClick={() => {setCollapsed(!collapsed)}}
      >
        <Divider className="mt-4">Powered By Hubert</Divider>
      </span>
    ):(null)}

    <Collapse in={collapsed}>
      <Row>
        <Col>
          <PrettyPrintJson data={data} />
        </Col>
      </Row>
    </Collapse>
  </Container>
  );
};