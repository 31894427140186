import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import { QutterRules } from 'features/QutterRules';

import { UpdateQutter } from 'features/Qutters/slices/thunks';

export const QutterRulesModal = ({
  template_id,
  qutter_id,
  showModal,
  setShowModal
}) => {
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const qutter = qutter_config[qutter_config.language.current];

  const onSaveClick = () => {
    // VALIDATION - Validate the saved qutter
    dispatch(UpdateQutter(qutter_config)).then((res) => {
      toast.success(`Rules updated.`);
      setShowModal(false);
    });
  };

  return (
  <Modal
    show={showModal}
    onHide={() => setShowModal(false)}
    dialogClassName="create-question-modal"
    size="lg"
    aria-labelledby=""
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        <span className='h6 m-0'>Question Rules</span>
        <div
          className="mt-1 h6"
          dangerouslySetInnerHTML={{ __html: `<i>${qutter.text.join(' ')}</i>` }}
        />
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <QutterRules
        template_id={template_id} 
        qutter_id={qutter_id} 
      />
    </Modal.Body>

    {template_id ? (null):(
    <Modal.Footer>
      <span
        className="font-size-10 hover-blue me-2"
        onClick={()=>{ onSaveClick(); }}
      >
        <FontAwesomeIcon icon={faFloppyDisk} className="me-1"/>
        Save
      </span>
    </Modal.Footer> 
    )}
  </Modal>
  );
};