import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, InputGroup, FormControl, Form } from 'react-bootstrap';
import { ScoringCategorySelect } from 'features/ScoringCategorySelect';
import { 
  updateQutterScoringCategory,
  updateQutterWeight
} from 'features/Qutters/slices/LibrarySlice';

export const EvaluateOpen = ({
  id
}) => {
  const dispatch = useDispatch();
  const qutter = useSelector(state => state.Library[id]);
  const [weight, set_weight] = useState(qutter.weight);

  useEffect(() => {
    if (qutter.weight === undefined) {
      set_weight(0)
    }
  }, [qutter.weight]);

  return (
    <Row>
      <Col className='m-auto'>
        <ScoringCategorySelect
          selected={qutter.scoring_category}
          on_select={(e) => {
            dispatch(updateQutterScoringCategory({
              id,
              scoring_category: e.value
            }))
          }}
        />
      </Col>
      <Col className='m-auto'>
        {/* Set default weight */}
        <span className='font-size-10'>
          Weight: {Math.round(weight * 100 * 10) / 10}%
        </span>
        <Form.Range
          min={0}
          max={100}
          step={0.5}
          defaultValue={weight * 100}
          onBlur={(e) => {
            dispatch(updateQutterWeight({ id, weight }))
          }}
          onChange={(e) => {
            set_weight(parseFloat(e.target.value / 100))
          }}
        />
      </Col>
    </Row>
  );
}
