import React from 'react';
import Select from 'react-select';
import { TAGS } from '../assets/constants';

const QutterTagSelectSingle = ({
  on_select,
  selected_tag,
  disabled,
  placeholder,
  className
}) => {

  let idx = null;
  if (selected_tag) {
    function find (obj) { return obj.value === selected_tag; }
    idx = TAGS.findIndex(find);
  }

  return (
    <Select
      isMulti={false}
      isDisabled={disabled}
      defaultValue={(selected_tag ? TAGS[idx]: null)}
      placeholder={placeholder ? placeholder: 'Choose language(s)'}
      className={className}
      options={TAGS}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};

export default QutterTagSelectSingle;
