import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';

import { QutterHeader } from 'features/Template';
import { PreviewSelectOptions } from '..';
// import { AddQutterToBlock } from 'features/TemplateBlock';
// import { UniqueFollowups } from '..';

import { TemplateTexts } from 'features/PreviewQuestionTexts';
import { PreviewRules } from 'features/QutterRules';

export const PreviewSelect = ({
  template_id,
  block_id,
  node,
  block_name,
  block_idx,
  qutter_id,
  qutter_idx,
  order,
  provided,
  is_followup,
  parent_id
}) => {
  // console.log('<PreviewSelect />', qutter_id);
  const [previewIsOpen, setPreviewIsOpen] = useState(false);

  const template = useSelector(state => state.Template[template_id]);
  let params = useSelector(state => state.Parameters[qutter_id]);
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];

  // Rules
  const [rules, set_rules] = useState([]);
  const custom_rules_key = `rules`;

  useEffect(() => {
    // Rules
    if (params && params.hasOwnProperty(custom_rules_key)) {
      set_rules(params[custom_rules_key]);
    } else if (qutter_config.rules) {
      set_rules(qutter_config.rules);
    }
  }, [qutter_config, params, language]);

  // Handlers
  const onFullPreviewClick = e => { setPreviewIsOpen(!previewIsOpen); };

  return (
  <div
    key={`view-choice-qutter-${qutter_id}-${qutter_idx}`}
    className={`mb-2`}
  >

    <QutterHeader
      template_id={template_id}
      block_id={block_id}
      qutter_id={qutter_id}
      order={order}
      previewIsOpen={previewIsOpen}
      onFullPreviewClick={onFullPreviewClick}
      provided={provided}
      is_followup={is_followup}
      parent_id={parent_id}

      rules={rules}
    />

    {qutter_config && qutter ? (
      <div className={`pt-1 ${previewIsOpen ? '': 'd-none'}`}>

        {/* Intro (field: intro) */}
        <TemplateTexts
          template_id={template_id}
          qutter_id={qutter_id}
          field={'intro'}
        />
        {/* Question (field: text) */}
        <TemplateTexts
          template_id={template_id}
          qutter_id={qutter_id}
          field={'text'}
        />

        {/* Outro (field: outro) */}
        <TemplateTexts
          template_id={template_id}
          qutter_id={qutter_id}
          field={'outro'}
        />

        <PreviewSelectOptions
          template_id={template_id}
          qutter_id={qutter_id}
          order={order}
        />
      </div>
    ) : null}

    <PreviewRules
      template_id={template_id}
      qutter_id={qutter_id}
      order={order}
      rules={rules}
    />

    {/* 
    <UniqueFollowups
      template_id={template_id}
      block_id={block_id}
      block_idx={block_idx}
      qutter_id={qutter_id}
      qutter_idx={qutter_idx}
      order={order}
      idx={order}
    /> 
    */}

    {/* 
    {template_id && is_followup !== true ? (
      <AddQutterToBlock
        template_id={template_id}
        block_id={block_id}
        qutter_id={qutter_id}
        qutter_idx={qutter_idx}
      />
    ) : null} */}

  </div>
  );
}
