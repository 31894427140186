import React from 'react';

export const ApplicationAccepted = ({
  application_id,
  interview_id,
  accepted
}) => {
  console.log('<ApplicationAccepted />', accepted);

  if (accepted === true) {
    return (<span role="img" aria-label="thumbs-up">👍</span>);
  } else if (accepted === false) {
    return (<span role="img" aria-label="thumbs-down">👎</span>);
  } else return (<span>{'-'}</span>);
};
