import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

export const InfoIcon = ({}) => {

  return (
    <span
      onClick={(e) => {}}
      className='cursor-pointer'
    >
      <FontAwesomeIcon
        size={'lg'}
        icon={faInfoCircle}
      />
    </span>
  );
};