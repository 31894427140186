import { gql } from '@apollo/client';

export const SCORE_INTERVIEW = gql`
query ScoreInterviewById($interview_id: String) {
  ScoreInterviewById(interview_id: $interview_id) {
    id
    language
    state
    scoring_status {
      name
      updated_at
      history {
        name
        updated_at
      }
    }
    ai_detection {
      is_ai
      tag
      score
    }
    auto_updating {
      auto_update_eligible
      auto_update_executed
      tags
      updated_at
    }
    interview_id
    application {
      id
      stage
      status
      qualified
      job {
        id
        title
        threshold
        templates {
          id
          name
          role {
            id
            name
          }
        }
        role_id
        integration
      }
      candidate {
        id
        firstName
        lastName
        email
      }
    }
    summary {
      id
      threshold
      application_id
      interview_id
      summary {
        header
        icon
        bonuspoints
        average
        details {
          label
          answer
          warning
          symbol
          icon
          question
          points
          bonuspoints
        }
      }
    }
    manual_score {
      id
    }
  }
}
`;
