import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { updateQutterSummaryBlock } from 'features/Qutters/slices/LibrarySlice';
import { findOptionValue } from 'features/common';

import * as INTERVIEW_SUMMARY_CONSTANTS from 'features/InterviewSummary';

export const QutterSummaryDefaultBlocksSelect = ({
  qutter_id
}) => {
  console.log('<QutterSummaryDefaultBlocksSelect />', qutter_id);
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const qutter = qutterConfig[qutterConfig.language.current];

  return (
    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Default Summary Block</span>
      </div>
      <div className='w-50 text-left'>
        <Select
          defaultValue={findOptionValue(INTERVIEW_SUMMARY_CONSTANTS.default_summary_blocks, qutter.presentation.block)}
          options={INTERVIEW_SUMMARY_CONSTANTS.default_summary_blocks}
          onChange={(e) => {
            dispatch(updateQutterSummaryBlock({ id: qutter_id, block: e.value }));
          }}
        />
      </div>
    </div>
  );
};
