import { initiateEvaluation, setDefaultEvaluation } from "..";

export const date_context = async (
  dispatch,
  library,
  template,
  eval_state,
  node,
  is_followup
) => {
  const qutter_config = library[node.id];
  const languages = template.language_settings.list;

  dispatch(initiateEvaluation({
    languages,
    qutter_id: node.id,
    is_followup: is_followup
  }));

  languages.map(async language => {
    const qutter = qutter_config[language];
    if (!qutter) return;

    const default_eval = qutter_config.evaluation;
    dispatch(setDefaultEvaluation({
      language,
      qutter_id: node.id,
      evaluation: default_eval
    }));
  });
};