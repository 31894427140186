import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const QutterAnswerSelectMultiple = ({
  disabled,
  placeholder,
  className,

  answer_list,
  qutter_id,
  on_select,
  selected,
}) => {
  const [selected_answers, set_selected_answers] = useState([]);

  useEffect(() => {
    async function load_selected () {
      if (selected && Array.isArray(selected)) {
        let _answers = [];
        for (let i = 0; i < selected.length; i++) {
          const answer_id = selected[i];
          const fn = (o) => o.value === answer_id;
          const index = await answer_list.findIndex(fn);

          if (index > -1) _answers.push(answer_list[index]);
        }
        set_selected_answers(_answers);
      }
    }
    load_selected();
  }, [selected, answer_list]);

  return (
    <Select
      isMulti={true}
      className={className}
      isDisabled={disabled}

      options={answer_list}
      value={selected_answers}
      placeholder={placeholder ? placeholder: 'Choose answers'}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};

export default QutterAnswerSelectMultiple;
