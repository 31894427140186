import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faCertificate,
  faTimesCircle,
  faStarHalf,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons';

import { BonusIcon, InfoIcon, WarningIcon, DisqualifiedIcon, PointIcon } from 'features/QutterEvaluation';

function isObject (item) {
  return (typeof item === "object" && !Array.isArray(item) && item !== null);
}

export const EvaluationIcon = ({ evaluation }) => {
  // console.log(`<EvaluationIcon />`, evaluation);

  if (evaluation.name === 'bonus') {
    return <BonusIcon bonuspoints={evaluation.bonus_points} />
  } else 
  if (evaluation.name === 'disqualified') {
    return <DisqualifiedIcon />
  } else 
  if (evaluation.name === 'warning') {
    return <WarningIcon />
  } else 
  if (evaluation.name === 'point') {
    return <PointIcon />
  } else 
  if (evaluation.name === 'info') {
    return <InfoIcon />
  } else  {
    return <InfoIcon />
  }
};
