import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Tab, Tabs } from 'react-bootstrap';

import { RuleName, RuleConditions, RuleActions, DeleteRuleButton, AndOrSelect } from '../..';

import { deleteCustomRuleByIndex, customRuleUpdate, customRuleActionUpdate } from 'features/Parameters';
import { deleteRuleByIndex, ruleUpdate } from 'features/Qutters';

export const QutterRule = ({
  template_id,
  qutter_id,
  rule,
  rule_idx,
  is_default
}) => {
  const dispatch = useDispatch();
  const [rule_type, set_rule_type] = useState(rule.type);

  return (
  <div
    className='mb-1 p-1'
    style={{ border: '1px solid lightgray', borderRadius: '3px' }}
  >

    <div className='d-flex m-1'>
      <div className='w-75 text-start m-auto'>
        <RuleName
          template_id={template_id}
          qutter_id={qutter_id}
          rule={rule}
          rule_idx={rule_idx}
          is_default={is_default}
        />
      </div>

      <div className='w-25 text-end'>
        <DeleteRuleButton
          template_id={template_id}
          qutter_id={qutter_id}
          is_default={is_default}
          on_click={() => {
            if (template_id) {
              dispatch(deleteCustomRuleByIndex({ template_id, qutter_id, rule_idx }))
            } else {
              dispatch(deleteRuleByIndex({ qutter_id, rule_idx }))
            }
          }}
        />
      </div>
    </div>

    {rule.conditions && rule.conditions.length >= 2 ? (
      <div className='d-flex m-2'>
        <div className='w-75 text-start m-auto'>
          And // OR
        </div>
        <div className='w-25 text-end'>
          <AndOrSelect
            selected={rule.operator}
            on_select={(e) => {
              let r = Object.assign({}, rule);
              r.operator = e.value;
              if (template_id) {
                dispatch(customRuleUpdate({ template_id, qutter_id, rule_idx, rule: r }))
              } else {
                dispatch(ruleUpdate({ qutter_id, rule_idx, rule: r }))
              }
            }}
          />
        </div>
      </div>
    ): (null)}

    {template_id ? (
      <Tabs
        defaultActiveKey={rule_type}
        className="mb-3"
      >
        <Tab
          title="Show this if"
          eventKey="SHOW_THIS_IF"
          onEnter={() => { set_rule_type('SHOW_THIS_IF'); }}
          disabled={rule_type !== "SHOW_THIS_IF"}
        >
          <RuleConditions
            template_id={template_id}
            qutter_id={qutter_id}
            is_default={is_default}
            rule={rule}
            rule_idx={rule_idx}
            rule_type={rule_type}
          />
        </Tab>
        <Tab
          title="Do this if answer is"
          eventKey="DO_THIS_IF"
          disabled={rule_type !== "DO_THIS_IF"}
          onEnter={() => { set_rule_type('DO_THIS_IF'); }}
        >
          <div>
            <RuleConditions
              template_id={template_id}
              qutter_id={qutter_id}
              is_default={is_default}
              rule={rule}
              rule_idx={rule_idx}
              rule_type={rule_type}
            />

            <RuleActions
              template_id={template_id}
              qutter_id={qutter_id}
              is_default={is_default}
              rule={rule}
              rule_idx={rule_idx}
            />
          </div>
        </Tab>
      </Tabs>
    ):(
      <div>
        <RuleConditions
          template_id={template_id}
          qutter_id={qutter_id}
          is_default={is_default}
          rule={rule}
          rule_idx={rule_idx}
          rule_type={rule_type}
        />

        <RuleActions
          template_id={template_id}
          qutter_id={qutter_id}
          is_default={is_default}
          rule={rule}
          rule_idx={rule_idx}
        />
      </div>
    )}

  </div>
  );
};


/**
if (template_id) {
  if (is_default) {
    return (null)
  } else {
    return (
    );
  }
} else {
  return (
  );
}
 */