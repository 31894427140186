import { nanoid } from '@reduxjs/toolkit';

export const baseIntent = {
  intent:     'affirm',
  value:      'affirm',
  id:         'affirm',
  replies:    [
    'utter_confirm',
    'next'
  ],
  evaluation:  'info',
  jumps_to:    []
};

export const baseOption = {
  id:          nanoid(),
  label:       'Label: Edit this.',
  replies:     ['utter_confirm'],
  evaluation:  'info',
  jumps_to:    []
};

export const initialInfo =  {
  id: 'create-info',
  type: 'info',
  language: {
    current: 'en-gb',
    list: [
      'sv-se', 
      'en-gb', 
      'en-us', 
      'es-es', 
      'es-mx', 
      'fr-fr', 
      'da-dk', 
      'de-de', 
      'fi-fi', 
      'no-no',
      'it-it',
    ]
  },
  global:     true,
  tags:  [],
  roles: [],
  pre_actions:  [],
  post_actions: [],
  'sv-se': {
    type: 'info',
    text: ['Tjena!'],
    language: 'sv-se',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    }
  }, 
  'en-gb': {
    type: 'info',
    text: ['Goodday Sir!'],
    language: 'en-gb',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    },
  }, 
  'en-us': {
    type: 'info',
    text: ['Howdie!'],
    language: 'en-us',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    },
  }, 
  'es-es': {
    type: 'info',
    text: ['¡Hola!'],
    language: 'es-es',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    },
  },
  'es-mx': {
    type: 'info',
    text: ['¡Hola!'],
    language: 'es-mx',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    },
  },
  'fr-fr': {
    type: 'info',
    text: ['Bonjour!'],
    language: 'fr-fr',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    },
  }, 
  'da-dk': {
    type: 'info',
    text: ['Hej skat'],
    language: 'da-dk',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    },
  }, 
  'de-de': {
    type: 'info',
    text: ['Guten tag'],
    language: 'de-de',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    },
  },
  'fi-fi': {
    type: 'info',
    text: [ 'Terve!' ],
    language: 'fi-fi',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    },
  }, 
  'no-no': {
    type: 'info',
    text: [ 'Ut på tur!' ],
    language: 'no-no',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    },
  },
  'it-it': {
    type: 'info',
    text: [ 'Ciao' ],
    language: 'it-it',
    presentation: {
      id:     null,
      block:  '',
      label:  ''
    },
  }
};

export const initialChoice =  {
  id: 'create-choice',
  type: 'single-select',
  language: {
    current: 'en-gb',
    list: [
      'sv-se', 
      'en-gb', 
      'en-us', 
      'es-es', 
      'es-mx', 
      'fr-fr', 
      'da-dk', 
      'de-de', 
      'fi-fi', 
      'no-no',
      'it-it',
    ]
  },
  global: true,
  client_id: false,
  tags:  [],
  roles: [],
  pre_actions: [],
  post_actions: [],
  is_star_question: false,
  is_reusable: false,
  'sv-se': {
    type: 'single-select',
    intro: [], text:  [], outro: [], clarify: [],
    language: 'sv-se',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'en-gb': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'en-gb',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'en-us': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'en-us',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'es-es': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'es-es',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'es-mx': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'es-mx',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'fr-fr': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'fr-fr',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'da-dk': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'da-dk',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'de-de': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'de-de',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'fi-fi': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'fi-fi',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'no-no': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'no-no',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'it-it': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'it-it',
    options: [{ ...baseOption }],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }
};

export const initialCreateYesNo =  {
  id: 'create-yesno',
  type: 'single-select',
  language: {
    current: 'sv-se',
    list: [
      'sv-se', 
      'en-gb', 
      'en-us', 
      'es-es', 
      'es-mx', 
      'fr-fr', 
      'da-dk', 
      'de-de', 
      'fi-fi', 
      'no-no',
      'it-it'
    ]
  },
  global: true,
  client_id: false,
  tags:  [],
  roles: [],
  pre_actions: [],
  post_actions: [],
  is_star_question: false,
  is_reusable: false,
  'sv-se': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'sv-se',
    options: [
      {
        id:          nanoid(),
        label:       'Ja',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'Nej',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'en-gb': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'en-gb',
    options: [
      {
        id:          nanoid(),
        label:       'Yes',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'No',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'en-us': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'en-us',
    options: [
      {
        id:          nanoid(),
        label:       'Yes',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'No',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'es-es': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'es-es',
    options: [
      {
        id:          nanoid(),
        label:       'Sí',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'No',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'es-mx': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'es-mx',
    options: [
      {
        id:          nanoid(),
        label:       'Sí',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'No',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'fr-fr': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'fr-fr',
    options: [
      {
        id:          nanoid(),
        label:       'Oui',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'Non',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'fi-fi': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'fi-fi',
    options: [
      {
        id:          nanoid(),
        label:       'Joo',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'Ei',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      block: '',
      label: ''
    }
  },
  'de-de': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'de-de',
    options: [
      {
        id:          nanoid(),
        label:       'Ja',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'Nein',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'da-dk': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'da-dk',
    options: [
      {
        id:          nanoid(),
        label:       'Ja',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'Ingen',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'no-no': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'no-no',
    options: [
      {
        id:          nanoid(),
        label:       'Ja',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'Nei',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'it-it': {
    type: 'single-select',
    intro: [], text: [], outro: [], clarify: [],
    language: 'it-it',
    options: [
      {
        id:          nanoid(),
        label:       'Si',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      },
      {
        id:          nanoid(),
        label:       'No',
        replies:     ['utter_confirm'],
        evaluation:  'info',
        jumps_to:    []
      }
    ],
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
};

export const initialCreateOpen = {
  id: 'create-open',
  type: 'open',
  language: {
    current: 'sv-se',
    list: [
      'sv-se', 
      'en-gb', 
      'en-us', 
      'es-es', 
      'es-mx', 
      'fr-fr', 
      'da-dk', 
      'de-de', 
      'fi-fi', 
      'no-no',
      'it-it'
    ]
  },
  global: true,
  client_id: false,
  tags:  [],
  roles: [],
  pre_actions: [],
  post_actions: [],
  is_star_question: false,
  is_reusable: false,
  'sv-se': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'sv-se',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  }, 
  'en-gb': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'en-gb',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  },
  'en-us': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'en-us',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  }, 
  'es-es': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'es-es',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  }, 
  'es-mx': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'es-mx',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  }, 
  'fr-fr': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'fr-fr',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  }, 
  'da-dk': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'da-dk',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  }, 
  'de-de': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'de-de',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  },
  'fi-fi': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'fi-fi',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  }, 
  'no-no': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'no-no',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  },
  'it-it': {
    type: 'open',
    intro: [], text: [], outro: [], clarify: [],
    language: 'it-it',
    intents: [{ ...baseIntent }],
    presentation: {
      id: null,
      block: '',
      label: '',
      example_answer: ''
    },
  }
};


export const initialCreateCalendar = {
  id: 'create-calendar',
  type: 'date-picker',
  language: {
    current: 'sv-se',
    list: [
      'sv-se',
      'en-gb',
      'en-us', 
      'es-es', 
      'es-mx', 
      'fr-fr', 
      'da-dk', 
      'de-de', 
      'fi-fi', 
      'no-no',
      'it-it'
    ]
  },
  global: true,
  client_id: false,
  tags:  [],
  roles: [],
  pre_actions: [],
  post_actions: [],
  is_star_question: false,
  is_reusable: false,
  presentation: {
    id: null,
    block: '',
    label: ''
  },
  'sv-se': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'sv-se',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'en-gb': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'en-gb',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'en-us': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'en-us',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'es-es': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'es-es',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'es-mx': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'es-mx',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'fr-fr': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'fr-fr',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'da-dk': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'da-dk',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'de-de': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'de',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'fi-fi': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'fi-fi',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }, 
  'no-no': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'no-no',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  },
  'it-it': {
    type: 'date-picker',
    intro: [], text: [], outro: [], clarify: [],
    language: 'it-it',
    presentation: {
      id: null,
      block: '',
      label: ''
    },
  }
};
