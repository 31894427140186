import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

import { FormSliderPreview } from './FormSliderPreview';
import { PreviewContainer } from '../../PreviewContainer';

import { 
  updateFormByIndex
} from 'features/Qutters/slices/LibrarySlice';

export const FormSlider = ({ qutter_id, form_idx }) => {
  console.log('<FormSlider />');
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  const form = forms[form_idx];

  const min = form.min ? form.min: 0;
  const max = form.max ? form.max: 100;
  const step = form.step ? form.step: 1;
  const [value, set_value] = useState(0);

  const onSliderChange = value => {
    console.log(value);
    set_value(value)
  };

  return (
  <div>
    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Label</span>
      </div>
      <div className='w-50 text-left'>
        <Form.Control
          defaultValue={form.label}
          type="text"
          placeholder="Label"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.label = e.target.value;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
        />
      </div>
    </div>

    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Label Left/Right</span>
      </div>
      <div className='w-50 text-left'>
        <Form.Control
          defaultValue={form.label_left}
          className='mb-1'
          type="text"
          placeholder="Label Left"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.label_left = e.target.value;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
        />
        <Form.Control
          defaultValue={form.label_right}
          type="text"
          placeholder="Label Right"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.label_right = e.target.value;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
        />
      </div>
    </div>

    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Unit</span>
      </div>
      <div className='w-50 text-left'>
        <Form.Control
          defaultValue={form.unit}
          type="text" 
          placeholder="$$$"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.unit = e.target.value;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
        />
      </div>
    </div>

    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Min/Max/Step</span>
      </div>
      <div className='w-50 text-start'>

        <Form.Control
          defaultValue={form.min}
          className='d-inline me-2'
          type="number"
          placeholder="0"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.min = parseInt(e.target.value);
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
          style={{ width: '30%' }}
        />

        <Form.Control
          defaultValue={form.max}
          type="number"
          className='d-inline m-1'
          placeholder="100"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.max = parseInt(e.target.value);
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
          style={{ width: '30%' }}
        />

        <Form.Control
          defaultValue={isNaN(form.step) ? 1: form.step}
          type="number"
          step={isNaN(form.step) || form.step === 0 ? 1: form.step}
          className='d-inline m-1'
          placeholder="1"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.step = parseFloat(e.target.value);
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
          style={{ width: '30%' }}
        />

      </div>
    </div>

    <PreviewContainer>
      <FormSliderPreview qutter_id={qutter_id} form_idx={form_idx} />
    </PreviewContainer>

  </div>
  );
}
