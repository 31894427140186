import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  max_bonus_points: 0,
  max_weighted: 0,
  initial_weight: 0,
  weighted_total: 0,
  total: 0,
  per_qutter: {}
};

const ScoringSlice = createSlice({
  name: 'scoring',
  initialState,
  reducers: {
    initScoring (state, action) {
      const {
        chat,

        list,
        details,

        bonuspoints_sum,
        bonuspoints_max,

        weighted_sum,
        weighted_max,

        qualified,
        total,
      } = action.payload;

      return {
        ...state,
        chat,

        list,
        details,

        bonuspoints_sum,
        bonuspoints_max,

        weighted_sum,
        weighted_max,

        qualified,
        total,
      }
    },
    updateQutterScore (state, action) {
      const {
        id,
        scale_max,
        value
      } = action.payload;
      const detail = state.details[id];
      if (detail) {
        detail.edited = true;
        detail.value = value;
        console.log('detail', detail)

        if (!detail) return;

        function zero_to_hundred (value, old_min, old_max) {
          let old_range = old_max - old_min;
          let new_range = 100 - 0;
          return (((value - old_min) * new_range) / old_range)
        }
        
        if (detail.is_weighted === true) {
          let score = 
            (state.weighted_max * detail.weight) * 
            (parseFloat(value)/scale_max);
          let displayed_score = zero_to_hundred (score, 0, (state.weighted_max * detail.weight));

          detail.displayed_score = displayed_score;
          detail.score = score;
        } else {
          detail.score = value;
        }
      }
    },
    updateChatDetail (state, action) {
      const {
        id,
        category_id
      } = action.payload;
      let detail = null;
      if (category_id)
        detail = state.details[category_id];
      else
        detail = state.details[id];
      const idx = state.chat.findIndex((msg) => msg._id === id);

      if (idx > -1 && detail) {
        const obj = state.chat[idx];
        state.chat[idx] = {
          ...obj,
          detail: detail
        }
      }
    },
    updateTotals (state, action) {
      const list = state.list;
      let total = 0;
      let weighted_sum = 0;
      let bonuspoints_sum = 0;

      for (let i = 0; i < list.length; i++) {
        const id = list[i];
        const detail = state.details[id];
        total += detail.score;
        if (detail.is_weighted === true) {
          weighted_sum += detail.score;
        } else {
          bonuspoints_sum += detail.score;
        }
      }

      return {
        ...state,
        bonuspoints_sum,
        weighted_sum,
        total,
      }
    },
  }
});

export const { 
  initScoring,
  updateQutterScore,
  updateTotals,
  updateChatDetail
} = ScoringSlice.actions;
export default ScoringSlice.reducer;
