import React from 'react';
import { QutterTypeSelectSingle } from './QutterTypeSelectSingle';
import { QutterTypeSelectMultiple } from './QutterTypeSelectMultiple';

export const QutterTypeSelect = ({
  multi,
  on_select,
  selected,
  selected_type,
  disabled,
  placeholder,
  className
}) => {

  if (multi) {
    return (
      <QutterTypeSelectMultiple
        on_select={on_select}
        selected={selected}
        disabled={disabled}
        placeholder={placeholder}
        className={className}
      />
    );
  }

  return (
    <QutterTypeSelectSingle
      on_select={on_select}
      selected_type={selected_type}
      disabled={disabled}
      placeholder={placeholder}
      className={className}
    />
  );
};
