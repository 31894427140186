import { createSlice } from '@reduxjs/toolkit';

import {
  ResetEvaluation,
  InitiateEvaluation,
  RemoveEvaluation,
  SetDefaultEvaluation,
  SetCustomEvaluation,
  UpdateScoringCategories,
  UpdateCategoryWeight
} from '../reducers/reducers';

const initialState = {
  scoring_categories: {
    list: []
  }
};
/**
{
  [qutter_id]: {

  }
};
*/

const EvaluationSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    resetEvaluation: ResetEvaluation,
    initiateEvaluation: InitiateEvaluation,
    removeEvaluation: RemoveEvaluation,
    setDefaultEvaluation: SetDefaultEvaluation,
    setCustomEvaluation: SetCustomEvaluation,
    updateScoringCategories: UpdateScoringCategories,
    updateCategoryWeight: UpdateCategoryWeight,
  }
});

export const {
  resetEvaluation,
  initiateEvaluation,
  removeEvaluation,
  setDefaultEvaluation,
  setCustomEvaluation,
  updateScoringCategories,
  updateCategoryWeight
} = EvaluationSlice.actions;
export default EvaluationSlice.reducer;
