import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import TemplateQutterSelectSingle from './TemplateQutterSelectSingle';
import TemplateQutterSelectMultiple from './TemplateQutterSelectMultiple';

import { prepare_qutter_list } from '../utils/utils';

export const TemplateQutterSelect = ({
  multi,
  disabled,
  placeholder,
  className,

  on_select,
  template_id,
  current_qutter_id,
  selected,
}) => {

  const [loading, set_loading] = useState(true);
  const [list, set_list] = useState([]);

  const parameters = useSelector(state => state.Parameters[template_id]);
  const outline = useSelector(state => state.TemplateOutline[template_id]);
  const library = useSelector(state => state.Library);

  useEffect(() => {
    async function load_list () {
      const l = await prepare_qutter_list(library, outline.qutter_list, current_qutter_id)
      set_list(l);
      set_loading(false);
    }
    load_list();
  }, []);

  if (loading) return (<span>Loading templates question list...</span>);

  if (multi) {
    return (
      <TemplateQutterSelectMultiple
        className={className}
        disabled={disabled}
        placeholder={placeholder}

        on_select={on_select}
        selected={selected}
        list={list}
      />
    );
  }

  return (
    <TemplateQutterSelectSingle
      className={className}
      disabled={disabled}
      placeholder={placeholder}

      on_select={on_select}
      selected={selected}
      list={list}
    />
  );
};
