import { nanoid } from '@reduxjs/toolkit';
import { interview_actions_list } from 'features/HubertActions';

export function OptionPlus (state, action){
  const { id } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].options.push({
      id:          nanoid(),
      label:       'Option Label: Edit this.',
      replies:     ['utter_confirm'],
      jumps_to:     [],
      evaluation:  'info'
    });
  }
};

export function OptionMinus (state, action){
  const { id, option_idx } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].options.splice(option_idx, 1);
  }
};

export function OptionLabelChange (state, action){
  const { id, option_idx, label } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].options[option_idx].label = label;
  }
};

export function OptionEvaluationChange (state, action){
  const { id, option_idx, evaluation } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].options[option_idx].evaluation = evaluation;
    if (evaluation !== 'bonus')
      delete qutter[language.current].options[option_idx].bonus_points;
    if (evaluation !== 'weight')
      delete qutter[language.current].options[option_idx].weight;

    if (evaluation === 'bonus')
      qutter[language.current].options[option_idx].bonus_points = 0;
    if (evaluation === 'weight')
      qutter[language.current].options[option_idx].weight = 0;
  }
};

export function OptionBonusChange (state, action){
  const { id, option_idx, bonus_points } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    if (!bonus_points || [null, undefined].includes(bonus_points))
      qutter[language.current].options[option_idx].bonus_points = 0;
    else
      qutter[language.current].options[option_idx].bonus_points = parseInt(bonus_points);
  }
}

export function OptionWeightChange (state, action){
  const { id, option_idx, weight } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    if (!weight || [null, undefined].includes(weight))
      qutter[language.current].options[option_idx].weight = 0;
    else
      qutter[language.current].options[option_idx].weight = parseInt(weight);
  }
}

export function OptionReplyMinus (state, action) {
  const { id, option_idx, reply_idx } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].options[option_idx].replies.splice(reply_idx, 1);
  }
}

export function OptionReplyPlus (state, action) {
  const { id, option_idx, reply_idx } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].options[option_idx].replies.splice((reply_idx + 1), 0, 'Edit this.');
  }
}

export function OptionReplyChange (state, action) {
  const { id, option_idx, reply_idx, text } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].options[option_idx].replies[reply_idx] = text;
  }
}

export function OptionIsMultipleSelectChange (state, action) {
  const { id, tf } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    if (tf === true)
      qutter[language.current].type = 'multiple-select';
    else
      qutter[language.current].type = 'single-select';
  }
};

export function OptionOtherChange (state, action) {
  const { id, tf } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    if (tf === true) {
      let idx = qutter[language.current].options.findIndex(option => option.id === 'other');
      if (idx === -1) {
        qutter[language.current].options.push({
          id: 'other',
          value: 'other',
          label: 'Other',
          evaluation: 'info',
          replies: [''],
          action: null,
          jumps_to: []
        });
      }
    } else {
      let idx = qutter[language.current].options.findIndex(option => option.id === 'other');
      if (idx > -1) {
        qutter[language.current].options.splice(idx, 1);
      }
    }
  }
};

export function OptionNoneAboveChange (state, action) {
  const { id, tf } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    if (tf === true) {
      let idx = qutter[language.current].options.findIndex(option => option.id === 'none-above');
      if (idx === -1) {
        qutter[language.current].options.push({
          id: 'none-above',
          value: 'none-above',
          label: 'None of the above',
          evaluation: 'info',
          replies: [''],
          unset: true,
          action: null,
          jumps_to: []
        });
      }
    } else {
      let idx = qutter[language.current].options.findIndex(option => option.id === 'none-above');
      if (idx > -1) {
        qutter[language.current].options.splice(idx, 1);
      }
    }
  }
};

export function OptionAllAboveChange (state, action) {
  const { id, tf } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    if (tf === true) {
      let idx = qutter[language.current].options.findIndex(option => option.id === 'all-above');
      if (idx === -1) {
        qutter[language.current].options.push({
          id: 'all-above',
          value: 'all-above',
          label: 'All of the above',
          evaluation: 'info',
          replies: [''],
          unset: true,
          action: null,
          jumps_to: []
        });
      }
    } else {
      let idx = qutter[language.current].options.findIndex(option => option.id === 'all-above');
      if (idx > -1) {
        qutter[language.current].options.splice(idx, 1);
      }
    }
  }
};

export function OptionDefaultFollowup (state, action) {
  const { id, option_idx, followup_id } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    if (!('jumps_to' in qutter[language.current].options[option_idx])) {
      qutter[language.current].options[option_idx].jumps_to = [];
    }
    qutter[language.current].options[option_idx].jumps_to.push(followup_id);
    qutter[language.current].has_followups = true;
  }
};

export function OptionRemoveDefaultFollowup (state, action) {
  const { 
    qutter_id, 
    option_idx, 
    followup_id 
  } = action.payload;

  const qutter = state[qutter_id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    const option = qutter[language.current].options[option_idx];
    if (!('jumps_to' in option)) {
      return;
    }
    let jumps_to = option.jumps_to;
    let idx = jumps_to.indexOf(followup_id);
    if (idx > -1) {
      jumps_to.splice(idx, 1);
    }
  }
};

export function OptionActionPlus (state, action){
  const { id, option_idx } = action.payload;
  const qutter = state[id];
  const option = qutter[qutter.language.current].options[option_idx];

  if (option.hasOwnProperty('actions')) {
    qutter[qutter.language.current].options[option_idx].actions.push(interview_actions_list[0].value);
  } else {
    qutter[qutter.language.current].options[option_idx].actions = [interview_actions_list[0].value];
  }
};

export function OptionActionMinus (state, action){
  const { id, option_idx, option_action_idx } = action.payload;
  const qutter = state[id];
  const option = qutter[qutter.language.current].options[option_idx];
  option.actions.splice(option_action_idx, 1);
};

export function OptionActionUpdate (state, action){
  const { id, option_idx, option_action_idx, value } = action.payload;
  const qutter = state[id];
  const option = qutter[qutter.language.current].options[option_idx];
  option.actions[option_action_idx] = value;
};

export function OptionHubiverseTagsPlus (state, action){
  const { id, option_id, hubiverse_tag_id } = action.payload;
  const qutter = state[id];
  const option = qutter[qutter.language.current].options.find(o => o.id === option_id);
  if (option.hasOwnProperty('hubiverse_tags')) {
    const idx = option.hubiverse_tags.findIndex(t => t.id === hubiverse_tag_id);
    if (idx === -1)
      option.hubiverse_tags.push(hubiverse_tag_id);
  } else {
    option.hubiverse_tags = [hubiverse_tag_id];
  }
};

export function OptionHubiverseTagsMinus (state, action){
  const { id, option_id, hubiverse_tag_id } = action.payload;
  const qutter = state[id];
  const option = qutter[qutter.language.current].options.find(o => o.id === option_id);
  if (option.hasOwnProperty('hubiverse_tags') && option.hubiverse_tags.length > 0) {
    const idx = option.hubiverse_tags.findIndex(t => t.id === hubiverse_tag_id);
    if (idx > -1) {
      option.hubiverse_tags.splice(idx, 1);
    }
  }
};