import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Form } from 'react-bootstrap';

import { FormMatrixRow } from './FormMatrixRow';
import { FormMatrixColumn } from './FormMatrixColumn';
import { FormMatrixPreview } from './FormMatrixPreview';

import {
  initForm,
  updateFormByIndex,
  addFormMatrixRowAtIndex,
  addFormMatrixColumnAtIndex
} from 'features/Qutters/slices/LibrarySlice';

export const FormMatrix = ({ qutter_id, form_idx }) => {
  // console.log('<FormMatrix />');
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  const form = forms[form_idx];

  useEffect(() => {
    // console.log('init form');
    // dispatch(initForm({
    //   qutter_id, 
    //   form_idx, 
    //   form: {
    //     type:         'matrix',
    //     label:        null,
    //     help_text:    null,
    //     is_mandatory: false,
    //     rows:     [],
    //     columns:  [],
    //     validator:    null,
    //     hubiverse_property: null,
    //     candidate_property: null,
    //   }
    // }));
  }, [qutter_config, qutter, form]);


  return (
  <div>

    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Label</span>
      </div>
      <div className='w-50 text-left'>
        <Form.Control
          defaultValue={form.label}
          type="text"
          placeholder="Label"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.label = e.target.value;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
        />
      </div>
    </div>

    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Help text</span>
      </div>
      <div className='w-50 text-left'>
        <Form.Control
          defaultValue={form.help_text}
          type="text"
          placeholder="Label"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.help_text = e.target.value;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
        />
      </div>
    </div>

    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Header Left/Right</span>
      </div>
      <div className='w-50 text-left'>
        <Form.Control
          defaultValue={form.label_left}
          className='mb-1'
          type="text"
          placeholder="Label Left"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.label_left = e.target.value;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
        />
        <Form.Control
          defaultValue={form.label_right}
          type="text"
          placeholder="Label Right"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.label_right = e.target.value;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
        />
      </div>
    </div>

    <div>Rows</div>
    {form.rows && form.rows.length ? (
      form.rows.map((row, row_idx) => {
        return (<FormMatrixRow key={`create-form-matrix-row-${row.id}`} row={row} qutter_id={qutter_id} form_idx={form_idx} row_idx={row_idx} />);
      })
    ):(
      <div className='text-center mt-3'>
        <span
          onClick={() => {
            dispatch(addFormMatrixRowAtIndex({
              qutter_id,
              form_idx,
              row_idx: 0
            }))
          }}
          className='hover-blue font-size-10 m-1'
          >
          <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
          Add Row
        </span>
      </div>
    )}

    <div>Columns</div>
    {form.columns && form.columns.length ? (
      form.columns.map((column, column_idx) => {
        return (<FormMatrixColumn key={`create-form-matrix-column-${column.id}`} column={column} qutter_id={qutter_id} form_idx={form_idx} column_idx={column_idx} />);
      })
    ):(
    <div className='text-center mt-3'>
      <span
        onClick={() => {
          dispatch(addFormMatrixColumnAtIndex({
            qutter_id,
            form_idx,
            column_idx: 0
          }))
        }}
        className='hover-blue font-size-10 m-1'
      >
        <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
        Add Column
      </span>
    </div>
    )}

    <FormMatrixPreview qutter_id={qutter_id} form_idx={form_idx} />

  </div>
  );
}
