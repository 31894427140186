import { nanoid } from '@reduxjs/toolkit';

export function SetCurrentTemplateLanguage (state, action) {
  const { language } = action.payload;
  state.language.current = language;
};

export function UpdateFilteredArray (state, action) {
  const { ids } = action.payload;
  state.filtered = ids;
};

export function AddTemplate (state, action) {
  const { id, template } = action.payload;
  if (!template.hasOwnProperty('parameters')) template.parameters = {};
  state[id] = template;
  state.language = {
    current:            template.language_settings.starting_language,
  //   starting_language:  template.language_settings.starting_language,
  //   list:               template.language_settings.list,
  //   allowed_languages:  template.language_settings.allowed_languages
  };
  state[id].status = 'loading';
};

export function ToggleStatus (state, action) {
  const { id, status } = action.payload;
  state[id].status = status;
};

export function SetReadOnlyMode (state, action) {
  const { mode } = action.payload;
  console.log('SetReadOnlyMode', mode)
  state.readOnlyMode = mode;
};

export function EditTemplateScriptFlag (state, action) {
  const { id, script } = action.payload;
  state[id].script = script;
};

export function EditTemplateClient (state, action) {
  const { id, client_id } = action.payload;
  state[id].client_id = client_id;
}

export function EditTemplateLanguage (state, action) {
  const { id, language, allowed } = action.payload;
  if (language) {
    state[id].language_settings.list = language;
    state[id].language_settings.starting_language = language[0];
  } else if (allowed) {
    const allowed_languages = allowed.map(al => {
      return { code: al };
    });
    state[id].language_settings.allowed_languages = allowed_languages;
  }
}

export function EditTemplateDefault (state, action) {
  const { id, isDefault } = action.payload;
  state[id].default = isDefault;
}

export function EditTemplateRoleId (state, action) {
  const { id, role_id } = action.payload;
  state[id].role.id = role_id;
}

export function EditTemplateRoleName (state, action) {
  const { id, role_name } = action.payload;
  state[id].role.name = role_name;
}

export function EditTemplateName (state, action) {
  const { id, name } = action.payload;
  state[id].name = name;
}

export function EditTemplateDescription (state, action) {
  const { id, description } = action.payload;
  state[id].description = description;
}

export function EditTemplateTitle (state, action) {
  const { id, title } = action.payload;
  state[id].title = title;
}

export function EditTemplateCompanyName (state, action) {
  const { id, name } = action.payload;
  console.log(id, name)
  state[id].company = name;
  state[id].organization.name = name;
}

export function EditTemplateCompanyDepartment (state, action) {
  const { id, department } = action.payload;
  state[id].organization.department = department;
}

export function EditTemplateFormOfEmployment (state, action) {
  const { id, form_of_employment } = action.payload;
  state[id].position.form_of_employment = form_of_employment;
}

export function EditTemplateThreshold (state, action) {
  const { id, threshold } = action.payload;
  state[id].threshold = parseInt(threshold);
}

export function EditTemplateEstimatedLength (state, action) {
  const { id, length } = action.payload;
  state[id].estimated_length = parseInt(length);
}

export function EditTemplatePublished (state, action) {
  const { id, published } = action.payload;
  state[id].published = published;
}

export function EditTemplateImmediatelyNotifyUnscored (state, action) {
  const { id, immediately_notify_unscored } = action.payload;
  state[id].immediately_notify_unscored = immediately_notify_unscored;
}

export function EditTemplateCandidateAppToggle (state, action) {
  const { id, candidate_app } = action.payload;
  state[id].candidate_app = candidate_app;
}

// Interview Summary
export function EditQutterSummaryViewability (state, action) {
  const {
    id,
    is_default,
    qutter_id,
    viewable,
    block,
    label
  } = action.payload;
  let template = state[id];
  const parameters = template.parameters;

  if (!parameters.hasOwnProperty(qutter_id)) {
    if (viewable === true) {
      parameters[qutter_id] = {
        presentation: { block: '', label: '' }
      };
    } else if (viewable === false) {
      parameters[qutter_id] = { presentation: false };
    }

  } else {
    if ('presentation' in parameters[qutter_id]) {
      parameters[qutter_id].presentation.label = label;
    } else {
      parameters[qutter_id].presentation = { label: label }
    }
  }
}

export function EditQutterSummaryLabel (state, action) {
  const {
    id,
    qutter_id,
    language,
    label
  } = action.payload;
  const template = state[id];
  const parameters = template.parameters;

  if (!(qutter_id in parameters)) {
    parameters[qutter_id] = {
      presentation: { label: label }
    };
  } else {
    if ('presentation' in parameters[qutter_id]) {
      parameters[qutter_id].presentation.label = label;
    } else {
      parameters[qutter_id].presentation = { label: label }
    }
  }
}

export function EditQutterSummaryLabelVisibility (state, action) {
  const {
    id,
    qutter_id,
    language,
    label
  } = action.payload;
}
