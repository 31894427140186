import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

import { InterviewActionsSelect } from 'features/HubertActions';

import {
  qutterPreActionsPlus,
  qutterPreActionsMinus,
  updateQutterPreAction
} from '../../Qutters/slices/LibrarySlice';

export const QutterPreActions = ({
  template_id,
  block_id,
  qutter_id,
}) => {
  // console.log('<QutterPreActions />', qutter_id);
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const actions = qutterConfig.pre_actions;

  return (
  <div>
    <span className='h6 d-block'>Pre actions
      <span
        onClick={(e) => { dispatch(qutterPreActionsPlus({ id: qutter_id })) }}
        className='hover-blue d-block float-end'
      >
        <span className="font-size-10 me-2">
          <FontAwesomeIcon icon={faPlusCircle} />
        </span>
        <span className="font-size-10">
          Add action
        </span>
      </span>
    </span>

    <Table>
      <tbody>
      {actions.map((action, idx) => {
        return (
          <tr
            key={`preaction-${qutter_id}-${idx}`}
          >
            <td>
              <InterviewActionsSelect
                selected={action}
                on_select={(selected) => {
                  console.log('selected', selected)
                  dispatch(updateQutterPreAction({
                    id: qutter_id,
                    idx,
                    pre_action: selected.value
                  }))
                }}
              />
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <span
                onClick={(e) => { dispatch(qutterPreActionsMinus({ id: qutter_id, idx })) }}
                className=''
              >
                <FontAwesomeIcon
                  className='hover-blue'
                  style={{ color: 'red' }} 
                  icon={faCircleXmark}
                />
              </span>
            </td>
          </tr>
        )
      })}
      </tbody>
    </Table>
  </div>
  )
}
