import React from 'react';
import { useSelector } from 'react-redux';

export const WeightedSummary = ({}) => {
  const scoring = useSelector(state => state.scoring);
  // console.log('<BonuspointSummary /> scoring', scoring);

  return (
    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Weighted</span>
      <span className='p-0 text-center'>
        {scoring.weighted_sum.toFixed(1)} / {scoring.weighted_max}
      </span>
    </div>
  );
};
