import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { LANGUAGE_LABELS } from 'features/LanguageSelect/assets/constants';

export const PreviewRuleShowThisIfCondition = ({
  condition,
 }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    if (condition.type === 'interview_property') {
      if (condition.property === 'qualified') {
        if (condition.value === 'qualified') {
          setText('Interview is qualified.');
        } else 
        if (condition.value === 'qualinot_qualifiedfied') {
          setText('Interview is not qualified.');
        } 
        else {
          setText(`Erroneous rule preview: ${condition.id}`);
        }

      } else 
      if (condition.property === 'language') {
        if (condition.operator === 'EQUALS' && condition.value) {
          setText(`Interview language equals ${condition.value}.`);
        } else
        if (condition.operator === 'IS_IN' && condition.value && Array.isArray(condition.value) && condition.value.length) {
          setText(`Interview language any of ${condition.value}.`);
        } else {
          setText(`Erroneous rule preview: ${condition.id}`);
        }
      }
    } else if (condition.type === 'random_number') {
        setText(`random number is ${condition.operator} ${condition.value}`);
    } else {
      setText(`Erroneous rule preview: ${condition.id}`);
    }
  }, [condition]);

  return (
  <div
    key={`preview-rule-condition-${condition.id}`}
    className="ms-4 mb-1"
  >
    {text}
  </div>
  );
};
