import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const MainQuestionsCount = () => {
  const library = useSelector(state => state.Library);
  const Evaluation = useSelector(state => state.Evaluation);
  const template = useSelector(state => state.Template);
  const language = template.language.current;
  const outline = useSelector(state => state.TemplateOutline[language]);

  const [count, set_count] = useState(0);
  const [loading, set_loading] = useState(true);

  let list = [];
  if (outline) list = outline.qutter_list;

  useEffect(() => {
    set_loading(true);

    async function compute_count () {
      let count = 0;

      for (let i = 0; i < list.length; i++) {
        const qutter_id = list[i];
        const qutter_config = library[qutter_id];
        if (!qutter_config) continue;
        if (qutter_config.type !== 'info')
          count += 1;
      }
      return count;
    }

    async function compute () {
      const c = await compute_count();
      set_count(c);
      set_loading(false)
    }

    if (!outline || list === null || list === undefined) return;
    compute();

  }, [outline, library, language]);

  if (loading) return (null);

  return (
  <div className='d-flex font-size-10'>
    <div className='w-75 text-start'>
      Total number of main questions:
    </div>
    <div className='w-25 text-end'>
      {count}
    </div>
  </div>
  );
};
