var accessToken = '';

export const getAccessToken = () => {
  return accessToken;
};

export const setAccessToken = token => {
  accessToken = token;
};

export const fetchAccessToken = () => {
  return new Promise((resolve, reject) => {
    const uri = process.env.REACT_APP_URL ? process.env.REACT_APP_URL + '/refresh_token': 'http://localhost:3001/refresh_token';

    fetch(uri, {
      method: 'POST',
      credentials: 'include'
    })
      .then(async (x) => {
        const { accessToken, user } = await x.json();
        setAccessToken(accessToken);
        resolve({ accessToken, user });
      })
      .catch(err => {
        setAccessToken('');
        console.error(err);
        reject(err);
      });
  });
};
