import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { Card, Row, Col } from 'react-bootstrap';

import CardDropdown from 'components/common/CardDropdown';
import { Dropdown } from 'react-bootstrap';
import { ResetInterviewDropdownItem } from 'features/ResetInterview';
import { DeleteApplicationDropdownItem } from 'features/DeleteApplication';
import { Link } from 'react-router-dom';

import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import SoftBadge from '_components/common/SoftBadge';

import Flag from 'react-world-flags';

import { 
  ApplicationQualified,
  ApplicationScore,
  ApplicationStatus,
  ApplicationStage,
  ApplicationAccepted
} from 'features/Application';
import ApplicationIdsPopover from './ApplicationIdsPopover';

import SummaryCanvasLink from '_components/applications/SummaryCanvas';
import ScoringStatusHistoryPopover from '_components/score/ScoringStatusHistoryPopover';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
// import ApplicationsTableHeader from './ApplicationsTableHeader';
// import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

import { BulkActions } from './BulkActions';

import { GET_APPLICATIONS } from '../api/gql';
// import { columns } from '../assets/columns';

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const languages = {
  'sv-se': <Flag code={'SE'} height="8" />,
  'en-gb': <Flag code={'GB'} height="8" />,
  'en-us': <Flag code={'GB'} height="8" />
};

export const ApplicationsList = ({
  header,
  job_id,
  filter
}) => {
  const [applications, set_applications] = useState([]);
  const { loading, error, data } = useQuery(
    GET_APPLICATIONS, 
    { variables: { job_id: job_id, filter: filter }}
  );

  const columns = [
    {
      accessor: 'fullname',
      Header: 'Candidate',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { candidate, fullname } = rowData.row.original;
        const logo = candidate.logo;
        return (
          <Link to={`/candidates/${candidate.id}`}>
            <Flex alignItems="center">
              {logo ? (
                <Avatar src={logo} name={fullname} size="xl" className="me-2" />
              ) : (
                <Avatar size="xl" name={fullname} className="me-2" />
              )}
              <div className="flex-1">
                <h5 className="mb-0 fs--1">{fullname}</h5>
              </div>
              </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Email',
      Cell: rowData => {
        const { email } = rowData.row.original;
        return <a href={`mailto:${email}`}>{email}</a>;
      }
    },
    {
      accessor: 'score',
      Header: 'Score',
      Cell: rowData => {
        const { score } = rowData.row.original;
        return <ApplicationScore score={score} />
      }
    },
    {
      accessor: 'stage',
      Header: 'Stage',
      Cell: rowData => {
        const { stage } = rowData.row.original;
        return <ApplicationStage stage={stage} />
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: rowData => {
        const { status } = rowData.row.original;
        const { deleted } = rowData.row.original;
        return (<ApplicationStatus status={status} deleted={deleted} />);
      }
    },
    {
      accessor: 'qualified',
      Header: 'Qualified',
      Cell: rowData => {
        const { qualified } = rowData.row.original;
        return (<ApplicationQualified qualified={qualified} />);
      }
    },
    {
      accessor: 'accepted',
      Header: 'Accepted',
      Cell: rowData => {
        const { accepted } = rowData.row.original;
        return (<ApplicationAccepted accepted={accepted} />)
      }
    },
    {
      accessor: 'interviews',
      Header: 'Interviews',
      Cell: rowData => {
        const { id, interviews } = rowData.row.original;
  
        return (
          <>
        {interviews.map((interview, idx) => {
  
          if (interview.summary && interview.summary.id) {
            return (
              <SummaryCanvasLink 
                key={`interview-${idx}`} 
                application_id={id}
                interview_id={interview.id}
                summary_id={interview.summary.id}
              />
            );
          } else 
          {
            return (
              <Link key={`interview-${idx}`} target='_blank' to={`/score/${interview.id}`}>
                <Flex alignItems="center">
                <SoftBadge>
                  Score
                </SoftBadge>
                </Flex>
              </Link>
            );
          }
        })}
        </>
        );
      }
    },
    {
      accessor: 'interview',
      Header: 'Scoring Status',
      Cell: rowData => {
        const { id, interviews } = rowData.row.original;
        return (
          <>
        {interviews.map((interview, idx) => {
          if (interview.scoring_status) {
            return (
              <ScoringStatusHistoryPopover
                status={interview.scoring_status.name}
                history={interview.scoring_status.history}
              />
            );
          } else 
          {
            return (
              <span>-</span>
            );
          }
        })}
        </>
        );
      }
    },
    {
      accessor: 'id',
      Header: 'IDs & Links',
      Cell: rowData => {
        const { id, interviews, candidate } = rowData.row.original;
        return (
          <ApplicationIdsPopover
            application_id={id}
            candidate_id={candidate.id}
            interview_ids={interviews.map((x) => x.id)}
            previewLinks={interviews.map((x) => x.links?.preview ?? `${process.env.REACT_APP_INTERVIEW_BASE_URL}/preview/${x.id}`)}
            interviewLinks={interviews.map((x) => x.links?.interview ?? `${process.env.REACT_APP_INTERVIEW_BASE_URL}/${x.id}`)}
          />
        );
      }
    },
    {
      accessor: 'language',
      Header: 'Language',
      Cell: rowData => {
        const { interviews } = rowData.row.original;
        return (
          <span>
            {interviews.map((interview, idx) => {
              const language = interview.language;
              return (<span key={`interview-language-${idx}`}>{languages[language]}</span>);
            })}
          </span>
        );
      }
    },
    {
      accessor: 'created_at',
      Header: 'Created',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <span>{new Date(created_at).toLocaleDateString("en-UK", options)}</span>;
      }
    },
    {
      accessor: 'none',
      Header: 'Action',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: data => {
        const { interviews, id } = data.row.original;
        return (
          <CardDropdown 
            iconClassName="fs--1" 
            style={{ zIndex: '1000' }}
            btnRevealClass="btn-reveal-sm" 
            drop="start"
          >
            <div className="py-2">
              {interviews.map((interview, idx) => {
                return (
                  <ResetInterviewDropdownItem 
                    application_id={id}
                    interview_id={interview.id}
                    idx={idx}
                  />
                );
              })}
              <Dropdown.Item href="#!">TODO: Send Reinvite</Dropdown.Item>
              <DeleteApplicationDropdownItem 
                application_id={id}
                applications_list={applications}
                set_applications_list={set_applications}
              />
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  useEffect(() => {
    if (data && data.FilterApplicationsByJob) {
      let apps = data.FilterApplicationsByJob.map((application, idx) => {

        let firstname = application.candidate.firstName;
        if (typeof firstname !== 'string') firstname = 'Unknown ';
        else if (typeof firstname === 'string' && !firstname.length) firstname = 'Unknown ';

        let lastname = application.candidate.lastName;
        if (typeof lastname !== 'string') lastname = 'Unknown ';
        else if (typeof lastname === 'string' && !lastname.length) lastname = 'Unknown ';

        return {
          ...application,
          email: application.candidate.email,
          firstname: firstname,
          lastname: lastname,
          fullname: `${firstname} ${lastname}`
        }
      })
      set_applications(apps)
    }
  }, [data]);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  if (!data.FilterApplicationsByJob.length) return '0 result...';

  return (
    <AdvanceTableWrapper
      columns={columns}
      selection={true}
      sortable
      pagination
      perPage={40}

      data={applications}
    >
      <Card className="mb-3">
        <Card.Header>
          {/* <ApplicationsTableHeader header={header} table /> */}
          <Row className="flex-end-center mb-2 mt-2">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table/>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>

        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <AdvanceTableFooter
            rowCount={applications.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />

        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};
