import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import { useParams } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';

import ApplicationsCollapse from '../applications/ApplicationsCollapse';

import { RoleSelect } from 'features/RoleSelect';
import { ClientSelect } from 'features/ClientSelect';
import { AdminSelect } from 'features/AdminSelect';

import JobTitle from './JobTitle';
import JobActive from './JobActive';
import JobThreshold from './JobThreshold';

import IntegrationSmall from '_components/integrations/IntegrationSmall';
import { InviteCandidateModal } from 'features/InviteCandidate';
import { OpenInterviewLink } from 'features/Job';

import { DeleteJob, JobTemplates } from 'features/Job';
import { ApplyPageModalShowButton } from 'features/ApplyPage';
import { CandidateFilterSearchModalShowButton } from 'features/CandidateSearch';

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const QUERY_GET_JOB = gql`
query GetJob($job_id: String) {
  GetJob(job_id: $job_id) {
    id
    title
    created_at
    client_id
    threshold
    active
    role_id
    integration
    version
    is_test_job
    test
    responsible_cs {
      id
      first_name
      last_name
    }
    templates {
      id
      name
      language
      role {
        name
      }
    }
    apply_settings {
      cv
      cl
      country
      linkedin
      city
      postal_code
      street_address
      logo
    }
    scoring_statistics {
      total_applications
      total_interviews
      todos
      manually_updated
      auto_updated
      invited
      wrongly_labeled
    }
  }
}
`;

/**
 * 
  statistics {
    number_applications
    invited
    started
    ended
    new_leads
    next_step
    hired
    not_hired
    qualified
    not_qualified
    todos
    done
  }
*/

const Job = () => {
  let { id } = useParams();
  const [ inviteCandidate, setInviteCandidateModal ] = useState(false);
  const { loading, error, data } = useQuery(
    QUERY_GET_JOB,
    { variables: { job_id: id }}
  );

  useEffect(() => {
    if (job && job.title) {
      document.title = `Job - ${job.title}`;
    }
  }, [data && data.GetJob]);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const job = data.GetJob;
  const responsible_cs = job && job.responsible_cs && job.responsible_cs.id ? job.responsible_cs.id: '';

  return (
    <>

      <InviteCandidateModal
        job_id={id}
        job_title={job.title}
        showModal={inviteCandidate}
        setShowModal={setInviteCandidateModal}
      />

      <Card>
        <Card.Header>
          <Row>
            <Col>
              <div>Created at: {new Date(job.created_at).toLocaleDateString("en-UK", options)}</div>

            </Col>
            <Col className="text-end">
              {/* <DeleteJob job_ids={[job.id]} /> */}
              {job && (job.is_test_job || job.test) ? (
                <div className='badge badge-info'>Test job</div>
              ):(null)}
            </Col>
          </Row>
          <JobTitle job_id={job.id} _title={job.title} />
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>

              <div>Number of applicants: {job.scoring_statistics.total_applications} (#interviews: {job.scoring_statistics.total_interviews})</div>
              <JobThreshold job_id={job.id} _threshold={(job.threshold || job.threshold === 0 ? job.threshold: 0)} />
              <IntegrationSmall integration={job.integration} />
              <JobActive job_id={job.id} _active={(job.active)} />

              <OpenInterviewLink
                job_id={id}
                templates={job.templates}
                version={job.version}
              />

              <ApplyPageModalShowButton
                job_id={job.id}
                apply_settings={job.apply_settings}
              />

              <CandidateFilterSearchModalShowButton
                job_id={job.id}
              />

            </Col>
            <Col>
              <div className="text-end float-end ms-auto">
                <div className='pb-1'>
                  <ClientSelect
                    selected_client_id={job.client_id}
                    job_id={job.id}
                  />
                </div>
                <div className='pb-1'>
                  <RoleSelect 
                    job_id={job && job.id ? job.id: null}
                    role_id={job.role_id}
                  />
                </div>
                <div className='pb-1'>
                  <AdminSelect
                    selected_admin_id={responsible_cs}
                    job_id={job.id}
                  />
                </div>

                <div
                  onClick={(e) => {
                    setInviteCandidateModal(true);
                  }}
                  className='hover-blue font-size-10'
                >
                  <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
                  Add Candidate
                </div>

              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <ApplicationsCollapse 
        header={"Todo's"}
        number_of={job.scoring_statistics.todos}
        job_id={id} 
        filter={'todos'} />
      <ApplicationsCollapse 
        number_of={job.scoring_statistics.manually_updated}
        header={"Manually updated"}
        job_id={id}
        filter={'manually-updated'} />
      <ApplicationsCollapse
        number_of={job.scoring_statistics.auto_updated}
        header={"Auto updated"}
        job_id={id}
        filter={'auto-updated'} />
      <ApplicationsCollapse
        number_of={job.scoring_statistics.invited}
        header={"The others"}
        job_id={id}
        filter={'invited'} />
      <ApplicationsCollapse
        number_of={job.scoring_statistics.wrongly_labeled}
        header={"Wrongly Labeled"}
        job_id={id}
        filter={''} />

      <JobTemplates jobId={id} />
    </>
  );
};

export default Job;
