import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const WeightsInfoIconLow = ({
  containerClass=''
}) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip id="button-tooltip-2">The sum of the weighted questions must be 100. The...</Tooltip>
      }
    >
    {({ ref, ...triggerHandler }) => (
      <span
        className={containerClass}
        ref={ref}
        {...triggerHandler}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
    )}
    </OverlayTrigger>
  );
};

export const WeightsInfoIconHigh = ({
  containerClass=''
}) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip id="button-tooltip-2">The sum of the weighted questions must be 100. The overflow will in this case be spread out on each category.</Tooltip>
      }
    >
    {({ ref, ...triggerHandler }) => (
      <span
        className={containerClass}
        ref={ref}
        {...triggerHandler}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
    )}
    </OverlayTrigger>
  );
};