import React, { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from './AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
// import JobsTableHeader from './JobsTableHeader';

import { CreateJobModalShowButton } from 'features/CreateJob';

function prepareJobData (jobs) {
  let res = jobs.map((job) => {
    return {
      ...job,
      todos: job.scoring_statistics.todos,
      manually_updated: job.scoring_statistics.manually_updated,
      auto_updated: job.scoring_statistics.auto_updated,
      invited: job.scoring_statistics.invited
    }
  });
  return res;
}

const JobsList = ({
  jobs,
  client_id
}) => {
  const [jobsList, setJobsList] = useState((jobs && jobs.length ? jobs: []));

  // console.log('jobs', jobs);
  // console.log('data', data);
  // console.log('client', client);
  // console.log('loading', loading);
  
  return (
    <>

    {(jobsList && jobsList.length) && 
      <AdvanceTableWrapper
        columns={columns}
        data={prepareJobData(jobsList)}
        selection
        sortable
        pagination
        perPage={20}
        sortBy={{
          id: 'todos',
          desc: true
        }}
      >
        <Card className="mb-3">
          <Card.Header>
            <Row className="flex-end-center mb-2 mt-2">
              <Col>
                <h5 className="mb-0">Jobs</h5>
              </Col>
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table/>
              </Col>
              <CreateJobModalShowButton
                client_id={client_id}
              />

            </Row>
          </Card.Header>

          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              // isLoading={loading}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    }
    </>
  );
};

export default JobsList;


const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const columns = [
  {
    accessor: 'title',
    Header: 'Title',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, title } = rowData.row.original;
      return (
        <Link to={`/jobs/${id}`}>
          <h5 className="mb-0 fs--1">{title}</h5>
        </Link>
      );
    }
  },
  {
    accessor: 'invited',
    Header: 'Invited',
    defaultSorted: true,
    Cell: rowData => {
      const { invited } = rowData.row.original;
      return <span>{invited}</span>;
    }
  },
  {
    accessor: 'todos',
    Header: 'Todos',
    Cell: rowData => {
      const { todos } = rowData.row.original;
      return <span>{todos}</span>;
    }
  },
  {
    accessor: 'manually_updated',
    Header: 'Manually Updated',
    Cell: rowData => {
      const { manually_updated } = rowData.row.original;
      return <span>{manually_updated}</span>;
    }
  },
  {
    accessor: 'auto_updated',
    Header: 'Auto Updated',
    Cell: rowData => {
      const { auto_updated } = rowData.row.original;
      return <span>{auto_updated}</span>;
    }
  },
  {
    accessor: 'version',
    Header: 'Template Version',
    Cell: rowData => {
      const { version } = rowData.row.original;
      return <span>{version}</span>;
    }
  },
  {
    accessor: 'responsible_cs',
    Header: 'CS',
    Cell: rowData => {
      const { responsible_cs } = rowData.row.original;
      if (responsible_cs) {
        return (
          <Link to={`/admins/${responsible_cs.id}`}>
            <Flex alignItems="center">
              {responsible_cs.logo ? (
                <Avatar src={responsible_cs.logo} name={responsible_cs.first_name + ' ' + responsible_cs.last_name} size="xl" className="me-2" />
              ) : (
                <Avatar size="xl" name={responsible_cs.first_name + ' ' + responsible_cs.last_name} className="me-2" />
              )}
              <div className="flex-1">
                <h5 className="mb-0 fs--1">{responsible_cs.first_name + ' ' + responsible_cs.last_name}</h5>
              </div>
            </Flex>
          </Link>
        );
      } else {
        return (<span></span>)
      }
    }
  },
  {
    accessor: 'created_at',
    Header: 'Created',
    Cell: rowData => {
      const { created_at } = rowData.row.original;
      return <span>{new Date(created_at).toLocaleDateString("en-UK", options)}</span>;
    }
  },
  // {
  //   accessor: 'none',
  //   Header: '',
  //   disableSortBy: true,
  //   cellProps: {
  //     className: 'text-end'
  //   },
  //   Cell: () => {
  //     return (
  //       <CardDropdown iconClassName="fs--1">
  //         <div className="py-2">
  //           <Dropdown.Item href="#!">Edit</Dropdown.Item>
  //           <Dropdown.Item href="#!">Delete</Dropdown.Item>
  //         </div>
  //       </CardDropdown>
  //     );
  //   }
  // }
];
