import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faExclamationCircle,
  faArrowsUpDown
} from '@fortawesome/pro-solid-svg-icons';

import Select from 'react-select';

import {
  Container,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';

import { move_interview_summary_block } from '../slices/InterviewSummarySlice';

import {
  NOT_VIEWABLE_BLOCK_IN_SUMMARY
} from '..';

const prepositions = [
  { label: 'Before', value: 'before' },
  { label: 'After', value: 'after' }
]

const MoveSummaryBlockPopOver = ({
  template_id,
  block_id,
  block_idx,
}) => {
  const dispatch = useDispatch();
  const summary = useSelector(state => state.InterviewSummary[template_id]);
  console.log('summary', summary)

  const [error, set_error] = useState(false);
  const [preposition, set_preposition] = useState(null);
  const [selected_block, set_selected_block] = useState(null);
  const [block_list, set_block_list] = useState([]);

  useEffect(() => {
    const get_block_list = () => {
      const list = []
      for (let i = 0; i < summary.block_ids.length; i++) {
        let id = summary.block_ids[i];
        if (id === NOT_VIEWABLE_BLOCK_IN_SUMMARY) 
          continue;
        let block = summary.blocks[id];
        list.push({
          label: block.name,
          value: id
        });
      }
      set_block_list(list);
    }

    (async () => {
      await get_block_list();
    })();

  }, []);

  return (
  <Popover 
    id="popover-basic"
    className={`qutter-followup-popover`}
  >
    <Popover.Header className=''>
      <span>Move summary block</span>
    </Popover.Header>

    <Popover.Body
      as={Container}
      className='w-100'
    >

      <div className='d-flex m-1'>
        <div className='w-25 text-start m-auto'>
          Move: 
        </div>
        <div className='w-75 text-start'>
          <Select
            defaultValue={null}
            placeholder=''
            options={prepositions}
            onChange={(e) => {
              console.log(e)
              set_preposition(e.value);
            }}
          />
        </div>
      </div>

      <div className='d-flex m-1'>
        <div className='w-25 text-start m-auto'>
          Block: 
        </div>
        <div className='w-75 text-start'>
          <Select
            defaultValue={null}
            placeholder=''
            options={block_list}
            onChange={(e) => {
              console.log(e)
              set_selected_block(e.value);
            }}
          />
        </div>
      </div>

      <div className='d-flex'>
        <div className='text-start m-auto w-50'>
          <span
            className={`font-size-10 red ${error ? 'd-block': 'd-none'}`}
          >
            <FontAwesomeIcon icon={faExclamationCircle} className='me-1' />
            Choose preposition and block.
          </span>
        </div>
        <div className='text-end m-auto w-50'>
          <span
            className='text-end font-size-10 hover-blue'
            onClick={() => {
              if (!preposition || !selected_block) {
                console.log(preposition, selected_block)
                set_error(true);
                return;
              }

              let to_idx = summary.block_ids.findIndex((id) => id === selected_block)
              if (preposition === 'before')
                to_idx = to_idx - 1;
              else
                to_idx = to_idx + 1;

              if (to_idx < 0)
                to_idx = 0;
              if (to_idx > (summary.block_ids.length - 1))
                to_idx = summary.block_ids.length - 1;

              dispatch(move_interview_summary_block({
                template_id,
                block_id,
                to_idx
              }))
              set_error(false);
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} className='me-1' />
            Move block
          </span>
        </div>
      </div>
    </Popover.Body>
  </Popover>
  )
}

export const MoveInterviewSummaryBlockOverlay = ({
  template_id,
  block_id,
  block_idx,
 }) => {
  console.log('<MoveSummaryBlockOverlay />',template_id,block_id,block_idx);

  return (
    <OverlayTrigger
      trigger={['click']}
      placement='left'
      rootClose
      overlay={
        MoveSummaryBlockPopOver({
          template_id,
          block_id,
          block_idx
        })
      }
    >

      <span 
        className={`hover-blue me-2 ${block_id !== NOT_VIEWABLE_BLOCK_IN_SUMMARY ? 'me-3': ''}`}
        onClick={() => { }}
      >
        <FontAwesomeIcon icon={faArrowsUpDown} className='me-1' />
      </span>
    </OverlayTrigger>
  );
};
