import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import SummaryCanvasLink from '_components/applications/SummaryCanvas';
import SoftBadge from '_components/common/SoftBadge';
import Flex from 'components/common/Flex';

import { 
  ApplicationScore,
  ApplicationQualified,
  ApplicationStatus,
  ApplicationStage,
  ApplicationAccepted
} from 'features/Application';

import { getCandidateById } from 'features/CandidateSearch/api/api';

export const CandidateV2 = () => {
  const { id } = useParams();
  const [loading, setLoading ] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    getCandidateById(id)
    .then((data) => {
      console.log(data);
      setData({
        ...data.candidate,
        applications: [],
      });
      setLoading(false);
    })
    .catch((error) => {
      console.log(error)
    })
  }, [id]);

  if (loading) {
    return (<span>Loading candidate...</span>);
  }

  const firstName = data.firstName;
  const lastName = data.lastName;
  const email = data.email;
  const applications = data.applications;

  return (
    <>
      <h1>{firstName} {lastName}</h1>
      <span>{email}</span>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Job</th>
            <th>Score</th>
            <th>Qualified</th>
            <th>Stage</th>
            <th>Status</th>
            <th>Accepted</th>
          </tr>
        </thead>

        <tbody>
        {applications.map((application, idx) => {
          return (
          <tr key={`row-${idx}`}>
            <td>
              <Link key={`job-${idx}`} target='_blank' to={`/jobs/${application.job.id}`}>
                {application.job.title}
              </Link>
            </td>
            <td>
              <ApplicationScore score={application.score} />
            </td>
            <td>
              <ApplicationQualified qualified={application.qualified} />
            </td>
            <td>
              <ApplicationStatus status={application.status} />
            </td>
            <td>
              <ApplicationStage stage={application.stage} />
            </td>
            <td>
              <ApplicationAccepted accepted={application.accepted} />
            </td>
            <td>
            {application.interviews.map((interview, idx2) => {
              return (
              <>
                {(() => {
                  if (interview.summary && interview.summary.id && interview.summary.id.length) {
                    return (<SummaryCanvasLink summary_id={interview.summary.id} />);
                  } else {
                    return (
                      <Link key={`interview-${idx}`} target='_blank' to={`/score/${interview.id}`}>
                        <Flex alignItems="center">
                        <SoftBadge>
                          Score
                        </SoftBadge>
                        </Flex>
                      </Link>
                    );
                  }
                })()}
              </>)
            })}
            </td>
          </tr>
          )
          })
        }
        </tbody>
      </Table>
    </>
  );
};