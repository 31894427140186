import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

import {
  Container,
  Popover,
  OverlayTrigger,
  Form
} from 'react-bootstrap';

import { addRule } from 'features/Qutters';
import { addCustomRule } from 'features/Parameters';

const NewRulePopOver = ({
  template_id,
  qutter_id,
}) => {
  const dispatch = useDispatch();
  const [error, set_error] = useState(false);
  const [type, set_type] = useState('SHOW_THIS_IF_PREVIOUS');

  const handleChange = e => {
    e.persist();
    set_type(e.target.value);
  };

  const on_add_click = () => {
    if (!type) {
      toast.warning(`You must choose something.`);
      return;
    }
    if (template_id) {
      dispatch(addCustomRule({ qutter_id, type }))
    } else {
      dispatch(addRule({ qutter_id, type }))
    }
  };

  return (
  <Popover 
    id="popover-basic"
    // className={`${show ? '': 'd-none'}`}
  >
    <Popover.Header className=''>
      <span>New Rule</span>
    </Popover.Header>

    <Popover.Body as={Container} className='w-100' >

      {template_id ? (
        <Form.Check
          name='create-rule'
          value={`SHOW_THIS_IF`}
          type={'radio'}
          label={`Show this if`}
          onChange={handleChange}
        />
      ):(null)}

      <Form.Check
        name='create-rule'
        value={`DO_THIS_IF`}
        type={'radio'}
        label={`Do if answer is`}
        onChange={handleChange}
      />

      <div style={{ textAlign: 'right' }}>
        <span
          onClick={on_add_click}
          className='d-block hover-blue font-size-10'
        >
          <FontAwesomeIcon className="me-1" icon={faPlusCircle} />
          Add
        </span>
      </div>

    </Popover.Body>
  </Popover>
  )
};

export const NewRuleOverlay = ({
  template_id,
  qutter_id,
  is_default
 }) => {

  if (template_id && is_default) return null;

  return (
    <OverlayTrigger
      trigger={['click']}
      placement='right'
      rootClose
      overlay={
        NewRulePopOver({
          template_id,
          qutter_id,
        })
      }
    >
      <span className='font-size-10 hover-blue me-2'>
        <FontAwesomeIcon icon={faPlusCircle} className='me-1' />
        New Rule
      </span>
    </OverlayTrigger>
  );
};
