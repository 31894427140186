import React, { useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faBoltLightning, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

import { Row, Col } from 'react-bootstrap';

import { AddNodeOverlay } from 'features/Nodes';

import {
  toggleSearchQutterModal,
  toggleInterviewNodeActionsModal
} from 'features/Modals/slices/ModalSlice';

export const AddQutterToBlock = ({
  template_id,
  block_id,
  node_idx,
  block_idx,
  qutter_id,
  qutter_idx,
}) => {
  const dispatch = useDispatch();
  const templateConfig = useSelector(state => state.Template);
  const template = templateConfig[template_id];
  const is_editable_block = !['start', 'end'].includes(block_id);
  const [show_node, set_show_node] = useState(false);
  const [showSearchQutterModal, setShowSearchQutterModal] = useState(false);
  const block = template.blocks.map[block_id];

  const on_action_add_click = () => {
    dispatch(
      toggleInterviewNodeActionsModal({
        show: true,
        data: {
          template_id,
          node_idx,
          block_id
        }
      })
    );
  };

  const on_question_add_click = () => {
    dispatch(
      toggleSearchQutterModal({
        show: true,
        add_policy: 'add-qutter-to-template-block',
        shouldDisplayCreateQutterButton: false,
        template_id,
        block_id,
        block_name: block.name,
        qutter_idx,
        data: {
          template_id,
          node_idx,
          block_id
        }
      })
    );
  };

  if (templateConfig.readOnlyMode) return null;

  return (
  <Row className='text-center m-auto mt-2 mb-2'>
    <Col>
        {/* <AddNodeOverlay
          show={show_node}
          set_show={set_show_node}
          template_id={template_id} 
          block_id={block_id}
          block_idx={block_idx}
          node_idx={node_idx}
          qutter_idx={qutter_idx}
        /> */}

        <span 
          className="font-size-10 hover-blue me-2"
          onClick={on_action_add_click}
        >
          <FontAwesomeIcon icon={faBoltLightning} className="me-1" />
          Add action
        </span>

        <span 
          className="font-size-10 hover-blue me-2"
          onClick={on_question_add_click}
        >
          <FontAwesomeIcon icon={faQuestionCircle} className="me-1" />
          Add question
        </span>
    </Col>
  </Row>
  );
};