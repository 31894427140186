import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { findOptionValue } from 'features/common';
import { condition_types } from '../../constants/condition_types';

import { QutterConditionByInterviewProperty } from '../QutterConditionByInterviewProperty';
import { RuleConditionByQutterAnswer } from '../RuleConditionByQutterAnswer';

import { ruleConditionUpdate } from 'features/Qutters';
import { customRuleConditionUpdate } from 'features/Parameters';

export const RuleConditionShowIf = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  condition_idx,
  condition,
}) => {
  const dispatch = useDispatch();

  return (
  <div>
    <div className='d-flex font-size-10 m-1'>
      <div className='text-start m-auto'>
        Condition by:
      </div>
      <div className='w-50 text-start'>
        <Select
          defaultValue={condition ? findOptionValue(condition_types, condition.type): null}
          placeholder='Condition type'
          options={condition_types}
          isDisabled={template_id && is_default ? true: false}
          onChange={(e) => {
            let cond = Object.assign({}, condition);
            cond.type = e.value;
            if (template_id) {
              dispatch(customRuleConditionUpdate({
                template_id,
                qutter_id,
                rule_idx, 
                condition_idx,
                condition: cond
              }))
            } else {
              dispatch(ruleConditionUpdate({
                qutter_id,
                rule_idx, 
                condition_idx,
                condition: cond
              }))
            }
          }}
        />
      </div>
    </div>

    {(() => {
      if (condition.type === 'question_answer') {
        return (
          <RuleConditionByQutterAnswer
            template_id={template_id}
            qutter_id={qutter_id}
            is_default={is_default}
            rule_idx={rule_idx}
            condition_idx={condition_idx}
            condition={condition}
          />
        );
      } else
      if (condition.type === 'interview_property') {
        return (
          <QutterConditionByInterviewProperty
            template_id={template_id}
            qutter_id={qutter_id}
            is_default={is_default}
            condition={condition}
            rule_idx={rule_idx}
            condition_idx={condition_idx}
          />
          );
      } else
      if (condition.type === 'candidate_property') {
        return (<div className="candidate_property" />);
      }
    })()}
  </div>
  );
};