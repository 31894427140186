import React from 'react';
import { useSelector } from 'react-redux';
import { LabelPreview } from '../../common/LabelPreview';

export const FormEmailPreview = ({
  qutter_id,
  form_idx 
}) => {
  console.log('<FormEmailPreview />');
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const interviewReceptState = useSelector(state => state.InterviewReceipt);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  const form = forms[form_idx];

  return (
  <div className="form-group">
    <LabelPreview label={form.label} is_mandatory={form.is_mandatory} />
    <input
      type='email'
      placeholder={`${form.placeholder}`}
      className="form-control"
      disabled={interviewReceptState.readOnlyMode ? true : false}
    />
  </div>
  );
}
