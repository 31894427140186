export const initialCreateNewJob =  {
  id: 'createNewJob',
  client_id: '',
  position: {
    name: '',
    form_of_employment: ''
  },
  role: {
    id: '',
    role_id: '',
    name: '',
    role_group: '',
    description: '',
  },
  threshold: 0,
  active: false,
  interview_language: '',
  templates: [],
  responsible_cs: '',
  company: {
    name: '',
    department: ''
  },
  title: {
    internal: '',
    external: '',
  },
  description: '',
  parameters: {},
  published: false
};