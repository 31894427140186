import { CreateQutterYesNo }  from 'features/QutterSelects';
import { CreateOpen }         from 'features/QutterOpen';
import { CreateSelect }       from 'features/QutterSelects';
import { CreateCalendar }     from 'features/QutterCalendar';
import { CreateInfo }         from 'features/QutterInfo';
import { CreateForm }         from 'features/QutterForm';

export const QutterComponents = {
  'create-yesno':     CreateQutterYesNo,
  'create-open':      CreateOpen,
  'create-choice':    CreateSelect,
  'create-info':      CreateInfo,
  'create-calendar':  CreateCalendar,
  'create-form':      CreateForm,

  'single-select':    CreateSelect,
  'multiple-select':  CreateSelect,
  'open':             CreateOpen,
  'info':             CreateInfo,
  'date-picker':      CreateCalendar,
  'month-picker':     CreateCalendar,
  'form':             CreateForm,
};