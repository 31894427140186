import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Form } from 'react-bootstrap';

import {
  updateFormMatrixColumnAtIndex,
  removeFormMatrixColumnAtIndex,
  addFormMatrixColumnAtIndex,
} from 'features/Qutters/slices/LibrarySlice';

export const FormMatrixColumn = ({
  column,
  qutter_id,
  form_idx,
  column_idx
}) => {
  // console.log('<FormMatrixColumn />');
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  const form = forms[form_idx];
  // const column = forms[form_idx].columns[column_idx];

  return (
  <div className='d-flex mt-3'>
    <div className='m-auto text-center' style={{ width: '95%' }}>
      <Form.Control
        defaultValue={column.label}
        className='mb-1'
        type="text"
        placeholder="Column text"
        onChange={(e) => {
          const value = Object.assign({}, column);
          value.label = e.target.value;
          dispatch(updateFormMatrixColumnAtIndex({ 
            qutter_id, 
            form_idx,
            column_idx,
            value
          }));
        }}
      />

    </div>
    <div className='text-end m-auto' style={{ width: '5%' }}>
      <div className='font-size-10 m-auto'>
        <span
          onClick={() => {
            dispatch(removeFormMatrixColumnAtIndex({ 
              qutter_id,
              form_idx,
              column_idx
            }))
          }}
          className='hover-blue d-block'
        >
          <FontAwesomeIcon className="me-2" icon={faMinusCircle} />
        </span>
        <span
          onClick={() => {
            dispatch(addFormMatrixColumnAtIndex({ 
              qutter_id,
              form_idx,
              column_idx
            }))
          }}
          className='hover-blue d-block'
        >
          <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
        </span>
      </div>
    </div>
  </div>
  );
}
