import React from 'react';
import { useSelector } from 'react-redux';

export const WarningsOutline = ({
  loading,
  warnings
}) => {
  // const template = useSelector(state => state.Template[template_id]);
  // const outline = useSelector(state => state.TemplateOutline[template_id]);
  // if (!template || !outline) return null;
  // const warnings = outline.warnings;
  if (loading) return (<span>Counting warnings...</span>);
  if (!warnings) return (<span>Warnings error...</span>);

  return (
  <div className='d-flex font-size-10'>
    <div className='w-75 text-start'>
      Questions w/ warnings
    </div>
    <div className='w-25 text-end'>
      {warnings.ids.length}
    </div>
  </div>
  );
};
