import React from 'react';
import { Modal } from 'react-bootstrap';

import { CreateJobForm } from './CreateJobForm';

export const CreateJobModal = ({
  client_id,
  showModal,
  setShowModal
}) => {

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="small"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className='font-size-10 d-block'>Create job</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">

        <CreateJobForm />

      </Modal.Body>

    </Modal>
  );
};
