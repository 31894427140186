import React from 'react';

export const CandidateMessagesClosed = ({ message }) => {
  // console.log(`<CandidateMessagesClosed />`, message);

  if (message.type === 'gaps') {
    return message.value.map((v) => {
      return (
        <p
          className="mb-0"
          dangerouslySetInnerHTML={{
            __html: `<p class='d-block'>${v.text}</p>`
          }}
        />
      );
    });
  }

  return (
    <p
      className="mb-0 d-block"
      dangerouslySetInnerHTML={{
        __html: message.text
      }}
    />
  );
};
