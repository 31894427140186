import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Badge, Offcanvas } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from '_components/common/SoftBadge';

import JobCardSmall from '../../../_components/jobs/JobCardSmall';

const JobCanvas = ({
  job,
  template_id,
  show
}) => {
  return (
    <Offcanvas
      onClick={(e) => { e.stopPropagation(); }}
      show={show}
      scroll={true}
      backdrop={true}
      data-bs-keyboard="false"
      // className='w-75'
      style={{
        width: '50%',
        height: '100%',
      }}
    >
      <Offcanvas.Body>
        <JobCardSmall
          job={job}
          template_id={template_id}
          scoring_template={true}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export const JobOffCanvasTab = ({
  job,
  template_id
}) => {
  const [show, setShow] = useState(false);
  const onClick = () => { setShow(!show); }

  return (
    <Flex alignItems="center" className='d-inline-flex mx-2'>
      <Badge
        onClick={onClick}
        className='cursor-pointer'
      >
        Job
        <JobCanvas
          job={job}
          template_id={template_id}
          show={show}
          setShow={setShow}
        />
      </Badge>
    </Flex>
  );
};
