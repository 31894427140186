import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useLazyQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

import { Container, Row, Col, Card, Form } from 'react-bootstrap';

import { CandidatesListV2 } from './CandidatesListV2';
import { HubiverseTags } from 'features/HubiverseTags';
import { RoleSelect } from 'features/RoleSelect';
import { MunicipalitySelect } from 'features/MunicipalitySelect';
import { CandidateFilterSearch } from '..';

import { filter_candidates } from '../api/api';
import { ClientSelect } from 'features/ClientSelect';

export const CandidateSearchV2 = () => {
  const [loading, set_loading] = useState(false);
  const [error, set_error] = useState(false);

  const [result, set_result] = useState([]);
  const [text, set_text] = useState('');
  const [clients, set_clients] = useState([]);
  const [roles, set_roles] = useState([]);
  const [municipalities, set_municipalities] = useState([]);
  const [zip_codes, set_zip_codes] = useState([]);
  const [attributes, set_attributes] = useState([]);

  const on_search_click = async () => {
    console.log('Text', text)
    console.log('Roles', roles)
    console.log('Municipalities', municipalities)
    console.log('Attributes', attributes)

    const query = {
      text,
      clients,
      roles,
      municipalities,
      zip_codes,
      attributes
    }

    set_loading(true);
    try {
      const res = await filter_candidates(query);
      set_result(res.interviews);
    } catch {
      console.log('error', error);
      set_result([]);
    } finally {
      set_loading(false);
    }
  };

  if (error) {
    toast.error(`Error: ${error.message}`);
    return <span>Error.</span>;
  }

  return (
  <Container fluid>
    <Row>

      <Col className='ps-1 pe-1'>
        <Card className='mb-2'>
          <Card.Body>

            <Form.Group className="mb-3">
              <Form.Label>Text Search (email or name)</Form.Label>
              <Form.Control
                type="text"
                placeholder='Hit enter to search'
                onChange={(e) => { set_text(e.target.value); }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    onSearchClick();
                  }
                }}
              />
            </Form.Group>

            <div className='mt-4'>
              <h6>Client</h6>
              <ClientSelect
                noDefault={true}
                multi={true}
                on_select={(selected) => {
                  console.log(selected);
                  set_clients(selected.map(x => x.value));
                }}
              />
            </div>

            <div className='mt-4'>
              <h6>Role</h6>
              <RoleSelect
                multi={true}
                on_select={(selected) => {
                  console.log(selected)
                  set_roles(selected.map(s => s.value))
                }}
              />
            </div>

            <div className='mt-4'>
              <h6>Municipality</h6>
              <MunicipalitySelect
                multi={true}
                selected={municipalities}
                on_select={(selected) => {
                  console.log('municipalities', selected)
                  let codes = [];
                  set_municipalities(selected.map(s => {
                    let c = s.codes.map(c => parseInt(c))
                    codes = codes.concat(c)
                    return s.value;
                  }))
                  set_zip_codes(codes)
                }}
              />
            </div>

            <div className='mt-4'>
              <h6>Global attributes</h6>
              <HubiverseTags
                limited={true}
                set_result={set_attributes}
              />
            </div>

            {/*
            <h6>Local attributes</h6>
            <CandidateFilterSearch />
            */}

            <span
              onClick={on_search_click}
              className='hover-blue font-size-10'
            >
              <FontAwesomeIcon className="me-1" icon={faMagnifyingGlass} />
              Search
            </span>

          </Card.Body>
        </Card>
      </Col>

      <Col xs={8} className='ps-1 pe-1'>
        <CandidatesListV2
          loading={loading}
          list={result}
        />
      </Col>

    </Row>
  </Container>
  );
};
