import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

import { Card, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { CandidatesList } from 'features/CandidatesList';
import { CandidateSearchModal } from 'features/Modals';

import { QUERY_FIND_CANDIDATES_BY_STRING } from '../api/gql';

export const CandidateSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [show_advanced_search_modal, set_show_advanced_search_modal] = useState(false);
  const [text_search, set_text_search] = useState('');
  const [searchCandidates, { loading, data, error }] = useLazyQuery(QUERY_FIND_CANDIDATES_BY_STRING, { fetchPolicy: 'network-only' });

  const onSearchClick = () => {
    setIsLoading(true);
    searchCandidates({ variables: { searchString: text_search }});
    setIsLoading(false);
  };

  if (error) {
    toast.error(`Error: ${error.message}`);
    return <span>Error.</span>;
  }

  return (
    <>
    <Card className='mb-2'>
      <Card.Body>

        <Form.Group className="mb-3">
          <Form.Label>Text Search (email or name)</Form.Label>
          <Form.Control 
            type="text"
            placeholder='Hit enter to search'
            onChange={(e) => { set_text_search(e.target.value); }}
            onKeyPress={event => {
              if (event.key === "Enter") {
                onSearchClick();
              }
            }}
          />
        </Form.Group>

        <CandidateSearchModal
          showModal={show_advanced_search_modal}
          setShowModal={set_show_advanced_search_modal}
        />

        <span
          onClick={onSearchClick}
          className='hover-blue font-size-10'
        >
          <FontAwesomeIcon className="me-1" icon={faMagnifyingGlass} />
          Search
        </span>

        <span
          onClick={() => { set_show_advanced_search_modal(!show_advanced_search_modal) }}
          className='hover-blue font-size-10 ms-2'
        >
          <FontAwesomeIcon className="me-1" icon={faMagnifyingGlass} />
          Advanced Search
        </span>

      </Card.Body>
    </Card>


    <CandidatesList 
      list={data && data.SearchCandidate ? data.SearchCandidate : []} 
    />
    </>
  );
};
