import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Row,
  Col,
  Table
} from 'react-bootstrap';

import { SelectOption } from '..';
import { optionPlus } from 'features/Qutters/slices/LibrarySlice';

export const SelectOptions = ({
  id,
}) => {
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[qutterConfig.language.current];

  const onPlusClick = () => {
    dispatch(optionPlus({ id }));
  };

  if (!qutter) return (<span>Loading...</span>);

  return (
    <div>
      {qutter.options && qutter.options.length ? (
        <Table>
          <tbody>
          {qutter.options.map((option, idx) => {
            return (
              <SelectOption
                key={`${id}-option-${option.id}-${idx}`}
                id={id}
                option={option}
                option_idx={idx}
              />
            )
          })}
          </tbody>
        </Table>
      ):(
        <Row className=''>
          <Col className=''>
            <span>No options added.</span>
          </Col>
        </Row>
      )}
    </div>
  );
};
