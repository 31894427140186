import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Form } from 'react-bootstrap';

import { QutterEvaluationSelect } from 'features/QutterEvaluationSelect';
import { OperatorsSelect } from 'features/QutterRules';

import { 
  updateFormByIndex
} from 'features/Qutters/slices/LibrarySlice';

export const FormSliderEvaluationItem = ({
  qutter_id,
  form_idx,
  evaluation,
  evaluationIdx
}) => {
  console.log('<FormSliderEvaluationItem />');
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  const form = forms[form_idx];

  return (
    <div className='w-50 text-start'>
      <div>
        <QutterEvaluationSelect
          selected={evaluation.name}
          // on_select={}
        />
        <OperatorsSelect
          selected={evaluation.condition.operator}
          // on_select={}
        />
      </div>
      <div>

      </div>
    </div>
  );
}
