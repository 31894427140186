import Flag from 'react-world-flags';

// export const available_languages = [
//   'sv-se',
//   'en',
//   'en-us',
//   'en-gb',
//   'es',
//   'fr',
//   'de',
//   'no',
//   'dk',
//   'fi',
// ];

export const LANGUAGE_LABELS = {
  '': '',
  'sv': <span className='ms-2'><Flag code={'SE'} height="8" className='me-1'/> Swedish</span>,
  'sv-se': <span className='ms-2'><Flag code={'SE'} height="8" className='me-1'/> Swedish</span>,
  'en-gb': <span className='ms-2'><Flag code={'GB'} height="8" className='me-1'/>British English</span>,
  'en-us': <span className='ms-2'><Flag code={'US'} height="8" className='me-1'/>American English</span>,
  'es-es': <span className='ms-2'><Flag code={'ES'} height="8" className='me-1'/>Spanish (Spain)</span>,
  'es-mx': <span className='ms-2'><Flag code={'ES'} height="8" className='me-1'/>Spanish (Mexico)</span>,
  'fr-fr': <span className='ms-2'><Flag code={'FR'} height="8" className='me-1'/>French</span>,
  'de-de': <span className='ms-2'><Flag code={'DE'} height="8" className='me-1'/>German (Germany)</span>,
  'no-no': <span className='ms-2'><Flag code={'NO'} height="8" className='me-1'/>Norwegian</span>,
  'da-dk': <span className='ms-2'><Flag code={'DK'} height="8" className='me-1'/>Danish</span>,
  'fi-fi': <span className='ms-2'><Flag code={'FI'} height="8" className='me-1'/>Finnish</span>,
  'it-it': <span className='ms-2'><Flag code={'IT'} height="8" className='me-1'/>Italian</span>,
};

export const LANGUAGE_FLAGS = {
  '': '',
  'sv': <Flag code={'SE'} height="12" className='me-1'/>,
  'sv-se': <Flag code={'SE'} height="12" className='me-1'/>,
  'en-gb': <Flag code={'GB'} height="12" className='me-1'/>,
  'en-us': <Flag code={'US'} height="12" className='me-1'/>,
  'es-es': <Flag code={'ES'} height="12" className='me-1'/>,
  'es-mx': <Flag code={'ES'} height="12" className='me-1'/>,
  'fr-fr': <Flag code={'FR'} height="12" className='me-1'/>,
  'de-de': <Flag code={'DE'} height="12" className='me-1'/>,
  'no-no': <Flag code={'NO'} height="12" className='me-1'/>,
  'da-dk': <Flag code={'DK'} height="12" className='me-1'/>,
  'fi-fi': <Flag code={'FI'} height="12" className='me-1'/>,
  'it-it': <Flag code={'IT'} height="12" className='me-1'/>,
};

export const LANGUAGES = [
  { label: 'Select language', value: -1 },
  {
    label: LANGUAGE_LABELS.sv,
    value: 'sv-se'
  },
  {
    label: LANGUAGE_LABELS['en-gb'],
    value: 'en-gb'
  },
  {
    label: LANGUAGE_LABELS['en-us'],
    value: 'en-us'
  },
  {
    label: LANGUAGE_LABELS['es'],
    value: 'es-es'
  },
  {
    label: LANGUAGE_LABELS['es'],
    value: 'es-mx'
  },
  {
    label: LANGUAGE_LABELS['fr'],
    value: 'fr-fr'
  },
  {
    label: LANGUAGE_LABELS['de'],
    value: 'de-de'
  },
  {
    label: LANGUAGE_LABELS['no'],
    value: 'no-no'
  },
  {
    label: LANGUAGE_LABELS['dk'],
    value: 'da-dk'
  },
  {
    label: LANGUAGE_LABELS['fi'],
    value: 'fi-fi'
  },
  {
    label: LANGUAGE_LABELS['it'],
    value: 'it-it'
  }
];