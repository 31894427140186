import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  getColor,
  getPastDates,
  getPastMonths,
  getPastWeeks,
  getPastYears,
  rgbaColor
} from '../../index';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const tooltipFormatter = params => {
  if(params.length > 1) {
    const info = params.map((x) => `<tr><td>${x.seriesName}</td><td class='text-800 fw-semi-bold fs--1' style='text-align: right'  >${x.data}</td></tr>`).join('');
    return `<div>
    <div class="d-flex align-items-center">
      <table class='mb-0 text-600 fs--1'>${info}</table>
    </div>
  </div>`
  } else return `<div>
        <div class="d-flex align-items-center">
          <p class='mb-0 text-600 fs--1'>
            <span class='text-800 fw-semi-bold fs--1'>${params[0].data}</span>
          </p>
        </div>
      </div>`;
};

const timeCategorySelector = (timeCategory, length) => {
  switch (timeCategory) {
    case '7days':
      return getPastDates(7);
    case '15days':
        return getPastDates(15);
    case 'weeks':
      return getPastWeeks(length);
    case 'months':
      return getPastMonths(length);
    case 'years':
      return getPastYears(length);
    default:
      return getPastDates(length);
  }
};

const timeFormatterSelector = (timeCategory, value) => {
  switch (timeCategory) {
    case '7days':
    case '15days':
      return dayjs(value).format('MMM DD');
    default:
      return value;
  }
};

const getOptions = (data, timeCategory) => {
  const primary = data[0];
  console.log({ primary });
  const seriesExemplar = {
    type: 'line',
    name: 'created',
    data: [],
    showSymbol: false,
    symbol: 'circle',
    animation: false,
    itemStyle: {
      borderColor: getColor('primary'),
      borderWidth: 2
    },
    lineStyle: {
      color: getColor('primary')
    },
    areaStyle: {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: rgbaColor(getColor('primary'), 0.2)
          },
          {
            offset: 1,
            color: rgbaColor(getColor('primary'), 0)
          }
        ]
      }
    }
  };

  const series = [
    {...seriesExemplar, ...({ data: data[0] }) },
    {
      type: 'line',
      data: data[1],
      symbol: 'none',
      lineStyle: {
        type: 'dashed',
        width: 1,
        color: getColor('info')
      }
    }
  ];

  if(data.series) {
    data.series.forEach((x) => {
      console.log(x);
      series.push({...seriesExemplar, ...x});
    });
  }

  return {
    color: getColor('white'),
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      },
      formatter: tooltipFormatter
    },
    xAxis: {
      type: 'category',
      data: timeCategorySelector(timeCategory, data[0].length),
      axisLabel: {
        color: getColor('gray-600'),
        formatter: value => timeFormatterSelector(timeCategory, value),
        align: 'left',
        fontSize: 11,
        padding: [0, 0, 0, 5],
        showMaxLabel: false
      },
      axisLine: {
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisTick: {
        show: true,
        length: 20,
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      boundaryGap: false
    },
    yAxis: {
      position: 'right',
      axisPointer: { type: 'none' },
      axisTick: 'none',
      splitLine: {
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisLine: { show: false },
      axisLabel: { color: getColor('gray-600') }
    },
    series,
    grid: {
      containLabel: true,
      right: '5px',
      left: 0,
      bottom: 0,
      top: '10px'
    }
  }
};

const AudienceChart = ({ data, timeCategory }) => {
  const chartRef = useRef(null);

  return (
    <ReactEChartsCore
      ref={chartRef}
      echarts={echarts}
      option={getOptions(data, timeCategory)}
      style={{ height: '21.25rem' }}
    />
  );
};

export default AudienceChart;
