import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Tab, Nav, Form, Button } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import AudienceChart from './LineChart';
import { getTimezone } from '../../../../helpers/tz'
import { colors, getColor } from '../../index';

const TabTitle = ({ title, value }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs--2 text-nowrap">{title}</h6>
    <h5 className="text-800">{value}</h5>
  </div>
);

const InitialSelection = '15days';

const PrimaryDetailsChart = ({ className }) => {
  const [chartData, setChartData] = useState({
    application: [[]],
    candidates: [[]],
    interviews: [[]],
    applicationTotal: 0,
    candidatesTotal: 0,
    interviewsTotal: 0
  });
  const [chartTimeCategory, setChartTimeCategory] = useState(InitialSelection);
  const [monthOrYearSelection, setMonthOrYearSelection] = useState(null);
  const [isLoadingExportButton, setIsLoadingExportButton] = useState(false);
  const [listOfYears, setListOfYears] = useState([]);

  useEffect(() => {
    timeCategoryBySelection(InitialSelection);
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 5 }, (_, i) => currentYear - 4 + i);
    setListOfYears(years);
  }, []);

  const setChartDataToState = response => {
    try {
      const tempApplication = [];
      const tempCandidates = [];
      const tempInterviews = [];
      const { ApplicationCount, CandidateCount, interviews, TotalCounts } =
        response.data;
      ApplicationCount.forEach(element => {
        tempApplication.push(parseFloat(element.count));
      });
      CandidateCount.forEach(element => {
        tempCandidates.push(parseFloat(element.count));
      });
      const interviewsData = {
        started: [],
        completed: [],
      };
      interviews.rows.forEach((x) => {
        tempInterviews.push(parseFloat(x.total));
        interviewsData.started.push(parseFloat(x.started));
        interviewsData.completed.push(parseFloat(x.ended));
      });

      const seriesColor = (x) => ({
        lineStyle: { color: x },
        itemStyle: {
          borderColor: x,
        }
      })

      setChartData({
        application: [tempApplication],
        candidates: [tempCandidates],
        interviews: { 
          [0]: tempInterviews, 
          series: [{
            name: 'started',
            data: interviewsData.started,
            ...seriesColor(colors[3]),
          },{
            name: 'completed',
            data: interviewsData.completed,
            ...seriesColor(colors[1])
          }]
        },
        applicationTotal: TotalCounts.applicationTotal,
        candidatesTotal: TotalCounts.candidateTotal,
        interviewsTotal: interviews.total
      });
    } catch (error) {
      console.log(error);
      toast.error('Failed to process data: ' + err.message);
    }
  };

  const getChartData = (timeCategory, monthOrYear) => {
    const tz = getTimezone();
    const config = tz ? { headers: { 'X-Timezone': tz } } : undefined;
  
    let url = `${process.env.REACT_APP_URL}/dashboard-analytics/v1/count-for-graph/${timeCategory}`;
    if (monthOrYear != undefined || monthOrYear != null) {
      url = `${process.env.REACT_APP_URL}/dashboard-analytics/v1/count-for-graph/${timeCategory}/${monthOrYear}`;
    }
    url = url + '?t=' + new Date().setHours(0, 0, 0, 0);
    axios.get(url, config).then(async res => {
      if (res.status === 200) {
        setChartDataToState(res);
      }
    });
  };

  const valueFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : '0';
  };

  const timeCategoryBySelection = (timeCategory, year) => {
    if (monthOrYearSelection === null) {
      getChartData(timeCategory);
      const currentYear = new Date().getFullYear();
      setMonthOrYearSelection(currentYear);
    } else {
      if (monthOrYearSelection != null && year === undefined) {
        getChartData(timeCategory, monthOrYearSelection);
      } else {
        getChartData(timeCategory, year);
      }
    }
  };

  const exportToExcel = async () => {
    try {
      setIsLoadingExportButton(true);
      let candidatesData;
      let applicationData;
      let interviewsData;
      let totalCount;

      let url = `${process.env.REACT_APP_URL}/dashboard-analytics/v1/analytics-export`;
      const result = await axios.get(url);
      if (result.status === 200) {
        console.log(result);
        candidatesData = result.data.CandidateCount.results;
        applicationData = result.data.ApplicationCount.results;
        interviewsData = result.data.InterviewsCount.results;
        totalCount = {
          applications: result.data.ApplicationCount.totalCount,
          candidates: result.data.CandidateCount.totalCount,
          interviews: result.data.InterviewsCount.totalCount
        };
      } else {
        toast.error('Failed to export data: ' + err.message);
      }
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const fileName = `Hubert Analytics`;

      const wsCandidates = XLSX.utils.json_to_sheet(candidatesData);
      const wsApplication = XLSX.utils.json_to_sheet(applicationData);
      const wsInterviews = XLSX.utils.json_to_sheet(interviewsData);

      const lastRowCandidates = XLSX.utils.decode_range(wsCandidates['!ref']).e
        .r;
      XLSX.utils.sheet_add_aoa(wsCandidates, [['']], {
        origin: lastRowCandidates + 1
      });
      const lastRowApplication = XLSX.utils.decode_range(wsApplication['!ref'])
        .e.r;
      XLSX.utils.sheet_add_aoa(wsApplication, [['']], {
        origin: lastRowApplication + 1
      });
      const lastRowInterviews = XLSX.utils.decode_range(wsInterviews['!ref']).e
        .r;
      XLSX.utils.sheet_add_aoa(wsInterviews, [['']], {
        origin: lastRowInterviews + 1
      });

      XLSX.utils.sheet_add_json(
        wsCandidates,
        [['TotalCandidate', totalCount.candidates]],
        {
          skipHeader: true,
          origin: -1
        }
      );
      XLSX.utils.sheet_add_json(
        wsApplication,
        [['TotalApplication', totalCount.applications]],
        {
          skipHeader: true,
          origin: -1
        }
      );
      XLSX.utils.sheet_add_json(
        wsInterviews,
        [['TotalInterviews', totalCount.interviews]],
        {
          skipHeader: true,
          origin: -1
        }
      );

      const wb = {
        Sheets: {
          Candidates: wsCandidates,
          Application: wsApplication,
          Interviews: wsInterviews
        },
        SheetNames: ['Candidates', 'Application', 'Interviews']
      };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      setIsLoadingExportButton(false);
    } catch (error) {
      console.log(error);
      toast.error('Failed to export data: ' + err.message);
    }
  };

  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <ToastContainer />
      <Tab.Container id="audience-tab" defaultActiveKey="applications">
        <SimpleBarReact>
          <Card.Header className="p-0 bg-light">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
              <Nav.Item>
                <Nav.Link className="mb-0" eventKey="applications">
                  <TabTitle
                    title="Applications"
                    value={valueFormatter(
                      parseInt(chartData.applicationTotal),
                      2
                    )}
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="mb-0" eventKey="candidates">
                  <TabTitle
                    title="Candidates"
                    value={valueFormatter(
                      parseInt(chartData.candidatesTotal),
                      2
                    )}
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="mb-0" eventKey="interviews">
                  <TabTitle
                    title="Interviews"
                    value={valueFormatter(
                      parseInt(chartData.interviewsTotal),
                      2
                    )}
                  />
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Col
              style={{
                paddingTop: '10px',
                paddingLeft: '10px'
              }}
              xs="auto"
            >
              <h6>{`Year - ${
                monthOrYearSelection || new Date().getFullYear()
              }`}</h6>
            </Col>
          </Card.Header>
        </SimpleBarReact>

        <Card.Body>
          <Tab.Content>
            <Tab.Pane unmountOnExit eventKey="applications">
              <AudienceChart
                data={chartData.application}
                timeCategory={chartTimeCategory}
              />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="candidates">
              <AudienceChart
                data={chartData.candidates}
                timeCategory={chartTimeCategory}
              />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="interviews">
              <AudienceChart
                data={chartData.interviews}
                timeCategory={chartTimeCategory}
              />
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>

      <Card.Footer className="bg-light py-2">
        <Row className="g-0 flex-between-center">
          <Col xs={2}>
            <Form.Select
              size="sm"
              className="me-2"
              value={chartTimeCategory}
              onChange={e => {
                setChartTimeCategory(e.target.value);
                switch (e.target.value) {
                  case 'months':
                    timeCategoryBySelection('months');
                    break;
                  case 'years':
                    timeCategoryBySelection('years');
                    break;
                  case '15days':
                    timeCategoryBySelection('15days');
                    break;
                  case 'weeks':
                    timeCategoryBySelection('weeks');
                    break;
                  default:
                    timeCategoryBySelection('months');
                    break;
                }
              }}
            >
              <option value={'15days'}>Days (Last 15 Days)</option>
              <option value={'weeks'}>Weeks</option>
              <option value={'months'}>Months</option>
              <option value={'years'}>Years</option>
            </Form.Select>
          </Col>
          <Col xs={2}>
            {chartTimeCategory === 'weeks' || chartTimeCategory === 'months' ? (
              <Form.Select
                size="sm"
                className="me-2"
                value={monthOrYearSelection === null ? 2 : monthOrYearSelection}
                onChange={e => {
                  setMonthOrYearSelection(e.target.value);
                  timeCategoryBySelection(chartTimeCategory, e.target.value);
                }}
              >
                {listOfYears.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            ) : (
              ''
            )}
          </Col>
          <Col xs={6}>&nbsp;</Col>
          <Col xs="auto">
            <Button
              onClick={() => {
                exportToExcel();
              }}
              variant="link"
              disabled={isLoadingExportButton}
            >
              {isLoadingExportButton ? 'Processing..' : 'Export'}
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default PrimaryDetailsChart;
