import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';

export const bonusIconWithText = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#36B37E' }} size={'lg'} icon={faCertificate} />
    <span className="m-2">Bonus</span>
  </span>;

export const bonusIcon = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#36B37E' }} size={'lg'} icon={faCertificate} />
  </span>;