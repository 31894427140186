import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';

import { HubiverseTagsModal } from '..';

export const HubiverseTagsModalShowButton = ({
  qutter_id=null,
  option_id=null,
  form_id=null,
  column_id=null,
  row_id=null,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [hubiverse_tags, set_hubiverse_tags] = useState([]);
  const library = useSelector(state => state.Library);

  useEffect(() => {

    if (qutter_id) {
      const qutter_config = library[qutter_id];
      if (qutter_config.type === 'single-select') {
        const qutter = qutter_config[qutter_config.language.current];
        const option = qutter.options.find(o => o.id === option_id);
        set_hubiverse_tags(
          option.hubiverse_tags && option.hubiverse_tags.length > 0 ? 
          option.hubiverse_tags:
          []
        );
      } else 
      if (qutter_config.type === 'form' && form_id) {
        console.log(form_id, 'form_id')
        const qutter = qutter_config[qutter_config.language.current];
        const form = qutter.forms.find(o => o.id === form_id);
        set_hubiverse_tags(
          form.hubiverse_tags && form.hubiverse_tags.length > 0 ? 
          form.hubiverse_tags:
          []
        );
      }
    }

  }, [qutter_id, library]);

  return (
    <>
      {showModal && (
        <HubiverseTagsModal
          showModal={showModal}
          setShowModal={setShowModal}
          qutter_id={qutter_id}
          option_id={option_id}
          form_id={form_id}
          hubiverse_tags={hubiverse_tags}
        />
      )}

      <span
        className="hover-blue"
        className={`m-2 font-size-10 cursor-pointer ${hubiverse_tags.length ? 'color-blue': 'hover-blue'}`}
        onClick={(e) => { setShowModal(!showModal); }}
      >
        <FontAwesomeIcon icon={faTags} className='me-1' />
        Hubiverse Tags
      </span>
    </>
  );
};
