import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';

// toast.warning(`No response: ${error.request}`);
// toast.error(`Error: ${error.message}`);
// toast.success(`Logged in as ${'HEJJA'}`);
// toast(`Logged in as ${'HEJJA'}`);
// toast.info(`Logged in as ${'HEJJA'}`);

import {
  Row,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Container 
} from 'react-bootstrap';

import { addBlock } from 'features/Template/slices/TemplateSlice';

export const CreateBlockModal = ({
  template_id,
  block_idx, 
  show, 
  setShow 
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');

  const handleChange = async (type, event) => {
    if (type === 'block-name') setName(event.target.value);
  };

  const save = () => {
    dispatch(addBlock({ id: template_id, block_idx, name }));
    setShow(false);
    toast.success(`Block "${name}" successfully added.`);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            New block
          </Modal.Title>
        </Modal.Header>
        <Modal.Body as={Container}>
          <Row>
            <Col>
              <FloatingLabel
                controlId="inputInternal"
                label="Block name"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Bla bla"
                  defaultValue={name}
                  onChange={event => handleChange('block-name', event)}
                />
              </FloatingLabel>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='text-center'>

          <span 
            className="hover-blue font-size-10 me-3"
            onClick={() => { setShow(false) }}
          >
            <FontAwesomeIcon icon={faXmark} className="me-2"/>
            Cancel
          </span>
          <span 
            className="hover-blue font-size-10"
            onClick={save}
          >
            <FontAwesomeIcon icon={faFloppyDisk} className="me-2"/>
            Save
          </span>

        </Modal.Footer>
      </Modal>
    </>
  );
};