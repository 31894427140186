import React from 'react';
import { PreviewRuleDoThisIf } from './PreviewRuleDoThisIf';
import { PreviewRuleShowThisIf } from './PreviewRuleShowThisIf';

export const PreviewRule = ({
  template_id,
  qutter_id,
  parent_id,
  rule,
  rule_idx,
  order,
 }) => {

  return (
  <div className="ms-1 mb-1 mt-0">
    {rule.type === 'DO_THIS_IF' ? (
      <PreviewRuleDoThisIf
        template_id={template_id}
        parent_id={qutter_id}
        qutter_id={qutter_id}
        rule={rule}
        rule_idx={rule_idx}
        order={order}
      />
    ) : (
      <PreviewRuleShowThisIf
        template_id={template_id}
        parent_id={qutter_id}
        qutter_id={qutter_id}
        rule={rule}
        rule_idx={rule_idx}
        order={order}
      />
    )}
  </div>
  );
};