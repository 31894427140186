import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  
  faTimesCircle,
  faCheck
} from '@fortawesome/free-solid-svg-icons';

export const ApplicationQualified = ({
  application_id,
  interview_id,
  qualified
}) => {
  console.log('<ApplicationQualified />', qualified);

  if (qualified === true) {
    return (
      <span className="fa-layers fa-fw cert">
        <FontAwesomeIcon className="text-success icon" style={{ color: '#36B37E' }} size={'lg'} icon={faCheck} />
      </span>
    );
  } else if (qualified === false) {
    return (
      <span className="fa-layers fa-fw cert">
        <FontAwesomeIcon className="text-danger icon" style={{ color: '#36B37E' }} size={'lg'} icon={faTimesCircle} />
      </span>
    );
  } else return (<span>{'-'}</span>);
};
