import React, { useEffect } from 'react';
import { Outlet, Link, Route, Routes } from 'react-router-dom';

import is from 'is_js';

import MainLayouts from './MainLayouts';
import RecruiterAppMainLayout from './RecruiterAppMainLayout';

import Login from '../_components/auth/Login';
import Logout from '../_components/auth/Logout';

import AppHubertLogin from 'features/Login/components/AppHubertLogin';
import InterviewReceipt from '../_components/interviewReceipt/InterviewReceipt';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import JobSearch from '../_components/jobs/JobSearch';
import Job from '../_components/jobs/Job';

import ClientsList from '../_components/clients/ClientsList';
import Client from '../_components/clients/Client';

import QuestionsIndex from 'features/Qutters/components/QuestionsIndex';
import InterviewsIndex from 'features/Interviews/components/InterviewsIndex';

import { Candidate } from 'features/Candidate';
import { CandidateSearch, CandidateSearchV2, CandidateV2 } from 'features/CandidateSearch';

import { ScoreProvider } from 'features/Scoring';
import Summary from '../_components/score/Summary';

import { 
  TemplatesIndex,
  AppTemplatesIndex
} from 'features/Template';

import { TemplatesContainer } from 'features/Template';
import { Dashboard } from 'features/HubertDashboard';

import { ParserIndex } from 'features/Parser';
import { TestHubertQuill } from 'features/HubertQuill';
// import Map from '_components/maps/Map';

import { CloseButton, Fade } from 'components/common/Toast';

import { UpdateApplication } from 'features/Integrations/UpdateApplication';

// import MainLayout from './MainLayout';
// import AuthSimpleLayout from './AuthSimpleLayout';
// import SettingsToggle from '/components/settings-panel/SettingsToggle';
// import SettingsPanel from '/components/settings-panel/SettingsPanel';
// import AuthCardRoutes from 'components/authentication/card/AuthCardRoutes';
// import AuthSplitRoutes from 'components/authentication/split/AuthSplitRoutes';

// import ErrorLayout from './ErrorLayout';
// import WizardAuth from 'components/authentication/wizard/WizardAuth';
// import Landing from '../components/pages/landing/Landing';
// import { CloseButton, Fade } from '../components/common/Toast';

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

function Jobs() {
  return (
    <div style={{ padding: 20 }}>
      <Outlet />
    </div>
  );
}

function Clients() {
  return (
    <div style={{ padding: 20 }}>
      <Outlet />
    </div>
  );
}

function Candidates() {
  return (
    <div style={{ padding: 20 }}>
      <Outlet />
    </div>
  );
}

function Interviews() {
  return (
    <div style={{ padding: 20 }}>
      <Outlet />
    </div>
  );
}

function Templates() {
  return (
    <div style={{ padding: 20 }}>
      <Outlet />
    </div>
  );
}

function Questions() {
  return (
    <div style={{ padding: 20 }}>
      <Outlet />
    </div>
  );
}

function InterviewReceipts() {
  return (
    <div style={{ padding: 20 }}>
      <Outlet />
    </div>
  );
}

function Parser() {
  return (
    <div style={{ padding: 20 }}>
      <Outlet />
    </div>
  );
}

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayouts />}>
          <Route index element={<Dashboard />} />

          <Route path="clients" element={<Clients />}>
            <Route index element={<ClientsList />} />
            <Route path=":id" element={<Client />} />
          </Route>

          <Route path="/jobs" element={<Jobs />}>
            <Route index element={<JobSearch />} />
            <Route path=":id" element={<Job />} />
          </Route>

          <Route path="/candidates" element={<Candidates />}>
            <Route index element={<CandidateSearch />} />
            <Route path="v2" element={<CandidateSearchV2 />} />
            <Route path=":id" element={<Candidate />} />
            <Route path=":id/v2" element={<CandidateV2 />} />
          </Route>

          <Route path="/interviews" element={<Interviews />}>
            <Route index element={<InterviewsIndex />} />
          </Route>

          <Route path="/score/:id" element={<ScoreProvider />} />
          <Route path="/summary/:id" element={<Summary />} />

          <Route path="/templates" element={<Templates />}>
            <Route index element={<TemplatesIndex />} />
            <Route path=":template_id" element={<TemplatesContainer readOnly={false} />} />
          </Route>

          <Route path="/questions" element={<Questions />}>
            <Route index element={<QuestionsIndex />} />
          </Route>

          <Route path="/parser" element={<Parser />}>
            <Route index element={<ParserIndex />} />
          </Route>

          <Route path="/quill" element={<TestHubertQuill />} />

          <Route path="*" element={<NoMatch />} />
          {/* <Route path="/maps/test" element={<Map />} /> */}
        </Route>

        <Route path="app" element={<RecruiterAppMainLayout />}>
          <Route index element={<AppTemplatesIndex />} />
          <Route
            path="script/:template_id"
            element={<TemplatesContainer readOnly={true} /> }
          />
        </Route>

        <Route path={`/update-application`} exact element={<UpdateApplication />} />
        <Route path={`/login`} exact element={<Login />} />
        <Route path={`/app-login`} exact element={<AppHubertLogin />} />
        <Route path={`/loggedout`} exact element={<Logout />} />
        <Route path={`/app-loggedout`} exact element={<Logout />} />

        {/* <Route path={`/interview-receipt`} exact element={<InterviewReceipt />} /> */}
        {/* <Route path="/" exact element={<Dashboard />} /> */}
        {/* <Route path="/landing" component={Landing} /> */}
        {/* <Route path="/errors" component={ErrorLayout} /> */}
        {/* <Route path="/login" component={AuthSimpleLayout} /> */}
        {/* <Route path="/authentication/card" component={AuthCardRoutes} /> */}
        {/* <Route path="/authentication/split" component={AuthSplitRoutes} /> */}
        {/* <Route path="/authentication/wizard" component={WizardAuth} /> */}
        {/* <Route component={MainLayout} /> */}
        {/* <Navigate to="/errors/404" /> */}
      </Routes>

      {/* 
      <SettingsToggle />
      <SettingsPanel />
    */}

      <ToastContainer
        style={{ color: 'white !important' }}
        variant="secondary"
        transition={Fade}
        closeButton={CloseButton}
        position="top-right"
        autoClose={5000}
        showProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        draggable={true}
        pauseOnHover={true}
      />
    </>
  );
};

export default Layout;
