import React  from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

import { 
  SearchByTextInLibraryThunk 
} from 'features/Qutters/slices/thunks';

import { updateFilteredArray } from '../../slices/TemplateSlice';

export const SearchQutterInTemplate = ({
  template_id,
}) => {
  const dispatch = useDispatch();
  // console.log('<SearchQutterInTemplate />');

  const onSearchTextChange = (e) => {
    const text = e.target.value;
    if (text.length > 3) {
      dispatch(SearchByTextInLibraryThunk({ findText: text, languages: ['sv-se'] }))
      .then(async (result) => {
        console.log('Search Result', result);
        const ids = result.payload.result;
        dispatch(updateFilteredArray({ ids }));
      });
    } else {
      dispatch(updateFilteredArray({ ids: [] }));
    }
  };

  return (
    <div>
      <Form.Label htmlFor="inputQutterSearch">
        <span>
          <FontAwesomeIcon
            size={'sm'}
            className='me-1'
            style={{ color: 'grey' }}
            icon={faMagnifyingGlass}
          />
          Search question
        </span>
      </Form.Label>
      <Form.Control
        // onChange={(e) => { console.log(e.target.value) }}
        onChange={onSearchTextChange}
        type="text"
        size="sm"
        id="inputQutterSearch"
        aria-describedby="inputQutterSearchHelpBlock"
      />
      <Form.Text 
        className='font-size-10'
        id="inputQutterSearchHelpBlock" muted>
        NOTE: Searching only the <strong>base questions</strong>, not followups. Text must be at least 2 characters long.
      </Form.Text>
    </div>
  );
};