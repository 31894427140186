import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';

import { addQutterToTemplateBlock } from 'features/Template/slices/TemplateSlice';
import { addFollowup } from 'features/Parameters';

import { 
  addToLibrary,
  optionDefaultFollowup
} from '../../Qutters/slices/LibrarySlice';

import { UpdateQutter } from 'features/Qutters/slices/thunks';

import { customRuleActionUpdate } from 'features/Parameters';
import { ruleActionUpdate } from 'features/Qutters';

import { add_to_default_qutter_block } from 'features/InterviewSummary';
import { toggleSearchQutterModal } from 'features/Modals/slices/ModalSlice';

export function QutterListBulkActions({
  add_policy,
  setShowModal,
  template_id,
  qutter_id,
  qutter_idx,
  block_id,
  block_name,
  option_idx,
  option_id,
  list,
  selectedRowIds
}) {
  const dispatch = useDispatch();
  const library = useSelector(state => state.Library);
  const template = useSelector(state => state.Template[template_id]);
  // const languages = template.language_settings.list;
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const modals = useSelector(state => state.Modals);
  const parameters = useSelector(state => state.Parameters);

  let language;
  let qutter;
  if (qutterConfig) {
    language = qutterConfig.language.current;
    qutter = qutterConfig[language];
  }

  if (!add_policy) { return (<></>); }

  const AddQutterToTemplateBlock = async () => {
    const data = modals.searchQutterModal.data;
    let ids = [];
    for (let key in selectedRowIds) {
      ids.push(list[key].id)
      await dispatch(addToLibrary({ qutter: list[key] }));
    }
    ids.map(async (id, idx) => {
      const qutter_config = list.find(q => q.id === id);

      await dispatch(addQutterToTemplateBlock({
        id: template_id,
        block_id,
        question_id: id,
        node_idx: data.node_idx
      }));

      await dispatch(add_to_default_qutter_block({ 
        template_id, 
        block_id: qutter_config[qutter_config.available_languages[0]].presentation.block,
        qutter_id
      }));
    });

    dispatch(toggleSearchQutterModal({
      show: false,
      shouldDisplayCreateQutterButton: false,
      template_id: null,
      block_id: null,
      block_name: null,
      qutter_idx: null,
      qutter_id: null
    }));
    toast.success(`Question added to block ${block_name}.`);
  };

  const AddToCustomConditionRule = async () => {
    const data = modals.searchQutterModal.data;
    const params = parameters[data.qutter_id];
    if (!params) {
      toast.error(`Error: can not find the correct data.`);
      return;
    }

    let rule = params.rules[data.rule_idx];
    let updated_action = Object.assign({}, rule.actions[data.action_idx]);

    let ids = [];
    for (let key in selectedRowIds) {
      ids.push(list[key].id)
    }

    updated_action.value = ids;

    dispatch(customRuleActionUpdate({
      qutter_id: data.qutter_id,
      rule_idx: data.rule_idx, 
      action_idx: data.action_idx,
      updated_action
    }));

    toast.success(`Custom Rule Action updated.`);
    dispatch(toggleSearchQutterModal({
      show: false,
      shouldDisplayCreateQutterButton: false,
      template_id: null,
      block_id: null,
      block_name: null,
      qutter_idx: null,
      qutter_id: null,
      data: null
    }));
  };

  const CUSTOM_RULE_ACTION_PLUS = async () => {
    const data = modals.searchQutterModal.data;
    const params = parameters[data.qutter_id];
    if (!params) {
      toast.error(`Error: can not find the correct data.`);
      return;
    }

    let rule = params.rules[data.rule_idx];
    let updated_action = Object.assign({}, rule.actions[data.action_idx]);
    let updated_value = Object.assign([], updated_action.value);

    let ids = [];
    for (let key in selectedRowIds) {
      ids.push(list[key].id)
    }

    updated_value.push(...ids);
    updated_action.value = updated_value;

    dispatch(customRuleActionUpdate({
      qutter_id: data.qutter_id,
      rule_idx: data.rule_idx, 
      action_idx: data.action_idx,
      updated_action
    }));

    toast.success(`Custom Rule Action updated.`);
    dispatch(toggleSearchQutterModal({
      show: false,
      shouldDisplayCreateQutterButton: false,
      template_id: null,
      block_id: null,
      block_name: null,
      qutter_idx: null,
      qutter_id: null,
      data: null
    }));
  };

  const AddToDefaultConditionRuleAction = async () => {
    // rule_idx, action_idx
    const data = modals.searchQutterModal.data;
    const q = library[data.qutter_id];
    let rule = q.rules[data.rule_idx];
    let updated_action = Object.assign({}, rule.actions[data.action_idx]);

    let ids = [];
    for (let key in selectedRowIds) {
      ids.push(list[key].id);
    }

    updated_action.value = ids;

    dispatch(ruleActionUpdate({
      qutter_id: data.qutter_id,
      rule_idx: data.rule_idx, 
      action_idx: data.action_idx,
      updated_action
    }));

    toast.success(`Rule Action updated.`);
    dispatch(toggleSearchQutterModal({
      show: false,
      shouldDisplayCreateQutterButton: false,
      template_id: null,
      block_id: null,
      block_name: null,
      qutter_idx: null,
      qutter_id: null,
      data: null
    }));
  };

  const DEFAULT_RULE_ACTION_PLUS = async () => {
    // rule_idx, action_idx
    const data = modals.searchQutterModal.data;
    const q = library[data.qutter_id];
    let rule = q.rules[data.rule_idx];
    let updated_action = Object.assign({}, rule.actions[data.action_idx]);
    let updated_value = Object.assign([], updated_action.value);

    let ids = [];
    for (let key in selectedRowIds) {
      ids.push(list[key].id);
    }

    updated_value.push(...ids);
    updated_action.value = updated_value;

    dispatch(ruleActionUpdate({
      qutter_id: data.qutter_id,
      rule_idx: data.rule_idx, 
      action_idx: data.action_idx,
      updated_action
    }));

    dispatch(toggleSearchQutterModal({
      show: false,
      shouldDisplayCreateQutterButton: false,
      template_id: null,
      block_id: null,
      block_name: null,
      qutter_idx: null,
      qutter_id: null,
      data: null
    }));
  };

  return (
  <Row className='mt-2'>
    <Col className='m-auto text-end'>

      {/* 
      <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
        {Object.keys(selectedRowIds).length > 0 ? 'You have selected ' + Object.keys(selectedRowIds).length + ' row(s)': ''}
      </h5> 
      */}

      {(() => {
        if (add_policy === 'add-qutter-to-template-block') {
          return (
            <div className="float-end">
              <span
                className={`${Object.keys(selectedRowIds).length > 0 ? 'hover-blue': 'd-none'} font-size-10 me-3`}
                onClick={AddQutterToTemplateBlock}
                disabled={Object.keys(selectedRowIds).length > 0 ? false: true}
              >
                <FontAwesomeIcon className="me-1" icon={faCirclePlus} />
                Add selected ({Object.keys(selectedRowIds).length}) to Block <strong><em> {block_name}</em></strong>
              </span>
            </div>
          );
        } else 
        if (add_policy === 'DEFAULT_RULE_ACTION') {
          return (
            <div className="float-end">
              <span
                className={`${Object.keys(selectedRowIds).length > 0 ? 'hover-blue': 'd-none'} font-size-10 me-3`}
                onClick={AddToDefaultConditionRuleAction}
                disabled={Object.keys(selectedRowIds).length > 0 ? false: true}
              >
                <FontAwesomeIcon className="me-1" icon={faCirclePlus} />
                Add selected ({Object.keys(selectedRowIds).length}) to rule
              </span>
            </div>
          );
        } else 
        if (add_policy === 'DEFAULT_RULE_ACTION_PLUS') {
          return (
            <div className="float-end">
              <span
                className={`${Object.keys(selectedRowIds).length > 0 ? 'hover-blue': 'd-none'} font-size-10 me-3`}
                onClick={DEFAULT_RULE_ACTION_PLUS}
                disabled={Object.keys(selectedRowIds).length > 0 ? false: true}
              >
                <FontAwesomeIcon className="me-1" icon={faCirclePlus} />
                Add selected ({Object.keys(selectedRowIds).length}) to rule
              </span>
            </div>
          );
        } else 
        if (add_policy === 'CUSTOM_RULE_ACTION') {
          return (
            <div className="float-end">
              <span
                className={`${Object.keys(selectedRowIds).length > 0 ? 'hover-blue': 'd-none'} font-size-10 me-3`}
                onClick={AddToCustomConditionRule}
                disabled={Object.keys(selectedRowIds).length > 0 ? false: true}
              >
                <FontAwesomeIcon className="me-1" icon={faCirclePlus} />
                Add selected ({Object.keys(selectedRowIds).length}) to rule action
              </span>
            </div>
          );
        } else 
        if (add_policy === 'CUSTOM_RULE_ACTION_PLUS') {
          return (
            <div className="float-end">
              <span
                className={`${Object.keys(selectedRowIds).length > 0 ? 'hover-blue': 'd-none'} font-size-10 me-3`}
                onClick={CUSTOM_RULE_ACTION_PLUS}
                disabled={Object.keys(selectedRowIds).length > 0 ? false: true}
              >
                <FontAwesomeIcon className="me-1" icon={faCirclePlus} />
                Add selected ({Object.keys(selectedRowIds).length}) to rule action
              </span>
            </div>
          );
        }
      })()}

    </Col>
  </Row>
  );
};

/* 
if (add_policy === 'default-followup') {
  return (
    <div className="float-end">
      <span
        className={`${Object.keys(selectedRowIds).length > 0 ? 'hover-blue': 'd-none'} font-size-10 me-3`}
        onClick={AddDefaultFollowup}
        disabled={Object.keys(selectedRowIds).length > 0 ? false: true}
      >
        <FontAwesomeIcon className="me-1" icon={faCirclePlus} />
        Add selected ({Object.keys(selectedRowIds).length}) as default followup
      </span>
    </div>
  );
} else 
if (add_policy === 'template-followup') {
  return (
    <div className="float-end">
      <span
        className={`${Object.keys(selectedRowIds).length > 0 ? 'hover-blue': 'd-none'} font-size-10 me-3`}
        onClick={AddTemplateFollowup}
        disabled={Object.keys(selectedRowIds).length > 0 ? false: true}
      >
        <FontAwesomeIcon className="me-1" icon={faCirclePlus} />
        Add selected ({Object.keys(selectedRowIds).length}) to template and question <strong><em> {qutter_id}</em></strong>
      </span>
    </div>
  );
} else 

const AddDefaultFollowup = async () => {
  let ids = [];
  for (let key in selectedRowIds) {
    ids.push(list[key].id)
    await dispatch(addToLibrary({ qutter: list[key] }));
  }
  ids.map(async (id, idx) => {
    await dispatch(optionDefaultFollowup({
      id: qutter_id,
      option_idx,
      followup_id: id
    }));
  });

  // Close modal if one is open
  if (setShowModal) {
    console.log('AddDefaultFollowup', qutterConfig);
    // VALIDATION - Validate the saved qutter
    // dispatch(UpdateQutter(qutterConfig)).then((res) => {
    //   console.log('UpdateQutter res', res);
    //   setShowModal(false);
    //   // if (onModalClose) onModalClose();
    // });
    setShowModal(false);
  }
};

const AddTemplateFollowup = async () => {
  let ids = [];
  for (let key in selectedRowIds) {
    if (list[key].id === qutter_id) continue;
    ids.push(list[key].id)
    await dispatch(addToLibrary({ qutter: list[key] }));
  }
  ids.map(async (id, idx) => {
    dispatch(addFollowup({
      id: template_id,
      qutter_id,
      option_id,
      followup_id: id,
    }));
  });

  // Close modal if one is open
  if (setShowModal) {
    setShowModal(false);
    toast.success(`Followup added.`);
  }
};
*/