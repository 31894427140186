import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import { addToLibrary } from 'features/Qutters/slices/LibrarySlice';
import { FetchQutter } from 'features/Qutters/slices/thunks';

import { PreviewSelect } from 'features/QutterSelects';
import { QutterPreviewComponents } from 'features/QutterType';

import { TemplateOutlineCounter } from 'features/TemplateOutline';
import { EvaluationContext } from 'features/QutterEvaluation';

import { AddQutterToBlock } from 'features/TemplateBlock';

export const TemplateQutterProvider = ({
  allowed_languages,
  template_id,
  language,
  block_id,
  block_name,
  block_idx,
  qutter_id,
  qutter_idx,
  node,
  node_idx,
  order,
  children,
  is_followup,
  parent_id
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const qutter_config = useSelector(state => state.Library[qutter_id]);

  useEffect(() => {
    async function fetch () {
      try {

        if (!qutter_config) {
          await dispatch(FetchQutter(qutter_id))
          .unwrap()
          .then((result) => {
            // handle result here
            const qutter = result.result;
            dispatch(addToLibrary({ qutter, allowed_languages }));
            setLoading(false);
          })
          .catch((rejectedValueOrSerializedError) => {
            // handle error here
            const error = JSON.parse(rejectedValueOrSerializedError.message);
            setIsError(true)
            setError(error.data.message);
            setLoading(false);
          });
        } else setLoading(false);

      } catch (e) {
        return null;
      }
    }
    fetch();
  }, [qutter_config]);

  if (isError) {
    return (<div>Error <strong>{node.id}</strong> - {error}</div>)
  }

  if (!qutter_config || loading) {
    return (<div>Loading {node.id}...</div>)
  }

  if (!(qutter_config.type in QutterPreviewComponents)) {
    return (<div>ERROR: Qutter.js !(qutter_config.type in components)</div>)
  }

  return (
  <span id={qutter_id}>

    {QutterPreviewComponents.hasOwnProperty(qutter_config.type) && is_followup === false ? (
      <Draggable
        key={`draggable-key-${qutter_id}-${block_id}`}
        draggableId={`${qutter_id}`}
        index={parseInt(node_idx)}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            // style={getItemStyle( snapshot.isDragging, provided.draggableProps.style )}
          >
            {React.createElement(
              (
                ['single-select', 'multiple-select'].includes(qutter_config.type) ? 
                PreviewSelect : QutterPreviewComponents[qutter_config.type]
              ),
              {
                template_id: template_id,
                language: language,
                block_id: block_id,
                block_name: block_name,
                qutter_id: qutter_id,
                block_idx: block_idx,
                qutter_idx: qutter_idx,
                node: node,
                node_idx: node_idx,
                order: order,
                children,
                provided: provided,
                is_followup: is_followup,
                parent_id: parent_id
              }
            )}

          </div>
        )}
      </Draggable>

    ):(
      <>
      {React.createElement(
        (
          ['single-select', 'multiple-select'].includes(qutter_config.type) ? 
          PreviewSelect : QutterPreviewComponents[qutter_config.type]
        ),
        {
          template_id: template_id,
          language: language,
          block_id: block_id,
          block_name: block_name,
          qutter_id: qutter_id,
          block_idx: block_idx,
          qutter_idx: qutter_idx,
          node: node,
          node_idx: node_idx,
          order: order,
          children,
          is_followup: is_followup,
          parent_id: parent_id
        }
      )}
      </>
    )}

    {is_followup ? (null): (
    <AddQutterToBlock
      template_id={template_id}
      block_id={block_id}
      node_idx={node_idx}
    />
    )}

    <EvaluationContext
      template_id={template_id}
      qutter_id={qutter_id}
      node={node}
      node_idx={node_idx}
      is_followup={is_followup}
    />

    <TemplateOutlineCounter
      template_id={template_id}
      qutter_id={qutter_id}
      node={node}
      node_idx={node_idx}
      is_followup={is_followup}
    />

  </span>
)};