import React, { useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { ClientSelect } from 'features/ClientSelect';

import { find_questions_by_client_id } from '../api/api';
import { handle_form_labels } from '../utils/utils';

const prepare_questions = (questions) => {
  let global = [];
  let local = [];
  questions.map((question_config) => {
    const languages = question_config.available_languages;
    const global    = question_config.global;
    const id        = question_config.id;

    let text = '';
    languages.map((language, idx) => {
      const question = question_config[language];
      text += question.text + ' / ';
      if (idx !== 0) text += ' / ';
    });

    if (question_config.global) global.push({ label: text, value: id });
    else local.push({ label: text, value: id });
  });

  return {
    global: global,
    local: local
  };
};

export const CandidateSearchLocal = () => {

  const [loading, set_loading] = useState(false);
  const [client_id, set_client_id] = useState(null);
  const [questions, set_questions] = useState([]);
  const [answers, set_answers] = useState([]);
  const [local_questions, set_local_questions] = useState([]);
  const [global_questions, set_global_questions] = useState([]);

  const [text, set_text] = useState('');
  const [language, set_language] = useState('');
  const [types, set_types] = useState([]);
  const [qutter_list, set_qutter_list] = useState([]);

  const on_client_select = (client_id) => {
    set_loading(true);
    find_questions_by_client_id(client_id).then(async (data) => {
      console.log('find_questions_by_client_id', data);
      set_questions(data);
      const questions = await prepare_questions(data);
      set_local_questions(questions.local);
      set_global_questions(questions.global);
      console.log('find_questions_by_client_id questions', questions);
    }).catch((error) => {
      set_loading(false);
      toast.error(`Search failed: ${error}`);
    });
  };

  return (
  <div>
    <div>Find candidates in <strong>your database</strong> that have answered in your search criteria.</div>

    <ClientSelect
      className={'m-1'}
      on_select={(e) => {
        set_client_id(e.value);
        on_client_select(e.value)
      }}
      selected_client_id={client_id}
    />

    <Select
      className={'m-1'}
      placeholder={'Choose local question'}
      options={local_questions}
      onChange={async (e)=> {
        console.log(e);
        const question_config = await questions.find(question => question.id === e.value);
        console.log(question_config);
        
        if (question_config.type === 'single-select') {

        } else

        if (question_config.type === 'multiple-select') {

        } else

        if (question_config.type === 'form') {

          const res = await handle_form_labels(question_config);
          console.log('res', res);
          set_answers(res)
        } else

        if (question_config.type === 'open') {

        }
      }}
    />

    <Select
      className={'m-1'}
      placeholder={'Choose global question'}
      options={global_questions}
    />

    <div>Answers</div>
    <Select
      isMulti={true}
      className={'m-1'}
      placeholder={'Choose answer/s'}
      options={answers}
      onChange={async (e)=> {
        console.log(e);
      }}
    />
  </div>
  );
};
