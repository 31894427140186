import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { DeleteApplicationRequest } from '../api/api';

const ResultMessage = ({
  message
}) => {
  if (Array.isArray(message)) {
    return (
      message.map((m) => {
        return (<div key={`result-message-${nanoid()}`}>{m}</div>)
      })
    )
  } else {
    return (
      <div>{message}</div>
    );
  }
};


export const DeleteApplicationDropdownItem = ({
  application_id,
  applications_list,
  set_applications_list
}) => {
  return (
    <Dropdown.Item 
      href="#!"
      style={{ color: 'red' }}
      onClick={() => {
        DeleteApplicationRequest(application_id)
        .then((res) => {
          // toast.success(res.message)
          toast.success(<ResultMessage message={res.message} />)
          if (applications_list) {
            const idx = applications_list.findIndex(application => application.id === application_id);
            if (idx > -1) {
              let cp = [...applications_list];
              cp.splice(idx, 1);
              set_applications_list(cp);
            }
          }
        })
        .catch((error) => {
          toast.error(`Failed to delete application. Error: ${error}.`);
        })
      }}
    >
      Delete Application
    </Dropdown.Item>
  );
};
