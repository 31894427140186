import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateBack, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import { QutterCommentContainer } from 'features/QutterComment';
import { toggleQutterCommentModal } from 'features/Modals/slices/ModalSlice';

export const QutterCommentModal = ({
  showModal,
  data,
}) => {
  const dispatch = useDispatch();
  const [questionText, setQuestionText] = useState('');
  const library = useSelector(state => state.Library);

  useEffect(() => {
    if (data && data.qutter_id) {
      const qutterConfig = library[data.qutter_id];
      const qutter = qutterConfig[qutterConfig.language.current];
      const text = qutter.text.join(' ');
      setQuestionText(`${text}`);
    }
  }, [data]);

  const onCloseModalClick = (e) => {
    dispatch(toggleQutterCommentModal({
      show: false,
      data: null,
    }));
  };

  console.log('<QutterCommentModal /> data', data);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        onCloseModalClick();
      }}
      dialogClassName=""
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="">
          <span style={{ fontSize: '12px' }}>
            <i
              dangerouslySetInnerHTML={{ __html: questionText }}
            />
          </span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        scrollable={true}
        className=""
        style={{
          height: '85vh',
          maxHeight: '85vh',
        }}
      >
        {data ? (
        <QutterCommentContainer 
          template_id={data.template_id}
          qutter_id={data.qutter_id}
          comments={data.comments}
        />
        ): (null)}
      </Modal.Body>

    </Modal>
  );
};
