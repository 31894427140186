import { lookup } from "features/common";
import { nanoid } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

import { OPERATORS } from "../constants/condition_types";

/** RULES & CONDITIONS */

/** Copies original rules array to parameters.rules */
export function RulesCopy (state, action) {
  const {
    qutter_id,
    original
  } = action.payload;
  const params = state[qutter_id];
  const key = `rules`;

  let temp = [];
  if (!original || original.length === 0) temp = [];
  else temp = original;

  if (params) {
    params[key] = temp;
  } else {
    state[qutter_id] = { [key]: temp };
  }
}


/********************** Rules ***********************/
/** Delete Rule */
export function DeleteCustomRuleByIndex (state, action) {
  const { qutter_id, rule_idx } = action.payload;
  state[qutter_id].rules.splice(rule_idx, 1);
}

export function DeleteRuleByIndex (state, action) {
  const { qutter_id, rule_idx } = action.payload;
  state[qutter_id].rules.splice(rule_idx, 1);
}
/** End Delete Rule */


/** Delete All Rules */
export function CustomDeleteAllRules (state, action) {
  const { qutter_id } = action.payload;
  let params = state[qutter_id];
  if (params) {
    params.rules = [];
    if (isEmpty(params)) delete state[qutter_id];
  } else {
    state[qutter_id] = { rules: [] };
  }
}

export function DeleteAllRules (state, action) {
  const { qutter_id } = action.payload;
  state[qutter_id].rules = [];
}
/** END Delete All Rules */


/** Reset to original (Only for custom rules) */
export function ResetToOriginalRules (state, action) {
  const { qutter_id } = action.payload;
  delete state[qutter_id]['rules'];
  if (isEmpty(state[qutter_id])) delete state[qutter_id];
}
/** END Reset to original */


/** Add Rule */
function rule_type_1() {
  const DEFAULT_RULE = {
    id: nanoid(),
    name: 'My Do This If Rule',
    type: 'DO_THIS_IF',
    operator: 'AND',
    conditions: [],
    actions: []
  };
  return DEFAULT_RULE;
}

function rule_type_2 () {
  const DEFAULT_RULE = {
    id: nanoid(),
    name: 'My Show This If Rule',
    type: 'SHOW_THIS_IF',
    operator: 'AND',
    conditions: [],
    actions: [{
      id: nanoid(),
      name: "SHOW"
    }]
  };
  return DEFAULT_RULE;
}

export function AddRule (state, action) {
  const { qutter_id, type } = action.payload;
  const rule = (type === 'DO_THIS_IF') ? rule_type_1(): rule_type_2();
  state[qutter_id].rules.push(rule);
}

export function AddCustomRule (state, action) {
  const { qutter_id, type } = action.payload;
  let params = state[qutter_id];
  const rule = (type === 'DO_THIS_IF') ? rule_type_1(): rule_type_2();

  if (!params || !params.hasOwnProperty('rules')) {
    state[qutter_id] = {
      ...state[qutter_id],
      rules: [rule]
    };
  } else {
    params.rules.push(rule);
  }
}
/** END Add Rule */

/** Rule Update */
// EXACTLY THE SAME
export function RuleUpdate (state, action) {
  const { qutter_id, rule_idx, rule } = action.payload;
  state[qutter_id].rules[rule_idx] = rule;
}

export function CustomRuleUpdate (state, action) {
  const { qutter_id, rule_idx, rule } = action.payload;
  state[qutter_id].rules[rule_idx] = rule;
}
/** END Rule Update */
/********************** END Rules ***********************/


/********************** Rule Conditions ***********************/
/** Add Rule Condition */
// EXACTLY THE SAME
function new_condition() {
  const DEFAULT_CONDITION = {
    id: nanoid(),
    type: 'question_answer',
    operator: OPERATORS.EQUALS,
    value: null
  };
  return DEFAULT_CONDITION;
}

export function CustomRuleConditionPlus (state, action) {
  const { qutter_id, rule_idx } = action.payload;
  let rule = state[qutter_id].rules[rule_idx];
  if (!rule.conditions) {
    state[qutter_id].rules[rule_idx] = {
      ...state[qutter_id].rules[rule_idx],
      conditions: [new_condition()]
    };
  } else {
    rule.conditions.push(new_condition());
  }
}
export function RuleConditionPlus (state, action) {
  const { qutter_id, rule_idx } = action.payload;
  let rule = state[qutter_id].rules[rule_idx];
  if (!rule.conditions) {
    state[qutter_id].rules[rule_idx] = {
      ...state[qutter_id].rules[rule_idx],
      conditions: [new_condition()]
    };
  } else {
    rule.conditions.push(new_condition());
  }
}
/** END Add Rule Condition */


/** Delete Rule Condition */
// EXACTLY THE SAME
export function RuleConditionRemoveByIndex (state, action) {
  const { qutter_id, rule_idx, condition_idx } = action.payload;
  state[qutter_id].rules[rule_idx].conditions.splice(condition_idx, 1);
}
export function CustomRuleConditionRemoveByIndex (state, action) {
  const { qutter_id, rule_idx, condition_idx } = action.payload;
  state[qutter_id].rules[rule_idx].conditions.splice(condition_idx, 1);
}
/** END Delete Rule Condition */

/** Update Rule Condition */
// EXACTLY THE SAME
export function RuleConditionUpdate (state, action) {
  const { qutter_id, rule_idx, condition_idx, condition } = action.payload;
  state[qutter_id].rules[rule_idx].conditions[condition_idx] = condition;
}
export function CustomRuleConditionUpdate (state, action) {
  const { qutter_id, rule_idx, condition_idx, condition } = action.payload;
  state[qutter_id].rules[rule_idx].conditions[condition_idx] = condition;
}
/** END Update Rule Condition */
/********************** END Rule Conditions ***********************/



/********************** Rule Actions ***********************/
/** Add Rule Action */
function new_action() {
  const DEFAULT_ACTION = {
    id: nanoid(),
    name: '',
    value: null
  };
  return DEFAULT_ACTION;
}

/** Add Rule Action */
// EXACTLY THE SAME
export function RuleActionPlus (state, action) {
  const { qutter_id, rule_idx } = action.payload;
  let rule = state[qutter_id].rules[rule_idx];
  if (!rule.actions) {
    state[qutter_id].rules[rule_idx] = {
      ...state[qutter_id].rules[rule_idx],
      actions: [new_action()]
    };
  } else {
    rule.actions.push(new_action());
  }
}
export function CustomRuleActionPlus (state, action) {
  const { qutter_id, rule_idx } = action.payload;
  let rule = state[qutter_id].rules[rule_idx];
  if (!rule.actions) {
    state[qutter_id].rules[rule_idx] = {
      ...state[qutter_id].rules[rule_idx],
      actions: [new_action()]
    };
  } else {
    rule.actions.push(new_action());
  }
}
/** END Add Rule Action */

/** Rule Action Remove By Index */
// EXACTLY THE SAME
export function RuleActionRemoveByIndex (state, action) {
  const { qutter_id, rule_idx, action_idx } = action.payload;
  state[qutter_id].rules[rule_idx].actions.splice(action_idx, 1);
}
export function CustomRuleActionRemoveByIndex (state, action) {
  const { qutter_id, rule_idx, action_idx } = action.payload;
  state[qutter_id].rules[rule_idx].actions.splice(action_idx, 1);
}
/** END Rule Action Remove By Index */

/** Rule Action Update */
// EXACTLY THE SAME
export function RuleActionUpdate (state, action) {
  const { qutter_id, rule_idx, action_idx, updated_action } = action.payload;
  state[qutter_id].rules[rule_idx].actions[action_idx] = updated_action;
}
export function CustomRuleActionUpdate (state, action) {
  const { qutter_id, rule_idx, action_idx, updated_action } = action.payload;
  state[qutter_id].rules[rule_idx].actions[action_idx] = updated_action;
}
/** END Rule Action Update */

/** Rule Action Update Value */
// EXACTLY THE SAME
export function DeleteRuleActionValueByIndex (state, action) {
  const { qutter_id, rule_idx, action_idx, value_idx } = action.payload;
  state[qutter_id].rules[rule_idx].actions[action_idx].value.splice(value_idx, 1);
}
export function CustomDeleteRuleActionValueByIndex (state, action) {
  const { qutter_id, rule_idx, action_idx, value_idx } = action.payload;
  state[qutter_id].rules[rule_idx].actions[action_idx].value.splice(value_idx, 1);
}
/** END Rule Action Update Value */

/********************** END Rule Actions ***********************/