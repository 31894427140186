import React from 'react';
import { Link } from 'react-router-dom';
import {  Row, Col, Card, Button } from 'react-bootstrap';

export const CandidateHeader = ({
  candidate
}) => {
  // console.log('<CandidateHeader />');
  const first = candidate.firstName ? candidate.firstName: candidate.first_name;
  const last = candidate.lastName ? candidate.lastName: candidate.last_name;
  const fullname = `${first} ${last}`;

  return (
  <Card className='mb-2'>

    <Card.Header>
      <Link 
        target='_blank' 
        to={`/candidates/${candidate.id ? candidate.id: candidate._id}`}
      >
        {fullname}
      </Link>
    </Card.Header>

    <Card.Body className='pt-0'>
      <Row>
        <Col>
          <h5 className="fs-0 fw-normal">
            Email: {candidate.email}
          </h5>
        </Col>
      </Row>
    </Card.Body>
  </Card>
  );
};