import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QutterRulesModal } from 'features/Modals';

export const QutterRulesOverlay = ({
  template_id,
  block_id,
  qutter_id,
  rules
}) => {
  const [rules_modal_show, set_rules_modal_show] = useState(false);

  return (
  <>
    {rules_modal_show && (
      <QutterRulesModal
        template_id={template_id}
        block_id={block_id}
        qutter_id={qutter_id}
        showModal={rules_modal_show}
        setShowModal={set_rules_modal_show}
      />
    )}
    <OverlayTrigger
      placement='left'
      overlay={
      <Tooltip>
        <div>
        <span>Branching</span>
        {rules.map(rule => { 
          return (
            <div key={`${rule.id}`}>{rule.name}</div>
          )
        })}
        </div>
      </Tooltip>
      }
    >
    {({ ref, ...triggerHandler }) => (
      <span
        ref={ref}
        {...triggerHandler}
        onClick={() => { set_rules_modal_show(!rules_modal_show) }}
        className={`cursor-pointer ${rules.length > 0 ? 'color-blue' : 'hover-blue'} me-3 font-size-10`}
      >
        <FontAwesomeIcon icon={faCodeBranch} />
      </span>
    )}
    </OverlayTrigger>
  </>
  );
};