import { nanoid } from '@reduxjs/toolkit';

export function ToggleBlock (state, action) {
  const { id, block_id } = action.payload;
  const is_open = state[id].blocks.map[block_id].is_open;
  state[id].blocks.map[block_id].is_open = !is_open;
}

export function UpdateBlockList (state, action) {
  const { id, block_id, question_ids } = action.payload;
  const block = state[id].blocks.map[block_id];
  if (block) {
    block.question_ids = question_ids;
  }
};

export function RemoveFromBlockAtIndex (state, action) {
  const { template_id, block_id, idx } = action.payload;
  const template = state[template_id];
  template.blocks.map[block_id].nodes.splice(idx, 1);
};

export function AddToBlockAtIndex (state, action) {
  const { template_id, block_id, idx, qutter_id, new_node } = action.payload;
  const template = state[template_id];
  if (new_node) {
    template.blocks.map[block_id].nodes.splice(idx, 0, new_node);
  } else {
    const node = { type: 'question', id: qutter_id };
    template.blocks.map[block_id].nodes.splice(idx, 0, node);
  }
};

export function AddBlock(state, action) {
  const { id, block_idx, name } = action.payload;
  const block_id = nanoid();
  const newBlock = {
    id: id,
    name: name,
    is_open: true,
    nodes: []
  };
  state[id].blocks.map[block_id] = newBlock;
  state[id].blocks.ids.splice(block_idx + 1, 0, block_id);
};

export function EditBlockName (state, action) {
  const { id, block_id, name } = action.payload;
  state[id].blocks.map[block_id].name = name;
};

export function RemoveBlock(state, action) {
  const { id, block_id, block_idx } = action.payload;
  let idx = state[id].blocks.ids.indexOf(block_id);
  if (idx > -1) {
    delete state[id].blocks.map[block_id];
    state[id].blocks.ids.splice(idx, 1);
  }
};

export function AddQutterToTemplateBlock (state, action) {
  const { id, block_id, question_id, node_idx } = action.payload;
  console.log('AddQutterToTemplateBlock node_idx', node_idx);
  const block = state[id].blocks.map[block_id];
  if (!block.nodes) block.nodes = [];
  let idx = block.nodes.findIndex(node => node.type === 'question' && node.id === question_id);
  if (idx === -1) {
    block.nodes.splice((node_idx + 1), 0, { type: 'question', id: question_id });
  }
};

export function AddNodeToTemplateBlock (state, action) {
  const { template_id, block_id, node_idx, new_node } = action.payload;
  const block = state[template_id].blocks.map[block_id];
  if (!block.nodes) block.nodes = [];
  block.nodes.splice(node_idx + 1, 0, new_node);
};

export function RemoveQutterFromBlock (state, action) {
  const { id, block_id, qutter_id } = action.payload;
  const template = state[id];
  const parameters = template.parameters;
  const block = template.blocks.map[block_id];

  // let idx = block.question_ids.indexOf(qutter_id);
  // // Remove from block
  // if (idx > -1) block.question_ids.splice(idx, 1);

  let index = block.nodes.findIndex(node => node.type === 'question' && node.id === qutter_id);
  // Remove from nodes
  if (index > -1) block.nodes.splice(index, 1);
  // Remove any custom stuff
  if (parameters && parameters.hasOwnProperty(qutter_id))
    delete parameters[qutter_id];
}

export function RemoveNodeActionAtIndex (state, action) {
  const { template_id, block_id, node_idx, action_idx } = action.payload;
  const block = state[template_id].blocks.map[block_id];
  const node = block.nodes[node_idx];
  const actions = node.actions;
  actions.splice(action_idx, 1);
  if (actions.length === 0) {
    block.nodes.splice(node_idx, 1);
  }
};