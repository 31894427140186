import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Popover, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFloppyDisk,
  faPen,
  faAsterisk
} from '@fortawesome/pro-solid-svg-icons';

import { ScoringCategoryList } from './ScoringCategoryList';

import { updateCategoryWeight } from 'features/QutterEvaluation';
import { capitalizeFirstLetter } from 'features/common';

export const ScoringCategoryPopover = (
  template_id,
  category_id,
  category,
) => {
  const dispatch = useDispatch();
  const [edit_mode, set_edit_mode] = useState(false);
  const [weight, set_weight] = useState(category.weight);

  let name = category_id.split('_').join(' ');
  name = capitalizeFirstLetter(name);

  return (
    <Popover style={{ minWidth: '400px' }}>
      <Popover.Header as="h3">
        {name} ({category.weight * 100}%)
      </Popover.Header>
      <Popover.Body>

        <div>
          <span className='font-size-10'>
            <FontAwesomeIcon
              className='red me-1'
              size={'sm'} 
              icon={faAsterisk}
            />
            <span>= must be answered to score the category.</span>
          </span>
        </div>

        <ScoringCategoryList
          template_id={template_id}
          category_id={category_id}
          category={category}
        />

        {edit_mode ? (
          <div className='d-flex font-size-10 mt-2'>
            <div className='w-75 text-start'>
              <span
                style={{ fontSize: '9px', fontWeight: 900 }}
              >
                <Form.Control 
                  defaultValue={category.weight * 100}
                  type='number'
                  placeholder="%"
                  onChange={(e) => {
                    const w = parseFloat(e.target.value) / 100;
                    set_weight(w);
                  }}
                />
              </span>
            </div>
            <div className='w-25 text-end m-auto'>
              <span
                onClick={() => {
                  dispatch(updateCategoryWeight({
                    category_id,
                    weight
                  }));
                  set_edit_mode(false);
                }}
                className='hover-blue d-block font-size-10 m-auto'
              >
                <FontAwesomeIcon
                  className='me-1 m-auto'
                  size={'sm'} 
                  icon={faFloppyDisk}
                />
                Save
              </span>
            </div>
          </div>
        ):(
          <div className='text-end mt-2'>
            <span
              onClick={() => { set_edit_mode(true); }}
              className='hover-blue d-block font-size-10'
            >
              <FontAwesomeIcon
                className='me-1'
                size={'sm'} 
                icon={faPen}
              />
              Edit category weight
            </span>
          </div>
        )}
      </Popover.Body>
    </Popover>
  );
}