import React, { useRef, useState } from 'react';
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { copyToClipBoard, copy_text_to_clipboard } from 'helpers/utils';

export const ClickToCopy = ({
  url,
  text,
  tooltip_text,
  className
}) => {
  const [tooltipText, setTooltipText] = useState(tooltip_text ? tooltip_text: 'Click to copy');
  const copyTextRef = useRef(null);
  const copyBtnRef = useRef(null);

  const handleCopyText = () => {
    copy_text_to_clipboard(url);
    setTooltipText('Copied!');
  };

  return (
  <div className={className}>
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={<Tooltip>{tooltipText}</Tooltip>}
    >
      <span>
        <IconButton
          onMouseLeave={() =>
            setTooltipText(tooltip_text)
          }
          ref={copyBtnRef}
          onClick={handleCopyText}
          variant="link"
          size="sm"
          icon={['far', 'copy']}
          className="text-decoration-none fs--2"
          iconClassName="me-2"
        >
          {text ? (
            <span>{text}</span>
          ):(
            <span>{'Copy link'}</span>
          )}
        </IconButton>
      </span>
    </OverlayTrigger>
  </div>
  );
};
