import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HubiverseTagsBranch } from './HubiverseTagsBranch';

export const HubiverseTagsTree = ({
  // data,
  // list,
  map,
  root_tags,
  selection,
  expanded = [],
  selectedItems = [],
  setSelectedItems,
  on_tag_click,
  on_tag_check,
}) => {
  const [openedItems, setOpenedItems] = useState(expanded);

  // if (!data || !data.length) return null;
  if (!root_tags || !root_tags.length) return null;

  console.log('selectedItems', selectedItems)
  console.log('openedItems', openedItems)

  return (
    <ul className="treeview treeview-select">
      {root_tags.map((id, index) => {
        return (
          <HubiverseTagsBranch
            key={index}
            id={id}
            map={map}

            openedItems={openedItems}
            setOpenedItems={setOpenedItems}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            selection={selection}

            on_tag_click={on_tag_click}
            on_tag_check={on_tag_check}
          />
        )
      })}
    </ul>
  );
};

HubiverseTagsTree.propTypes = {
  // data:         PropTypes.array.isRequired,
  // list:         PropTypes.array.isRequired,
  map:          PropTypes.object.isRequired,
  root_tags:    PropTypes.array.isRequired,

  selection: PropTypes.bool, // If true selection is enabled.
  expanded: PropTypes.array, // Default expanded children ids.
  selectedItems: PropTypes.array, // Selected item ids..
  setSelectedItems: PropTypes.func, // Setter to select items
  on_tag_click: PropTypes.func // Setter to select items
};


// return (
//   <ul className="treeview treeview-select">
//     {data.map((treeviewItem, index) => {
//     return (
//       <TreeviewListItem
//         key={index}
//         item={treeviewItem}
//         openedItems={openedItems}
//         setOpenedItems={setOpenedItems}
//         selectedItems={selectedItems}
//         setSelectedItems={setSelectedItems}
//         selection={selection}
//         on_tag_click={on_tag_click}
//       />
//     )})}
//   </ul>
// );