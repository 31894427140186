import { StripHTML } from "features/common";

export function prepare_qutter_list (library, qutter_ids, current_qutter_id) {
  let list = [];
  for (let i = 0; i < qutter_ids.length; i++) {
    let qutter_config = library[qutter_ids[i]];

    // Only single/multiple selects
    if (!['single-select', 'multiple-select'].includes(qutter_config.type)) continue;
    // Cannot condition on the same question as the current one
    if (qutter_config.id === current_qutter_id) continue;

    const languages = qutter_config.available_languages;
    let label = '';
    // Add question text in all languages
    for (let k = 0; k < languages.length; k++) {
      if (k === 0) label += StripHTML(qutter_config[languages[k]].text);
      else label += `/${StripHTML(qutter_config[languages[k]].text)}`;
    }
    list.push({ label: label, value: qutter_config.id });
  }
  return list;
}
