import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Score from '../../../_components/score/Score';
import ScoreSummary from '../../../_components/score/ScoreSummary';

import { CandidateOffCanvasButton } from 'features/Candidate/components/CandidateOffCanvasButton';
import { JobOffCanvasTab } from 'features/Job';

import { SCORE_INTERVIEW } from '../api/gql';

export const ScoreContainer = ({ id }) => {

  const { loading, error, data } = useQuery(
    SCORE_INTERVIEW,
    { variables: { interview_id: id }}
  );

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const interview = data.ScoreInterviewById;

  const job = interview && interview.application && interview.application.job ? 
    interview.application.job: {};
  const candidate = interview && interview.application && interview.application.candidate ? 
    interview.application.candidate: {};
  const application = interview && interview.application ? { ...interview.application }: {};

  return (
    <div className=' /*d-flex*/'>
      <Score interview={interview} />
      <div 
        className='text-center p-2 w-100'
        style={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          backgroundColor: 'black',
          color: 'white'
        }}
      >
        <span 
          className='h4 me-1'
          style={{ color: 'white' }}
        >{job.title}</span>

        {job && job.role_id && (
          <span className='h7 ms-1'>
            {`| ${job.role_id.split('_').join(' ')}`}
          </span>
        )}

        <JobOffCanvasTab
          job={job}
          template_id={interview.interview_id}
        />
        <CandidateOffCanvasButton
          candidate_id={candidate.id}
          candidate={candidate}
        />
      </div>

      <ScoreSummary
        job={job}
        application={application}
        interview={interview}
        candidate_id={candidate.id}
      />
    </div>
  );
};
