import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

// import CreateQutter from '../../QutterCreate/components/QutterCreate';
import { QutterCreate } from 'features/QutterCreate';

import {
  toggleCreateQuestionModal
} from 'features/Modals/slices/ModalSlice';

export const CreateQutterModal = ({
  header,
  showModal,
  qutter_id
}) => {
  const dispatch = useDispatch();

  const onCloseModalClick = (e) => {
    dispatch(toggleCreateQuestionModal({
      show: false,
      qutter_id: null,
      language: null,
    }));
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        onCloseModalClick();
      }}
      dialogClassName="create-question-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {header ? header: 'Create Question'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <QutterCreate id={qutter_id} />
      </Modal.Body>
    </Modal>
  );
};
