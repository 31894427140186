import React from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { DeleteRuleActionButton } from '../..';
import { RuleActionJumpTo } from './RuleActionJumpTo';

import { customRuleActionRemoveByIndex, customRuleActionUpdate } from 'features/Parameters';
import { ruleActionRemoveByIndex, ruleActionUpdate } from 'features/Qutters';

import { then_types } from '../../constants/condition_types';
import { findOptionValue } from 'features/common';

export const RuleAction = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  action_idx,
  action,
}) => {
  const dispatch = useDispatch();

  return (
  <div
    className='m-1 p-2 mb-2'
    style={{ border: '1px solid lightgray', borderRadius: '3px' }}
  >

    <div className='d-flex m-2'>
      <div className='w-75 text-start m-auto'>
        If above conditions are met...
      </div>
      <div className='w-25 text-end'>
        <DeleteRuleActionButton
          template_id={template_id}
          is_default={is_default}
          on_click={() => {
            if (template_id) {
              dispatch(customRuleActionRemoveByIndex({ template_id, qutter_id, rule_idx, action_idx }));
            } else {
              dispatch(ruleActionRemoveByIndex({ qutter_id, rule_idx, action_idx }));
            }
          }}
        />
      </div>
    </div>

    <div className='d-flex font-size-10 m-1'>
      <div className='w-25 text-start m-auto'>
        Then: 
      </div>
      <div className='w-50 text-start'>
        <Select
          defaultValue={action ? findOptionValue(then_types, action.name): null}
          placeholder='Choose action'
          options={then_types}
          isDisabled={template_id && is_default ? true: false}
          onChange={(e) => {
            let updated_action = Object.assign({}, action);
            updated_action.name = e.value;
            if (template_id) {
              dispatch(customRuleActionUpdate({
                template_id,
                qutter_id,
                rule_idx,
                action_idx,
                updated_action
              }));
            } else {
              dispatch(ruleActionUpdate({
                qutter_id,
                rule_idx, 
                action_idx,
                updated_action
              }));
            }
          }}
        />
      </div>
    </div>

    {action.name && action.name.length ? (
      <div className=''>
        {(() => {
          if (action.name === 'JUMP_TO') {
            return (
              <RuleActionJumpTo 
                template_id={template_id}
                qutter_id={qutter_id}
                is_default={is_default}
                rule_idx={rule_idx}
                action_idx={action_idx}
                action={action}
              />
            );
          }
        })()}
      </div>
    ):
    (null)}
  </div>
  );
};