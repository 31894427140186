import { createSlice } from '@reduxjs/toolkit';
import { NOT_VIEWABLE_BLOCK_IN_SUMMARY } from '..';

const initialState = {
  block_ids: [],
  blocks: {},
  approved: false,
  approved_by: null,
  approved_date: null,
};

const InterviewSummarySlice = createSlice({
  name: 'interview_summary',
  initialState: {},
  reducers: {
    ResetInterviewSummary (state, action) {
      const { template_id, interview_summary } = action.payload;

      if (interview_summary === null) 
        return initialState;
      // if (!interview_summary || Object.keys(interview_summary).length === 0) {
      //   return {
      //     ...state,
      //     [template_id]: initialState
      //   };
      // }

      return {
        [template_id]: interview_summary
      };
    },
    update_block_ids (state, action) {
      const { template_id, block_ids } = action.payload;
      const summary = state[template_id];
      summary.block_ids = block_ids;
    },
    update_blocks (state, action) {
      const { template_id, blocks } = action.payload;
      const summary = state[template_id];
      summary.blocks = blocks;
    },
    add_interview_summary_block (state, action) {
      const { template_id, block_id, block } = action.payload;
      const summary = state[template_id];
      summary.blocks[block_id] = block;
      summary.block_ids.unshift(block_id);
    },
    move_interview_summary_block (state, action) {
      const { template_id, block_id, to_idx } = action.payload;
      const summary = state[template_id];
      const current_idx = summary.block_ids.findIndex((id) => id === block_id);
      summary.block_ids.splice(current_idx, 1);
      summary.block_ids.splice(to_idx, 0, block_id);
      // Put non viewable last
      const non_view_idx = summary.block_ids.findIndex((id) => id === NOT_VIEWABLE_BLOCK_IN_SUMMARY);
      summary.block_ids.splice(non_view_idx, 1);
      summary.block_ids.push(NOT_VIEWABLE_BLOCK_IN_SUMMARY);
    },
    remove_interview_summary_block (state, action) {
      const { template_id, block_id } = action.payload;
      const summary = state[template_id];
      let idx = summary.block_ids.indexOf(block_id);
      if (idx > -1) {
        // Move to "No show" block
        const details = summary.blocks[block_id].details;
        if (summary.block_ids.indexOf(NOT_VIEWABLE_BLOCK_IN_SUMMARY) > -1) {
          const list = [...summary.blocks[NOT_VIEWABLE_BLOCK_IN_SUMMARY].details, ...details];
          summary.blocks[NOT_VIEWABLE_BLOCK_IN_SUMMARY].details = list;
        } else {
          summary.blocks[NOT_VIEWABLE_BLOCK_IN_SUMMARY] = {
            id: NOT_VIEWABLE_BLOCK_IN_SUMMARY,
            name: 'No show',
            details: details
          }
          summary.block_ids.push(NOT_VIEWABLE_BLOCK_IN_SUMMARY);
        }
        delete summary.blocks[block_id];
        summary.block_ids.splice(idx, 1);
      }
    },

    remove_from_block_at_idx (state, action) {
      const { template_id, block_id, idx } = action.payload;
      const summary = state[template_id];
      summary.blocks[block_id].details.splice(idx, 1);
    },

    add_to_block_at_idx (state, action) {
      const { template_id, block_id, idx, qutter_id } = action.payload;
      const summary = state[template_id];
      summary.blocks[block_id].details.splice(idx, 0, { id: qutter_id });
    },

    add_to_default_qutter_block (state, action) {
      const { template_id, block_id, qutter_id } = action.payload;
      const summary = state[template_id];
      
      let idx = summary.block_ids.indexOf(block_id);
      if (idx > -1) {
        summary.blocks[block_id].details.push(qutter_id);
      } else {
        const len = summary.block_ids.length;
        const block_ids = summary.block_ids.slice();
        summary.blocks[block_id] = {
          id: block_id,
          name: '',
          details: []
        };
        summary.block_ids = block_ids.splice(len - 2, 0, block_id);
      }
    },

    approve (state, action) {
      const {
        template_id,
        approved,
        approved_by,
        approved_date
      } = action.payload;
      const summary = state[template_id];
      summary.approved = approved;
      summary.approved_by = approved_by;
      summary.approved_date = approved_date;
    },

    update_label_at_idx (state, action) {
      const {
        template_id,
        block_id,
        detail_idx,
        label
      } = action.payload;
      const summary = state[template_id];
      summary.blocks[block_id].details[detail_idx].label = label;
    },
  }
});

export const {
  ResetInterviewSummary,
  update_block_ids,
  update_blocks,
  add_interview_summary_block,
  remove_interview_summary_block,
  move_interview_summary_block,
  remove_from_block_at_idx,
  add_to_block_at_idx,
  add_to_default_qutter_block,
  approve,

  update_label_at_idx
} = InterviewSummarySlice.actions;
export default InterviewSummarySlice.reducer;
