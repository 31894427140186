

export function AddJob (state, action) {
  const { id, job } = action.payload;
  state[id] = job;
  state[id].status = 'loading';
};

export function EditJobClient (state, action) {
  const { id, client_id } = action.payload;
  state[id].client_id = client_id;
};

export function EditJobTemplateIds (state, action) {
  const { id, template_ids } = action.payload;
  state[id].templates = template_ids;
};

export function EditJobInterviewLanguage (state, action) {
  const { id, interview_language } = action.payload;
  state[id].interview_language = interview_language;
};

export function EditJobTitle (state, action) {
  const { id, title } = action.payload;
  state[id].title = title;
};

export function EditJobThreshold (state, action) {
  const { id, threshold } = action.payload;
  state[id].threshold = threshold;
};

export function EditJobResponsibleCS (state, action) {
  const { id, responsible_cs } = action.payload;
  state[id].responsible_cs = responsible_cs;
};

export function EditJobCompanyName (state, action) {
  const { id, company_name } = action.payload;
  state[id].company.name = company_name;
};

export function EditJobCompanyDepartment (state, action) {
  const { id, department } = action.payload;
  state[id].company.department = department;
};

export function EditJobDescription (state, action) {
  const { id, description } = action.payload;
  state[id].description = description;
};

export function EditJobFormOfEmployment (state, action) {
  const { id, form_of_employment } = action.payload;
  state[id].position.form_of_employment = form_of_employment;
};

export function EditJobRoleId (state, action) {
  const { id, role_id } = action.payload;
  state[id].role.id = role_id;
};

export function EditJobRoleName (state, action) {
  const { id, name } = action.payload;
  state[id].role.name = name;
};
