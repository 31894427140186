import React, { useState } from 'react';

import { Card, Row, Col, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from '_components/common/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

import { ApplicationScore } from 'features/Application';

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

let n = 0;
const collator = new Intl.Collator(undefined, { sensitivity: 'accent' });
const columns = [
  {
    accessor: (row) => {
      const candidate = row.data?.candidate;
      return {
        candidate_id: candidate?._id,
        first_name: candidate?.first_name,
        last_name: candidate?.last_name,
      };
    },
    sortType: (a, b, id) => {
      const x = a.values[id];
      const y = b.values[id]; 
      const r = collator.compare(x.first_name, y.first_name);
      return r === 0 ? collator.compare(x.last_name, y.last_name) : r;
    },
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { candidate_id, first_name, last_name } = rowData.value;
      return (
        <Link 
          to={`/candidates/${candidate_id}`}
          target="_blank" rel="noopener noreferrer" 
        >
          <h5 className="mb-0 fs--1">{first_name} {last_name}</h5>
        </Link>
      );
    },
  },
  {
    accessor: 'data.application.score',
    sortType: (a, b, id, desc) => {
      const d = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
      const x = a.values[id];
      const y = b.values[id];
      return (x ?? d) - (y ?? d);
    },
    Header: 'Score',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => (<ApplicationScore score={rowData.value} />),
  },
  {
    accessor: (row) => row.data?.candidate?.email ?? '-',
    sortType: (a, b, id) => collator.compare(a.values[id], b.values[id]),
    Header: 'Email',
    Cell: rowData => <span>{rowData.value}</span>,
  },
  {
    accessor: 'data.candidate.created_at',
    Header: 'Created',
    Cell: rowData => {
      const createdAt = rowData.value;
      const display = createdAt ? new Date(createdAt).toLocaleDateString('en-UK', options) : '-';
      return <span>{display}</span>;
    }
  },
  {
    accessor: 'data.candidate',
    Header: 'Link',
    Cell: rowData => {
      const candidateId = rowData.value?._id;
      return candidateId ? (
        <Link 
          to={`/candidates/${candidateId}/v2`}
          target="_blank" rel="noopener noreferrer" 
        >
          <h5 className="mb-0 fs--1">SUM</h5>
        </Link>
      ) : <span></span>;
    }
  }
];

export const CandidatesListV2 = ({
  loading,
  list
}) => {
  console.log('candidatesList', list);

  if (loading) {
    return (
      <div className='text-center m-4'>
        <Spinner animation="border" variant="dark" />
      </div>
    )
  }

  return (
  <AdvanceTableWrapper
    columns={columns}
    data={list && list.length ? list: []} 
    selection
    sortable
    pagination
    perPage={40}
  >
    <Card>
      <Card.Header>
        <Row className="flex-end-center mb-2 mt-2">
          <Col>
            <h5 className="mb-0">Candidates</h5>
          </Col>
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body className="p-0">
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            size: 'sm',
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
          // isLoading={loading}
        />
      </Card.Body>
      <Card.Footer>
        <AdvanceTablePagination table />
      </Card.Footer>
    </Card>
  </AdvanceTableWrapper>
  );
};