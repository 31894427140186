import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

import { TemplateQuestionSelect } from '..';
import { TemplateQuestionAnswer } from '..';
import { Operator } from '..';

export const TemplateQuestionAnswerFilter = ({
  disabled,
  placeholder,
  className,

  on_select,
  template_id,
  filters,
  filter_idx,
  set_filters,
}) => {
  console.log('TemplateQuestionAnswerFilter', filters[filter_idx])
  const [question_selection, set_question_selection] = useState(null);
  const [question, set_question] = useState(null);
  const [operator, set_operator] = useState(null);
  const [answers_selection, set_answers_selection] = useState(null);

  useEffect(() => {
    let f = Object.assign({}, filters[filter_idx]);
    f.operator = operator;
    let fs = Object.assign([], filters);
    fs[filter_idx] = f;
    set_filters([...fs])
  }, [operator]);

  useEffect(() => {
    let f = Object.assign({}, filters[filter_idx]);
    f.question_selection = question_selection;
    let fs = Object.assign([], filters);
    fs[filter_idx] = f;
    set_filters([...fs])
  }, [question_selection]);

  useEffect(() => {
    let f = Object.assign({}, filters[filter_idx]);
    f.answers_selection = answers_selection;
    let fs = Object.assign([], filters);
    fs[filter_idx] = f;
    set_filters([...fs])
  }, [answers_selection]);

  const on_remove_click = () => {
    let fs = Object.assign([], filters);
    fs.splice(filter_idx, 1);
    set_filters([...fs]);
  }

  return (
  <div>

    <div>
      <div className='d-flex'>
        <div className='w-75'>
          <TemplateQuestionSelect
            className={className}
            disabled={disabled}
            placeholder={placeholder}

            template_id={template_id}
            on_select={(e, selected) => {
              set_question_selection(e)
              set_question(selected)
            }}
          />
        </div>
        <div className='w-10 m-auto text-center'>
          <span
            className="hover-blue m-auto"
            onClick={() => { on_remove_click(); }}
          >
            <FontAwesomeIcon icon={faTrash} className="me-1"/>
          </span>
        </div>
      </div>
    </div>


    {question && ![null, undefined].includes(filters[filter_idx].question_selection) ? (
    <div className='mt-2'>
      <div className='d-flex'>
        <div className='w-25'>

          <Operator
            filter={filters[filter_idx]}
            set_operator={set_operator}
            on_select={(e) => {
              console.log('operator: ', e)
              set_operator(e);
            }}
          />
          {/* OPERATOR: {JSON.stringify(operator)} */}
        </div>

        <div className='w-75'>

          <TemplateQuestionAnswer
            question={question}
            filter={filters[filter_idx]}
            on_select={(e) => {
              console.log('answer selection: ', e)
              set_answers_selection(e)
            }}
          />

        </div>
      </div>
    </div>
    ):(null)}

  </div>
  );
};
