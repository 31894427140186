import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import { Card, Table } from 'react-bootstrap';
import { Loading } from 'features/common';

import { LANGUAGE_FLAGS } from 'features/LanguageSelect/assets/constants';
import { GetTemplateJobs } from '../api/api';

export const TemplateJobs = ({
  templateId
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    GetTemplateJobs(templateId)
    .then((result) => {
      setJobs(result);
    }).catch((error) => {
      const e = JSON.stringify(error);
      setError(true);
      setErrorMessage(e);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  if (loading) return <span>loading jobs list... <Loading /></span>;
  if (error) return <span>Error: {errorMessage}</span>;

  return (
  <Card className="mb-3">

    <Card.Body className="p-0">
      <Table className='m-0'>
        <tbody>
          {jobs.map((job, idx) => {
            if (!job) return null;
            const url = `/jobs/${job._id}`;

            return (
              <tr
                key={`${job._id}-${idx}`}
              >
                <td>
                  <p className="mb-0">
                    <Link to={url}>{job.title}</Link>
                  </p>
                </td>
                <td>
                  <p className="mb-0">
                    {job.created_at ? new Date(job.created_at).toLocaleString(): '-'}
                  </p>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Card.Body>
  </Card>
  );
};
