import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/pro-solid-svg-icons';

export const BonusIcon = ({
  bonuspoints
}) => {

  return (
    <span
      className="cursor-pointer fa-layers fa-fw"
    >
      <FontAwesomeIcon 
        style={{ color: '#36B37E' }}
        size={'lg'}
        icon={faCertificate}
      />
      <span 
        className="fa-layers-text fa-inverse font-size-10" 
        style={{ fontWeight: '900' }}
      >
        +{bonuspoints}
      </span>
    </span>
  );
};