import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import AdvanceTableWrapper from '_components/common/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

import { LANGUAGE_LABELS } from 'features/LanguageSelect/assets/constants';
import { ViewDefaultQutterModal } from 'features/Modals';
import { QutterTypeBadges } from 'features/QutterType';
import { QutterListBulkActions } from './QutterListBulkActions';
import { QutterShortDescription } from 'features/QutterCreate';

export const QutterList = ({
  list,
  isLoading=false,
  setShowModal=null,
  selection=false,
  add_policy=false,
  template_id=null,
  block_id=null,
  block_name=null,
  qutter_id=null,
  qutter_idx=null,
  option_idx=null,
  option_id=null,
}) => {
  // const [qutter_list, set_qutter_list] = useState(list);
  const [openQutter, setOpenQutter] = useState(null);
  const [showQuestionModal, setShowQuestionModal] = useState(false);

  const columns = [
    {
      accessor: 'text',
      Header: 'Question Text',
      headerProps: { className: 'pe-1' },
      cellProps: { className: 'py-2' },
      Cell: rowData => {
        const { id, available_languages, short_description } = rowData.row.original;
        return (
          available_languages.map((language) => {
            let text = ''
            if (rowData.row.original.hasOwnProperty(language)) {
              text = rowData.row.original[language].text.join('');
            } else {
              return (null)
            }
            return (
              <div>
                <QutterShortDescription 
                  qutter_id={id}
                  shortDescription={short_description}
                />
                <p
                  dangerouslySetInnerHTML={{ __html: text }}
                  onClick={() => { onQuestionTextClick(id, language) }}
                  className={`d-block hover-blue cursor-pointer ${id === qutter_id ? 'removed': ''}`}
                ></p>
              </div>
            )
          })
        )
      }
    },
    {
      accessor: 'type',
      Header: 'Question Type',
      Cell: rowData => {
        const { type } = rowData.row.original;
        return <span>{QutterTypeBadges[type]}</span>;
      }
    },
    {
      accessor: 'language',
      Header: 'Language',
      Cell: rowData => {
        const { available_languages } = rowData.row.original;
        return (
          available_languages.map((lang) => {
            return <p>{LANGUAGE_LABELS[lang]}</p>;
          })
        )
      }
    }
  ];

  const onQuestionTextClick = (id, language) => {
    setOpenQutter({ id: id, language: language });
    setShowQuestionModal(true);
  }

  // console.log('QutterList', list)

  return (list && list.length ? (
    <>
    {openQutter && openQutter.id && openQutter.language && (
      <ViewDefaultQutterModal
        id={openQutter.id}
        language={openQutter.language}
        forced_language={openQutter.language}
        showModal={showQuestionModal}
        setShowModal={setShowQuestionModal}
      />
    )}

    <AdvanceTableWrapper
      columns={columns}
      data={list}
      selection={selection}
      sortable
      pagination
      perPage={20}
    >
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-end-center mb-2 mt-2">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table/>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            isLoading={isLoading}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />

          <QutterListBulkActions
            add_policy={add_policy}
            setShowModal={setShowModal}
            template_id={template_id}
            qutter_id={qutter_id}
            block_id={block_id}
            block_name={block_name}
            list={list}
            qutter_idx={qutter_idx}
            option_idx={option_idx}
            option_id={option_id}
            table
          />

        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
    </>
  ):(
    <span>No questions.</span>
  ))
};

export default QutterList;
