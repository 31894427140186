import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

import {
  Container,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';

const ScoringTemplatePopover = ({
  role_id
}) => {
  const [role, setRole] = useState(role_id);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/scoring/v1/score_categories/${role}`)
    .then(res => {
      setCategories(res.data.categories);
    });
  }, [role, role_id]);

  return (
  <Popover id="popover-basic" style={{ minWidth: '200px !important', width: '200px !important' }}>
    <Popover.Header as="h3">
      <strong className='h6 text-capitalize text-decoration-underline'>
        Scoring template - {role}
      </strong>
    </Popover.Header>
    <Popover.Body 
      as={Container} 
      className='w-100'
      style={{ minWidth: '200px !important', width: '200px !important' }}
    >
      {categories && categories.length ? (
        categories.map((category, idx) => {
          return (
            <div key={idx}>
              {category.name}: {category.score}
            </div>
          )
        })
      ) : (
        <span>This role has no scoring template.</span>
      )}
    </Popover.Body>
  </Popover>
  )
}


const JobRoleScoringTemplatePopover = ({
  role_id
}) => {
  const [role, setRole] = useState(role_id);

  return (
    <OverlayTrigger
      placement='right'
      trigger='hover'
      delay={{ show: 100, hide: 100 }}
      overlay={ScoringTemplatePopover({ role_id: role })}
    >
      <span
        className='hover-blue m-auto ps-3 flex-1'
      >
        <FontAwesomeIcon size={'lg'} icon={faCircleInfo} />
      </span>
    </OverlayTrigger>
  );
};

export default JobRoleScoringTemplatePopover;
