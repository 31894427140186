import React, { useState }  from 'react';
import { useSelector } from 'react-redux';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import enGB from 'date-fns/locale/en-GB';     // British English
import enUS from 'date-fns/locale/en-US';     // American English (default - no setup needed)
import es from 'date-fns/locale/es';          // Spanish
import fr from 'date-fns/locale/fr';          // French
import sv from 'date-fns/locale/sv';          // Swedish
import fi from 'date-fns/locale/fi';          // Finnish
import nb from 'date-fns/locale/nb';          // Norwegian (bokmål)
import da from 'date-fns/locale/da';          // Danish

import { TemplateTexts } from 'features/PreviewQuestionTexts';
import { QutterHeader } from 'features/Template';
import { CalendarEvaluationList } from './CalendarEvaluationList';

registerLocale('es', es);
registerLocale('fr', fr);
registerLocale('sv-se', sv);
registerLocale('fi', fi);
registerLocale('en-GB', enGB);
registerLocale('en-US', enUS);
registerLocale('nb', nb);
registerLocale('da', da);

export const PreviewCalendar = ({
  template_id,
  block_id,
  block_name,
  block_idx,
  qutter_id,
  qutter_idx,
  order,
  provided,
  is_followup,
  parent_id
}) => {
  // console.log('<PreviewCalendar />');
  const [previewIsOpen, setPreviewIsOpen] = useState(false);

  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];

  const onFullPreviewClick = e => { setPreviewIsOpen(!previewIsOpen); };

  const locale = language === 'en-gb' ? 'en-GB':
    (language === 'en-us' ? 'en-US': language);
  return (
  <div
    key={`view-choice-qutter-${qutter_id}-${qutter_idx}`} 
    className={`mb-2 ${qutter && qutter.is_follow_up ? 'p-0': ''}`}
  >

    <QutterHeader
      template_id={template_id}
      block_id={block_id}
      qutter_id={qutter_id}
      order={order}
      previewIsOpen={previewIsOpen}
      onFullPreviewClick={onFullPreviewClick}
      provided={provided}
      is_followup={is_followup}
      parent_id={parent_id}
    />

    {qutter_config && qutter && (
    <div className={`pt-1 ${previewIsOpen ? '': 'd-none'}`}>

      {/* Intro (field: intro) */}
      <TemplateTexts
        template_id={template_id}
        qutter_id={qutter_id}
        field={'intro'}
      />
      {/* Question (field: text) */}
      <TemplateTexts
        template_id={template_id}
        qutter_id={qutter_id}
        field={'text'}
      />

      {/* Outro (field: outro) */}
      <TemplateTexts
        template_id={template_id}
        qutter_id={qutter_id}
        field={'outro'}
      />

      <p style={{ fontWeight: 600 }}>
        {/* {config.pre_text} */}
        {/* TODO: Calendar pre text */}
      </p>

      <div className='text-center'>
        <DatePicker
          // selected={date}
          // onChange={(date) => setDate(date)}
          dateFormat="yyyy-MM-dd"
          locale={locale}
          placeholderText="Weeks start on Monday"
          inline
          className='form-control'
          minDate={new Date()}
          disabled
        />
      </div>

      <CalendarEvaluationList
        template_id={template_id}
        qutter_id={qutter_id}
      />

    </div>
    )}

    {/* {template_id && is_followup !== true && (
      <AddQutterToBlock
        template_id={template_id}
        block_id={block_id}
        qutter_id={qutter_id}
        qutter_idx={qutter_idx}
      />
    )} */}

  </div>
  );
}
