import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { Button, Form } from 'react-bootstrap';

const EDIT_JOB_THRESHOLD = gql`
mutation EditThreshold($job_id: String!, $threshold: Int!) {
  EditThreshold(job_id: $job_id, threshold: $threshold)
}
`;

const JobThreshold = ({ job_id, _threshold }) => {
  // console.log('<JobThreshold />', _threshold);

  let { id } = useParams();
  if (job_id) id = job_id;
  else job_id = id;

  const [threshold, setThreshold] = useState(parseInt(_threshold));
  const [editMode, setEditMode] = useState(false);

  const [thresholdMutation, {data, loading, error}] = useMutation(EDIT_JOB_THRESHOLD);
  const handleChange = (e) => { setThreshold(parseInt(e.target.value)); };

  return (
    <>
      {
        (() => {
          if (loading) { return ('Updating threshold...'); }
          else 
          if (error) { return (`Error: ${error}`); }
          else 
          if (editMode) { 
            return (
            <div>
              <Form.Label htmlFor="inputThreshold">Threshold</Form.Label>
              <Form.Control
                type="number"
                id="inputThreshold"
                aria-describedby="thresholdHelpBlock"
                defaultValue={threshold}
                onChange={event => handleChange(event)}
              />
              <Form.Text id="thresholdHelpBlock" muted>
                Set the threshold for the job.
              </Form.Text>
    
              <Button
                variant='hubert'
                className="w-auto btn btn-sm"
                onClick={() => thresholdMutation({
                  variables: {
                    job_id: job_id,
                    threshold: threshold
                  }
                }).then(() => { setEditMode(false); })}
              >
                Done
              </Button>
            </div>
            );
          }
          else 
          if (threshold && parseInt(threshold) >= 0) {
            return (
              <div
                onClick={() => { editMode ? setEditMode(false): setEditMode(true); }}
              >
                Threshold: <strong className='link-black cursor-pointer'>{threshold}</strong>
              </div>
            );
          }
          else {
            return (
              <Button 
                className="w-auto btn btn-sm"
                variant='hubert'
                onClick={() => { editMode ? setEditMode(false): setEditMode(true); }}
              >
                + Add threshold
              </Button>
            );
          }
        })()
      }
    </>
  );
};

export default JobThreshold;
