import React from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

import { TemplateQutterSelect } from 'features/TemplateQutterSelect';
import { RuleActionJumpToList } from './RuleActionJumpToList';

import { toggleSearchQutterModal } from 'features/Modals';
import { customRuleActionUpdate } from 'features/Parameters';
import { ruleActionUpdate } from 'features/Qutters';

export const RuleActionJumpTo = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  action_idx,
  action,
}) => {
  const dispatch = useDispatch();
  return (
  <>
  {template_id ? (
    <>
    {is_default ? (null) : 
    (
      <>
      {/* 
      <div className='d-flex font-size-10 m-1'>
        <div className='text-start m-auto'>
          Choose question from template
        </div>
        <div className='w-50 text-start'>
          <TemplateQutterSelect
            multi={true}
            disabled={template_id && is_default ? true: false}
            template_id={template_id}
            current_qutter_id={qutter_id}
            selected={action.value}
            placeholder={'Choose questions from template'}
            on_select={(e) => {
              let act = Object.assign({}, action);
              act.value = e.map(s => s.value);
              if (template_id) {
                dispatch(customRuleActionUpdate({ template_id, qutter_id, rule_idx, action_idx, updated_action: act }));
              } else {
                dispatch(ruleActionUpdate({ qutter_id, rule_idx, action_idx, updated_action: act }));
              }
            }}
          />
        </div>
      </div> 
      */}

      <div className='d-flex font-size-10 m-1'>
        <div className='text-start m-auto'>
          Choose from library
        </div>
        <div className='w-50 text-start'>
          <span
            onClick={() => {
              dispatch(toggleSearchQutterModal({
                show: true,
                add_policy: 'CUSTOM_RULE_ACTION',
                shouldDisplayCreateQutterButton: false,
                template_id: template_id,
                qutter_id: qutter_id,
                data: {
                  qutter_id: qutter_id,
                  rule_idx: rule_idx,
                  action_idx: action_idx
                }
              }))
            }}
            className={`ms-1 hover-blue me-3 font-size-10`}
          >
            <FontAwesomeIcon className={'me-1'} icon={faPlusCircle} />
            Choose from library
          </span>
        </div>
      </div>
      </>
    )}
    </>
  ):(
    <div className='d-flex font-size-10 m-1'>
      <div className='text-start m-auto'>
        Choose from library
      </div>
      <div className='w-50 text-start'>
        <span
          onClick={() => {
            dispatch(toggleSearchQutterModal({
              show: true,
              add_policy: 'DEFAULT_RULE_ACTION',
              shouldDisplayCreateQutterButton: false,
              qutter_id: qutter_id,
              data: {
                qutter_id: qutter_id,
                rule_idx: rule_idx,
                action_idx: action_idx
              }
            }))
          }}
          className={`ms-1 hover-blue me-3 font-size-10`}
        >
          <FontAwesomeIcon className={'me-1'} icon={faPlusCircle} />
          Choose from library
        </span>
      </div>
    </div>
  )}

    <RuleActionJumpToList
      template_id={template_id}
      qutter_id={qutter_id}
      is_default={is_default}
      rule_idx={rule_idx}
      action_idx={action_idx}
      action={action}
    />
  </>
  );
};