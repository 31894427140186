import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Nav } from 'react-bootstrap';
import ProfileDropdown from './ProfileDropdown';
import { NotificationDropdown } from './NotificationDropdown';
import { getUnread } from 'features/QutterComment';

export const TopNavRightSideNavItem = () => {
  const user = useSelector(state => state.config.user);
  const [unread, setUnread] = useState([]);
  const [error, setError] = useState({ isError: false, message: '' });

  useEffect(() => {
    if (!user) {
      return;
    }

    getUnread(user, {
      client_id: user.client_id,
      user_id: user._id
    })
    .then((data) => {
      console.log('get_unread data', data);
      setUnread(data);
    })
    .catch((error) => {
      console.log(error);
      setUnread([]);
      setError({ isError: true, message: error });
    });
  }, []);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <NotificationDropdown unread={unread} />
      <ProfileDropdown />
    </Nav>
  );
};
