import { 
  createAsyncThunk,
  nanoid
} from '@reduxjs/toolkit';

import { 
  saveNewTemplate,
  createTemplateFromScratch,
  updateTemplate,
  getTemplateById,
  removeTemplateById
} from '../api/api';

export const SaveNewTemplate = createAsyncThunk(
  'create-template/save-new',
  async (template) => {
    // The value we return becomes the `fulfilled` action payload
    return await saveNewTemplate(template);
  }
);

export const CreateTemplateFromScratch = createAsyncThunk(
  'create-template/create-template-from-scratch',
  async () => {
    return await createTemplateFromScratch();
  }
);

export const UpdateTemplate = createAsyncThunk(
  'template/update',
  async (template) => {
    return await updateTemplate(template);
  }
);

export const GetTemplateById = createAsyncThunk(
  'template/get-by-id',
  async (template_id) => {
    return await getTemplateById(template_id);
  }
);

export const RemoveTemplateById = createAsyncThunk(
  'template/remove-by-id',
  async (template_id) => {
    return await removeTemplateById(template_id);
  }
);
