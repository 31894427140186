
export async function prepare_qutter_answers (library, selected_qutter_id) {
  const qutter_config = library[selected_qutter_id];
  if (!qutter_config) return [];
  // Only single/multiple selects
  if (!['single-select', 'multiple-select'].includes(qutter_config.type)) return;
  const languages = qutter_config.language.list;
  // Add options in all languages
  let available_options = [];
  let id_label_map = {};
  for (let i = 0; i < languages.length; i++) {
    let language = languages[i];
    qutter_config[language].options.map((option, idx) => {
      if (id_label_map.hasOwnProperty(option.id)) {
        id_label_map[option.id] += ` / ${option.label}`;
      } else {
        id_label_map[option.id] = option.label;
      }
    });
  }

  for (const key in id_label_map) {
    available_options.push({ label: id_label_map[key], value: key });
  }

  return available_options;
}