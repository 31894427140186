import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning, faComment, faMinusCircle, faDatabase } from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select';
import {
  Form,
  Button,
  InputGroup,
  FormControl,
} from 'react-bootstrap';

import {
  optionMinus,
  optionLabelChange,
  optionEvaluationChange,
  optionBonusChange,
  optionWeightChange
} from 'features/Qutters/slices/LibrarySlice';

import { findOptionValue } from 'features/common';
import { evaluation_list } from 'features/QutterEvaluation';

import { SelectReplyModal } from './SelectReplyModal';
import { SelectActionsModal } from './SelectActionsModal';
import { DefaultFollowupModal } from 'features/Modals';
import { HubiverseTagsModalShowButton } from 'features/HubiverseTags';

import { JumpsToOverlay } from '..';


export const SelectOption = ({
  id,
  option_idx,
  // language
}) => {
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[qutterConfig.language.current];

  const [showReplyModal, setShowReplyModal] = useState(false);
  const [showFollowupModal, setShowFollowupModal] = useState(false);
  const [showActionsModal, setShowActionsModal] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [label, setLabel] = useState('');

  if (!qutter) return (<span>Loading...</span>);

  const option = qutter.options[option_idx];
  const has_actions = option.actions && option.actions.length > 0;

  return (
  <tr
    className='mt-2'
  >
    {showReplyModal && (
      <SelectReplyModal
        showModal={showReplyModal}
        setShowModal={setShowReplyModal}
        id={id}
        option_idx={option_idx}
      />
    )}

    {showFollowupModal && (
      <DefaultFollowupModal
        id={id}
        option_idx={option_idx}
        showModal={showFollowupModal}
        setShowModal={setShowFollowupModal}
      />
    )}

    {showActionsModal && (
      <SelectActionsModal
        id={id}
        option_idx={option_idx}
        showModal={showActionsModal}
        setShowModal={setShowActionsModal}
      />
    )}

    <td className='text-center m-auto' style={{ verticalAlign: 'middle' }}>
      {editMode ? (
      <>
        <InputGroup className="mb-3">
          <FormControl
            defaultValue={option.label}
            placeholder="Option text"
            aria-label="Option text"
            autoFocus
            aria-describedby={`option-text-${id}-${option_idx}`}
            onChange={(e) => setLabel(e.target.value)}
          />
          <Button 
            variant="outline-secondary" 
            id={`option-text-${id}-${option_idx}`}
            onClick={() => {
              dispatch(optionLabelChange({ id, option_idx, label }));
              setEditMode(!editMode);
            }}>
            Save
          </Button>
        </InputGroup>
      </>
      ):(
        <div
          className='option cursor-pointer'
          onClick={() => { setEditMode(!editMode) }}
        >
          <span className=''>{option.label}</span>
        </div>
      )}
    </td>

    <td className='' xs={12} md={5} lg={5} style={{ verticalAlign: 'middle' }}>
      <Select
        defaultValue={findOptionValue(evaluation_list, option.evaluation)}
        options={evaluation_list}
        isDisabled={false}
        onChange={(e)=> {
          dispatch(optionEvaluationChange({ id, option_idx, evaluation: e.value }));
        }}
      />

      {option.evaluation === 'bonus' && (
      <div>
        <span>Default Bonus Points</span>
        <Form.Control
          defaultValue={('bonus_points' in option) ? parseInt(option.bonus_points): 0}
          type="number"
          min={0}
          max={100}
          placeholder="Default Bonus"
          onChange={(e) => {
            dispatch(optionBonusChange({ id, option_idx, bonus_points: e.target.value }));
            }}
        />
      </div>
      )}

      {option.evaluation === 'point' && (
      <div>
        <span>Default Weight</span>
        <Form.Control
          defaultValue={('weight' in option) ? parseInt(option.weight): 0}
          type="number"
          min={0}
          max={100}
          placeholder="Default Weight"
          onChange={(e) => {
            dispatch(optionWeightChange({ id, option_idx, weight: e.target.value }));
            }}
        />
      </div>
      )}
    </td>

    <td className='text-center m-auto' xs={1} md={1} lg={1} style={{ verticalAlign: 'middle' }}>

      {/* 
      <JumpsToOverlay
        qutter_id={id}
        option_idx={option_idx}
      /> 
      */}

      <span
        onClick={() => { setShowActionsModal(true); }}
        className={`cursor-pointer m-2 hover-blue ${showActionsModal || has_actions ? 'color-blue': ''}`}
      >
        <FontAwesomeIcon icon={faBoltLightning} />
      </span>

      <span
        onClick={() =>  setShowReplyModal(true) }
        className='cursor-pointer m-2 hover-blue'
      >
        <FontAwesomeIcon icon={faComment} />
      </span>

      <span
        onClick={() => { dispatch(optionMinus({ id, option_idx })); }}
        className='cursor-pointer hover-blue'
      >
        <FontAwesomeIcon icon={faMinusCircle} />
      </span>

      <HubiverseTagsModalShowButton
        qutter_id={id}
        option_id={option.id}
      />

    </td>
  </tr>
  );
};
