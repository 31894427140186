import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faArrowsUpDown } from '@fortawesome/pro-solid-svg-icons';

import { Card, Container, Table } from 'react-bootstrap';
import { getListStyle } from '../utils/utils';

import { InterviewSummaryDetail } from './InterviewSummaryDetail';

import {
  MoveInterviewSummaryBlockOverlay,
  RemoveInterviewSummaryBlock,
  remove_interview_summary_block,
  NOT_VIEWABLE_BLOCK_IN_SUMMARY
} from '..';

export const InterviewSummaryBlock = ({
  template_id,
  summary_block_id,
  summary_block_idx
}) => {
  const summary = useSelector(state => state.InterviewSummary[template_id]);
  const interviewReceptState = useSelector(state => state.InterviewReceipt);

  return (
    <Card
      key={`interview-summary-block-${summary_block_id}`}
      className="mt-3 mb-3"
    >
      <Card.Header>
        <div className="d-flex w-100">
          <div className="w-50">{summary.blocks[summary_block_id].name}</div>
          {interviewReceptState.readOnlyMode ? (
            ''
          ) : (
            <div className="w-50 text-end">

              <MoveInterviewSummaryBlockOverlay
                template_id={template_id}
                block_id={summary_block_id}
                block_idx={summary_block_idx}
              />

              <RemoveInterviewSummaryBlock
                template_id={template_id}
                block_id={summary_block_id}
              />
            </div>
          )}
        </div>
      </Card.Header>

      <Card.Body className="pt-0 pb-0" as={Container}>
        <Droppable
          key={`droppable-block###${summary_block_id}`}
          droppableId={`droppable-block###${summary_block_id}`}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              <Table hover size="sm">
                <tbody style={{ verticalAlign: 'center' }}>
                  {summary.blocks[summary_block_id].details.map(
                    (detail, detail_idx) => {
                      console.log('summary_block_id', summary_block_id)
                      console.log('detail', detail)
                      if (detail) {
                        return (
                          <InterviewSummaryDetail
                            key={`summary-detail-${summary_block_id}-${detail.id}`}
                            template_id={template_id}
                            summary_block_id={summary_block_id}
                            summary_block_idx={summary_block_idx}
                            qutter_id={detail.id}
                            detail={detail}
                            detail_idx={detail_idx}
                          />
                        )
                      }
                    }
                  )}
                </tbody>
              </Table>

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Card.Body>
    </Card>
  );
};
