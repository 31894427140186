export const OpenInNewTab = ({
  url,
  name
}) => {
  const onURLClick = () => { window.open(url); };

  return (
    <a
      className='link-primary hover-blue m-1'
      target='_blank'
      href={`${url}`}
      rel="noreferrer"
      style={{ fontSize: '9px', fontWeight: 'bold' }}
    >
      {name}
    </a>
  );
}