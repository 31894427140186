import React, { useEffect, useState } from 'react';
import LanguageSelectSingle from './LanguageSelectSingle';
import LanguageSelectMultiple from './LanguageSelectMultiple';

import { get_languages } from '../api/api';

export const LanguageSelect = ({
  multi,
  disabled,
  on_select, 
  selected,
  selected_language,
  placeholder,
  className,
  clearable=false,
  searchable=true,
  exclude=[]
}) => {
  const [languages, set_languages] = useState([]);
  const [error, set_error] = useState(null);
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    get_languages(exclude)
    .then((data) => {
      set_languages(data);
      set_loading(false);
    })
    .catch((error) => {
      console.log('error', error)
      set_languages([]);
      set_error(error)
      set_loading(false);
    })
  }, []);

  if (loading) { return (<span>Loading languages...</span>); }
  if (error) { return (<span>Error: {error.toString()}</span>); }

  if (multi) {
    return (
      <LanguageSelectMultiple
        language_list={languages}
        className={className}
        disabled={disabled}
        on_select={on_select}
        selected={selected}
        placeholder={placeholder}
        clearable={clearable}
        searchable={searchable}
      />
    )
  }

  return (
    <LanguageSelectSingle
      language_list={languages}
      className={className}
      disabled={disabled}
      on_select={on_select}
      selected_language={selected_language}
      placeholder={placeholder}
      clearable={clearable}
      searchable={searchable}
    />
  );
};
