import hubert_dashboard from 'assets/img/illustrations/hubert_dashboard.png';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Background, Flex } from '../../../common';
import { getTimezone } from '../../../../helpers/tz'

const GreetingCard = () => {
  const tz = getTimezone();
  const config = tz ? { headers: { 'X-Timezone': tz } } : {};
  const user = useSelector(state => state.config.user);
  const [chartData, setChartData] = useState({
    applicationTotal: 0,
    candidateTotal: 0,
    interviewsTotal: 0,
    interviews: { started: 0, ended: 0 },
    pastWeek: {
      applicationTotal: 0,
      candidateTotal: 0,
      interviewsTotal: 0,
      interviews: { started: 0, ended: 0 },
    }
  });

  useEffect(() => {
    getChartData();
  }, []);

  const getGreeting = () => {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 5 && hour < 12) {
      return `Good Morning, ${user.first_name}!`;
    } else if (hour >= 12 && hour < 18) {
      return `Good Afternoon, ${user.first_name}!`;
    } else if (hour >= 18 && hour < 22) {
      return `Good Evening, ${user.first_name}!`;
    } else {
      return `Hello, ${user.first_name}!`;
    }
  };

  const getChartData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_URL}/dashboard-analytics/v1/daily-counts?t=` + new Date().setHours(0, 0, 0, 0), config);
      if (res.status === 200) {
        setChartData(res.data);
      }
  };

  return (
    <Card className="bg-transparent-50 overflow-hidden">
      <Card.Header className="position-relative">
        <Background
          image={hubert_dashboard}
          className="d-none d-md-block bg-card z-index-1"
          style={{
            backgroundSize: '230px',
            backgroundPosition: 'right bottom',
            zIndex: '-1'
          }}
        />
        <div className="position-relative z-index-2">
          <div>
            <h3 className="text-primary mb-1">{getGreeting()}</h3>
            <p className="text-700">Here’s what happening with the Hubert</p>
          </div>
          <Flex className="py-3">
            <div className="pe-3">
              <p className="text-600 fs--1 fw-medium">Today's applications</p>
              <div className="text-400 fs--1 fw-medium">&nbsp;</div>
              <h4 className="text-800 mb-0">{chartData.applicationTotal}</h4>
              <div>
                <p className="text-600 fs--1 fw-medium">past week</p>
                <h4 className="text-800 mb-0">{chartData.pastWeek.applicationTotal}</h4>
              </div>
            </div>
            <div className="ps-3">
              <p className="text-600 fs--1 fw-medium">Today’s candidates</p>
              <div className="text-400 fs--1 fw-medium">&nbsp;</div>
              <h4 className="text-800 mb-0">{chartData.candidateTotal}</h4>
              <div>
                <p className="text-600 fs--1 fw-medium">past week</p>
                <h4 className="text-800 mb-0">{chartData.pastWeek.candidateTotal}</h4>
              </div>
            </div>
            <div className="ps-3">
              <p className="text-600 fs--1 fw-medium">Today’s interviews</p>
              <div className="text-400 fs--1 fw-medium">(started / completed)</div>
              <h4 className="text-800 mb-0">{chartData.interviews.started} / {chartData.interviews.ended}</h4>
              <div>
                <p className="text-600 fs--1 fw-medium">past week</p>
                <h4 className="text-800 mb-0">{chartData.pastWeek.interviews.started} / {chartData.pastWeek.interviews.ended}</h4>
              </div>
            </div>
          </Flex>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <ul className="mb-0 list-unstyled"></ul>
      </Card.Body>
    </Card>
  );
};

GreetingCard.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object)
};

export default GreetingCard;
