import React, { useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { TemplateBlocks } from 'features/TemplateBlock';
import { Loading } from 'features/common';

const scroll = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }
};

export const Template = ({ id }) => {
  // console.log(`<Template /> ${id}`)
  const template = useSelector(state => state.Template[id]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const paramId = searchParams.get('id');
    const paramComment = searchParams.get('comment');
    if (paramId) {
      scroll(paramId);
    }
  }, []);

  useEffect(() => {
    if (template && template.title) {
      document.title = `Template - ${template.title}`;
    }
  }, [template]);

  if (!template) return null;

  return (
    <Suspense fallback={<Loading />}>
      <div 
        id={id}
      >
        <TemplateBlocks id={id} />
      </div>
    </Suspense>
  );
};