import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/pro-solid-svg-icons';

import { Card } from 'react-bootstrap';
import {formatDate} from 'features/common';

export const QutterCommentItem = ({
  content,
  authorId,
  authorName,
  authorType,
  created_at
}) => {

  const onCheckClick = () => {
    console.log('onCheckClick');
  };

  const onTrashClick = () => {
    console.log('onCheckClick');
  };

  return (
    <Card 
      style={{
        marginBottom: '10px',
        margin: '1rem',
        // maxWidth: '80%',
        // minWidth: '80%',
        // float: `${authorType === 'user'? 'left': 'right'}`
      }}
    >

      <Card.Body>
        <Card.Subtitle className="mb-2 text-muted">
          {authorName}
          {authorType === 'cs' ? '@Hubert': ''}
        </Card.Subtitle>
        <Card.Text>
          <div
            className={``}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Card.Text>
      </Card.Body>

      <Card.Footer className="text-muted font-size-12">
        <div>
          <div className='float-start'>
            <span
              style={{ position: 'relative' }}
              className={`hover-blue me-3`}
              onClick={onCheckClick}
            >
              <FontAwesomeIcon size={'sm'} icon={faCheck} />
            </span>

            <span
              style={{ position: 'relative' }}
              className={`hover-blue me-3`}
              onClick={onTrashClick}
            >
              <FontAwesomeIcon size={'sm'} icon={faTrash} className="" />
            </span>
          </div>

          <div className='float-end'>
            <span>
              {formatDate(new Date(created_at))}
            </span>
          </div>

        </div>
      </Card.Footer>
    </Card>
  );
};
