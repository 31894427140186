import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const TemplateSelectSingle = ({
  templates_list,
  client_id,
  on_select=null,
  selected_template_id,
  clearable=false,
  disabled=false,
  className
}) => {
  let mapping = [];
  for (let i = 0; i < templates_list.length; i++) {
    const t = templates_list[i];
    let label = '';
    label = `${t.default ? 'Default template - ': ''} ${t.name}`;

    let role = t.role;
    if (typeof role === 'string') {
      label += ` - ${role}`;
    } else {
      label += ` - ${role.name}`;
    }

    mapping.push({
      label: label,
      value: `${t._id}`
    });
  }

  let template_idx = null;
  if (selected_template_id) {
    function find(obj) { return obj.value === selected_template_id; }
    template_idx = mapping.findIndex(find);
  }

  return (
    <Select
      defaultValue={selected_template_id && selected_template_id.length ? mapping[template_idx]: null}
      options={mapping}
      isMulti={false}
      isClearable={clearable}
      isDisabled={disabled}
      className={className}
      onChange={async (e)=> {
        if (on_select) {
          const selected = await templates_list.find((template) => template._id === e.value);
          on_select(e, selected);
        }
      }}
    />
  );
};

export default TemplateSelectSingle;