
export const QUTTER_TYPES_TO_TEXT = {
  'single-select':    'Single select',
  'multiple-select':  'Multiple select',
  'open':             'Open',
  'info':             'Informational',
  'date-picker':      'Date picker',
  'month-picker':     'Month picker',
  'form':             'Form',
  'calendly':         'Calendly',
};

export const QUTTER_TYPES_LIST = [
  { label: 'Single select', value: 'single-select' },
  { label: 'Multiple select', value: 'multiple-select' },
  { label: 'Open', value: 'open' },
  { label: 'Informational', value: 'info' },
  { label: 'Date picker', value: 'date-picker' },
  { label: 'Month picker', value: 'month-picker' },
  { label: 'Form', value: 'form' },
  { label: 'Calendly', value: 'calendly' },
];