import React, { useState }  from 'react';
import { useSelector } from 'react-redux';

import { QutterHeader } from 'features/Template';
import { TemplateTexts } from 'features/PreviewQuestionTexts';
import { AddQutterToBlock } from 'features/TemplateBlock';

export const PreviewReusable = ({
  template_id,
  block_id,
  block_name,
  qutter_id,
  qutter_idx,
  order,
  provided,
  is_followup,
  parent_id
}) => {
  // console.log('<PreviewReusable />', qutter_id);
  const [previewIsOpen, setPreviewIsOpen] = useState(false);
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const qutter = qutter_config[qutter_config.language.current];
  const onFullPreviewClick = e => { setPreviewIsOpen(!previewIsOpen); };
  return (
  <div
    key={`view-info-qutter-${qutter_id}-${qutter_idx}`} 
    className={`mb-2 ${qutter && qutter.is_follow_up ? 'p-0': ''}`}
  >

    <QutterHeader
      template_id={template_id}
      block_id={block_id}
      qutter_id={qutter_id}
      order={order}
      previewIsOpen={previewIsOpen}
      onFullPreviewClick={onFullPreviewClick}
      provided={provided}
      is_followup={is_followup}
      parent_id={parent_id}
    />

    {qutter_config && qutter && (
      <div className={`${previewIsOpen ? '': 'd-none'}`}>
        <div className='mt-1'>
          {/* Intro (field: intro) */}
          <TemplateTexts
            template_id={template_id}
            qutter_id={qutter_id}
            field={'intro'}
          />
          {/* Question (field: text) */}
          <TemplateTexts
            template_id={template_id}
            qutter_id={qutter_id}
            field={'text'}
          />

          {/* Outro (field: outro) */}
          <TemplateTexts
            template_id={template_id}
            qutter_id={qutter_id}
            field={'outro'}
          />
        </div>
      </div>
    )}

    {/* {template_id && is_followup !== true && (
      <AddQutterToBlock
        template_id={template_id}
        block_id={block_id}
        qutter_id={qutter_id}
        qutter_idx={qutter_idx}
      />
    )} */}

  </div>
  );
}
