import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { LabelPreview } from '../../common/LabelPreview';

import { FormMatrixRowPreview } from './FormMatrixRowPreview';
import { FormEvaluation } from '../../common/FormEvaluation';

export const FormMatrixPreview = ({
  template_id,
  qutter_id,
  form_idx 
}) => {
  // console.log('<FormMatrixPreview />');
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const [checked_id, set_checked_id] = useState(null);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  const form = forms[form_idx];
  const rows = forms[form_idx].rows;
  const columns = forms[form_idx].columns;

  const Evaluation = useSelector(state => state.Evaluation[qutter_id]);
  const [evaluation, setEvaluation] = useState(null);

  const custom_key = `${form.id}.evaluation`;

  useEffect(() => {
    if (!template_id) {
      setEvaluation(form.evaluation);
    } else if (Evaluation && Evaluation[language]) {
      if (Evaluation[language].hasOwnProperty(custom_key)) {
        setEvaluation(Evaluation[language][custom_key]);
      }
    }
  }, [qutter_config, Evaluation, language]);

  return (
  <div className="form-group">
    <LabelPreview label={form.label} is_mandatory={form.is_mandatory} />
    <LabelPreview label={form.help_text} />

    <Table borderless size="sm" hover className="">
      <thead>
        <tr>
        <th></th>
        {columns && columns.length ? (
          columns.map((column, column_idx) => {
            return (
              <th key={`${column.id}-${column_idx}`} className="text-center summery-icon">
                {column.label}
              </th>
            );
          })
        ):(
          <span>Add columns to preview.</span>
        )}
        </tr>
      </thead>
      <tbody>
      {rows && rows.length ? (
        <>
          {rows.map((row, row_idx) => {
            return (
              <FormMatrixRowPreview
                key={`matrix-preview-${row.id}`}
                qutter_id={qutter_id} 
                form_idx={form_idx} 
                row_idx={row_idx} 
              />
            );
          })}
        </>
      ):(
        <span>Add rows to preview.</span>
      )}
      </tbody>
    </Table>
    <FormEvaluation
      template_id={template_id}
      qutter_id={qutter_id}
      form={form} evaluation={evaluation}
    />
  </div>
  );
}
