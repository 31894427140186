import React from 'react';
import { Button, Modal } from 'react-bootstrap';


export const ConfirmationModal = ({
  headerTitle,
  bodyContent,
  cancelButtonFunction,
  confirmButtonFunction,
  cancelButtonText,
  confirmButtonText,
  modalVisibility,
  setModalVisibility
}) => {
  const onCloseModalClick = e => {
    setModalVisibility(false);
  };

  return (
    <Modal
      show={modalVisibility}
      onHide={() => {
        onCloseModalClick();
      }}
      dialogClassName="interview-receipt-url-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {headerTitle}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        {bodyContent}
      </Modal.Body>
      <Modal.Footer>
          <Button variant="secondary" onClick={cancelButtonFunction}>
            {cancelButtonText}
          </Button>
          <Button variant="primary" onClick={confirmButtonFunction}>
            {confirmButtonText}
          </Button>
        </Modal.Footer>
    </Modal>
  );
};
