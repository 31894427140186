import React, { useState, useEffect, useRef }  from 'react';
import { useSelector, dispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const ClientQutterIcon = ({
  qutter_id,
  editable=false,
  containerClass=''
}) => {
  // console.log('<ClientQutterIcon />');
  const qutter = useSelector(state => state.Library[qutter_id]);

  if (qutter && qutter.client_id) {
    return (
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id="button-tooltip-2">Client Question</Tooltip>}
      >
      {({ ref, ...triggerHandler }) => (
        <span
          className={containerClass}
          ref={ref}
          {...triggerHandler}
        >
          <FontAwesomeIcon icon={faUser} />
        </span>
      )}
      </OverlayTrigger>
    );
  }
  else {
    return (<></>);
  }
};