import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

export const WarningIcon = ({}) => {

  return (
    <span
      onClick={(e) => {}}
      className='cursor-pointer'
    >
      <FontAwesomeIcon 
        style={{ color: '#FFC400' }} 
        className="mr-3"
        size={'lg'}
        icon={faExclamationTriangle}
      />
    </span>
  );
};