import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareXmark,
  faPlusCircle,
  faXmark
} from '@fortawesome/pro-solid-svg-icons';

import { Modal, Table } from 'react-bootstrap';

import { InterviewActionsSelect } from 'features/HubertActions';
import { NodeAction } from './NodeAction';

import { toggleInterviewNodeActionsModal } from 'features/Modals/slices/ModalSlice';

const new_node = () => {
  return { id: nanoid(), type: 'action', actions: [] };
}

export const NodeActions = ({
  template_id,
  block_id,
  block_idx,
  node,
  set_node
}) => {
  const dispatch = useDispatch();

  if (!node) return null;

  return (
  <div>
    <span className='h6 d-block'>Interview actions
      <span
        className='hover-blue d-block float-end font-size-10'
        onClick={(e) => {
          const copy = Object.assign({}, node);
          const new_action = { id: nanoid(), context: 'interview_setting', value: 'sv-se' };
          copy.actions.push(new_action);
          set_node(copy);
        }}
      >
        <FontAwesomeIcon icon={faPlusCircle} className="me-1"/>
        Add action
      </span>
    </span>

    <Table>
      <tbody>
      {node.actions && node.actions.length ? (
        node.actions.map((action, action_idx) => {
          return (
            <NodeAction
              key={`node-action-${action.id}`}
              node={node}
              set_node={set_node}
              action={action}
              action_idx={action_idx}
            />
          )
        })
      ):(null)}
      </tbody>
    </Table>
  </div>
  );
};
