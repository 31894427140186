import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Form } from 'react-bootstrap';

import {
  updateFormMatrixRowAtIndex,
  addFormMatrixRowAtIndex,
  removeFormMatrixRowAtIndex,
} from 'features/Qutters/slices/LibrarySlice';

export const FormMatrixRow = ({
  row,
  qutter_id, 
  form_idx, 
  row_idx 
}) => {
  // console.log('<FormMatrixRow />');
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  // const row = forms[form_idx].rows[row_idx];

  return (
  <div className='d-flex mt-3'>
    <div className='m-auto text-center' style={{ width: '95%' }}>
      <Form.Control
        defaultValue={row.label}
        className='mb-1'
        type="text"
        placeholder="Row text"
        onChange={(e) => {
          const value = Object.assign({}, row);
          value.label = e.target.value;
          dispatch(updateFormMatrixRowAtIndex({ 
            qutter_id,
            form_idx,
            row_idx,
            value
          }));
        }}
      />
    </div>
    <div className='text-end m-auto' style={{ width: '5%' }}>
      <div className='font-size-10 m-auto'>
        <span
          onClick={() => {
            dispatch(removeFormMatrixRowAtIndex({
              qutter_id,
              form_idx,
              row_idx
            }))
          }}
          className='hover-blue d-block'
        >
          <FontAwesomeIcon className="me-2" icon={faMinusCircle} />
        </span>
        <span
          onClick={() => {
            dispatch(addFormMatrixRowAtIndex({
              qutter_id,
              form_idx,
              row_idx
            }))
          }}
          className='hover-blue d-block'
        >
          <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
        </span>
      </div>
    </div>
  </div>
  );
}
