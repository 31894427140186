import React  from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const InfoQutterIcon = ({
  qutter_id,
  editable=false,
  containerClass=''
}) => {
  // console.log('<InfoQutterIcon />');
  const qutter = useSelector(state => state.Library[qutter_id]);

  if (qutter && qutter.type === 'info') {
    return (
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id="button-tooltip-2">Informational message from Hubert</Tooltip>}
      >
      {({ ref, ...triggerHandler }) => (
        <span
          className={containerClass}
          ref={ref}
          {...triggerHandler}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      )}
      </OverlayTrigger>
    );
  }
  else {
    return (<></>);
  }
}
