import { createSlice } from '@reduxjs/toolkit';

import {
  AddJob,
  // General Job Information
  EditJobClient,
  EditJobTemplateIds,
  EditJobInterviewLanguage,
  EditJobTitle,
  EditJobThreshold,
  EditJobResponsibleCS,
  EditJobCompanyName,
  EditJobCompanyDepartment,
  EditJobDescription,
  EditJobFormOfEmployment,
  EditJobRoleId,
  EditJobRoleName
} from './jobReducers';

import { SaveNewJob } from './thunks';

import { initialCreateNewJob } from './constants';

const initialState = {
  status: 'idle',
  current: '',
  is_edited: false,
  createNewJob: initialCreateNewJob
};

const JobSlice = createSlice({
  name: 'job',
  initialState: initialState,
  reducers: {
    addJob: AddJob,

    // General Job Information
    editJobClient: EditJobClient,
    editJobTemplateIds: EditJobTemplateIds,
    editJobInterviewLanguage: EditJobInterviewLanguage,
    editJobTitle: EditJobTitle,
    editJobThreshold: EditJobThreshold,
    editJobResponsibleCS: EditJobResponsibleCS,
    editJobCompanyName: EditJobCompanyName,
    editJobCompanyDepartment: EditJobCompanyDepartment,
    editJobDescription: EditJobDescription,
    editJobFormOfEmployment: EditJobFormOfEmployment,
    editJobRoleId: EditJobRoleId,
    editJobRoleName: EditJobRoleName
  },
  extraReducers: (builder) => {
    builder
    .addCase(SaveNewJob.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(SaveNewJob.rejected, (state) => {
      state.status = 'error';
    })
    .addCase(SaveNewJob.fulfilled, (state, action) => {
      state.status = 'idle';
      const id = action.payload.id;
      console.log('SaveNewJob Finished');
      if (id === 'createNewJob') state[id] = initialCreateNewJob;
    });
  },
});

export const {
  addJob,

  // General Job Information
  editJobClient,
  editJobTemplateIds,
  editJobInterviewLanguage,
  editJobTitle,
  editJobThreshold,
  editJobResponsibleCS,
  editJobCompanyName,
  editJobCompanyDepartment,
  editJobDescription,
  editJobFormOfEmployment,
  editJobRoleId,
  editJobRoleName
} = JobSlice.actions;
export default JobSlice.reducer;
