import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select';
import { Accordion, Form, Container } from 'react-bootstrap';

import { HubiversePropertySelect } from 'features/HubertActions';
import { form_types } from '../constants/form_types';

import { FormText } from './input_types/text/FormText';
import { FormNumber } from './input_types/number/FormNumber';
import { FormEmail } from './input_types/email/FormEmail';
import { FormSlider } from './input_types/slider/FormSlider';
import { FormCheckbox } from './input_types/checkbox/FormCheckbox';
import { FormMatrix } from './input_types/matrix/FormMatrix';
import { FormRange } from './input_types/range/FormRange';
import { FormFile } from './input_types/file/FormFile';

import { HubiverseTagsModalShowButton } from 'features/HubiverseTags';

import { 
  removeFormByIndex,
  updateFormByIndex
} from 'features/Qutters/slices/LibrarySlice';

export const FormConfig = ({ qutter_id, form_idx }) => {
  console.log('<FormConfig />');
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  const form = forms[form_idx];

  return (
  <Accordion className='m-1 mt-2'>
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        Form element {form_idx + 1}
      </Accordion.Header>
      <Accordion.Body as={Container}>

        <div className='d-flex'>
          <div className='w-50 m-auto text-center'>
            <span className=''></span>
          </div>
          <div className='w-50 text-end'>
            <span
                onClick={() => { dispatch(removeFormByIndex({ qutter_id, form_idx })) }}
                className='hover-blue font-size-10'
              >
              <FontAwesomeIcon className="me-2" icon={faMinusCircle} />
              Remove Form
            </span>
          </div>
        </div>

        <div className='d-flex mt-3'>
          <div className='w-50 m-auto text-center'>
            <span>Input type</span>
          </div>
          <div className='w-50 text-left'>
            <Select 
              onChange={(e) => {
                let temp = Object.assign({}, form);
                temp.type = e.value;
                dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
              }}
              defaultValue={form_types.find((type) => type.value === form.type)}
              options={form_types}
            />
          </div>
        </div>

        {(() => {
          if (!form.type) {
            return (<></>);
          } else 
          if (form.type === 'text') {
            return (<FormText qutter_id={qutter_id} form_idx={form_idx} />);
          } else
          if (form.type === 'email') {
            return (<FormEmail qutter_id={qutter_id} form_idx={form_idx} />);
          } else
          if (form.type === 'number') {
            return (<FormNumber qutter_id={qutter_id} form_idx={form_idx} />);
          } else
          if (form.type === 'slider') {
            return (<FormSlider qutter_id={qutter_id} form_idx={form_idx} />);
          } else
          if (form.type === 'range') {
            return (<FormRange qutter_id={qutter_id} form_idx={form_idx} />);
          } else
          if (form.type === 'checkbox') {
            return (<FormCheckbox qutter_id={qutter_id} form_idx={form_idx} />);
          } else
          if (form.type === 'matrix') {
            return (<FormMatrix qutter_id={qutter_id} form_idx={form_idx} />);
          } else
          if (form.type === 'file') {
            return (<FormFile qutter_id={qutter_id} form_idx={form_idx} />);
          }
          else return (<></>);
        })()}

        <div className='d-flex mt-3'>
          <div className='w-50 m-auto text-center'>
            <span>Mandatory</span>
          </div>
          <div className='w-50 text-left'>
            <Form.Check
              // defaultValue={form.is_mandatory}
              checked={form.is_mandatory}
              type="checkbox"
              onChange={(e) => {
                let temp = Object.assign({}, form);
                temp.is_mandatory = e.target.checked;
                dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
              }}
            />
          </div>
        </div>

        <div className='mt-4'>
          <HubiversePropertySelect
            on_select={(e) => {
              let temp = Object.assign({}, form);
              temp.hubiverse_property = e.value;
              temp.candidate_property = e.value;
              dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
            }}
            selected={form.hubiverse_property || form.candidate_property}
          />
        </div>

        <HubiverseTagsModalShowButton
          qutter_id={qutter_id}
          form_id={form.id}
        />

      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
  );
}
