import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Accordion,
  Form
} from 'react-bootstrap';

import { HubiversePropertySelect } from 'features/HubertActions';
import { QutterPreActions } from 'features/QutterPreActions';
import { QutterPostActions } from 'features/QutterPostActions';

import { updateQutterCandidateProperty, updateQutterSkipIfExists } from 'features/Qutters';

export const QutterActionsAccordion = ({
  template_id,
  block_id,
  qutter_id,
}) => {
  // console.log('<QutterActions />', qutter_id);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const qutter = qutter_config[qutter_config.language.current];

  return (
  <Accordion className='m-1 mt-2'>
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        Actions
      </Accordion.Header>
      <Accordion.Body as={Container}>

          <Row className='mt-2'>
            <Col>
              <QutterPreActions qutter_id={qutter_id} />
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col>
              <QutterPostActions qutter_id={qutter_id} />
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col>
              <span className='h6'>Save as Candidate property *</span>
              <HubiversePropertySelect
                on_select={(e) => {
                  dispatch(updateQutterCandidateProperty({
                    id: qutter_id,
                    candidate_property: e.value
                  }));
                }}
                selected={qutter_config.candidate_property}
              />

              <div className='mt-4'>
                <Form.Check 
                  type={'checkbox'}
                  id={`skip-property-checkbox`}
                  label={`Skip this question if property already exists`}
                  onChange={(e) => {
                    console.log('e.target.checked: ', e.target.checked)
                    dispatch(updateQutterSkipIfExists({ 
                      id: qutter_id, 
                      checked: e.target.checked
                    }));
                  }}
                  defaultChecked={ qutter_config ? qutter_config.skip_if_exists: false }
                />
              </div>

              <div className='mt-2'>
                <Form.Check
                  type={'checkbox'}
                  disabled={true}
                  defaultChecked={ qutter_config ? qutter_config.is_reusable: false }
                  id={`re-usable-checkbox`}
                  label={`Re-usable`}
                />
              </div>
            </Col>
          </Row>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
  );
}