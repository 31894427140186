import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';

import { get_labels } from '../utils/utils';

export const TemplateQuestionAnswer = ({
  disabled,
  placeholder,
  className,

  question,
  filter,
  on_select,
}) => {
  console.log('TemplateQuestionAnswer', question.id);
  console.log('TemplateQuestionAnswer', filter);
  const [type, set_type] = useState(null); // dropdown, text, or number
  const [labels, set_labels] = useState([]);

  useEffect(() => {

    // set_options([]);

    if (['single-select', 'multiple-select'].includes(filter.question_selection.type)) {
      set_type('dropdown');
    } else 

    if (['open'].includes(filter.question_selection.type)) {
      set_type('text');
    } else

    if (filter.question_selection.type === 'form') {
      let form_type = filter.question_selection.form_type;

      if (form_type === 'matrix') {
        set_type('dropdown');
      } else 

      if (['slider', 'range'].includes(form_type)) {
        set_type('dropdown');
      } else 

      if (['text', 'email'].includes(form_type)) {
        set_type('text');
      } else 

      if (form_type === 'checkbox') {
        set_type('dropdown');
      } else 

      if (form_type === 'number') {
        set_type('number');
      }
    } else

    if (['date-picker'].includes(filter.question_selection.type)) {
      set_type('text');
    } else
    if (['month-picker'].includes(filter.question_selection.type)) {
      set_type('text');
    }

  }, [question, filter, filter.question_selection]);

  useEffect(() => {
    if (type === 'dropdown') {
      async function load_selected () {
        const labels = await get_labels(filter.question_selection, question);
        console.log('answer labels', labels)
        set_labels(labels);
      }
      load_selected();
    }
  }, [type, filter.question_selection]);

  if (['text', 'number'].includes(type)) {
    return (
      <Form.Control 
        type={type}
        placeholder='write something...'
        // onChange={(e) => { set_text_search(e.target.value); }}
        // onKeyPress={event => {
        //   if (event.key === "Enter") {
        //     onSearchClick();
        //   }
        // }}
      />
    )
  }

  console.log('END answer labels', labels)

  return (
    <Select
      isMulti={filter.operator && filter.operator.value && filter.operator.value === 'INCLUDES' ? true: false}
      className={className}
      isDisabled={disabled}

      options={labels}
      placeholder={placeholder ? placeholder: 'Select answer'}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};
