import React, { useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';

import { Invite } from './Invite';

export const InviteCandidateForm = ({
  job_id,
}) => {
  const [email, set_email] = useState('');
  const [first_name, set_first_name] = useState('');
  const [last_name, set_last_name] = useState('');
  const [error, set_error] = useState('');
  const [success, set_success] = useState(null);

  return (
    <Container>
      {/* Email */}
      <Form.Group className="mb-3" controlId="form-title-internal">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="text"
          defaultValue={email}
          placeholder="jacob@hubert.ai"
          onChange={(e) => { set_email(e.target.value) }}
        />
      </Form.Group>

      {/* First Name */}
      <Form.Group className="mb-3" controlId="form-title-external">
        <Form.Label>First Name</Form.Label>
        <Form.Control 
          type="text"
          defaultValue={first_name}
          placeholder={'Rocky'}
          onChange={(e) => { set_first_name(e.target.value) }}
        />
      </Form.Group>

      {/* Last Name */}
      <Form.Group className="mb-3" controlId="form-company-name">
        <Form.Label>Last Name</Form.Label>
        <Form.Control 
          type="text"
          defaultValue={last_name}
          placeholder="Balboa"
          onChange={(e) => { set_last_name(e.target.value) }}
        />
      </Form.Group>

      {error && (
        <div className='mb-3 text-center red'>{error}</div>
      )}

      {success && (
        <>
        <div className='mb-3 text-center' style={{ color: 'green' }}>{success.message}</div>
        <div className='mb-3 text-center'>Go to interview: {success.interview_link}</div>
        </>
      )}

      <Invite
        job_id={job_id}
        email={email}
        first_name={first_name}
        last_name={last_name}
        set_error={set_error}
        set_success={set_success}
      />
    </Container>
  );
};
