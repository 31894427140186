import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

export const PreviewRulesButton = ({
  show,
  on_click
}) => {
  return (
    <span
      onClick={(e) => { on_click(e); }}
      className='font-size-10 hover-blue m-1 mt-2 mb-2'
    >
      <span className="font-size-10 me-2">
        { show ? 'Hide Rules' : 'Show Rules' }
      </span>
      <FontAwesomeIcon icon={show ? faChevronDown: faChevronRight} />
    </span>
  );
};