import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import AppContext from 'context/Context';
import PrimaryDetailsChart from './components/CountByCategoryChart/MainCard';
import GreetingCard from './components/GreetingCard/GreetingCard';
import InterviewAnalyticsCard from './components/InterviewAnalyticsCard/InterviewAnalyticsCard';

export const Dashboard = () => {
  const {
    config: { isFluid }
  } = useContext(AppContext);

  return (
    <>
      <div className={'container-fluid'}>
        <Row className="g-3 mb-3">
          <Col xs={12}>
            <GreetingCard/>
          </Col>
          <Col xxl={12}>
            <PrimaryDetailsChart className="mb-3" />
          </Col>
          <Col xxl={12}>
            <InterviewAnalyticsCard className="mb-3" />
          </Col>
        </Row>
      </div>
    </>
  );
};
