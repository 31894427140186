export function QutterPreActionsPlus (state, action) {
  const { id } = action.payload;
  const qutter = state[id];
  qutter.pre_actions.push('');
};

export function QutterPreActionsMinus (state, action) {
  const { id, idx } = action.payload;
  const qutter = state[id];
  qutter.pre_actions.splice(idx, 1);
};

export function UpdateQutterPreAction (state, action) {
  const { id, idx, pre_action } = action.payload;
  const qutter = state[id];
  qutter.pre_actions[idx] = pre_action;
};
