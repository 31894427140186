import React from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { toast } from 'react-toastify';
import Select from 'react-select';

import { QUERY_GET_ALL_ADMINS, EDIT_JOB_ADMIN } from '../api/gql';

export const AdminSelect = ({
  onSelectCallback,
  selected_admin_id,
  placeholder,
  job_id,
  update_type=null
}) => {
  // console.log('<AdminSelect />');
  const [job_admin_mutation, { job_admin_mutation_data, job_admin_mutation_loading, job_admin_mutation_error }] = useMutation(EDIT_JOB_ADMIN);
  const { loading, error, data } = useQuery(QUERY_GET_ALL_ADMINS);
  if (loading) return 'Loading admin list...';
  if (error) return `Error loading all admins: ${error.message}`;

  let mapping = [{ label: 'Select admin', value: null }];
  data.GetAllAdmins.map(admin => {
    mapping.push({
      label: `${admin.first_name} ${admin.last_name}`,
      value: admin.id
    });
  });

  let admin_idx = null;
  if (selected_admin_id) {
    function findAdmin(obj) { return obj.value === selected_admin_id; }
    admin_idx = mapping.findIndex(findAdmin);
  }

  return (
    <Select
      defaultValue={(selected_admin_id ? mapping[admin_idx]: mapping[0])}
      placeholder={placeholder ? placeholder: 'No admin selected'}
      options={mapping}
      onChange={(e)=> {
        if (onSelectCallback) onSelectCallback(e);
        else
        if (job_id && job_id.length) {
          if (!e.value) e.value = '';
          job_admin_mutation({
            variables: {
              job_id: job_id,
              admin_id: e.value
            }
          }).then((result) => {
            toast.success(`Job admin edited.`);
          }).catch((error) => {
            toast.error(`Failed to update job admin. ${error}`);
          });
        }
      }}
    />
  );
};
