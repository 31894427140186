import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { LabelPreview } from '../../common/LabelPreview';
import { FormEvaluationList } from '../../common/FormEvaluationList';
import { FormEvaluation } from '../../common/FormEvaluation';
import { setCustomEvaluation } from 'features/QutterEvaluation';

export const FormSliderPreview = ({
  template_id,
  qutter_id,
  form_idx,
  preview_container
}) => {
  const dispatch = useDispatch();
  const Evaluation = useSelector(state => state.Evaluation[qutter_id]);
  const interviewReceptState = useSelector(state => state.InterviewReceipt);
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const [value, setValue] = useState(0);
  const [evaluation, setEvaluation] = useState(null);

  const language = qutterConfig.language.current;
  const qutter = qutterConfig[language];
  const forms = qutter.forms;
  const form = forms[form_idx];

  const min = form.min ? form.min: 0;
  const max = form.max ? form.max: 10;
  const step = form.step ? form.step: 1;

  // useEffect(() => {
  //   console.log(`<FormSliderPreview useEffect ${qutter_id}`)
  //   const customKey = `${form.id}.evaluation`;
  //   const evaluation = form.evaluation ?? [];
  //   if (!template_id) {
  //     setEvaluation(evaluation);
  //   } else {
  //     try {
  //       if (!Evaluation && !Evaluation[language] && !Evaluation[language][customKey]) {
  //         dispatch(setCustomEvaluation({
  //           language,
  //           qutter_id: qutter_id,
  //           id: form.id,
  //           evaluation: evaluation
  //         }));
  //       } else {
  //         setEvaluation(Evaluation[language][customKey]);
  //       }
  //     } catch (error) {
  //       console.log('FormSliderPreview useEffect error', error)
  //       dispatch(setCustomEvaluation({
  //         language,
  //         qutter_id: qutter_id,
  //         id: form.id,
  //         evaluation: evaluation
  //       }));
  //     }
  //   }
  // }, []);

  const onSliderChange = value => { setValue(value); };

  return (
  <>
    <div className="form-group">
      <LabelPreview label={form.label} is_mandatory={form.is_mandatory} />

      <div className='d-flex mt-1'>
        <div className='w-25 m-auto text-start'>
          <span>{form.label_left}</span>
        </div>
        <div className='w-50 m-auto text-center'>
          <span>{value} {form.unit}</span>
        </div>
        <div className='w-25 text-end'>
          <span>{form.label_right}</span>
        </div>
      </div>

      <div className='ms-6 me-6'>
        <Slider
          min={min}
          max={max}
          step={step}
          defaultValue={0}
          onChange={onSliderChange}
          dots={true}
          disabled={interviewReceptState.readOnlyMode ? true : false}
        />
      </div>
    </div>
    {/* <FormEvaluationList form={form} evaluation={evaluation}/> */}
    <FormEvaluation
      template_id={template_id}
      qutter_id={qutter_id}
      form={form}
    />
  </>
  );
}
