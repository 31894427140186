import { StripHTML } from "features/common";
import { nanoid } from "@reduxjs/toolkit";
import _ from "lodash";

export function prepare_questions_list (questions) {
  let list = [];
  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    const languages = question.available_languages;

    if (question.type === 'form') {
      let id_label_map = {};

      // Add each form in each language
      for (let j = 0; j < languages.length; j++) {
        const language = languages[j];
        const q = question[language];

        q.forms.map((form, idx) => {
          let id = null;

          if (form.type === 'slider') {
            id = `${question.id}#${form.id}`;

          } else if (form.type === 'matrix') {
            form.rows.map(row => {
              id = `${question.id}#${form.id}#${row.id}`;

              if (id_label_map.hasOwnProperty(id)) {
                id_label_map[id].label += ` / ${StripHTML(row.label)}`;
              } else {
                id_label_map[id] = {
                  id:           id,
                  label:        StripHTML(row.label),
                  question_id:  question.id,
                  type:         question.type,
                  form_id:      form.id,
                  form_type:    form.type,
                  row_id:       row.id,
                };
              }
            });

          } else if (form.type === 'text') {
            id = `${question.id}#${form.id}`;

          } else if (form.type === 'email') {
            id = `${question.id}#${form.id}`;

          } else if (form.type === 'checkbox') {
            id = `${question.id}#${form.id}`;

          } else if (form.type === 'range') {
            id = `${question.id}#${form.id}`;

          } else {
            // if (k === 0) label += StripHTML(form.label);
            // else label += `/${StripHTML(form.label)}`;  
            // list.push({ label: label, value: question.id });
          }

          if (form.type !== 'matrix') {
            if (id_label_map.hasOwnProperty(id)) {
              id_label_map[id].label += ` / ${StripHTML(form.label)}`;
            } else {
              id_label_map[id] = {
                id:           id,
                label:        StripHTML(form.label),
                question_id:  question.id,
                type:         question.type,
                form_id:      form.id,
                form_type:    form.type
              };
            }
          }

        });
      }

      for (const key in id_label_map) {
        let obj = {
          value:        id_label_map[key].id,
          label:        id_label_map[key].label,
          question_id:  id_label_map[key].question_id,
          type:         id_label_map[key].type,
          form_id:      id_label_map[key].form_id,
          form_type:    id_label_map[key].form_type,
        };

        if (obj.form_type === 'matrix')
          obj.row_id = id_label_map[key].row_id;
        list.push(obj);
      }

    } else 

    if (['single-select', 'multiple-select'].includes(question.type)) {
      let label = '';
      // Add question text in all languages
      for (let k = 0; k < languages.length; k++) {
        if (k === 0) label += StripHTML(question[languages[k]].text);
        else label += `/${StripHTML(question[languages[k]].text)}`;
      }
      list.push({
        value:        question.id,
        label:        label,
        question_id:  question.id,
        type:         question.type,
      });

    } else 

    if (question.type === 'open') {

    } else continue;

  }
  return list;
}

const prepare_questions = (questions) => {
  let global = [];
  let local = [];
  questions.map((question_config) => {
    const languages = question_config.available_languages;
    const global    = question_config.global;
    const id        = question_config.id;

    let text = '';
    languages.map((language, idx) => {
      const question = question_config[language];
      text += question.text + ' / ';
      if (idx !== 0) text += ' / ';
    });

    if (question_config.global) 
      global.push({ label: text, value: id });
    else 
      local.push({ label: text, value: id });
  });

  return {
    global: global,
    local: local
  };
};

export const get_select_labels = async (qutter_config) => {
  const languages = qutter_config.available_languages;

  // Add options in all languages
  let available_options = [];
  let id_label_map = {};

  for (let i = 0; i < languages.length; i++) {
    let language = languages[i];
    qutter_config[language].options.map(option => {
      if (id_label_map.hasOwnProperty(option.id)) {
        id_label_map[option.id] += ` / ${option.label}`;
      } else {
        id_label_map[option.id] = option.label;
      }
    });
  }

  for (const key in id_label_map) {
    available_options.push({ label: id_label_map[key], value: key });
  }

  return available_options;
};

export const get_form_labels = async (selection, question_config) => {
  const languages = question_config.available_languages;
  const form_type = selection.form_type;
  const form_id   =   selection.form_id;
  const row_id    =   selection.row_id;

  let options = [];
  let id_label_map = {};

  languages.map(async (language, idx) => {
    const question = question_config[language];
    const form = await question.forms.find(f => f.id === form_id);

    if (form_type === 'matrix') {
      await form.columns.map((column, col_idx) => {
        if (id_label_map.hasOwnProperty(column.id)) {
          id_label_map[column.id] += ` / ${column.label}`;
        } else {
          id_label_map[column.id] = {
            label:        `${column.label} (${col_idx})`,
            value:        `${column.id}`,
            row_id:       `${row_id}`,
            column_id:    `${column.id}`,
            form_id:      `${form_id}`,
            question_id:  `${question_config.id}`,
            type:         `matrix`
          };
        }
      });

      for (const key in id_label_map) {
        options.push(id_label_map[key]);
      }
    } else 

    if (form_type === 'slider') {
      const arr = _.range(form.min, form.max, form.step);
      console.log('slider arr', arr)
      await arr.map(element => {
        const fake_id = nanoid();
        if (id_label_map.hasOwnProperty(fake_id)) {
          id_label_map[fake_id] += ` / ${element}`;
        } else {
          id_label_map[fake_id] = {
            label:        `${element}`,
            value:        element,
            form_id:      `${form_id}`,
            question_id:  `${question_config.id}`,
            type:         `slider`
          };
        }
      });

      for (const key in id_label_map) {
        options.push(id_label_map[key]);
      }
    }

  });
  return options;
};


export const get_labels = async (question_selection, question) => {
  if (['single-select', 'multiple-select'].includes(question_selection.type)) {
    return await get_select_labels(question);
  } else 

  if (['form'].includes(question_selection.type)) {
    return await get_form_labels(question_selection, question);
  }
};