import { Badge } from 'react-bootstrap';

export const QutterTypeBadges = {
  info: <Badge pill className="p-2 mr-2">Info Question</Badge>,
  'single-select': <Badge pill className='ms-2'>Single Select</Badge>,
  'multiple-select': <Badge pill className='ms-2'>Multiple Select</Badge>,
  open: <Badge pill className='ms-2'>Open</Badge>,
  form: <Badge pill className='ms-2'>Form</Badge>,
  slider: <Badge pill className='ms-2'>Slider</Badge>,
  range: <Badge pill className='ms-2'>range</Badge>,
};
