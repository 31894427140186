import React from 'react';
import { Card, OverlayTrigger, Badge, Tooltip } from 'react-bootstrap';

import { CandidateMessagesOpen } from './CandidateMessagesOpen';
import { CandidateMessagesClosed } from './CandidateMessagesClosed';
import { EvaluationIcon } from './EvaluationIcon';

const interviewTagsTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props.description}
  </Tooltip>
);

export const CandidateMessages = ({
  message,
  scoringDetail
}) => {
  console.log(`<CandidateMessages />`, message);
  const evaluation = message.evaluation;
  const reusage = message.reusage;

  return (
    <Card
      className='candidate-message rounded-2'
      style={{
        color: 'black',
        backgroundColor: 'hsl(249deg 100% 97%)'
      }}
    >

      {scoringDetail && scoringDetail.aiDetection ? (
        <OverlayTrigger
          placement="right"
          delay={0}
          overlay={
            interviewTagsTooltip({
              description: `Score: ${scoringDetail.aiDetection.score}` 
            })
          }
        >
          <Badge
            bg={`${scoringDetail.aiDetection.is_ai ? 'warning': 'success'}`}
            className="me-1 w-100"
          >
            {(() => {
              if (scoringDetail.aiDetection.prediction_tag) {
                return (
                  <span>{scoringDetail.aiDetection.prediction_tag.split('_').join(' ')}</span>
                )
              } else 
              if (scoringDetail.aiDetection.tag) {
                return (
                  <span>{scoringDetail.aiDetection.tag.split('_').join(' ')}</span>
                )
              } else {
                return (
                  <span>Erroneous tag</span>
                )
              }
            })()}
          </Badge>
        </OverlayTrigger>
      ):(null)}

      {evaluation && evaluation.name ? (
        <span>
          <EvaluationIcon evaluation={evaluation} />
        </span>
      ):(null)}

      {reusage && reusage.is_reused ? (
        // <div className='float-end'>
          <span className='float-end'>Reused
          {reusage.is_edited ? (
            <span> and edited.</span>
          ):(
            <span> and not edited.</span>
          )}
          </span>
        // </div>
      ):(null)}

      <Card.Body>
        {message.type === 'open' ? (
          <CandidateMessagesOpen message={message} />
          ):(
          <CandidateMessagesClosed message={message} />
        )}
      </Card.Body>
    </Card>
  );
};
