import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LanguageSelect } from 'features/LanguageSelect';
import { InterviewPropertySelect, OperatorsSelect, InterviewQualifiedSelect } from '..';

import { customRuleConditionUpdate } from 'features/Parameters';

export const QutterConditionByInterviewProperty = ({
  template_id,
  qutter_id,
  condition,
  rule_idx,
  condition_idx,
}) => {
  const dispatch = useDispatch();
  const [property, set_property] = useState(condition.property ? condition.property: null);
  const [value, set_value] = useState(condition.value ? condition.value: null);

  const qutter_config = useSelector(state => state.Library[qutter_id]);

  console.log('<QutterConditionByInterviewProperty /> condition', condition)
  console.log('<QutterConditionByInterviewProperty /> property', property)
 
  useEffect(() => {
    let cond = Object.assign({}, condition);
    cond.property = property;
    cond.value = value;
    dispatch(customRuleConditionUpdate({
      qutter_id,
      rule_idx,
      condition_idx,
      condition: cond
    }));
  }, [property, value]);

  return (
    <div>

      <div className='d-flex font-size-10 m-1'>
        <div className='text-start m-auto'>
          Property:
        </div>
        <div className='w-50 text-start'>
          <InterviewPropertySelect
            on_select={(s) => {
              console.log('selected prop', s);
              set_property(s.value)
            }}
            selected={property}
          />
        </div>
      </div>

      {(() => {
        if (property === 'qualified') {
          return (
          <div className='d-flex font-size-10 m-1'>
            <div className='text-start m-auto'>
              Value:
            </div>
            <div className='w-50 text-start'>
              <InterviewQualifiedSelect
                on_select={(s) => {
                  console.log('selected prop', s);
                  set_value(s.value)
                }}
                selected={value}
              />
              {value ? (
                <p>
                  This question will <strong>only be shown</strong> if the current interview is <strong>{value ? value.split('_').join(' '): '...'}</strong>.
                </p>
              ):(null)}
            </div>
          </div>
          );
        } else

        if (property === 'language') {
          return (
          <div className='m-1'>
            <div className='d-flex font-size-10 m-1'>
              <div className='w-25 text-start m-auto'>
                Show this question only if interview language is any of the below:
              </div>
              <div className='w-50 text-start'>
                <OperatorsSelect
                  selected={condition.operator}
                  on_select={(s) => {
                    console.log('selected prop', s);
                    let cond = Object.assign({}, condition);
                    cond.operator = s.value;
                    dispatch(customRuleConditionUpdate({
                      qutter_id,
                      rule_idx,
                      condition_idx,
                      condition: cond
                    }));
                  }}
                />
                <LanguageSelect
                  multi={false}
                  selected_language={condition.value ? condition.value: null}
                  on_select={(selected) => {
                    console.log('selected lang', selected)
                    let cond = Object.assign({}, condition);
                    cond.value = selected.value;
                    dispatch(customRuleConditionUpdate({
                      qutter_id,
                      rule_idx,
                      condition_idx,
                      condition: cond
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          );
        }
      })()}
    </div>
  );
};