import { configureStore } from '@reduxjs/toolkit';

// Legacy
import ScorerSlice from '_components/score/redux/ScorerSlice';
// New/Current
import ScoringSlice from 'features/Scoring/slice/ScoringSlice';

// // Create Questions
import LibrarySlice from 'features/Qutters/slices/LibrarySlice';
import TemplateSlice from 'features/Template/slices/TemplateSlice';
import ParameterSlice from 'features/Parameters/slice/ParameterSlice';
import EvaluationSlice from 'features/QutterEvaluation/slice/EvaluationSlice';
import ConfigReducer from '_components/config/ConfigSlice';
import JobSlice from '_components/jobs/slices/JobSlice';
import ModalSlice from 'features/Modals/slices/ModalSlice';
import InterviewReceiptSlice from '../_components/interviewReceipt/InterviewReceiptSlice';

import TemplateOutlineSlice from 'features/TemplateOutline/slice/TemplateOutlineSlice';
import InterviewSummarySlice from 'features/InterviewSummary/slices/InterviewSummarySlice';

export const store = configureStore({
  reducer: {
    config: ConfigReducer,

    Library: LibrarySlice,
    Template: TemplateSlice,
    Parameters: ParameterSlice,
    Evaluation: EvaluationSlice,
    Job: JobSlice,
    Modals: ModalSlice,
    TemplateOutline: TemplateOutlineSlice,
    InterviewSummary: InterviewSummarySlice,
    InterviewReceipt: InterviewReceiptSlice,

    // Old Scoring
    scorer: ScorerSlice,
    // New Scoring
    scoring: ScoringSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
});
