import React from 'react';
import { 
  Accordion,
  Row,
  Col,
  Form
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import {
  optionPlus,
  optionIsMultipleSelectChange,
  optionOtherChange,
  optionNoneAboveChange,
  optionAllAboveChange
} from 'features/Qutters/slices/LibrarySlice';

export const SelectOptionSettings = ({
  id,
  language
}) => {
  const dispatch = useDispatch();
  // const qutter = useSelector(state => state.Library[id]);
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[language ? language: qutterConfig.language.current];

  const onOptionOtherChange = e => {
    dispatch(optionOtherChange({ id, tf: e.target.checked }));
  };
  
  const onOptionNoneAboveChange = e => {
    dispatch(optionNoneAboveChange({ id, tf: e.target.checked }));
  };

  const onOptionAllAboveChange = e => {
    dispatch(optionAllAboveChange({ id, tf: e.target.checked }));
  };

  const onOptionMultipleChange = e => {
    dispatch(optionIsMultipleSelectChange({ id, tf: e.target.checked }));
  };

  const onPlusClick = () => {
    dispatch(optionPlus({ id }));
  };

  if (!qutter) return (<span>Loading...</span>);

  return (
    <Row
      style={{
        paddingLeft: '1.25rem',
        paddingRight: '1.25rem'
      }}
    >
      <Col xs={6} className='m-auto'>
        <span
          className="m-1 cursor-pointer hover-blue d-inline"
          onClick={() => { 
            onPlusClick(); 
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} />
          <span className='ms-2'>Add option</span>
        </span>
      </Col>

      <Col>
        <Accordion className=''>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Other settings</Accordion.Header>
            <Accordion.Body>

              <Form.Check
                defaultChecked={(qutter.type === 'multiple-select' ? true: false)}
                type="switch"
                id="multiple-select-switch"
                className="ms-1"
                label="Accept multiple answers"
                disabled={false}
                key='select-accept-multiple-answers'
                onChange={(e) => onOptionMultipleChange(e)}
              />

              <Form.Check
                defaultChecked={(qutter.options.findIndex(option => option.id === 'other') > -1) ? true: false}
                type="switch"
                id="select-option-other-switch"
                className="ms-1"
                label='Add the option "Other"'
                disabled={false}
                key='select-option-other'
                onChange={(e) => onOptionOtherChange(e)}
              />

              {qutter.type === 'multiple-select' && (
                <>
                  <Form.Check
                    defaultChecked={(qutter.options.findIndex(option => option.id === 'all-above') > -1) ? true: false}
                    type="switch"
                    id="select-option-all-switch"
                    className="ms-1"
                    label='Add the option "All of the above"'
                    disabled={false}
                    key='select-option-all'
                    onChange={(e) => onOptionAllAboveChange(e)}
                  />

                  <Form.Check
                    defaultChecked={(qutter.options.findIndex(option => option.id === 'none-above') > -1) ? true: false}
                    type="switch"
                    id="select-option-none-switch"
                    className="ms-1"
                    label='Add the option "None of the above"'
                    disabled={false}
                    key='select-option-none'
                    onChange={(e) => onOptionNoneAboveChange(e)}
                  />
                </>
              )}

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  )
};