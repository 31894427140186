import React from 'react';

import {
  Container,
  Row,
  Col,
  Badge,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';

function compare( a, b ) {
  if ( a.score < b.score ){
    return -1;
  }
  if ( a.score > b.score ){
    return 1;
  }
  return 0;
}

const popover = ({header, data}) => {
  console.log('data.data', data.data)

  let copy = [...data.data];
  let sorted = copy.sort(compare);

  return (
  <Popover id="popover-basic" style={{ minWidth: '200px !important', width: '200px !important' }}>
    <Popover.Header as="h3">{header} - <Badge>{data.score}</Badge></Popover.Header>
    <Popover.Body 
      as={Container} 
      className='w-100' style={{ minWidth: '200px !important', width: '200px !important' }}>
      {sorted.map((d, idx) => {
        return (
          <Container key={'experience-popover-container-'+idx}>
            <Row className='text-center'>
              <Col>
              <h4>{d.label} - <Badge>{d.score.toFixed(0)}</Badge></h4>
              </Col>
            </Row>
            <Row className='w-100' style={{ minWidth: '200px !important', width: '200px !important' }}>
            <Row>
              <Col md={4}>Workplace</Col>
              {d.workplace.length ? (
                <Col md={8}>
                  {d.workplace.map((workplace, idx) => {
                    return (
                      <span key={'workplace-'+idx}>{workplace}</span>
                    )
                  }
                  )}
                </Col>
            ):(<Col md={8}>None</Col>)}
            </Row>
            <Row>
              <Col md={4}>Duration</Col>
              {d.duration.length ? (
                <Col md={8}>
                  {d.duration.map((duration, idx) => {
                    return (
                      <span key={'duration-'+idx}>{duration}</span>
                    )
                  }
                  )}
                </Col>
              ):(<Col md={8}>None</Col>)}
            </Row>
            <Row>
              <Col md={4}>Location</Col>
              {d.location.length ? (
                <Col md={8}>
                  {d.location.map((location, idx) => {
                    return (
                      <span key={'location-'+idx}>{location}</span>
                    )
                  }
                  )}
                </Col>
              ):(<Col md={8}>None</Col>)}
            </Row>
            </Row>
          </Container>
        )
      })}
    </Popover.Body>
  </Popover>)
}

export const ExperiencePopover = ({ header, data }) => {

  return (
    <OverlayTrigger trigger={["click"]} placement="left" overlay={popover({header , data})}>
      <Badge bg="success" className="me-2 float-end">{header.split('_').join(' ').toLowerCase()}</Badge>
    </OverlayTrigger>
  );
};

