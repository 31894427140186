import React, { useState, useEffect } from 'react';
import { CandidateFilterSearchModal } from '..';
import { get_job_by_id } from '../api/api';
import { toast } from 'react-toastify';

export const CandidateFilterSearchModalShowButton = ({
  job_id
}) => {
  const [showModal, setShowModal] = useState(false);

  const [template_version, set_template_version] = useState(1);
  const [client_id, set_client_id] = useState(null);
  const [template_id, set_template_id] = useState(null);

  useEffect(() => {
    // Preload job to get client and template
    get_job_by_id(job_id).then(async (job) => {
      try {
        if (!job) return;
        if (job.client_id)
          set_client_id(job.client_id);
        if (job.interviews && job.interviews[0].id) {
          const template = job.interviews[0];
          set_template_id(template.id);
          if (template.hasOwnProperty('template_version'))
            set_template_version(parseInt(template.template_version));
        }
      } catch (error) {
        toast.error(`Erro: ${error}`);
      }
    }).catch((error) => {
      toast.error(`CandidateFilterSearchModalShowButton failed: ${error}`);
    });
  }, []);

  if (template_version !== 2) return (null);

  return (
    <>
      {showModal && (
        <CandidateFilterSearchModal
          job_id={job_id}
          client_id={client_id}
          template_id={template_id}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}

      <span
        className='link-primary hover-blue m-1'
        style={{ fontSize: '9px', fontWeight: 'bold' }}
        onClick={(e) => { setShowModal(!showModal); }}
      >
        Candidate filter/search
      </span>
    </>
  );
};
