import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Form } from 'react-bootstrap';

import { 
  updateFormByIndex
} from 'features/Qutters/slices/LibrarySlice';

export const FormEmail = ({ qutter_id, form_idx }) => {
  console.log('<FormEmail />');
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  const forms = qutter.forms;
  const form = forms[form_idx];

  return (
  <div>
    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Label</span>
      </div>
      <div className='w-50 text-left'>
        {/* <Form.Control
          defaultValue={form.label}
          type="text"
          placeholder="Label"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.label = e.target.value;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
        /> */}

        <ReactQuill
          onChange={(e) => {
            let temp = Object.assign({}, form);
            console.log('ReactQuill e', e)
            temp.label = e;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
          placeholder="Label"
          defaultValue={form.label}
        />
      </div>
    </div>

    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Placeholder</span>
      </div>
      <div className='w-50 text-left'>
        <Form.Control
          defaultValue={form.placeholder}
          type="text" 
          placeholder="Placeholder"
          onChange={(e) => {
            let temp = Object.assign({}, form);
            temp.placeholder = e.target.value;
            dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
          }}
        />
      </div>
    </div>

  </div>
  );
}
