import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { ResetInterviewRequest } from '../api/api';

export const ResetInterviewDropdownItem = ({
  application_id,
  interview_id,
  idx
}) => {
  return (
    <Dropdown.Item 
      href="#!"
      onClick={() => { 
        console.log('Reset Interview')
        ResetInterviewRequest({
          application_id: application_id,
          interview_id: interview_id
        }).then((res) => {
          toast.success(res.message);
        }).catch((error) => {
          toast.error(`Error: ${error}.`);
        })
      }}
    >
      Reset interview {idx + 1}
    </Dropdown.Item>
  );
};
