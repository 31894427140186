import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';

import { 
  faBuilding
} from '@fortawesome/free-solid-svg-icons';

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const ClientDetailsHeader = ({
  client
}) => {

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row>
          <Col>
            <h5 className="mb-2">
              {client.org_name} 
              {/* (<a href="mailto:tony@gmail.com">tony@gmail.com</a>) */}
            </h5>
            {/* 
            <IconButton
              iconClassName="fs--2 me-1"
              variant="falcon-default"
              size="sm"
              icon="plus"
            >
              Add note
            </IconButton> 
            */}
            {/* 
            <Dropdown className="d-inline-block ms-2">
              <Dropdown.Toggle
                as={Button}
                variant="falcon-default"
                size="sm"
                className="dropdown-caret-none"
              >
                <FontAwesomeIcon icon="ellipsis-h" />
              </Dropdown.Toggle>

              <Dropdown.Menu align={isRTL ? 'end' : 'start'}>
                <Dropdown.Item as={Link} to="#!">
                  Edit
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="#!">
                  Report
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="#!">
                  Archive
                </Dropdown.Item>
                <Dropdown.Divider as="div" />
                <Dropdown.Item as={Link} to="#!" className="text-danger">
                  Delete user
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> 
            */}
          </Col>
          <Col xs="auto">
            <h6 className="text-uppercase text-600">
              Client
              <FontAwesomeIcon icon={faBuilding} className="ms-2" />
            </h6>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="border-top">
        <Flex>
          <FontAwesomeIcon
            className="text-success me-2"
            transform="down-5"
            icon={faBuilding}
          />
          <div className="flex-1">
            <p className="mb-0">Client was created</p>
            <p className="fs--1 mb-0 text-600">
              {new Date(client.created_at).toLocaleDateString("en-UK", options)}
            </p>
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default ClientDetailsHeader;
