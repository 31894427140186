import React from 'react';
import Select from 'react-select';
import { QUTTER_TYPES_LIST } from '..';

export const QutterTypeSelectSingle = ({
  on_select,
  selected_type,
  disabled,
  placeholder,
  className
}) => {

  let idx = null;
  if (selected_type) {
    function find (obj) { return obj.value === selected_type; }
    idx = QUTTER_TYPES_LIST.findIndex(find);
  }

  return (
    <Select
      isMulti={false}
      isDisabled={disabled}
      defaultValue={(selected_type ? QUTTER_TYPES_LIST[idx]: null)}
      placeholder={placeholder ? placeholder: 'Choose type'}
      className={className}
      options={QUTTER_TYPES_LIST}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};
