import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackspace, faPen } from '@fortawesome/pro-solid-svg-icons';

import { QutterRule, DeleteAllRulesButton, NewRuleOverlay } from '../..';
import { lookup } from 'features/common';

import { addCustomRule, customDeleteAllRules, resetToOriginalRules, rulesCopy } from 'features/Parameters';
import { addRule, deleteAllRules } from 'features/Qutters';

export const QutterRules = ({
  template_id,
  qutter_id,
  showModal,
  setShowModal
}) => {
  const dispatch = useDispatch();
  const [rules, set_rules] = useState([]);
  const [is_default, set_is_default] = useState(true);
  
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;

  let params = useSelector(state => state.Parameters[qutter_id]);
  const custom_key = `rules`;

  useEffect(() => {
    if (params && params.hasOwnProperty(custom_key)) {
      set_rules(params.rules);
      set_is_default(false);
    } else if (qutter_config.rules) {
      set_rules(qutter_config.rules);
      set_is_default(true);
    }
  }, [qutter_config, params, language]);

  return (
  <div>

    <div className='d-flex font-size-10 mb-3'>
      <div className='w-50 text-start m-auto'>
        <NewRuleOverlay
          template_id={template_id}
          qutter_id={qutter_id}
          is_default={is_default}
        />
      </div>
      <div className='w-50 text-end m-auto'>

        {template_id ? (
          <>
          {is_default ? (
            <span
              className={`hover-blue font-size-10 ms-1 me-1`}
              onClick={() => {
                dispatch(rulesCopy({ qutter_id, original: rules }));
              }}
            >
              <FontAwesomeIcon className='hover-blue me-1' size={'sm'} icon={faPen} />
              Edit rules
            </span>
          ):(
            <span
              className={`hover-blue font-size-10 ms-1 me-1`}
              onClick={() => {
                dispatch(resetToOriginalRules({ template_id, qutter_id }));
              }}
            >
              <FontAwesomeIcon
                className='hover-blue me-1'
                size={'sm'} 
                icon={faBackspace}
              />
              Reset to original
            </span>
          )}
          </>
        ):(null)}

        <DeleteAllRulesButton 
          template_id={template_id}
          qutter_id={qutter_id}
          rules={rules} 
          is_default={is_default}
        />

      </div>
    </div>
    <hr className="bg-200 dark__bg-900" />

    {rules.map((rule, rule_idx) => {
      return (
        <QutterRule
          key={`${qutter_id}-rules-${rule_idx}-${rule.id}`}
          template_id={template_id} 
          qutter_id={qutter_id}
          rule={rule}
          rule_idx={rule_idx}
          is_default={is_default}
        />
      )
    })}
  </div>
  );
};