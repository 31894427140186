import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SoftBadge from 'components/common/SoftBadge';

import { prepare_condition_option_label } from 'features/QutterRules/utils/utils';

export const PreviewRuleCondition = ({
  template_id,
  qutter_id,
  parent_id,
  condition,
  order,
 }) => {
  // console.log('<PreviewRuleCondition />', qutter_id);
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  if (!qutter_config) return (<span>hellooooo</span>)
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];

  const template = useSelector(state => state.Template[template_id]);

  const type = condition.type;
  const operator = condition.operator;
  const value = condition.value;

  const [language_list, set_language_list] = useState([]);
  const [labels, set_labels] = useState([]);
  const [error, set_error] = useState(null);

  useEffect(() => {
    if (['single-select', 'multiple-select'].includes(qutter_config.type)) {
      if (template) set_language_list(template.language_settings.list);
      else set_language_list(qutter_config.language.list);
  
      async function prepare () {
        const l = await prepare_condition_option_label(qutter_config, value, language_list);
        set_labels(l);
      }
      prepare();
    } else {
      if (Array.isArray(value))
        set_labels(value)
      else 
        set_labels([value])
    }
  }, [language_list]);

  if (error) return (<div>Error.</div>);

  return (
    <div className='font-size-10 mb-1'>
      {/* <div>{condition.id}</div> */}
      <div  className='font-size-10'>
        <span>If </span>
        <strong>{type === 'question_answer' ? 'answer to the question ': 'ERROR'}</strong>
        <strong>{operator}</strong>

        <span className='ms-1'>
        {labels.map((label, vidx) => {
          return (
            <SoftBadge
              key={`preview-rule-condition-${vidx}`}
              bg='primary' className='me-2'
            >
              {label}
            </SoftBadge>
          )
        })}
        </span>
      </div>

    </div>
  );
};