import { gql } from '@apollo/client';

export const EDIT_JOB_ROLE = gql`
mutation EditJobRole($job_id: String!, $role_id: String!) {
  EditJobRole(job_id: $job_id, role_id: $role_id)
}
`;

export const QUERY_GET_ALL_ROLES = gql`
query GetAllJobRoles {
  GetAllJobRoles {
    id
    role_id
    name
  }
}
`;