import React, { useState } from 'react';
import Select from 'react-select';

import { evaluation_list } from 'features/QutterEvaluation';

export const QutterEvaluationSelectMultiple = ({
  disabled,
  on_select, 
  selected,
  placeholder,
  className
}) => {

  let values = [];
  if (selected && selected.length > 0) {
    let idx = 0;
    evaluation_list.map((s) => {
      function find(obj) { return obj.value === s; }
      idx = evaluation_list.findIndex(find);
      if (idx > -1) evaluation_list.push(evaluation_list[idx]);
    });
  }

  return (
    <Select
      isMulti={true}
      className={className}
      isDisabled={disabled}
      value={values && values.length > 0 ? values: null}
      options={evaluation_list}
      placeholder={placeholder ? placeholder: ''}
      onChange={(e)=> {
        // console.log(e)
        if (on_select) on_select(e);
      }}
    />
  );
};
