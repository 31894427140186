import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  max_bonus_points: 0,
  max_weighted: 0,
  initial_weight: 0,
  weighted_total: 0,
  total: 0,
  per_qutter: {}
};

const ScorerSlice = createSlice({
  name: 'scorer',
  initialState,
  reducers: {
    initScorer (state, action) {
      const {
        max_bonus_points,
        max_weighted,
        initial_weight,
        weighted_total,
        total,
        per_qutter,
        qualified
      } = action.payload;

      return {
        max_bonus_points: max_bonus_points,
        max_weighted: max_weighted,
        initial_weight: initial_weight,
        weighted_total: weighted_total,
        total: total,
        per_qutter: per_qutter,
        qualified: qualified
      }
    },
    updateQutterScore (state, action) {
      const {
        qutter_id,
        scale_max,
        new_score
      } = action.payload;
      const qutter_score = state.per_qutter[qutter_id];
      if (qutter_score) {
        if (qutter_score.is_weighted === true) {
          let value = 
            (state.max_weighted * qutter_score.weight) * 
            (parseFloat(new_score)/scale_max);
          qutter_score.score = new_score;
          qutter_score.weighted = value;
          qutter_score.edited = true;
        } else {
          qutter_score.score = new_score;
          qutter_score.edited = true;
        }
      }
    },
    updateTotals (state, action) {
      const qutters = state.per_qutter;
      let total = 0;
      let weighted_total = 0;
      for (let k in qutters) {
        let q = qutters[k];
        if (q.is_weighted === true) {
          total += q.weighted;
          weighted_total += q.weighted;
        } else {
          total += q.score;
        }
      }
      return {
        ...state,
        weighted_total: weighted_total,
        total: total,
      }
    },
  }
});

export const { 
  initScorer,
  updateQutterScore,
  updateTotals
} = ScorerSlice.actions;
export default ScorerSlice.reducer;
