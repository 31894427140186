import React, { useState } from 'react';
import Divider from 'components/common/Divider';

import { TemplateQuestionAnswerFilter } from './TemplateQuestionSelect';

export const CandidateFilter = ({
  client_id,
  template_id,
  filters,
  filter_idx,
  set_filters
}) => {
  const [loading, set_loading] = useState(false);

  // 3 types: global, template, and client questions
  // They are Not exclusive - i.e. questions can be 
  // in all dropdowns

  return (
  <div className='m-2 p-3'>
    {filter_idx > 0 ? (
      <Divider className="mt-4 mb-4">and</Divider>
    ):(null)}

    <TemplateQuestionAnswerFilter
      template_id={template_id}
      filter_idx={filter_idx}
      filters={filters}
      set_filters={set_filters}
    />

  </div>
  );
};
