import axios from 'axios';
import { getHubertAccessToken, fetchHubertAccessToken } from 'tokens/hubertTokens';
import { getAccessToken, fetchAccessToken } from 'tokens/tokens';
import jwtDecode from 'jwt-decode';

const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const URL = `${base}/template-comments/v1`;
const httpClient = axios.create({ baseURL: base });

const isExpired = (token) => {
  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000;
};

httpClient.interceptors.request.use(async (config) => {
  let token = '';
  token = config.headers.userType === 'cs' ? getAccessToken(): getHubertAccessToken();
  if(!token || isExpired(token)) {
    const { accessToken } = config.headers.userType === 'cs' ? await fetchAccessToken(): await fetchHubertAccessToken();
    token = accessToken;
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const getCommentsByQuestion = async (user, filter) => {
  const config = { headers: { userId: user._id, userType: user.userType }};

  return new Promise((resolve, reject) => {
    httpClient.post(`${URL}/get-comments-by-question-id`, filter, config)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
};

export function postComment (user, comment) {
  const config = { headers: { userId: user._id, userType: user.userType }};
  const path = 
    user.userType === 'cs' ?
    'post-cs-comment':
    'post-user-comment';

  return new Promise((resolve, reject) => {
    httpClient.post(`${URL}/${path}`, comment, config)
    .then(result => {
      console.log('axios result ', result)
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}

export function getUnread (user, data) {
  const config = { headers: { userId: user._id, userType: user.userType }};

  return new Promise((resolve, reject) => {
    httpClient.post(`${URL}/unread`, data, config)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}

export function markAsread (user, data) {
  const config = { headers: { userId: user._id, userType: user.userType }};

  return new Promise((resolve, reject) => {
    httpClient.post(`${URL}/mark-as-read`, data, config)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}

export function getScriptsByClientId (user, clientId) {
  const config = { headers: { userId: user._id, userType: user.userType }};
  const URL = `${base}/templates/v1`;

  return new Promise((resolve, reject) => {
    httpClient.get(`${URL}/get-scripts-by-client-id/${clientId}`)
    .then(result => {
      console.log('getScriptsByClientId result ', result)
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}