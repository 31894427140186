import axios from 'axios';

const base = `${process.env.REACT_APP_URL}/templates/v1`;

export function getAllTemplates () {
  return new Promise((resolve) => {
    axios.get(`${base}/get-all-templates`)
    .then(res => {
      resolve(res.data);
    });
  });
};

export function saveNewTemplate (template) {
  return new Promise((resolve) => {
    axios.post(
      `${base}/save-new-template`,
      template
    )
    .then(res => {
      resolve(res.data);
    });
  });
};

export function createTemplateFromScratch () {
  return new Promise((resolve) => {
    axios.get(
      `${base}/create-template-from-scratch`
    )
    .then(res => {
      resolve(res.data);
    });
  });
};

export function updateTemplate (template) {
  return new Promise((resolve) => {
    axios.post(
      `${base}/update`,
      template
    )
    .then(res => {
      resolve(res.data);
    });
  })
}

export function getTemplateById (template_id) {
  return new Promise((resolve) => {
    axios.get(
      `${base}/get-by-id/${template_id}`,
    )
    .then(res => {
      resolve(res.data);
    });
  })
}

export function removeTemplateById (template_id) {
  return new Promise((resolve) => {
    axios.get(
      `${base}/remove-by-id/${template_id}`,
    )
    .then(res => {
      resolve(res.data);
    });
  })
};

export function GetTemplateJobs (templateId) {
  return new Promise((resolve, reject) => {
    axios.get(`${base}/get-template-jobs/${templateId}`)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}
