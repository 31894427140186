import React from 'react';
import { useDispatch } from 'react-redux';
import { Accordion } from 'react-bootstrap';

import { customRuleConditionPlus } from 'features/Parameters';
import { ruleConditionPlus } from 'features/Qutters';

import { AddRuleConditionButton, RuleCondition } from '../..';

export const RuleConditions = ({
  template_id,
  qutter_id,
  rule_idx,
  rule,
  rule_type,
  is_default,
}) => {
  const dispatch = useDispatch();
  const conditions = rule.conditions;

  return (
    <>
      {conditions && conditions.map((condition, condition_idx) => {
        return (
          <RuleCondition
            key={`${qutter_id}-rule-condition-${condition_idx}`}
            template_id={template_id}
            qutter_id={qutter_id}
            is_default={is_default}
            rule_idx={rule_idx}
            rule_type={rule_type}
            condition={condition}
            condition_idx={condition_idx}
          />
        )
      })}

      <AddRuleConditionButton
        template_id={template_id}
        qutter_id={qutter_id}
        is_default={is_default}
        on_click={() => {
          if (template_id) {
            dispatch(customRuleConditionPlus({ template_id, qutter_id, rule_idx }));
          } else {
            dispatch(ruleConditionPlus({ qutter_id, rule_idx }));
          }
        }}
      />
    </>
  );
};