import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning } from '@fortawesome/pro-solid-svg-icons';
import { SelectActionsModal } from 'features/QutterSelects/components/SelectActionsModal';

import { EvaluationOverlay } from 'features/QutterEvaluation';

export const PreviewIntent = ({
  template_id,
  qutter_id,
  intent_idx,
  order
}) => {
  const [showActionsModal, setShowActionsModal] = useState(false);

  const templateConfig = useSelector(state => state.Template);
  const readOnly = templateConfig.readOnlyMode;

  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];

  if (!qutter) return <span>Intent missing.</span>;
  const intent = qutter.intents[intent_idx];

  // Option properties
  let Evaluation = useSelector(state => state.Evaluation[qutter_id]);
  const has_actions = intent.actions && intent.actions.length > 0;
  const custom_key = `${intent.intent}.evaluation`;

  const [evaluation, set_evaluation] = useState(intent.evaluation);
  const [is_default, set_is_default] = useState(true);

  useEffect(() => {
    if (!Evaluation || !Evaluation[language]) return;

    if (Evaluation[language].hasOwnProperty(custom_key)) {
      set_evaluation(Evaluation[language][custom_key]);
      set_is_default(Evaluation[language].is_default);
    }
    // else {
    //   set_evaluation({ name: option.evaluation });
    //   set_is_default(true);
    // }
  }, [qutter_config, Evaluation, language]);

  return (
    <Row className="m-1 option-row-hover">
      <Col className="m-auto" style={{ position: 'relative' }}>
        {showActionsModal && (
          <SelectActionsModal
            id={qutter_id}
            option_idx={option_idx}
            showModal={showActionsModal}
            setShowModal={setShowActionsModal}
          />
        )}

        <div className="option text-center w-50 m-auto">
          <span className="">{intent.intent}</span>
          <span className="font-size-10">{`${intent.intent}`}</span>
        </div>

        <div style={{ position: 'absolute', top: '3px', right: 0 }}>
          <div className="m-auto float-end" style={{ verticalAlign: 'middle' }}>
            {readOnly ? (null):
            (
              <span
                onClick={() => {
                  setShowActionsModal(true);
                }}
                className={`cursor-pointer hover-blue ${
                  showActionsModal || has_actions ? 'color-blue' : ''
                }`}
              >
                <FontAwesomeIcon icon={faBoltLightning} />
              </span>
            )}

            <EvaluationOverlay
              template_id={template_id}
              qutter_id={qutter_id}
              evaluation={evaluation}
              type={'intent'}
              id={intent.intent}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};
