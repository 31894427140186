var accessToken = '';

export const getHubertAccessToken = () => {
  return accessToken;
};

export const setHubertAccessToken = token => {
  accessToken = token;
};

export const fetchHubertAccessToken = () => {
  return new Promise((resolve, reject) => {
    const uri = process.env.REACT_APP_URL ? process.env.REACT_APP_URL + '/hubert-refresh-token': 'http://localhost:9091/hubert-refresh-token';

    fetch(uri, {
      method: 'POST',
      credentials: 'include'
    })
      .then(async (x) => {
        const { accessToken, user } = await x.json();
        setHubertAccessToken(accessToken);
        resolve({ accessToken, user });
      })
      .catch(err => {
        setHubertAccessToken('');
        console.error('fetchHubertAccessToken', err);
        reject(err);
      });
  });
};
