import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LANGUAGE_LABELS } from 'features/LanguageSelect/assets/constants';
import {
  toggleEditQutterModal
} from 'features/Modals/slices/ModalSlice';

import { addLanguage } from 'features/Qutters/slices/LibrarySlice';

export const MissingQutter = ({
  template_id,
  qutter_id,
  missingLanguage
}) => {
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const templateConfig = useSelector(state => state.Template);
  // const qutter = qutterConfig[qutterConfig.language.list[0]];
  // console.log('<MissingQutter />', qutter);
  // console.log('<MissingQutter /> missingLanguage', missingLanguage);

  return (
  <span>
    {`Missing complete definition for `}
    {LANGUAGE_LABELS[missingLanguage]}
    .
    {templateConfig.readOnlyMode ? (null):(
      <span>
        Create it 
        <span 
          className='link-primary hover-blue'
          onClick={() => {
            dispatch(addLanguage({
              qutter_id,
              copy_from_language: qutterConfig.language.list[0],
              new_language: missingLanguage
            }));
            dispatch(toggleEditQutterModal({
              show: true,
              qutter_id: qutter_id,
              language: missingLanguage,
              newLanguage: false
            }));
          }}
        > here
        </span>.
      </span>
    )}
  </span>
  );
};
