import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

import { Table } from 'react-bootstrap';

import { InterviewActionsSelect } from 'features/HubertActions';

import {
  qutterPostActionsPlus,
  qutterPostActionsMinus,
  updateQutterPostAction
} from '../../Qutters/slices/LibrarySlice';

export const QutterPostActions = ({
  template_id,
  block_id,
  qutter_id,
}) => {
  // console.log('<QutterActions />', qutter_id);
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const actions = qutterConfig.post_actions;

  return (
  <div>
    <span className='h6 d-block'>
      Post actions
      <span
        onClick={(e) => {
          dispatch(qutterPostActionsPlus({ id: qutter_id }))
        }}
        className='hover-blue d-block float-end'
      >
        <span className="font-size-10 me-2">
          <FontAwesomeIcon icon={faPlusCircle} />
        </span>
        <span className="font-size-10">
          Add action
        </span>
      </span>
    </span>
    <Table>
      <tbody>
      {actions.map((action, idx) => {
        return (
          <tr
            key={`interview-action-select-${idx}`}
          >
            <td>
              {/* <InterviewActionsSelect
                selected={action}
              /> */}

              <InterviewActionsSelect
                selected={action}
                on_select={(selected) => {
                  console.log('selected post action', selected)
                  dispatch(updateQutterPostAction({
                    id: qutter_id,
                    idx,
                    post_action: selected.value
                  }))
                }}
              />
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <span
                onClick={(e) => {
                  dispatch(qutterPostActionsMinus({ id: qutter_id, idx }))
                }}
                className=''
              >
                <FontAwesomeIcon
                  className='hover-blue'
                  style={{ color: 'red' }}
                  icon={faCircleXmark}
                />
              </span>
            </td>
          </tr>
        )
      })}
      </tbody>
    </Table>
  </div>
  )
}
