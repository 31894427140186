import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

export const FormMatrixEvaluation = ({ qutter_id, form_idx }) => {
  console.log('<FormMatrixEvaluation />');

  return (
    <div>
      FormMatrixEvaluation
    </div>
  )
}
