import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findOptionValue } from 'features/common';

import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faPlusCircle,
  faComment
} from '@fortawesome/free-solid-svg-icons';

import {
  Table,
  Form
} from 'react-bootstrap';

import { 
  intentPlus,
  intentMinus,
  intentChange,
  intentEvaluationChange,
  intentBonusChange,
  intentWeightChange
} from 'features/Qutters/slices/LibrarySlice';

import { evaluation_list } from 'features/QutterEvaluation';

import IntentReplyModal from './IntentReplyModal';
import { allIntents, groupedIntents } from '../constants/intents';

/**
 * Hierarchy:
 * 1. disqualification  --> not qualified
 * 2. point             --> open, weighted question
 * 3. bonus             --> 
 * 4. warnings          --> 
 * 5. info              --> informational, if nothing else, than this
 */

 const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const Intent = ({
  id,
  intent,
  intent_idx,
}) => {
  // console.log('<Intent />'/*, question_id*/);
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[qutterConfig.language.current];
  const [showReplyModal, setShowReplyModal] = useState(false);

  console.log('intent', intent)

  return (
  <tr className='mt-2'>
    <IntentReplyModal
      showModal={showReplyModal}
      setShowModal={setShowReplyModal}
      id={id}
      intent_idx={intent_idx}
    />
    <td 
      style={{ verticalAlign: 'middle' }}
    >
      <Select
        defaultValue={findOptionValue(allIntents, intent.intent)}
        options={groupedIntents}
        isDisabled={false}
        onChange={(e)=> {
          dispatch(intentChange({ id, intent_idx, intent: e.value }));
        }}
        formatGroupLabel={formatGroupLabel}
      />
    </td>
    <td
      style={{ verticalAlign: 'middle' }}
    >
      <Select
        defaultValue={findOptionValue(evaluation_list, intent.evaluation)}
        options={evaluation_list}
        isDisabled={false}
        onChange={(e)=> {
          dispatch(intentEvaluationChange({ id, intent_idx, evaluation: e.value }));
        }}
      />
      {qutter.intents[intent_idx].evaluation === 'bonus' && (
        <div>
          <span>Default Bonus Points</span>
          <Form.Control
            defaultValue={('bonus_points' in qutter.intents[intent_idx]) ? parseInt(qutter.intents[intent_idx].bonus_points): 0}
            type="number"
            min={0}
            max={100}
            placeholder="Default Bonus"
            onChange={(e) => {
              dispatch(intentBonusChange({ id, intent_idx, bonus_points: e.target.value }));
              }}
          />
        </div>
      )}

      {qutter.intents[intent_idx].evaluation === 'point' && (
        <div>
          <span>Default Weight</span>
          <Form.Control
            defaultValue={('weight' in qutter.intents[intent_idx]) ? parseInt(qutter.intents[intent_idx].weight): 0}
            type="number"
            min={0}
            max={100}
            placeholder="Default Weight"
            onChange={(e) => {
              dispatch(intentWeightChange({ id, intent_idx, weight: e.target.value }));
              }}
          />
        </div>
      )}
    </td>

    <td 
      className='text-center m-auto'
      style={{ verticalAlign: 'middle' }}
    >
      <span
        onClick={() =>  setShowReplyModal(true) }
        className='cursor-pointer m-2'
      >
        <FontAwesomeIcon icon={faComment} />
      </span>
      <span
        onClick={() => { dispatch(intentMinus({ id, intent_idx })) }}
        className='cursor-pointer'
      >
        <FontAwesomeIcon icon={faMinusCircle} />
      </span>
    </td>
  </tr>
  );
};

const Intents = ({
  id,
}) => {
  // console.log('<Intents />', id);
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[qutterConfig.language.current];

  const onPlusClick = () => { dispatch(intentPlus({ id })); };
  if (!qutter) return (<span>Loading...</span>);

  return (
  <div>
    <div>
      <span
        className="m-1 hover-blue font-size-10"
        onClick={() => { onPlusClick(); }}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
        <span className='ms-2'>Add intent</span>
      </span>
    </div>
    {qutter.intents && qutter.intents.length ? (
    <Table hover size="sm">
      <tbody style={{ verticalAlign: 'center' }}>
      {qutter.intents.map((intent, idx) => {
        return (
          <Intent
            key={`${id}-intent-${intent.intent}-${idx}`}
            id={id}
            intent={intent}
            intent_idx={idx}
          />
        )
      })}
      </tbody>
    </Table>
    ):(
      <div className='font-size-10'>
        <span>No intents added.</span>
      </div>
    )}
  </div>
  );
};

export default Intents;
