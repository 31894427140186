import axios from 'axios';

export function saveNewJob (job) {
  return new Promise((resolve) => {
    axios.post(
      `${process.env.REACT_APP_URL}/jobs/v1/save-new`,
      job
    ).then(res => { resolve(res.data); });
  });
}
