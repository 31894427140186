import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Table } from 'react-bootstrap';

import { TemplatesList } from 'features/Template';
import { getScriptsByClientId } from 'features/TemplateSelect';
import { LANGUAGE_FLAGS } from 'features/LanguageSelect/assets/constants';

export const AppTemplatesIndex = () => {
  const user = useSelector(state => state.config.user);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ isError: false, message: '' });
  const [templatesList, setTemplatesList] = useState([]);

  useEffect(() => {
    if (user && user.client_id) {
      getScriptsByClientId(user.client_id)
      .then((response) => {
        if (response && Array.isArray(response)) {
          setTemplatesList(response);
        } else {
          setTemplatesList([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    }
  }, []);

  if (loading) return 'Loading...';
  if (error.isError) return `Error! ${error.message}`;

  return (
  <Card className='m-4'>
    <Card.Header>
      <h5 className="mb-0">My Hubert Templates</h5>
    </Card.Header>
    <Card.Body className="">

      <Table className='m-0'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Role Name</th>
            <th>Language</th>
          </tr>
        </thead>
        <tbody>
          {templatesList.map((template, idx) => {
            let url = `/app/script/${template._id}`

            return (
              <tr
                key={`${template.id}-${idx}`}
                className="g-0 align-items-center border-bottom py-2 px-3"
              >
                <td>
                  <p className="mb-0">
                    <Link to={url}>{template.name}</Link>
                  </p>
                </td>
                <td>
                  <p className="mb-0">
                    {template.description}
                  </p>
                </td>
                <td>
                  <p className="mb-0">
                    {template.role.name}
                  </p>
                </td>
                <td>
                  <span>
                    {template.language_settings ? (
                      template.language_settings.list.map((language, idx) => {
                        return (<span key={`${language}-${idx}`}>{LANGUAGE_FLAGS[language]}</span>)
                      })
                    ): (null)}
                  </span>
                </td>
              </tr>
            )}
          )}
        </tbody>
      </Table>
{/* 
    {(() => {
      if (templatesList && templatesList.length) {
        return (
          <TemplatesList
            clientName={''}
            templates={templatesList}
            show={true}
          />
        );
      } else {
        return (
          <span>No scripts yet.</span>
        );
      }
    })()} */}
    </Card.Body>
  </Card>
  );
};
