import React, { useState }  from 'react';
import { EvaluationOverlay } from 'features/QutterEvaluation';

const operators = {
  'lt': 'Less than',
  'gt': 'Greater than',
  'GREATER_THAN': 'Greater than',
  'LESS_THAN': 'Less than'
};

export const CalendarEvaluationElement = ({
  template_id,
  qutter_id,
  evaluation
}) => {
  const condition = evaluation.condition;

  return (
  <div className='d-flex'>
    <div className='w-75 text-center'>
      {condition.type === 'relative' ? (
        <span>
          <span>
            <span>If answer is </span>
            <strong>
              <i>
                <span>{operators[condition.operator]} </span>
              </i>
            </strong>
            <strong>
              <i>
                <span>{condition.value} </span>
              </i>
            </strong>
          </span>
        </span>
      ):(
        <span>
          <span>
            <span>If answer is </span>
            <strong>
              <i>
                <span>{operators[condition.operator]} </span>
              </i>
            </strong>
            <strong>
              <i>
                <span>{new Date(condition.value).toDateString()}</span>
              </i>
            </strong>
          </span>
        </span>
      )}
    </div>
    <div className='w-25 text-end'>
      <EvaluationOverlay
        template_id={template_id}
        qutter_id={qutter_id}
        evaluation={evaluation}
        type={'calendar'}
      />
    </div>
  </div>
  );
}
