import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

const Font = ReactQuill.Quill.import('formats/font');
const SizeStyle = ReactQuill.Quill.import('attributors/style/size');
Font.whitelist = ['large', 'medium', "small", "regular", "bold", "pullquote"] ;
ReactQuill.Quill.register(Font, true);
ReactQuill.Quill.register(SizeStyle, true);


// var Link = ReactQuill.Quill.import('formats/link');
// class CustomLink extends Link {
//   constructor(
//     bounds,
//     children,
//     className,
//     defaultValue,
//     formats,
//     id,
//     modules
//   ) {
//     console.log('CustomLink bounds', bounds)
//     console.log('CustomLink children', children)
//     console.log('CustomLink className', className)
//     console.log('CustomLink defaultValue', defaultValue)
//     console.log('CustomLink formats', formats)
//     console.log('CustomLink id', id)
//     console.log('CustomLink modules', modules)
//     this.quill = quill;
//     this.options = options;
//     this.container = document.querySelector(options.container);
//     quill.on('text-change', this.update.bind(this));
//     this.update();  // Account for initial contents
//   }

//   calculate() {
//     let text = this.quill.getText();
//     if (this.options.unit === 'word') {
//       text = text.trim();
//       // Splitting empty text returns a non-empty array
//       return text.length > 0 ? text.split(/\s+/).length : 0;
//     } else {
//       return text.length;
//     }
//   }
  
//   update() {
//     var length = this.calculate();
//     var label = this.options.unit;
//     if (length !== 1) {
//       label += 's';
//     }
//     this.container.innerText = length + ' ' + label;
//     console.log(length + ' ' + label)
//   }
// }
// ReactQuill.Quill.register('formats/link', CustomLink, true);


class Counter {
  constructor(quill, options) {
    console.log('Counter quill', quill)
    console.log('Counter options', options)
    this.quill = quill;
    this.options = options;
    this.container = document.querySelector(options.container);
    quill.on('text-change', this.update.bind(this));
    this.update();  // Account for initial contents
  }

  calculate() {
    let text = this.quill.getText();
    if (this.options.unit === 'word') {
      text = text.trim();
      // Splitting empty text returns a non-empty array
      return text.length > 0 ? text.split(/\s+/).length : 0;
    } else {
      return text.length;
    }
  }
  
  update() {
    var length = this.calculate();
    var label = this.options.unit;
    if (length !== 1) {
      label += 's';
    }
    this.container.innerText = length + ' ' + label;
  }
}

ReactQuill.Quill.register('modules/counter', Counter);


const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    // [{ script:  "sub" }, { script:  "super" }],
    ["blockquote", /*"code-block"*/],
    [{ list:  "ordered" }, { list:  "bullet" }],
    [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
    ["link", /*"image", "video"*/],
    ["clean"],
    // ["custom-link"],
  ],
  counter: {
    container: '#counter',
    unit: 'word'
  }
};


export const HubertQuill = ({
  on_change,
  placeholder,
  value,
}) => {
  console.log('<HubertQuill />');
  const [text, set_text] = useState('');

  useEffect(() => {
    const el = document.getElementsByClassName("example");
  }, []);
  
  return (
    <div 
      className="text-editor" 
      style={{ border: '1px solid slategray', borderRadius: '5px' }}
    >
      <div className='variables-popover-container'>
        hello
      </div>
      <div id="counter"></div>
      <ReactQuill 
        // theme="bubble"
        theme="snow"
        syntax
        placeholder={placeholder ? placeholder: 'Write something...'}
        modules={modules}
        // onChange={set_text}
        onChange={on_change}
        onKeyDown={(e) => {
          console.log('keydown: ', e)
        }}
        defaultValue={value}
      />
    </div>
  )
};
