import React, { useState, useEffect } from 'react';
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

import { get_qutter_in_templates } from '../api/api';

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    Cell: rowData => {
      const { name, _id } = rowData.row.original
      return(
        <Link
          target='_blank'
          to={`/templates/${_id}`}
        >
          <Flex alignItems="center">
            {name}
          </Flex>
        </Link>
      )
    }
  },
  {
    accessor: 'description',
    Header: 'Description'
  },
  {
    accessor: 'language_settings',
    Header: 'Languages',
    Cell: rowData => {
      const { language_settings } = rowData.row.original;
      if (!language_settings || !language_settings.list || !language_settings.list.length) return <span></span>; 
      return (
        language_settings.list.map((language, idx) => {
          return (
            <span>{language}</span>
          )
        })
      )
    }
  }
];

export const QutterInTemplateList = ({
  id,
  className
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    get_qutter_in_templates(id)
    .then((data) => {
      console.log(data)
      setList(data);
    })
    .catch((error) => {
      setError(true);
      setErrorMessage(error.toString());
    })
  }, [id]);

  if (error) {
    return (<div>{errorMessage}</div>);
  }

  if (!list || !list.length) {
    return (null);
  }

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={list}
      sortable
    >
      <AdvanceTable
        table
        headerClassName="bg-200 text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          bordered: true,
          striped: true,
          className: 'fs--1 mb-0 overflow-hidden'
        }}
      />
    </AdvanceTableWrapper>
  )
};
