import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getHubertAccessToken, fetchHubertAccessToken } from 'tokens/hubertTokens';
import jwtDecode from 'jwt-decode';

const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const URL = `${base}/template-comments/v1`;
const httpClient = axios.create({ baseURL: base });

const isExpired = (token) => {
  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000;
}

httpClient.interceptors.request.use(async (config) => {
  let token = getHubertAccessToken();
  if(!token || isExpired(token)) {
      const { accessToken } = await fetchHubertAccessToken();
      token = accessToken;
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

import {
    Container,
    Row,
    Col,
    Tabs,
    Tab,
  } from 'react-bootstrap';
import {
  disableReadOnlyMode,
  enableReadOnlyMode
} from './InterviewReceiptSlice';
import { TemplateOverview } from 'features/TemplateOverview';
import { Template } from 'features/Template';
import { InterviewSummaryContainer, set_interview_summary } from 'features/InterviewSummary';
import { Loading } from 'features/common';
import { ModalsContainer } from 'features/Modals';

import { ResetOutline } from 'features/TemplateOutline';
import { resetEvaluation } from 'features/QutterEvaluation';
import { ResetParameters } from 'features/Parameters';
import { ResetInterviewSummary } from 'features/InterviewSummary/slices/InterviewSummarySlice';

import {
  addTemplate,
  toggleStatus,
  setReadOnlyMode,
} from 'features/Template/slices/TemplateSlice';

const InterviewReceipt = () => {
  const dispatch = useDispatch();
  let { template_id } = useParams();
  const [tab_key, set_tab_key] = useState('interview');
  const [loading, setLoading] = useState(true);
  let test_job = null;

  useEffect(() => {
    dispatch(enableReadOnlyMode());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(disableReadOnlyMode());
    };
  }, []);

  useEffect(() => {
    // Reset all stores
    dispatch(ResetOutline({ languages: null }));
    dispatch(resetEvaluation({ evaluation: null }));
    dispatch(ResetParameters({ parameters: null }));
    dispatch(ResetInterviewSummary({ template_id, interview_summary: null }));
    dispatch(setReadOnlyMode({ mode: true }));

    httpClient
      .get(`${process.env.REACT_APP_URL}/templates/v1/template/${template_id}`)
      .then(async res => {
        const template = res.data.template;

        dispatch(ResetOutline({ languages: template.language_settings.list }));
        dispatch(resetEvaluation({ evaluation: template.evaluation }));
        dispatch(ResetParameters({ parameters: template.parameters }));
        dispatch(ResetInterviewSummary({ 
          template_id: template._id,
          interview_summary: template.interview_summary 
        }));

        dispatch(addTemplate({ 
          id: template._id,
          template: template
        }));

        setLoading(false);
        dispatch(toggleStatus({ 
          id: template._id,
          status: 'idle'
        }));
      });
  }, []);

  if (!template_id) return <div>loading...</div>

  return (
    <>
      <ModalsContainer />

      {template_id === 'loading' ? (
        <Loading />
      ) : (
        <Container
          style={{ overflowY: 'auto', maxWidth: 'none' }}
        >
          <Row >
            <Col
              xs="12"
              md="3"
              lg="3"
              style={{ maxHeight: 'calc(100vh - 40px)', overflowY: 'auto' }}
              className="pb-5"
            >
              <TemplateOverview id={template_id} test_job={test_job} />
            </Col>

            <Col
              id="search-qutter-container"
              style={{ maxHeight: 'calc(100vh - 40px)', overflowY: 'auto' }}
              className="pb-5"
            >
              <Tabs
                id="templates-tabs"
                activeKey={tab_key}
                onSelect={k => set_tab_key(k)}
                className="mb-3"
                style={{ borderBottom: 'unset' }}
              >
                <Tab eventKey="interview" title="Interview">
                  {!loading && tab_key && tab_key === 'interview' ? (
                    <Template id={template_id} />
                  ) : (
                    <span>Nothing to show</span>
                  )}
                </Tab>

                <Tab eventKey="summary" title="Summary">
                  {!loading && tab_key && tab_key === 'summary' ? (
                    <InterviewSummaryContainer template_id={template_id} />
                  ) : (
                    <span>Nothing to show</span>
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default InterviewReceipt;
