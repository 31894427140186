import axios from 'axios';

const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const URL = `${base}/jobs/v1/invite-candidate`;

export function InviteCandidateRequest (data) {
  return new Promise((resolve, reject) => {
    axios.post(`${URL}`, data)
    .then(result => {
      console.log('Result')
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}
