import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { ruleActionUpdate } from 'features/Qutters';

import { array_move } from 'features/QutterRules/utils/utils';

export const RuleActionJumpToValueUp = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  action_idx,
  action,
  item_id,
  item_idx,
  num_items,
}) => {
  const dispatch = useDispatch();
  const [loading, set_loading] = useState(true);

  if (template_id) {
    return null;
  }

  const onClick = async () => {
    let updated_action = JSON.parse(JSON.stringify(action));
    updated_action.value = await array_move (updated_action.value, item_idx, (item_idx - 1));
    dispatch(ruleActionUpdate({
      qutter_id,
      rule_idx,
      action_idx,
      updated_action
    }));
  };

  if (item_idx !== 0) {
    return (
    <div className='w-50 m-auto text-start'>
      <FontAwesomeIcon
        className='hover-blue'
        style={{ color: 'red' }} 
        // size={'sm'}
        icon={faArrowUp}
        onClick={onClick}
      />
    </div>
    );
  }
  else return null;
};