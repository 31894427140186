import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

import Flag from 'react-world-flags';

import { setCurrentQutterLanguage } from 'features/Qutters/slices/LibrarySlice';

export const LANGUAGE_MAP = {
  '': '',
  'sv-se': 'SE',
  'en-gb': 'GB',
  'en-us': 'US',
  'es-es': 'ES',
  'es-mx': 'ES',
  'fr-fr': 'FR',
  'de-de': 'DE',
  'no-no': 'NO',
  'da-dk': 'DK',
  'fi-fi': 'FI',
  'it-it': 'IT',
};

const TemplateQutterLanguages = ({
  template_id,
  qutter_id,
  // language,
  onClick,
}) => {
  const dispatch = useDispatch();
  const language_settings = useSelector(state => state.Template[template_id].language_settings);
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const [active, setActive] = useState(qutterConfig.language.current);
  const [anyMissing, setAnyMissing] = useState(false);

  useEffect(() => {
    language_settings.list.map((language, idx) => {
      let l = qutterConfig[language];
      if (!(l)) setAnyMissing(true);
    });
  }, []);

  return (
  <>
  {anyMissing && (
    <span
      onClick={() => {}}
      className='red me-2 /*float-end*/ font-size-10'>
      <FontAwesomeIcon icon={faExclamationTriangle} />
    </span>
  )}
  {language_settings.list && language_settings.list.map((_language) => {
    return (
      <span
        key={`template-qutter-language-${template_id}-${qutter_id}-${_language}`} 
        // className={`mb-1`}
        onClick={() => {
          dispatch(setCurrentQutterLanguage({ id: qutter_id, language: _language }));
          setActive(_language);
        }}
      >
        {(qutterConfig[_language]) ? (
          <Flag
            code={LANGUAGE_MAP[_language]}
            height="8"
            className={`me-2 ${qutterConfig.language.current === _language ? 'grow': 'cursor-pointer'}`}
          />
        ):(
          <Flag 
            code={LANGUAGE_MAP[_language]}
            height="8"
            style={{
              border: '1px solid red'
            }}
            className={`me-2 ${qutterConfig.language.current === _language ? 'grow': 'cursor-pointer'}`}
          />
        )}
      </span>
    );
  })}
  </>
  );
};

const JustQutterLanguages = ({
  qutter_id,
  onClick
}) => {
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  return (
    qutterConfig.language.list.map((language) => {
      return (
        <span
          key={`qutter-language-${qutter_id}-${language}`}
          className={`cursor-pointer`}
          onClick={() => {
            dispatch(setCurrentQutterLanguage({ id: qutter_id, language }));
            if (onClick) {
              onClick(language);
            }
          }}
        >
          {qutterConfig.language.current === language ? (
            <Flag
              code={LANGUAGE_MAP[language]}
              height="8"
              className={`me-2 grow`}
            />
          ) : (
            <Flag
              code={LANGUAGE_MAP[language]}
              height="8"
              className={`me-2`}
            />
          )}
        </span>
      );
    })
  );
};

const QutterLanguages = ({
  template_id,
  // language,
  qutter_id,
  onClick
}) => {
  return (template_id ? (
    <TemplateQutterLanguages
      template_id={template_id}
      qutter_id={qutter_id}
      // language={language}
      onClick={onClick}
    />
  ) : (
    <JustQutterLanguages
      qutter_id={qutter_id}
      onClick={onClick}
    />
  ));
};

export default QutterLanguages;
