import React from 'react';
import { useSelector } from 'react-redux';

import { FormTextPreview } from './input_types/text/FormTextPreview';
import { FormFilePreview } from './input_types/file/FormFilePreview';
import { FormSliderPreview } from './input_types/slider/FormSliderPreview';
import { FormEmailPreview } from './input_types/email/FormEmailPreview';
import { FormNumberPreview } from './input_types/number/FormNumberPreview';
import { FormCheckboxPreview } from './input_types/checkbox/FormCheckboxPreview';
import { FormRangePreview } from './input_types/range/FormRangePreview';
import { FormMatrixPreview } from './input_types/matrix/FormMatrixPreview';

export const PreviewForm = ({
  template_id,
  qutter_id,
  form_idx
}) => {
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const qutter = qutterConfig[qutterConfig.language.current];
  const forms = qutter.forms;
  const form = forms[form_idx];
  // console.log(`<PreviewForm />`, form);
  return (
  <>
  {(() => {
    if (!form.type) {
      return (<></>);
    } else 
    if (form.type === 'text') {
      return (<FormTextPreview template_id={template_id} qutter_id={qutter_id} form_idx={form_idx} />);
    } else
    if (form.type === 'file') {
      return (<FormFilePreview template_id={template_id} qutter_id={qutter_id} form_idx={form_idx} />);
    } else
    if (form.type === 'email') {
      return (<FormEmailPreview template_id={template_id} qutter_id={qutter_id} form_idx={form_idx} />);
    } else
    if (form.type === 'number') {
      return (<FormNumberPreview template_id={template_id} qutter_id={qutter_id} form_idx={form_idx} />);
    } else
    if (form.type === 'slider') {
      return (<FormSliderPreview template_id={template_id} qutter_id={qutter_id} form_idx={form_idx} />);
    } else
    if (form.type === 'range') {
      return (<FormRangePreview template_id={template_id} qutter_id={qutter_id} form_idx={form_idx} />);
    } else
    if (form.type === 'checkbox') {
      return (<FormCheckboxPreview template_id={template_id} qutter_id={qutter_id} form_idx={form_idx} />);
    } else  
    if (form.type === 'matrix') {
      return (<FormMatrixPreview template_id={template_id} qutter_id={qutter_id} form_idx={form_idx} />);
    }
    else return (<></>);
  })()}
  </>
  );
};
