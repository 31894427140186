import React  from 'react';

import { Container } from 'react-bootstrap';
import Divider from 'components/common/Divider';

import { SelectOptions } from '..';
import { SelectOptionSettings } from '..';

import { TextComponent } from 'features/TextComponent';
import { QutterSummary } from 'features/QutterSummary';

export const CreateSelect = ({
  id,
  language
}) => {
  // console.log('<CreateSelect />', id);

  return (
    <Container key={'yes-no-create-question'}>

      <TextComponent
        id={id}
        field={'intro'}
      />
      <TextComponent 
        id={id}
        field={'text'}
      />
      <TextComponent 
        id={id}
        field={'outro'}
      />
      <TextComponent 
        id={id} 
        field={'clarify'} 
      />

      <Divider className="mt-4">Options</Divider>
      <SelectOptionSettings
        key={`choice-settings-${id}`}
        id={id}
      />

      <SelectOptions id={id} />

      <Divider className="mt-4">Interview Summary</Divider>
      <QutterSummary id={id} />

    </Container>
  );
}