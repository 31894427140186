import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

import { Card, Form } from 'react-bootstrap';
import { JsonPreview } from 'features/common';

import { PredictedLanguage } from './PredictedLanguage';
import { PredictedIntents } from './PredictedIntents';

export const ParsedText = ({ loading, result }) => {

  const [language, set_language] = useState(null);
  const [sentiment, set_sentiment] = useState(null);
  const [intent, set_intent] = useState(null);
  const [entities, set_entities] = useState(null);
  const [text_en, set_text_en] = useState(null);
  const [text_sv, set_text_sv] = useState(null);

  useEffect(() => {
    if (!result) return;
    if (result.language) set_language(result.language);
    if (result.text_en) set_text_en(result.text_en);
    if (result.text_sv) set_text_sv(result.text_sv);
    if (result.intent) set_intent(result.intent);
    if (result.sentiment) set_sentiment(result.sentiment);
    if (result.entities) set_entities(result.entities);

  }, [result]);

  return (
  <div>
    {loading ? (
      <span>Parsing...</span>
    ):(
      <div>
        <p>Input text: <q>{result.text}</q></p>
        <PredictedLanguage language={language} />
        <PredictedIntents intent={intent} />
        <JsonPreview data={result} show={true} />
      </div>
    )}
  </div>
  );
};
