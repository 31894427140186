import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  user: {},
  showAll: true,
  status: 'idle'
};

const ConfigSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateShowAll (state, action) {
      return {
        ...state,
        showAll: (state.showAll ? false: true)
      };
    },
    updateConfigStatus (state, action) {
      const { status } = action.payload;
      return {
        ...state,
        status: status
      };
    },
    setIsLoggedIn (state, action) {
      const { user } = action.payload;
      // console.log('setIsLoggedIn user', user)
      if (user) {
        return {
          ...state,
          user: user,
          isLoggedIn: true
        };
      } else {
        return {
          ...state,
          isLoggedIn: true
        };
      }
    },
    setIsLoggedOut (state, action) {
      return {
        ...state,
        user: {},
        isLoggedIn: false
      };
    },
  }
});

export const { 
  updateShowAll,
  setIsLoggedIn,
  setIsLoggedOut,
  updateConfigStatus
} = ConfigSlice.actions;
export default ConfigSlice.reducer;
