
import { 
  baseIntent 
} from "../constants/initial_state";

export function IntentPlus (state, action){
  const { id } = action.payload;
  const qutter = state[id];
  const language = qutter.language;
  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].intents.push({ ...baseIntent });
  }
};

export function IntentMinus (state, action){
  const { id, intent_idx } = action.payload;
  const qutter = state[id];
  const language = qutter.language;
  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].intents.splice(intent_idx, 1);
  }
};

export function IntentChange (state, action){
  const { id, intent_idx, intent } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].intents[intent_idx].intent = intent;
    qutter[language.current].intents[intent_idx].value = intent;
    qutter[language.current].intents[intent_idx].id = intent;
  }
};

export function IntentEvaluationChange (state, action){
  const { id, intent_idx, evaluation } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].intents[intent_idx].evaluation = evaluation;
    if (evaluation !== 'bonus')
      delete qutter[language.current].intents[intent_idx].bonus_points;
  }
};

export function IntentBonusChange (state, action){
  const { id, intent_idx, bonus_points } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    if (!bonus_points || [null, undefined].includes(bonus_points))
      qutter[language.current].intents[intent_idx].bonus_points = 0;
    else
      qutter[language.current].intents[intent_idx].bonus_points = parseInt(bonus_points);
  }
};

export function IntentWeightChange (state, action){
  const { id, intent_idx, weight } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    if (!weight || [null, undefined].includes(weight))
      qutter[language.current].intents[intent_idx].weight = 0;
    else
      qutter[language.current].intents[intent_idx].weight = parseInt(weight);
  }
};

export function IntentReplyMinus (state, action) {
  const { id, intent_idx, reply_idx } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].intents[intent_idx].replies.splice(reply_idx, 1);
  }
};

export function IntentReplyPlus (state, action) {
  const { id, intent_idx, reply_idx } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].intents[intent_idx].replies.splice((reply_idx + 1), 0, 'Edit this.');
  }
};

export function IntentReplyChange (state, action) {
  const { id, intent_idx, reply_idx, text } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    qutter[language.current].intents[intent_idx].replies[reply_idx] = text;
  }
};

export function IntentReplyActionChange (state, action) {
  const { id, intent_idx, intent_action } = action.payload;
  const qutter = state[id];
  const language = qutter.language;

  if (qutter && language.current && qutter[language.current]) {
    let len = qutter[language.current].intents[intent_idx].replies.length;
    qutter[language.current].intents[intent_idx].replies[len - 1] = intent_action;
  }
};
