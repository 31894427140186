import {
  createSlice,
  nanoid
} from '@reduxjs/toolkit';

import {
  AddRule,
  DeleteRuleByIndex,
  DeleteAllRules,
  RuleUpdate,
  RuleConditionPlus,
  RuleConditionRemoveByIndex,
  RuleConditionUpdate,
  RuleActionPlus,
  RuleActionRemoveByIndex,
  RuleActionUpdate
 } from 'features/QutterRules';

import {
  // Library
  SetCurrentQutterLanguage,
  SetAllCurrentQutterLanguage,
  AddToLibrary,
  AddLanguage,
  RemoveFromLibrary,

  // Texts
  TextPlus,
  TextMinus,
  UpdateQutterTextField,

  // Presentation
  UpdateQutterSummaryBlock,
  UpdateQutterSummaryLabel,
  UpdateQutterExampleAnswer,

    // General
  UpdateQutterIsGlobal,
  UpdateQutterTags,
  UpdateQutterClientId,
  UpdateQutterRoles,
  UpdateQutterIsSTAR,
  UpdateQutterIsReusable,
  UpdateQutterWeight,
  UpdateQutterScoringCategory,
  UpdateQutterShortDescription
} from './commonReducers';

// Pre Actions
import {
  QutterPreActionsMinus,
  QutterPreActionsPlus,
  UpdateQutterPreAction
} from 'features/QutterPreActions';

// Post Actions
import {
  QutterPostActionsMinus,
  QutterPostActionsPlus,
  UpdateQutterPostAction
} from 'features/QutterPostActions';

import { 
  UpdateQutterCandidateProperty,
  UpdateQutterSkipIfExists
} from 'features/HubertActions';

import { UpdateInputQutterConfig } from 'features/QutterInput';
import { 
  AddForm,
  InitForm,
  RemoveFormByIndex,
  UpdateFormByIndex,
  UpdateFormQutterHelpText,
  UpdateFormQutterSendText,

  // Form Matrix
  // Row
  UpdateFormMatrixRowAtIndex,
  AddFormMatrixRowAtIndex,
  RemoveFormMatrixRowAtIndex,
  // Column
  UpdateFormMatrixColumnAtIndex,
  AddFormMatrixColumnAtIndex,
  RemoveFormMatrixColumnAtIndex,
} from 'features/QutterForm';

import {
  IntentPlus,
  IntentMinus,
  IntentChange, 
  IntentEvaluationChange,
  IntentBonusChange,
  IntentWeightChange,

  // Intent Replies
  IntentReplyPlus, 
  IntentReplyMinus,
  IntentReplyChange, 
  IntentReplyActionChange
} from './intentReducers';

import {
  OptionPlus,
  OptionMinus,
  OptionLabelChange,
  OptionEvaluationChange,
  OptionBonusChange,
  OptionWeightChange,

  // Option Replies
  OptionReplyPlus,
  OptionReplyMinus,
  OptionReplyChange,

  // Default Followups
  OptionDefaultFollowup,
  OptionRemoveDefaultFollowup,

  // Settings
  OptionIsMultipleSelectChange,
  OptionOtherChange,
  OptionNoneAboveChange,
  OptionAllAboveChange,

  // Option Actions
  OptionActionPlus,
  OptionActionMinus,
  OptionActionUpdate,

  // Option Hubiverse Tags
  OptionHubiverseTagsMinus,
  OptionHubiverseTagsPlus
} from './optionReducers';

import {
  SaveNewQutter,
  RemoveQutter,
  FetchQutter,
  FetchMultipleQutters,
  SearchByTextInLibraryThunk,
} from './thunks';

import {
  initialInfo,
  initialCreateYesNo,
  initialCreateOpen,
  initialChoice,
  initialCreateCalendar
} from '../constants/initial_state';

import { initial_input } from 'features/QutterInput';
import { initial_form } from 'features/QutterForm';

const initialState = {
  status:             'idle',
  'create-info':      initialInfo,
  'create-yesno':     initialCreateYesNo,
  'create-open':      initialCreateOpen,
  'create-choice':    initialChoice,
  'create-calendar':  initialCreateCalendar,
  'create-input':     initial_input,
  'create-form':      initial_form
};

const LibrarySlice = createSlice({
  name: 'library',
  initialState: initialState,
  reducers: {
    /* Common */
    // Library
    addToLibrary: AddToLibrary,
    addLanguage: AddLanguage,
    removeFromLibrary: RemoveFromLibrary,
    setCurrentQutterLanguage: SetCurrentQutterLanguage,
    setAllCurrentQutterLanguage: SetAllCurrentQutterLanguage,

    // Actions
    qutterPreActionsPlus: QutterPreActionsPlus,
    qutterPreActionsMinus: QutterPreActionsMinus,
    updateQutterPreAction: UpdateQutterPreAction,
    updateQutterPostAction: UpdateQutterPostAction,
    qutterPostActionsPlus: QutterPostActionsPlus,
    qutterPostActionsMinus: QutterPostActionsMinus,

    // InputQutter
    updateInputQutterConfig: UpdateInputQutterConfig,

    // Forms
    addForm: AddForm,
    initForm: InitForm,
    removeFormByIndex: RemoveFormByIndex,
    updateFormByIndex: UpdateFormByIndex,
    updateFormQutterHelpText: UpdateFormQutterHelpText,
    updateFormQutterSendText: UpdateFormQutterSendText,
    
    // Form matrix
    // Row
    updateFormMatrixRowAtIndex: UpdateFormMatrixRowAtIndex,
    addFormMatrixRowAtIndex: AddFormMatrixRowAtIndex,
    removeFormMatrixRowAtIndex: RemoveFormMatrixRowAtIndex,
    // Column
    updateFormMatrixColumnAtIndex: UpdateFormMatrixColumnAtIndex,
    addFormMatrixColumnAtIndex: AddFormMatrixColumnAtIndex,
    removeFormMatrixColumnAtIndex: RemoveFormMatrixColumnAtIndex,

    // Texts
    updateQutterTextField: UpdateQutterTextField,
    updateQutterSummaryBlock: UpdateQutterSummaryBlock,
    updateQutterSummaryLabel: UpdateQutterSummaryLabel,
    updateQutterExampleAnswer: UpdateQutterExampleAnswer,
    textMinus: TextMinus,
    textPlus: TextPlus,

    // General
    updateQutterIsGlobal: UpdateQutterIsGlobal,
    updateQutterTags: UpdateQutterTags,
    updateQutterClientId: UpdateQutterClientId,
    updateQutterRoles: UpdateQutterRoles,
    updateQutterIsSTAR: UpdateQutterIsSTAR,
    updateQutterIsReusable: UpdateQutterIsReusable,
    updateQutterWeight: UpdateQutterWeight,
    updateQutterScoringCategory: UpdateQutterScoringCategory,
    updateQutterCandidateProperty: UpdateQutterCandidateProperty,
    updateQutterSkipIfExists: UpdateQutterSkipIfExists,
    updateQutterShortDescription: UpdateQutterShortDescription,

    // Intents
    intentPlus: IntentPlus,
    intentMinus: IntentMinus,
    intentChange:  IntentChange,
    intentEvaluationChange: IntentEvaluationChange,
    intentBonusChange: IntentBonusChange,
    intentWeightChange: IntentWeightChange,
    intentReplyPlus: IntentReplyPlus,
    intentReplyMinus: IntentReplyMinus,
    intentReplyChange: IntentReplyChange, 
    intentReplyActionChange: IntentReplyActionChange,

    // Options (a.k.a Choice or Single/multiple selects)
    optionPlus: OptionPlus,
    optionMinus: OptionMinus,
    optionLabelChange: OptionLabelChange,
    optionEvaluationChange: OptionEvaluationChange,
    optionBonusChange: OptionBonusChange,
    optionWeightChange: OptionWeightChange,
    // Option Replies
    optionReplyPlus: OptionReplyPlus,
    optionReplyMinus: OptionReplyMinus,
    optionReplyChange: OptionReplyChange,

    // Default Followups
    optionDefaultFollowup: OptionDefaultFollowup,
    optionRemoveDefaultFollowup: OptionRemoveDefaultFollowup,

    // Option Settings
    optionIsMultipleSelectChange: OptionIsMultipleSelectChange,
    optionOtherChange: OptionOtherChange,
    optionNoneAboveChange: OptionNoneAboveChange,
    optionAllAboveChange: OptionAllAboveChange,
    // Option Actions
    optionActionPlus: OptionActionPlus,
    optionActionMinus: OptionActionMinus,
    optionActionUpdate: OptionActionUpdate,

    // Option Hubiverse Tags
    optionHubiverseTagsMinus: OptionHubiverseTagsMinus,
    optionHubiverseTagsPlus: OptionHubiverseTagsPlus,

    // NEW Rules Functionality
    addRule: AddRule,
    deleteRuleByIndex: DeleteRuleByIndex,
    deleteAllRules: DeleteAllRules,
    ruleUpdate: RuleUpdate,
    ruleConditionPlus: RuleConditionPlus,
    ruleConditionRemoveByIndex: RuleConditionRemoveByIndex,
    ruleConditionUpdate: RuleConditionUpdate,
    ruleActionPlus: RuleActionPlus,
    ruleActionRemoveByIndex: RuleActionRemoveByIndex,
    ruleActionUpdate: RuleActionUpdate,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SaveNewQutter.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(SaveNewQutter.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(SaveNewQutter.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log('fulfilled action', action);
        const id = action.payload.id;
        if (id === 'create-info') state[id] = initialInfo;
        else if (id === 'create-open') state[id] = initialCreateOpen;
        else if (id === 'create-yesno') state[id] = initialCreateYesNo;
        else if (id === 'create-choice') state[id] = initialChoice;
      })

      .addCase(FetchQutter.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(FetchQutter.rejected, (state, action) => {
        state.status = 'error';
        // const result = action.payload.result;
      })
      .addCase(FetchQutter.fulfilled, (state, action, getState, extra) => {
        state.status = 'idle';
        const result = action.payload.result;
      })

      .addCase(FetchMultipleQutters.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(FetchMultipleQutters.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(FetchMultipleQutters.fulfilled, (state, action) => {
        state.status = 'idle';
        // console.log('addCase(FetchMultipleQutters) fulfilled action', action);
        const result = action.payload.result;
      })

      .addCase(SearchByTextInLibraryThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(SearchByTextInLibraryThunk.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(SearchByTextInLibraryThunk.fulfilled, (state, action) => {
        state.status = 'idle';
        const result = action.payload.result;
      })

      .addCase(RemoveQutter.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(RemoveQutter.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(RemoveQutter.fulfilled, (state, action) => {
        state.status = 'idle';
        const qutter_id = action.payload.qutter_id;
        const language = action.payload.language;
        const deletedCount = action.payload.deletedCount;
        if (qutter_id && language) {
          let idx = state[qutter_id].available_languages.indexOf(language);
          if (idx > -1) {
            delete state[qutter_id][language];
            state[qutter_id].available_languages.splice(idx, 1);
            state[qutter_id].language.current = state[qutter_id].language.list[0];
          }
        } else {
          delete state[qutter_id];
        }
      });
  },
});

export const {
  /***** Common *****/
  // Library
  setCurrentQutterLanguage,
  setAllCurrentQutterLanguage,
  addToLibrary,
  addLanguage,
  removeFromLibrary,

  // Actions
  qutterPreActionsPlus,
  qutterPreActionsMinus,
  updateQutterPreAction,
  updateQutterPostAction,
  qutterPostActionsPlus,
  qutterPostActionsMinus,

  // InputQutter
  updateInputQutterConfig,

  // Forms
  addForm,
  initForm,
  removeFormByIndex,
  updateFormByIndex,
  updateFormQutterHelpText,
  updateFormQutterSendText,

  // Form Matrix
  // Row
  updateFormMatrixRowAtIndex,
  addFormMatrixRowAtIndex,
  removeFormMatrixRowAtIndex,
  // Column
  updateFormMatrixColumnAtIndex,
  addFormMatrixColumnAtIndex,
  removeFormMatrixColumnAtIndex,

  // General
  updateQutterIsGlobal,
  updateQutterTags,
  updateQutterClientId,
  updateQutterRoles,
  updateQutterIsSTAR,
  updateQutterIsReusable,
  updateQutterWeight,
  updateQutterScoringCategory,
  updateQutterCandidateProperty,
  updateQutterSkipIfExists,
  updateQutterShortDescription,

  // Texts
  textPlus,
  textMinus,
  updateQutterTextField,
  updateQutterSummaryBlock,
  updateQutterSummaryLabel,
  updateQutterExampleAnswer,

  /*** Open Qutter & Intents ****/
  intentPlus,
  intentMinus,
  intentChange,
  intentEvaluationChange,
  intentBonusChange,
  intentWeightChange,
  intentReplyPlus,
  intentReplyMinus,
  intentReplyChange, 
  intentReplyActionChange,

  /*** Choice Qutter & Options ****/
  optionPlus,
  optionMinus,
  optionLabelChange,
  optionEvaluationChange,
  optionBonusChange,
  optionWeightChange,
  optionReplyPlus,
  optionReplyMinus,
  optionReplyChange,
  /*** Choice Settings ****/
  optionIsMultipleSelectChange,
  optionOtherChange,
  optionNoneAboveChange,
  optionAllAboveChange,

  /*** Option actions ****/
  optionActionPlus,
  optionActionMinus,
  optionActionUpdate,

  /*** Option Hubiverse Tags ****/
  optionHubiverseTagsMinus,
  optionHubiverseTagsPlus,

  // Default Followups
  optionDefaultFollowup,
  optionRemoveDefaultFollowup,

  // NEW Rules Functionality
  addRule,
  deleteRuleByIndex,
  deleteAllRules,
  ruleUpdate,
  ruleConditionPlus,
  ruleConditionRemoveByIndex,
  ruleConditionUpdate,
  ruleActionPlus,
  ruleActionRemoveByIndex,
  ruleActionUpdate,

} = LibrarySlice.actions;
export default LibrarySlice.reducer;
