import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Card } from 'react-bootstrap';

import { HubertMessages } from './HubertMessages';
import { CandidateMessages } from './CandidateMessages';

export const ChatCollection = ({
  collection
}) => {
  // console.log(`<ChatCollection />`);
  const user = useSelector(state => state.config.user);
  const [answer_language, set_answer_language] = useState(null);
  const [hubert_missing, set_hubert_missing] = useState(null);

  useEffect(() => {
    async function find_language () {
      let answer = await collection.messages.find(m => m.sender === 'candidate');
      if (answer) set_answer_language(answer.expected_language ? answer.expected_language: answer.language);
    }
    find_language();
  }, []);

  useEffect(() => {
    // Generally - During Reusage is Hubert's questions missing as saved log
    async function check_hubert () {
      let answer = await collection.messages.find(m => m.sender === 'hubert');
      if (answer) set_hubert_missing(false);
      else set_hubert_missing(true);
    }
    check_hubert();
  }, []);

  // Special type: reusable
  if (collection.question.type === 'reusable') {
    const text = 'Hubert lists all reusable questions which candidate answer...';
    return (
    <>
      {collection.messages.map((message, idx) => {
        if (message.sender === 'hubert') {
          return (
            <HubertMessages
              key={`chat-collection-hubert-messages-${idx}`}
              language={answer_language}
              all_messages={collection.messages} 
              message={message} 
              question={collection.question} 
            />
          )
        }
      })}
      <Card className='hubert-message rounded-2'>
        <Card.Body>
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Card.Body>
      </Card>
    </>
    )
  };

  // if (!answer_language) return (<span>Loading answer language...</span>);
  if (hubert_missing === null) return (<span>Loading hubert message...</span>);

  return (
  <div
    id={`${collection._id}`}
  >
    {hubert_missing ? (
      <HubertMessages
        language={answer_language}
        all_messages={collection.messages}
        question={collection.question} 
      />
    ):(null)}

    {collection.messages.map((message, idx) => {
      if (message.sender === 'hubert') {
        return (
          <HubertMessages
            key={`chat-collection-hubert-messages-${idx}`}
            language={answer_language}
            all_messages={collection.messages} 
            message={message} 
            question={collection.question} 
          />
        )
      } else {
        return (
          <CandidateMessages
            key={`chat-collection-candidate-messages-${idx}`}
            message={message}
            scoringDetail={collection.scoring_detail}
          />
        )
      }
    })}
  </div>
  );
};
