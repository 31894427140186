import { isEmpty } from 'lodash';

/** Copies original text array to parameters.<qutter_id> */
export function TemplateTextCopy (state, action) {
  const {
    qutter_id,
    field,
    language,
    original
  } = action.payload;
  const params = state[qutter_id];
  const key = `${field}.${language}`;

  let temp = [];
  if (!original || original.length === 0) temp = ['']
  else temp = original;

  if (params) {
    params[key] = temp;
  } else {
    state[qutter_id] = { [key]: temp };
  }
}

/** Removes any custom intro, text, or outro */
export function TextFieldResetToOriginal (state, action) {
  const {
    qutter_id,
    field,
    language,
  } = action.payload;
  const params = state[qutter_id];
  const key = `${field}.${language}`;
  delete params[key];
  if (isEmpty(params)) delete state[qutter_id];
}

/** Adds to custom intro, text, or outro at index */
export function TextFieldPlus (state, action) {
  const {
    qutter_id,
    field,
    language,
    idx
  } = action.payload;
  const params = state[qutter_id];
  const key = `${field}.${language}`;
  params[key].splice((idx + 1), 0, '');
}

/** Removes from custom intro, text, or outro at index */
export function TextFieldMinus (state, action) {
  const {
    qutter_id,
    field,
    language,
    idx
  } = action.payload;
  const params = state[qutter_id];
  const key = `${field}.${language}`;
  params[key].splice(idx, 1);
}

/** Updates custom intro, text, or outro at index */
export function TextFieldUpdate (state, action) {
  const {
    qutter_id,
    field,
    language,
    idx,
    value
  } = action.payload;
  const params = state[qutter_id];
  const key = `${field}.${language}`;
  params[key][idx] = value;
}
