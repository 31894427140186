import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Col, Form } from 'react-bootstrap';
import { QutterEvaluationSelect } from 'features/QutterEvaluationSelect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';

import { setCustomEvaluation } from 'features/QutterEvaluation';

export const EvaluationPopoverContent = ({
  template_id,
  qutter_id,
  evaluation,
  type,
  id,
  onSave
}) => {
  const dispatch = useDispatch();
  const Template = useSelector(state => state.Template);
  const template = useSelector(state => state.Template[template_id]);
  const languages = template.language_settings.list;

  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  // const qutter = qutter_config[language];

  const [eval_name, set_eval_name] = useState(null);
  const [apply_all, set_apply_all] = useState(true);
  const [points, set_points] = useState(0);
  const [bonuspoints, set_bonuspoints] = useState(0);
  const [weight, set_weight] = useState(0);
  const [label, set_label] = useState('');

  useEffect(() => {
    set_eval_name(evaluation.name);
    set_label(
      apply_all ?
      `Apply for all languages (${languages.toString()})`:
      `Apply for only ${language.toString()}`
    );
    if (evaluation.name === 'bonus') {
      set_bonuspoints(evaluation.bonus_points);
    } else 
    if (evaluation.name === 'points') {
      set_points(evaluation.points);
    }
  }, []);

  useEffect(() => {
    set_label(
      apply_all ?
      `Apply for all languages (${languages.toString()})`:
      `Apply for only ${language.toString()}`
    );
  }, [apply_all]);

  const onSaveClick = () => {
    if (onSave) {
      let value = { name: eval_name };
      if (eval_name === 'bonus') value.bonus_points = bonuspoints;
      if (eval_name === 'point') value.points = points;
      onSave(value);

    } else {
      let value = { name: eval_name }
      if (eval_name === 'bonus') value.bonus_points = bonuspoints;
      if (eval_name === 'point') value.points = points;
  
      // console.log('Update evaluation:', value);
  
      if (apply_all) {
        languages.map((lang) => {
          dispatch(setCustomEvaluation({
            language: lang,
            qutter_id,
            id,
            evaluation: value
          }));
        });
      } else
      if (!apply_all) {
        dispatch(setCustomEvaluation({
          language,
          qutter_id,
          id,
          evaluation: value
        }));
      }
    }
  }

  if (!evaluation || !evaluation.name) return (<span>Something is wrong.</span>);
  if (!eval_name) return (<span>Loading...</span>);

  return (
  <div>
    <QutterEvaluationSelect
      key={Math.random}
      selected={eval_name}
      on_select={(e) => {
        set_eval_name(e.value);
      }}
    />

    {eval_name === 'bonus' && (
    <div>
      <span>Bonus Points</span>
      <Form.Control
        defaultValue={bonuspoints}
        key={Math.random(1)*100}
        type="number"
        min={0}
        max={100}
        placeholder='Bonus points'
        onChange={(e) => { 
          set_bonuspoints(parseInt(e.target.value)); 
        }}
      />
    </div>
    )}

    {eval_name === 'point' && (
    <div>
      <span>Points</span>
      <Form.Control
        defaultValue={points}
        type="number"
        min={0}
        max={100}
        placeholder="Default Weight"
        onChange={(e) => {
          set_points(parseInt(e.target.value));
        }}
      />
    </div>
    )}

    <div>
      <Form.Check
        type="switch"
        id="custom-switch"
        label={label}
        defaultChecked={apply_all}
        onChange={() => { set_apply_all(!apply_all); }}
      />
    </div>

    <div>
      <div className='float-end'>
        <span
          className='hover-blue font-size-10'
          onClick={async () => {
            await onSaveClick();
          }}
        >
          <FontAwesomeIcon className="me-1" icon={faFloppyDisk} />
          Save
        </span>
      </div>
    </div>
  </div>
  )
};
