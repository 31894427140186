import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons';

import { LANGUAGE_LABELS } from 'features/LanguageSelect/assets/constants';

import { addToLibrary } from 'features/Qutters/slices/LibrarySlice';
import { FetchQutter } from 'features/Qutters/slices/thunks';

import { customRuleActionUpdate } from 'features/Parameters';
import { ruleActionUpdate } from 'features/Qutters';

export const RuleActionJumpToValueDeleteAtIndex = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  action_idx,
  action,
  item_idx,
}) => {
  const dispatch = useDispatch();
  const [loading, set_loading] = useState(true);

  if (template_id) {
    if (is_default)
      return null;
    else {
      return (
        <div className='w-5 m-auto text-start'>
          <FontAwesomeIcon
            className='hover-blue'
            style={{ color: 'red' }}
            // size={'sm'}
            icon={faCircleXmark}
            onClick={() => {
              let updated_action = Object.assign({}, action);
              updated_action = JSON.parse(JSON.stringify(action));
              updated_action.value.splice(item_idx, 1);
              dispatch(customRuleActionUpdate({
                template_id,
                qutter_id,
                rule_idx,
                action_idx,
                updated_action
              }));
            }}
          />
        </div>
      )
    }
  } else {
    return (
    <div className='w-50 text-start m-auto'>
      <FontAwesomeIcon
        className='hover-blue'
        style={{ color: 'red' }}
        // size={'sm'}
        icon={faCircleXmark}
        onClick={() => {
          let updated_action = Object.assign({}, action);
          updated_action = JSON.parse(JSON.stringify(action));
          updated_action.value.splice(item_idx, 1);
          dispatch(ruleActionUpdate({
            qutter_id,
            rule_idx, 
            action_idx,
            updated_action
          }));
        }}
      />
    </div>
    )
  }
};