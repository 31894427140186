import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { ClickToCopy } from 'features/ClickToCopy';

import { capitalizeFirstLetter } from 'features/common';
// import { LANGUAGE_LABELS } from '_components/Selects/LanguageSelect';
import { LANGUAGE_FLAGS } from 'features/LanguageSelect/assets/constants';

import { setCurrentTemplateLanguage } from 'features/Template/slices/TemplateSlice';
import { setAllCurrentQutterLanguage } from 'features/Qutters/slices/LibrarySlice';

import { TemplateStartingLanguageInfo } from './TemplateStartingLanguageInfo';
import { TemplateLanguageInfo } from './TemplateLanguageInfo';
import { TemplateAllowedLanguageInfo } from './TemplateAllowedLanguageInfo';

const formOfEmploymentTypes = {
  '': '-',
  ft: 'Full time',
  pt: 'Part time',
  ew: 'Extra work'
};

const RedirectPage = ({ url, name }) => {
  return (
    <a
      className="link-primary hover-blue"
      style={{ fontSize: '9px', fontWeight: 'bold' }}
      target="_blank"
      href={url}
      rel="noreferrer"
    >
      {name}
    </a>
  );
};

const setClientName = template => {
  if (template.client_details) {
    return template.client_details.org_name;
  }
  return template.client_id;
};

export const TemplateSettings = ({ id }) => {
  const dispatch = useDispatch();
  const templateConfig = useSelector(state => state.Template);
  const template = templateConfig[id];
  const readOnly = templateConfig.readOnlyMode;
  const templateLanguage = useSelector(state => state.Template.language);

  const onLanguageClick = async language => {
    await dispatch(setAllCurrentQutterLanguage({ language }));
    await dispatch(setCurrentTemplateLanguage({ language }));
  };

  return (
    <div>
      <div>
        <span style={{ fontSize: '9px' }}>Template Name: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {template.name}
        </span>
      </div>

      <div>
        <span style={{ fontSize: '9px' }}>Template ID: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {template._id}
        </span>
      </div>

      <div>
        <span style={{ fontSize: '9px' }}>Template Description: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {template.description}
        </span>
      </div>

      {readOnly ? (null):(
      <div>
        <span style={{ fontSize: '9px' }}>Client: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {setClientName(template)}{' '}
        </span>
      </div>
      )}

      <div>
        <span style={{ fontSize: '9px' }}>
          Template Language:
          <TemplateLanguageInfo />
        </span>
        {template.language_settings.list.map((language, idx) => {
          return (
            <span
              key={`overview-template-languages-${language}-${idx}`}
              onClick={() => onLanguageClick(language)}
              className={`ps-2 ${
                templateLanguage.current === language
                  ? 'color-blue'
                  : ' cursor-pointer'
              }`}
            >
              {LANGUAGE_FLAGS[language]}
            </span>
          );
        })}
      </div>
      <div>
        <span style={{ fontSize: '9px' }}>
          Starting Language: <TemplateStartingLanguageInfo />
        </span>
        <span
          // onClick={() => onLanguageClick(language)}
          className={`ps-2`}
        >
          {LANGUAGE_FLAGS[template.language_settings.starting_language]}
        </span>
      </div>
      <div>
        <span style={{ fontSize: '9px' }}>
          Allowed Languages:
          <TemplateAllowedLanguageInfo />
        </span>
        {template.language_settings.allowed_languages.map((language, idx) => {
          return (
            <span
              key={`overview-template-languages-${language}-${idx}`}
              onClick={() => onLanguageClick(language)}
              className={`ps-2`}
            >
              {LANGUAGE_FLAGS[language.code]}
            </span>
          );
        })}
      </div>
      <div>
        <span style={{ fontSize: '9px' }}>Company Name: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {template.organization
            ? template.organization.name
            : template.company}
        </span>
      </div>

      {readOnly ? (null):(
      <div>
        <span style={{ fontSize: '9px' }}>Company Department: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {template.organization ? template.organization.department : ''}
        </span>
      </div>
      )}

      {readOnly ? (null):(
      <div>
        <span style={{ fontSize: '9px' }}>Title: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {template.title}
        </span>
      </div>
      )}

      <div>
        <span style={{ fontSize: '9px' }}>Role: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {capitalizeFirstLetter(template.role.id.split('_').join(' '))}
        </span>
      </div>
      <div>
        <span style={{ fontSize: '9px' }}>Your Role Name: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {template.role.name}
        </span>
      </div>

      {readOnly ? (null):(
      <div>
        <span style={{ fontSize: '9px' }}>Form of employment: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {formOfEmploymentTypes[template.position.form_of_employment]}
        </span>
      </div>
      )}

      <div>
        <span style={{ fontSize: '9px' }}>Threshold: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {template.threshold}
        </span>
      </div>

      <div>
        <span style={{ fontSize: '9px' }}>Estimated Length (minutes): </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          {template.estimated_length}
        </span>
      </div>

      {readOnly ? (null):(
      <div>
        <span style={{ fontSize: '9px' }}>Published: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          <span>
            <FontAwesomeIcon
              style={{ color: (template.published ? 'green': 'red') }}
              size={'sm'}
              icon={(template.published ? faCircleCheck: faCircleXmark)}
            />
          </span>
        </span>
      </div>
      )}

      {readOnly ? (null):(
      <div>
        <span style={{ fontSize: '9px' }}>Immediately notify unscored: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          <span>
            <FontAwesomeIcon
              style={{ color: (template.immediately_notify_unscored ? 'green': 'red') }}
              size={'sm'}
              icon={(template.immediately_notify_unscored ? faCircleCheck: faCircleXmark)}
            />
          </span>
        </span>
      </div>
      )}

      {readOnly ? (null):
      (
      <div>
        <span style={{ fontSize: '9px' }}>Script: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>

            <span>
              <FontAwesomeIcon
                style={{ 
                  color: (template.script ? 'green': 'red') 
                }}
                size={'sm'}
                icon={(template.script ? faCircleCheck: faCircleXmark)}
              />
            </span>

        </span>
      </div>
      )}

      {readOnly ? (null):(
      <div>
        <span style={{ fontSize: '9px' }}>MyHubert: </span>
        <span style={{ fontSize: '9px', fontWeight: 'bold' }}>
          <span>
            <FontAwesomeIcon
              style={{ color: (template.candidate_app ? 'green': 'red') }}
              size={'sm'}
              icon={(template.candidate_app ? faCircleCheck: faCircleXmark)}
            />
          </span>
        </span>
      </div>
      )}

    </div>
  );
};
