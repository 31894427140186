import React from 'react';
import Select from 'react-select';

import { evaluation_list } from 'features/QutterEvaluation';

export const QutterEvaluationSelectSingle = ({
  disabled,
  on_select,
  selected,
  placeholder,
  className
}) => {
  let idx = null;
  if (selected) {
    function find (obj) { return obj.value === selected; }
    idx = evaluation_list.findIndex(find);
  }

  return (
    <Select
      isMulti={false}
      className={className}
      isDisabled={disabled}
      value={(selected ? evaluation_list[idx]: null)}
      placeholder={placeholder ? placeholder: 'Choose evaluation type'}
      options={evaluation_list}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};

