import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons';

import { RuleActionJumpToValueDeleteAtIndex } from 'features/QutterRules';
import { RuleActionJumpToValueUp } from './RuleActionJumpToValueUp';
import { RuleActionJumpToValueDown } from './RuleActionJumpToValueDown';

import { LANGUAGE_LABELS } from 'features/LanguageSelect/assets/constants';

import { addToLibrary } from 'features/Qutters/slices/LibrarySlice';
import { FetchQutter } from 'features/Qutters/slices/thunks';

import { customRuleActionUpdate } from 'features/Parameters';
import { ruleActionUpdate } from 'features/Qutters';

export const RuleActionJumpToListItem = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  action_idx,
  action,
  item_id,
  item_idx,
  num_items,
}) => {
  const dispatch = useDispatch();
  const [loading, set_loading] = useState(true);

  const library = useSelector(state => state.Library);
  const config = library[item_id];

  useEffect(() => {
    async function fetch () {
      try {
        if (!config) {
          await dispatch(FetchQutter(item_id)).then(async (res) => {
            const qutter = res.payload.result;
            await dispatch(addToLibrary({ qutter }));
            set_loading(false);
          });
        }
        else set_loading(false);

      } catch (e) {
        console.log('ERROR RuleActionJumpToListItem: ', item_id);
        console.log('ERROR RuleActionJumpToListItem: ', e);
        return null;
      }
    }
    fetch();
  }, [config]);

  if (loading) return (<div>Fetching question...</div>);
  if (!config) return (<div className='red'>Could not find the question... (id: {item_id})</div>);

  const available_languages = config.available_languages;
  let content = '';
  available_languages.map((language, idx) => {
    content += config[language].text.join(' ');
  });

  return (
    <li>
      <div className='d-flex font-size-10 m-1'>
        <div className='w-75 text-start m-auto'>
          <div dangerouslySetInnerHTML={{ __html: content }} ></div>
        </div>
        <div className='w-20 m-auto text-start'>
          {available_languages.map((language, idx) => {
            return (<span key={`l-${language}-${idx}`} className='d-block'>{LANGUAGE_LABELS[language]}</span>)
          })}
        </div>

        <div className='d-flex w-5 m-auto text-start'>
          <RuleActionJumpToValueUp
            template_id={template_id}
            qutter_id={qutter_id}
            is_default={is_default}
            rule_idx={rule_idx}
            action_idx={action_idx}
            action={action}
            item_idx={item_idx}
            num_items={num_items}
          />

          <RuleActionJumpToValueDown
            template_id={template_id}
            qutter_id={qutter_id}
            is_default={is_default}
            rule_idx={rule_idx}
            action_idx={action_idx}
            action={action}
            item_idx={item_idx}
            num_items={num_items}
          />

          <RuleActionJumpToValueDeleteAtIndex
            template_id={template_id}
            qutter_id={qutter_id}
            is_default={is_default}
            rule_idx={rule_idx}
            action_idx={action_idx}
            action={action}
            item_idx={item_idx}
          />
        </div>

      </div>
    </li>
  );
};