import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import QutterAnswerSelectSingle from './QutterAnswerSelectSingle';
import QutterAnswerSelectMultiple from './QutterAnswerSelectMultiple';

import { prepare_qutter_answers } from '../utils/utils';

export const QutterAnswerSelect = ({
  multi,
  disabled,
  placeholder,
  className,

  on_select,
  qutter_id,
  selected,
}) => {
  const [answer_list, set_answer_list] = useState([]);
  const [loading, set_loading] = useState(false);
  const library = useSelector(state => state.Library);

  useEffect(() => {
    async function load_answers () {
      const answers = await prepare_qutter_answers(library, qutter_id);
      set_answer_list(answers);
      set_loading(false);
    }
    load_answers();
  }, [qutter_id]);

  if (loading) {
    return (<span>Loading answers...</span>)
  }

  console.log('multi', multi);
  console.log('selected', selected);

  if (multi) {
    return (
      <QutterAnswerSelectMultiple
        className={className}
        disabled={disabled}
        placeholder={placeholder}
        
        qutter_id={qutter_id}
        on_select={on_select}
        selected={selected}
        answer_list={answer_list}
      />
    );
  }

  return (
    <QutterAnswerSelectSingle
      className={className}
      disabled={disabled}
      placeholder={placeholder}

      on_select={on_select}
      selected={selected}
      answer_list={answer_list}
    />
  );
};
