import React from 'react';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import AppHubertLoginForm from './AppHubertLoginForm';
// import bgImg from 'assets/img/generic/14.jpg';
import logo from 'assets/img/hubert/logo/Favicon/logo.svg';
import Flex from 'components/common/Flex';

const AppHubertLogin = () => {
  return (
    <AuthSplitLayout bgProps={{ image: logo }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Login</h3>
      </Flex>
      <AppHubertLoginForm layout="split" hasLabel />
    </AuthSplitLayout>
  );
};

export default AppHubertLogin;
