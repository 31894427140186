import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import ScoreSummaryHeader from './ScoreSummaryHeader';
import { ApplicationQualified } from 'features/Application';

const UPDATE_URL = `${process.env.REACT_APP_URL}/scoring/v1/manual_post_score`;

const ScoreSummary = ({
  job,
  application,
  candidate_id,
  interview
}) => {
  const scorer = useSelector(state => state.scorer);
  const user = useSelector(state => state.config.user);
  const [startTime, setStartTime] = [new Date()];

  // Summary
  
  const [summary_id, setSummaryId] = useState((interview.summary ? interview.summary.id: null));
  const [showSummary, setShowSummary] = useState(false);

  const fullname = user.first_name + ' ' + user.last_name;

  console.log('<ScoreSummary />', scorer);
  console.log('<ScoreSummary />', user);

  const onUpdateClick = () => {
    console.log('Save and update score.');
    console.log(scorer);
    console.log(scorer.per_qutter);

    let manual_score = {
      job_id: job.id,
      application_id: application.id,
      candidate_id: candidate_id,
      interview_id: interview.id,

      role_id: job.role_id,
      threshold: job.threshold,
      bonus_booster: 0,
      score_time: (new Date() - startTime)/1000,
      total_score: scorer.total,
      weighted_total: scorer.weighted_total,
      initial_weight: scorer.initial_weight,
      max_weighted: scorer.max_weighted,

      tagged_by: fullname,
      tagged_by_admin_id: user._id,
    };

    for (let key in scorer.per_qutter) {
      let score_id = scorer.per_qutter[key].score_id;
      manual_score[score_id] = {
        score: scorer.per_qutter[key].score,
        predicted: scorer.per_qutter[key].predicted,
        edited: scorer.per_qutter[key].edited,
      };
      if (scorer.per_qutter[key].is_weighted) {
        manual_score[score_id].weight = scorer.per_qutter[key].weight;
        manual_score[score_id].weighted_score = scorer.per_qutter[key].weighted_score;
      }
    }

    console.log(manual_score);

    axios.post(UPDATE_URL,
      { manual_score: manual_score }
    ).then(res => {
      // 2XX statuses.
      let data = res.data.data;
      toast.success(<span>Score and summary updated. <strong>Message:</strong> {data.message}</span>, { autoClose: false });

      // let response_message = data.response.message;
      // if (typeof(response_message) !== 'string') {
      //   toast.success(`Message: ${response_message.message}`);
      // } else toast.success(`Message: ${data.response.message}`);

      if (data.summaryId) {
        setSummaryId(data.summaryId);
        setShowSummary(true);
      }

    }).catch((error) => {
      // Error
      console.log('manual_post_score error', error);
      if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          const severity = error.response.status === 422 ? 'warning' : 'error';
          const { message, error: { message: errorMessage } } = error.response.data;
          toast[severity](`Error ${error.response.status}: ${message ?? errorMessage }`, { autoClose: false });
      } else if (error.request) {
          // The request was made but no response was received.
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
          toast.warning(`No response: ${error.request}`);
      } else {
          // Something happened in setting up the request that triggered an Error
          toast.error(`Error: ${error.message}`);
      }
      console.log(error.config);
    });
  };

  // toast.success(`Logged in as ${'HEJJA'}`);
  // toast(`Logged in as ${'HEJJA'}`);
  // toast.info(`Logged in as ${'HEJJA'}`);

  console.log('SCORE SUMMARY: ', job);

  return (
  <div
    id='score-summary-container'
    className='text-center p-1 row-fluid'
    style={{}}
  >

    <ScoreSummaryHeader
      application_id={application.id}
      summary_id={summary_id}
      interview_id={interview.id}
      showSummary={showSummary}
      setShowSummary={setShowSummary}
    />

    <div className='' style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Available BPs</span>
      <span className='p-0 text-center'>{scorer.max_bonus_points}</span>
    </div>

    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Weighted Max</span>
      <span className='p-0 text-center'>{scorer.max_weighted}</span>
    </div>

    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Initial weight</span>
      <span className='p-0 text-center'>{scorer.initial_weight.toFixed(1)}</span>
    </div>

    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Weighted Total</span>
      <span className='p-0 text-center'>{scorer.weighted_total.toFixed(1)}</span>
    </div>

    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block font-size-10'>Qualified</span>
      <span className='p-0 text-center'>
        <ApplicationQualified qualified={scorer.qualified} />
      </span>
    </div>

    <div style={{ WebkitFlex: 'none', height: '75px' }}>
      <span className='p-0 text-center d-block'>Total</span>
      <span className={`p-0 text-center ${(scorer.total > job.threshold ? 'bg-success': 'bg-warning')}`}>
        {scorer.total.toFixed(1)}
      </span>
    </div>

    <div
      className='text-center App-header'
      style={{ WebkitFlex: 'none', height: '75px' }}
    >
      <Button
        className='btn-sm m-auto text-center'
        onClick={onUpdateClick}
        style={{ position: 'fixed', bottom: '5px' }}
      >
        Update
      </Button>
    </div>

  </div>
  );
};

export default ScoreSummary;
