import React from 'react';
import { Modal } from 'react-bootstrap';

import { CreateQutterBlock } from 'features/QutterBlock';

export const CreateQutterBlockModal = ({
  showModal,
  setShowModal
}) => {

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      dialogClassName="create-question-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Qutter Block
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <CreateQutterBlock />
      </Modal.Body>

    </Modal>
  );
};