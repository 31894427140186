import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const options1 = [
  { label: 'includes', value: 'INCLUDES' }
];
const options2 = [
  { label: 'mentions', value: 'MENTIONS' }
];
const options3 = [
  { label: 'less than', value: 'LESS_THAN' },
  { label: 'equals', value: 'EQUALS' },
  { label: 'more than', value: 'MORE_THAN' },
];
const options4 = [
  { label: 'equals', value: 'EQUALS' }
];

export const Operator = ({
  placeholder,
  className,

  on_select,
  filter,
  set_operator
}) => {
  const [options, set_options] = useState([]);
  const [selected, set_selected] = useState(null);
  const [disabled, set_disabled] = useState(false);

  console.log('Operator filter', filter)

  useEffect(() => {

    if (!filter.question_selection) return;

    if (['single-select', 'multiple-select'].includes(filter.question_selection.type)) {
      set_options(options1);
      set_selected(options1[0]);
      set_disabled(true);
    } else 

    if (['open'].includes(filter.question_selection.type)) {
      set_options(options2);
      set_selected(options2[0]);
      set_disabled(true);
    } else

    if (filter.question_selection.type === 'form') {
      let form_type = filter.question_selection.form_type;

      if (form_type === 'matrix') {
        set_options(options1);
        set_selected(options1[0]);
        set_disabled(true);
      } else 

      if (['slider', 'range'].includes(form_type)) {
        set_options(options3);
        set_selected(options3[0]);
        set_disabled(false);
      } else 

      if (['text', 'email'].includes(form_type)) {
        set_options(options2);
        set_selected(options2[0]);
        set_disabled(true);
      } else 

      if (form_type === 'checkbox') {
        set_options(options4);
        set_selected(options4[0]);
        set_disabled(true);
      } else 

      if (form_type === 'number') {
        set_options(options3);
        set_selected(options3[0]);
        set_disabled(false);
      }
    }

    else {
      set_options(options1);
      set_selected(options1[0]);
    }

  }, [filter.question_selection]);

  console.log('Operator filter', filter)
  
  useEffect(() => {
    console.log('selected Operator', selected)
    if (selected) set_operator(selected)
  }, [selected]);

  if (!filter.question_selection || !options.length) {
    return (null);
  }

  return (
    <Select
      isMulti={false}
      className={className}
      isDisabled={disabled}

      // placeholder={'Select operator'}
      options={options}
      // defaultValue={filter.operator}
      value={filter.operator}
      onChange={(e)=> {
        if (on_select)
          on_select(e);
      }}
    />
  );
};