import React, {useState} from 'react';
import ApplyPageModal from './ApplyPageModal';

export const ApplyPageModalShowButton = ({
  job_id,
  apply_settings
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <ApplyPageModal
          job_id={job_id}
          showModal={showModal}
          setShowModal={setShowModal}
          apply_settings={apply_settings}
        />
      )}

      <span
        className='link-primary hover-blue m-1'
        style={{ fontSize: '9px', fontWeight: 'bold' }}
        onClick={(e) => { setShowModal(!showModal); }}
      >
        Apply Pages
      </span>
    </>
  );
};
