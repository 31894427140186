import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Draggable } from 'react-beautiful-dnd';
import { Table } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faGripVertical } from '@fortawesome/pro-solid-svg-icons';

import { removeNodeActionAtIndex } from 'features/Template/slices/TemplateSlice';

export const PreviewNodeAction = ({
  template_id,
  block_id,
  node_idx,
  block_idx,
  node,
  order,
  provided,
  is_followup,
  rules=[]
}) => {
  const dispatch = useDispatch();

  useEffect(() => {

  }, []);

  return (
  <Draggable
    key={`draggable-key-${node.id}-${block_id}`}
    draggableId={`${node.id}`}
    index={parseInt(node_idx)}
  >
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        className='text-center m-auto mt-2 mb-2'
      >
        <Table>
          <tbody>
          {node.actions && node.actions.length ? (
            node.actions.map((action, action_idx) => {
              return (
              <tr
                key={`node-action-${action.id}-${action_idx}`}
              >
                <td>
                  {!is_followup && (
                    <span>
                      {/* {children} */}
                      {provided && (
                      <span
                        {...provided.dragHandleProps}
                        className="mr-card font-size-10"
                      >
                        <FontAwesomeIcon icon={faGripVertical} />
                      </span>
                      )}
                    </span>
                  )}
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <span
                    onClick={(e) => {}}
                  >
                    Action
                  </span>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <span
                    onClick={(e) => {}}
                  >
                    {action.value}
                  </span>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <span

                    className="font-size-10 hover-blue me-2"
                    onClick={() => {
                      console.log('remove action')
                      dispatch(removeNodeActionAtIndex({
                        template_id,
                        block_id,
                        node_idx,
                        action_idx
                      }))
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} className="me-1"/>
                  </span>
                </td>
              </tr>
              )
            })
          ):(null)}
          </tbody>
        </Table>
      </div>
    )}
  </Draggable>
  );
};