import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';

import IntegrationSmall from '_components/integrations/IntegrationSmall';
import { JobTemplates } from 'features/Job/components/JobTemplates';

import { RoleSelect } from 'features/RoleSelect';

const JobCardSmall = ({
  job,
  template_id,
  scoring_template=false,
}) => {
  console.log('<JobCardSmall />', job, template_id, scoring_template);

  return (
  <>
    <Card className='mb-2'>

      <Card.Header>
        <Link variant="" target='_blank' to={`/jobs/${job.id ? job.id: job._id}`}>
          {job.title}
        </Link>
      </Card.Header>

      <Card.Body className='pt-0'>
        <Row>
          <Col>

            <div className='my-2'>Threshold: {job.threshold}</div>

            <IntegrationSmall integration={job.integration} />

            <RoleSelect 
              role_id={job.role_id}
              scoring_template={scoring_template}
              disabled={true}
            />

            {/* <p className="text-500">Region/City, Country</p> */}

          </Col>
        </Row>
      </Card.Body>
    </Card>

    <JobTemplates jobId={job.id ? job.id: job._id} linkOnly={true} />
  </>
  );
};

export default JobCardSmall;
