import React, { useState } from 'react';

import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from '_components/common/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const columns = [
  {
    accessor: 'id',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, firstName, lastName } = rowData.row.original;
      return (
        <Link to={`/candidates/${id}`}>
          <h5 className="mb-0 fs--1">{firstName} {lastName}</h5>
        </Link>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: rowData => {
      const { email } = rowData.row.original;
      return <span>{email}</span>;
    }
  },
  {
    accessor: 'created_at',
    Header: 'Created',
    Cell: rowData => {
      const { created_at } = rowData.row.original;
      if (created_at)
        return <span>{new Date(created_at).toLocaleDateString("en-UK", options)}</span>;
      else
        return <span>{'-'}</span>;
    }
  }
];

export const CandidatesList = ({
  list
}) => {
  console.log('candidatesList', list);

  return (
  <AdvanceTableWrapper
    columns={columns}
    data={list && list.length ? list: []} 
    selection
    sortable
    pagination
    perPage={40}
  >
    <Card>
      <Card.Header>
        <Row className="flex-end-center mb-2 mt-2">
          <Col>
            <h5 className="mb-0">Candidates</h5>
          </Col>
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body className="p-0">
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            size: 'sm',
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
          // isLoading={loading}
        />
      </Card.Body>
      <Card.Footer>
        <AdvanceTablePagination table />
      </Card.Footer>
    </Card>
  </AdvanceTableWrapper>
  );
};