import React from 'react';
import { ChatCollection } from './ChatCollection';

export const Chat = ({
  chat
}) => {
  // console.log(`<Chat />`);
  return (
    chat.map((collection, idx) => {
      return (
        <ChatCollection
          key={`ChatCollection-${idx}`}
          collection={collection} 
        />
      )
    })
  );
};
