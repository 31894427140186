

// Inititial Create Template
export const shortBlocksList = ['start', 'end'];
export const shortBlocks = {
  start: {
    id: "start",
    name: "Start",
    description: "",
    editable: false,
    is_open: true,
    question_ids: []
  },
  end: {
    id: "end",
    name: "End",
    description: "",
    editable: false,
    is_open: true,
    question_ids: []
  }
};

export const initialCreateNewTemplate =  {
  id: 'createNewTemplate',
  type: 'interview',
  name: '',
  role: {
    id: '',
    name: ''
  },
  position: {
    form_of_employment: ''
  },
  company: {
    name: '',
    department: ''
  },
  title: '',
  description: '',
  threshold: 0,
  language: '',
  block_ids: shortBlocksList,
  blocks: { ...shortBlocks },
  client_id: '',
  parameters: {},
  published: false,
  default: false,
};
