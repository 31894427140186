import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const QutterPreviewIcon = ({
  qutter_id,
  previewIsOpen,
  onFullPreviewClick,
  containerClass='',
}) => {
  // console.log('<GlobalQutter />');
  const qutter = useSelector(state => state.Library[qutter_id]);

  if (qutter) {
    return (
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id="button-tooltip-2">Preview</Tooltip>}
      >
      {({ ref, ...triggerHandler }) => (
        <span
          className={`hover-blue me-3 font-size-10 ${containerClass} ${previewIsOpen ? 'color-blue': ''}`}
          ref={ref}
          {...triggerHandler}
          onClick={() => { if (onFullPreviewClick) onFullPreviewClick(); }}
        >
          <FontAwesomeIcon icon={faEye} />
        </span>
      )}
      </OverlayTrigger>
    );
  }
  else {
    return (<>Preview Error! Cannot find qutter.</>);
  }
};