
export const get_multiple_select_labels = async () => {

};

export const get_single_select_labels = async () => {

};

export const handle_form_labels = async (question_config) => {
  const languages = question_config.available_languages;

  let options = [];
  languages.map((language, idx) => {
    const question = question_config[language];
    question.forms.map(async (form, form_idx) => {
      const r = await get_form_labels(form, options);
      console.log('get_form_labels', r);
    });
  });
  return options;
};

export const get_form_labels = async (form, options) => {
  if (form.type === 'matrix') {
    form.rows.map((row, row_idx) => {
      form.columns.map((column, column_idx) => {
        options.push({
          label: `${row.label} - ${column.label}`,
          value: `${row.id}#${column.id}`,
          row_id: `${row.id}`,
          column_id: `${column.id}`,
          type: `matrix`
        });
      });
    });
  }
};