import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Select from 'react-select';

import { OPERATORS_SELECT_LIST, then_types } from '../../constants/condition_types';
import { findOptionValue } from 'features/common';

import { QutterAnswerSelect } from 'features/QutterAnswerSelect';

import { ruleConditionUpdate } from 'features/Qutters';
import { customRuleConditionUpdate } from 'features/Parameters';

export const RuleConditionIfAnswerSelects = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  condition_idx,
  condition,

  on_select,
  selected
}) => {
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  console.log('condition', condition)
  console.log('qutter', qutter)

  /**
   * single-select, multiple-select
   * open
   * calendar
   * form
   *    text
   *    email
   *    number
   *    slider
   *    checkbox
   *    range 
   *    matrix
   */

  if (['IS_IN', 'IS_NOT_IN', 'INCLUDES'].includes(condition.operator)) {
    return (
      <QutterAnswerSelect
        template_id={template_id}
        qutter_id={qutter_id}
        selected={condition.value}

        multi={true}
        clearable={true}
        searchable={true}
        disabled={template_id && is_default ? true: false}

        on_select={(e) => {
          let cond = Object.assign({}, condition);
          cond.value = e.map(s => s.value);
          if (template_id) {
            dispatch(customRuleConditionUpdate({ template_id, qutter_id, rule_idx, condition_idx, condition: cond }))
          } else {
            dispatch(ruleConditionUpdate({ qutter_id, rule_idx, condition_idx, condition: cond }))
          }
        }}
      />
    );
  } else {
    return (
      <QutterAnswerSelect
        template_id={template_id}
        qutter_id={qutter_id}
        selected={condition.value}

        multi={false}
        clearable={true}
        searchable={true}
        disabled={template_id && is_default ? true: false}

        on_select={(e) => {
          let cond = Object.assign({}, condition);
          cond.value = e.value;
          if (template_id) {
            dispatch(customRuleConditionUpdate({ template_id, qutter_id, rule_idx, condition_idx, condition: cond }))
          } else {
            dispatch(ruleConditionUpdate({ qutter_id, rule_idx, condition_idx, condition: cond }))
          }
        }}
      />
    )
  }
};