import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';

import { toast } from 'react-toastify';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, Observable } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { onError } from 'apollo-link-error';
import jwtDecode from 'jwt-decode';

import { getAccessToken, setAccessToken } from './tokens/tokens';

import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

const uri_refresh = process.env.REACT_APP_URL
  ? `${process.env.REACT_APP_URL}/refresh_token`
  : 'http://localhost:9091/refresh_token';
const uri = process.env.REACT_APP_URL
  ? `${process.env.REACT_APP_URL}/graphql`
  : 'http://localhost:9091/graphql';


const cache = new InMemoryCache({});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((operation) => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    new RetryLink(),
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();
        if (!token) {
          console.info('no token');
          return true;
        }

        try {
          const { exp, userId, name } = jwtDecode(token);
          if (Date.now() >= exp * 1000) {
            // console.warn('Token expired');
            return false;
          } else {
            // console.info('Token Valid.', userId, name);
            return true;
          }
        } catch {
          return false;
        }
      },
      fetchAccessToken: () => {

        return fetch(uri_refresh, {
          method: 'POST',
          credentials: 'include',
        });
      },
      handleFetch: (accessToken) => {
        setAccessToken(accessToken);

      },
      handleError: (err) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        // eslint-disable-next-line no-restricted-globals
        setAccessToken('');
        console.error(err);
      },
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          if (message === 'Not authenticated') {
            toast.error('Not authenticated');
            // console.error('Not authenticated');
            setAccessToken('');
            // window.location.reload(false);
          }

          if(extensions.code === 'UNAUTHORIZED') {
            return;
          }

          console.log(graphQLErrors)
          toast.error(`[GraphQL error]: Message: ${message}`);
          toast.error(`[GraphQL error]: Locations: ${locations}`);
          toast.error(`[GraphQL error]: Path: ${path}`);
        });
      if (networkError) console.warn(`[Network error]: ${networkError}`);
    }),
    requestLink,
    new HttpLink({
      uri: uri,
      credentials: 'include'
    })
  ]),
  cache,
  defaultOptions: {
    watchQuery: { fetchPolicy: "no-cache" },
    query: { fetchPolicy: "no-cache" },
  }
});

const Main = props => {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return (
  <ApolloProvider client={client}>
    <AppContext.Provider value={{ config, setConfig, configDispatch }}>
      {props.children}
    </AppContext.Provider>
  </ApolloProvider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
