import { gql } from '@apollo/client';

export const QUERY_GET_ALL_ADMINS = gql`
  query {
    GetAllAdmins {
      id
      first_name
      last_name
    }
  }
`;

export const EDIT_JOB_ADMIN  = gql`
mutation EditJobAdmin($job_id: String!, $admin_id: String!) {
  EditJobAdmin(job_id: $job_id, admin_id: $admin_id)
}
`;
