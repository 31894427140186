import React from 'react';
import { Card } from 'react-bootstrap';

function isObject (item) {
  return (typeof item === "object" && !Array.isArray(item) && item !== null);
};

const HubertMessages = ({ answer, answer_idx }) => {
  console.log(`<HubertMessages />`);

  return (
    <Card className='hubert-message rounded-2'>
      <Card.Body>
        {
          (() => {
            if (typeof(answer.messages) === 'string') {
              return (<div key={`answer-${answer_idx}`}>{answer.messages}</div>);
            } else 
            if (Array.isArray(answer.messages)) {
              return answer.messages.map((msg, msg_idx) => {
                if (isObject(msg)) {
                  return (
                    <p
                      key={`msg-${msg_idx}`}
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: (msg.text ? msg.text : (msg.payload ? msg.payload: ''))
                      }}
                  />)
                }
                else {
                  return (
                    <p
                      key={`msg-${msg_idx}`}
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: msg
                      }}
                  />)
                }
              });

            } else
            {
              console.log('other type');
            }
          })()
        }
      </Card.Body>
    </Card>
  );
};

export default HubertMessages;
