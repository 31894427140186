import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { ModalsContainer } from 'features/Modals';
import { QutterSearch } from 'features/QutterSearch';
import { ViewDefaultQutterModal } from 'features/Modals';

const QuestionsIndex = () => {
  let [id, setId] = useState(null);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const param = searchParams.get("id");
    if (param) {
      setId(param);
      setShowQuestionModal(true);
    }
  }, []);

  useEffect(() => {
    document.title = `Questions${id ? ` - ${id}`: ''}`;
  }, []);

  return (
    <div>
      {id && id.length > 0 ? (
        <ViewDefaultQutterModal
          id={id}
          language={null}
          showModal={showQuestionModal}
          setShowModal={setShowQuestionModal}
        />
      ):(null)}
      <ModalsContainer />
      <QutterSearch
        template_id={null}
        block_id={null}
        block_name={null}
      />
    </div>
  );
};

export default QuestionsIndex;
