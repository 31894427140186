import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faCertificate,
  faTimesCircle,
  faCircle,
  faStarHalf,
  faCheck,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons';

// import { ColourOption } from '../data';

const labelWithIcon = <span className="fas fa-stroopwafel" />;

const info = 
  <span className="">
    <FontAwesomeIcon icon={faInfoCircle} />
    <span className="m-2">Informational</span>
  </span>;

const warning = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#FFC400' }} className="mr-3" size={'lg'} className="me-1" icon={faExclamationTriangle} />
    <span className="m-2">Warning</span>
  </span>;

const bonus = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#36B37E' }} size={'lg'} icon={faCertificate} />
    <span className="m-2">Bonus</span>
  </span>;

const disqualification = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'indianred' }} size={'lg'} icon={faTimesCircle} />
    <span className="m-2">Not Qualified</span>
  </span>;

const knockout = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'red' }} size={'lg'} icon={faTimesCircle} />
    <span className="m-2">Knockout</span>
  </span>;

const point = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#5243AA' }} size={'lg'} icon={faStarHalf} />
    <span className="m-2">Point</span>
  </span>;

export const summaryLabels = [
  { 
    id: 'info',
    label: info,
    value: "info",
    color: "grey"
  },
  { 
    id: 'warnings', 
    label: warning, 
    value: "warning", 
    color: '#FFC400' 
  },
  { 
    id: 'disqualifications',
    label: disqualification,
    value: "disqualified",
    color: '#FF5630'
  },
  { 
    id: 'ko',
    label: knockout,
    value: "knockout",
    color: '#FF5630'
  },
  { 
    id: 'bonus',
    label: bonus,
    value: "bonus",
    color: '#36B37E'
  },
  {
    id: 'point',
    label: point,
    value: "point",
    color: '#5243AA'
  }
];

export const colourOptions = [
  { value: 'ocean', label: labelWithIcon, color: '#00B8D9', isFixed: true },
  { value: 'purple', label: 'Bonus', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];


const getCert = (points) => {
  return (
    <span className="fa-layers fa-fw cert">
      {/* <FontAwesomeIcon fixedWidth className="text-success icon" icon={['fas', 'certificate']} /> */}
      <FontAwesomeIcon className="text-success icon" style={{ color: '#36B37E' }} size={'lg'} icon={faCertificate} />
      <span style={{ fontSize: '0.92593rem' }} className="fa-layers-text fa-inverse">
        {points}
      </span>
    </span>
  );
};

const getPointCorrect = (points) => {
  return (
    <span className="fa-layers fa-fw cert">
      {/* <FontAwesomeIcon className="text-success" icon={['fas', 'circle']} /> */}
      <FontAwesomeIcon className="text-success" style={{ color: '#36B37E' }} size={'lg'} icon={faCircle} />
      <span style={{ fontSize: '1rem' }} className="fa-layers-text fa-inverse">
        {points}
      </span>
    </span>
  );
};

const getPointIncorrect = (points) => {
  return (
    <span className="fa-layers fa-fw cert">
      {/* <FontAwesomeIcon className="text-danger" fixedWidth icon={['fas', 'circle']} /> */}
      <FontAwesomeIcon className="text-danger" style={{ color: '#36B37E' }} size={'lg'} icon={faCircle} />
      <span style={{ fontSize: '1rem' }} className="fa-layers-text fa-inverse">
        {points}
      </span>
    </span>
  );
};

export const getIcon = (detail, large) => {
  let icon;
  switch (true) {
    case detail.icon === 'accepted':
      icon = (
        <span className="fa-layers fa-fw">
          {/* <FontAwesomeIcon style={{ fontSize: '0.95em' }} className="icon text-success" fixedWidth icon={['fas', 'check']} /> */}
          <FontAwesomeIcon style={{ fontSize: '0.95em' }} fixedWidth className="icon text-success mr-3" size={'lg'} icon={faCheck} />
        </span>
      );
      break;
    case detail.icon === 'disqualified':
      // icon = <FontAwesomeIcon className="icon text-danger" fixedWidth icon={['fas', 'times-circle']} />;
      icon = <FontAwesomeIcon className="icon text-danger" fixedWidth style={{ color: 'indianred' }} size={'lg'} icon={faTimesCircle} />
      break;

    case detail.icon === 'point':
      icon = (
        <span className="fa-layers fa-fw">
          {/* <FontAwesomeIcon fixedWidth className="purple" icon={['fa', 'circle']} /> */}
          <FontAwesomeIcon style={{ color: '#5243AA' }} className="purple" size={'lg'} icon={faCircle} />
          <span style={{ fontSize: '0.92593rem' }} className="fa-layers-text fa-inverse">
            {Math.round(detail.points)}
          </span>
        </span>
      );
      break;
    case detail.icon === 'warning':
      icon = (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon style={{ color: '#FFC400' }} fixedWidth className="icon text-warning mr-3" size={'lg'} icon={faExclamationTriangle} />
        </span>
      );
      break;
    case detail.icon === 'bonus':
      icon = getCert(detail.bonuspoints);
      break;
    case detail.points != null:
      if (detail.points !== 0) icon = getPointCorrect(detail.points.toFixed(0));
      else icon = getPointIncorrect(detail.points.toFixed(0));
      break;

    // case summary.icon === 'correct':
    //   icon = getPointIncorrect(summary.points.toFixed(0));
    //   break;
    case detail.icon === 'info':
      icon = <FontAwesomeIcon className="text-grey icon" fixedWidth style={{ color: 'grey' }} icon={faInfoCircle} />
      break;

    default:
      icon = <FontAwesomeIcon className="text-grey icon" fixedWidth style={{ color: 'grey' }} icon={faInfoCircle} />
  }
  return icon;
};