import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const infoIconWithText = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'grey' }} size={'lg'} icon={faInfoCircle} />
    <span className="m-2">Informational</span>
  </span>;

export const infoIcon = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'grey' }} size={'lg'} icon={faInfoCircle} />
  </span>;