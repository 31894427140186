import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';

import { QutterHeader } from 'features/Template';
import { PreviewQuestionTexts } from 'features/PreviewQuestionTexts';
import { AddQutterToBlock } from 'features/TemplateBlock';

import { TemplateTexts } from 'features/PreviewQuestionTexts';
import { custom } from 'features/common';

export const PreviewFillInTheGaps = ({
  template_id,
  block_id,
  block_name,
  qutter_id,
  qutter_idx,
  order,
  provided,
  is_followup,
  parent_id
}) => {
  // console.log('<PreviewInfo />', qutter_id);
  const [previewIsOpen, setPreviewIsOpen] = useState(false);

  let params = useSelector(state => state.Parameters[template_id]);
  if (!params) params = {};
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];

  const [rules, set_rules] = useState([]);
  const [is_default_rules, set_is_default_rules] = useState(true);

  useEffect(() => {
    // if (qutter_config.rules) {
    //   set_rules(qutter_config.rules);
    //   set_is_default_rules(true);
    // }

    // custom(
    //   params,
    //   qutter_id,
    //   language,
    //   set_rules,
    //   set_is_default_rules
    // );
  }, [qutter_config, params[qutter_id], language]);

  const onFullPreviewClick = e => { setPreviewIsOpen(!previewIsOpen); };
  return (
  <div
    key={`view-info-qutter-${qutter_id}-${qutter_idx}`} 
    className={`mb-2 ${qutter && qutter.is_follow_up ? 'p-0': ''}`}
  >

    <QutterHeader
      template_id={template_id}
      block_id={block_id}
      qutter_id={qutter_id}
      order={order}
      previewIsOpen={previewIsOpen}
      onFullPreviewClick={onFullPreviewClick}
      provided={provided}
      is_followup={is_followup}
      parent_id={parent_id}
    />

    {qutter_config && qutter && (
      <div className={`pt-1 ${previewIsOpen ? '': 'd-none'}`}>
        {/* Question (field: text) */}
        <TemplateTexts
          template_id={template_id}
          qutter_id={qutter_id}
          field={'text'}
        />
      </div>
    )}
    {/* 
    {template_id && is_followup !== true && (
      <AddQutterToBlock
        template_id={template_id}
        block_id={block_id}
        qutter_id={qutter_id}
        qutter_idx={qutter_idx}
      />
    )} */}

  </div>
  );
}
