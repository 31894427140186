import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

import { addCustomRule, customDeleteAllRules, resetToOriginalRules } from 'features/Parameters';
import { addRule, deleteAllRules } from 'features/Qutters';

export const DeleteAllRulesButton = ({
  template_id,
  qutter_id,
  rules,
  is_default_rules
}) => {
  const dispatch = useDispatch();

  if (rules.length === 0) return null;


  return (
    <>
    {template_id ? (
      <span
        className={`hover-blue font-size-10 ms-1 me-1`}
        onClick={() => {
          dispatch(customDeleteAllRules({ template_id, qutter_id }));
        }}
      >
        <FontAwesomeIcon
          className='hover-blue me-1'
          style={{ color: 'red' }}
          size={'sm'} 
          icon={faCircleXmark}
        />
        Delete all rules
      </span>
    ):(
      <span
        className={`hover-blue font-size-10 ms-1 me-1`}
        onClick={() => {
          dispatch(deleteAllRules({ template_id, qutter_id }));
        }}
      >
        <FontAwesomeIcon
          className='hover-blue me-1'
          size={'sm'}
          style={{ color: 'red' }}
          icon={faCircleXmark}
        />
        Delete all rules
      </span>
    )}
    </>
  );
};

/**
              <span
                className={`hover-blue font-size-10 ms-1 me-1`}
                onClick={() => {
                  dispatch(resetToOriginalRules({ template_id, qutter_id }));
                }}
              >
                <FontAwesomeIcon
                  className='hover-blue me-1'
                  size={'sm'} 
                  icon={faBackspace}
                />
                Reset to original
              </span>
 */