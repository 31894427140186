import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons';

import { LANGUAGE_LABELS } from 'features/LanguageSelect/assets/constants';

import { addToLibrary } from 'features/Qutters/slices/LibrarySlice';
import { FetchQutter } from 'features/Qutters/slices/thunks';

import { customRuleActionUpdate } from 'features/Parameters';
import { ruleActionUpdate } from 'features/Qutters';

import { array_move } from 'features/QutterRules/utils/utils';

export const RuleActionJumpToValueDown = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  action_idx,
  action,
  item_id,
  item_idx,
  num_items,
}) => {
  const dispatch = useDispatch();
  const [loading, set_loading] = useState(true);

  if (template_id) {
    return null;
  }

  const onClick = async () => {
    let updated_action = JSON.parse(JSON.stringify(action));
    updated_action.value = await array_move (updated_action.value, item_idx, (item_idx + 1));

    dispatch(ruleActionUpdate({
      qutter_id,
      rule_idx,
      action_idx,
      updated_action
    }));
  };

  if (item_idx !== (num_items - 1)) {
    return (
      <div className='w-50 m-auto text-start'>
        <FontAwesomeIcon
          className='hover-blue'
          style={{ color: 'red' }} 
          icon={faArrowDown}
          onClick={onClick}
        />
      </div>
    )
  }
  else return (null);
};