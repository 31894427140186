export const TAGS = [
  { label: 'Interview flow', value: 'interview_flow' },
  { label: 'Practical', value: 'practical' },
  { label: 'Experience', value: 'experience' },
  { label: 'Current occupation', value: 'current_occupation' },
  { label: 'Screening', value: 'screening' },
  { label: 'STAR', value: 'star' },
  { label: 'Working hours', value: 'working_hours' },
  { label: 'Body work/Physically demanding', value: 'physically_demanding_work' },
  { label: 'Certficates & Licenses', value: 'licenses' },
  { label: 'Education', value: 'education' },
  { label: 'Commuting', value: 'commute' },
  { label: 'Interests', value: 'interests' },
  { label: 'Motivation', value: 'motivation' },
  { label: 'Personal information', value: 'personal_info' },
  { label: 'Legal', value: 'legal' },
  { label: 'Salary', value: 'salary' },
  { label: 'Language', value: 'language' },
];
