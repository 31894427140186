import axios from 'axios';
const BASE = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const QUESTIONS_BASE = `${BASE}/qutters/v1`;

export function get_template_questions (template_id) {
  return new Promise((resolve, reject) => {
    axios.get(`${QUESTIONS_BASE}/get-by-template-id/${template_id}`)
    .then(result => {
      console.log('API Template Questions', result.data);
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
};
