import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'react-bootstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Select from 'react-select';

import { OPERATORS_SELECT_LIST, then_types } from '../../constants/condition_types';
import { findOptionValue } from 'features/common';

import { QutterAnswerSelect } from 'features/QutterAnswerSelect';

import { ruleConditionUpdate } from 'features/Qutters';
import { customRuleConditionUpdate } from 'features/Parameters';

export const RuleConditionIfAnswerForm = ({
  template_id,
  qutter_id,
  is_default,
  rule_idx,
  condition_idx,
  condition,

  on_select,
  selected
}) => {
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const language = qutter_config.language.current;
  const qutter = qutter_config[language];
  console.log('condition', condition)
  console.log('qutter', qutter)

  const handleChange = e => {
    e.persist();
    let cond = Object.assign({}, condition);
    cond.apply_to_form_id = e.target.value;
    if (template_id) {
      dispatch(customRuleConditionUpdate({ template_id, qutter_id, rule_idx, condition_idx, condition: cond }))
    } else {
      dispatch(ruleConditionUpdate({ qutter_id, rule_idx, condition_idx, condition: cond }))
    }
  };

  useEffect(() => {

  }, []);

  /**
   * form
   *    text
   *    email
   *    number
   *    slider
   *    checkbox
   *    range 
   *    matrix
   */

  return (
    <div className=''>
      <div className='d-flex'>
        Apply to form element:
        {qutter.forms.map((form, idx) => {
          return (
            <Form.Check
              type='radio'
              onChange={handleChange}
              label={`${form.type}`}
              id={`${form.id}`}
              value={`${form.id}`}
              name='apply-to-form'
              className='ms-2'
              checked={condition.apply_to_form_id === form.id}
            />
          )
        })}
      </div>

      {qutter.forms.map((form, idx) => {
        if (form.id === condition.apply_to_form_id && form.type === 'slider') {
          return (
            <div>
              <Slider
                min={form.min}
                max={form.max}
                step={form.step}
                defaultValue={condition.value}
                onChange={(e) => {
                  let cond = Object.assign({}, condition);
                  cond.value = e;
                  if (template_id) {
                    dispatch(customRuleConditionUpdate({ template_id, qutter_id, rule_idx, condition_idx, condition: cond }))
                  } else {
                    dispatch(ruleConditionUpdate({ qutter_id, rule_idx, condition_idx, condition: cond }))
                  }
                }}
                dots={true}
              />
              <div>{condition.value}</div>
            </div>
          )
        }
      })}
    </div>
  );
};