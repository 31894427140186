import React, { useState } from 'react';
import { Card, Button, Collapse } from 'react-bootstrap';

import { ApplicationsList } from 'features/ApplicationsList';

const ApplicationsCollapse = ({
  job_id,
  number_of,
  filter,
  header
}) => {
  const [open, setOpen] = useState((filter === 'todos' ? true: false));

  return (
    <Card
      key={`collapse-card-${job_id}-${header}`}
      className='mt-1 mb-1'
    >
      <Card.Header
        onClick={() => setOpen(!open)}
      >
        {header} - {number_of}
      </Card.Header>

      <Card.Body
        className='pt-0 pb-0'
      >

      <Collapse in={open}>
        {open && open === true ? (
          <ApplicationsList
            header={header}
            job_id={job_id}
            filter={filter}
          />
        ) : (
          <></>
        )}
      </Collapse>

    </Card.Body>
  </Card>
  );
};

export default ApplicationsCollapse;
