import { 
  initiateEvaluation, 
  setDefaultEvaluation,
  updateScoringCategories
} from "..";

export const slider_context = async (
  dispatch,
  library,
  template,
  eval_state,
  node,
  is_followup
) => {
  const qutter_config = library[node.id];
  const languages = template.language_settings.list;

};

export const form_context = async (
  dispatch,
  library,
  template,
  eval_state,
  node,
  is_followup
) => {
  const qutter_config = library[node.id];
  const languages = template.language_settings.list;

  dispatch(initiateEvaluation({
    languages,
    qutter_id: node.id,
    is_followup: is_followup
  }));

  languages.map(async language => {
    const qutter = qutter_config[language];
    if (!qutter) return;

    for (let i = 0; i < qutter.forms.length; i++) {
      const form = qutter.forms[i];
      if (!form) continue;
      const default_eval = form.evaluation;
      if (!default_eval) continue;

      if (form.type === 'matrix') {
        if (default_eval.hasOwnProperty('weight')) {
          dispatch(updateScoringCategories({
            qutter_id: node.id,
            category: form.id,
            weight: default_eval.weight,
            is_followup
          }));

          dispatch(setDefaultEvaluation({
            language,
            qutter_id: node.id,
            id: form.id,
            evaluation: {
              weight: default_eval.weight,
              scoring_category: form.id
            }
          }));
        } else {
          dispatch(setDefaultEvaluation({
            language,
            qutter_id: node.id,
            evaluation: default_eval
          }));
        }
      } else 
      if (form.type === 'slider') {
        if (default_eval.hasOwnProperty('weight')) {
          dispatch(updateScoringCategories({
            qutter_id: node.id,
            category: form.id,
            weight: default_eval.weight,
            is_followup
          }));

          dispatch(setDefaultEvaluation({
            language,
            qutter_id: node.id,
            id: form.id,
            evaluation: {
              weight: default_eval.weight,
              scoring_category: form.id
            }
          }));
        } else {
          dispatch(setDefaultEvaluation({
            language,
            qutter_id: node.id,
            id: form.id,
            evaluation: default_eval
          }));
        }
      } else {
        dispatch(setDefaultEvaluation({
          language,
          qutter_id: node.id,
          evaluation: default_eval
        }));
      }
    }
  });
}