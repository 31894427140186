import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PreviewForm } from './PreviewForm';

import { PreviewContainer } from './PreviewContainer';

export const PreviewForms = ({
  template_id,
  qutter_id
}) => {
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const qutter = qutter_config[qutter_config.language.current];
  const forms = qutter.forms;
  // console.log(`<PreviewForms />`);

  if (!forms) return null;

  return (
    <PreviewContainer>
      <div>
        <span className='h4'>{qutter.help_text}</span>
      </div>

      {forms.map((form, form_idx) => {
        return (
          <PreviewForm 
            template_id={template_id}
            qutter_id={qutter_id}
            form_idx={form_idx}
            key={`preview-form-${form_idx}`}
          />
        );
      })}

      <div 
        id="input_error_container" 
        className="text-center w-100" 
        style={{ minHeight: '18px' }}
      >
        <p id="input_error" className="m-0 hidden" style={{ color: 'red' }}>
          {/* {form.error.message}  */}
        </p>
      </div>
      <hr/>
      <div 
        id="formButton" 
        className="inputBubbles"
        style={{
          borderRadius: '5px',
          backgroundColor: 'rgba(0,0,0,0.9)',
          color: '#fff',
          padding: '10px',
          // maxWidth: calc($width - 2 * $margin);
          margin: '5px',
          position: 'relative',
          fontSize: '1rem',
          // '-webkit-text-fill-color': '#fff',
          textAlign: 'center',
          cursor: 'pointer',
          textTransform: 'uppercase',
          marginRight: 'auto !important',
          marginLeft: 'auto !important'
        }}
      >
        {qutter.send_text}
      </div>
    </PreviewContainer>
  );
};
