import axios from 'axios';
const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const LOCAL_URL = `${base}/candidates/v1/local/search`;
const GLOBAL_URL = `${base}/candidates/v1/global/search`;

export function candidate_advanced_search (search_params) {
  return new Promise((resolve, reject) => {
    axios.post(`${URL}`, search_params)
    .then(result => {
      console.log('API Qutter Search', result.data);
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
};
