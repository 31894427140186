import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { OpenInNewTab } from 'features/OpenInNewTab';

const popover = (args) => {
  const { 
    application_id,
    candidate_id,
    interview_ids } = args;
  return (
    <Popover style={{ minWidth: '400px' }}>
      <Popover.Header as="h3">IDs & Links</Popover.Header>
      <Popover.Body>

        <div className='d-flex font-size-10'>
          <div className='w-25 text-start'>
            <strong>Application:</strong>
          </div>
          <div className='w-75 text-end'>
            {application_id}
          </div>
        </div>

        <div className='d-flex font-size-10'>
          <div className='w-25 text-start'>
            <strong>Candidate:</strong>
          </div>
          <div className='w-75 text-end'>
            {candidate_id}
          </div>
        </div>

        <div className='d-flex font-size-10'>
          <div className='w-25 text-start'>
            <strong>Interview/s:</strong>
          </div>
          <div className='w-75 text-end'>
            {interview_ids.toString()}
          </div>
        </div>

        <div className='d-flex font-size-10'>
          <div className='w-25 text-start'>
            <strong>Candidate dashboard:</strong>
          </div>
          <div className='w-75 text-end'>
            <OpenInNewTab url={`https://interview.hubert.ai/candidate/dashboard/${application_id}`} name={'Candidate dashboard'} />
          </div>
        </div>

        <div className='d-flex font-size-10'>
          <div className='w-25 text-start'>
            <strong>Job in Recruiter App:</strong>
          </div>
          <div className='w-75 text-end'>
            <OpenInNewTab url={`https://app.hubert.ai/${application_id}`} name={'Job in Recruiter App'} />
          </div>
        </div>

        <div className='d-flex font-size-10'>
          <div className='w-25 text-start'>
            <strong>Interview Preview:</strong>
          </div>
          <div className='w-75 text-end'>
            <span>
              {args.previewLinks.map((item, idx) => {
                return (
                  <OpenInNewTab
                    key={`open-new-tab-interview-preview-${idx}`}
                    url={`${item}`}
                    name={`Preview Interview ${idx + 1}`}
                  />
                );
              })}
            </span>
          </div>
        </div>

        <div className='d-flex font-size-10'>
          <div className='w-25 text-start'>
            <strong>Interview Links:</strong>
          </div>
          <div className='w-75 text-end'>
            <span>
              {args.interviewLinks.map((item, idx) => {
                return (
                  <OpenInNewTab
                    key={`open-new-tab-interview-${idx}`}
                    url={`${item}`}
                    name={`Interview ${idx + 1}`}
                  />
                );
              })}
            </span>
          </div>
        </div>

      </Popover.Body>
    </Popover>
  );
}

const ApplicationIdsPopover = ({
  application_id,
  candidate_id,
  interview_ids,
  previewLinks,
  interviewLinks
}) => {
  return (
    <OverlayTrigger 
      trigger="click" placement="left" 
      overlay={
        popover({
          application_id, 
          candidate_id, 
          interview_ids,
          previewLinks,
          interviewLinks
        })
      }
    >
      <span>Click to open</span>
    </OverlayTrigger>
  )
}

export default ApplicationIdsPopover;