import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFloppyDisk,
  faXmark
} from '@fortawesome/pro-solid-svg-icons';

import { Modal } from 'react-bootstrap';
import { NodeActions } from '..';

import { toggleInterviewNodeActionsModal } from 'features/Modals/slices/ModalSlice';
import { addNodeToTemplateBlock } from 'features/Template/slices/TemplateSlice';

const new_node = () => {
  return { id: nanoid(), type: 'action', actions: [] };
};

export const NodeActionModal = ({
  template_id,
  block_id,
  block_idx
}) => {
  const dispatch = useDispatch();
  let modal = useSelector(state => state.Modals.InterviewNodeActionsModal);
  const [node, set_node] = useState(null);

  useEffect(() => {
    set_node(new_node());
  }, [modal.show]);
  console.log(modal);

  if (!node) return null;

  return (
  <Modal
    show={modal.show}
    onHide={() => {
      dispatch(toggleInterviewNodeActionsModal({
        show: false,
        data: null
      }));
    }}
    backdrop={true}
    backdropClassName={'black'}
    dialogClassName=""
    size="m"
    centered
  >

    <Modal.Header closeButton>
      <Modal.Title>
        Interview Actions
      </Modal.Title>
    </Modal.Header>

    <Modal.Body className="">
      <NodeActions
        template_id={template_id}
        block_id={block_id}
        block_idx={block_idx}
        node={node}
        set_node={set_node}
      />
    </Modal.Body>

    <Modal.Footer>
      <span
        className="font-size-10 hover-blue me-2"
        onClick={() => {
          dispatch(toggleInterviewNodeActionsModal({
            show: false,
            data: null
          }));
        }}
      >
        <FontAwesomeIcon icon={faXmark} className="me-1"/>
        Close
      </span>
      <span
        className="font-size-10 hover-blue me-2"
        onClick={() => {
          dispatch(addNodeToTemplateBlock({
            template_id: modal.data.template_id,
            block_id: modal.data.block_id,
            node_idx: modal.data.node_idx,
            new_node: node
          }))
        }}
      >
        <FontAwesomeIcon icon={faFloppyDisk} className="me-1"/>
        Save
      </span>
    </Modal.Footer>
  </Modal>
  );
};
