import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import classNames from 'classnames';

import Logo from 'features/Login/components/Logo';
import { TopNavRightSideNavItem } from './TopNavRightSideNavItem';

import { getScriptsByClientId } from 'features/QutterComment';

export const LimitedNavbar = () => {
  const { pathname } = useLocation();
  const [templates, setTemplates] = useState([]);
  const [error, setError] = useState({ isError: false, message: '' });
  const user = useSelector(state => state.config.user);
  console.log('user', user)

  useEffect(() => {
    if (user && user.userType !== 'user') return;
    if (!user.client_id) {
      toast.error('Seems like you do not belong to a client. Please email support@hubert.ai.');
      return;
    }
    getScriptsByClientId(user, user.client_id)
    .then((data) => {
      console.log('getScriptsByClientId data', data);
      setTemplates(data);
    })
    .catch((error) => {
      console.log(error);
      setTemplates([]);
      setError({ isError: true, message: error });
    });
  }, []);

  useEffect(() => {
    // window.addEventListener('scroll', setDropShadow);
    // return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
  <Navbar
    className={classNames('navbar-glass fs--1 sticky-kit', {})}
  >
    <Logo
      at="navbar-top"
      width={40}
      id="topLogo"
      linkTo='/app'
    />

    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="navbarScroll">
      <Nav className="me-auto my-2 my-lg-0">

        {user.userType === 'user' && templates && templates.length ? (
        <NavDropdown title="Templates" id="navbarScrollingDropdown">
          <div className='py-2 bg-light rounded-3'>
            {templates.map(template => {
              return (
                <NavDropdown.Item
                  key={`navbar-item-${template._id}`}
                >
                  <Nav.Link
                    as={Link}
                    to={`/app/script/${template._id}`}
                  >
                    {template.name}
                  </Nav.Link>
                </NavDropdown.Item>
              )
            })}
            {/* 
            <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action5">Something else here</NavDropdown.Item> 
            */}
          </div>
        </NavDropdown>
        ):(null)}

        {user.userType === 'cs' ? (
          <>
          <Nav.Link as={Link} to="/clients">Clients</Nav.Link>
          <Nav.Link as={Link} to="/jobs">Jobs</Nav.Link>
          <Nav.Link as={Link} to="/candidates">Candidates</Nav.Link>
          <Nav.Link as={Link} to="/templates">Templates</Nav.Link>
          <Nav.Link as={Link} to="/questions">Questions</Nav.Link>
          <Nav.Link as={Link} to="/parser">Parser</Nav.Link>
          </>
        ):(null)}

      </Nav>
    </Navbar.Collapse>

    <TopNavRightSideNavItem />
  </Navbar>
  );
};
