import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';

import { Row, Container, Col } from 'react-bootstrap';
import { JsonPreview } from 'features/common';

import Divider from 'components/common/Divider';

import { LanguageSelect } from 'features/LanguageSelect';

import { QutterComponents } from 'features/QutterType';
import { QutterTypePicker } from 'features/QutterType';
import { CreateSelect } from 'features/QutterSelects';

import { QutterActionsAccordion } from 'features/QutterActions';
import { QutterGeneralSettings } from '..';
import CreateQutterSaveButton from './CreateQutterSaveButton';
import { EditQutterShortDescription } from '..';

import { setCurrentQutterLanguage } from 'features/Qutters/slices/LibrarySlice';
import { toggleSearchQutterModal } from 'features/Modals';

export const QutterCreate = ({
  id
}) => {
  const dispatch = useDispatch();
  const Modals = useSelector(state => state.Modals);
  const [qutter_id, set_qutter_id] = useState(Modals.createQuestionModal.qutter_id ? Modals.createQuestionModal.qutter_id: 'create-info');
  const [copy_from_error, set_copy_from_error] = useState(null);
  const [copy_from_qutter_id, set_copy_from_qutter_id] = useState(Modals.createQuestionModal.qutter_id ? Modals.createQuestionModal.qutter_id: 'create-info');
  const [language, set_language] = useState(Modals.createQuestionModal.language ? Modals.createQuestionModal.language: null);
  const qutterConfig = useSelector(state => state.Library[(qutter_id)]);
  
  let qutter = null;
  if (qutterConfig && language) {
    qutter = qutterConfig[language];
    // console.log('<CreateQutter />', qutter_id, language);
  }

  if (!qutter_id || !language) {
    return (
    <>

      <QutterTypePicker
        id={qutter_id}
        on_select={(e) => { set_qutter_id(e); }}
      />

      <Divider className="mt-4">Language</Divider>
      <Row>
        <Col className='m-auto text-center'>
          <span className='m-auto'>Choose Language</span>
        </Col>
        <Col className='m-auto'>
          <LanguageSelect
            exclude={['nn-no']}
            on_select={(e) => {
              console.log('LanguageSelect e', e)
              set_language(e.value)
              if (qutter_id && qutter_id.length) {
                dispatch(setCurrentQutterLanguage({
                  id: qutter_id,
                  language: e.value
                }));
              }
            }}
            selected_language={
              // (newLanguage ? newLanguage: (qutter && qutter.language ? qutter.language: 0))
              (qutter && qutter.language ? qutter.language: 0)
            }
          />
        </Col>
      </Row>

      {/* 
      <Row className='text-center mt-5'>
        <span>
          {copy_from_error ? (
            <p className='red'>{copy_from_error}</p>
          ): (null)}
          <span className='me-1'>Or</span>
          <span
            onClick={() => {
              if (!language) {
                set_copy_from_error('Choose a language first.');
              }
              dispatch(toggleSearchQutterModal({
                show: true,
                add_policy: 'COPY_FROM_EXISTING_QUTTER',
                shouldDisplayCreateQutterButton: false,
                data: {
                  copy_from_language: language
                }
              }))
            }}
            className='hover-blue'>
            copy from existing
            <FontAwesomeIcon icon={faCopy} className='ms-2' />
          </span>
        </span>
      </Row> 
      */}
    </>
    );
  }

  return (
  <>
    <QutterTypePicker
      id={qutter_id}
      on_select={(e) => { set_qutter_id(e); }}
    />
    {qutter_id && qutterConfig ? (
    <>
      <Divider className="mt-4">Language</Divider>
      <Row>
        <Col className='m-auto text-center'>
          <span className='m-auto'>Choose Language</span>
        </Col>
        <Col className='m-auto'>
          <LanguageSelect
            on_select={(e) => {
              console.log('LanguageSelect e', e)
              set_language(e.value)
              if (qutter_id && qutter_id.length) {
                dispatch(setCurrentQutterLanguage({
                  id: qutter_id,
                  language: e.value
                }));
              }
            }}
            selected_language={
              // (newLanguage ? newLanguage: (qutter && qutter.language ? qutter.language: 0))
              (qutter && qutter.language ? qutter.language: 0)
            }
          />
        </Col>

      </Row>

      <EditQutterShortDescription qutter_id={qutter_id} />

      <Divider className="mt-4">Details</Divider>
      <Container>
        <Row>
          {qutter_id && qutter_id.length ? (
            React.createElement(
              (['single-select', 'multiple-select'].includes(qutterConfig.type) ? 
                CreateSelect : 
                QutterComponents[qutterConfig.type]
              ),
              {
                id: qutter_id,
                language: language
              }
            )
          ):(
            <span>Nothing chosen yet.</span>
          )}
        </Row>

        <QutterGeneralSettings id={qutter_id} />
        <QutterActionsAccordion qutter_id={qutter_id} />

      </Container>

      <CreateQutterSaveButton
        qutter_id={qutter_id}
        language={language}
      />

      <JsonPreview data={qutter} />
    </>
    ) : (
    <div 
      className='m-auto text-center center'
      style={{
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        msTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <span>You must choose a type.</span>
    </div>
    )}
  </>
  );
};
