import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  createQuestionModal: {
    show: false,
    qutter_id: null,
    language: null,
  },
  editQutterModal: {
    show: false,
    qutter_id: null,
    language: null,
    newLanguage: false
  },
  InterviewNodeActionsModal: {
    show: false,
    data: null
  },
  searchQutterModal: {
    show: false,
    add_policy: false,
    shouldDisplayCreateQutterButton: false,
    template_id: null,
    block_id: null,
    block_name: null,
    qutter_idx: null,
    qutter_id: null
  },
  formEvaluationModal: {
    show: false,
    data: null,
  },
  qutterCommentModal: {
    show: false,
    data: null,
  }
};

const ModalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleCreateQuestionModal (state, action) {
      const { show, qutter_id, language } = action.payload;
      return {
        ...state,
        createQuestionModal: {
          show: show,
          qutter_id: qutter_id,
          language: language,
        }
      }
    },
    toggleEditQutterModal (state, action) {
      const { 
        show, 
        qutter_id, 
        language,
        newLanguage
      } = action.payload;

      return {
        ...state,
        editQutterModal: {
          show: show,
          qutter_id: qutter_id,
          language: language,
          newLanguage: newLanguage
        }
      };
    },
    toggleInterviewNodeActionsModal (state, action) {
      const {
        show,
        data,
      } = action.payload;

      return {
        ...state,
        InterviewNodeActionsModal: {
          show: show,
          data: data
        }
      };
    },
    toggleFormEvaluationModal (state, action) {
      const {
        show,
        data,
      } = action.payload;

      return {
        ...state,
        formEvaluationModal: {
          show: show,
          data: data
        }
      };
    },
    toggleQutterCommentModal (state, action) {
      const {
        show,
        data,
      } = action.payload;

      return {
        ...state,
        qutterCommentModal: {
          show: show,
          data: data
        }
      };
    },
    toggleSearchQutterModal (state, action) {
      const {
        show,
        shouldDisplayCreateQutterButton,
        add_policy,
        header_text,
        template_id,
        block_id,
        block_name,
        qutter_idx,
        qutter_id,
        data,
        fn
      } = action.payload;
      return {
        ...state,
        searchQutterModal: {
          show: show,
          add_policy: add_policy,
          header_text: header_text,
          shouldDisplayCreateQutterButton: shouldDisplayCreateQutterButton,
          template_id: template_id,
          block_id: block_id,
          block_name: block_name,
          qutter_id: qutter_id,
          qutter_idx: qutter_idx,
          data: data,
          fn: fn
        }
      };
    },
  }
});

export const {
  toggleCreateQuestionModal,
  toggleEditQutterModal,
  toggleInterviewNodeActionsModal,
  toggleSearchQutterModal,
  toggleFormEvaluationModal,
  toggleQutterCommentModal
} = ModalSlice.actions;
export default ModalSlice.reducer;
