import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ScoringCategoryPopoverOverlay } from './ScoringCategoryPopoverOverlay';

export const ScoringCategory = ({
  template_id,
  category_id,
  category,
  category_idx,
}) => {
  if (!category) return null;

  return (
  <div key={`${category}-${category_idx}`}>
    <div className='d-flex font-size-10'>
      <div className='w-75 text-start'>
        <span style={{ fontSize: '9px', fontWeight: 900 }}>
          <ScoringCategoryPopoverOverlay
            template_id={template_id}
            category_id={category_id}
            category={category}
            weight={category.weight}
          />
        </span>
      </div>
      <div className='w-25 text-end'>
        <span className=''>
          {Math.round((category.weight * 100) * 10) / 10}%
        </span>
      </div>
    </div>
  </div>
  );
};
