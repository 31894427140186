import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

export const DeleteRuleConditionButton = ({
  template_id,
  is_default,
  on_click
}) => {

  if (template_id) {
    if (is_default) {
      return (null)
    } else {
      return (
        <span
          className="font-size-10 hover-blue m-auto"
          onClick={() => { on_click(); }}
        >
          <FontAwesomeIcon icon={faTrash} className="me-1"/>
          Delete condition
        </span>
      );
    }
  } else {
    return (
      <span
        className="font-size-10 hover-blue m-auto"
        onClick={() => { on_click(); }}
      >
        <FontAwesomeIcon icon={faTrash} className="me-1"/>
        Delete condition
      </span>
    );
  }
};