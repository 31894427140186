import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

import { updateQutterSummaryLabel } from 'features/Qutters/slices/LibrarySlice';

export const QutterSummaryLabel = ({
  qutter_id
}) => {
  console.log('<QuestionSummaryLabel />', qutter_id);
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const qutter = qutterConfig[qutterConfig.language.current];

  return (
    <div className='d-flex mt-3'>
      <div className='w-50 m-auto text-center'>
        <span>Default Summary Label</span>
      </div>
      <div className='w-50 text-left'>
        <Form.Control
          value={qutter.presentation.label}
          type="text"
          placeholder="Summary Label"
          onChange={(e) => {
            dispatch(updateQutterSummaryLabel({ id: qutter_id, label: e.target.value }));
          }}
        />
      </div>
    </div>
  );
};