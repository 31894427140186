import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';

import { Button } from 'react-bootstrap';
import { QutterCommentItem } from '..';
import { getCommentsByQuestion, postComment } from '../api/api';

export const QutterCommentsList = ({
  comments
}) => {
  // console.log('<QutterCommentsList /> ')
  if (!comments || !comments.comments) return <span>No comments.</span>

  return (
    <div
      className=''
      style={{
        height: '80%',
        maxHeight: '80%',
        overflowY: 'scroll'
      }}
    >
      {comments.comments.toReversed().map((comment, idx) => {
        return (
          <QutterCommentItem
            key={idx}
            content={comment.content}
            authorId={comment.author.id}
            authorName={comment.author.name}
            authorType={comment.author.author_type}
            created_at={comment.created_at}
            idx={idx}
          />
        )
      })}
    </div>
  );
};
