import React, { useState } from 'react';
import Select from 'react-select';

const TemplateQutterSelectSingle = ({
  disabled,
  placeholder,
  className,

  list,
  selected,
  on_select,
}) => {

  let idx = null;
  if (selected && !Array.isArray(selected)) {
    function findQuestion (obj) { return obj.value === selected; }
    idx = list.findIndex(findQuestion);
  }

  return (
    <Select
      isMulti={false}
      className={className}
      isDisabled={disabled}

      value={(selected ? list[idx]: null)}
      placeholder={placeholder ? placeholder: 'Choose Question'}
      options={list}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};

export default TemplateQutterSelectSingle;