import React from 'react';
import { useSelector } from 'react-redux';
import { Card, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faCertificate,
  faTimesCircle,
  faStarHalf,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons';

function isObject (item) {
  return (typeof item === "object" && !Array.isArray(item) && item !== null);
}

const interviewTagsTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props.description}
  </Tooltip>
);

const info = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'grey' }} size={'lg'} icon={faInfoCircle} />
    <span className="m-2">Informational</span>
  </span>;

const warning = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#FFC400' }} className="mr-3" size={'lg'} icon={faExclamationTriangle} />
    <span className="m-2">Warning</span>
  </span>;

const bonus = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#36B37E' }} size={'lg'} icon={faCertificate} />
    <span className="m-2">Bonus</span>
  </span>;

const disqualification = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'indianred' }} size={'lg'} icon={faTimesCircle} />
    <span className="m-2">Not Qualified</span>
  </span>;

const knockout = 
  <span className="">
    <FontAwesomeIcon style={{ color: 'red' }} size={'lg'} icon={faTimesCircle} />
    <span className="m-2">Knockout</span>
  </span>;

const point = 
  <span className="">
    <FontAwesomeIcon style={{ color: '#5243AA' }} size={'lg'} icon={faStarHalf} />
    <span className="m-2">Point</span>
  </span>;


const icons = {
  warning: warning,
  info: info,
  bonus: bonus,
  disqualification: disqualification,
  knockout: knockout,
  point: point
}


const CandidateMessages = ({ answer, answer_idx }) => {
  const scoringDetail = useSelector(state => state.scorer.per_qutter[answer.id]);
  console.log(`<CandidateMessages /> scoringDetail answer.id`, answer.id, scoringDetail);
  if ('parse' in answer) {
    return <></>;
  }

  const warning = answer.warning;
  const disqualification = answer.disqualification;

  return (
    <Card
      className='candidate-message rounded-2'
      style={{
        color: 'black',
        backgroundColor: 'hsl(249deg 100% 97%)'
      }}
    >

      {scoringDetail && scoringDetail.aiDetection ? (
        <OverlayTrigger
          placement="right"
          delay={0}
          overlay={
            interviewTagsTooltip({
              description: `Score: ${scoringDetail.aiDetection.score}`
            })
          }
        >
          <Badge
            bg={`${scoringDetail.aiDetection.is_ai ?
              'warning':
              'success'}`}
            className="me-1 w-100"
          >
            {(() => {
              if (scoringDetail.aiDetection.prediction_tag) {
                return (
                  <span>{scoringDetail.aiDetection.prediction_tag.split('_').join(' ')}</span>
                )
              } else 
              if (scoringDetail.aiDetection.tag) {
                return (
                  <span>{scoringDetail.aiDetection.tag.split('_').join(' ')}</span>
                )
              } else {
                return (
                  <span>Erroneous tag</span>
                )
              }
            })()}
          </Badge>

        </OverlayTrigger>
      ):(null)}

      {warning && (
        <Card.Header>
          {warning ? (icons.warning) : (<></>)}
        </Card.Header>
      )}

      {disqualification && (
        <Card.Header>
          {disqualification ? (icons.disqualification) : (<></>)}
        </Card.Header>
      )}
      <Card.Body>
        {
          (() => {
            if (typeof(answer.text) === 'string') {
              let text = answer.text;
              if (answer.score || answer.score === 0) {
                text += `<span class="font-size-10 float-end font-monospace">(+${answer.score})<span>`
              }
              return (
                <p
                  key={`answer-${answer_idx}`}
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: text
                  }}
                />
              );
            } else 
            if (Array.isArray(answer.text)) {
              return answer.text.map((msg, msg_idx) => {
                if (isObject(msg)) {
                  let text = (msg.text ? msg.text : (msg.payload ? msg.payload: ''))
                  if (msg.score) {
                    text += `<span class="font-size-10 float-end font-monospace">(+${msg.score})<span>`
                  }
                  return (
                    <p
                      key={`msg-${msg_idx}`}
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: text
                      }}
                  />)
                }
                else {
                  return (
                    <p
                      key={`msg-${msg_idx}`}
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: msg
                      }}
                    />
                  )
                }
              });

            } else
            {
              console.log('other type');
            }
          })()
        }
      </Card.Body>
    </Card>
  );
};

export default CandidateMessages;
