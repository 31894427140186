import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Collapse } from 'react-bootstrap';

export const HubiverseTagsBranch = ({
  id,
  // item,
  map,
  openedItems=[],
  setOpenedItems,
  selectedItems,
  setSelectedItems,
  selection,

  on_tag_click,
  on_tag_check
}) => {
  // console.log('openedItems', openedItems)
  const item = map[id];
  if (!item) return null;
  // console.log('item', item)
  const [open, setOpen] = useState(openedItems.indexOf(item.id) !== -1);
  const [children, setChildren] = useState([]);
  const [firstChildren, setFirstChildren] = useState([]);
  const [childrenOpen, setChildrenOpen] = useState(false);
  const checkRef = useRef();

  const getChildrens = item => {
    function flatInnter(ids) {
      let flat = [];
      ids.map(id => {
        let item = map[id]
        if (item.children && item.children.length) {
          flat = [...flat, id, ...flatInnter(item.children)];
        } else {
          flat = [...flat, id];
        }
      });

      return flat;
    }
    if (item.children && item.children.length) {
      return flatInnter(item.children);
    } else {
      return [];
    }
  };

  const isChildrenOpen = () => {
    return openedItems.some(item => firstChildren.indexOf(item) !== -1);
  };

  const handleOnExiting = () => {
    setOpenedItems(openedItems.filter(openedItem => openedItem !== item.id));
  };
  const handleEntering = () => {
    setOpenedItems([...openedItems, item.id]);
  };

  const handleSingleCheckboxChange = e => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, item.id]);
      if (on_tag_check) on_tag_check(item, true);
    } else {
      setSelectedItems(
        selectedItems.filter(selectedItem => selectedItem !== item.id)
      );
      if (on_tag_check) on_tag_check(item, false);
    }
  };

  const handleParentCheckboxChange = e => {
    const filteredItems = selectedItems.filter(
      selectedItem => children.indexOf(selectedItem) === -1
    );
    if (e.target.checked) {
      setSelectedItems([...filteredItems, ...children]);
    } else {
      setSelectedItems(filteredItems);
    }
  };

  useEffect(() => {
    setChildren(getChildrens(item));
    if (item.children) {
      setFirstChildren(item.children);
    }
  }, []);

  useEffect(() => {
    setChildrenOpen(isChildrenOpen());
  }, [children, openedItems]);

  useEffect(() => {
    const childrenSelected = selectedItems.some(
      selectedItem => children.indexOf(selectedItem) !== -1
    );

    const allChildrenSelected = children.every(
      child => selectedItems.indexOf(child) !== -1
    );

    if (childrenSelected && checkRef.current) {
      checkRef.current.indeterminate = true;
    }
    if (!childrenSelected && checkRef.current) {
      checkRef.current.indeterminate = false;
    }
    if (allChildrenSelected && checkRef.current) {
      checkRef.current.indeterminate = false;
      checkRef.current.checked = true;
    }
    if (!allChildrenSelected && checkRef.current) {
      checkRef.current.checked = false;
    }
  }, [selectedItems, checkRef.current]);

  if (item.hasOwnProperty('children') && item.children.length > 0) {
    return (
      <li className="treeview-list-item">
        <div className="toggle-container">
          {selection && (
            <input
              type="checkbox"
              className="form-check-input"
              onChange={handleParentCheckboxChange}
              ref={checkRef}
            />
          )}
          <a
            className={classNames('collapse-toggle', {
              collapsed: open || item.expanded
            })}
            href="#!"
            onClick={() => setOpen(!open)}
          >
            <p
              className={classNames('ms-4 treeview-text', { 'ms-2': !selection })}
              onClick={() => {
                if (on_tag_click)
                  on_tag_click(item)
              }}
            >
              <span 
                className=''
                style={{
                  marginLeft: '30px'
                }}
              >
                {item.name}
              </span>
            </p>
          </a>
        </div>
        <Collapse
          in={open}
          onExiting={handleOnExiting}
          onEntering={handleEntering}
        >
          <ul
            className={classNames('treeview-list', {
              'collapse-hidden': !open,
              'collapse-show treeview-border': open,
              'treeview-border-transparent': childrenOpen
            })}
          >
            {item.children.map((id, index) => {
              return (
                <HubiverseTagsBranch
                  key={index}
                  id={id}
                  map={map}
  
                  index={index}
                  openedItems={openedItems}
                  setOpenedItems={setOpenedItems}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  selection={selection}

                  on_tag_click={on_tag_click}
                  on_tag_check={on_tag_check}
                />
              )
            })}
          </ul>
        </Collapse>
      </li>
    );

  } else {
    return (
      <li 
        className="treeview-list-item"
        style={{ marginLeft: '1.1em', marginTop: '0.2em' }}
      >
          <div className="treeview-item">
            {selection && (
              <input
                type="checkbox"
                className="form-check-input"
                onChange={handleSingleCheckboxChange}
                checked={selectedItems.indexOf(item.id) !== -1}
              />
            )}
            <a 
              href="#!" 
              className="flex-1"
            >
              <p 
                className="treeview-text"
                onClick={() => {
                  if (on_tag_click)
                    on_tag_click(item)
                }}
              >
                {item.icon ? (
                  <FontAwesomeIcon
                    icon={item.icon}
                    className={classNames('me-2', item.iconClass)}
                  />
                ):(null)}
                {item.name}
              </p>
            </a>
          </div>
      </li>
    );
  }
};


HubiverseTagsBranch.propTypes = {
  id:           PropTypes.string,
  item:         PropTypes.object,
  map:          PropTypes.object.isRequired,

  openedItems: PropTypes.array,
  setOpenedItems: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  selection: PropTypes.bool,
  on_tag_click: PropTypes.func,
};