import { 
  createAsyncThunk,
  nanoid
} from '@reduxjs/toolkit';

import {
  saveNewQutter,
  updateQutter,
  removeQutter,
  fetchQutter,
  fetchMultipleQutters
} from '../api/api';

export const SaveNewQutter = createAsyncThunk(
  'create-qutter/save-new',
  async (qutter) => {
    // The value we return becomes the `fulfilled` action payload
    const id = qutter.id;
    // Assign new random id
    qutter.id = nanoid();
    const result = await saveNewQutter(qutter);
    return { id: id, result: result };
  }
);

export const UpdateQutter = createAsyncThunk(
  'create-qutter/update',
  async (qutter) => {
    const result = await updateQutter(qutter);
    return { result: result };
  }
);

export const RemoveQutter = createAsyncThunk(
  'create-qutter/remove',
  async (data) => {
    const result = await removeQutter(data);
    return result;
  }
);

export const FetchQutter = createAsyncThunk(
  'create-qutter/fetch-qutter',
  async (qutter_id) => {
    // The value we return becomes the `fulfilled` action payload
    const result = await fetchQutter(qutter_id);
    return { result: result };
  }
);

export const FetchMultipleQutters = createAsyncThunk(
  'create-qutter/fetch-qutters',
  async (qutter_ids) => {
    // TODO: Check in state.Library if qutters already loaded?
    // If so: skip fetching those.
    const result = await fetchMultipleQutters(qutter_ids);
    return { result: result };
  }
);

export const SearchByTextInLibraryThunk = createAsyncThunk(
  'create-qutter/search-qutter-in-library',
  async ({findText, languages}, { getState }) => {
    const state = getState();
    const regexp = new RegExp(findText, 'i');

    let qutter_ids = [];
    for (const key in state.Library) {
      let qutterConfig = state.Library[key];
      if (!qutterConfig || key.startsWith('create')) continue;
      
      for (let i = 0; i < languages.length; i++) {
        if (!qutterConfig[languages[i]]) continue;
        let plain_text = qutterConfig[languages[i]].plain_text;
        if (qutterConfig.hasOwnProperty(languages[i]) && regexp.test(plain_text)) {
          qutter_ids.push(key);
        }
      }
    }
    return { result: qutter_ids };
  }
);
