import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPlusCircle, faTags } from '@fortawesome/pro-solid-svg-icons';

import { create_tag_request } from '../api/api';

export const HubiverseTagCreate = ({
  id,
  root_tags,
  set_root_tags,
  map,
  set_map,
}) => {
  const [error, set_error] = useState(null);
  const [loading, set_loading] = useState(true);
  const [name, set_name] = useState('');

  const on_create_root_tag_click = (e) => {
    if (!name) set_error('Missing name.');
    // if (!parent) set_error('Missing parent.');
    create_root_tag();
  };

  const create_root_tag = (e) => {
    const id = nanoid();
    const new_tag = {
      id:           id,
      name:         name.trim(),
      description:  '',
      is_root:      true,
      children:     []
    };
    create_tag_request(new_tag)
    .then(r => {
      set_name('');
      set_error(null);

      let copy = Object.assign({}, map);
      copy[id] = new_tag;
      set_map(copy);
      set_root_tags([...root_tags, id]);
    })
    .catch(e => {

    });
  };

  return (
  <div style={{ position: 'absolute', bottom: '0' }}>
    <Form.Group className="mb-3">
      <Form.Label>Add root tag</Form.Label>
      <Form.Control
        type="text"
        placeholder="Tag name"
        value={name}
        onChange={(e) => {
          let text = e.target.value;
          set_name(text);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            on_create_root_tag_click();
          }
        }}
      />
    </Form.Group>

    {error ? (
      <div><p className='red'>{error}</p></div>
    ):(null)}

    <span
      className="font-size-10 hover-blue"
      onClick={on_create_root_tag_click}
    >
      <FontAwesomeIcon icon={faPlusCircle} className='me-1' />
      Add root tag
    </span>

  </div>
  );
};
