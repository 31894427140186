import React, { useState } from 'react';
import Select from 'react-select';
// import { municipality } from '../constants/constants';

const MunicipalitySelectMulti = ({
  list,
  disabled,
  on_select, 
  selected,
  placeholder,
  className,
  clearable,
  searchable
}) => {
  let municipalities = [];

  if (selected && selected.length > 0) {
    let idx = 0;
    selected.map((s) => {
      function find(obj) { return obj.value === s; }
      idx = list.findIndex(find);
      if (idx > -1) {
        municipalities.push(list[idx]);
      }
    });
  }

  return (
    <Select
      isMulti={true}
      className={className}
      isDisabled={disabled}
      value={municipalities && municipalities.length > 0 ? municipalities: null}
      options={list}
      placeholder={placeholder ? placeholder: 'Choose municipality'}
      isClearable={clearable}
      isSearchable={searchable}

      onChange={(e)=> {
        console.log(e)
        if (on_select) on_select(e);
      }}
    />
  );
};

export default MunicipalitySelectMulti;
