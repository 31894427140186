import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';

import { QutterEvaluationSelect } from 'features/QutterEvaluationSelect';
import { EvaluationPopoverContent } from './EvaluationPopoverContent';

export const EvaluationPopoverContainer = (
  template_id,
  qutter_id,
  evaluation,
  type,
  id,
  onSave
) => {

  return (
    <Popover
      id="popover-basic-evaluation-popup"
      className={`qutter-followup-popover`}
    >
      <Popover.Header as="h3">Evaluation - {qutter_id} | {id}</Popover.Header>
      <Popover.Body className='h-100'>

        <EvaluationPopoverContent
          template_id={template_id}
          qutter_id={qutter_id}
          evaluation={evaluation}
          type={type}
          id={id}
          onSave={onSave}
        />

      </Popover.Body>
    </Popover>
  )
};
