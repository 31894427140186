import React from 'react';
import { Container } from 'react-bootstrap';
import Divider from 'components/common/Divider';

import { SelectOptions } from '..';
import { QutterSummary } from 'features/QutterSummary';
import { TextComponent } from 'features/TextComponent';

export const CreateQutterYesNo = ({ id }) => {
  // console.log('<CreateQuestionYesNo />');

  return (
    <Container key={'yes-no-create-question'}>

      <TextComponent id={id} field={'intro'} />
      <TextComponent id={id} field={'text'} />
      <TextComponent id={id} field={'outro'} />
      <TextComponent id={id} field={'clarify'} />

      <Divider className="mt-4">Options</Divider>
      <SelectOptions id={id} />

      <Divider className="mt-4">Summary</Divider>
      <QutterSummary id={id} /> 
    </Container>
  );
}
