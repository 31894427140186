import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Form } from 'react-bootstrap';

import { FormSliderEvaluationItem } from './FormSliderEvaluationItem';
import { FormEvaluationElement } from '../../common/FormEvaluationElement';
import { setCustomEvaluation } from 'features/QutterEvaluation';

import {
  updateFormByIndex
} from 'features/Qutters/slices/LibrarySlice';

export const FormSliderEvaluation = ({
  template_id,
  qutter_id,
  form,
  originalEvaluation,
}) => {
  console.log('<FormSliderEvaluation /> originalEvaluation', originalEvaluation);
  const interviewReceptState = useSelector(state => state.InterviewReceipt);
  const Evaluation = useSelector(state => state.Evaluation[qutter_id]);
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const language = qutterConfig.language.current;

  const dispatch = useDispatch();
  const [evaluation, setEvaluation] = useState(null);

  const custom_key = `${form.id}.evaluation`;
  useEffect(() => {
    if (!template_id) {
      setEvaluation(form.evaluation);
    } else {
      setEvaluation(Evaluation[language][custom_key]);
    }
  }, [Evaluation, language]);

  const onAddEvaluationListElement = (e) => {
    let customKey = null;
    if (qutterConfig.type === 'form') {
      customKey = `${form.id}.evaluation`;
    }
    let temp = Object.assign([], Evaluation[language][customKey]);
    temp.push({
      name: 'bonus',
      bonus_points: 2,
      condition: {
          type: 'relative',
          reference: 'answer',
          operator: 'eq',
          value: 50
      }
    });

    if (template_id) {
      dispatch(setCustomEvaluation({
        language,
        qutter_id: qutter_id,
        id: form.id,
        evaluation: temp
      }));
    } else {
      toast.info('Sorry: can only be edited in template.');
    }
  };

  const onEvaluationTypeChange = (e) => {
    const type = e.target.checked ? 'weighted': 'list';
    if (Array.isArray(evaluation)) {
      const ev = { weight: 0, policy: 'basic' };
      dispatch(setCustomEvaluation({
        language,
        qutter_id: qutter_id,
        id: form.id,
        evaluation: ev
      }));
      setEvaluation(ev);
    }
    else {
      dispatch(setCustomEvaluation({
        language,
        qutter_id: qutter_id,
        id: form.id,
        evaluation: []
      }));
      setEvaluation([]);
    }
  };

  console.log('<FormSliderEvaluation /> evaluation', evaluation);

  if (!evaluation) {
    return (
      <div>
        No evaluation! 
        <span className="font-size-10 hover-blue me-2" // onClick={onEvaluationAddClick} 
        >
          <FontAwesomeIcon icon={faPlusCircle} className="me-1" />Add
        </span>
      </div>
    )
  }

  return (
    <div>
      {/*
        Not Checked === list
        Checked     === weighted
      */}
      <Form.Check
        type="switch"
        label="Weighted"
        onChange={onEvaluationTypeChange}
        checked={
          Array.isArray(evaluation) ? 
            false:
            true
        }
      />
      {(() => {
        if (Array.isArray(evaluation)) {
          console.log('evaluation list', evaluation)
          return (
            <>
            {evaluation.map((detail, idx) => {
              return (
                <FormEvaluationElement
                  key={`form-evaluation-element-${template_id}-${qutter_id}-${idx}`}
                  template_id={template_id}
                  qutter_id={qutter_id}
                  formId={form.id}
                  evaluation={detail}
                  evaluationIdx={idx}
                />
              )
            })}

              <div>
                <span
                  className="font-size-10 hover-blue me-2"
                  onClick={onAddEvaluationListElement}
                >
                  <FontAwesomeIcon icon={faPlusCircle} className="me-1" />
                  Add
                </span>
              </div>
            </>
          )
        } else
        {
          console.log('evaluation weighted', evaluation)
          return (
            <div className='text-center'>
              <span className='badge badge-soft-info'>Weighted: {evaluation.weight * 100}%</span>
            </div>
          );
        } 
        // else {
        //   return (<div className="">Not supported evaluation type.</div>);
        // }
      })()}
    </div>
  )
}

// const qutter_config = useSelector(state => state.Library[qutter_id]);
// const language = qutter_config.language.current;
// const qutter = qutter_config[language];
// const forms = qutter.forms;
// const form = forms[form_idx];

// const onEvaluationAddClick = value => {
//   let temp = Object.assign({}, form);
//   temp = JSON.parse(JSON.stringify(temp));
//   const obj = {
//     name: "bonus",
//     bonus_points: 0,
//     condition: {
//       type: "relative",
//       reference: "answer",
//       operator: "LT",
//       value: 1
//     }
//   };

//   if (temp.evaluation) {
//     temp.evaluation.push(obj);
//   }
//   else {
//     temp.evaluation = [obj];
//   }
//   dispatch(updateFormByIndex({ qutter_id, form_idx, form_config: temp }));
// };