import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  FormControl,
  Form,
  Row,
  Button,
  Modal,
  Container,
  Col
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
  faGripVertical
} from '@fortawesome/free-solid-svg-icons';

import { MissingQutter } from 'features/Qutters';

import { addToLibrary } from 'features/Qutters/slices/LibrarySlice';

import { FetchQutter } from 'features/Qutters/slices/thunks';

import { getItemStyle } from '../utils/utils';

import * as INTERVIEW_SUMMARY_CONSTANTS from 'features/InterviewSummary';

import {
  add_to_block_at_idx,
  remove_from_block_at_idx,
  update_label_at_idx
} from '..';

export const InterviewSummaryDetail = ({
  template_id,
  summary_block_id,
  summary_block_idx,
  qutter_id,
  detail,
  detail_idx
}) => {
  const dispatch = useDispatch();
  const template = useSelector(state => state.Template[template_id]);
  const qutter_config = useSelector(state => state.Library[qutter_id]);
  const interviewReceptState = useSelector(state => state.InterviewReceipt);
  let qutter = null;
  if (qutter_config)
    qutter = qutter_config[qutter_config.language.current];
  else
    return null;

  const [is_loading, set_is_loading] = useState(true);
  // Custom label
  const [label, set_label] = useState(
    detail.label && detail.label.length > 0
    ? detail.label
    : qutter ? qutter.presentation.label: ''
  );
  const [editMode, setEditMode] = useState(false);
  const [is_viewable, set_is_viewable] = useState(true);

  useEffect(() => {
    if (
      summary_block_id ===
      INTERVIEW_SUMMARY_CONSTANTS.NOT_VIEWABLE_BLOCK_IN_SUMMARY
    ) {
      set_is_viewable(false);
    }

    set_is_loading(false);
  }, [qutter_config, template]);

  if (is_loading) return <span>Loading...</span>;
  if (!qutter) return (null);

  if (!qutter_config || !qutter) {
    return (
      <tr style={{ verticalAlign: 'middle' }} className="font-size-10 pl-0">
        <td
          className="font-size-10 pl-0"
          style={{ padding: '0.25rem', color: 'red' }}
        >
          <MissingQutter
            template_id={template_id}
            qutter_id={qutter_id}
            missing_language={qutter_config.language.current}
          />
        </td>
        <td></td>
        <td></td>
      </tr>
    );
  }

  let question_text = '<strong>Answer to</strong>:<br>';
  question_text += qutter.text.join(' ');

  let header = '<strong>Label</strong>:<br>';

  return (
    <>
      <Draggable
        key={`draggable-qutter###${qutter_id}`}
        draggableId={`draggable-qutter###${qutter_id}`}
        index={detail_idx}
      >
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            // {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            {provided && (
              <td
                {...provided.dragHandleProps}
                style={{ verticalAlign: 'middle' }}
              >
                {interviewReceptState.readOnlyMode ? (
                  ''
                ) : (
                  <span className="font-size-10">
                    <FontAwesomeIcon icon={faGripVertical} />
                  </span>
                )}
              </td>
            )}

            <td
              className="font-size-10 cursor-pointer"
              style={{ padding: '0.25rem', verticalAlign: 'middle' }}
              onClick={() => {
                if (!interviewReceptState.readOnlyMode) {
                  setEditMode(true);
                }
              }}
            >
              {!editMode ? (
                <span
                  dangerouslySetInnerHTML={{ __html: header + label }}
                  style={{ verticalAlign: 'middle' }}
                  className="m-0"
                ></span>
              ) : (
                <Form.Control
                  defaultValue={label}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      setEditMode(false);
                      dispatch(
                        update_label_at_idx({
                          template_id,
                          block_id: summary_block_id,
                          detail_idx,
                          label: e.target.value
                        })
                      );
                      set_label(e.target.value);
                    }
                  }}
                  onChange={e => {
                    // set_label(e.target.value);
                  }}
                  aria-label="Small"
                  size="sm"
                  aria-describedby="inputGroup-sizing-sm"
                />
              )}
            </td>
            <td className="font-size-10">
              <span
                dangerouslySetInnerHTML={{ __html: question_text }}
                style={{ verticalAlign: 'middle' }}
                className="m-0"
              ></span>
            </td>

            <td
              className=""
              style={{ textAlign: 'right', verticalAlign: 'middle' }}
            >
              {is_viewable ? (
                <span
                  className="m-1 cursor-pointer hover-blue"
                  onClick={() => {
                    if (!interviewReceptState.readOnlyMode) {
                      set_is_viewable(!is_viewable);
                      dispatch(
                        remove_from_block_at_idx({
                          template_id,
                          block_id: summary_block_id,
                          idx: detail_idx
                        })
                      );
                      dispatch(
                        add_to_block_at_idx({
                          template_id,
                          block_id:
                            INTERVIEW_SUMMARY_CONSTANTS.NOT_VIEWABLE_BLOCK_IN_SUMMARY,
                          idx: 0,
                          qutter_id
                        })
                      );
                    }
                  }}
                >
                  {interviewReceptState.readOnlyMode ? (
                    ''
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </span>
              ) : (
                <span
                  className="m-1 cursor-pointer hover-blue"
                  onClick={() => {
                    if (!interviewReceptState.readOnlyMode) {
                      set_is_viewable(!is_viewable);
                    }
                  }}
                >
                  {interviewReceptState.readOnlyMode ? (
                    ''
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </span>
              )}
            </td>
          </tr>
        )}
      </Draggable>
    </>
  );
};
