import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Col } from 'react-bootstrap';

import {
  SaveNewQutter
} from 'features/Qutters/slices/thunks';

import {
  toggleCreateQuestionModal
} from 'features/Modals/slices/ModalSlice';

const CreateQutterSaveButton = ({
  qutter_id,
  language
}) => {
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[(qutter_id)]);
  // console.log('<CreateQutterSaveButton />', qutter_id, language);

  let qutter = null;
  if (qutterConfig && language) {
    qutter = qutterConfig[language];
  }

  const onCloseModalClick = (e) => {
    dispatch(toggleCreateQuestionModal({
      show: false,
      qutter_id: null,
      language: null,
    }));
  };

  const onSaveClick = () => {
    // TODO: VALIDATION - Validate the saved qutter
    console.log('save qutter config', qutterConfig)
    console.log('save qutter', qutter)
    console.log('save qutter language', language)
    let qutter_def = {
      type: qutter.type,
      global: qutterConfig.global,
      is_reusable: qutterConfig.is_reusable,
      is_star_question: qutterConfig.is_star_question,
      pre_actions: qutterConfig.pre_actions,
      post_actions: qutterConfig.post_actions,
      roles: qutterConfig.roles,
      tags: qutterConfig.tags,
      short_description: qutterConfig.short_description,
    };

    if (qutterConfig.client_id)
      qutter_def.client_id = qutterConfig.client_id;

    qutter_def.available_languages = [language];
    qutter_def[language] = qutter;

    let payload = {
      qutter: qutter_def,
      qutter_language: language
    };
    dispatch(SaveNewQutter(payload))
    .then((res) => {
      onCloseModalClick();
    });
  };

  return (
  <Row className='m-2'>
    <Col className="text-center">
      <Button
        className="text-center"
        // disabled={id && id.length ? false : true}
        onClick={() => { onSaveClick(); }}
      >
        Save Question
      </Button>
    </Col>
  </Row>
  );
};

export default CreateQutterSaveButton;