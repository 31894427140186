export function QutterPostActionsPlus (state, action) {
  const { id } = action.payload;
  const qutter = state[id];
  qutter.post_actions.push('');
};

export function QutterPostActionsMinus (state, action) {
  const { id, idx } = action.payload;
  const qutter = state[id];
  qutter.post_actions.splice(idx, 1);
};

export function UpdateQutterPostAction (state, action) {
  const { id, idx, post_action } = action.payload;
  const qutter = state[id];
  qutter.post_actions[idx] = post_action;
};
