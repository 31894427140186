import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Row, Button, Modal, Container, Col } from 'react-bootstrap';

import { findOptionValue } from 'features/common';
import {IntentText} from './IntentText';

import {
  intentReplyActionChange
} from 'features/Qutters/slices/LibrarySlice';

const actionList = [
  { 
    id: 'next', 
    label: 'Next', 
    value: 'next',
    color: "grey"
  },
  { 
    id: 'sleep',
    label: 'Sleep',
    value: 'sleep',
    color: "grey"
  }
];

const IntentReplyModal = ({
  showModal,
  setShowModal,
  id,
  intent_idx,
}) => {
  const dispatch = useDispatch();
  // const qutter = useSelector(state => state.Library[id]);
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[qutterConfig.language.current];

  const len = qutter.intents[intent_idx].replies.length;
  const intent_action = qutter.intents[intent_idx].replies[len - 1];

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop={true}
      backdropClassName={'black'}
      dialogClassName=""
      size="s"
      aria-labelledby=""
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Hubert's replies
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">

        <Row>
          <Col auto='true'>
            {qutter.intents.length ? (
              <>
              {qutter.intents[intent_idx].replies.map((text, reply_idx) => {
                if (['sleep', 'next'].includes(text)) {
                  return (
                    <Select
                      key={`intent-reply-action-text-${intent_idx}-${reply_idx}`}
                      defaultValue={findOptionValue(actionList, intent_action)}
                      options={actionList}
                      isDisabled={false}
                      onChange={(e)=> {
                        dispatch(intentReplyActionChange({ id, intent_idx, intent_action: e.value }));
                      }}
                    />
                  )
                } else {
                  return (
                    <IntentText
                      key={`intent-reply-text-${intent_idx}-${reply_idx}`}
                      id={id}
                      intent_idx={intent_idx}
                      reply_idx={reply_idx}
                    />
                  );
                }
              })}
              </>
            ):(
              <Button
                className="m-1"
                onClick={() => {}}
              >
                Add texts
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Container>
          <Row className="float-end">
            <Col>
              <Button
                className="m-1"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Close
              </Button>
            </Col>
          </Row>
        </Container>

      </Modal.Footer>
    </Modal>
  );
};

export default IntentReplyModal;
