import { PreviewSelect } from 'features/QutterSelects';
import { PreviewCalendar } from 'features/QutterCalendar';
import { PreviewOpen } from 'features/QutterOpen';
import { PreviewInfo } from 'features/QutterInfo';
import { PreviewForms } from 'features/QutterForm';
import { PreviewFormInTemplate } from 'features/QutterForm';
import { PreviewReusable } from 'features/QutterReusable';
import { PreviewFillInTheGaps } from 'features/QutterFillInTheGaps';
import { PreviewCalendly } from 'features/QutterCalendly';

export const QutterPreviewComponents = {
  info:               PreviewInfo,
  open:               PreviewOpen,
  'single-select':    PreviewSelect,
  'multiple-select':  PreviewSelect,
  'date-picker':      PreviewCalendar,
  'month-picker':     PreviewCalendar,
  'form':             PreviewFormInTemplate,
  'reusable':         PreviewReusable,
  'gaps':             PreviewFillInTheGaps,
  'calendly':         PreviewCalendly
};
