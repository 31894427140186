import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

import { Card, Form } from 'react-bootstrap';
import { JsonPreview } from 'features/common';
import { hubert_parse } from '../api/api';

import { ParsedText } from './ParsedText';

export const ParserIndex = ({}) => {
  const [text, set_text] = useState('');
  const [result, set_result] = useState({});
  const [loading, set_loading] = useState(false);

  const on_parse_click = () => {
    let payload = {};
    if (text.length) payload.text = text;
    set_loading(true);

    hubert_parse(payload).then((data) => {
      set_loading(false);
      set_result(data)
    }).catch((error) => {
      set_loading(false);
      toast.error(`Search failed: ${error}`);
    });
  };

  return (
    <Card className='mb-1'>
      <Card.Body>

        <Form.Group className="mb-3">
          <Form.Label>Parse Candidate Text</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Parse some text"
            rows="5"
            defaultValue={text}
            onChange={(e) => { set_text(e.target.value); }}
            onKeyPress={event => {
              if (event.key === "Enter") {
                on_parse_click();
              }
            }}
          />
        </Form.Group>

        <span
          className="font-size-10 me-4 hover-blue"
          onClick={on_parse_click}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} className='me-1' />
          Parse
        </span>

        <ParsedText
          loading={loading}
          result={result}
        />

      </Card.Body>
    </Card>
  );
};
