import React, { useState } from 'react';
import { Card, Collapse } from 'react-bootstrap';

const InterviewMetaData = ({
  meta
}) => {
  const [open, setOpen] = useState(false);
  if (!meta) return (<span>No information to show.</span>);

  return (
    <Card
      key={`collapse-card-interview-meta`}
      className='mt-1 mb-1'
    >
      <Card.Header onClick={() => setOpen(!open)} >
        Interview Meta Data
      </Card.Header>

      <Card.Body className='pt-0 pb-0'>

        <Collapse in={open}>
          {open && open === true ? (
            <>
              <p>
                Device: {meta.device}
              </p>
              <p>
                OS: {meta.OS}
              </p>
              <p>
                Browser: {meta.browserName}
              </p>
            </>
          ) : (
            <></>
          )}
        </Collapse>
      </Card.Body>
    </Card>
  );
};

export default InterviewMetaData;
