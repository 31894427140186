import axios from 'axios';
import { getAccessToken } from 'tokens/tokens';

const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:9091';
const URL = `${base}/templates/v1`;

export function get_all_templates (only_new) {
  if (only_new) {
    return new Promise((resolve, reject) => {
      axios.post(`${base}/graphql`, {
        query: `query {
          templates: GetAllTemplates {
            id
            _id: id
            name
            company {
              name
            }
            client {
              org_name
            }
            role {
              id
              name
            }
            type
            language
            published
          }
        }`,
        variables: { },
      }, { headers: { 'Content-Type': 'application/json', 'Authorization': `bearer ${getAccessToken()}` } })
      .then(result => {
        console.info('getAllTemplates', { result })
        resolve(result.data.data.templates);
      })
      .catch(error => {
        if (error.response) {
          // Request made and server responded
          reject(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          reject(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error.message);
        }
      });
    });
  } else {
    return new Promise((resolve, reject) => {
      axios.get(`${URL}/get-all-templates`)
      .then(result => {
        resolve(result.data);
      })
      .catch(error => {
        if (error.response) {
          // Request made and server responded
          reject(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          reject(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          reject(error.message);
        }
      });
    });
  }
}

export function get_client_templates (client_id) {
  return new Promise((resolve, reject) => {
    axios.get(`${URL}/get-client-templates/${client_id}`)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}

export function getScriptsByClientId (client_id) {
  console.log('TemplateSelect getScriptsByClientId', client_id)
  return new Promise((resolve, reject) => {
    axios.get(`${URL}/get-scripts-by-client-id/${client_id}`)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        reject(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error.message);
      }
    });
  });
}
