import React, { useState } from 'react';
import Select from 'react-select';

import { municipality } from '../constants/constants';

const MunicipalitySelectSingle = ({
  disabled,
  on_select, 
  selected,
  placeholder,
  className
}) => {

  let idx = null;
  if (selected) {
    function find (obj) { return obj.value === selected; }
    idx = municipality.findIndex(find);
  }

  return (
    <Select
      isMulti={false}
      className={className}
      isDisabled={disabled}
      value={(selected ? municipality[idx]: null)}
      placeholder={placeholder ? placeholder: 'Choose municipality'}
      options={municipality}
      onChange={(e)=> {
        if (on_select) on_select(e);
      }}
    />
  );
};

export default MunicipalitySelectSingle;
