import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { QutterSearch } from 'features/QutterSearch';

import { toggleSearchQutterModal } from 'features/Modals/slices/ModalSlice';

export const SearchQutterModal = ({
  add_policy,
  header_text,
  template_id,
  block_id,
  block_name,
  qutter_idx,
  showModal,
  setShowModal
}) => {
  const dispatch = useDispatch();
  return (
    <Modal
      show={showModal}
      onHide={() => {
        if (setShowModal) setShowModal(false);
        else {
          dispatch(toggleSearchQutterModal({
            show: false,
            shouldDisplayCreateQutterButton: false,
            header_text: null,
            template_id: null,
            block_id: null,
            block_name: null,
            qutter_idx: null,
            qutter_id: null
          }));
        }
      }}
      dialogClassName="create-question-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {header_text ? 
            header_text: 
            <span>Add Question to Block <strong><em>{block_name}</em></strong></span>
          }
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <QutterSearch
          template_id={template_id}
          block_id={block_id}
          block_name={block_name}
          qutter_idx={qutter_idx}
          selection={true}
          add_policy={add_policy ? add_policy : 'add-qutter-to-template-block'}
          setShowModal={setShowModal}
        />
      </Modal.Body>

    </Modal>
  );
};
