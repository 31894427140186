import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import { Button, Form } from 'react-bootstrap';

const EDIT_JOB_TITLE = gql`
mutation EditTitle($job_id: String!, $title: String!) {
  EditTitle(job_id: $job_id, title: $title)
}
`;

const JobTitle = ({ job_id, _title }) => {
  console.log('<JobTitle />');

  let { id } = useParams();
  if (job_id) id = job_id;
  else job_id = id;

  const [title, setTitle] = useState(_title);
  const [editMode, setEditMode] = useState(false);

  const [titleMutation, {data, loading, error}] = useMutation(EDIT_JOB_TITLE);
  const handleChange = (e) => { 
    setTitle(e.target.value);
  };

  return (
    <>
      {
        (() => {
          if (loading) { return ('Updating title...'); }
          else 
          if (error) { return (`Error: ${error}`); }
          else 
          if (editMode) { 
            return (
            <div>
              <Form.Label htmlFor="inputPassword5">Title</Form.Label>
              <Form.Control
                type="text"
                id="inputTitle"
                aria-describedby="titleHelpBlock"
                defaultValue={title}
                onChange={event => handleChange(event)}
              />
              <Form.Text id="titleHelpBlock" muted>
                Set the title for the job.
              </Form.Text>
    
              <Button
                variant='hubert'
                className="w-auto btn btn-sm"
                onClick={() => titleMutation({
                  variables: {
                    job_id: job_id,
                    title: title
                  }
                }).then(() => { setEditMode(false); })}
              >
                Done
              </Button>
            </div>
            );
          }
          else 
          if (title) {
            return (
              <span
                onClick={() => { editMode ? setEditMode(false): setEditMode(true); }}
                className='h3'
              >
                <span className='cursor-pointer'>{title}</span>
              </span>
            );
          }
          else {
            return (
              <span
                onClick={() => { editMode ? setEditMode(false): setEditMode(true); }}
                className='hover-blue font-size-10'
              >
                <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
                Add title
              </span>
            );
          }
        })()
      }
    </>
  );
};

export default JobTitle;
