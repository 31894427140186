import React, { useState, useEffect } from 'react';
import MunicipalitySelectSingle from './MunicipalitySelectSingle';
import MunicipalitySelectMulti from './MunicipalitySelectMulti';

import { get_municipalities } from '../api/api';

export const MunicipalitySelect = ({
  selected=[],
  disabled,
  multi=false,
  on_select,
  placeholder,
  className
}) => {
  const [loading, set_loading] = useState(true);
  const [error, set_error] = useState(false);
  const [list, set_list] = useState([]);

  useEffect(() => {
    get_municipalities()
    .then((res) => {
      console.log('muni list', res.zip_codes);
      let _ = [];
      for (const key in res.zip_codes) {
        if (!key || !key.length) continue;
        _.push({
          label: key,
          value: key,
          codes: res.zip_codes[key].list
        });
      }
      set_list(_)
      set_loading(false);
    })
    .catch((error) => {
      set_error(true);
    })
  }, []);

  if (loading) return (<span>Loading municipalities...</span>)
  if (error) return (<span>Error loading municipalities...</span>)

  if (multi) {
    return (
      <MunicipalitySelectMulti
        list={list}
        disabled={disabled}
        selected={selected}
        on_select={on_select}
        placeholder={placeholder}
        className={className}
      />
    );
  }

  return (
    <MunicipalitySelectSingle
      disabled={disabled}
      selected={selected}
      on_select={on_select}
      placeholder={placeholder}
      className={className}
    />
  );
};
