import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlusCircle,
  faMinusCircle
} from '@fortawesome/free-solid-svg-icons';

import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';

import {
  textPlus,
  textMinus,
  updateQutterTextField
} from 'features/Qutters/slices/LibrarySlice';

export const Text = ({
  id,
  idx,
  field,
  language
}) => {
  const dispatch = useDispatch();
  const qutterConfig = useSelector(state => state.Library[id]);
  const qutter = qutterConfig[language ? language: qutterConfig.language.current];
  // console.log('<Text />', id, qutterConfig.language.current);

  const [text, setText] = useState(qutter[field][idx]);
  const [editMode, setEditMode] = useState(false);

  const handleDone = () => {
    dispatch(updateQutterTextField({ id, idx, field, text: text }));
    editMode ? setEditMode(false): setEditMode(true);
  };

  const onMinusClick = () => { dispatch(textMinus({ id, idx, field })); };
  const onPlusClick = () => { dispatch(textPlus({ id, idx, field })); };

  return (
  <Row className='m-1'>
    {editMode ? (
      <Row>
        <Col>
          <ReactQuill
            onChange={setText}
            placeholder={'Question Text'}
            defaultValue={qutter[field][idx]}
          />
        </Col>
        <Col xs={1} md={1} lg={1} className='m-auto'>
          <Button
            className="w-auto btn btn-sm"
            variant='primary'
            onClick={handleDone}
          >
            Done
          </Button>
        </Col>
      </Row>
    ) : (
      (() => {
        if (qutter[field][idx].length) {
          return (
            <Row>
              <Col
                className='bubble cursor-pointer m-auto'
                dangerouslySetInnerHTML={{
                  __html: qutter[field][idx]
                }}
                onClick={() => { editMode ? setEditMode(false): setEditMode(true); }}
              ></Col>

              <Col className='text-center m-auto' xs={1} md={1} lg={1}>
                <span 
                  className='m-1 cursor-pointer hover-blue'
                  onClick={() => { onMinusClick(); }}
                >
                  <FontAwesomeIcon icon={faMinusCircle} />
                </span>
                <span
                  className='m-1 cursor-pointer hover-blue'
                  onClick={() => { onPlusClick(); }}
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                </span>
              </Col>
            </Row>
          );
        } else {
          return (
            <Button 
              className="btn btn-sm w-auto"
              variant='hubert'
              onClick={() => { editMode ? setEditMode(false): setEditMode(true); }}
            >
              + Add Question Text
            </Button>
          );
        }
      })()
    )}
  </Row>
  );
};
