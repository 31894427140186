import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LimitedNavbar } from 'features/Navbar';

import {
  setHubertAccessToken,
  fetchHubertAccessToken
} from 'tokens/hubertTokens';
import { setIsLoggedIn, setIsLoggedOut } from '_components/config/ConfigSlice';

const RecruiterAppMainLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const { hash, pathname } = useLocation();

  useEffect(() => {
    fetchHubertAccessToken()
      .then(({ accessToken, user }) => {
        if (accessToken) {
          setHubertAccessToken(accessToken);
          dispatch(setIsLoggedIn({
            user: {
              ...user,
              userType: 'user'
            }
          }));
          setIsLoadingAuth(false);
        } else {
          setHubertAccessToken('');
          dispatch(setIsLoggedOut());
          return navigate(`/app-login?redirect=${pathname}`);
        }
      })
      .catch((err) => {
        setHubertAccessToken('');
        dispatch(setIsLoggedOut());
        navigate(`/app-login`);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      {isLoadingAuth ? (
        <span>loading</span>
      ):(
        <div>
          <LimitedNavbar />
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default RecruiterAppMainLayout;
