import React from 'react';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const DisqualificationsInfoIcon = ({
  info_text,
  containerClass=''
}) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={<Tooltip id="button-tooltip-2">{info_text}</Tooltip>}
    >
    {({ ref, ...triggerHandler }) => (
      <span
        className={containerClass}
        ref={ref}
        {...triggerHandler}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
    )}
    </OverlayTrigger>
  );
};

export const DisqualificationsWarningIcon = ({
  info_text,
  containerClass=''
}) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={<Tooltip id="button-tooltip-2">{info_text}</Tooltip>}
    >
    {({ ref, ...triggerHandler }) => (
      <span
        className={containerClass}
        style={{ color: '' }}
        ref={ref}
        {...triggerHandler}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </span>
    )}
    </OverlayTrigger>
  );
};

export const DisqualificationsOutline = ({
  disqualifications,
  loading
}) => {
  const ids = [...disqualifications.ids];
  const length = ids.length;

  if (loading)
    return (<span>Counting Disqualifications...</span>);
  if (!disqualifications) 
    return (<span>Disqualifications error...</span>);

  return (
    <div className='d-flex font-size-10'>
      <div className='w-75 text-start'>
        Disqualifying questions:
      </div>
      <div className='w-25 text-end'>
        {length}
        {length < 3 ? (
          <DisqualificationsWarningIcon
            info_text={'Maybe you should re-think and add a few disqualifying questions.'}
            containerClass={'ms-2 font-size-10'}
          />
        ):(
          <DisqualificationsInfoIcon
            info_text={'Seems like a reasonable number of disqualifying questions.'}
            containerClass={'ms-2 font-size-10'} 
          />
        )}
      </div>
    </div>
  );
};
