import React from 'react';

import {
  Container,
  Button,
  Badge,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';

const popover = ({
  name,
  header,
  is_weighted,
  score,
  predicted,
  edited,
  weight,
  weighted
}) => {

  return (
  <Popover id="popover-basic" style={{ minWidth: '200px !important', width: '200px !important' }}>
    <Popover.Header as="h3">
      {( is_weighted === true ) ? (
        <strong class='h6 text-capitalize text-decoration-underline'>
          Weighted question<br/>
        </strong>
      ):(
        <strong class='h6 text-capitalize text-decoration-underline'>
          Bonus question<br/>
        </strong>
      )}
    </Popover.Header>
    <Popover.Body 
      as={Container} 
      className='w-100'
      style={{ minWidth: '200px !important', width: '200px !important' }}
    >

      {score || score === 0 ? (
        <span>
          Score: {score.toFixed(1)} <br/>
        </span>
      ) :
      (<></>)}

      {is_weighted === true ? (
        <>
          {(predicted || predicted === 0) ? (
            <span>
              Predicted score: {predicted.toFixed(1)}<br/>
              {(edited === true) && (
                <Badge bg="warning" className="d-table me-2">EDITED</Badge>
              )}
              {(edited === false) && (
                <Badge bg="success" className="d-table me-2">NOT EDITED</Badge>
              )}
            </span>
          ):(<></>)}

          {(weight || weight === 0) ? (
            <span>
              Weight: {(weight * 100).toFixed(1)}%<br/>
            </span>
          ):(<></>)}

          {(weighted || weighted === 0) ? (
            <strong class='h5 text-capitalize text-decoration-underline'>
              {weighted.toFixed(1)}<br/>
            </strong>
          ):(<></>)}
        </>
      ):(<></>)}
    </Popover.Body>
  </Popover>)
}


const ScoringDetailsPopover = ({
  name,
  header,
  is_weighted,
  score,
  predicted,
  edited,
  weight,
  weighted
}) => {

  return (
    <OverlayTrigger 
      trigger={['click']} 
      placement='left'
      overlay={
        popover({header, is_weighted, score, predicted, edited, weight, weighted})
      }
      >
        <Badge bg="info" className="me-2 float-end">{header}</Badge>
    </OverlayTrigger>
  );
};

export default ScoringDetailsPopover;
