import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import Main from './Main';

// Rollbar
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'

import { Provider } from 'react-redux';
import { store } from './store/store';

import './App.scss';
import './index.css';

import 'helpers/initFA';
import './helpers/initFAPro';

const rollbarConfig = {
  accessToken: '0b70f423df49441fb979f3d11dbdee51',
  environment: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT: 'DEVELOPMENT',
};

function TestError() {
  const a = null;
  return a.hello_manager();
}

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store}>
          <Main>
            <App />
          </Main>
          {/* <TestError /> */}
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  // </React.StrictMode>
);
