import axios from 'axios';

export async function fetchQutter (qutter_id) {
  return new Promise(async (resolve, reject) => {
    axios.get(
      `${process.env.REACT_APP_URL}/qutters/v1/get-by-id/${qutter_id}`
    ).then(res => {
      resolve(res.data);
    })
    .catch(error => {
      console.log(`Api error ${qutter_id}`, error)
      reject(
        JSON.stringify({
          isError: true,
          status: error.response.status,
          data: error.response.data,
          statusText: error.response.statusText
        })
      );
    });
  });
}

export function fetchMultipleQutters (qutter_ids) {
  return new Promise(async (resolve) => {
    await axios.post(
      `${process.env.REACT_APP_URL}/qutters/v1/get-by-ids`,
      { ids: qutter_ids }
    ).then(res => { resolve(res.data); });
  });
}

export function saveNewQutter (qutter) {
  return new Promise((resolve) => {
    axios.post(
      `${process.env.REACT_APP_URL}/qutters/v1/save-new`,
      qutter
    ).then(res => { resolve(res.data); });
  });
}

export function updateQutter (qutter) {
  return new Promise((resolve) => {
    axios.post(
      `${process.env.REACT_APP_URL}/qutters/v1/update`,
      qutter
    ).then(res => { resolve(res.data); });
  });
}

export function removeQutter (data) {
  return new Promise((resolve) => {
    axios.post(
      `${process.env.REACT_APP_URL}/qutters/v1/remove-by-id`,
      {
        id: data.id,
        language: data.language
      }
    ).then(res => { resolve(res.data); });
  });
}
