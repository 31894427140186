import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const ScoringCategorySelectSingle = ({
  list,
  on_select=null,
  selected,
  clearable=false,
  disabled=false,
  className
}) => {
  // console.log('<ScoringCategorySelectSingle />');

  let idx = null;
  if (selected) {
    function find(obj) { return obj.value === selected; }
    idx = list.findIndex(find);
  }

  return (
    <Select
      defaultValue={selected && selected.length ? list[idx]: null}
      options={list}
      isMulti={false}
      isClearable={clearable}
      isDisabled={disabled}
      className={className}
      onChange={async (e)=> {
        if (on_select) {
          const s = await list.find((o) => o.value === e.value);
          on_select(e, s);
        }
      }}
    />
  );
};

export default ScoringCategorySelectSingle;