import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { PreviewRule } from './PreviewRule';
import { PreviewRulesButton } from './PreviewRulesButton';

export const PreviewRules = ({
  template_id,
  qutter_id,
  rules,
  order,
 }) => {
  // console.log('<PreviewRules />', qutter_id);
  // const qutter_config = useSelector(state => state.Library[qutter_id]);
  // const language = qutter_config.language.current;
  // const qutter = qutter_config[language];
  // const template = useSelector(state => state.Template[template_id]);
  // const params = useSelector(state => state.Parameters[qutter_id]);

  const [show, set_show] = useState(true);

  if (rules.length === 0) return (null);

  return (
  <div className="ms-1 mb-1 mt-0">
    <PreviewRulesButton show={show} on_click={() => { set_show(!show) }} />

    {show && rules.map((rule, rule_idx) => {
      return (
        <div
          key={`preview-rule-${rule.id}`}
          className="ms-2 mb-1"
        >

            <PreviewRule
              template_id={template_id}
              parent_id={qutter_id}
              qutter_id={qutter_id}
              rule={rule}
              rule_idx={rule_idx}
              order={order}
            />

        </div>
      )
    })}
  </div>
  )
};