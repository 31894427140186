import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';

import { Form, InputGroup, FormControl } from 'react-bootstrap';

import {
  editTemplateClient,
  editTemplateLanguage,
  editTemplateRoleId,
  editTemplateRoleName,
  editTemplateName,
  editTemplateDescription,
  editTemplateTitle,
  editTemplateCompanyName,
  editTemplateCompanyDepartment,
  editTemplateFormOfEmployment,
  editTemplateThreshold,
  editTemplateEstimatedLength,
  editTemplatePublished,
  editTemplateImmediatelyNotifyUnscored,
  editTemplateCandidateAppToggle,
  setReadOnlyMode,
  editTemplateScriptFlag
} from 'features/Template/slices/TemplateSlice';

import { ResetOutline } from 'features/TemplateOutline';

import { UpdateTemplate } from 'features/Template/slices/thunks';

import { RoleSelect } from 'features/RoleSelect';
import { ClientSelect } from 'features/ClientSelect';
import { LanguageSelect } from 'features/LanguageSelect';

import { setCurrentTemplateLanguage } from 'features/Template/slices/TemplateSlice';
import { setAllCurrentQutterLanguage } from 'features/Qutters/slices/LibrarySlice';

const RedirectPage = ({
  url,
  name
}) => {
  return (
  <a
    className='link-primary hover-blue'
    style={{ fontSize: '9px', fontWeight: 'bold' }}
    target='_blank'
    href={url} rel="noreferrer"
  >
    {name}
  </a>
  )
}

export const EditTemplateSettings = ({
  id,
  set_edit_mode
}) => {
  const dispatch = useDispatch();
  const template = useSelector(state => state.Template[id]);
  const [testJob, setTestJob] = useState(false);

  const onSaveClick = () => {
    console.log('save template', template);
    // VALIDATION - Validate the saved template
    dispatch(UpdateTemplate({ ...template })).then((r) => {
      // do additional work
      console.log('r', r)
    });
  }

  useEffect(() => {
    axios.get(
      `${process.env.REACT_APP_URL}/jobs/v1/get-test-job-by-template-id/${id}`
    ).then(async res => {
      setTestJob(res.data);
    });
  }, []);

  const allowed_languages = template.language_settings.allowed_languages.map((l) => { return l.code })

  return (
  <div>
    <div>
      <span style={{ fontSize: '9px', fontWeight: 'bold',  }}>Default Settings</span>

      {/* Template Name/Description */}
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Template Name</InputGroup.Text>
        <FormControl
          defaultValue={template.name}
          onChange={(e) => { dispatch(editTemplateName({ id, name: e.target.value })) }}
          aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
      </InputGroup>
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Template Description</InputGroup.Text>
        <FormControl
          defaultValue={template.description}
          onChange={(e) => { dispatch(editTemplateDescription({ id, description: e.target.value })) }}
          aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
      </InputGroup>

      {/* Client */}
      <ClientSelect
        client_id={template.client_id}
        selected_client_id={template.client_id}
        on_select={(e) => {
          dispatch(editTemplateClient({ id, client_id: e.value }));
        }}
      />

      {/* Template Language */}
      <LanguageSelect
        multi={true}
        placeholder='Interview languages'
        selected={template.language_settings.list}
        clearable={false}
        on_select={(selected) => {
          let languages = selected.map(s => s.value);
          console.log('<LanguageSelect /> selected', languages)
          if (languages.length === 0) return;
          //   languages = ['sv-se'];
          // }
          dispatch(editTemplateLanguage({ id, language: languages }))
          dispatch(ResetOutline({ languages }))
        }}
        exclude={['nn-no']}
      />

      {/* Allowed Template Languages */}
      <LanguageSelect
        multi={true}
        placeholder='Allowed languages'
        selected={allowed_languages}
        on_select={(selected) => {
          let languages = selected.map(s => s.value);
          dispatch(editTemplateLanguage({ id, allowed: languages }))
        }}
      />

      {/* Company */}
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Company Name</InputGroup.Text>
        <FormControl 
          defaultValue={template.organization ? template.organization.name: template.company}
          onChange={(e) => { dispatch(editTemplateCompanyName({ id, name: e.target.value })) }}
          aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
      </InputGroup>

      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Department</InputGroup.Text>
        <FormControl 
          defaultValue={template.company.department}
          onChange={(e) => { dispatch(editTemplateCompanyDepartment({ id, department: e.target.value })) }}
          aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
      </InputGroup>

      {/* Default Title */}
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Title</InputGroup.Text>
        <FormControl
        defaultValue={template.title}
        onChange={(e) => { dispatch(editTemplateTitle({ id, title: e.target.value })) }}
        aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
      </InputGroup>

      {/* Template Role */}
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Role</InputGroup.Text>
        <RoleSelect
          role_id={template.role.id}
          on_select={(e) => { dispatch(editTemplateRoleId({ id, role_id: e.value })) }}
          onChange={(e) => { console.log('onChange', e); }}
        />
      </InputGroup>

      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Your role name</InputGroup.Text>
        <FormControl
          defaultValue={template.role.name}
          onChange={(e) => { dispatch(editTemplateRoleName({ id, role_name: e.target.value })) }}
          aria-label="Small" aria-describedby="inputGroup-sizing-sm"
        />
      </InputGroup>

      {/* Form of Employment */}
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Form of Employment</InputGroup.Text>
        <Form.Select 
          aria-label="Form of Employment"
          defaultValue={template.position.form_of_employment}
          onChange={(e) => { dispatch(editTemplateFormOfEmployment({ id, form_of_employment: e.target.value })) }}
        >
          <option>Open this select menu</option>
          <option value="ft">Full time</option>
          <option value="pt">Part time</option>
          <option value="ew">Extra work</option>
        </Form.Select>
      </InputGroup>

      {/* Threshold */}
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Threshold</InputGroup.Text>
        <FormControl
          defaultValue={template.threshold}
          onChange={(e) => { dispatch(editTemplateThreshold({ id, threshold: e.target.value })) }}
          type="number"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
        />
      </InputGroup>

      {/* Template Estimated Length */}
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">Estimated Length</InputGroup.Text>
        <FormControl
          defaultValue={template.estimated_length}
          onChange={(e) => { dispatch(editTemplateEstimatedLength({ id, length: e.target.value })) }}
          type="number"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
        />
      </InputGroup>

      {/* Published */}
      <InputGroup size="sm" className="">
        <Form.Check 
          type='switch'
          id='checkedSwitch'
          label={(template.published === true ? 'Published': 'Not published')}
          defaultChecked={template.published}
          onChange={(e) => { dispatch(editTemplatePublished({ id, published: e.target.checked })) }}
        />
      </InputGroup>

      {/* Immediately notify unscored */}
      <InputGroup size="sm" className="">
        <Form.Check 
          type='switch'
          id='immediately_notify_unscoredSwitch'
          label={'Immediately notify unscored'}
          defaultChecked={template.immediately_notify_unscored}
          onChange={(e) => { dispatch(editTemplateImmediatelyNotifyUnscored({ id, immediately_notify_unscored: e.target.checked })) }}
        />
      </InputGroup>

      {/* Script */}
      <InputGroup size="sm" className="">
        <Form.Check 
          type='switch'
          id='receipt'
          label={'Script'}
          defaultChecked={template.script}
          onChange={(e) => { dispatch(editTemplateScriptFlag({ id, script: e.target.checked })) }}
        />
      </InputGroup>

      {/* Candidate app/Myhubert toggle */}
      <InputGroup size="sm" className="">
        <Form.Check 
          type='switch'
          id='candidate_app_switch'
          label={'Myhubert'}
          defaultChecked={template.candidate_app}
          onChange={(e) => { dispatch(editTemplateCandidateAppToggle({ id, candidate_app: e.target.checked })) }}
        />
      </InputGroup>

      <div className='text-center'>
        <span 
          className="hover-blue font-size-10"
          onClick={() => {
            if (set_edit_mode) set_edit_mode(false);
            onSaveClick();
          }}
        >
          <FontAwesomeIcon icon={faFloppyDisk} className="me-2"/>
          Save
        </span>
      </div>

    </div>
  </div>
  );
};
