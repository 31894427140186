import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SoftBadge from 'components/common/SoftBadge';

export const QutterShortDescription = ({ 
  shortDescription=null,
  qutter_id,
}) => {
  const qutterConfig = useSelector(state => state.Library[qutter_id]);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (qutterConfig && qutterConfig.short_description && qutterConfig.short_description.length) {
      setValue(qutterConfig.short_description);
    }
  }, [qutter_id]);

  useEffect(() => {
    if (shortDescription && shortDescription.length)
      setValue(shortDescription);
  }, [shortDescription]);

  if (!value.length)
    return null;

  return (
    <>
      <SoftBadge bg='light' className=''>
        {value}
      </SoftBadge>
      <br></br>
    </>
  )
};
