import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  toggleEditQutterModal
} from 'features/Modals/slices/ModalSlice';

export const EditQutterIcon = ({
  qutter_id,
  language
}) => {
  const dispatch = useDispatch();
  // console.log('<EditQutterIcon />', qutter_id);

  const onEditQutterClick = () => {
    dispatch(toggleEditQutterModal({
      show: true,
      qutter_id: qutter_id,
      language: language,
      newLanguage: false
    }));
  };

  return (
  <OverlayTrigger
    placement='top'
    overlay={
    <Tooltip id="button-tooltip-2">
      Edit
    </Tooltip>
    }
  >
  {({ ref, ...triggerHandler }) => (
    <span
      className={`hover-blue me-3 font-size-10`}
      ref={ref}
      {...triggerHandler}
      onClick={onEditQutterClick}
    >
      <FontAwesomeIcon icon={faPen} />
    </span>
  )}
  </OverlayTrigger>
  );
};
