import React from 'react';
import Select from 'react-select';

import { interview_actions_list } from '..';

export const InterviewActionsSelect = ({
  on_select,
  selected,
}) => {

  let idx = null;
  if (selected) {
    function findObj(obj) { return obj.value === selected; }
    idx = interview_actions_list.findIndex(findObj);
  }

  return (
    <Select
      defaultValue={selected ? interview_actions_list[idx]: null}
      placeholder='Choose action'
      options={interview_actions_list}
      onChange={(e)=> { if (on_select) on_select(e); }}
    />
  );
};
