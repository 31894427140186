import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareXmark,
  faPlusCircle,
  faXmark
} from '@fortawesome/pro-solid-svg-icons';

import { Modal, Table } from 'react-bootstrap';

import { InterviewActionsSelect } from 'features/HubertActions';
import { optionActionPlus, optionActionMinus, optionActionUpdate } from 'features/Qutters/slices/LibrarySlice';

export const SelectActionsModal = ({
  id,
  option_idx,
  showModal,
  setShowModal,
}) => {
  const dispatch = useDispatch();
  const qutter_config = useSelector(state => state.Library[id]);
  const qutter = qutter_config[qutter_config.language.current];
  const option = qutter.options[option_idx];
  const actions = option.actions;

  return (
  <Modal
    show={showModal}
    onHide={() => setShowModal(false)}
    backdrop={true}
    backdropClassName={'black'}
    dialogClassName=""
    size="m"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>
        Actions
      </Modal.Title>
    </Modal.Header>

    <Modal.Body className="">

      <span className='h6 d-block'>Select option actions
        <span
          className='hover-blue d-block float-end font-size-10'
          onClick={(e) => { 
            dispatch(optionActionPlus({ id, option_idx }));
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} className="me-1"/>
          Add action
        </span>
      </span>

      <Table>
        <tbody>
        {option.actions && option.actions.length ? (
          actions.map((action, option_action_idx) => {
            return (
              <tr>
                <td>
                  <InterviewActionsSelect
                    selected={action}
                    on_select={(e) => {
                      dispatch(optionActionUpdate({ id, option_idx, option_action_idx, value: e.value }));
                    }}
                  />
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <span
                    onClick={(e) => { 
                      dispatch(optionActionMinus({ id, option_idx, option_action_idx }))
                    }}
                  >
                    <FontAwesomeIcon
                      className='hover-blue'
                      style={{ color: 'red' }} 
                      icon={faSquareXmark}
                    />
                  </span>
                </td>
              </tr>
            )
          })
        ):(null)}
        </tbody>
      </Table>
    
    </Modal.Body>

    <Modal.Footer>
      <span
        className="font-size-10 hover-blue me-2"
        onClick={()=>{ setShowModal(false); }}
      >
        <FontAwesomeIcon icon={faXmark} className="me-1"/>
        Close
      </span>
    </Modal.Footer>
  </Modal>
  );
};
